/* eslint-disable */
import WATER_ACTIVITIES_REPOSITORY
    from "@/js/common/components/filterCriteria/waterActivities/WaterActivitiesRepository";
import {viewModelDecorator} from "@/js/common/Domain/Service/mixins/decorators";

@viewModelDecorator({})
export default class WaterActivities extends Backbone.Epoxy.View {
    initialize({searchPageModel}) {
        this.searchPageModel = searchPageModel;
        this.initModelEvents();
        this.render()

    }

    initModelEvents() {
        WATER_ACTIVITIES_REPOSITORY.forEach(item => {
            let model = item.name;
            this.viewModel.mapState(`${model}Count`, this.searchPageModel);
            this.viewModel.mapState(`${model}CountOverall`, this.searchPageModel);
        });
        this.viewModel.mapState(`beforeToursLoading`, this.searchPageModel);
    }

    render() {
        this.$el.find(".filtersListContainer")?.html(this.template?.({
            waterActivities: WATER_ACTIVITIES_REPOSITORY
        }))
    }
}