import { TrackJS } from 'trackjs';

type AbTestsData = string[];
type ABTestsInitOptions = {
    abTests: AbTestsData;
}
export default class AbTests {
    private abTests: AbTestsData | null = null;

    public init(options: ABTestsInitOptions) {
        const {
            abTests,
        } = options;

        if (Array.isArray(abTests)) {
            this.abTests = options.abTests;
        }
    }

    public getTests(): AbTestsData {
        this.checkAbtestsData();

        return this.abTests || [];
    }

    public hasTest(testName: string): boolean {
        this.checkAbtestsData();
        return !!this.abTests?.includes(testName);
    }

    private checkAbtestsData() {
        if (!this.abTests) {
            TrackJS.track('abTestsData is not set');
        }
    }
}