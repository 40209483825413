/* eslint-disable */
import {extendDecorator, propsDecorator} from '@/js/common/Domain/Service/mixins/decorators';

import './assets/cashbackHotelCardBanner.scss';
import template from './assets/template.html';

const CashbackHotelCardBannerModel = Backbone.Model.extend({
    defaults: {
        tailPosition: null,
        width: null,
    },
});

@propsDecorator({
    model: new CashbackHotelCardBannerModel(),
    computeds: {
        tailPositionBottomCenter: {
            deps: ['tailPosition'],
            get: (tailPosition) => tailPosition === 'bottom-center',
        },
        fullWidth: {
            deps: ['width'],
            get: (width) => width === 'full',
        },
        roundedTop: {
            deps: ['rounded'],
            get: (rounded) => rounded === 'top',
        },
    },
    bindings: {
        '.js-arrow-position': "classes:{'cashbackHotelBanner__container-arrow--bottom-center': tailPositionBottomCenter}",
        '.js-banner-width': "classes:{'cashbackHotelBanner__container--fullWidth': fullWidth}",
        '.js-rounded': "classes:{'cashbackHotelBanner__container--roundedTop': roundedTop}",
    },
})
@extendDecorator({
    template,
})
export default class CashbackHotelCardBanner extends Backbone.Epoxy.View {
    initialize(options) {
        this.productType = options?.productType;
        this.page = options?.page;
        this.initModel(options);
        if (options?.addType === 'append') {
            this.$el.append(this.template({
                productType: this.productType,
                page: this.page,
            }));
        } else {
            this.$el.after(this.template({
                productType: this.productType,
                page: this.page,
            }));
        }
    }

    initModel(options) {
        this.model.set('tailPosition', options?.tailPosition || null);
        this.model.set('width', options?.width || null);
        this.model.set('rounded', options?.rounded || null);
    }
}
