import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import requestService from '@/js/common/Domain/Service/request';
import tourHunterService from '@/js/common/Domain/Service/tourHunter';
import TrackingService from '@ve/pages/index/analytics/yandexAnalytics/indexTrackingService';
import templateUnauthorize from './templateUnauthorize.phtml';
import templateAuthorize from './templateAuthorize.phtml';

import TopMenuBarModel from './TopMenuBarModel';

import './topMenuBar.css';

@extendDecorator({
    events: {
        'click .openAuthForm': 'openAuthForm',
        'click .accountTopMenuBar__logInButton': 'openAuthForm',
        'click .accountTopMenuBar__logout': 'logout',
        'click .accountTopMenuBar__dropdownButton': 'openLK',
    },
    bindings: {
        ':el': 'toggle:accountItemShow',
        '.accountTopMenuBar__dropdownButton': 'classes:{loading:profileRequestInProgress}',
        '.accountTopMenuBar__userName': 'text:accountUsername',
        '.accountTopMenuBar__tourHunterCount': 'text:activeTourHunters',
    },
    computeds: {
        accountUsername: {
            deps: ['name', 'email'],
            get(name, email) {
                return name || email;
            },
        },
    },
})
export default class AccountTopMenuBar extends Backbone.Epoxy.View {
    openLK() {
        window.open(window.appConfig.accountUrl, '_blank');
    }

    logout() {
        const options = {
            url: `${window.appConfig.accountUrl}/api/customer/logout`,
            type: 'POST',
            xhrFields: { withCredentials: true },
        };

        if (!this.viewModel.get('logoutInProgress')) {
            this.viewModel.set('logoutInProgress', true);
            requestService(options, {
                success: (response) => {
                    this.viewModel.set('logoutInProgress', false);
                    if (response.success) {
                        this.model.set('authorized', false);
                    }
                },
                error: (response, textStatus) => {
                    this.viewModel.set('logoutInProgress', false);
                },
            });
        }
    }

    async openAuthForm() {
        new TrackingService().trackAuth();

        await this.parent.lazyAuthorizeInit();
        this.parent.authorize.show();
    }

    render() {
        const template = this.model.get('authorized') ? templateAuthorize({
            accountDomain: window.appConfig.accountUrl,
        }) : templateUnauthorize();
        this.$el.empty().append(template);

        $(document).trigger('topMenuBarChanged');

        this.applyBindings();
    }

    initModelEvents() {
        this.model.on('change:authorized', $.proxy(this.render, this));

        if (this.model.get('profileDataReceived')) {
            this.viewModel.set('accountItemShow', 1);
        } else {
            this.model.once('profileDataReceived', () => {
                this.viewModel.set('accountItemShow', 1);
            });
        }

        tourHunterService.getActiveSubscribes().on('add remove', (elements, collection) => {
            this.viewModel.set('activeTourHunters', collection.length ? collection.length : '');
        });
        tourHunterService.getActiveSubscribes().on('reset', (collection) => {
            this.viewModel.set('activeTourHunters', collection.length ? collection.length : '');
        });
    }

    initialize(options) {
        this.viewModel = new TopMenuBarModel();
        this.parent = options.parent;
        this.render();
        this.initModelEvents();
    }
}
