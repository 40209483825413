
    import { defineComponent, computed, unref } from 'vue';
    import FilterListRender from '@ve/components/filters/common/components/FilterListRender/FilterListRender.vue';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import useFilterAttributesHotel from '@ve/components/filters/common/use/useFilterAttributesHotel';
    import useLogicRestType from '@ve/components/filters/common/use/useLogicRestType';
    import FilterAttributeMethodsNearCenter from './FilterAttributesMethodsNearCenter';
    import SingleListFilter from '../../searchFilters/filterList/desktop/SingleListFilter.vue';

    export default defineComponent({
        name: 'NearCenterFilter',
        components: {
            FilterListRender,
            SingleListFilter,
            FilterPostWrapper,
        },
        setup() {
            const {
                selectedAttributes,
                filteredHotels,
                syncHotels,
                isPopularityFilterActive,
            } = useFilterAttributesHotel('NearCenterFilter');

            return {
                FilterAttributeMethodsNearCenter,
                selectedAttributes,
                filteredHotels,
                syncHotels,
                isPopularityFilterActive,
                ...useLogicRestType(FilterAttributeMethodsNearCenter),
            };
        },
    });
