var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'icon-rating',
    `icon-rating_${_vm.size}`,
]},[_c('div',{class:[
        'icon-rating__rating',
        `icon-rating__rating_${_vm.size}`,
        `icon-rating__rating_${_vm.type}`,

    ]},[_c('div',{class:[
            'icon-rating__rating-value',
            {
                'icon-rating__rating-value_hide': _vm.hidenText,
            }
        ]},[_vm._t("rating")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }