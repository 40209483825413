import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { TCheckInDateRangeResult } from '..';

export default (date: TCheckInDateRangeResult, daysFlexible: number | undefined): TCheckInDateRangeResult => {
    if (date.from && daysFlexible) {
        return {
            from: subDays(date.from, daysFlexible),
            to: addDays(date.from, daysFlexible),
        };
    }
    return date;
};