/* eslint-disable */
const SearchFormFieldModel = Backbone.Model.extend({
	defaults: {
		collection:null,
		model:null,
		oldModel: null,
		view: null
	},
	setNull: function() {
		var self = this,
			model = self.get('model');

		//console.log('setNull', this);
		if(model) {
			self.set('oldModel', model);
		}
		self.set('model', null, {initiator: 'controller'});
	}
});

export default SearchFormFieldModel;