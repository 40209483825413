/* eslint-disable */
/**
 * @param  {object} items
 * @param  items[key].links[key].price
 * @returns {string} minPrice
 */

export const findMinPrices = items => {
	if (!_.isObject(items) || Array.isArray(items)) return '';

	if (_.isEmpty(items)) return '';

	const links = Object.keys(items).map(key => items[key].links);

	if (!links || links.some(element => (element === null)
										|| (element === undefined)
										|| !_.isObject(element)
										|| _.isEmpty(element))) return '';

	const prices2D = links.map(link => Object.keys(link).map(linkKey => link[linkKey].price));
	const minPrice = prices2D.reduce((currMin, nextArr) => {
		let __currMin = currMin;
		const min = nextArr.reduce((priceArr, nextPrice) => {
			if (nextPrice && nextPrice.replace) {
				return priceArr.concat(nextPrice.replace(' ', '.'));
			} return priceArr;
		}, []).sort((a, b) => a.length - b.length)[0];

		if (__currMin < 0 || +min < +__currMin) __currMin = min;
	
		return __currMin;
	}, -1);

	if (isNaN(minPrice) || minPrice === Infinity) return '';

	return `${minPrice}`.replace('.', ' ');
};
