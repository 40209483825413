import { type IHotel } from '@entities/hotel';
import { type BeachTypeFilterItem } from '../types'

function filterHotelByBeachType(hotel: IHotel, beachTypeFilterIds: BeachTypeFilterItem['id'][]): boolean {
    if (beachTypeFilterIds.length === 0) {
        return true;
    }

    if (hotel.beachType === 0) return false;

    return beachTypeFilterIds.includes(hotel.beachType);
}

export default filterHotelByBeachType;
