/* eslint-disable */
import ResortEntity from '../Entity/Resort';
import EntityManager from '../EntityManager';

const ResortFactory = function(data) {
	var resort = new ResortEntity(),
		entityManager = new EntityManager(),
		country,
		element,
		CountriesRepository = require("../Repository/Countries").default;

	element = data['id'];
	if(typeof element != "undefined") {
		resort.set('id', element);
	}
	
	element = data['name'];
	if(typeof element != "undefined") {
		resort.set('name', element);
	}

	element = data['atFiltering'];
	if(typeof element != "undefined") {
		resort.set('atFiltering', element);
	}

	element = data['country'];
	if(typeof element != "undefined") {
		country = entityManager.get(CountriesRepository, element);
		resort.set('country', country);
	}
	
	element = data['isPopular'];
	if(typeof element != "undefined") {
		resort.set('isPopular', element);
	}
	
	return resort;
};

export default ResortFactory;