export default (tourCriteria) => {
    const params = {};
    const element = tourCriteria.get('nightRange');
    if (element) {
        const from = element.get('from');
        const to = element.get('to');
        if (from) {
            params.nightFrom = from;
        }
        if (to) {
            params.nightTo = to;
        }
    }

    return params;
};