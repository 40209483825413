
    import {
        defineComponent, SetupContext, unref, computed, watch, ref,
    } from 'vue';
    import FilterBySearchHotels from '@ve/components/filters/searchFilterByHotel/desktop/FilterBySearchHotel.vue';
    import { useState, useMutations } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState, IMapForFiltersSerpMutations } from '@ve/components/searchFilters/store/types';
    import { FoundHotelsBySearchFilter } from '@ve/types';
    import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import useVueProxy from '@ve/services/useVueProxy';

    import TooltipHotelSearch from './ToolTipHotelSearch.vue';

    type hotelDataComponent = {
        id: number;
        name: string;
    }

    export default defineComponent({
        name: 'FilterSearchHotelWrapperSerp',
        components: {
            FilterBySearchHotels,
            TooltipHotelSearch,
        },
        setup() {
            const { $root } = useVueProxy();
            const {
                foundHotelsByFilters,
                loadingState,
                takenHotels,
            } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['foundHotelsByFilters', 'loadingState', 'takenHotels']);

            const serpTrackingService = new SerpTrackingService();

            const { setTakenHotels } = useMutations<IMapForFiltersSerpMutations>(nameFilterSerpStore, ['setTakenHotels']);

            const getHotel = (hotel: FoundHotelsBySearchFilter): hotelDataComponent => ({
                id: hotel.id,
                name: `${hotel.name}, ${hotel.resortName}`,
            });

            const takenHotelsAdapt = computed(() => unref(takenHotels).map((hotel) => getHotel(hotel)));

            const foundHotelsAdapt = computed(() => unref(foundHotelsByFilters).map((hotel) => getHotel(hotel)));

            const takenHotelHandler = (takenHotel: hotelDataComponent) => {
                const hotelAllData = unref(foundHotelsByFilters).find((hotel) => hotel.id === takenHotel.id);
                if (hotelAllData) {
                    setTakenHotels([...unref(takenHotels), hotelAllData]);
                }
            };

            const removeHotelHandler = (takenHotel: hotelDataComponent) => {
                const hotels = unref(takenHotels).filter((hotel) => hotel.id !== takenHotel.id); // delete taken hotels
                setTakenHotels(hotels);
            };

            const resetAllHotelsHandler = () => {
                setTakenHotels([]);
            };

            const startSearchHandler = () => {
                $root?.$emit('start-search-by-filter-search', unref(takenHotels));
                serpTrackingService.filterBySearchHotelStart();
            };
            const inputHandlerEvent = (words: string) => {
                $root?.$emit('input-filter-hotels-search', words);
                serpTrackingService.filterBySearchHotelInput();
            };

            return {
                foundHotelsAdapt,
                takenHotelsAdapt,
                loadingState,
                inputHandlerEvent,
                takenHotelHandler,
                removeHotelHandler,
                resetAllHotelsHandler,
                startSearchHandler,
            };
        },
    });
