/* eslint-disable */
import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import HotelCategoriesRepository from '@/js/common/Domain/Repository/HotelCategories';
import tourHunterService from '@/js/common/Domain/Service/tourHunter';
import account from '@/js/desktop/components/account';
import thSuccessPopupView from '@/js/desktop/components/search/thSuccessPopup/ThSuccessPopup';
import trackingService from '@/js/common/Domain/Service/tracking/tracking';

let submitTourHunterProxy;
const TourHunterFavoritesViewModel = Backbone.Epoxy.Model.extend({
    defaults: {
        viewModelEmail: '',
        requestInProgress: false,
        viewModelAuthorized: false,
        addedToFavorites: false,
    },
});

const ThFavoritesViewAbstract = Backbone.Epoxy.View.extend({
    events: {
        click: 'toggleFavorites',
    },
    bindings: {
        ':el': 'classes:{added: addedToFavorites, updating:requestInProgress}',
    },
    getMinPrice() {
        return this.model.get('minPrice');
    },
    getTourCriteria() {
        const tourCriteria = this.model.get('tourProducts').at(0).get('tourCriteria').clone();

        tourCriteria.set('hotels', new HotelsRepository([this.model]));
        tourCriteria.set('hotelCategories', new HotelCategoriesRepository()); //to prevent wrong categories on hotel page

        return tourCriteria;
    },
    toggleFavorites(event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.viewModel.get('requestInProgress')) return;
        if (this.viewModel.get('addedToFavorites')) {
            this.removeFromFavorites();
        } else {
            this.addToFavorites();
        }
    },
    removeFromFavorites() {
        this.trackingClickTh('removeFromTh');

        if (this.$el.attr('disabled')) return;

        const self = this;
            const tourCriteria = this.getTourCriteria();

        if (!this.viewModel.get('requestInProgress')) {
            this.viewModel.set('requestInProgress', 1);

            tourHunterService.unsubscribeByTourCriteria({
                tourCriteria,
                success() {
                    self.viewModel.set('requestInProgress', 0);
                },
            });
        }
    },
    trackingClickTh(type) {},//abstract
    async addToFavorites() {
        this.trackingClickTh('addToTh');

        if (this.$el.attr('disabled')) return;

        if (this.viewModel.get('viewModelAuthorized')) {
            this.submitTourHunter();
        } else {
            await this.openAuthorizeForm();
            this.addAuthorizeSubscribes();
        }
    },
    async openAuthorizeForm() {
        await account.lazyAuthorizeInit();
        account.authorize.show();
    },
    submitTourHunterProxy() {
        this.submitTourHunter({
            success(tourCriteria) {
                thSuccessPopupView({
                    tourCriteria,
                }).show();
            },
        });
    },
    addAuthorizeSubscribes() {
        if (submitTourHunterProxy) {
            account.authorize.model.off('change:authorized', submitTourHunterProxy);
            account.authorize.model.off('needToVerifyUser', submitTourHunterProxy);
        }

        submitTourHunterProxy = $.proxy(this.submitTourHunterProxy, this);
        account.authorize.model.once('change:authorized', submitTourHunterProxy);
        account.authorize.model.once('needToVerifyUser', submitTourHunterProxy);
    },
    trackTourHunters(tourCriteria) {
        trackingService.trackTourHunters(tourCriteria);
    },
    submitTourHunter(callback) {
        const email = this.viewModel.get('viewModelEmail');
        const self = this;
        const tourCriteria = this.getTourCriteria();

        if (!this.viewModel.get('requestInProgress')) {
            this.viewModel.set('requestInProgress', 1);

            tourHunterService.subscribe({
                data: {
                    email,
                    tourCriteria,
                    minPrice: this.getMinPrice(),
                },
                success(response) {
                    self.viewModel.set('requestInProgress', 0);
                    callback && callback.success ? callback.success(tourCriteria) : '';
                },
                error(response) {
                    self.viewModel.set('requestInProgress', 0);
                    callback && callback.error ? callback.error(tourCriteria) : '';
                },
            });
        }
    },
    updateFavoriteState() {
        const tourCriteria = this.getTourCriteria();

        this.viewModel.set('addedToFavorites', tourHunterService.criteriaIsSubscribed(tourCriteria));
    },
    initSubscribes() {
        const self = this;

        tourHunterService.getActiveSubscribes().on('add remove reset', $.proxy(this.updateFavoriteState, this));

        this.viewModel.set('viewModelEmail', account.model.get('email'));
        account.model.on('change:email', (model, value) => {
            self.viewModel.set('viewModelEmail', value || '');
        });

        this.viewModel.set('viewModelAuthorized', account.model.get('authorized'));
        account.model.on('change:authorized', (model, value) => {
            self.viewModel.set('viewModelAuthorized', value);
        });
    },
    initialize(options) {
        this.viewModel = new TourHunterFavoritesViewModel();
        this.searchPageModel = options.searchPageModel;
        this.updateFavoriteState();
        this.initSubscribes();
    },
});

export default ThFavoritesViewAbstract;