/* eslint-disable */
import {propsDecorator, viewModelDecorator} from '@/js/common/Domain/Service/mixins/decorators';
import CustomSelect from '@/js/desktop/components/customSelect/CustomSelect';
import template from './template/selectTemplate.html';

@viewModelDecorator({
    defaults: {
        showCloseIcon: false,
    },
})

@propsDecorator({
    el: '<div class="child-select"></div>',
    template,
    bindings: {
        '.js-close-icon': 'toggle:showCloseIcon',
    },
    events: {
        'click .js-close-icon': 'removeChild',
    },
})
export default class ChildView extends Backbone.Epoxy.View {
    initialize(options = {}) {
        this.collectionView = options.collectionView;
        this.render();
        this.initModelEvents();
        this.viewModel.set({
            showCloseIcon: this.model?.get('model')?.get('name'),
        });
    }

    initModelEvents() {
        this.model.on('change:model', (model, value) => {
            if (value) {
                this.viewModel.set({showCloseIcon: true});
                this.collectionView?.controlChanged();
            }
        });
    }

    render() {
        this.$el.html(this.template());
        new CustomSelect({
            model: this.model,
            el: this.$el.find('.customSelect'),
            labelTitle: '',
            defaultLabelValue: 'Добавить ребенка',
            requiredValue: false,
            arrow: true,
        });
    }

    removeChild(e) {
        e?.stopPropagation();
        this.collectionView?.removeChild(this.model);
    }
}