import moment from 'moment';
import 'moment/locale/ru';
import _ from 'underscore';

import trackingDefaultDataTypesHydrator, { ItrackingDefaultDataTypes } from './trackingDefaultDataTypes';
import trackingTourCriteriaHydrator, { ItrackingTourCriteriaReturn } from './trackingTourCriteria';

interface ItrackingCalendarDataHydrator {
    datesWithPricesAmount: number,
    tourCriteria: Backbone.Model,
    timestamp: number,
    timeFinished: number,
}

interface ItrackingCalendarDataHydratorReturn extends ItrackingDefaultDataTypes, ItrackingTourCriteriaReturn {
    percentCovered: number,
    timeFinished: number,
    hasPersonalProfile: number,
}

/*
    hasPersonalProfile - неиспользуемое поле первой реализации перс выдачи. Не удалил так как контракт бэка ожидает его.
*/
const trackingCalendarDataHydrator = async (options: ItrackingCalendarDataHydrator): Promise<ItrackingCalendarDataHydratorReturn> => {
    const trackingCalendarData = {};
    const checkInDateRange = options.tourCriteria.get('checkInDateRange');
    const requestedDays = moment(checkInDateRange.get('to')).diff(checkInDateRange.get('from'), 'days') + 1;
    const daysWithPricesPercentCovered = Math.round((options.datesWithPricesAmount / requestedDays) * 100);
    const timeFinished = options.timeFinished ? Math.round((options.timeFinished - options.timestamp) / 1000) : 0;
    const trackingDefaultDataTypes = await trackingDefaultDataTypesHydrator(options);
    const trackingTourCriteria = trackingTourCriteriaHydrator(options);

    _.extend(trackingCalendarData, {
        percentCovered: daysWithPricesPercentCovered,
        timeFinished,
        hasPersonalProfile: 0,
    }, trackingDefaultDataTypes, trackingTourCriteria);

    return trackingCalendarData as ItrackingCalendarDataHydratorReturn;
};

export default trackingCalendarDataHydrator;