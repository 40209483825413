/* eslint-disable */
import errorHandlerService from './Service/errorHandler';

const EntityManager = function() {
	var self = this;
	
	self.get = function(Repository, identity) {
		var entity,
			currentEntity;
	
		if(_.isArray(identity)) {
			entity = new Repository;

			_.each(identity, function(currentIdentity) {
				currentEntity = Repository.repository.get(currentIdentity);
				if(!entity && currentIdentity !== "all") {
					errorHandlerService('No entity with id: ' + currentIdentity + ' in the repository');
				} else {
					entity.add(currentEntity);
				}
			});
		} else {
			entity = Repository.repository.get(identity);
			if(!entity && identity !== "all") {
				errorHandlerService('No entity with id: ' + identity + ' in the repository');
				return null;
			}
		}
		return entity;
	};
	
	self.add = function(entities, data, additionalParams) {
		var readyData;

		if(_.isArray(data)) {
			readyData = [];
			_.each(data, function(hotel) {
				readyData.push(new entities.factory(hotel));
			});
		} else {
			readyData = new entities.factory(data);
		}

		readyData = entities.repository.add(readyData, additionalParams);

		return readyData;
	};
	
	self.isExist = function(repository, identity) {
		return repository.indexOf(identity) !== -1;
	};
};

export default EntityManager;