
    import Cookies from 'js-cookie';
    import {
        defineComponent, ref, watch, PropType, toRefs,
    } from 'vue';

    export default defineComponent({
        props: {
            loadingState: {
                type: String as PropType<string>,
                default: '',
            },
        },
        setup(props) {
            const { loadingState } = toRefs(props);
            const showToolTip = ref(false);

            watch(loadingState, (value) => {
                const isToolTipWasShoved = Cookies.get('hotelSearchTooltipWasShoved');
                if (value === 'asyncLoading' && !isToolTipWasShoved) {
                    showToolTip.value = true;
                    setTimeout(() => {
                        Cookies.set('hotelSearchTooltipWasShoved', 'true');
                        showToolTip.value = false;
                    }, 3000);
                }
            });

            return {
                showToolTip,
            };
        },
    });
