import { BackboneHotel } from '@ve/types/backboneModels/Domain/Entity/BackboneHotel';
import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';

export default (hotels: BackboneHotel[]): Partial<SerpHotelAdapted>[] => {
    if (!hotels?.length) return [];
    return hotels.map((hotel) => ({
        id: hotel.get('id'),
        isExclusive: hotel.get('isExclusive'),
        minPrice: hotel.get('minPrice'),
        beachOwner: hotel.get('beachOwner'),
        nearTheLift: hotel.get('nearTheLift'),
        beachLine: hotel.get('beachLine'),
        rating: hotel.get('rating'),
        distanceLift: hotel.get('distanceLift'),
        wifi: hotel.get('wifi'),
        lonelyMan: hotel.get('lonelyMan'),
        adultsOnly: hotel.get('adultsOnly'),
        withKids: hotel.get('withKids'),
        forCouples: hotel.get('forCouples'),
        airConditioner: hotel.get('airConditioner'),
        withAnimals: hotel.get('withAnimals'),
        spaAndHealth: hotel.get('spaAndHealth'),
        bayLocated: hotel.get('bayLocated'),
        parking: hotel.get('parking'),
        saunaBathHammam: hotel.get('saunaBathHammam'),
        jacuzzi: hotel.get('jacuzzi'),
        children_childBed: hotel.get('children_childBed'),
        children_nanny: hotel.get('children_nanny'),
        children_clubs: hotel.get('children_clubs'),
        children_animators: hotel.get('children_animators'),
        children_pool: hotel.get('children_pool'),
        children_childmenu: hotel.get('children_childmenu'),
        children_playroom: hotel.get('children_playroom'),
        restTypes: hotel.get('restTypes'),
        nearTheCenter: hotel.get('nearTheCenter'),
        beachType: hotel.get('beachType'),
        childServices: hotel.get('childServices'),
        waterActivities: hotel.get('waterActivities'),
        facilities: hotel.get('facilities'),
        tourProducts: [],
    }));
};