import { TourProduct } from '@entities/tourProduct';
import { IHotel } from '@entities/hotel';

export default function (tourProducts: TourProduct[], hotels: IHotel[]): TourProduct[] {
    const mapHotels = hotels.reduce((acc, hotel) => {
        acc[hotel.id] = true;
        return acc;
    }, { } as Record<number, boolean>);

    return tourProducts.filter((tourProduct) => mapHotels[tourProduct?.hotel?.id || 0]);
}