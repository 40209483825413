import { ValuesOf } from '@ve/types/helpers';
import { IFilterAttribute } from '@ve/components/filters/common/types';
import { TConfirmation } from '@entities/tourProduct';

// eslint-disable-next-line no-shadow
export enum idAttributeConfirmation {
    quick = 1,
    long = 2,
    cancel = 3,
}

type keysConfirmation = TConfirmation;
type idsConfirmation = ValuesOf<idAttributeConfirmation>;

export type IFilterAttributeConfirmation = IFilterAttribute<idsConfirmation | 0, keysConfirmation>;