import { Store } from 'vuex';
import { BaseState } from '@ve/services/getStore/types';
import getStore from '@ve/services/getStore/getStore';
import { BackboneSearchPageModel } from '@ve/types/backboneModels/BackboneSearchPageModel';

export const NAMESPACE = 'serpPageStateStore';
export default ({
    model,
}: {
    model: BackboneSearchPageModel;
}): Store<BaseState> => getStore({
    name: NAMESPACE,
    backboneMap: [
        {
            fields: [{
                covidBanner: 'covidBanner',
                viewMode: 'viewMode',
            }],
            model,
        },
    ],
    storeOptions: {
        getters: {
            viewModeIsMap(state): boolean {
                return state.viewMode === 'map';
            },
        },
    },
});