/* eslint-disable */
import {TrackJS} from 'trackjs';

const maxGaTries = 300;
const gaAvilable = () => !_.isUndefined(window.ga);

const gaPromise = new Promise((resolve) => {
    let attempts = 0;
    const check = () => {
        if (gaAvilable()) {
            resolve();
        } else if (attempts < maxGaTries) {
            attempts++;
            setTimeout(() => {
                check();
            }, 200);
        }
    };

    check();
});

export default async (callback) => {
    if (typeof (window.ga) !== 'undefined') {
        if (_.isFunction(window.ga)) {
            callback();
        } else {
            console.log(window.ga, JSON.stringify(window.ga));
            TrackJS.track('GA redefined');
        }
    } else {
        await gaPromise;
        callback();
    }
};