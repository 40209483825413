/* eslint-disable */
import Epoxy from 'backbone.epoxy';

Epoxy.Model.prototype.recompute = function (attribute) {
    var self = this,
        computeds;

    attribute = attribute ? attribute : _.keys(self.computeds);

    if (_.isArray(attribute)) {
        _.each(attribute, function (attr) {
            if (self.hasComputed(attr)) {
                return self.c()[attr].get(true);
            }
        });
    } else if (attribute) {
        if (this.hasComputed(attribute)) {
            return this.c()[attribute].get(true);
        }
    }

    return null;
};

Epoxy.View = Epoxy.View.extend({
    bindingFilters: {
        empty: function (modelField, value) {
            return _.isEmpty(modelField, value);
        },
        equal: function (modelField, value) {
            return modelField === value;
        },
        wordEnding: function (number, value) {
            if (typeof number == "number" || (typeof number == "string" && parseInt(number, 10) == number)) {
                return number.wordEnding(value);
            } else {
                return false;
            }
        },
        formatNumber: function (number) {
            if (_.isNumber(number)) {
                return number.formatNumber();
            }
        },
        length: function (collection) {
            return (collection !== null && collection.length) ? collection.length : false;
        }
    },
    bindingHandlers: {
        animatedToggle: {
            set: function ($element, params) {
                var value = params,
                    animationTime = 400;

                if (_.isArray(params)) {
                    value = params[0];
                    animationTime = params[1];
                }
                $element.stop(false, true);

                if (!value) {
                    if ($element.is(':visible')) {
                        $element.slideUp(animationTime);
                    } else if (!$element.parent().is(':visible')) {
                        $element.hide();
                    }

                }
                if (!$element.is(':visible') && value) {
                    $element.slideDown(animationTime);
                }
            },
            get: function () {
            }
        },
        fadeToggle: {
            set: function ($element, param) {
                var value = param,
                    timeout = 400;
                if (_.isArray(value)) {
                    value = param[0];
                    timeout = param[1];
                }

                if ($element.is(':visible') && !value) {
                    $element.fadeOut(timeout).addClass("hidden");
                }

                if (!$element.is(':visible') && value) {
                    $element.removeClass("hidden").fadeIn(timeout);
                }
            },
            get: function () {
            }
        },
        tempToggle: {
            set: function ($element, param) {
                var value = param,
                    timeout = 2500;
                if (_.isArray(value)) {
                    value = param[0];
                    timeout = param[1];
                }
                clearTimeout($element.data('animationTimer'));
                if (!value) {
                    $element.hide();
                } else {
                    $element.show();
                    $element.data(
                        'animationTimer', setTimeout(function () {
                            $element.hide();
                        }, timeout)
                    )
                }
            },
            get: function () {
            }
        },
        toggleInlineBlock: {
            set: function ($element, value) {
                if (value) {
                    $element.css('display', 'inline-block');
                } else {
                    $element.css('display', 'none');
                }
            },
            get: function () {
            }
        },
        delayedToggle: {
            set: function ($element, param) {
                var value = param,
                    timeout = 3000;
                if (_.isArray(value)) {
                    value = param[0];
                    timeout = param[1];
                }
                clearTimeout($element.data('animationTimer'));
                if (value) {
                    $element.data(
                        'animationTimer', setTimeout(function () {
                            $element.show();
                        }, timeout)
                    )
                } else {
                    $element.hide();
                }
            },
            get: function () {
            }
        },
        fadeIn: {
            set: function ($element, param) {
                var value = param,
                    timeout = 400;
                if (_.isArray(value)) {
                    value = param[0];
                    timeout = param[1];
                }

                $element.hide().fadeIn(timeout);
            },
            get: function () {
            }
        },
        event: function ($element, eventParams) {
            $($element).on(eventParams[0], $.proxy(this.view[eventParams[1]], this.view));
        },
        set: function ($element, eventParams) {
            var self = this;
            $($element).on(eventParams[0], function () {
                self.view.model.set(eventParams[1], eventParams[2]);
                return false;
            });
        },
        toggler: function ($element, eventParams) {
            var self = this;
            $($element).on(eventParams[0], function () {
                var currentValue = self.view.model.get(eventParams[1]);
                self.view.model.set(eventParams[1], !currentValue);
                return false;
            });
        },
        setUnset: function (element, eventParams) {
            var self = this;
            $(element).on(eventParams[0], function () {
                var setValue;
                if ($(this).prop('checked')) {
                    setValue = eventParams[2];
                } else {
                    setValue = false;
                }
                self.view.model.set(eventParams[1], setValue);
            });
        },
        checkedSet: function (element, eventParams) {
            $(element).prop('checked', eventParams[2] == this.view.model.get(eventParams[1]));
            this.view.model.on(eventParams[0] + ':' + eventParams[1], function (model, value) {
                $(element).prop('checked', value == eventParams[2]);
            });
        },
        clear: function (element, value) {
            if (!value) {
                $(element).empty();
            }
        }
    }
});
