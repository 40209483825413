import departureCity from './tourCriteria/departureCity';
import country from './tourCriteria/country';
import checkInDateRange from './tourCriteria/checkInDateRange';
import nightRange from './tourCriteria/nightRange';
import touristGroup from './tourCriteria/touristGroup';
import meals from './tourCriteria/meals';
import operators from './tourCriteria/operators';
import priceRange from './tourCriteria/priceRange';
import resorts from './tourCriteria/resorts';
import hotelCategories from './tourCriteria/hotelCategories';
import hotels from './tourCriteria/hotels';

export default (tourCriteria) => {
    let asyncTourCriteriaJson = {
        ...departureCity(tourCriteria),
        ...country(tourCriteria),
        ...checkInDateRange(tourCriteria),
        ...nightRange(tourCriteria),
        ...touristGroup(tourCriteria),
        ...meals(tourCriteria),
        ...operators(tourCriteria),
        ...priceRange(tourCriteria),
        ...hotels(tourCriteria),
    };

    const hotelsData = tourCriteria.get('hotels');
    if (!hotelsData?.length) {
        asyncTourCriteriaJson = {
            ...asyncTourCriteriaJson,
            ...resorts(tourCriteria),
            ...hotelCategories(tourCriteria),
        };
    }

    return asyncTourCriteriaJson;
};