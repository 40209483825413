import './makeSearchStaticBanner.css';

const MakeSearchStaticBannerView = Backbone.View.extend({
    el: '<div class="makeSearchStaticBanner">\n\t<i class="close icon-i16_close"></i>\n\t<h3>Цены могли устареть</h3>\n\t<p>Обновите выдачу, чтобы увидеть актуальные цены</p>\n\t<div class="updatePrices"><span>Обновить цены</span><i class="icon-renew-small"></i></div>\n</div>',
    events: {
        'click .close': 'hideBanner',
        click: 'startSearch',
    },
    hideBanner(e) {
        this.$el.slideUp(200);
        e.stopPropagation();
    },
    addToStatic() {
        const { noToursFound, hideUpdatePriceBanner = false } = window.appConfig;

        if (!noToursFound && !hideUpdatePriceBanner) {
            $('.mainColumnRight .staticContainer').prepend(this.$el);
        }
    },
    initialize(options) {
        this.parentView = options.parentView;
        this.addToStatic();
    },
});

export default MakeSearchStaticBannerView;