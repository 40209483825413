import { useGetters, useMutations } from 'vuex-composition-helpers';
import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';

import TouristGroupModel, { NAMESPACE, TouristGroupGetters, TouristGroupMutations } from '../model/TouristGroupStore';

export default (modelPrefix = '') => {
    registerModule.register(getStore(), TouristGroupModel, `${NAMESPACE}${modelPrefix}`);
    const { touristGroup, touristGroupText } = useGetters<TouristGroupGetters>(`${NAMESPACE}${modelPrefix}`, ['touristGroup', 'touristGroupText']);
    const {
        setAdults,
        setAges,
        setInfants,
        setKids,
    } = useMutations<TouristGroupMutations>(`${NAMESPACE}${modelPrefix}`, ['setAdults', 'setAges', 'setInfants', 'setKids']);

    return {
        setAdults,
        setAges,
        setInfants,
        setKids,
        touristGroup,
        touristGroupText,
    };
};