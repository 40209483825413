import { ChildServiceId } from '../types/IChildService';

export default (ids: number[]): ChildServiceId[] => {
    const result: ChildServiceId[] = [];
    if (ids.includes(229)) result.push(ChildServiceId.Animators);
    if (ids.includes(111)) result.push(ChildServiceId.Pool);
    if (ids.includes(163)) result.push(ChildServiceId.Menu);
    if (ids.includes(109)) result.push(ChildServiceId.Playroom);
    if (ids.includes(112)) result.push(ChildServiceId.Clubs);
    if (ids.includes(114) || ids.includes(246) || ids.includes(247)) result.push(ChildServiceId.Bed);
    if (ids.includes(113) || ids.includes(110)) result.push(ChildServiceId.Nanny);
    return result;
};
