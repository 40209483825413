/* eslint-disable */
import getStore from '@ve/services/getStore/getStore';
import CountryConditionsStore from './CountryConditionsStore';
import { CountryCondition } from '@ve/types';

const store = getStore();
const storeName = 'countryConditions';

store.registerModule(storeName, CountryConditionsStore);

const methods = {
    update: (countryConditions: CountryCondition[]) => {
        store.commit(`${storeName}/updateCountryConditions`, countryConditions);
    },
};

export default methods;