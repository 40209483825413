import { childServiceFilterMethods, restTypeFilterMethods, beachTypeFilterMethods } from '@features/hotel/filterHotels';
import FilterAttributeBeachLineMethods from '@ve/components/filters/beachLineFilter/filterMethods/FilterAttributeBeachLineMethods';
import FilterAttributeBeachOwnerMethods from '@ve/components/filters/beachOwnerFilter/filterMethods/FilterAttributeBeachOwnerMethods';
import FilterAttributeDistanceLiftMethods from '@ve/components/filters/distanceLiftFilter/filtersMethods/FilterAttributeDistanceLiftMethods';
import FilterAttributesMethodsNearCenter from '@ve/components/filters/nearCenterFilter/FilterAttributesMethodsNearCenter';
import FilterAttributeRatingReviewsMethods from '@ve/components/filters/ratingReviewsFilter/filterMethods/FilterAttributeRatingReviewsMethods';
import FacilitiesFilterAttributeMethods from '@ve/pages/serp/components/filters/facilitiesFilter/common/FacilitiesFilterAttributeMethods';
import FilterAttributeMethodsWaterActivities from '@ve/pages/serp/components/filters/filterWaterActivities/common/FilterMethodsWaterActivities';
import { type IFilterApplyingItem } from '../types';

const PreviousFilterApplyingItem: IFilterApplyingItem[] = [
    {
        id: 1,
        nameAction: 'action_typeFilterRating',
        nameState: 'typeFilterRating',
        filterAttributeMethod: FilterAttributeRatingReviewsMethods,
        valueState: 0,
    },
    {
        id: 2,
        nameAction: 'action_typeLines',
        nameState: 'typeLines',
        filterAttributeMethod: FilterAttributeBeachLineMethods,
        valueState: [],
    },
    {
        id: 3,
        nameAction: 'action_typeDistanceLift',
        nameState: 'typeDistanceLift',
        filterAttributeMethod: FilterAttributeDistanceLiftMethods,
        valueState: [],
    },
    {
        id: 4,
        nameAction: 'action_takenBeachType',
        nameState: 'takenBeachType',
        filterAttributeMethod: beachTypeFilterMethods,
        valueState: [],
    },
    {
        id: 5,
        nameAction: 'action_takenWaterActivities',
        nameState: 'takenWaterActivities',
        filterAttributeMethod: FilterAttributeMethodsWaterActivities,
        valueState: [],
    },
    {
        id: 6,
        nameAction: 'action_restType',
        nameState: 'restType',
        filterAttributeMethod: restTypeFilterMethods,
        valueState: 0,
    },
    {
        id: 7,
        nameAction: 'action_takenHotelAttributes',
        nameState: 'takenHotelAttributes',
        filterAttributeMethod: FilterAttributeBeachOwnerMethods,
        valueState: [],
    },
    {
        id: 8,
        nameAction: 'action_takenFacilities',
        nameState: 'takenFacilities',
        filterAttributeMethod: FacilitiesFilterAttributeMethods,
        valueState: [],
    },
    {
        id: 9,
        nameAction: 'action_takenHotelAttributes',
        nameState: 'takenHotelAttributes',
        filterAttributeMethod: FilterAttributesMethodsNearCenter,
        valueState: [],
    },
    {
        id: 10,
        nameAction: 'action_childServiceFilterIds',
        nameState: 'childServiceFilterIds',
        filterAttributeMethod: childServiceFilterMethods,
        valueState: [],
    },
];

export default PreviousFilterApplyingItem;