/* eslint-disable */
import './popupWrapper.css';
import template from './template.phtml';
import {propsDecorator} from "@/js/common/Domain/Service/mixins/decorators";

@propsDecorator({
	showCallback: null,
	closeCallback: null,
})
export default class PopupWrapperView extends Backbone.View {
	show() {
		$(".ui-dialog-content").dialog("close");
		this.$dialog.dialog("open");
	}

	hide() {
		this.$dialog.dialog("close");
	}

	refresh() {
		// change if you know how to make better
		$(window).trigger('resize');
	}

	destroy() {
		this.$dialog.dialog("destroy");
	}

	initPopup() {
		let self = this;

		this.$dialog.correctPopupPosition().dialog({
			width: this.width,
			autoOpen: false,
			dialogClass: (this.dialogClass ? this.dialogClass : '') + ' popupWrapper',
			modal: true,
			closeOnEscape: this.closeOnEscape,
			draggable: false,
			resizable: false,
			open() {
				self.showCallback ? self.showCallback() : null;
			},
			close() {
				self.closeCallback ? self.closeCallback() : null;
			}
		});

		this.$dialog.on('click', '.popupClose,.js-popup-close', function () {
			self.hide();
		});
		if(this.notClosePopupAfterBodyClick){
			return;
		}
		$('body').on("click", '.ui-widget-overlay', function (e) {
			if ($('.ui-dialog-content', e.target).is(self.$dialog)) {
				self.hide();
			}
		});
	}

	appendPopup() {
		let $content = this.$el.detach();

		this.$dialog = $(template());
		$('.content', this.$dialog).append($content);
		this.initPopup();
	}

	initialize(options) {
		this.closeOnEscape = options.closeOnEscape !== false;
		this.showCallback = options.showCallback || null;
		this.closeCallback = options.closeCallback || null;
		this.dialogClass = options.dialogClass || null;
		this.width = options.width || 1000;
		this.notClosePopupAfterBodyClick = options.notClosePopupAfterBodyClick || false;
		this.appendPopup();
	}
}