module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 waterActivities.forEach(function(item){ 
__p+='\n<li class="firstLine"\n    data-bind="toggle:any('+
((__t=(item.name ))==null?'':__t)+
'CountOverall,beforeToursLoading),classes:{disabled:all(not('+
((__t=(item.name ))==null?'':__t)+
'Count),not(beforeToursLoading))}">\n    <div class="filtersList__item">\n        <label class="checkbox labelTag '+
((__t=(item.name ))==null?'':__t)+
'">\n            <input type="checkbox" data-layer-name="'+
((__t=(item.title ))==null?'':__t)+
'">\n            <div class="checkboxStyle"><span class="icon icon-i16_check_thin"></span></div>\n            <span class="filtersList__item-label">\n                '+
((__t=(item.title ))==null?'':__t)+
'\n                ';
 if(item.description) { 
__p+='\n                    <span class="filtersHint withHint">\n                        <span class="hintPopover">\n                            '+
((__t=(item.description ))==null?'':__t)+
'\n                        </span>\n                    </span>\n                ';
} 
__p+='\n            </span> \n        </label>\n        <span class="filtersList__item-extra">ТОЛЬКО</span>\n    </div>\n</li>\n';
})
__p+='';
}
return __p;
};
