import { computed, unref } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';

const { useState, useActions } = createNamespacedHelpers<IMapForFiltersSerpState, any, IMapForFiltersSerpActions>(nameFilterSerpStore);

export default () => {
    const {
        isExclusive: selected,
        syncHotels,
        filteredHotels,
        beforeToursLoading,
    } = useState(['isExclusive', 'syncHotels', 'filteredHotels', 'beforeToursLoading']);

    const {
        action_isExclusive: updateIsExclusive,
    } = useActions(['action_isExclusive']);

    const visible = computed(() => unref(beforeToursLoading) || !!unref(syncHotels).find((hotel) => hotel.isExclusive));
    const count = computed(() => unref(filteredHotels).filter((hotel) => hotel.isExclusive).length);

    const disabled = computed(() => !unref(beforeToursLoading) && !(unref(selected) || unref(count)));

    const isFilterSelected = computed({
        get: () => unref(selected),
        set: (value: boolean) => {
            updateIsExclusive(value);
        },
    });

    return {
        selected,
        disabled,
        count,
        isFilterSelected,
        visible,
    };
};