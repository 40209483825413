/* eslint-disable */
import VK_async from '@/js/common/plugins/vk-async.min';

import vkTemplate from './vkTemplate.html';
import okTemplate from './okTemplate.html';
import twitterTemplate from './twitterTemplate.html';
import fbTemplate from './fbTemplate.html';

let SocialButtons = Backbone.Epoxy.View.extend({
	initVk() {
		this.$el.append(vkTemplate());
		VK_async.ready(function () {
			window.VK.init({apiId: 6055219, onlyWidgets: true});
			window.VK.Widgets.Like("vk_like44113", {type: "mini"});
		});
	},
	initTwitter() {
		this.$el.append(twitterTemplate());
		!function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (!d.getElementById(id)) {
				js = d.createElement(s);
				js.id = id;
				js.src = "https://platform.twitter.com/widgets.js";
				fjs.parentNode.insertBefore(js, fjs);
			}
		}(document, "script", "twitter-wjs");
	},
	initOk:function() {
		this.$el.append(okTemplate());
		!function (d, id, did, st) {
			var js = d.createElement("script");
			js.src = "//connect.ok.ru/connect.js";
			js.onload = js.onreadystatechange = function () {
				if (!this.readyState || this.readyState == "loaded" || this.readyState == "complete") {
					if (!this.executed) {
						this.executed = true;
						if(typeof(OK)!=="undefined") {
							OK.CONNECT.insertShareWidget(id,did,st);
						}
					}
				}};
			d.documentElement.appendChild(js);
		}(document,"ok_shareWidget",document.URL,"{width:170,height:30,st:'straight',sz:20,ck:3}");
	},
	initFb() {
		this.$el.append(fbTemplate());
	},
	initialize:function() {
		if(this.$el.length) {
			this.initVk();
			this.initTwitter();
			this.initOk();
			this.initFb();
		}
	}
});

export default SocialButtons;