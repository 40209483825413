import { extendDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';

@viewModelDecorator({
    defaults: {
        tourProduct: [],
    },

    computeds: {
        minPercent: {
            deps: ['tourProduct'],
            get(tourProduct) {
                return tourProduct?.firstPaymentDefinition?.firstPaymentPart || 0;
            },
        },

        minPrice: {
            deps: ['tourProduct'],
            get(tourProduct) {
                return (tourProduct?.tour?.get('priceWithOilTax') * this.get('minPercent')).formatNumber();
            },
        },

        minPriceText() {
            return `${this.get('minPrice')} руб.`;
        },

        minPercentText() {
            return `${Math.round(this.get('minPercent') * 100)}%`;
        },
    },
})
@extendDecorator({
    template: null,
    $dialog: null,
    bindings: {
        '.js-min-partial-payment-percent': 'text: minPercentText',
        '.js-min-partial-payment-price': 'text: minPriceText',
    },
    events: {
        'click .js-open-hotel-tour-dg': 'openPopup',
    },
})
export default class PartialPaymentTourBannerView extends Backbone.Epoxy.View {
    initialize(options = {}) {
        const { tourProducts, countryName } = options;

        this.viewModel.set('tourProduct', tourProducts?.at(0)?.toJSON());
        this.viewModel.set('tourProducts', tourProducts?.toJSON());
        this.viewModel.set('countryName', countryName);
        this.$el.html(this.template());
    }

    openPopup() {}
}
