import { Store } from 'vuex';
import { BaseState } from './getStore/types';

export default {
    register(store: Store<BaseState>, Module: any, name: string): void {
        if (!(store && store.state && store.state[name])) {
            store.registerModule(name, Module as any);
        } else {
            // re-use the already existing module
            console.log(`reusing module: ${name}`);
        }
    },
};