import { CheckInDateRange } from '@ve/types';
import errorHandlerService from '../../Service/errorHandler';
import CheckInDateRangeObjectValue from '../../ObjectValue/CheckInDateRange';
import createDateByFormat from './services/createDateByFormat';
import addFlexibleDaysToDate from './services/addFlexibleDaysToDate';
import correctDateByWrongBuild from './services/correctDateByWrongBuild';
import {
 TDateRangeParams, TCheckInDateRangeResult, TDateOptions, TDefaultDateRange,
} from '.';

export default function CheckInDateRangeFactory(date: TDateRangeParams, defaultDateParams: TDefaultDateRange | null, options: TDateOptions | undefined): Backbone.Model<CheckInDateRange<Date>> | null | undefined {
    try {
        const checkInDateRange: TCheckInDateRangeResult = {
            to: createDateByFormat(date.to || date.from),
            from: createDateByFormat(date.from || date.to),
        };
        const checkInDateRangeWithFlexibleDate = addFlexibleDaysToDate(checkInDateRange, date?.daysFlexible);
        const correctDate = correctDateByWrongBuild(checkInDateRangeWithFlexibleDate, defaultDateParams, options);
        if (!correctDate) {
            errorHandlerService(`JS Error! params functions - from: ${date?.from}, to: ${date?.to} get null`);
            return null;
        }
        return new CheckInDateRangeObjectValue(correctDate);
    } catch (error) {
        errorHandlerService('JS Error!', error);
        return null;
    }
}
