/* eslint-disable */
import {propsDecorator} from "@/js/common/Domain/Service/mixins/decorators";

@propsDecorator({
	defaults:{
		age: null,
		name: null
	}
})
export default class AgeModel extends Backbone.Epoxy.Model{

}