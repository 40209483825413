import { differenceInMinutes } from 'date-fns';
import { ISearchPageHotelCardStore } from './types/ISearchPageHotelCardStore';

export const NAMESPACE = 'searchPageHotelCardStore';

export default {
    namespaced: true,
    state: () => ({
        dateAfterSearchStarted: new Date(),
        minutesAfterSearchStarted: 0,
    }),
    mutations: {
        setDateAfterSearchStarted: (state, dateAfterSearchStarted) => {
            state.dateAfterSearchStarted = dateAfterSearchStarted;
        },
        setMinutesAfterSearchStarted: (state, minutesAfterSearchStarted) => {
            state.minutesAfterSearchStarted = minutesAfterSearchStarted;
        },
    },
    actions: {
        setMinutesAfterSearchStarted: ({ commit, state }, date) => {
            commit('setMinutesAfterSearchStarted', differenceInMinutes(date, state.dateAfterSearchStarted));
        },
    },
} as ISearchPageHotelCardStore;