/* eslint-disable max-classes-per-file */
import _ from 'underscore';
import Vue from 'vue';
import getFiltersSerpStore from '@ve/components/searchFilters/store/getFiltersSerpStore';
import SearchFormStore from '@ve/pages/serp/store/SearchFormStore';
import SearchDataStore from '@ve/pages/serp/store/SearchDataStore';
import MealsRepository from '@/js/common/Domain/Repository/Meals';
import ResortRepository from '@/js/common/Domain/Repository/Resorts';
import HotelCategoriesRepository from '@/js/common/Domain/Repository/HotelCategories';
import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import searchSessionService from '@/js/common/Domain/Service/search/searchSession';
import gaAsync from '@/js/common/Domain/Service/gaAsync';
import getStore from '@ve/services/getStore/getStore';
import { operatorRepositoryName } from '@entities/operator';
import RefreshLoaderWrapper from '@ve/components/refreshLoader/desktop/RefreshLoaderWrapper.vue';
import FilterCriteriaView from '@/js/desktop/components/filterCriteria/FilterCriteria';
import MakeSearchStaticBannerView from '@/js/desktop/components/search/makeSearchStaticBanner/MakeSearchStaticBanner';
import FixMapPositionView from '@/js/desktop/components/search/fixMapPosition/FixMapPosition';
import PaylateInformer from '@/js/desktop/components/search/paylate/paylateInformer/PaylateInformer';
import SystemInfoBenchmark from '@ve/services/SystemInfoBenchmark';
import SearchPageModel from '@/js/desktop/Domain/Model/SearchPageModel';
import SearchPageHotelView from '@/js/desktop/View/SearchPageHotel';
import trackingService from '@/js/common/Domain/Service/tracking/tracking';
import FilterCriteriaModel from '@/js/desktop/components/filterCriteria/FilterCriteriaModel';
import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import EmptyResultByFilters from '@ve/components/EmptyResultByFilters/EmptyResultByFilters.vue';
import PreviousFilterApplying from '@ve/pages/serp/components/previousFilterApplying/PreviousFilterApplying.vue';
import { getValuesFilterCriteriaByOld, getValuesFilterCriteriaByNew, getNewNameFieldsFilterCriteria } from '@/js/common/Domain/Service/AdaptersFilterCriteria';
import getPreviousFilterStore from '@ve/pages/serp/components/previousFilterApplying/store/getPreviousFilterStore';
import tourCriteriaService from '@/js/common/Domain/Service/tourCriteria';
import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';

const serpTrackingService = new SerpTrackingService();

@extendDecorator({
    arrayCriterias: ['operators', 'typeLines', 'takenHotelAttributes', 'typeDistanceLift'],
    model: null,
    events: {
        'click .searchContainer_filtersButton': 'toggleFilters',
        'click .banner-close': 'closeBanner',
    },
    filtersUpdateTimeout: null,
})
export default class SearchPageAbstract extends Backbone.Epoxy.View {
    initSubviews() {
        const filterCriteria = this.model.get('filterCriteria');

        this.filters = new FilterCriteriaView({
            model: filterCriteria,
            searchPageModel: this.model,
            searchFormViewModel: this.searchFormViewModel,
            dataLayerAnalytics: this.dataLayerAnalytics,
        });

        new MakeSearchStaticBannerView({
            parentView: this,
        });

        new FixMapPositionView({
            searchPageModel: this.model,
        });

        new PaylateInformer({
            searchPageModel: this.model,
        });

        this.initAdditionalViews();
        this.initVueComponents();
    }

    initDomEvents() {
        $(document).on('submit', '.thBlockForm', function (e) {
            $('.thFormSubmit', $(this).parent()).click();
            e.preventDefault();
            return false;
        });
        $('.formControl.selectHotel').on('click', () => {
            this.model.set('showHotelSearchTooltip', false);
            $.cookie('hotelSearchTooltipWasShoved', true);
        });
        this.beforeCloseInit();
    }

    getModelOptions({ searchFormViewModel }, hotelsCollection, filterCriteria, systemLogInfo) {
        return {
            filterCriteria,
            hotelsCollection,
            searchFormViewModel,
            systemLogInfo,
        };
    }

    initialize(options) {
        this.systemInfoBenchmark = new SystemInfoBenchmark();
        this.collection = new HotelsRepository();
        getStore({
            name: 'PageStore',
            storeOptions: {
                state: {
                    namePage: options?.namePage || 'search',
                },
            },
        });
        options = options || {};

        const filterCriteria = new FilterCriteriaModel();

        const ModelConstructor = options && options.SearchPageModel ? options.SearchPageModel : SearchPageModel;

        const constructorParams = this.getModelOptions(options, this.collection, filterCriteria, this.systemInfoBenchmark);

        this.model = new ModelConstructor(constructorParams);

        this.searchFormViewModel = options.searchFormViewModel;
        const { searchFormViewModel } = this;
        this.hotelView = options?.SearchPageHotelView
            ? options.SearchPageHotelView
            : SearchPageHotelView;

        class ItemView extends this.hotelView {
            initialize(...args) {
                this.searchFormViewModel = searchFormViewModel;
                super.initialize(...args);
            }
        }

        this.itemView = ItemView;
        this.dataLayerAnalytics = options.dataLayerAnalytics;
        this.withoutStatic = options.withoutStatic;

        this.viewModel = new Backbone.Epoxy.Model({
            filtersUpdating: false,
        });
        this.namePage = options.namePage;

        SearchFormStore({ model: this.searchFormViewModel });
        SearchDataStore({ model: this.model });

        this.initSubviews();
        this.initDomEvents();
        this.initSubscribes();
        this.initModelEvents();
        this.initMainColumnRightBlockHeightHandler();
    }

    closeBanner(e) {
        const { currentTarget } = e;
        const banerName = $(currentTarget).data('banner');
        const bannerPosition = $(currentTarget).data('index') + 1;
        const parent = currentTarget.parentElement;

        if (!parent) return;

        window.dataLayer.push({
            event: 'selectContent',
            selectContent:
                {
                    prop: [
                        { type: 'engageBanner', value: banerName },
                    ],
                    prop2: [
                        { type: 'position', value: bannerPosition },
                    ],
                },
        });

        $(parent).remove();
    }

    toggleFilters() {
        const $filtersContainer = $('.filters-container');
        if ($filtersContainer.hasClass('filtersOpened')) {
            $filtersContainer.removeClass('filtersOpened');
        } else {
            $filtersContainer.addClass('filtersOpened');
        }
    }

    HotelOnMapViewConstructor() {
        return null;
    }

    async changedViewMode(model, viewMode) {
        serpTrackingService.toggleMap();

        switch (viewMode) {
            case 'compact':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'View1', 'blocks', { nonInteraction: 1 });
                });
                break;
            case 'standart':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'View2', 'list', { nonInteraction: 1 });
                });
                break;
            case 'map':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'View3', 'map', { nonInteraction: 1 });
                });
                break;
            default: break;
        }

        const { searchFormViewModel } = this;
        class SearchPageHotelViewExtend extends this.hotelView {
            initialize(...args) {
                this.searchFormViewModel = searchFormViewModel;
                super.initialize(...args);
            }
        }

        if (viewMode === 'map' && !this.hotelsMap) {
            const HotelsMapView = await this.initHotelMapView();

            const HotelOnMapViewConstructor = this.HotelOnMapViewConstructor();
            const hotelOnMapView = new HotelOnMapViewConstructor();

            this.hotelsMap = new HotelsMapView({
                hotelMapView: hotelOnMapView,
                searchPageModel: this.model,
                HotelsList: SearchPageHotelViewExtend,
            });
        } else {
            setTimeout(() => {
                $('.js-images-carousel')
                    .jcarousel('reload', { animation: 'fast' });
            }, 1);
        }

        this.changeMainColumnRightHeight();

        this.scrollToTours();
        console.log(`changed viewMode to:${viewMode}`);
    }

    getFilterCriteriaByUrl(data) {
        let attributes = {};
        const allAtributes = {};
        const filterCriteria = this.model.get('filterCriteria');
        const filterCriteriaAttrbutes = filterCriteria.attributes;
        if (data && data.sid) {
            searchSessionService.setSearchId(data.sid);
        } else {
            searchSessionService.generateNewSearchId();
        }

        if (!data) {
            return null;
        }

        if (data.sort && ~_.indexOf(['recommend', 'priceUp', 'priceDown', 'ratingDown', 'reviewsDown'], data.sort)) {
            this.model.set('sortStrategy', data.sort);
        }

        _.each(data, (value, key) => {
            const attribute = key.replace('f_', '');
            allAtributes[attribute] = value;
            if (~this.arrayCriterias.indexOf(attribute) && attribute in filterCriteriaAttrbutes) {
                attributes[attribute] = _.map(value.split('.'), (id) => {
                    const intId = parseInt(id, 10);
                    return intId || null;
                });
            } else if (/^f_/.test(key) && attribute in filterCriteriaAttrbutes) {
                if (/^\d*$/.test(value)) {
                    value = parseInt(value, 10);
                }
                attributes[attribute] = value === 'true' ? true : value;
            }
        });
        attributes = {
            ...attributes,
            ...getValuesFilterCriteriaByOld(allAtributes),
        };

        return attributes;
    }

    setOtherCriterias(data) {
        const filterCriteria = this.model.get('filterCriteria');
        const attributes = this.getFilterCriteriaByUrl(data);
        filterCriteria.set(attributes, {
            initiator: 'loadFromUrl',
        });
    }

    isAttributeEnabled(attribute) {
        return !['isPopularityFilterActive'].includes(attribute);
    }

    getOtherCriterias() {
        const otherCriteria = {
            sid: searchSessionService.getSearchId(),
        };
        const sortStrategy = this.model.get('sortStrategy');
        let filterCriteria = this.model.get('filterCriteria');

        if (sortStrategy) {
            otherCriteria.sort = sortStrategy;
        }

        if (filterCriteria) {
            filterCriteria = this.model.get('filterCriteria').attributes;
            const extendFilterCriteria = {
                ...filterCriteria,
                ...getValuesFilterCriteriaByNew(filterCriteria),
            };
            _.each(extendFilterCriteria, (value, name) => {
                if (~this.arrayCriterias.indexOf(name) && value.length && getNewNameFieldsFilterCriteria.indexOf(name) === -1) {
                    otherCriteria[`f_${name}`] = value.join('.');
                } else if (!this.arrayCriterias.includes(name) && value && getNewNameFieldsFilterCriteria.indexOf(name) === -1 && this.isAttributeEnabled(name)) {
                    otherCriteria[`f_${name}`] = value;
                }
            });
        }
        return otherCriteria;
    }

    beforeCloseInit() {
        const mouseLeaveCallback = (e) => {
            if (e.clientY < 0 && !$('.ui-dialog:visible').length && this.model.get('loadingState') === 'finished') {
                if (this.model.get('regionType') === 'region' && $.cookie('beforeCloseSerpPopupShown') !== 'true' && !$('#onBeforeClose:visible').length) {
                    if (typeof (yaCounter11881159) !== 'undefined') {
                        yaCounter11881159.reachGoal('dontleavepopupopen');
                    }
                    $.cookie('beforeCloseSerpPopupShown', true, { path: '/', expires: 1, domain: 'all' });
                    $('body').off('mouseleave', mouseLeaveCallback);
                } else if ($.cookie('emailSubscribePopup') !== 'true' && document.location.pathname === '/search') {
                    $.cookie('emailSubscribePopup', true, { path: '/', expires: 1, domain: 'all' });
                    $('body').off('mouseleave', mouseLeaveCallback);
                }
            }
        };

        $('body').on('mouseleave', mouseLeaveCallback);
    }

    initModelEvents() {
        this.model.on('change:loadingState', (model, value) => {
            if (value === 'finished' && !$.cookie('hotelSearchTooltipWasShoved')) {
                this.model.set('showHotelSearchTooltip', true);
                setTimeout(() => {
                    $.cookie('hotelSearchTooltipWasShoved', true);
                    this.model.set('showHotelSearchTooltip', false);
                }, 3000);
            }
        });

        const filterCriteriaChanged = _.debounce((model, context) => {
            const renderTime = this.systemInfoBenchmark.getResult('serpToursProductsRenderTime');
            console.log(`%c products cards render time: ${renderTime}`, 'background: #222; color: #bada55');
            trackingService.trackSearchFilters({
                filters: this.filters,
                serpProductsRenderTime: renderTime,
                autoChange: context && _.isObject(context) && !_.isEmpty(context),
                filteredHotels: this.model.get('filteredHotels'),
                operatorsData: getStore().getters[`${operatorRepositoryName}/getEntities`],
            });
        }, 10);

        const filterCriteria = this.model.get('filterCriteria');
        filterCriteria.on('change', filterCriteriaChanged.bind(this));
    }

    initMainColumnRightBlockHeightHandler() {
        if (typeof ResizeObserver === 'undefined') {
            const mainColumnRightElement = document.querySelector('.mainColumnRight');
            mainColumnRightElement.classList.add('mainColumnRight_more-height');
        } else {
            this.initMainColumnLeftResizeObserver();
        }
    }

    changeMainColumnRightHeight() {
        const mainColumnLeftElement = document.querySelector('.mainColumnLeft');
        const mainColumnRightElement = document.querySelector('.mainColumnRight');
        if (mainColumnRightElement.offsetHeight < mainColumnLeftElement.offsetHeight) {
            document.querySelector('.mainColumnRight').setAttribute('style', `min-height: ${mainColumnLeftElement.offsetHeight}px`);
        }
    }

    initMainColumnLeftResizeObserver() {
        const mainColumnLeftElement = document.querySelector('.mainColumnLeft');
        this.mainColumnLeftObserver = new ResizeObserver(() => this.changeMainColumnRightHeight());
        this.mainColumnLeftObserver.observe(mainColumnLeftElement);
    }

    initSubscribes() {
        this.model.on('change:sortStrategy', this.changedSortStrategy.bind(this));
        this.model.on('change:viewMode', this.changedViewMode.bind(this));
        this.filters.model.on('change', this.changedFilters.bind(this));
    }

    pageInc() {
        if (this.model.get('pageIncAvaliable')) {
            this.model.set('pageNumber', this.model.get('pageNumber') + 1);
        }
        return false;
    }

    nothingFoundFormToggle() {
        this.model.set('nothingFoundFormOpened', !this.model.get('nothingFoundFormOpened'));
    }

    clearFilter(options) {
        const { currentModel } = options;
        if (!currentModel.get('countries')) return false;
        const filterCriteria = this.model.get('filterCriteria');
        const country = currentModel.get('countries').get('model').at(0);
        const departureCityId = currentModel.get('departureCity').get('model').get('id');
        const departureCityType = departureCityId === 1 || departureCityId === 2 ? 'capital' : 'region';
        const countryTourCriteria = country.get(`${departureCityType}TourCriteria`);

        if (!options.onlyPostfilters) {
            currentModel.get('hotelCategories').set('model', new HotelCategoriesRepository(HotelCategoriesRepository.repository.getById(countryTourCriteria.hotelCategories)), {
                initiator: 'clearFilter',
            });
            currentModel.get('meals').set('model', new MealsRepository(MealsRepository.repository.getById(countryTourCriteria.meals)), {
                initiator: 'clearFilter',
            });
        }

       filterCriteria.clearFilters();

        return false;
    }

    scrollToTours() {
        const element = $('.searchContainer_navbar');
        const currenPosition = $(document).scrollTop();
        const headerHeight = 176;
        const menuHeight = $('.menuContainer').height();
        let scrollHotellistPosition;

        if (element.length) {
            scrollHotellistPosition = $(element).offset().top;

            scrollHotellistPosition -= headerHeight;

            if (menuHeight) {
                scrollHotellistPosition -= menuHeight;
            }

            scrollHotellistPosition -= 10;

            if (currenPosition > scrollHotellistPosition) {
                $('html,body').stop(true, false).animate({ scrollTop: scrollHotellistPosition }, 700);
            }
        }
    }

    scrollToTop() {
        const currenPosition = $(document).scrollTop();
        //scrollHotellistPosition = $('.mainColumnRight').offset().top;

        if (currenPosition > 0) {
            $('html,body').stop(true, false).animate({ scrollTop: 0 }, 700);
        }
    }

    changedFilters() {
        clearTimeout(this.filtersUpdateTimeout);
        this.viewModel.set('filtersUpdating', true);
        this.filtersUpdateTimeout = setTimeout(() => {
            this.viewModel.set('filtersUpdating', false);
        }, 1000);
    }

    changedSortStrategy(model, sortDirection) {
        const filteredHotels = this.model.get('filteredHotels');

        switch (sortDirection) {
            case 'recommend':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'Recommended', 'a-z', { nonInteraction: 1 });
                });
                break;
            case 'priceUp':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'Price', 'a-z', { nonInteraction: 1 });
                });
                break;
            case 'priceDown':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'Price', 'z-a', { nonInteraction: 1 });
                });
                break;
            case 'ratingDown':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'Guest_Rating', 'z-a', { nonInteraction: 1 });
                });
                break;
            case 'reviewsDown':
                gaAsync(() => {
                    ga('send', 'event', 'Sorting', 'Guest_ReviewsCount', 'z-a', { nonInteraction: 1 });
                });
                break;
            default: break;
        }

        this.model.set('sortStrategyChanged', true);
        if (filteredHotels.length) {
            this.model.set('pageNumber', 1, { silent: true });
            this.collection.reset();
            this.model.get('filteredHotels').sort();
            this.model.trigger('change:filteredHotels');
        }
        this.model.set('sortStrategyChanged', false);

        console.log(`changed SortStrategy to:${sortDirection}`);
    }

    initFilters() {
        const store = getFiltersSerpStore({
            filtersModel: this.model.get('filterCriteria'),
            searchFormViewModel: this.searchFormViewModel,
            searchPageModel: this.model,
            mealsRepository: MealsRepository.repository,
        });
        this.initFiltersPreviousApplying();
        const filtersComponent = this.initFiltersComponent(store);
        const emptyFiltersComponent = this.initEmptyResultFiltersComponent(store);
        filtersComponent.$on('meals-filter-changed', (meals) => {
            const newMeals = new MealsRepository(MealsRepository.repository.getById(meals));
            newMeals.manuallyChanged = true;
            this.searchFormViewModel.get('meals').set('model', newMeals, { initiator: 'view' });
            tourCriteriaService.addTourCriteriaChangedField('meals');
        }).$on('hotel-categories-filter-changed', (hotelCategoties) => {
            const newHotelCategories = new HotelCategoriesRepository(HotelCategoriesRepository.repository.getById(hotelCategoties));
            newHotelCategories.manuallyChanged = true;
            this.searchFormViewModel.get('hotelCategories').set('model', newHotelCategories, { initiator: 'view' });
            tourCriteriaService.addTourCriteriaChangedField('hotelCategories');
        });
        filtersComponent.$on('input-filter-hotels-search', async (words) => {
            try {
                const currentTourCriteria = this.searchFormViewModel.getTourCriteria().clone();
                currentTourCriteria.set('hotels', new HotelsRepository([]));
                const hotels = await HotelsRepository.repository.getHotelsNew({
                    tourCriteria: currentTourCriteria,
                    term: words,
                    pageType: this.namePage,
                });
                store.commit('mapForFiltersSerp/setHotelByFilters', hotels);
            } catch (error) {
                store.commit('mapForFiltersSerp/setHotelByFilters', []);
            }
        }).$on('start-search-by-filter-search', (hotels) => { // Для фильтра поиска требуется запускать поиск с новыми критериями, но эти критерии не должны попадать в модели основого поиска
            const modelsHotels = HotelsRepository.repository.getHotelsBuild(hotels);
            const tourCriteria = this.searchFormViewModel.getTourCriteria().clone(); // Копируется критерии поиска тура
            tourCriteria.set('hotels', new Backbone.Collection([])); // Делаем новую ссылку на массив, так как ссылка у двух моделей критериев одинаковая
            modelsHotels.forEach((hotelModel) => tourCriteria.get('hotels').add(hotelModel));
            this.filters.showPreviousFilters();
            this.searchFormViewModel.trigger('goAsyncSearch', tourCriteria);
        });

        filtersComponent.$on('change-resorts-by-filter', (resortsIdsArray) => {
            const resorts = ResortRepository.repository.getById(resortsIdsArray);
            resorts.manuallyChanged = true;
            this.searchFormViewModel.get('resorts').set('model', new ResortRepository(resorts), { initiator: 'view' });
        });

        filtersComponent.$on('start-search-by-quick-confirmation', (isQuickConfirmation) => {
            this.searchFormViewModel.get('warranted').set('model', isQuickConfirmation, { initiator: 'view' });
        });

        filtersComponent.$on('start-search-by-direct-flight-filter', (isDirectFlightFilter) => {
            this.searchFormViewModel.get('directFlight').set('model', isDirectFlightFilter, { initiator: 'view' });
        });

        emptyFiltersComponent.$on('reset-filters-to-default', () => {
            this.model.get('filterCriteria').clearFilters();
        });
    }

    initRefreshLoader() {
        const store = getStore({
            name: 'RefreshLoaderWrapper',
            backboneMap: [{
                fields: [{ show: 'filtersUpdating' }],
                model: this.viewModel,
            }],
        });
        this.refreshLoaderVueComponent = new RefreshLoaderWrapper({
            store,
            propsData: {
                storeModuleName: 'RefreshLoaderWrapper',
                showPropName: 'show',
            },
        }).$mount();
        $('footer').append(this.refreshLoaderVueComponent.$el);
    }

    initAdditionalViews() {
    }

    initFiltersComponent(store) {
    }

    initFiltersPreviousApplying() {
        const store = getPreviousFilterStore(this.model);
        return new Vue({
            render: (h) => h(PreviousFilterApplying),
            store,
        }).$mount('#previous-filter-applying');
    }

    initVueComponents() {
    }

    initHotelMapView() {
    }

    initEmptyResultFiltersComponent(store) {
        return new Vue({
            render: (h) => h(EmptyResultByFilters),
            store,
        }).$mount('#js-hook-empty-result-filters');
    }
}