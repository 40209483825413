module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="js-lottery-dg ypopup" style="display: none">\n	<b class="lotteryDg__bgLayer" style="background-image:url(\''+
((__t=( image ))==null?'':__t)+
'\')"></b>\n\n	<div class="popupClose"><i class="icon icon-i16_x"></i></div>\n	<div class="lotteryDg__content">\n		<div class="lotteryDg__title">Розыгрыш от Travelata<p>Выиграйте тур мечты '+
((__t=(direction ))==null?'':__t)+
'!\n			</p>\n		</div>\n	</div>\n	<div class="lotteryDg__sale">Скидку <span>'+
((__t=(couponAmount ))==null?'':__t)+
' руб.</span> получит каждый</div>\n	<div class="lotteryDg__vipTourLabel"></div>\n	<div class="lotteryDg__footer">\n		<div class="lotteryDg__hotel-info">\n			<div class="lotteryDg__rating"><i class="lotteryDg__rating-likeIcon icon-i16_like"></i> '+
((__t=(hotel.rating ))==null?'':__t)+
'\n				<div class="lotteryDg__rating-reviewsCount">'+
((__t=(hotel.reviewsQty ))==null?'':__t)+
'\n					отзыв'+
((__t=(hotel.reviewsQty.wordEnding(';а;ов') ))==null?'':__t)+
'</div>\n				<div class="lotteryDg__rating-luxury">luxury</div>\n			</div>\n			<div class="lotteryDg__hotel-title">';
 if(hotel.stars) { 
__p+='<div class="lotteryDg__hotel-stars">\n					';
 for(var i=1; i<= hotel.stars; i++) { 
__p+='<i class="icon icon-i16_features"></i>';
 } 
__p+='</div>';
 } 
__p+='\n				<p>'+
((__t=( hotel.name ))==null?'':__t)+
'</p>\n				<div class="lotteryDg__hotel-resort">'+
((__t=( hotel.countryName ))==null?'':__t)+
', '+
((__t=( hotel.cityName ))==null?'':__t)+
'</div>\n			</div>\n		</div><a href="'+
((__t=(checkoutLink ))==null?'':__t)+
'" target="_blank"\n			class="lotteryDg__footer-link">Принять<br>участие<span>Стоимость: '+
((__t=( price ))==null?'':__t)+
' руб.</span></a>\n	</div>\n</div>';
}
return __p;
};
