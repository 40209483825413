export default [
    25000,
    50000,
    75000,
    100000,
    150000,
    200000,
    250000,
    350000,
    500000,
    650000,
    800000,
    1000000,
    1500000,
    3000000,
    6000000,
];