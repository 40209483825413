import ThFavoritesViewAbstract from '@/js/common/components/thFavorites/thFavoritesAbstract';
import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';

export default class thFavorites extends ThFavoritesViewAbstract {
    submitTourHunter() {
        const tourCriteria = this.getTourCriteria();

        if (!this.viewModel.get('requestInProgress')) {
            this.trackTourHunters(tourCriteria);
        }
        super.submitTourHunter();
   }

   trackingClickTh(clickType) {
        const serpTrackingService = new SerpTrackingService();
        const isUserAuth = this.viewModel.get('viewModelAuthorized');

        serpTrackingService.hotelThClick(isUserAuth, clickType);
   }
}
