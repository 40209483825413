/* eslint-disable */
import ChildesAge from "@/js/common/Domain/Repository/ChildesAge";

export default function childesAgeFactory(age) {
	let childesAge = new ChildesAge();
	let model = childesAge.get("collection")?.findWhere?.(age);
	if (model) {
		childesAge?.get("model")?.set({
			age: model.get('age'),
			name: model.get('name')
		})
	}
	return childesAge
}