import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
import { IFilterApplyingItem } from '../types';

export default function (stateFiltersCriteria: IMapForFiltersSerpState | null, previousFilterApplyingItems: IFilterApplyingItem[]): IFilterApplyingItem[] {
    if (!stateFiltersCriteria) return [];
    const filterApplyingItemByTakenFilters = previousFilterApplyingItems.filter((applyingAttribute) => {
        const valueState = stateFiltersCriteria[applyingAttribute.nameState];
        if (Array.isArray(valueState)) {
            return !!valueState.length;
        }
        return !!valueState;
    });
    const applyingItemWithValueState = filterApplyingItemByTakenFilters.map((applyingAttribute) => {
        const valueState = stateFiltersCriteria[applyingAttribute.nameState];
        if (Array.isArray(valueState)) {
            return {
                ...applyingAttribute,
                valueState: valueState.filter((id) => !!applyingAttribute.filterAttributeMethod.getAttributeById(id)),
            };
        }
        return {
            ...applyingAttribute,
            valueState,
        };
    });
    return applyingItemWithValueState;
}