/* eslint-disable */
var funnelPage = window.funnelPage,
	pageType;

function getPage() {
	var location = window.location.pathname,
		reg = /.html/,
		staticPage = location.substr(1, location.search(reg)-1);

	staticPage = staticPage ? staticPage : "unknown-page";
	return staticPage;
}

let getPageTypeService = function() {
	if (funnelPage) {
		pageType = funnelPage;
	} else {
		pageType = getPage();
	}

	return pageType;
};

export default getPageTypeService;