import { childServiceFilterMethods, type ChildServiceFilterItem, beachTypeFilterMethods, type BeachTypeFilterItem } from '@features/hotel/filterHotels';
import { filterAttributeHotelMethodsByAll } from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsHotelBoolean/FilterAttributeHotelMethodsByAll';
import { type IFilterFacility } from '@fr/vue/pages/serp/components/filters/facilitiesFilter/common';
import facilitiesFilterAttributeMethods from '@fr/vue/pages/serp/components/filters/facilitiesFilter/common/FacilitiesFilterAttributeMethods';
import FilterAttributeBeachLineMethods from '@ve/components/filters/beachLineFilter/filterMethods/FilterAttributeBeachLineMethods';
import {
 type IBeachLine, type IAttributeHotel, type IReviewRating, type IFilterCriteriaModel, type IWaterActivities,
} from '@ve/components/filters/common/types';
import FilterAttributeRatingReviewsMethods from '@ve/components/filters/ratingReviewsFilter/filterMethods/FilterAttributeRatingReviewsMethods';
import filterAttributeMethodsWaterActivities from '@ve/pages/serp/components/filters/filterWaterActivities/common/FilterMethodsWaterActivities';

type TFilterCriteriaOld = IBeachLine['key']
    | IAttributeHotel['key'] | IReviewRating['key']
    | BeachTypeFilterItem['key'] | IWaterActivities['key']
    | IFilterFacility['key'] | ChildServiceFilterItem['key'];

type IFilterCriteriaModelLimitation = Pick<IFilterCriteriaModel, 'takenHotelAttributes' | 'typeLines' | 'typeFilterRating' | 'takenBeachType' | 'takenWaterActivities' | 'takenFacilities' | 'childServiceFilterIds'>

//функция принимает старый интерфейс фильтров и преобразовывает в новый интерфейс
export function getValuesFilterCriteriaByOld(filterCriteria: Record<TFilterCriteriaOld, boolean>): IFilterCriteriaModelLimitation {
    if (!filterCriteria) return {} as IFilterCriteriaModelLimitation;
    const idsAttributeHotel = filterAttributeHotelMethodsByAll.getIdsAttributesByObjectKeys(filterCriteria);
    const idsBeachLine = FilterAttributeBeachLineMethods.getIdsAttributesByObjectKeys(filterCriteria);
    const idsRatingReviews = FilterAttributeRatingReviewsMethods.getIdsAttributesByObjectKeys(filterCriteria);
    const idsBeachType = beachTypeFilterMethods.getIdsAttributesByObjectKeys(filterCriteria);
    const idsWaterActivities = filterAttributeMethodsWaterActivities.getIdsAttributesByObjectKeys(filterCriteria);
    const idsFacilities = facilitiesFilterAttributeMethods.getIdsAttributesByObjectKeys(filterCriteria);
    const idsServiceChild = childServiceFilterMethods.getIdsAttributesByObjectKeys(filterCriteria);

    return {
        takenHotelAttributes: idsAttributeHotel,
        typeLines: idsBeachLine,
        takenBeachType: idsBeachType,
        typeFilterRating: idsRatingReviews[0] ? idsRatingReviews[0] : 0,
        takenWaterActivities: idsWaterActivities,
        takenFacilities: idsFacilities,
        childServiceFilterIds: idsServiceChild,
    };
}

//функция принимает новый интерфейс фильтров и преобразовывает в старый интерфейс
export function getValuesFilterCriteriaByNew(filterCriteriaNew: IFilterCriteriaModel): Record<TFilterCriteriaOld, boolean> {
    if (!filterCriteriaNew) return {} as Record<TFilterCriteriaOld, boolean>;

    const valuesAttributeHotelOld = filterAttributeHotelMethodsByAll.getObjectKeysAttributesByIds(filterCriteriaNew?.takenHotelAttributes);
    const valuesAttributeBeachLineOld = FilterAttributeBeachLineMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.typeLines);
    const valuesAttributeRatingReviewsOld = FilterAttributeRatingReviewsMethods.getObjectKeysAttributesByIds([filterCriteriaNew?.typeFilterRating]);
    const valuesAttributesBeachTypeOld = beachTypeFilterMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.takenBeachType);
    const valuesAttributesWaterActivitiesOdl = filterAttributeMethodsWaterActivities.getObjectKeysAttributesByIds(filterCriteriaNew?.takenWaterActivities);
    const valuesAttributesFacilitiesOld = facilitiesFilterAttributeMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.takenFacilities);
    const valuesAttributesChildServiceOld = childServiceFilterMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.childServiceFilterIds);

    return {
        ...valuesAttributeHotelOld,
        ...valuesAttributeBeachLineOld,
        ...valuesAttributeRatingReviewsOld,
        ...valuesAttributesBeachTypeOld,
        ...valuesAttributesWaterActivitiesOdl,
        ...valuesAttributesFacilitiesOld,
        ...valuesAttributesChildServiceOld,
    };
}

export function getValuesOldFilterByValuesNewFilter(filterCriteriaNew: IFilterCriteriaModel): Record<IFilterFacility['key'], boolean> {
    if (!filterCriteriaNew) return {} as Record<TFilterCriteriaOld, boolean>;

    const valuesAttributesFacilitiesOld = facilitiesFilterAttributeMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.takenFacilities);
    const valuesAttriibutesWaterActivities = filterAttributeMethodsWaterActivities.getObjectKeysAttributesByIds(filterCriteriaNew?.takenWaterActivities);
    const valuesAttributesChildServiceOld = childServiceFilterMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.childServiceFilterIds);
    const valuesAttributesBeachTypeOld = beachTypeFilterMethods.getObjectKeysAttributesByIds(filterCriteriaNew?.takenBeachType);

    return {
        ...valuesAttributesFacilitiesOld,
        ...valuesAttriibutesWaterActivities,
        ...valuesAttributesChildServiceOld,
        ...valuesAttributesBeachTypeOld,
    };
}

export const getNewNameFieldsFilterCriteria: Array<keyof IFilterCriteriaModelLimitation> = ['takenHotelAttributes', 'typeLines', 'typeFilterRating', 'takenBeachType', 'takenWaterActivities', 'takenFacilities', 'childServiceFilterIds'];
