import {
    computed, ComputedRef, unref,
} from 'vue';
import { useState } from 'vuex-composition-helpers';
import { IMapForSearchDataState } from '@ve/pages/serp/store';
import _ from 'underscore';

import { NAMESPACE as MAP_FOR_SEARCH_DATA } from '@ve/pages/serp/store/SearchDataStore';

export default (): {
    isPairOfDepartureCityAndArrivalCountryAreValid: ComputedRef<boolean>
} => {
    const { searchDepartureCity, searchCountries } = useState<IMapForSearchDataState>(MAP_FOR_SEARCH_DATA, ['searchDepartureCity', 'searchCountries']);

    const searchDepartureCityValue = computed(() => unref(searchDepartureCity));
    const currentCountryId = computed(() => unref(searchCountries)[0].id);
    const isPairOfDepartureCityAndArrivalCountryAreValid = computed(() => {
        if (unref(searchDepartureCityValue).countriesWithDirectFlights && unref(currentCountryId)) {
            return _.contains(unref(searchDepartureCityValue).countriesWithDirectFlights, unref(currentCountryId));
        }
        return false;
    });

    return {
        isPairOfDepartureCityAndArrivalCountryAreValid,
    };
};