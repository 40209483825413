/* eslint-disable */
const promiseWrapper = (request) => {
    return new Promise((resolve, reject) => {
        request.done((response) => {
            resolve(response);
        });

        request.fail((error) => {
            reject(error);
        });
    });
};

/**
 * Метод для получения cover изображения страны по id
 * @param id - ид страны
 */
export const getCountryCover = (id) => {
    const getCover = $.get(`${window?.appConfig?.gatewayUrl}/apiV1/country/getCover`, {
        id,
    });

    return promiseWrapper(getCover);
};

/**
 * Метод для получения cover изображения курорта по id
 * @param id - ид страны
 */
export const getResortCover = (id) => {
    const getCover = $.get(`${window?.appConfig?.gatewayUrl}/apiV1/resort/getCover`, {
        id,
    });

    return promiseWrapper(getCover);
};

/**
 * Метод для получения cover изображения отеля по id
 * @param id - ид отеля
 */
export const getHotelCover = (id) => {
    const getCover = $.get(`${window?.appConfig?.gatewayUrl}/apiV1/hotel/getCover`, {
        id,
    });

    return promiseWrapper(getCover);
};
