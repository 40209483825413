import Vue from 'vue';
import DepartureCitiesRepository from '@/js/common/Domain/Repository/DepartureCities';
import registerModule from '@ve/services/registerModule';
import AlternativeSearchStore, { NAMESPACE as ALTERNATIVE_SEARCH_STORE_NAME } from './store/AlternativeSearchStore';

export default (store, context, renderComponent) => {
    registerModule.register(
        store,
        AlternativeSearchStore,
        ALTERNATIVE_SEARCH_STORE_NAME,
    );

    DepartureCitiesRepository.repository.on('add', _.debounce(() => {
        const departureCities = DepartureCitiesRepository.repository.map((departureCity) => ({
           id: departureCity.get('id'),
           name: departureCity.get('name'),
        }));
        store.commit(`${ALTERNATIVE_SEARCH_STORE_NAME}/setDepartureCities`, departureCities);
     }, 1));

    new Vue({
        name: 'AlternativeSearch',
        el: '.js-alternative-departure',
        render: (h) => h(renderComponent, {
            on: {
                startAlternativeSearch: (dataCityId) => {
                    if (dataCityId) {
                        const departureCityNew = DepartureCitiesRepository.repository.get(dataCityId);
                        const tourCriteria = context.model.getTourCriteria().clone();
                        tourCriteria.set('departureCity', departureCityNew);
                        console.log('Запуск Альтернативного поиска');
                        context.goSearch(tourCriteria);
                    }
                },
            },
        }),
        store,
    });
};
