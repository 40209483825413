import { IDistanceLift } from '../types';

const dataDistanceLift = [
    {
        id: 1,
        name: 'Ski in — прямо у входа',
        key: 'rightEntrance',
        keyMerged: 'rightEntrance',
    },
    {
        id: 2,
        name: 'Пешая доступность — до 500 м',
        key: 'walkingDistance',
        keyMerged: 'walkingDistance',
    },
    {
        id: 3,
        name: 'Автобус — от 500 м',
        key: 'busDistance',
        keyMerged: 'busDistance',
    },
] as IDistanceLift[];

export default dataDistanceLift;