/* eslint-disable */
import './customSelect.scss';
import {propsDecorator, viewModelDecorator} from "@/js/common/Domain/Service/mixins/decorators";

@viewModelDecorator({
	defaults: {
		labelValue: "",
		labelTitle: "",
		defaultLabelValue: "",
		open: false,
		inputValue: "",
		foundResult: false,
		firstStr: "",
		lastStr: "",
		borderType: "",
		requiredValue: true,
		showCloseIcon: false,
		showArrow: false,
		labelIconHtml: "",
		isDisabled: false,
	},
	computeds: {
		linkText: {
			deps: ['labelValue', 'defaultLabelValue', 'labelIconHtml'],
			get(labelValue, defaultLabelValue, labelIconHtml) {
				return labelIconHtml + (labelValue || defaultLabelValue)
			}
		},
		borderClass: {
			deps: ['borderType'],
			get(borderType) {
				return "customSelect__form " + borderType
			}
		},

		defaultLabelChanged: {
			deps: ['defaultLabelValue', 'labelValue'],
			get(defaultLabelValue, labelValue) {
				return !!labelValue ? false : !!defaultLabelValue
			}
		}
	}
})
@propsDecorator({
	getCollection: null,
	bindings: {
		".customSelect__container": "classes: {open:open, disabled: isDisabled}",
		".customSelect__form-title a": "html:linkText, classes:{'customSelect__is-default': defaultLabelChanged}",
		".customSelect__form-title span": "text:labelTitle",
		".customSelect__input-first": "text:firstStr",
		".customSelect__input-last": "text:lastStr",
		".customSelect__input": "toggle:open",
		"input": "value:inputValue",
		".customSelect__input i.icon-i16_close": "toggle:showCloseIcon",
		".customSelect__list": "toggle:open",
		".customSelect__form-title .icon-i16_arrow_down": "toggle:showArrow",
		".customSelect__form": "attr:{class:borderClass}"
	},

	events: {
		"click .customSelect__form-title a": "openSelect",
		"click .customSelect__form-title .icon-i16_arrow_down": "openSelect",
		"click i.icon-i16_close": "clearInput",
		"input input": "generateSelect"
	}
})
export default class CustomSelectView extends Backbone.Epoxy.View {
	initialize(options = {}) {
		this.updateDefaultsValue(options);
		this.initModelEvents();
		this.initDOMEvents();
		this.renderAfterInitialize(this.model?.get("model")?.get('name'));
		if (this.model?.get("model")?.get('name')) {
			this.setModelValue(this.model, this.model.get("model"))
		}
	}

	updateDefaultsValue(options) {
		if (options.hasOwnProperty("labelTitle")) {
			this.viewModel.set({labelTitle: options.labelTitle})
		}
		if (options.hasOwnProperty("getCollection")) {
			this.getCollection = options.getCollection
		}
		if (options.hasOwnProperty("defaultLabelValue")) {
			this.viewModel.set({defaultLabelValue: options.defaultLabelValue})
		}
		if (options.hasOwnProperty("requiredValue")) {
			this.viewModel.set({requiredValue: options.requiredValue})
		}
		if (options.hasOwnProperty("arrow")) {
			this.viewModel.set({showArrow: options.arrow})
		}
		if (options.hasOwnProperty("openCallback")) {
			this.openCallback = options.openCallback
		}
		if (options.hasOwnProperty("closeCallback")) {
			this.closeCallback = options.closeCallback
		}
	}

	initModelEvents() {
		// получение данных
		let timeout,
			self = this;
		this.model.get('collection').on('add reset', function () {
			clearTimeout(timeout);
			timeout = setTimeout(function () {
				self.generateSelect();
			}, 1)
		});
		this.model.on('change:model', $.proxy(this.setModelValue, this));
	}

	initDOMEvents() {
		let self = this;
		$(document).on("click", function (e) {
			let $el = self.$el.children();
			if (!$(e.target).closest($el).length) {
				self.closeSelect();
			}
		})
	}

	set disabled(isDisabled) {
		if (!this.viewModel.get("open")) {
			this.viewModel.set({isDisabled})
		}
	}

	setModelValue(model, data) {
		let self = this;
		// внесение в модель выбранной строки
		self.viewModel.set("labelValue", data ? data.get("name") : "");
		self.model.set('model', data, {initiator: 'view'});
		if (!data) {
			this.clearInput();
		}
	}

	generateSelect(name) {
		let self = this,
			collection = self.model.get('collection'),
			value = self.$el.find("input").val(),
			$list = [],
			filteredCollection = [],
			reg = new RegExp("^" + value, "i");
		if (collection?.length) {
			collection.forEach(function (model) {
				if (reg.test(model.get("name"))) {
					$list.push(generateRow(model));
					filteredCollection.push(model)
				}
			})
		}
		self.renderSelect($list, filteredCollection[0], value || name);

		function generateRow(model) {
			let clickRow = function Row() {
			};
			clickRow.model = model;
			return $("<div/>", {
				class: "customSelect__row",
				text: model.get("name"),
				click: function () {
					let model = clickRow.model;
					self.closeSelect();
					self.setModelValue(null, model);
					self.viewModel.set({
						inputValue: model.get("name"),
						firstStr: "",
						lastStr: ""
					});
				}
			})
		}
	}

	renderSelect($list, firstElement, value) {
		let self = this,
			$container = self.$el.find(".js-rows-list"),
			firstStr = "",
			lastStr = "",
			borderType = "",
			name = "";

		if (!$list.length) {
			$list.push($("<div/>", {
				class: "customSelect__row customSelect__row-clear",
				text: "Ничего не найдено"
			}));
			borderType = "notFound";
		} else if (value) {
			name = firstElement.get("name");
			firstStr = name.substr(0, value.length);
			lastStr = name.substr(value.length);
			borderType = "found"
		}
		if (!self.viewModel.get("requiredValue") && !value) {
			self.model.set("model", null, {});
		}
		self.viewModel.set({
			foundResult: !!$list.length,
			firstStr: firstStr,
			lastStr: lastStr,
			showCloseIcon: !!value,
			borderType: borderType
		});

		$container.html($list);

	}

	openSelect(e) {
		e.preventDefault();
		if (this.viewModel.get("isDisabled")) return;
		if (this.getCollectionLength()) {
			this.viewModel.set({
				open: true,
				showCloseIcon: !!this.viewModel.get("inputValue")
			});

		} else if (this.getCollection) {
			this.getCollection()({
				success: $.proxy(this.getCollectionCallback, this)
			})
		}
		this.openCallback?.()
	}

	openCallback() {
		//abstract method
	}

	closeCallback() {
		//abstract method
	}

	getCollectionCallback(data) {
		if (data?.length) {
			this.model.get("collection").reset(data.models);
			this.generateSelect();
			this.viewModel.set("open", true)
		}
	}

	closeSelect() {
		if (this.viewModel.get("open")) {
			this.viewModel.set("open", false);
			this.closeCallback?.()
		}
	}

	clearInput() {
		this.viewModel.set("inputValue", "");
		this.generateSelect();
	}

	renderAfterInitialize(name) {
		if (this.getCollectionLength()) {
			this.generateSelect(name)
		}
	}

	getCollectionLength() {
		return !!(this.model?.get("collection")?.length)
	}

	setLabelIconHtml(html) {
		this.viewModel.set({
			labelIconHtml: html
		})
	}
}