import {
 computed, watch, ref, unref,
} from 'vue';
import { useCountriesRepository } from '@entities/country';
import { useTourProductsRepository } from '@entities/tourProduct';
import {
    useResortsRepository, mapResorts, processResortsAdapters, IBackboneResort,
} from '@entities/resort';
import { useHotelsRepository, mapHotel } from '@entities/hotel';
import { useOperatorRepository } from '@entities/operator';
import { useRoomsRepository } from '@entities/room';

import ResortsRepositoryBackbone from '@/js/common/Domain/Repository/Resorts';
import createTourProducts from './createTourProducts';

export default function () {
    const { countries } = useCountriesRepository();
    const { resorts, setResorts } = useResortsRepository();
    const { hotels } = useHotelsRepository();
    const { tourProducts } = useTourProductsRepository();
    const { operators } = useOperatorRepository();
    const { rooms } = useRoomsRepository();

    const searchedResorts = computed(() => mapResorts(unref(resorts), unref(countries)));

    const collectedHotels = computed(() => mapHotel(unref(hotels), unref(countries), unref(searchedResorts)));

    const collectedTourProducts = computed(() => createTourProducts(unref(tourProducts), unref(operators), unref(collectedHotels), unref(rooms)));

    ResortsRepositoryBackbone.repository.on('add', (data: { collection: Backbone.Collection<IBackboneResort> }) => {
        setResorts(processResortsAdapters(data.collection.models));
    });

    return {
        searchedTourProducts: collectedTourProducts,
    };
}