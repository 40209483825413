import { IHotel } from '@entities/hotel';
import { IOperator } from '@entities/operator';
import { IRawRoom } from '@entities/room';
import { TourProduct, IRawTourProduct } from '@entities/tourProduct';

export default function (tourProducts: IRawTourProduct[], operators: IOperator[], hotels: IHotel[], rooms: IRawRoom[]): TourProduct[] {
    return tourProducts.reduce((acc, tourProduct) => {
        const tourProductOperator = operators.find((operator) => operator.id === tourProduct.operator);
        const tourProductHotel = hotels.find((hotel) => hotel.id === tourProduct.hotel);
        const tourProductRoom = rooms.find((room) => room.id === tourProduct.room?.id);
        const tourProductModel = new TourProduct(tourProduct);
        tourProductModel.setHotel(tourProductHotel || null);
        tourProductModel.setOperator(tourProductOperator || null);
        tourProductModel.setRoom(tourProductRoom || null);
        acc.push(Object.freeze(tourProductModel));
        return acc;
    }, [] as TourProduct[]);
}