import AbstractSearch from '@/js/common/Domain/Service/search/AbstractSearch';

import _ from 'underscore';
import { TrackJS } from 'trackjs';
import getStore from '@ve/services/getStore/getStore';
import countryConditions from '@ve/components/countryConditions/common/store/countryConditions';
import OperatorsRepository from '@/js/common/Domain/Repository/Operators';
import apiToursToTourProducts from '@/js/common/Domain/Service/search/apiToursToTourProducts';
import { operatorRepositoryName, OperatorRepository } from '@entities/operator';
import { tourProductsRepositoryName } from '@entities/tourProduct';
import { hotelsRepositoryName } from '@entities/hotel';
import { countriesRepositoryName } from '@entities/country';
import registerModule from '@fr/vue/services/registerModule';
import { productCompareKey, rawProductCompareKey } from '@/js/common/Domain/Service/search/getTourProductCompareKeys';
import HotelsRepository from '../Repository/Hotels';
import HotelFactory from '../Factory/Hotel';
import TourProductsRepository from '../Repository/TourProducts';
import sessionService from './session';
import SessionStatusService from './SessionStatus';
import searchCacheService from './searchCache';
import TourProductFactory from '../Factory/TourProduct';
import tourCriteriaJsonNewApi from '../Hydrator/tourCriteriaJsonNewApi';
import additionalTourCriteriaJsonNewApiForTour from '../Hydrator/tourCriteria/additionalTourCriteriaJsonNewApiForTour';
import asyncTourCriteriaJson from '../Hydrator/asyncTourCriteriaJson';

import getTourFirstPaymentDefinitionService from './getTourFirstPaymentDefinition';

export default class Search extends AbstractSearch {
    constructor(options = {}) {
        super();
        this.hotelsRepositorySync = new HotelsRepository();
        this.tourProductsRepositorySync = new TourProductsRepository();
        this.operatorsRepository = OperatorsRepository.repository;
        this.HotelFactory = options.HotelFactory || HotelFactory;
        registerModule.register(getStore(), OperatorRepository, operatorRepositoryName);
    }

    getOffersData(getToursOptions) {
        const {
            searchUuid,
            clientUuid,
            customerUuid,
            requests,
            callback,
        } = getToursOptions;

        this.reset();

        this.startSearchTime = (new Date()).getTime();

        _.each(requests, (request) => {
            const cacheAjaxData = {
                ...tourCriteriaJsonNewApi(request.tourCriteria),
                ...additionalTourCriteriaJsonNewApiForTour(request.tourCriteria),
            };
            const asyncAjaxData = asyncTourCriteriaJson(request.tourCriteria);

            const afterFirstSearchRequest = {
                success: false,
            };
            let trackThisRequest = false;

            cacheAjaxData.commandUuid = searchUuid;
            asyncAjaxData.commandUuid = searchUuid;
            cacheAjaxData.clientUuid = clientUuid;
            asyncAjaxData.clientUuid = clientUuid;
            cacheAjaxData.customerUuid = customerUuid;
            asyncAjaxData.customerUuid = customerUuid;

            if (cacheAjaxData.track) {
                trackThisRequest = true;
                delete (cacheAjaxData.track);
            }

            if (request.limit) {
                cacheAjaxData.limit = request.limit;
            }

            if (request.type === 'sync' || request.type === 'both' || typeof request.type === 'undefined') {
                const syncRequest = () => {
                    const XHR = searchCacheService({
                        ...cacheAjaxData,
                        limit: cacheAjaxData.limit || 2000,
                    }, {
                        success: (response) => {
                            if (_.isFunction(afterFirstSearchRequest.callback)) {
                                afterFirstSearchRequest.callback();
                                afterFirstSearchRequest.success = true;
                                afterFirstSearchRequest.callback = false;
                            } else {
                                afterFirstSearchRequest.success = true;
                            }

                            this.updateData(response.result, request.tourCriteria, {
                                hotels: this.hotelsRepositorySync,
                                tourProducts: this.tourProductsRepositorySync,
                            });

                            if (this.startSearchTime && this.hotelsRepositorySync.length) {
                                this.firstCacheTime = (new Date()).getTime();
                            }

                            if (this.allAjaxAreCompleted('sync')) {
                                callback.sync(this.hotelsRepositorySync, this.tourProductsRepositorySync);
                            }
                        },
                        error: (XMLHttpRequest, textStatus, errorThrown) => {
                            if (errorThrown !== 'abort') {
                                if (_.isFunction(afterFirstSearchRequest.callback)) {
                                    afterFirstSearchRequest.callback();
                                    afterFirstSearchRequest.success = true;
                                    afterFirstSearchRequest.callback = false;
                                } else {
                                    afterFirstSearchRequest.success = true;
                                }

                                if (this.allAjaxAreCompleted('sync')) {
                                    callback.sync(this.hotelsRepositorySync, this.tourProductsRepositorySync);
                                }
                            }
                        },
                    });

                    this.syncAjaxRequests.push(XHR);
                    this.ajaxRequests.push(XHR);
                };

                if (typeof (request.timeout) !== 'undefined') {
                    this.requestTimeouts.push(setTimeout(() => {
                        if (this.hotelsRepositorySync?.length === 0) {
                            syncRequest();
                        }
                    }, request.timeout));
                } else {
                    syncRequest();
                }
            }
            if (request.type === 'async' || request.type === 'both' || typeof request.type === 'undefined') {
                if (request.type === 'async') {
                    afterFirstSearchRequest.success = true;
                }

                this.getOffersDataAsync({ asyncAjaxData, cacheAjaxData }, {
                    success: (response) => {
                        const updatedHotelsData = this.updateData(response.result, request.tourCriteria, {
                            hotels: this.hotelsRepositorySync,
                            tourProducts: this.tourProductsRepositorySync,
                        });

                        if (this.startSearchTime) {
                            this.searchFinishedTime = (new Date()).getTime();
                        }

                        if (this.allAjaxAreCompleted('async')) {
                            callback.async(updatedHotelsData.updatedHotels, updatedHotelsData.newHotelsLength, {
                                startSearchTime: this.startSearchTime,
                                firstCacheTime: this.firstCacheTime,
                                searchFinishedTime: this.searchFinishedTime,
                                scannedTours: response.result?.tours?.length || 0,
                                error: 0,
                            });
                        }
                    },
                    middleRequest: (response) => {
                        const updatedHotelsData = this.updateData(response.result, request.tourCriteria, {
                            hotels: this.hotelsRepositorySync,
                            tourProducts: this.tourProductsRepositorySync,
                        });

                        if (this.startSearchTime && !this.firstCacheTime && this.hotelsRepositorySync.length) {
                            this.firstCacheTime = (new Date()).getTime();
                        }

                        if (callback && callback.middleRequest) {
                            callback.middleRequest(updatedHotelsData.updatedHotels, updatedHotelsData.newHotelsLength);
                        }
                    },
                    error: (XMLHttpRequest, textStatus, errorThrown) => {
                        if (errorThrown !== 'abort') {
                            if (this.allAjaxAreCompleted('async')) {
                                if (this.startSearchTime) {
                                    this.searchFinishedTime = (new Date()).getTime();
                                }
                                callback.async([], 0, {
                                    startSearchTime: this.startSearchTime,
                                    firstCacheTime: this.firstCacheTime,
                                    searchFinishedTime: this.searchFinishedTime,
                                    scannedTours: 0,
                                    error: 1,
                                });
                            }
                        }
                    },
                }, trackThisRequest, afterFirstSearchRequest);
            }
            if (request.type === 'asyncWithoutTours') {
                afterFirstSearchRequest.success = true;

                this.getAsyncWithoutTours({ asyncAjaxData, cacheAjaxData }, {
                    success(response) {
                        callback.success(response);
                    },
                    middleRequest() {
                    },
                    error: (XMLHttpRequest, textStatus, errorThrown) => {
                        if (errorThrown !== 'abort') {
                            this.allAjaxAreCompleted('async');
                        }
                        callback.error(XMLHttpRequest, textStatus, errorThrown);
                    },
                }, trackThisRequest, afterFirstSearchRequest);
            }
        });
    }

    clearRepositories() {
        super.clearRepositories();
        this.tourProductsRepositorySync?.reset();
        getStore().commit(`${tourProductsRepositoryName}/resetTourProducts`);
        getStore().commit(`${hotelsRepositoryName}/resetHotels`);
    }

    updateData(data, tourCriteria, repositories) {
        console.time('Hotels-Tourproducts loaded');

        const {
            firstPaymentDefinitions,
            hotels,
            tours,
            countries,
            operators,
            legs = [],
            routes = [],
            tariffs = [],
        } = data;
        this.operatorsRepository.add(operators);
        getStore().commit(`${operatorRepositoryName}/setOperators`, operators?.map((operator) => Object.freeze(operator)));
        getStore().commit(`${tourProductsRepositoryName}/setTourProducts`, tours?.map((tour) => Object.freeze(tour)));
        getStore().commit(`${countriesRepositoryName}/setCountries`, countries?.map((country) => Object.freeze(country)));
        getStore().commit(`${hotelsRepositoryName}/setHotels`, hotels?.map((hotel) => Object.freeze(hotel)));
        const country = tourCriteria.get('countries').at(0);
        const currentHotelsHashTable = repositories.hotels.pluck('id');
        const currentTourProductsHashTable = repositories.tourProducts.map(productCompareKey);
        const resorts = country.get('resorts');

        const updatedHotelsModels = [];
        let newTourProductsGroupByHotelId;

        console.log('Hotels from request:', hotels ? hotels.length : 0, 'TourProducts from request:', tours?.length || 0);

        const populatedTourProducts = apiToursToTourProducts({
            tours,
            firstPaymentDefinitions,
            routes,
            tariffs,
            legs,
        });

        if (hotels && populatedTourProducts) {
            console.time('Find new items');

            const newHotels = [];
            const newTourProducts = [];

            _.each(hotels, (hotel) => {
                if (!~_.indexOf(currentHotelsHashTable, hotel.id)) {
                    newHotels.push(hotel);
                }
            });
            if (countries) {
                countryConditions.update(countries);
            }
            _.each(populatedTourProducts, (tourProduct) => {
                const rawProductKey = rawProductCompareKey(tourProduct.tour);
                if (!~_.indexOf(currentTourProductsHashTable, rawProductKey)) {
                    const firstPaymentDefinition = getTourFirstPaymentDefinitionService(tourProduct);
                    const allFirstPaymentDefinitions = tourProduct?.firstPaymentDefinitions || [];

                    newTourProducts.push({
                        ...tourProduct,
                        firstPaymentDefinition,
                        allFirstPaymentDefinitions,
                    });
                }
            });

            console.timeEnd('Find new items');
            console.time('buildHotels');

            repositories.hotels.add(_.map(newHotels, (hotel) => new this.HotelFactory(hotel)), {
                merge: true,
            });

            console.timeEnd('buildHotels');

            console.time('buildTourProducts');

            _.map(newTourProducts, (tourProduct) => $.extend(tourProduct, {
                tourCriteria,
            }));

            const newTourProductModels = newTourProducts.map((tourProduct) => new TourProductFactory(tourProduct, resorts));

            repositories.tourProducts.add(newTourProductModels, {
                merge: true,
            });

            console.timeEnd('buildTourProducts');
            console.time('new tour products to hotels');
            console.time('group tour products');

            newTourProductsGroupByHotelId = _.groupBy(newTourProductModels, (tourProduct) => tourProduct.get('tour').get('hotel'));
            console.timeEnd('group tour products');
            console.time('tour products merge with exist');

            _.each(newTourProductsGroupByHotelId, (tourProducts, key) => {
                const hotel = repositories.hotels.get(parseInt(key, 10));

                if (hotel === undefined) {
                    return;
                }

                const oldTourProducts = hotel.get('tourProducts');
                let existTourProducts = oldTourProducts ? _.clone(oldTourProducts.models) : [];

                updatedHotelsModels.push(hotel);
                existTourProducts = existTourProducts.concat(tourProducts);
                existTourProducts = _.sortBy(existTourProducts, (tourProduct) => tourProduct.get('tour').get('priceWithOilTax'));

                hotel.set('tourProducts', new TourProductsRepository(existTourProducts));
            });

            console.timeEnd('tour products merge with exist');
            console.timeEnd('new tour products to hotels');

            //console.log('Merged hotels:', hotelsRepository.length, 'Merged tourProducts:', tourProductsRepository.length);
            console.timeEnd('Hotels-Tourproducts loaded');
            console.log('New hotels', newHotels.length, 'New tour products', newTourProductModels.length, 'UpdatedHotels', updatedHotelsModels.length);

            return ({
                updatedHotels: updatedHotelsModels,
                newHotelsLength: newHotels.length,
            });
        }

        return null;
    }

    getOffersDataAsync(tourCriteriaForTourRequest, callback, trackThisRequest, afterFirstSearchRequest) {
        TrackJS.console.log('call getOffersDataAsync');
        const afterFirstSearchRequestInstance = afterFirstSearchRequest;
        const { cacheAjaxData, asyncAjaxData } = tourCriteriaForTourRequest;
        const { searchUuid, originalSearchUuid } = cacheAjaxData;

        TrackJS.console.log('Data for request in search');
        TrackJS.console.log(asyncAjaxData);
        const sessionXHR = sessionService(asyncAjaxData, {
            success: (response) => {
                const session = response?.result?.id || null;
                const getSessionWrapper = () => {
                    new SessionStatusService(session, {
                        success: () => {
                            if (trackThisRequest) {
                                cacheAjaxData.track = 1;
                            }
                            this.clearRequestTimeouts();

                            const XHR = searchCacheService(cacheAjaxData, {
                                success(cacheResponse) {
                                    if (cacheResponse.success === true) {
                                        callback.success(cacheResponse);
                                    } else {
                                        callback.error(null, cacheResponse.message, cacheResponse);
                                    }
                                },
                                error: callback.error,
                            });
                            this.asyncAjaxRequests.push(XHR);
                            this.ajaxRequests.push(XHR);
                        },
                        middleRequest: () => {
                            let XHR;
                            const middleRequestTourCriteria = _.clone(cacheAjaxData);

                            middleRequestTourCriteria.limit = 500;
                            if (callback && callback.middleRequest) {
                                XHR = searchCacheService(middleRequestTourCriteria, {
                                    success(cacheResponse) {
                                        if (cacheResponse.success === true) {
                                            callback.middleRequest(cacheResponse);
                                        } else {
                                            callback.middleRequest({
                                                result: {
                                                    hotels: [],
                                                    tourProducts: [],
                                                },
                                            });
                                        }
                                    },
                                    error() {
                                        callback.middleRequest({
                                            result: {
                                                hotels: [],
                                                tourProducts: [],
                                            },
                                        });
                                    },
                                });

                                this.ajaxRequests.push(XHR);
                            }
                            console.log('send middle request!!');
                        },
                        error: (message) => {
                            this.clearRequestTimeouts();
                            callback.error(null, message, response);
                        },
                    }, {
                        asyncAjaxRequests: this.asyncAjaxRequests,
                        ajaxRequests: this.ajaxRequests,
                        statusTimeouts: this.statusTimeouts,
                        globalStatusTimeouts: this.globalStatusTimeouts,
                        countryId: cacheAjaxData.country,
                        asyncStatus: this.asyncStatus,
                        searchUuid,
                        originalSearchUuid,
                    });
                };

                if (response.success === true && session) {
                    if (afterFirstSearchRequestInstance.success) {
                        getSessionWrapper();
                    } else {
                        afterFirstSearchRequestInstance.callback = getSessionWrapper;
                    }
                } else {
                    callback.error(null, response.message, response);
                }
            },
            error: callback.error,
        });

        this.ajaxRequests.push(sessionXHR);
        this.asyncAjaxRequests.push(sessionXHR);
    }

    getAsyncWithoutTours(tourCriteriaForTourRequest, callback, trackThisRequest, afterFirstSearchRequest) {
        TrackJS.console.log('call getAsyncWithoutTours');
        const afterFirstSearchRequestInstance = afterFirstSearchRequest;
        const { cacheAjaxData, asyncAjaxData } = tourCriteriaForTourRequest;
        const { searchUuid, originalSearchUuid } = cacheAjaxData;
        const sessionXHR = sessionService(asyncAjaxData, {
            success: (response) => {
                const session = response.result ? response.result.id : null;
                const getSessionWrapper = () => {
                    new SessionStatusService(session, {
                        success: () => {
                            if (trackThisRequest) {
                                cacheAjaxData.track = 1;
                            }
                            this.clearRequestTimeouts();
                            callback.success(response);
                        },
                        middleRequest() {
                        },
                        error: (message) => {
                            this.clearRequestTimeouts();
                            callback.error(null, message, response);
                        },
                    }, {
                        asyncAjaxRequests: this.asyncAjaxRequests,
                        ajaxRequests: this.ajaxRequests,
                        statusTimeouts: this.statusTimeouts,
                        globalStatusTimeouts: this.globalStatusTimeouts,
                        countryId: cacheAjaxData.country,
                        asyncStatus: this.asyncStatus,
                        searchUuid,
                        originalSearchUuid,
                    });
                };

                if (response.success === true && session) {
                    if (afterFirstSearchRequestInstance.success) {
                        getSessionWrapper();
                    } else {
                        afterFirstSearchRequestInstance.callback = getSessionWrapper;
                    }
                } else {
                    callback.error(null, response.message, response);
                }
            },
            error: callback.error,
        });

        this.ajaxRequests.push(sessionXHR);
        this.asyncAjaxRequests.push(sessionXHR);
    }
}
