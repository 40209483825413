/* eslint-disable */
import SkolkovoDesktop from './SkolkovoDesktop.vue';
import useVueCompositionApi from '@/js/common/plugins/use-vue-composition-api';
import Vue from 'vue';

let isOnPage = document.querySelector('.js-skolkovo');

if(isOnPage) {
    useVueCompositionApi()
    new Vue({
        render: (h) => h(SkolkovoDesktop),
    }).$mount('.js-skolkovo');
}