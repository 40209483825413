import { SetupContext, Ref } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { NAMESPACE } from './store';

interface IuseNoToursInStatic {
    clickHandler(): void,
    beforeToursLoading: Ref<boolean>,
}

export default ({ emit }: SetupContext): IuseNoToursInStatic => {
    const { beforeToursLoading } = useState(NAMESPACE, ['beforeToursLoading']);

    const clickHandler = () => {
        emit('update-search-click');
    };

    return {
        clickHandler,
        beforeToursLoading,
    };
};
