import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';
import requestService from '../../Domain/Service/request';
import template from './template.html';

const LifeHackBannerView = Backbone.Epoxy.View.extend({
    template,
    bindings: {
        '.js-step-1': 'toggle:not(lifeHackBannerEmailWasSend)',
        '.js-step-2': 'toggle:lifeHackBannerEmailWasSend',
        button: 'classes:{loading:loading}',
    },
    initialize() {
        this.viewModel = new (Backbone.Model.extend({
            defaults: {
                loading: false,
                lifeHackBannerEmailWasSend: false,
            },
        }))();
        this.$el.html(this.template);
        this.dataLayerAnalytics = new DataLayerAnalytics();
        this.initDOMEvents();
    },
    initDOMEvents() {
        setTimeout(() => {
            const $form = this.$el.find('form');
            $form.validate({
                errorElement: 'div',
                rules: {
                    email: {
                        required: true,
                        email: true,
                    },
                },
                messages: {
                    email: {
                        required: 'Обязательное поле',
                        email: 'Введите правильный email',
                    },
                },
            });

            $form.on('click', 'button', (e) => {
                e.preventDefault();
                e.stopPropagation();
                if ($form.valid() && !this.viewModel.get('loading')) {
                    this.formSubmit($form.find('input').val());
                }
            });
        }, 10);
    },
    formSubmit(email) {
        this.viewModel.set('loading', true);
        requestService({
            type: 'POST',
            url: '/subscriber/hackSubscribe',
            data: {
                email,
            },
        }).done((data) => {
            this.viewModel.set('loading', false);
            if (data && data.success) {
                this.viewModel.set('lifeHackBannerEmailWasSend', true);
                this.dataLayerAnalytics.subscribeEmail();
            }
        }).fail(() => {
            this.viewModel.set('loading', false);
        });
    },
});

export default LifeHackBannerView;
