/**
 * Метод для получения ID - операторов из массива тур продуктов.
 */

import _ from 'underscore';

 const prepareOperatorsIdsFromTourProducts = (tourProducts) => {
    const operatorsIds = _.reduce(tourProducts, (acc, tourProduct) => {
        const operatorId = tourProduct?.tour?.get('operator')?.id;

        if (operatorId) acc.push(operatorId);

        return acc;
    }, []);

    return _.uniq(operatorsIds);
};

export default prepareOperatorsIdsFromTourProducts;
