/* eslint-disable */
import 'jquery.cookie';

const disabledConsole = () => {
    const console = (typeof window.console != 'undefined') ? window.console : {};

    const isConsoleEnable = !!($?.cookie?.('tr_debug'));
    const isProduction = !!(~window.location.hostname.indexOf('travelata.ru'));

    if (isConsoleEnable || !isProduction) {
        window.$ = $;
        return;
    }

    console.warn = () => {};
    console.time = () => {};
    console.log = () => {};
    console.info = () => {};
    console.error = () => {};
    console.timeEnd = () => {};
};

disabledConsole();