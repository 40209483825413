import { BEACH_TYPE_OLD_MAP, BeachTypeId } from '@entities/hotel';
import { type BeachTypeFilterItem } from '../types';

const dataAttributesBeachType: BeachTypeFilterItem[] = [
    {
        id: BeachTypeId.SandBeach,
        key: BEACH_TYPE_OLD_MAP[BeachTypeId.SandBeach],
        keyMerged: 'sandBeach',
        name: 'Песчаный',
        nameLayer: 'тип пляжа_Песчаный',
    },
    {
        id: BeachTypeId.PebbleBeach,
        key: BEACH_TYPE_OLD_MAP[BeachTypeId.PebbleBeach],
        keyMerged: 'pebbleBeach',
        name: 'Галечный',
        nameLayer: 'тип пляжа_Галечный',
    },
    {
        id: BeachTypeId.SandPebbleBeach,
        key: BEACH_TYPE_OLD_MAP[BeachTypeId.SandPebbleBeach],
        keyMerged: 'sandPebbleBeach',
        name: 'Песчано-галечный',
    },
];

export default dataAttributesBeachType;