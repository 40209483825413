import _ from 'underscore';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { Model } from 'backbone';
import {
    GetStoreOptions, BaseState, BackboneMap, BaseStoreOptions, BackboneMapField,
} from './types';

let storeInstance: Store<BaseState>;
Vue.use(Vuex);

function mergeStoreOptions(store: BaseStoreOptions, storeOptions: BaseStoreOptions): BaseStoreOptions {
    return {
        ...store,
        ...storeOptions,
    };
}

// для увеличения производительности на больших данных. Vue не добавляет реактивности к полям таких данных, да она тут и не нужна, это же просто геттеры
const freezeValue = (data: any) => {
    if (_.isObject(data) && Object.freeze) {
        Object.freeze(data);
    }
    return data;
};

const mapField = (store: BaseStoreOptions, backboneMapField: BackboneMapField): void => {
    const {
        fromField,
        toField,
        model,
        action,
    } = backboneMapField;

    if (!model) {
        throw new Error('Model field is required');
    }
    const modelValue = model.get(fromField);

    if (store.state) {
        store.state[toField] = freezeValue(action ? action(model, modelValue) : modelValue);
    }

    if (store.mutations) {
        store.mutations[`commit_${toField}`] = (state, value) => {
            state[toField] = freezeValue(value);
        };
    }

    if (!action) {
        if (store.actions) {
            store.actions[`action_${toField}`] = (_store, value) => {
                model.set(fromField, value);
            };
        }
    }
};

const mapStates = (store: Store<BaseState>, backboneMapField: BackboneMapField, name?: string): void => {
    const {
        fromField,
        toField,
        model,
        action,
    } = backboneMapField;
    let storeValue: any;

    model.on(`change:${fromField}`, (currentModel: Model, value: any) => {
        if (action) {
            storeValue = action(currentModel || model, value);
        } else {
            storeValue = value;
        }
        const mutationPrefix = name ? `${name}/` : '';
        store.commit(`${mutationPrefix}commit_${toField}`, freezeValue(storeValue));
    });
};

const getBackboneFields = (backboneMap: BackboneMap): BackboneMapField[] => {
    const backboneFields: BackboneMapField[] = [];

    backboneMap.forEach((fieldsGroup) => {
        const { fields, model } = fieldsGroup;

        if (fields?.length) {
            fields.forEach((field) => {
                switch (typeof field) {
                    case 'string':
                        backboneFields.push({
                            fromField: field,
                            toField: field,
                            model,
                        });
                        break;
                    case 'object':
                        Object.keys(field).forEach((fieldItem) => {
                            const key = fieldItem as keyof typeof field;
                            const fieldValue = field[key];
                            if (typeof fieldValue === 'string') {
                                backboneFields.push({
                                    fromField: fieldValue,
                                    toField: fieldItem,
                                    model,
                                });
                            } else if (typeof fieldValue === 'object') {
                                const { field: fromField, action } = fieldValue;
                                if (fromField && action) {
                                    backboneFields.push({
                                        fromField,
                                        toField: fieldItem,
                                        action,
                                        model,
                                    });
                                }
                            }
                        });
                        break;
                    default:
                }
            });
        }
    });

    return backboneFields;
};

export default (options: GetStoreOptions = {}): Store<BaseState> => {
    const {
        name = null,
        storeOptions,
        backboneMap,
        clearStore, //field just for tests
    } = options;

    let mainStore: BaseStoreOptions = {
        state: {},
        mutations: {},
        actions: {},
        strict: true,
    };

    if (storeInstance && !clearStore && !name && (backboneMap || storeOptions)) {
        throw new Error('Main storage already initialized');
    }

    const backboneMapFields: BackboneMapField[] = backboneMap ? getBackboneFields(backboneMap) : [];

    if (!storeInstance || clearStore) {
        if (name) {
            storeInstance = new Store(mainStore);
        } else {
            if (storeOptions) {
                mainStore = mergeStoreOptions(mainStore, storeOptions);
            }

            backboneMapFields.forEach((backboneMapField) => {
                mapField(mainStore, backboneMapField);
            });
            storeInstance = new Store(mainStore);
            backboneMapFields.forEach((backboneMapField) => {
                mapStates(storeInstance, backboneMapField);
            });
        }
    }

    if (storeInstance && name) {
        let storeModule: BaseStoreOptions = {
            state: {},
            mutations: {},
            actions: {},
            namespaced: true,
        };

        if (!storeInstance.state[name]) {
            if (storeOptions) {
                storeModule = mergeStoreOptions(storeModule, storeOptions);
            }
            backboneMapFields.forEach((backboneMapField) => {
                mapField(storeModule, backboneMapField);
            });
            storeInstance.registerModule(name, storeModule);
            backboneMapFields.forEach((backboneMapField) => {
                mapStates(storeInstance, backboneMapField, name);
            });
        } else {
            console.log(`Module ${name} already registered`);
        }
    }

    return storeInstance;
};