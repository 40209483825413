/* eslint-disable */
import { TrackJS } from "trackjs";
var trackImageErrors = (imageUrls) => {
	_.each(imageUrls, (imageUrl) => {
		let image = createImage(imageUrl);
		subscribeEvents(image);
	});
};

var createImage = (imageUrl) => {
	let img = new Image();
	img.src = imageUrl;

	return img;
};

var subscribeEvents =  (image) => {
	image.onerror = function(evt) {
		if (!evt || !evt.srcElement) { return; }
		let evtpath = evt.path || [];
		var path = "";
		for(var elIdx = 0; elIdx < evtpath.length; elIdx++) {
			var currentEl = evtpath[elIdx];
			if (currentEl === window) {
				path += "Window";
				continue;
			}
			path += currentEl.nodeName;
			path += currentEl.id ? "#" + currentEl.id : "";
			path += currentEl.className ? "." + currentEl.className.split(" ").join(".") : "";
			if (elIdx < evtpath.length) {
				path += " > ";
			}
		}
		console.info({
			asset: evt.srcElement.src,
			integrity: evt.srcElement.integrity,
			element: evt.srcElement.outerHTML,
			path: path
		});
		TrackJS.track("Failed to load " + evt.srcElement.tagName + ": " + (evt.srcElement.src || evt.srcElement.href));
	};

	image.onload = function(evt) {
		if (!evt || !evt.srcElement) { return; }
		// console.log(`Successfully loaded: ${evt.srcElement.src}`);
	}
};

export default trackImageErrors;
