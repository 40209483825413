import _ from 'underscore';
import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { KeyOfTypes } from '@ve/types/helpers';
import { IFilterAttribute } from '@ve/components/filters/common/types';
import FilterAttributeMethodsAbstract from '../filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';

class FilterAttributeMethodsHotelArray<T extends IFilterAttribute> extends FilterAttributeMethodsAbstract<T, SerpHotelAdapted> {
    property?: KeyOfTypes<SerpHotelAdapted, number[]>;

    constructor(arrayAttributes: T[], property?: KeyOfTypes<SerpHotelAdapted, number[]>) {
        super(arrayAttributes);
        this.property = property;
    }

    getCountAttribute(hotels: SerpHotelAdapted[]): Record<T['key'], number> {
        if (this.property) {
            const { property } = this;
            return hotels.reduce((acc, hotel) => {
                const keysAttribute = this.getKeysAttributeByIds(hotel[property]);
                keysAttribute.forEach((keyAttribute) => {
                    acc[keyAttribute]++;
                });
                return acc;
            }, this.getAttributeObjectCount());
        }
        return {} as Record<T['key'], number>;
    }

    getIsOneHotelHasProperty(hotels: SerpHotelAdapted[]): boolean {
        const { property } = this;
        if (property) {
            return _.some(hotels, (hotel) => {
                const hotelPropertyValue = hotel[property];
                return !!hotelPropertyValue.length;
            });
        }
        return false;
    }
}

export default FilterAttributeMethodsHotelArray;
