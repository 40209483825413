export default class {
    constructor(data) {
        let element = data.medicalInsurance;
        if (typeof element !== 'undefined') {
            this.medicalInsurance = element;
        }

        element = data.tourCriteria;
        if (typeof element !== 'undefined') {
            this.tourCriteria = element;
        }

        element = data.price;
        if (typeof element !== 'undefined') {
            this.price = element;
        }

        element = data.discount;
        if (typeof element !== 'undefined') {
            this.discount = element;
        }

        element = data.hotelAvailable;
        if (typeof element !== 'undefined') {
            this.hotelAvailable = element;
        }
    }
}