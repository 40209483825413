import { useState, useMutations, useGetters } from 'vuex-composition-helpers';
import { computed, unref } from 'vue';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import RoomsRepository, {
    IRoomsRepositoryMutations,
    IRoomsRepositoryState,
    RoomsRepositoryName,
} from '../model/RoomsRepository';

export default function () {
    registerModule.register(getStore(), RoomsRepository, RoomsRepositoryName);
    const { rooms } = useState<IRoomsRepositoryState>(RoomsRepositoryName, ['rooms']);
    const { setRooms } = useMutations<IRoomsRepositoryMutations>(RoomsRepositoryName, ['setRooms']);

    return {
        rooms,
        setRooms,
    };
}