import { BaseState } from '@ve/services/getStore/types.d';
import getStore from '@ve/services/getStore/getStore';
import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { BackboneSearchPageModel } from '@ve/types/backboneModels/BackboneSearchPageModel';
import { BackboneSearchFormModel } from '@ve/types/backboneModels/BackboneSearchFormModel';
import { BackboneFiltersCriteriaModel } from '@ve/types/backboneModels/BackboneFiltersCriteriaModel';
import {
 BackboneHotelCategoriesRepository, BackboneMealsRepository, BackboneResortsRepository, BackboneCountriesRepository,
} from '@ve/types/backboneModels/Domain/Repository';
import { FoundHotelsBySearchFilter } from '@ve/types';

import { Store } from 'vuex';
import processHotelsAdapter from './adapters/processHotelsAdapter';
import processResortsAdapter from './adapters/processResortsAdapter';

export const nameFilterSerpStore = 'mapForFiltersSerp';

export default ({
                    filtersModel,
                    searchPageModel,
                    searchFormViewModel,
                    mealsRepository,
                }: {
    filtersModel: BackboneFiltersCriteriaModel;
    searchPageModel: BackboneSearchPageModel;
    searchFormViewModel: BackboneSearchFormModel;
    mealsRepository: BackboneMealsRepository;
}): Store<BaseState> => getStore({
    name: nameFilterSerpStore,
    backboneMap: [{
        fields: [
            'isExclusive',
            'minPrice',
            'maxPrice',
            'nearTheLift',
            'typeLines',
            'typeFilterRating',
            'typeDistanceLift',
            'takenHotelAttributes',
            'takenWaterActivities',
            'typeConfirmation',
            'childServiceFilterIds',
            'restType',
            'takenBeachType',
            'operators',
            'takenFacilities',
            'isPopularityFilterActive',
        ],
        model: filtersModel,
    }, {
        fields: [
            'beforeToursLoading',
            'loadingState',
            {
                syncHotels: {
                    field: 'syncHotels',
                    action: () => processHotelsAdapter(searchPageModel.get('syncHotels')),
                },
                filteredHotels: {
                    field: 'filteredHotels',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotels')),
                },
                filteredHotelsWithoutPrice: {
                    field: 'filteredHotelsWithoutPrice',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutPrice')),
                },
                filteredHotelsWithoutRating: {
                    field: 'filteredHotelsWithoutRating',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutRating')),
                },
                filteredHotelsWithoutConfirmation: {
                    field: 'filteredHotelsWithoutConfirmation',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutConfirmation')),
                },
                filteredHotelsWithoutAttrs: {
                    field: 'filteredHotelsWithoutAttrs',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutAttrs')),
                },
                touristGroup: {
                    field: 'searchData',
                    action: () => searchPageModel?.get('searchData')?.get('touristGroup')?.attributes || {},
                },
                filteredHotelsWithoutBeachLine: {
                    field: 'filteredHotelsWithoutBeachLine',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutBeachLine')),
                },
                filteredHotelsWithoutDistanceLift: {
                    field: 'filteredHotelsWithoutDistanceLift',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutDistanceLift')),
                },
                filteredHotelsWithoutBeachType: {
                    field: 'filteredHotelsWithoutBeachType',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutBeachType')),
                },
                filteredHotelsByRestType: {
                    field: 'filteredHotelsByRestType',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsByRestType')),
                },
                filteredHotelsWithoutRestType: {
                    field: 'filteredHotelsWithoutRestType',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutRestType')),
                },
                filteredHotelsWithoutOperator: {
                    field: 'filteredHotelsWithoutOperator',
                    action: () => processHotelsAdapter(searchPageModel.get('filteredHotelsWithoutOperator')),
                },
            }],
        model: searchPageModel,
    }, {
        fields: [
            {
            meals: {
                field: 'model',
                action: (model, meals: BackboneMealsRepository) => (meals && meals.map((meal) => meal.get('id'))) || [],
            },
        }],
        model: searchFormViewModel.get('meals'),
    }, {
        fields: [{
            hotelCategories: {
                field: 'model',
                action: (model, hotelCategories: BackboneHotelCategoriesRepository) => (
                    hotelCategories && hotelCategories.map((hotelCategory) => hotelCategory.get('id'))
                ) || [],
            },
        }],
        model: searchFormViewModel.get('hotelCategories'),
    },
        {
            fields: [{
                directFlight: 'model',
            }],
            model: searchFormViewModel.get('directFlight'),
        },
        {
        fields: [{ quickConfirmation: 'model' }],
        model: searchFormViewModel.get('warranted'),
    }, {
        fields: [{
            countries: {
                field: 'model',
                action: (model, countries: BackboneCountriesRepository) => countries?.map((country) => ({ id: country.get('id'), name: country.get('name') })) || [],
            },
        }],
        model: searchFormViewModel.get('countries'),
    }, {
        fields: [{
            resortsInSearch: {
                field: 'model',
                action: (model, resorts: BackboneResortsRepository) => processResortsAdapter(resorts),
            },
        }],
        model: searchFormViewModel.get('resorts'),
    }],
    storeOptions: {
        state: {
            mealsRepository: mealsRepository.map((meal) => meal.pick('id', 'name', 'code')),
            takenHotels: [],
            foundHotelsByFilters: [],
        },
        mutations: {
            setHotelByFilters(state, hotelsByFilters: FoundHotelsBySearchFilter[]) {
                state.foundHotelsByFilters = hotelsByFilters;
            },
            setTakenHotels(state, hotelsByFilters: FoundHotelsBySearchFilter[]) {
                state.takenHotels = hotelsByFilters;
            },
            setDefaultHotelsFilter(state) {
                state.foundHotelsByFilters = [];
                state.takenHotels = [];
            },
        },
        getters: {
            filteredHotels: ({ filteredHotels }) => filteredHotels,
            allHotels: ({ syncHotels }) => syncHotels,
        },
    },
});