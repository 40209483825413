import WATER_ACTIVITIES_REPOSITORY
    from '@/js/common/components/filterCriteria/waterActivities/WaterActivitiesRepository';
import getABTestValue from '@/js/common/Domain/Service/getABTestValue';
import getCustomerHash from '@/js/desktop/components/account/service/getCustomerHash';
import { childServiceFilterMethods, restTypeFilterMethods, beachTypeFilterMethods } from '@features/hotel/filterHotels';
import { FilterAttributeMethodsOperators } from '@features/operator/filterByOperator';
import { filterAttributesMethodsConfirmation } from '@features/tourProduct/filterTourProducts';
import FilterAttributeBeachLineMethods from '@ve/components/filters/beachLineFilter/filterMethods/FilterAttributeBeachLineMethods';
import { filterAttributeHotelMethodsByAll } from '@ve/components/filters/common/filterMethods/filterAttributeMethodsHotelBoolean/FilterAttributeHotelMethodsByAll';
import FilterAttributeDistanceLiftMethods from '@ve/components/filters/distanceLiftFilter/filtersMethods/FilterAttributeDistanceLiftMethods';
import FilterAttributeRatingReviewsMethods from '@ve/components/filters/ratingReviewsFilter/filterMethods/FilterAttributeRatingReviewsMethods';
import facilitiesFilterAttributeMethods from '@ve/pages/serp/components/filters/facilitiesFilter/common/FacilitiesFilterAttributeMethods';
import FilterAttributeMethodsWaterActivities from '@ve/pages/serp/components/filters/filterWaterActivities/common/FilterMethodsWaterActivities';
import getHotelsWithoutOil from './getHotelsWithoutOil';

const trackingSearchFilters = async (options, uuid, filteredSearchUuid) => {
    const isMobileDevice = !!window?.appConfig?.isMobileDomain;
    const searchUuid = uuid;
    const availableOperators = options.filters?.operators?.getAvailableOperators();
    const filterCriteria = options.filters?.model;
    const mergedHotelAttributes = filterAttributeHotelMethodsByAll.getMergedAttributes(filterCriteria?.get('takenHotelAttributes'));
    const mergedTypesLines = FilterAttributeBeachLineMethods.getMergedAttributes(filterCriteria?.get('typeLines'));
    const mergedDistanceLift = FilterAttributeDistanceLiftMethods.getMergedAttributes(filterCriteria?.get('typeDistanceLift'));
    const mergedBeachType = beachTypeFilterMethods.getMergedAttributes(filterCriteria?.get('takenBeachType'));
    const mergedWaterActivities = FilterAttributeMethodsWaterActivities.getMergedAttributes(filterCriteria?.get('takenWaterActivities'));
    const mergedTourOperators = new FilterAttributeMethodsOperators(options?.operatorsData || []).getMergedAttributes(filterCriteria?.get('operators'));
    const mergedRatingReviews = filterCriteria?.get('typeFilterRating') ? FilterAttributeRatingReviewsMethods.getMergedAttributes([filterCriteria?.get('typeFilterRating')]) : {};
    const mergedFacilities = filterCriteria?.get('takenFacilities') ? facilitiesFilterAttributeMethods.getMergedAttributes(filterCriteria?.get('takenFacilities')) : {};
    const mergedChildService = filterCriteria?.get('childServiceFilterIds') ? childServiceFilterMethods.getMergedAttributes(filterCriteria?.get('childServiceFilterIds')) : {};
    const mergedRestType = filterCriteria?.get('restType') ? restTypeFilterMethods.getMergedAttributes([filterCriteria?.get('restType')]) : {};
    const typeConfirmationMerged = filterCriteria?.get('typeConfirmation') ? filterAttributesMethodsConfirmation.getMergedAttributes([filterCriteria?.get('typeConfirmation')]) : {};

    const fieldsObjects = {
        ...mergedHotelAttributes,
        ...mergedTypesLines,
        ...mergedRatingReviews,
        ...mergedDistanceLift,
        ...mergedRestType,
        ...mergedBeachType,
        ...mergedWaterActivities,
        ...mergedFacilities,
        ...mergedChildService,
        ...typeConfirmationMerged,
    };

    const filterCriteriaJson = {
        ...filterCriteria.toJSON(),
    };
    filterCriteriaJson.exclusive = filterCriteriaJson.isExclusive;

    const attributeNames = [
        'freeCancellation',
        'children_animators',
        'children_seaEnter',
        'children_childmenu',
        'children_childBed',
        'children_nanny',
        'children_clubs',
        'withKids',
        'adultsOnly',
        'forCouples',
        'nearTheLift',
        'firstLine',
        'secondLine',
        'thirdLine',
        'beachOwner',
        'cashback',
        'airConditioner',
        'nearTheBeach',
        'nearTheCenter',
        'withAnimals',
        'satisfactorily',
        'mountains',
        'eco',
        'minPrice',
        'maxPrice',
        'best',
        'noScore',
        'better',
        'good',
        'satisfactorily',
        'wifi',
        'wellness',
        'pool',
        'heatedPool',
        'aquapark',
        'spaAndHealth',
        'lonelyMan',
        'exclusive',
    ].concat(WATER_ACTIVITIES_REPOSITORY.map((item) => item.name));
    const attributes = _.mapObject(_.pick.apply(this, [filterCriteriaJson].concat(attributeNames)), (value) => +value);
    const operators = filterCriteria ? filterCriteria.get('operators') : null;
    let mergedOpeartors = [];
    const customerHash = await getCustomerHash();

    if (availableOperators) {
        const availableOperatorsId = _.pluck(availableOperators, 'id');

        mergedOpeartors = availableOperatorsId.reduce((prev, next) => {
            prev[next] = 0;
            return prev;
        }, {});

        mergedOpeartors = operators.reduce((prev, next) => {
            prev[next] = 1;
            return prev;
        }, mergedOpeartors);
    }

    const hotelsWithoutOil = options.filteredHotels?.reduce((count, hotel) => (
        hotel.get('oilTaxIsAbsent')
            ? count + 1
            : count
    ), 0);

    return _.extend({}, {
        ab_tests: getABTestValue(),
        sandBeach: +(filterCriteriaJson.sandBeach || filterCriteriaJson.beachType === 'sandBeach'),
        pebbleBeach: +(filterCriteriaJson.pebbleBeach || filterCriteriaJson.beachType === 'pebbleBeach'),
        operators: isMobileDevice ? mergedOpeartors : mergedTourOperators,
        autoChange: +!!options.autoChange,
        hotelsLength: options.filteredHotels ? options.filteredHotels.length : undefined,
        hotelsWithoutOil,
        searchUuid,
        uuid: filteredSearchUuid,
        renderTime: options.serpProductsRenderTime,
        customer_hash: customerHash,
    }, attributes, getHotelsWithoutOil(options.filteredHotels), fieldsObjects);
};

export default trackingSearchFilters;
