/* eslint-disable camelcase */

import { format } from 'date-fns';
import trackingDefaultDataTypesHydrator from './trackingDefaultDataTypes';

const getTransfersTrackInfo = (routes) => {
    if (!routes?.length) {
        return {};
    }

    const [firstRoute] = routes;

    if (firstRoute instanceof Backbone.Model) {
        const transferType = firstRoute.get('tourProduct')?.get('transferType');

        if (firstRoute.get('isDefaultRoute')) {
            return {
                transferBack: transferType,
                transferThere: transferType,
            };
        }

        const route = firstRoute.get('route');

        const transferThere = route.there.availableTransfers?.length
            ? 'additional'
            : transferType;

        const transferBack = route.back.availableTransfers?.length
            ? 'additional'
            : transferType;

        return {
            transferBack,
            transferThere,
        };
    }

    const transferType = firstRoute.torProductModel?.get('transferType');
    const transferThere = firstRoute.there.availableTransfers?.length
        ? 'additional'
        : transferType;
    const transferBack = firstRoute.back.availableTransfers?.length
        ? 'additional'
        : transferType;

    return {
        transferBack,
        transferThere,
    };
};

const trackingTourPageDataHydrator = async (options) => {
    const trackingSearchData = {};
    const {
        roomPhotosCount,
        roomDescriptionAvailable,
        tour_found,
        identity,
        operator_id,
        resort_id,
        tourProduct,
        oldTourProduct,
        tour,
        oldTourProductTour,
        descriptionSource,
        descriptionStatus,
        roomName,
        roomCategoryId,
        route,
        actualizeId = null,
        actualizeSerialNumber,
        hotelMinPriceIdentity,
        hotelMinPriceOilTax,
        hotelMinPrice,
    } = options;

    const tour_found_time = options.tour_found_time
        ? Math.round((options.tour_found_time - options.timestamp) / 1000) : 0;
    const actualization_time = options.startNewActualizeTime
        ? Math.round((options.actualization_time - options.startNewActualizeTime) / 1000) : -1;
    const trackingActualize = options.trackingActualize || {};
    let hotelId = null;
    const trackingDefaultDataTypes = await trackingDefaultDataTypesHydrator(options);

    const routeThereLegs = route?.there?.legs;
    const routeBackLegs = route?.back?.legs;
    const flightNumberThere = routeThereLegs?.length ? _.map(routeThereLegs, (leg) => leg.flightNumber).join('_') : undefined;
    const flightNumberBack = routeBackLegs?.length ? _.map(routeBackLegs, (leg) => leg.flightNumber).join('_') : undefined;
    const touristGroup = (tour || oldTourProductTour)?.get('touristGroup');
    const roomNamePrettify = roomName?.includes('undefined') ? null : roomName;

    if (window.appConfig?.hotelInfo) {
        hotelId = window.appConfig.hotelInfo.id;
    }

    _.extend(trackingSearchData, {
        actualize_group_id: actualizeId,
        tour_found_time,
        actualization_time,
        roomPhotosCount,
        roomDescriptionAvailable,
        descriptionSource,
        descriptionStatus,
        roomName: roomNamePrettify || null,
        roomCategoryId,
        identity,
        tour_found,
        operator_id: operator_id || null,
        resort_id: resort_id || null,
        actualization_result: trackingActualize.actualization_result || -1,
        hotel_id: hotelId,
        flightNumberThere,
        flightNumberBack,
        departureDate: route?.there?.legs?.[0]?.departureDate,
        arrivalDate: route?.back?.legs?.[0]?.departureDate,
        countryId: (tour || oldTourProductTour)?.get('country')?.id,
        departure_city_id: (tour || oldTourProductTour)?.get('departureCity')?.id,
        meal_id: (tour || oldTourProductTour)?.get('meal')?.id,
        nights:  (tourProduct || oldTourProduct)?.get('nights'),
        adults: touristGroup?.adults ?? null,
        kids: touristGroup?.kids ?? null,
        infants: touristGroup?.infants ?? null,
        actualizeSerialNumber,
        hotelMinPriceIdentity,
        hotelMinPriceOilTax,
        hotelMinPrice,
    }, trackingDefaultDataTypes);

    _.extend(trackingSearchData, {
        timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });

    if (trackingActualize.afterActualize) {
        _.extend(trackingSearchData, {
            price_after_actualization: trackingActualize.afterActualize.price,
            oil_tax_after_actualization: trackingActualize.afterActualize.oilTax,
        });
    }

    if (trackingActualize.beforeActualize) {
        _.extend(trackingSearchData, {
            price_before_actualization: trackingActualize.beforeActualize.price,
            oil_tax_before_actualization: trackingActualize.beforeActualize.oilTax,
        });
    }

    if (tour) {
        const routes = tour.get('routes') || [];
        _.extend(trackingSearchData, {
            flights_groups_count: routes.length,
            ...getTransfersTrackInfo(routes),
        });
    }

    if (options.baggage && tour) {
        const baggageThere = options.baggage.baggageThereModel;
        const baggageBack = options.baggage.baggageBackModel;

        _.extend(trackingSearchData, {
            baggageThereDefined: baggageThere.get('baggageType') === 'notKnown' ? 0 : 1,
            handLuggageThereDefined: baggageThere.get('handLuggageType') === 'notKnown' ? 0 : 1,
            baggageBackDefined: baggageBack.get('baggageType') === 'notKnown' ? 0 : 1,
            handLuggageBackDefined: baggageBack.get('handLuggageType') === 'notKnown' ? 0 : 1,
        });
    }

    return trackingSearchData;
};

export default trackingTourPageDataHydrator;