export default {
    68: [
        {
            classNameImage: 'country-info__image-emirates',
            pathLink: 'https://intercom.help/travelata-ac8575273330/ru/articles/5001793-',
            nameBanner: 'all_Knows_UAE',
            text: 'Всё, что нужно <br> знать об ОАЭ',
        },
        {
            classNameImage: 'country-info__image-rules',
            pathLink: 'https://intercom.help/travelata-ac8575273330/ru/articles/4489237-',
            nameBanner: 'what_covid_19_UAE',
            text: 'Памятка по <br> въезду в ОАЭ',
        },
        {
            classNameImage: 'country-info__image-first-emirates',
            pathLink: 'https://travelata.ru/blog/emiraty-v-1-j-raz-chto-nuzhno-znat/',
            nameBanner: 'first_trip_UAE',
            text: 'Для тех, кто едет <br> в первый раз',
        },
    ],
    92: [
        {
            classNameImage: 'country-info__image-turkey',
            pathLink: 'https://intercom.help/travelata-ac8575273330/ru/articles/4893185',
            nameBanner: 'about_turkey',
            text: 'Всё, что нужно <br> знать о Турции',
        },
        {
            classNameImage: 'country-info__image-rules',
            pathLink: 'https://intercom.help/travelata-ac8575273330/ru/articles/4287835',
            nameBanner: 'rules_turkey',
            text: 'Правила въезда <br> в Турцию',
        },
        {
            classNameImage: 'country-info__image-first-turkey',
            pathLink: 'https://travelata.ru/blog/turtsiya-v-1-yj-raz-kuda-poehat-chto-nuzhno-znat/',
            nameBanner: 'first_turkey',
            text: 'Для тех, кто едет <br> в первый раз',
        },
    ],
    56: [
        {
            classNameImage: 'country-info__image-first-maldives',
            pathLink: 'https://intercom.help/travelata-ac8575273330/ru/articles/8850394-%D0%BC%D0%B0%D0%BB%D1%8C%D0%B4%D0%B8%D0%B2%D1%8B-%D0%B4%D0%BB%D1%8F-%D1%82%D0%B5%D1%85-%D0%BA%D1%82%D0%BE-%D0%B5%D0%B4%D0%B5%D1%82-%D0%B2-%D0%BF%D0%B5%D1%80%D0%B2%D1%8B%D0%B9-%D1%80%D0%B0%D0%B7',
            nameBanner: 'first_maldives',
            text: 'Мальдивы для тех, <br> кто едет в первый раз',
        },
        {
            classNameImage: 'country-info__image-maldives',
            pathLink: 'https://intercom.help/travelata-ac8575273330/ru/articles/4485767-%D0%BF%D0%B0%D0%BC%D1%8F%D1%82%D0%BA%D0%B0-%D0%BF%D0%BE-%D0%B2%D1%8A%D0%B5%D0%B7%D0%B4%D1%83-%D0%BD%D0%B0-%D0%BC%D0%B0%D0%BB%D1%8C%D0%B4%D0%B8%D0%B2%D1%8B',
            nameBanner: 'about_maldives',
            text: 'Памятка по въезду <br> на Мальдивы',
        },
        {
            classNameImage: 'country-info__image-maldives-when',
            pathLink: 'https://travelata.ru/blog/?s=%D0%BC%D0%B0%D0%BB%D1%8C%D0%B4%D0%B8%D0%B2%D1%8B',
            nameBanner: 'maldives_when',
            text: 'Когда лучше ехать <br> на Мальдивы',
        },
    ],
};