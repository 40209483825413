// eslint-disable-next-line no-shadow
export enum BeachTypeId {
    SandBeach = 1,
    PebbleBeach = 2,
    SandPebbleBeach = 3,
}

export type BeachTypeOld = 'sandBeach' | 'pebbleBeach' | 'sandPebbleBeach';

export const BEACH_TYPE_OLD_MAP: Record<BeachTypeId, BeachTypeOld> = {
    1: 'sandBeach',
    2: 'pebbleBeach',
    3: 'sandPebbleBeach'
};