/* eslint-disable */
const formatNumber = function (_number, _separator, _decimal) {
    const decimal = (typeof (_decimal) !== 'undefined') && _decimal && parseInt(_decimal, 10) === _decimal ? parseInt(_decimal, 10) : 0;
    const separator = (typeof (_separator) !== 'undefined') && _separator ? _separator : ' ';
    let r = parseFloat(_number);
    const exp10 = 10 ** decimal;

    r = Math.round(r * exp10) / exp10;

    const rr = Number(r).toFixed(decimal).toString().split('.');
    const b = rr[0].replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, `$1${separator}`);

    r = (rr[1] ? `${b}.${rr[1]}` : b);

    return r;
};

Number.prototype.formatNumber = function (separator, decimail) {
    return formatNumber(this, separator, decimail);
};

String.prototype.formatNumber = function (separator, decimail) {
    return formatNumber(this, separator, decimail);
};

export default formatNumber;
