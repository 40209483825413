/* eslint-disable */
import 'jquery.cookie';
import isForbiddenCashBackCountry from '@/js/common/Domain/Service/isForbiddenCashBackCountry';
import LotteryDgView from '@/js/desktop/components/lotteryDg/lotteryDg';
import CashBackDgView from '@/js/desktop/components/cashbackDg/cashbackDg';

import noMirService from '@/js/common/services/noMirService';
import BannerComponent from './Banners';

const initBanners = () => {
    noMirService('cashbackBanner-closed');
    const isCountryForbidden = isForbiddenCashBackCountry(window.appConfig, document.location);
    const shouldShowCashBackBanner = window?.appConfig?.cashbackAvailable
        && !$.cookie('cashbackBanner-closed')
        && !isCountryForbidden;

    if (shouldShowCashBackBanner) {
        new CashBackDgView({
            el: '.js-cashback-dg',
            cookieName: 'cashbackBanner-closed',
        });

        return;
    }

    if (/^.*utm_campaign=[^&]*coupon.*$/.test(document.location.search)) {
        new BannerComponent({
            dialogClass: 'takeCouponPopup',
            cookieName: 'takeCouponPopup',
            showConfirmMessage: true,
        });

        return;
    }

    new LotteryDgView({
        el: '.js-lottery-dg',
    });
};

if (!window.hideBanner) {
    initBanners();
}
