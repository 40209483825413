
    import {
        defineComponent, toRefs, PropType,
    } from 'vue';
    import trackingCountryConditions from '@/js/common/Domain/Service/tracking/trackingCountryConditions';
    import { CountryInfo } from '../../types/CountryInfo';

    export default defineComponent({
        props: {
            dataInfoCountry: {
                required: true,
                type: Object as PropType<CountryInfo>,
            },
        },
        setup(props) {
            const { dataInfoCountry } = toRefs(props);

            const openLink = (): void => {
                trackingCountryConditions(dataInfoCountry.value.nameBanner);
            };
            return {
                openLink,
            };
        },
    });
