/* eslint-disable */
import {propsDecorator} from "@/js/common/Domain/Service/mixins/decorators";
import template from './paylateInformer.html';
import './paylateInformer.scss'
import checkLotteryService from "@/js/common/Domain/Service/checkLottery";
import popoverStackView from "@/js/desktop/components/popoverStack/popoverStack";
import ls from 'local-storage';
import moment from "moment";

const HIDE_COUNTDOWN = 10000;
const LS_NAME = "PaylateInformerUserCloseDate";

@propsDecorator({
	el: $(template()),
})

export default class PaylateInformer extends Backbone.Epoxy.View {
	initialize(options = {}) {
		let value = ls.get(LS_NAME);
		if (value && moment().diff(moment(value, 'DD-MM-YYYY'), 'days') < 30) {
			return
		}
		this.searchPageModel = options.searchPageModel;
		this.initModelEvents();
	}

	initModelEvents() {
		let filteredHotelsChanged = () => {
			this.searchPageModel.off('change:filteredHotels', filteredHotelsChanged);
			this.checkLotteryClosed();
		};
		this.searchPageModel.on('change:filteredHotels', filteredHotelsChanged);
	}

	checkLotteryClosed() {
		checkLotteryService(lotteryData => {
			if (!lotteryData || (lotteryData && lotteryData.id && $.cookie('lottery_' + lotteryData.id + '_closed'))) {
				this.showPopoverWithTimeout();
			} else if (lotteryData && lotteryData.id) {
				$(window).on('lotteryPopupClosed', () => {
					this.showPopoverWithTimeout();
				});
			}
		});
	}

	showPopoverWithTimeout() {
		setTimeout(() => {
			popoverStackView.push({
				view: this,
				popoverClass: 'paylateInformer',
				popoverTimeout: HIDE_COUNTDOWN
			});
			ls.set(LS_NAME,moment().format("DD-MM-YYYY"));
		}, 3000);
	}
}