import { BaseState } from '@ve/services/getStore/types';
import { Store } from 'vuex';
import getStore from '@ve/services/getStore/getStore';
import { BackboneSearchDataModel } from '@ve/types/backboneModels/BackboneSearchDataModel';
import ProcessCountries from '../adapters/ProcessCountries';
import ProcessDepartureCity from '../adapters/ProcessDepartureCity';
import processCheckInDateRange from '../adapters/processCheckInDateRange';
import checkInDateRangeIsFlexible from './getters/checkInDateRangeIsFlexible';

export const NAMESPACE = 'mapForSearchData';

export default ({
                    model,
                }: {
    model: BackboneSearchDataModel
}): Store<BaseState> => getStore({
    name: NAMESPACE,
    backboneMap: [
        {
            fields: [{
                searchDepartureCity: {
                    field: 'searchData',
                    action: (_model, searchData: BackboneSearchDataModel) => ProcessDepartureCity(searchData.get('departureCity')),
                },
                searchCountries: {
                    field: 'searchData',
                    action: (_model, searchData: BackboneSearchDataModel) => ProcessCountries(searchData.get('countries')),
                },
                checkInDateRange: {
                    field: 'searchData',
                    action: (_model, searchData: BackboneSearchDataModel) => processCheckInDateRange(searchData.get('checkInDateRange')),
                },
            }],
            model,
        },
    ],
    storeOptions: {
        getters: {
            checkInDateRangeIsFlexible,
        },
    },
});