/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';

import './tourHunterSubscribe.css';
import accountModel from "@/js/desktop/components/account/accountModel";
import tourHunterService from "@/js/common/Domain/Service/tourHunter";
import account from '@/js/desktop/components/account'
import template from './template.html';
import gaAsync from "@/js/common/Domain/Service/gaAsync";

var submitTourHunterProxy;

var TourHunterSubscribeModel = Backbone.Epoxy.Model.extend({
	defaults: {
		email: '',
		viewModelState: 'subscribe',
		thLkUrl: window.appConfig.accountUrl + '#/tourhunter',
		requestInProgress: false,
		currentSubscribeBanner: false,
		errorMsg: false,
		tourCriteria: null,
		position: 0,
		subscribeStateHTML: "Добавьте направление в Турхантер, и мы пришлем вам письмо,<br>когда цена на туры по этому направлению снизится."
	},
	computeds: {
		departureCityText: {
			deps: ['tourCriteria'],
			get: function (tourCriteria) {
				var departureCity = tourCriteria ? tourCriteria.get('departureCity') : false;
				return departureCity ? departureCity.get('name') : '';
			}
		},
		checkInDateRangeText: {
			deps: ['tourCriteria'],
			get: function (tourCriteria) {
				var checkInDateRange = tourCriteria ? tourCriteria.get('checkInDateRange') : false,
					checkInDateRangeFrom = checkInDateRange ? moment(checkInDateRange.get('from')) : false,
					checkInDateRangeTo = checkInDateRange ? moment(checkInDateRange.get('to')) : false,
					checkInDateRangeText = '';

				if (checkInDateRangeFrom && checkInDateRangeTo) {
					checkInDateRangeText = checkInDateRangeFrom.format('DD.MM.YY');

					if (checkInDateRangeFrom.diff(checkInDateRangeTo)) {
						checkInDateRangeText += ' по ' + checkInDateRangeTo.format('DD.MM.YY');
					}
				}

				return checkInDateRangeText
			}
		},
		nightsText: {
			deps: ['tourCriteria'],
			get: function (tourCriteria) {
				var nightRange = tourCriteria ? tourCriteria.get('nightRange') : false,
					nightRangeFrom = nightRange ? nightRange.get('from') : false,
					nightRangeTo = nightRange ? nightRange.get('to') : false,
					nightsText = 'на ';

				if (nightRangeFrom && nightRangeTo) {
					if (nightRangeFrom == nightRangeTo) {
						nightsText += nightRangeFrom + ' ноч' + nightRangeFrom.wordEnding('ь;и;ей');
					} else {
						nightsText += nightRangeFrom + '-' + nightRangeTo + ' ноч' + nightRangeTo.wordEnding('ь;и;ей');
					}
				}

				return nightsText;
			}
		}
	}
});

let TourHunterSubscribeView = Backbone.Epoxy.View.extend({
	bindings: {
		'.tourHunterSubscribeBanner__subscribeState': 'toggle:equal(viewModelState, "subscribe")',
		'.tourHunterSubscribeBanner__successState': 'toggle:equal(viewModelState, "success")',
		'.tourHunterSubscribeBanner__departureCityText': 'text:departureCityText',
		'.tourHunterSubscribeBanner__checkInDateRangeText': 'text:checkInDateRangeText',
		'.tourHunterSubscribeBanner__nightsText': 'text:nightsText',
		'.tourHunterSubscribeBanner__subscribeState p': 'html:subscribeStateHTML'
	},
	initDomEvents:function() {
		this.$el.on('click', '.tourHunterSubscribeBanner__subscribeButton', this.subscribeButtonClick.bind(this));
		this.$el.on('click', '.tourHunterSubscribeBanner__goToLk', this.goToLk.bind(this));
	},
	goToLk: function () {
		window.open(this.viewModel.get('thLkUrl'), '_blank');
	},
	reset: function () {
		this.viewModel.set('viewModelState', 'subscribe');
	},
	render: function () {
		this.$el.append(template());
	},
	initModelEvents: function () {
		var self = this;

		this.viewModel.mapState('email', accountModel);
	},
	subscribeButtonClick:async function () {
		this.trackingService.bannerThClick(accountModel.get('authorized'));

		this.pushDataLayer('Турхантер_Следить за ценами');
		if (accountModel.get('authorized')) {
			this.submitTourHunter();
		} else {
			this.pushDataLayer('Авторизация_Войти');
			await this.openAuthorizeForm();
			this.addAuthorizeSubscribes();
		}
	},
	pushDataLayer: function(value) {
		const position = this.viewModel.get('position');
		window.dataLayer.push({
			event: 'selectContent',
			selectContent:
			{ 
				prop: [
					{ type: 'engageBanner', value }
				],
				prop2: [
					{ type: 'position', value: position }
				],
			}
		});
	},
	clearErrors: function () {
		this.viewModel.set('errorMsg', false);
	},
	currentSubscribeBanner: function () {
		return this.viewModel.get('currentSubscribeBanner');
	},
	submitTourHunter: function (callback) {
		var self = this,
			tourCriteria = this.getTourCriteria(),
			email = self.viewModel.get('email');

		this.viewModel.set('tourCriteria', tourCriteria);

		gaAsync(function () {
			ga('send', 'event', 'Tourhunter', 'follow the price click', window.location.href, {'nonInteraction': 1})
		});

		this.clearErrors();

		if (email && tourCriteria) {
			if (!this.model.get('tourHunterSubscribeInProgress')) {
				this.model.set('tourHunterSubscribeInProgress', 1);
				this.viewModel.set('tourCriteria', tourCriteria);

				tourHunterService.subscribe({
					data: {
						email: email,
						tourCriteria: tourCriteria,
						minPrice: this.model.get('minPrice')
					},
					success: function (response) {
						self.model.set('tourHunterSubscribeInProgress', 0);
						if (response.success == true) {
							self.viewModel.set('viewModelState', 'success');

							self.viewModel.set('currentSubscribeBanner', true);

							if (typeof (yaCounter11881159) !== 'undefined') {
								yaCounter11881159.reachGoal('Subscription');
							}
							callback && callback.success ? callback.success(tourCriteria) : '';
						}
					},
					error: function (response) {
						self.model.set('tourHunterSubscribeInProgress', 0);
						self.viewModel.set('errorMsg', response && response.message ? response.message : 'Что-то пошло не так. Попробуйе повторить попытку через некоторое время');
					}
				});
			}
		}
	},
	openAuthorizeForm:async function (position) {
		await account.lazyAuthorizeInit();
		account.authorize.show(position);
	},
	addAuthorizeSubscribes: function () {
		//prevent multiple subscribe events
		if (submitTourHunterProxy) {
			account.authorize.model.off('change:authorized', submitTourHunterProxy);
			account.authorize.model.off('needToVerifyUser', submitTourHunterProxy);
		}

		submitTourHunterProxy = $.proxy(this.submitTourHunter, this);
		account.authorize.model.once('change:authorized', submitTourHunterProxy);
		account.authorize.model.once('needToVerifyUser', submitTourHunterProxy);

	},
	getTourCriteria: function () {
		return this.searchFormViewModel.getTourCriteria();
	},
	initialize: function (options) {
		this.render();
		this.viewModel = new TourHunterSubscribeModel();
		this.trackingService = options.trackingService;
		if (options.subscribeStateHTML) {
			this.viewModel.set("subscribeStateHTML", options.subscribeStateHTML)
		}
		this.viewModel.set('position', options.hotelIndex);
		this.model = options.searchPageModel;
		this.searchFormViewModel = options.searchFormViewModel;
		
		if (!window.dataLayer) window.dataLayer = [];
		this.initModelEvents();
		setTimeout(() => {
			this.initDomEvents();
		}, 50);
	}
});

export default TourHunterSubscribeView;