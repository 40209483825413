/* eslint-disable */
let DestinationSearchFieldView = Backbone.View.extend({
	parent: null,
	$input: function () {
		this.$input = $('input', this.$el);
	},
	$dropdownContainer: function () {
		this.$dropdownContainer = this.$el.parents('.formDropdown');
	},
	$addControl: function () {
		this.$addControl = $('.add', this.$el);
	},
	$clearControl: function () {
		this.$clearControl = $('.close', this.$el);
	},
	$hintInput: function () {
		this.$hintInput = $('.hint', this.$el);
	},
	$errorField: function () {
		this.$errorField = $('.errorLabel', this.$el);
	},
	hideDropdownIfClickOutside: function (event) {
		var self = this,
			$dropdowns = this.$dropdownContainer,
			$hideDropdowns,
			clickedContainer = $(event.target).parents('.formDropdown'),
			clickedDropdown = $(event.target);

		$hideDropdowns = $dropdowns.not(clickedContainer).not(clickedDropdown);

		if ($hideDropdowns.length) {
			this.model.set('controlActivated', false);
		}
		this.hideEmptyDestinationError();
	},
	escKeyPressed: function () {
		this.model.set('controlActivated', false);
	},
	killEvent: function (event) {
		var controlActivated = this.model.get('controlActivated');

		if (controlActivated) {
			event.preventDefault();
			event.stopPropagation();
		}
	},
	selectMatched: function () {
		var currentInputSelected = this.model.get('currentInputSelected'),
			inputHint = this.model.get('inputHint'),
			optionsList = this.model.get('optionsList');

		if (!currentInputSelected && optionsList && inputHint !== false) {
			this.parent.selectItemByModel(optionsList[0]);
		}
	},
	autocompleteStringIfHasOptions: function () {
		var optionsList = this.model.get('optionsList'),
			inputHint = this.model.get('inputHint');

		if (optionsList && inputHint) {
			this.parent.selectItemByModel(optionsList[0]);
		}
	},
	clearInput: function () {
		var originalLabelChanged = this.model.get('originalLabelChanged'),
			currentLabel = this.model.get('label');

		if (originalLabelChanged) {
			this.hideEmptyDestinationError();
			this.$input.val(currentLabel);
			this.model.set('inputString', currentLabel, {initiator: "destView"});
		} else {
			this.$input.prop('readonly', false);
			this.$input.val('');
			this.model.set('inputString', '', {initiator: "destView"});
			this.$input.focus();

		}
	},
	initDOMEvents: function () {
		var self = this;

		$(document).click($.proxy(this.hideDropdownIfClickOutside, this));

		$('.formInput', this.$dropdownContainer).on('click focus', $.proxy(this.activateInputChangeOnClick, this));
		this.$input.on('focus', $.proxy(this.activateInputChangeOnClick, this));

		this.$input.bind('paste', function (e) {
			var _self = this;
			setTimeout(function () {
				var value = $(_self).val();
				self.model.set('inputString', value, {initiator: 'destView'});
			}, 10);
		});

		this.$input.on('keyup', function (event) {
			var value = $(this).val();
			self.model.set('inputString', value, {initiator: 'destView'});
		});

		this.$clearControl.on('click', $.proxy(this.clearInput, this));
	},
	activateInputChangeOnClick: function () {
		var selectedItems = this.model.get('selectedItems'),
			$formInput = $('.formInput', this.$dropdownContainer);

		if (selectedItems !== null && !$formInput.hasClass('close')) {
			this.model.set('controlActivated', true);
		}
	},
	updateInputVisibility: function () {
		var selectedItems = this.model.get('selectedItems');

		if (selectedItems == null) {
			this.$input.hide();
			this.$dropdownContainer.addClass('disable');
		} else {
			this.$input.show();
			this.$dropdownContainer.removeClass('disable');
		}
	},

	updateInputLabel: function () {
		var label = this.model.get('label');

		if (label) {
			this.$input.val(label);
			this.model.set('inputString', label);
		}
	},
	updateinputDisableState: function () {
		var selectedResorts = this.model.get('selectedResorts'),
			selectedHotels = this.model.get('selectedHotels');

		if ((selectedResorts && selectedResorts.length > 1) || (selectedHotels && selectedHotels.length > 1)) {
			this.$input.prop('readonly', true);
		} else {
			this.$input.prop('readonly', false);
		}
	},
	updateClearControlVisibility: function () {
		var inputStringValue = this.model.get('inputString'),
			selectedResorts = this.model.get('selectedResorts'),
			selectedCountry = this.model.get('selectedCountry'),
			selectedHotels = this.model.get('selectedHotels'),
			controlActivated = this.model.get('controlActivated');

		if (inputStringValue != "" && controlActivated && (selectedHotels || selectedCountry || selectedResorts)) {
			this.$clearControl.show();
		} else {
			this.$clearControl.hide();
		}
	},
	updateActiveState: function () {
		var controlActivatedState = this.model.get('controlActivated');

		if (controlActivatedState) {
			this.$dropdownContainer.addClass('open focused');
			this.dataLayerAnalyticsEvent();
		} else {
			this.$dropdownContainer.removeClass('open focused');
			this.$el.removeClass('invalid');
		}
	},
	dataLayerAnalyticsEvent(){
		if(this.dataLayerAnalytics){
			this.dataLayerAnalytics.searchFieldsEvent('countryToOpen')
		}
	},
	placeholder: 'Выберите направление',
	updateHintInput: function () {
		var inputStringValue = this.model.get('inputString'),
			inputHint = this.model.get('inputHint');

		if (inputStringValue == '') {
			this.$hintInput.html(this.placeholder);
			this.$hintInput.show();
		} else {
			if (inputHint) {
				this.$hintInput.html('<span>' + inputStringValue + '</span>' + inputHint);
				this.$hintInput.show();
			} else {
				this.$hintInput.hide();
			}
		}
	},

	selectedItemsChanged: function (model, item, context) {
		this.updateInputVisibility();
		this.updateInputLabel();
		this.updateinputDisableState();
		this.updateClearControlVisibility();
		this.updateHintInput();
	},
	hideEmptyDestinationError: function () {
		this.$errorField.hide();
	},
	showEmptyDestinationError: function () {
		var errorText = this.model.get('inputString') == '' ? 'Начните с выбора направления' : 'Неверное направление';
		$('i', this.$errorField).text(errorText);
		this.$errorField.show();
	},
	updateInputString: function () {
		var controlActivatedState = this.model.get('controlActivated'),
			selectedCountry = this.model.get('selectedCountry'),
			selectedHotels = this.model.get('selectedHotels'),
			selectedResorts = this.model.get('selectedResorts'),
			alreadySelectedCountry = $.cookie('countrySelected');

		if (controlActivatedState && ((!alreadySelectedCountry && !selectedResorts) || this.parent.emptyDestination)) {
			this.hideEmptyDestinationError();
			this.$input.focus();
		} else if (controlActivatedState && ((selectedHotels && selectedHotels.length <= 1) || (selectedResorts && selectedResorts.length <= 1) || selectedCountry)) {
			this.$input.select();
		} else {
			this.updateInputLabel();
			this.$input.blur();
		}
	},
	controlActivatedChanged: function () {
		this.updateInputString();
		this.updateActiveState();
		this.updateClearControlVisibility();
		this.updateinputDisableState();
		this.updateHintInput();
	},
	inputStringChanged: function () {
		var selectedItems = this.model.get('selectedItems');

		this.updateHintInput();
		if (selectedItems) {
			this.updateClearControlVisibility();
		}
	},
	initModelEvents: function () {
		this.model.on('change:selectedItems', $.proxy(this.selectedItemsChanged, this));
		this.model.on('change:controlActivated', $.proxy(this.controlActivatedChanged, this));
		this.model.on('change:inputString', $.proxy(this.inputStringChanged, this));
		this.model.on('change:optionsList', $.proxy(this.updateHintInput, this));
	},
	initialize: function (options) {
		this.parent = options.parent;
		this.dataLayerAnalytics = options.dataLayerAnalytics;
		this.$el.unbind('click');
		this.$input();
		this.$dropdownContainer();
		this.$clearControl();
		this.$hintInput();
		this.$errorField();
		this.$addControl();

		this.initDOMEvents();
		this.initModelEvents();
	}
});

export default DestinationSearchFieldView;