<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'box',
            },
            width: {
                type: [Number, String],
                default: 18,
            },
            height: {
                type: [Number, String],
                default: 18,
            },
            iconColor: {
                type: String,
                default: 'currentColor',
            },
            realWidth: {
                type: [Number, String],
                default: null,
            },
            realHeight: {
                type: [Number, String],
                default: null,
            },
        },
    };
</script>

<template>
    <div
        :class='`${iconName}__wrapper icon`'
        style="display: flex"
    >
        <svg xmlns="http://www.w3.org/2000/svg"
             :width="width"
             :height="height"
             :viewBox='`0 0 ${realWidth ? realWidth : width} ${realHeight ? realHeight : height}`'
             :aria-labelledby="iconName"
             role="presentation"
        >
            <g :fill="iconColor">
                <slot/>
            </g>
        </svg>
    </div>
</template>