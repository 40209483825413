/* eslint-disable */
import ResortsRepository from '../Repository/Resorts';
import {TrackJS} from "trackjs";

const CountryEntity = Backbone.Model.extend({
	defaults: {
		id: null,
		name: null,
		isPopular: null,
		position: null,
		regionTourCriteria: null,
		capitalTourCriteria: null,
		visaRequired: null,
		visaDays: null,
		visaOnline: null,
		resorts: null,
		tourCriteria: null,
		nameEng: null,
		minPrice: null
	},
	cache: {},
	getResorts: function (callback) {
		var resorts = this.get('resorts'),
			countryId = this.get('id'),
			self = this,
			cacheData = self.cache[countryId],
			options = {
				url: window.appConfig && window.appConfig.getResorts ? (window.appConfig.gatewayUrl + window.appConfig.getResorts) : '',
				data: {
					country: countryId
				},
				dataType: "jsonp"
			},
			createAjaxRequest = function () {
				return new Promise((resolve, reject) => {
					$.ajax(options)
					.success(function (response) {
						if (response.success === true) {
							var resortsCollection = new ResortsRepository(),
								resorts = response.data;

							_.each(resorts, function (resort) {
								resort.country = self;
								return resort;
							});
							resortsCollection.addEntry(resorts, {merge: true});

							resolve(resortsCollection);
							self.cache[countryId] = true;
						} else {
							reject(this, response.message);
						}
					}).fail((XMLHttpRequest, textStatus, errorThrown) => {
						reject(XMLHttpRequest, textStatus, errorThrown);
					});
				});

			},
			fail = function (textStatus) {
				callback && callback.error ? callback.error() : '';
				if (textStatus !== "abort") {
					console.log(textStatus);
					console.log(options);
					TrackJS.track("No resorts returned!!");
				}
			};

		if (cacheData && !(cacheData instanceof Promise) && resorts) {
			callback && callback.success ? callback.success(resorts) : '';
		} else {
			if (!(cacheData && cacheData instanceof Promise)) {
				cacheData = self.cache[countryId] = createAjaxRequest();
			}

			cacheData.then(function (resortsCollection) {
				self.set('resorts', resortsCollection);
				callback && callback.success ? callback.success(resortsCollection) : '';
			}, function (XMLHttpRequest, textStatus, errorThrown) {
				fail(textStatus);
			});
		}
	},
	getCountryModelById: function (countryId) {
		const newContryModel = this.collection.find((countryModel) => countryModel.get('id') === countryId);
		return newContryModel;
	},
	getRussia: function () {
		return this.collection.find((countryModel) => countryModel.get('id') === 76);
	}
});

export default CountryEntity;