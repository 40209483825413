import AbstractTrackingService from '@ve/services/analytics/yandexAnalytics/abstractTrackingService';

export default class SerpTrackingService extends AbstractTrackingService {
    private static _instance: SerpTrackingService;

    protected trackingServicePage = 'serp';

    constructor() {
        super();
        if (SerpTrackingService._instance) {
            return SerpTrackingService._instance;
        }
        SerpTrackingService._instance = this;
    }

    resortClick(): void {
        this.setTrackingGoal('serp_hotelcard_city', 'click');
    }

    filterResortClick(): void {
        this.setTrackingGoal('serp_filter_resort_click', 'click');
    }

    filterBySearchHotelInput(): void {
        this.setTrackingGoal('serp_find_hotel_name_enter', 'input');
    }

    filterBySearchHotelStart(): void {
        this.setTrackingGoal('serp_find_hotel_name_start', 'click');
    }

    discountClick(): void {
        this.setTrackingGoal('serp_chips_sale_click', 'click');
    }

    toggleMap(): void {
        this.setTrackingGoal('serp_find_hotel_click', 'click');
    }

    orderCountClick(): void {
        this.setTrackingGoal('serp_chips_booking_click', 'click');
    }

    lessPlacesClick() :void {
        this.setTrackingGoal('serp_chips_fewplaces_click', 'click');
    }

    sliderArrowClick() {
        this.setTrackingGoal('serp_hotelcard_photoslide_click', 'click');
    }

    allOffersClick(): void {
        this.setTrackingGoal('serp_click_all_variants', 'click');
    }

    popularOffersByFiltersLessTen(): void {
        this.setTrackingGoal('serp_filter_rule10', 'click');
    }

    popularOffersLessTen(): void {
        this.setTrackingGoal('serp_default_main_rule10', 'click');
    }

    public bannerThClick(isUserAuth: boolean): void {
        this.bannerThTracking(isUserAuth, 'region');
    }

    clickTourButtonWithConfirmation(typeConfirmation: string): void {
        this.setTrackingGoal('serp_tours_on_request', 'click', { typeConfirmation });
    }

    serpPriceTooltipMouseOver(): void {
        this.setTrackingGoal('serp_price_tooltip', 'other');
    }
}
