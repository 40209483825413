var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alert-block",class:[
        `alert-block_bg-${_vm.color}`,
        `alert-block_size-${_vm.size}`,
        {
            'alert-block_border': _vm.border,
        },
    ]},[_c('div',{staticClass:"alert-block__icon"},[_vm._t("icon")],2),_vm._v(" "),(_vm.$slots.header)?_c('div',{staticClass:"alert-block__header"},[_vm._t("header")],2):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('div',{staticClass:"alert-block__content"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }