const turkeyHotels = {
  49554: true,
  49183: true,
  48779: true,
  48669: true,
  49614: true,
  49513: true,
  105110: true,
  138032: true,
  129815: true,
  48228: true,
  48107: true,
  48461: true,
  49187: true,
  48159: true,
  47531: true,
  48176: true,
  48592: true,
  49592: true,
  49626: true,
  47595: true,
  47987: true,
  48681: true,
  48988: true,
  47678: true,
  47992: true,
  47435: true,
  47560: true,
  47812: true,
  281805: true,
  49629: true,
  49507: true,
  139083: true,
  49100: true,
  284322: true,
  48401: true,
  85532: true,
  49612: true,
  48525: true,
  49161: true,
  47474: true,
  47415: true,
  130318: true,
  47991: true,
  47950: true,
  47477: true,
  48229: true,
  48370: true,
  98237: true,
  116550: true,
  49134: true,
  47414: true,
  48327: true,
  47551: true,
  49180: true,
  48175: true,
  48775: true,
  49225: true,
  142439: true,
  48931: true,
  47385: true,
  47380: true,
  47391: true,
  47565: true,
  47635: true,
  47674: true,
  47722: true,
  47822: true,
  47825: true,
  47867: true,
  47996: true,
  48315: true,
  48347: true,
  48388: true,
  48392: true,
  48542: true,
  48624: true,
  48640: true,
  48657: true,
  48670: true,
  48719: true,
  48785: true,
  48796: true,
  48853: true,
  48856: true,
  48899: true,
  48961: true,
  49315: true,
  49336: true,
  49363: true,
  49440: true,
  49551: true,
  49564: true,
  49571: true,
  49611: true,
  107621: true,
  131727: true,
  133391: true,
  139823: true,
  143449: true,
  180202: true,
  266585: true,
  287976: true,
  288256: true,
  316345: true,
  322875: true,
  47513: true,
  47572: true,
  47667: true,
  47848: true,
  47936: true,
  47994: true,
  48151: true,
  48152: true,
  48196: true,
  48443: true,
  48505: true,
  48506: true,
  48541: true,
  48588: true,
  48762: true,
  48780: true,
  48794: true,
  48897: true,
  49006: true,
  49030: true,
  49031: true,
  49199: true,
  49316: true,
  49347: true,
  49566: true,
  49585: true,
  274787: true,
  316312: true,
  47388: true,
  47396: true,
  47799: true,
  47866: true,
  47872: true,
  47910: true,
  48028: true,
  48051: true,
  48132: true,
  48199: true,
  48394: true,
  48455: true,
  48504: true,
  48625: true,
  48748: true,
  48751: true,
  49073: true,
  49110: true,
  49120: true,
  49149: true,
  49288: true,
  49409: true,
  49544: true,
  98408: true,
  98480: true,
  98723: true,
  101403: true,
  105249: true,
  105544: true,
  105556: true,
  105648: true,
  120357: true,
  129407: true,
  319310: true,
  47410: true,
  47479: true,
  47661: true,
  47800: true,
  47947: true,
  47958: true,
  47962: true,
  47971: true,
  47990: true,
  47995: true,
  47998: true,
  48025: true,
  48053: true,
  48440: true,
  48456: true,
  48554: true,
  48655: true,
  48664: true,
  48733: true,
  48774: true,
  48934: true,
  48952: true,
  48996: true,
  49008: true,
  49077: true,
  49099: true,
  49213: true,
  49223: true,
  49320: true,
  49372: true,
  49421: true,
  98387: true,
  100566: true,
  105133: true,
  105590: true,
  116560: true,
  127286: true,
  129406: true,
  133806: true,
  144011: true,
  277443: true,
  288131: true,
  308790: true,
  101036: true,
  49407: true,
  105139: true,
  49601: true,
  105521: true,
  139482: true,
  49394: true,
  47433: true,
  47434: true,
  47495: true,
  47508: true,
  47611: true,
  47784: true,
  47968: true,
  48005: true,
  48682: true,
  48871: true,
  49127: true,
  49312: true,
  49509: true,
  49541: true,
  49588: true,
  87471: true,
  97718: true,
  98100: true,
  100815: true,
  101316: true,
  104762: true,
  116351: true,
  119659: true,
  128436: true,
  128538: true,
  274865: true,
  274914: true,
  303229: true,
  119715: true,
  49596: true,
  48539: true,
  47642: true,
  49122: true,
  48069: true,
  288965: true,
  49605: true,
  275538: true,
  284903: true,
  117311: true,
  321923: true,
  324410: true,
  48376: true,
  49433: true,
  48060: true,
  194293: true,
  282411: true,
  117307: true,
  301766: true,
  119713: true,
  48548: true,
  48002: true,
  132502: true,
  98427: true,
  49469: true,
  104259: true,
  49156: true,
  48214: true,
  49029: true,
  116541: true,
  97881: true,
  277267: true,
  49151: true,
  48217: true,
  47787: true,
  49154: true,
  274939: true,
  49153: true,
  47785: true,
  48216: true,
  104599: true,
  330848: true,
  104267: true,
  131039: true,
  47791: true,
  47416: true,
  85639: true,
  316277: true,
  47748: true,
  49263: true,
  85945: true,
  47883: true,
  47943: true,
  48743: true,
  286050: true,
  49490: true,
  49354: true,
  48224: true,
  333365: true,
  48257: true,
  337508: true,
  48314: true,
  48313: true,
  47891: true,
  48346: true,
  129755: true,
  48560: true,
  107254: true,
  105107: true,
  278066: true,
  162602: true,
  107265: true,
  48806: true,
  48808: true,
  48365: true,
  48745: true,
  47870: true,
  48935: true,
  106242: true,
  49078: true,
  49115: true,
  104268: true,
  49537: true,
  49155: true,
  48273: true,
  104383: true,
  49191: true,
  49218: true,
  48722: true,
  105683: true,
  49293: true,
  49302: true,
  47942: true,
  49244: true,
  49359: true,
  100570: true,
  49369: true,
  143028: true,
  278667: true,
  278877: true,
  49634: true,
  48426: true,
  334563: true,
  116341: true,
  49617: true,
  49649: true,
  47417: true,
  48747: true,
  48373: true,
  324922: true,
  48807: true,
  319299: true,
  49303: true,
  106231: true,
  49358: true,
  49607: true,
  49631: true,
};

const UAEHotels = {
  11918: true,
  11972: true,
  11974: true,
  11978: true,
  11981: true,
  12007: true,
  12025: true,
  12031: true,
  12050: true,
  12089: true,
  12102: true,
  12151: true,
  12155: true,
  12162: true,
  12164: true,
  12226: true,
  12232: true,
  12236: true,
  12239: true,
  12242: true,
  12269: true,
  12276: true,
  12311: true,
  12321: true,
  12325: true,
  12368: true,
  12440: true,
  97103: true,
  100430: true,
  100737: true,
  100916: true,
  104802: true,
  106524: true,
  108244: true,
  111895: true,
  113687: true,
  115474: true,
  124801: true,
  129344: true,
  129349: true,
  130373: true,
  131411: true,
  133332: true,
  133681: true,
  134531: true,
  134533: true,
  135532: true,
  135554: true,
  136194: true,
  136839: true,
  136942: true,
  137272: true,
  137516: true,
  139744: true,
  139988: true,
  140422: true,
  140698: true,
  140702: true,
  140818: true,
  141436: true,
  142207: true,
  142339: true,
  144194: true,
  274722: true,
  274986: true,
  275692: true,
  275778: true,
  279830: true,
  280865: true,
  281655: true,
  282659: true,
  284229: true,
  284941: true,
  285952: true,
  286036: true,
  286568: true,
  291980: true,
  292644: true,
  294583: true,
  309740: true,
  317048: true,
  317598: true,
};
const thailandHotels = {
  15571: true,
  15625: true,
  15637: true,
  15676: true,
  15685: true,
  15698: true,
  15751: true,
  15771: true,
  15787: true,
  15789: true,
  15790: true,
  15838: true,
  15886: true,
  15887: true,
  15977: true,
  15986: true,
  15989: true,
  15996: true,
  16112: true,
  16121: true,
  16133: true,
  16155: true,
  16228: true,
  16301: true,
  16371: true,
  16380: true,
  16405: true,
  16438: true,
  16445: true,
  16459: true,
  16532: true,
  16540: true,
  16565: true,
  16575: true,
  16600: true,
  16615: true,
  16648: true,
  16656: true,
  16764: true,
  16774: true,
  16808: true,
  16835: true,
  16856: true,
  16909: true,
  16911: true,
  16931: true,
  16979: true,
  17011: true,
  17066: true,
  17068: true,
  65330: true,
  65331: true,
  65332: true,
  65341: true,
  65532: true,
  65639: true,
  65768: true,
  65781: true,
  65853: true,
  65859: true,
  65955: true,
  104844: true,
  105340: true,
  105847: true,
  105863: true,
  105877: true,
  105879: true,
  106446: true,
  106451: true,
  106660: true,
  106715: true,
  106849: true,
  108698: true,
  109077: true,
  109086: true,
  109704: true,
  110155: true,
  111225: true,
  111629: true,
  115738: true,
  120485: true,
  120490: true,
  120532: true,
  122854: true,
  129736: true,
  130450: true,
  130472: true,
  131452: true,
  132050: true,
  132293: true,
  132375: true,
  132944: true,
  132989: true,
  133369: true,
  133426: true,
  134979: true,
  136943: true,
  139411: true,
  139574: true,
  142316: true,
  142620: true,
  144044: true,
  196800: true,
  237587: true,
  263409: true,
  264559: true,
  275561: true,
  275701: true,
  286627: true,
  305134: true,
  306995: true,
  307809: true,
  321763: true,
  327029: true,
  328492: true,
};

export default {
  ...turkeyHotels,
  ...UAEHotels,
  ...thailandHotels,
};
