import { MutationTree, GetterTree } from 'vuex';
import { wordEnding } from '@shared/lib';
import { TouristGroup } from '../types/TouristGroup';

export interface TouristGroupState {
    adults: number;
    ages: number[];
    infants: number;
    kids: number;
}

export interface TouristGroupMutations extends MutationTree<TouristGroupState> {
    setAdults(state: TouristGroupState, adults: number): void;
    setAges(state: TouristGroupState, ages: number[]): void;
    setInfants(state: TouristGroupState, infants: number): void;
    setKids(state: TouristGroupState, kids: number): void;
}

export interface TouristGroupGetters extends GetterTree<TouristGroupState, any>{
    touristGroup(state: TouristGroupState): TouristGroup;
    touristGroupText(state: TouristGroupState): string;
}

export interface TouristGroupStore {
    state: TouristGroupState,
    mutations: TouristGroupMutations,
    getters: TouristGroupGetters,
}

export const NAMESPACE = 'touristGroupStore';

export default {
    namespaced: true,
    state: {
        adults: 0,
        ages: [],
        infants: 0,
        kids: 0,
    },
    getters: {
        touristGroup(state) {
            return {
                adults: state.adults,
                ages: state.ages,
                kids: state.kids,
                infants: state.infants,
            };
        },

        touristGroupText(state) {
            const { adults, kids, infants } = state;
            const adultsText = `${adults} взросл${wordEnding(adults, 'ый;ых;ых')}`;
            const kidsTotal = kids + infants;

            if (kidsTotal > 0) {
                const kidsEnding = kids > 0 ? 'ребенок;детей;детей' : 'младенец;младенца;младенцев';

                return `${adultsText} и ${kidsTotal} ${wordEnding(kidsTotal, kidsEnding)}`;
            }
            return adultsText;
        },
    },
    mutations: {
        setAdults(state, adults) {
            state.adults = adults;
        },
        setAges(state, ages) {
            state.ages = ages;
        },
        setInfants(state, infants) {
            state.infants = infants;
        },
        setKids(state, adults) {
            state.kids = adults;
        },
    },
} as TouristGroupStore;