import Backbone from 'backbone';
import { NightRange } from '@ve/types';

export default function (model: Backbone.Model): NightRange | null {
    if (!model) return null;

    return {
        from: model.get('from'),
        to: model.get('to'),
    };
}
