import _ from 'underscore';
import { ApiTourConstructor, TourProduct } from '@ve/types';
import flightTypeAdapter from '@/js/common/Domain/Service/searchTour/service/apiTourToTourAdapter/flightTypeAdapter';

import routesAdapter from './routesAdapter';

export default (tourData: ApiTourConstructor): TourProduct => {
    const tourProduct: TourProduct = {
        ..._.pick(tourData,
            'price',
            'discount',
            'medicalInsurance',
            'availableTransfers',
            'firstPaymentDefinitions'),
        type: 'TourProduct',
        transferType: tourData.transfer,
        tour: {
            ..._.pick(tourData,
                'sortRate',
                'checkInDate',
                'id',
                'meal',
                'departureCity',
                'country',
                'resort',
                'hotel',
                'cashbackAvailable',
                'operator',
                'price',
                'oilTax',
                'room',
                'touristGroup',
                'hotelAvailable',
                'flightSpecificationPrice',
                'freeCancellation',
                'hotelCategory',
                'nights'),
            flightType: flightTypeAdapter(tourData.flightType),
            originalPrice: tourData.maxHistoricalPrice,
            routes: routesAdapter(tourData),
        },
        availableExtraBaggageArray: tourData.extraBaggage?.length ? tourData.extraBaggage : [],
    };

    if (tourData.transfers) {
        tourProduct.availableTransfers = tourData.transfers;
    }
    return tourProduct;
};