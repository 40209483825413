import accountModel from '@/js/desktop/components/account/accountModel';

export default () => new Promise((resolve) => {
    const handler = () => {
        resolve(accountModel.get('hash'));
    };

    if (accountModel.get('profileRequestInProgress')) {
        accountModel.on('change:profileRequestInProgress', handler);
    } else {
        handler();
    }
});