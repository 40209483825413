import moment from 'moment';
import 'moment/locale/ru';

export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('checkInDateRange');
    if (element) {
        tourCriteriaJson.checkInDateRange = {};
        const from = element.get('from');
        const to = element.get('to');
        if (from) {
            const fromMoment = moment(from);
            if (fromMoment.isValid()) {
                tourCriteriaJson.checkInDateRange.from = fromMoment.format('YYYY-MM-DD');
            }
        }
        if (to) {
            const toMoment = moment(to);
            if (toMoment.isValid()) {
                tourCriteriaJson.checkInDateRange.to = toMoment.format('YYYY-MM-DD');
            }
        }
    }

    return tourCriteriaJson;
};