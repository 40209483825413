import { BaseState } from '@ve/services/getStore/types';
import { Store } from 'vuex';
import getStore from '@ve/services/getStore/getStore';
import { BackboneSearchFormModel } from '@ve/types/backboneModels/BackboneSearchFormModel';
import { BackboneResortsRepository } from '@ve/types/backboneModels/Domain/Repository';
import _ from 'underscore';
import { Country } from '@ve/types/Country';
import ProcessNightRange from '@ve/pages/serp/adapters/ProcessNightRange';
import ProcessCheckInDateRange from '@ve/pages/serp/adapters/processCheckInDateRange';
import Backbone from 'backbone';
import ProcessCountries from '../adapters/ProcessCountries';
import ProcessDepartureCity from '../adapters/ProcessDepartureCity';
import processResortsAdapter from '../adapters/ProcessResortsAdapter';
import ProcessTouristGroup from '../adapters/ProcessTouristGroup';

export const NAMESPACE = 'mapForSearchForm';

export default ({
                    model,
                }: {
    model: BackboneSearchFormModel,
}): Store<BaseState> => getStore({
    name: NAMESPACE,
    backboneMap: [
        {
            fields: [{
                departureCity: {
                    field: 'model',
                    action: (_model: never) => ProcessDepartureCity(model.get('departureCity').get('model')),
                } as any,
            },
            ],
            model: model.get('departureCity'),
        },
        {
            fields: [{
                countries: {
                    field: 'model',
                    action: (_model: never) => ProcessCountries(model.get('countries').get('model')),
                } as any,
            },
            ],
            model: model.get('countries'),
        },
        {
            fields: [{
                resorts: {
                    field: 'model',
                    action: (_model: any, resorts: BackboneResortsRepository) => processResortsAdapter(resorts),
                },
            }],
            model: model.get('resorts'),
        },
        {
            fields: [{
                touristGroup: {
                    field: 'model',
                    action: (_model: never) => ProcessTouristGroup(model.get('touristGroup').get('model')),
                } as any,
            }],
            model: model.get('touristGroup'),
        },
        {
            fields: [{
                nightRange: {
                    field: 'model',
                    action: (_model: never) => ProcessNightRange(model.get('nightRange').get('model')),
                } as any,
            }],
            model: model.get('nightRange'),
        },
        {
            fields: [{
                checkInDateRange: {
                    field: 'model',
                    action: (_model: never) => ProcessCheckInDateRange(model.get('checkInDateRange').get('model') as Backbone.Model),
                } as any,
            }],
            model: model.get('checkInDateRange'),
        },
    ],
    storeOptions: {
        getters: {
            countriesWithDirectFlights: (state): number[] => state.departureCity.countriesWithDirectFlights,
            arrivalCountry: (state): Country => state.countries[0],
            arrivalCountryId: (state, { arrivalCountry }) : number => arrivalCountry.id,
            isPairOfDepartureCityAndArrivalCountryAreValid: (state, { arrivalCountryId, countriesWithDirectFlights }) => _.contains(countriesWithDirectFlights, arrivalCountryId),
        },
    },
});