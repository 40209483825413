import {
 Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class IsExclusiveBlockMixin extends Vue {
    @Prop() isExclusive!: boolean;

    @Prop() text!: string;

    @Prop() beforeClass!: string;

    @Prop({ default: 'Эксклюзивно на Travelata' }) contentTitle!: string;

    @Prop({ default: 'Проверили туры от всех туроператоров и нашли самое выгодное предложение от оператора «Травелата». Стоимость тура может измениться через 10 минут!' }) contentText!: string;
}