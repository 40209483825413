import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
import SearchPageHotelCardRightBlockSearchTimer from '@ve/components/serpHotelCard/components/SearchPageHotelCardRightBlockSearchTimer';

const AbstractSearchController = Backbone.View.extend({
    callFormHotelNameSearchClickMetric() {
        this.serpTrackingService.searchFormHotelNameSearchClick();
    },
    initializeMetrics() {
        this.serpTrackingService = new SerpTrackingService();
    },
    initSearchPageHotelCardRightBlockTimer() {
        this.searchPageHotelCardRightBlockTimer = new SearchPageHotelCardRightBlockSearchTimer();
    },
    countDownTimerForUpdatingMinutesAfterSearchStarted() {
        this.searchPageHotelCardRightBlockTimer.startSearchTimer();
    },
});

export default AbstractSearchController;