import _ from 'underscore';

import { idsFacilities, idFacility } from '@fr/vue/pages/serp/components/filters/facilitiesFilter/common';

export default function (idsTypes: number[], idsAttributes: number[]): idsFacilities[] {
    const result: idsFacilities[] = [];

    if (!_.contains(idsAttributes, 250)) result.push(idFacility.lonelyMan);
    if (_.contains(idsAttributes, 264)) result.push(idFacility.bayLocated);
    if (_.contains(idsAttributes, 217)) result.push(idFacility.adultsOnly);
    if (isHotelHasAirConditioner(idsAttributes)) result.push(idFacility.airConditioner);
    if (isHotelHasWifi(idsAttributes)) result.push(idFacility.wifi);
    if (_.contains(idsAttributes, 166)) result.push(idFacility.withAnimals);
    if (_.contains(idsAttributes, 147)) result.push(idFacility.spaAndHealth);
    if (_.contains(idsAttributes, 185) || _.contains(idsAttributes, 64)) result.push(idFacility.jacuzzi);
    if (_.contains(idsAttributes, 69) || _.contains(idsAttributes, 263)) result.push(idFacility.parking);
    if (_.contains(idsAttributes, 148) || _.contains(idsAttributes, 149)) result.push(idFacility.saunaBathHammam);
    if (isHotelHasForCouples(idsTypes, idsAttributes)) result.push(idFacility.forCouples);
    if (isHotelHasWithKids(idsTypes, idsAttributes)) result.push(idFacility.withKids);
    return result;
}

function isHotelHasAirConditioner(idsAttributes: number[]): boolean {
    return _.contains(idsAttributes, 83)
        || _.contains(idsAttributes, 212)
        || _.contains(idsAttributes, 233);
}

function isHotelHasWifi(idsAttributes: number[]): boolean {
    return _.contains(idsAttributes, 74)
        || _.contains(idsAttributes, 88)
        || _.contains(idsAttributes, 226)
        || _.contains(idsAttributes, 227)
        || _.contains(idsAttributes, 234)
        || _.contains(idsAttributes, 237);
}

function isHotelHasForCouples(idsTypes: number[], idsAttributes: number[]): boolean {
    return _.contains(idsTypes, 8)
        || _.contains(idsAttributes, 169)
        || _.contains(idsAttributes, 220)
        || _.contains(idsAttributes, 217);
}

function isHotelHasWithKids(idsTypes: number[], idsAttributes: number[]) {
    return _.contains(idsTypes, 5)
    || _.contains(idsAttributes, 109)
    || _.contains(idsAttributes, 229)
    || _.contains(idsAttributes, 112)
    || _.contains(idsAttributes, 229);
}