import { IFilterAttribute } from '@ve/components/filters/common/types';
import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { KeyOfTypes } from '@ve/types/helpers';
import FilterAttributeMethodsAbstract from '../filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';

export default class FilterAttributeMethodsHotelNumber<T extends IFilterAttribute> extends FilterAttributeMethodsAbstract<T, SerpHotelAdapted> {
    property?: KeyOfTypes<SerpHotelAdapted, number>;

    constructor(arrayAttributes: T[], property?: KeyOfTypes<SerpHotelAdapted, number>) {
        super(arrayAttributes);
        this.property = property;
    }

    getCountAttribute(hotels: SerpHotelAdapted[]): Record<T['key'], number> {
        return hotels.reduce((acc, hotel) => {
            if (this.property && hotel[this.property]) {
                const idAttributeInHotel = hotel[this.property] as T['id'];
                const attributeKey = this.getKeyByIdAttribute(idAttributeInHotel);
                if (attributeKey) {
                    acc[attributeKey]++;
                }
            }
        return acc;
    }, this.getAttributeObjectCount() as Record<string, number>);
    }

    getIsOneHotelHasProperty(hotels: SerpHotelAdapted[]): boolean {
        return !!this.property && !!hotels.find((hotel) => this.property && hotel[this.property])?.[this.property];
    }
}