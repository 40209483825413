
    import {
        computed,
        defineComponent,
        PropType,
        toRefs,
        unref,
    } from 'vue';
    import CheckBoxInputList from '@ve/components/inputs/CheckBoxInputList.vue';

    export default defineComponent({
        name: 'check-box-input-list-with-one-select',
        components: {
            CheckBoxInputList,
        },
        model: {
            prop: 'model',
            event: 'change',
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [Number, String],
                required: true,
            },
            model: {
                type: Array as PropType<(number | string)[]>,
                default: () => [],
            },
            isHover: {
                type: Boolean,
                default: false,
            },
            isOnlySelectorEnabled: {
                type: Boolean,
                default: true,
            },
            isWhiteSpace: {
                type: Boolean,
                default: true,
            },
        },
        setup(props, { emit }) {
            const {
                model,
            } = toRefs(props);

            const checkboxModel = computed({
                get: () => unref(model),
                set: (newValue: (number | string)[]) => {
                    emit('change', newValue);
                },
            });

            return {
                checkboxModel,
            };
        },
    });
