import { ITourProduct, getTypeConfirmation } from '@entities/tourProduct';
import { IFilterAttributeConfirmation, idAttributeConfirmation } from '../types/IConfirmationFilter';

export default function (tourProduct: ITourProduct, takenConfirmation: IFilterAttributeConfirmation['id'] | 0): boolean {
    if (!takenConfirmation) return true;
    const tourTypeConfirmation = getTypeConfirmation(tourProduct);

    if (takenConfirmation === idAttributeConfirmation.quick) return tourTypeConfirmation === 'quick';

    if (takenConfirmation === idAttributeConfirmation.long) return tourTypeConfirmation === 'quick' || tourTypeConfirmation === 'long';

    return false;
}