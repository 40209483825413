import wordEnding from '@/js/common/Domain/Service/mixins/wordEnding';
import {
 SetupContext, computed, ref, unref, watch, toRefs,
} from 'vue';

type dataComponent = {
    id: number;
    name: string;
}

type propsTypeData = {
    takenHotels: dataComponent[],
    foundHotels: dataComponent[],
}

export default function (props: propsTypeData, { emit }: SetupContext) {
    const { takenHotels, foundHotels } = toRefs(props);
    const showDropDownsHotels = ref<boolean>(false);
    const inputTextUser = ref<string>('');
    const startLoader = ref<boolean>(false);
    const isEmptyHotelsSearch = ref<boolean>(false);
    const isInputFocus = ref(false);

    const takenHotelsIds = computed(() => unref(takenHotels).map((hotel) => hotel.id));

    const textTakenHotels = computed(() => {
        const hotelLength = unref(takenHotelsIds).length;
        if (!hotelLength) return '';
        return `${wordEnding(hotelLength, 'Выбран;Выбрано;Выбраны')} ${hotelLength} ${wordEnding(hotelLength, 'отель;отеля;отелей')}`;
    });

    const foundHotelWithoutTakenHotels = computed(() => unref(foundHotels).filter((hotel) => unref(takenHotelsIds).indexOf(hotel.id) === -1));

    const placeHolderTextHotel = computed(() => (unref(showDropDownsHotels) ? '' : 'Поиск по названию отеля'));

    const textLabelDropDowns = computed(() => ((unref(isEmptyHotelsSearch) && unref(inputTextUser).length >= 3) ? 'Ничего не найдено' : 'Введите минимум 3 символа'));

    const inputHandler = () => {
        const commingValue = unref(inputTextUser);
        if (commingValue.length >= 3) {
            emit('input-filter-hotels-search', unref(inputTextUser));
            startLoader.value = true;
        }
    };
    const takenHotelHandler = (hotel: dataComponent): void => {
        emit('get-hotels-by-filter-search', hotel);
    };
    const deleteHotelHandler = (hotel: dataComponent): void => {
        emit('delete-hotels-by-filter-search', hotel);
    };
    const close = (): void => {
        inputTextUser.value = unref(textTakenHotels);
        isInputFocus.value = false;
        showDropDownsHotels.value = false;
    };
    const open = (): void => {
        inputTextUser.value = '';
        showDropDownsHotels.value = true;
    };

    const startSearch = (): void => {
        close();
        emit('start-search-by-filter-search');
    };

    const clearHotels = (): void => {
        inputTextUser.value = '';
        emit('reset-all-hotels-in-search');
        startSearch();
    };

    watch(takenHotels, () => {
        inputTextUser.value = unref(textTakenHotels);
    }, { immediate: true });
    watch(foundHotels, (arrayHotels) => {
        startLoader.value = false;
        if (!arrayHotels.length) {
            isEmptyHotelsSearch.value = true;
            return;
        }
        isEmptyHotelsSearch.value = false;
    });

    return {
        inputTextUser,
        showDropDownsHotels,
        foundHotelWithoutTakenHotels,
        placeHolderTextHotel,
        startLoader,
        textLabelDropDowns,
        isInputFocus,
        inputHandler,
        close,
        open,
        startSearch,
        clearHotels,
        takenHotelHandler,
        deleteHotelHandler,
    };
}