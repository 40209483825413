/* eslint-disable */
import {Module, VuexModule, Mutation} from 'vuex-module-decorators';
import _ from 'underscore';
import { CountryCondition } from '@ve/types';

import { CountryConditionsState} from './types';


export const state: CountryConditionsState = {
    countryConditions: [],
};

const namespaced: boolean = true;

@Module({
    namespaced,
})
export default class CountryConditionsStore extends VuexModule {
    countryConditions: CountryCondition[] = [];

    @Mutation
    updateCountryConditions(countryConditions: CountryCondition[]): void {
        const currentCoutryConditions = this.countryConditions;
        let newCountryCondititons = [
            ...currentCoutryConditions,
        ];
        countryConditions.forEach((countryCondition) => {
            const currentCoutryCondition = _.find(currentCoutryConditions, (currentCoutryCondition) => currentCoutryCondition.id === countryCondition.id);
            if (currentCoutryCondition) {
                newCountryCondititons = _.without(newCountryCondititons, currentCoutryCondition);
            }
            newCountryCondititons.push(countryCondition);
        });
        this.countryConditions = newCountryCondititons;
    }
}