export default (options, searchUuid, filteredSearchUuid = null) => {
    const maperSortStrategy = {
        recommend: 0,
        priceUp: 1,
        priceDown: 2,
        ratingDown: 3,
    };

    const { hotels, selectedHotel, sortStrategy } = options;
    const indexOfSelectedHotel = hotels.indexOf(selectedHotel);
    const preparedHotels = hotels.models
        .slice(0, indexOfSelectedHotel + 1)
        .map((hotel, index) => ({
            id: hotel.get('id'),
            price: hotel.get('priceWithOilTax'),
            position: index + 1,
            click: hotel.get('id') === selectedHotel.get('id') ? 1 : 0,
        }));

    return {
        searchUuid,
        filteredSearchUuid,
        sortType: maperSortStrategy[sortStrategy],
        hotels: preparedHotels,
    };
};
