/* eslint-disable */
/*
*
* Данный сервис принимает на вход модель +  массив вхожений в данную модель любой глубины
* Возвращает булевое значение если существют  данные вхождение в модель или нет
* Данный сервис поможет если нужно строить цепочку подобную этой tour.get("resort").get("country").get('id')
* Сервис проверяет будет ли креш в данной участке кода
* если последний элемент цепочки не является моделью то его указывать не нужно
* */

import DataProxy from "@/js/common/Domain/Service/DataProxy";

export default function backboneModelDeepInstanceOf({model,values}) {
	let result = model instanceof Backbone.Model;
	if(values && result){
		result = values && values.length
	}

	if(result && values && values.length > 0){
		let deepModel = _.clone(model);
		for (let i in values){
			let value = values[i];
			deepModel = deepModel.get(value);
			result = deepModel instanceof Backbone.Model || deepModel instanceof DataProxy;
			if(!result) break;
		}
	}

	return result;
}