
    import {
        defineComponent, PropType, computed, SetupContext, toRefs, unref,
    } from 'vue';
    import { Resort } from '@ve/types';
    import CheckBoxInput from '@ve/components/inputs/CheckBoxInput.vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import ToggleListContent from '@ve/components/filters/common/components/toggleListContent/ToggleListContent.vue';
    import TextCommon from '@ve/components/inputs/TextCommon.vue';
    import ResortFilterItem from './components/ResortFilterItem.vue';

    export default defineComponent({
        components: {
            ResortFilterItem,
            FilterListItem,
            CheckBoxInput,
            ToggleListContent,
            TextCommon,
        },
        props: {
            takenResorts: {
                type: Array as PropType<Resort[]>,
                default: () => [],
            },
            popularityResorts: {
                type: Array as PropType<Resort[]>,
                default: () => [],
            },
            othersResorts: {
                type: Array as PropType<Resort[]>,
                default: () => [],
            },
            nameCountryResort: {
                type: String,
            },
        },
        setup(props, { emit }: SetupContext) {
            const { takenResorts, popularityResorts, othersResorts } = toRefs(props);
            const allSelected = computed({
                get: () => !unref(takenResorts).length,
                set: () => {
                    emit('change', []);
                },
            });
            const selectedResorts = computed({
                get: () => unref(takenResorts).map((resort) => resort.id),
                set: (value) => {
                    emit('change', value);
                },
            });

            const popularityResortsWithoutTaken = computed(() => unref(popularityResorts).filter((resort) => unref(selectedResorts).indexOf(resort.id) === -1));
            const othersResortsWithoutTaken = computed(() => unref(othersResorts).filter((resort) => unref(selectedResorts).indexOf(resort.id) === -1));

            return {
                allSelected,
                selectedResorts,
                othersResortsWithoutTaken,
                popularityResortsWithoutTaken,
            };
        },
    });
