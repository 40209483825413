/* eslint-disable */
import { Resort, Country } from '@ve/types';
import { BackboneResortsRepository } from '@fr/vue/types/backboneModels/Domain/Repository';

export default function(resorts: BackboneResortsRepository): Resort[] {
    return resorts?.map((resort) => ({
        id: resort.get('id'),
        name: resort.get('name'),
        isPopular: resort.get('isPopular'),
        atFiltering: resort.get('atFiltering')
    })) || [];
}
