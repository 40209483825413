import { computed, ref, unref } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { CountryInfo } from '../types/CountryInfo';
import dataCountries from '../data/dataCountries';

type idTurkey = 92;
type idUAE = 68;
type countriesIds = idTurkey | idUAE;

export default function (nameStoreModule: string) {
    const { countries } = useState(nameStoreModule, ['countries']);

    const idCountry = computed((): countriesIds | null => {
        if (countries?.value) {
            return countries.value[0];
        }
        return null;
    });

    const viewBannerCountry = computed((): boolean => {
        if (idCountry.value) {
            return idCountry.value in dataCountries;
        }
        return false;
    });

    const infoCurrentCountry = computed((): CountryInfo[] | [] => {
        if (viewBannerCountry.value && idCountry.value) {
            return dataCountries[idCountry.value];
        }
        return [];
    });

    return {
        infoCurrentCountry,
        viewBannerCountry,
    };
}