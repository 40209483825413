/* eslint-disable */
import CountryEntity from '../Entity/Country';
import EntityManager from '../EntityManager';
import CountryFactory from '../Factory/Country';
let CountriesRepository = Backbone.Collection.extend({
	model: CountryEntity,
	addEntry:function(countries) {
		var entityManager = new EntityManager(),
			countriesModelsArray = entityManager.add({
				factory: CountryFactory,
				repository: repository
			}, countries);
		
		this.add(countriesModelsArray);
	}
});

let repository = new CountriesRepository();
repository.init = function(countries, defaultDepartureCity) {
	let DepartureCitiesRepository = require('../Repository/DepartureCities').default;
	var countriesModelsArray = [];
	_.each(countries, function(country) {
		var countryModel = new CountryFactory(country);

		countriesModelsArray.push(countryModel);
	});

	this.set(countriesModelsArray);
	
	if(defaultDepartureCity) {
		if(defaultDepartureCity instanceof DepartureCitiesRepository) {
			defaultDepartureCity.each(function(departureCity) {
				departureCity.set('countries', new CountriesRepository(countriesModelsArray));
			});
		} else {
			DepartureCitiesRepository.repository.get(defaultDepartureCity).set('countries', new CountriesRepository(countriesModelsArray));
		}
	}
};

CountriesRepository.repository = repository;

export default CountriesRepository;
