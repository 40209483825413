import { Tour } from '@ve/types';
import { IBackboneTourProduct } from '@ve/types/backboneModels/Domain/Entity/BackboneTourProduct';

const productCompareKey = (tourProduct: IBackboneTourProduct): string => {
    const tour = tourProduct.get('tour');
    return `${tourProduct.get('id')}_${tour.get('priceWithOilTax')}`;
};
const rawProductCompareKey = (product: Tour):string => `${product.id}_${product.price + (product.oilTax || 0)}`;

export {
    productCompareKey,
    rawProductCompareKey,
};