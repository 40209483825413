/* eslint-disable */
import { TrackJS } from 'trackjs';
import errorHandlerService from './errorHandler';

const requestService = function (options, callback) {
    let ajaxRequest;
    let requestTimeout;
    const requestTimeoutDuration = 40000;
    let $script;
    const jsonpName = `jsonp${Math.random().toString().replace('.', '')}`;
    const onError = function (jqXHR, textStatus, errorThrown) {
        if (textStatus == 'timeout' || textStatus == 'abort') {
            window[jsonpName] = function () {
                delete (window[jsonpName]);
            };
        }
        errorHandlerService(textStatus, {
            jqXHR,
            textStatus,
            errorThrown,
        });
        if (textStatus != 'abort') {
            if (jqXHR?.responseJSON) {
                console.error("Response JSON: ", jqXHR?.responseJSON);
            } else if (jqXHR?.responseText) {
                console.error("Response Text: ", jqXHR?.responseText);
            }
            TrackJS.track(`Request error: code ${jqXHR?.status ?? 'none'}, request ${options.url} error ${errorThrown}`);
            callback && callback.error ? callback.error(jqXHR, textStatus, errorThrown) : '';
        }
    };

    options = $.extend({
        type: 'GET',
        dataType: 'json',
        jsonpCallback: jsonpName,
    }, options);

    ajaxRequest = $.ajax(options);

    ajaxRequest.done((response) => {
        clearTimeout(requestTimeout);
        if (response.success === true) {
            callback && callback.success ? callback.success(response) : '';
        } else {
            errorHandlerService(response.message);
            callback && callback.error ? callback.error(response) : '';
        }
    });

    if (options.dataType != 'jsonp') {
        ajaxRequest.fail(onError);
    } else {
        $script = $(document.getElementsByTagName('head')[0].firstChild);
        $script[0].onerror = function (e) {
            clearTimeout(requestTimeout);
            ajaxRequest.readyState = 4;
            delete (window[jsonpName]);
            onError(e, null, $script.attr('src'));
        };

        requestTimeout = setTimeout(() => {
            $script[0].onerror = function () {
            };
            ajaxRequest.readyState = 4;
            onError($script, 'timeout', $script.attr('src'));
        }, requestTimeoutDuration);

        ajaxRequest.abort = function () {
            $script[0].onerror = function () {
            };
            clearTimeout(requestTimeout);
            onError($script, 'abort', $script.attr('src'));
        };
    }

    return ajaxRequest;
};

export default requestService;