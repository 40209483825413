interface FormatNumberOptions {
    separator?: string;
    decimal?: number;
}

const formatNumber = (value: number, { separator = ' ', decimal = 0 }: FormatNumberOptions = {}): string => {
    const exp10 = 10 ** decimal;
    const roundedValue = Math.round(value * exp10) / exp10;

    const [integerPart, decimalPart] = roundedValue.toFixed(decimal).split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
};

export default formatNumber;