import tourCriteriaService from '@/js/common/Domain/Service/tourCriteria';
import './styles/no-tours-banner.css';
import { propsDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import bannerTemplate from './template.html';

const formFields = {
    priceRange: 'Цена',
    hotelCategories: 'Класс отеля',
    meals: 'Питание',
    hotels: 'Отель',
    resorts: 'Курорт',
    checkInDateRange: 'Дата вылета',
    nightRange: 'Кол-во ночей',
};

@viewModelDecorator({
    defaults: {
        bannerVisible: false,
        havePriceCalendar: null,
        htmlLinksPurpose: '',
        bannerTitleText: 'Не нашли туры с выбранными критериями поиска',
        calendarTitleText: 'Воспользуетесь календарем низких цен для поиска дат вылетов с турами по вашим критериям',
    },
})

@propsDecorator({
    bindings: {
        '.no-tours-banner': 'animatedToggle:bannerVisible',
        '.js-epoxy-haveNotPriceCalendar': 'toggle:not(havePriceCalendar)',
        '.js-epoxy-havePriceCalendar': 'toggle:havePriceCalendar, text:calendarTitleText',
        '.no-tours-banner__title': 'text:bannerTitleText',
        '.no-tours-banner__links': 'html:htmlLinksPurpose',
    },
})
export default class NoToursBannerView extends Backbone.Epoxy.View {
    initialize(options = {}) {
        const {
            model, controllerModel, bannerTitleText, calendarTitleText, labels,
        } = options;
        if (!model) {
            throw new Error('no model provided');
        }

        this.labels = formFields;

        if (labels) {
            this.labels = {
                ...this.labels,
                ...labels,
            };
        }

        if (!controllerModel) {
            throw new Error('no controllerModel provided');
        }
        this.model = model;
        this.controllerModel = controllerModel;

        if (bannerTitleText) {
            this.viewModel.set('bannerTitleText', bannerTitleText);
        }
        if (calendarTitleText) {
            this.viewModel.set('calendarTitleText', calendarTitleText);
        }
        this.render();
    }

    render() {
        this.$el.html(bannerTemplate(this.model));
    }

    htmlLinksPurpose(searchData) {
        if (!searchData) return '';
        let changedFilters = tourCriteriaService.getMaybeWrongFields(searchData);
        const template = _.template("<li<%= !noClick ? ' class=\"clickable\"' : ''%>><%= name %></li>");
        let html = $();

        changedFilters = changedFilters.slice(0, 3);

        _.each(changedFilters, (field) => {
            if (field !== 'priceRange') {
                const view = this.controllerModel.get(field).get('view');
                let element;

                if (view?.label !== 'бюджет') {
                    if (!_.isEmpty(view) && view.scrollToElement) {
                        element = $(template({ name: this.labels[field] || view.label, noClick: false }));

                        element.on('click', (e) => {
                            view.scrollToElement(e);
                        });

                        html = html.add(element);
                    } else if (this.labels[field]) {
                        element = $(template({ name: this.labels[field], noClick: true }));
                        html = html.add(element);
                    }
                }
            }
        });
        return html;
    }

    showBanner(havePriceCalendar) {
        if (havePriceCalendar) {
            this.viewModel.set('havePriceCalendar', havePriceCalendar);
        } else {
            this.viewModel.set('htmlLinksPurpose', this.htmlLinksPurpose(this.model.get('searchData')));
        }
        this.viewModel.set('bannerVisible', true);
    }

    hideBanner() {
        this.viewModel.set('bannerVisible', false);
    }
}
