import 'jquery.cookie';

const mpCookieName = 'tr_sm';
const requestOptionName = 'trSm';

export default () => {
    const options = {};

    const mpShowcase = $.cookie(mpCookieName);

    if (mpShowcase) {
        options[requestOptionName] = mpShowcase;
    }

    return options;
};