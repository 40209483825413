/* eslint-disable */
let DataProxy = function(callback) {
	var callbackValue,
		self = this;

	function setMethods(callbackValue) {
		self.__proto__ = callbackValue;
	}

	this.get = function(field) {
		if(typeof callbackValue == "undefined") {
			callbackValue = callback();
			setMethods(callbackValue);
		}

		return this.__proto__.get(field);
	};

	this.set = function(field, value) {
		if(typeof callbackValue == "undefined") {
			callbackValue = callback();
			setMethods(callbackValue);
		}

		return this.__proto__.set(field, value);
	};

	this.on = function(eventName, eventCallback) {
		if(typeof callbackValue == "undefined") {
			callbackValue = callback();
			setMethods(callbackValue);
		}

		return this.__proto__.on(eventName, eventCallback);
	};

	return this;
};

export default DataProxy;