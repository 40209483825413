
    import {
        defineComponent, PropType,
    } from 'vue';
    import { typeRating } from '@ve/components/filters/ratingReviewsFilter/types';

    export default defineComponent({
        props: {
            size: {
                type: String as PropType<'medium' | 'small' | 'large'>,
                default: 'medium',
            },
            type: {
                type: String as PropType<typeRating>,
                default: 'noScore',
            },
            hidenText: {
                type: Boolean,
                default: false,
            },
        },
    });
