export default (params) => {
    const tourCriteria = {};
    let element;

    if ((params.dateFrom && params.dateFlexible) || (params.dateFrom && params.dateTo) || params.dateFrom) {
        tourCriteria.checkInDateRange = {};

        element = params.dateFrom;
        if (element) {
            tourCriteria.checkInDateRange.from = element;
        }

        element = params.dateTo;
        if (element) {
            tourCriteria.checkInDateRange.to = element;
        }

        element = params.dateFlexible;
        if (element) {
            tourCriteria.checkInDateRange.daysFlexible = 1;
        }
    }

    return tourCriteria;
};