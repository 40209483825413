/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';

let ADVCakeHydrator = function(tourCriteria) {
    var element,
        from,
        to,
        adults,
        kids,
        infants,
        params = {};

    element = tourCriteria.get('departureCity');
    if(element) {
        params['departureCity'] = element.pick('id', 'name');
    }

    element = tourCriteria.get('countries');
    if(element && element.length) {
        params['country'] = element.at(0).pick('id', 'name');
    }

    element = tourCriteria.get('resorts');
    if(element && element.length) {
        params['resorts'] = element.map(function(item){ return item.pick('id', 'name') });
    }

    element = tourCriteria.get('checkInDateRange');
    if(element) {
        from = element.get('from');
        to = element.get('to');
        params['checkInDateRange'] = {
            from: from ? moment(from).format('DD.MM.YYYY') : null,
            to: to ? moment(to).format('DD.MM.YYYY') : null,
        };
    }

    element = tourCriteria.get('nightRange');
    if(element) {
        from = element.get('from');
        to = element.get('to');
        params['nightRange'] = {
            from: from ? from : null,
            to: to ? to : null
        };
    }

    element = tourCriteria.get('touristGroup');
    if(element && element !== null) {
        adults = element.get('adults');
        kids = element.get('kids');
        infants = element.get('infants');
        params['touristGroup'] = {
            adults: adults ? adults : 0,
            kids : kids ? kids : 0,
            infants : infants ? infants  : 0
        }
    }

    element = tourCriteria.get('hotelCategories');
    if(element && element.length) {
        params['hotelCategories'] = element.map(function(item){ return item.pick('id', 'name') });
    } else {
        params['hotelCategories'] = [];
    }

    element = tourCriteria.get('meals');
    if(element && element.length) {
        params['meals'] = element.map(function(item){ return item.pick('id', 'name') });
    } else {
        params['meals'] = [];
    }

    element = tourCriteria.get('hotels');
    if(element && element.length) {
        params['hotels'] = element.map(function(item){ return item.pick('id', 'name') });
    }

    return params;
};

export default ADVCakeHydrator;