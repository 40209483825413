import departureCity from './tourCriteria/departureCity';
import sort from './tourCriteria/sort';
import country from './tourCriteria/country';
import resorts from './tourCriteria/resorts';
import checkInDateRange from './tourCriteria/checkInDateRange';
import nightRange from './tourCriteria/nightRange';
import touristGroup from './tourCriteria/touristGroup';
import hotelCategories from './tourCriteria/hotelCategories';
import meals from './tourCriteria/meals';
import hotels from './tourCriteria/hotels';
import operators from './tourCriteria/operators';
import track from './tourCriteria/track';
import priceRange from './tourCriteria/priceRange';
import warranted from './tourCriteria/warranted';
import isExclusive from './tourCriteria/isExclusive';

export default (tourCriteria) => {
    const tourCriteriaJson = {
        ...departureCity(tourCriteria),
        ...sort(tourCriteria),
        ...country(tourCriteria),
        ...resorts(tourCriteria),
        ...checkInDateRange(tourCriteria),
        ...nightRange(tourCriteria),
        ...touristGroup(tourCriteria),
        ...hotelCategories(tourCriteria),
        ...meals(tourCriteria),
        ...hotels(tourCriteria),
        ...operators(tourCriteria),
        ...track(tourCriteria),
        ...priceRange(tourCriteria),
        ...warranted(tourCriteria),
        ...isExclusive(tourCriteria),
    };

    const element = tourCriteria.get('hotels');
    if (element?.length) {
        const hotelsWithResorts = element.filter((hotel) => (hotel.get('resort') && hotel.get('resort')?.get?.('id')));
        if (tourCriteriaJson?.resorts?.length && _.isArray(tourCriteriaJson.resorts)) {
            tourCriteriaJson.resorts = _.uniq(tourCriteriaJson.resorts.concat(_.map(hotelsWithResorts, (hotel) => hotel.get('resort').get('id'))));
        }
    }

    return tourCriteriaJson;
};