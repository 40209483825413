/* eslint-disable */
import HotelsRepository from './Hotels';

let HotelsSortedRepository = HotelsRepository.extend({
	strategies: {
		compare:function(fields, model1, model2) {
			var points1 = 0,
				points2 = 0,
				i,
				value1,
				value2,
				sign,
				field,
				fieldsLength = fields.length;

			for(i=fieldsLength-1; i>=0; i--) {
				sign = 1;

				field = fields[i];

				if(_.isArray(field)) {
					value1 = field[0];
					value2 = field[1];
				} else {
					sign = field.dir ? field.dir : 1;

					value1 = model1.get(field.field);
					value2 = model2.get(field.field);
				}

				if(value1*sign > value2*sign) {
					points1 += 1<<i;
					break;
				} else {
					if(value1*sign != value2*sign) {
						points2 += 1<<i;
						break;
					}
				}
			}

			return points1 > points2 ? 1 : (points1 == points2 ? 0 : -1);
		},
		priceUp: function(model1, model2) {
			return this.compare([{field:'rating', dir:-1}, {field: this.getPriceField(model1, model2)}], model1, model2);
		},
		priceDown: function(model1, model2) {
			return this.compare([{field:'rating', dir:-1}, {field: this.getPriceField(model1, model2), dir:-1}], model1, model2);
		},
		ratingDown:function(model1, model2) {
			return this.compare([{field:this.getPriceField(model1, model2)}, {field:'rating', dir:-1}], model1, model2);
		},
		reviewsDown:function(model1, model2) {
			return this.compare([{field:this.getPriceField(model1, model2)}, {field:'reviewsQty', dir:-1}], model1, model2);
		},
		recommend:function(model1, model2) {
			return this.compare([{field:'rating', dir:-1}, {field:this.getPriceField(model1, model2)}, {field:'sortRate', dir:-1}], model1, model2);
		},
		getPriceField: function (model1,model2) {
			return "priceWithOilTax"
        }
	}
});

export default HotelsSortedRepository;