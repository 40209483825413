export interface ITrackingPages {
    page: string
    query: string
    referrer: string
}

export default (): ITrackingPages => ({
    page: window.location.pathname,
    query: `${window.location.search}${window.location.hash}`,
    referrer: document.referrer,
});