class DroppedOrderBannerCookieTracker {
    constructor(options) {
        if (!options?.onUpdate || typeof options?.onUpdate !== 'function') {
            throw new Error('onUpdate is empty or not function');
        }
        this.onUpdate = options.onUpdate;
        this.cookieTracker = null;
        this.oldDroppedOrderInfoCookieData = undefined;
        this.refreshData();
        this.startTracker();
    }

    startTracker() {
        this.cookieTracker = setInterval(() => {
            this.refreshData();
        }, 5000);
    }

    refreshData() {
        const droppedOrderInfoCookie = $.cookie('droppedOrderInfo');
        if (droppedOrderInfoCookie !== this.oldDroppedOrderInfoCookieData) {
            this.oldDroppedOrderInfoCookieData = droppedOrderInfoCookie;
            let newData = null;
            if (droppedOrderInfoCookie) newData = JSON.parse(droppedOrderInfoCookie);
            this.onUpdate(newData);
        }
    }
}

export default DroppedOrderBannerCookieTracker;
