/* eslint-disable */
const wordEnding = function (number, endings) {
    if (!_.isString(endings)) throw new Error('Wrong endings');
    const endingsArray = endings.split(';');
    if (endingsArray.length < 3) throw new Error('No important endings amount');
    return number % 10 === 1 && number % 100 !== 11 ? endingsArray[0] : (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20) ? endingsArray[1] : endingsArray[2]);
};

Number.prototype.wordEnding = function (endings) {
    return wordEnding(this, endings);
};

String.prototype.wordEnding = function (endings) {
    return wordEnding(this, endings);
};

export default wordEnding;