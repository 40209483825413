import {
    computed, unref, ComputedRef,
   } from 'vue';
   import { useState, useActions } from 'vuex-composition-helpers';
   import { IMapForFiltersSerpState, IMapForFiltersSerpActions } from '@ve/components/searchFilters/store/types';
   import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
   import {
        IFilterPreviousState, IFilterAdditionalItem, IReturnAdditionalPreviousFilter,
    } from '../types';
   import { budgetFilterCount } from './services/additionalFiltersServices';

export default function () {
    const { previousStateFiltersSerp } = useState<IFilterPreviousState>('previousFilterStore', ['previousStateFiltersSerp']);
    const { syncHotels } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['syncHotels']);
    const { action_maxPrice: setMaxPrice, action_minPrice: setMinPrice } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_maxPrice', 'action_minPrice']);

    const budgetFilterPrevious = computed({
        get: () => budgetFilterCount(unref(syncHotels), unref(previousStateFiltersSerp)),
        set: (BudgetFilterValue) => {
            if (BudgetFilterValue?.valueState) {
                setMaxPrice(BudgetFilterValue.valueState.maxPrice);
                setMinPrice(BudgetFilterValue.valueState.minPrice);
            }
        },
    });

    const allPreviousFilters = computed(() => [budgetFilterPrevious].filter((item) => !!unref(item)) as ComputedRef<IFilterAdditionalItem>[]);

    const additionalPreviousFilterItems = computed<IReturnAdditionalPreviousFilter>(() => ({
        unAvailableFilters: unref(allPreviousFilters).map((previousFilter) => unref(previousFilter)).filter((previousFilter) => previousFilter.isDisabled),
        availableFilters: unref(allPreviousFilters).map((previousFilter) => unref(previousFilter)).filter((previousFilter) => !previousFilter.isDisabled),
    }));

    const applyingAdditionalFilters = (idsTakenFilters: number[]) => {
        const takenAdditionalFilter = unref(allPreviousFilters).filter((filterItem) => idsTakenFilters.indexOf(unref(filterItem).id) !== -1);
        takenAdditionalFilter.forEach((filterPreviousAdditional) => {
            (filterPreviousAdditional.value as any) = unref(filterPreviousAdditional);
        });
    };

    return {
        applyingAdditionalFilters,
        additionalPreviousFilterItems,
    };
}