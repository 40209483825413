import { IHotel } from '@entities/hotel/@/tourProduct';
import TourProduct from '../model/TourProduct';

export default function (groupedTourProducts: TourProduct[][]): IHotel[] {
    return groupedTourProducts.reduce((acc, tourProducts) => {
        tourProducts.forEach((tourProduct) => {
            if (tourProduct.hotel) {
                const isHotelUniq = !acc.some((hotel) => hotel.id === tourProduct.hotel?.id);
                if (isHotelUniq) acc.push(tourProduct.hotel);
            }
        });

        return acc;
    }, [] as IHotel[]);
}