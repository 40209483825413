export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('touristGroup');
    if (element) {
        tourCriteriaJson.touristGroup = {};
        const adults = element.get('adults');
        const kids = element.get('kids');
        const infants = element.get('infants');
        const ages = element.get('ages');
        tourCriteriaJson.touristGroup.adults = adults || 0;
        tourCriteriaJson.touristGroup.kids = kids || 0;
        tourCriteriaJson.touristGroup.infants = infants || 0;
        tourCriteriaJson.touristGroup.kidsAges = [];

        if (kids && ages?.length) {
            tourCriteriaJson.touristGroup.kidsAges = _.chain(ages)
                .map((age) => +age)
                .filter((age) => age > 1)
                .value()
                .sort();
        }
    }
    return tourCriteriaJson;
};