import { Country } from '@ve/types';
import { BackboneCountriesRepository } from '@ve/types/backboneModels/Domain/Repository';
import ProcessResortsAdapter from '@ve/pages/serp/adapters/ProcessResortsAdapter';

export default function (countries: BackboneCountriesRepository | null): Country[] {
    if (!countries) return [];
    return countries.map((country) => ({
        id: country.get('id'),
        name: country.get('name'),
        resorts: ProcessResortsAdapter(country.get('resorts')),
    }));
}