import { Store } from 'vuex';
import { BackboneMapFieldObjectWithAction, BaseState } from '@ve/services/getStore/types';
import getStore from '@ve/services/getStore/getStore';
import { BackboneSearchPageModel } from '@ve/types/backboneModels/BackboneSearchPageModel';

export const NAMESPACE = 'serpSearchStore';
export default ({
    model,
    hotelsLengthFactory,
}: {
    model: BackboneSearchPageModel;
    hotelsLengthFactory: BackboneMapFieldObjectWithAction;
}): Store<BaseState> => getStore({
    name: NAMESPACE,
    backboneMap: [
        {
            fields: [{
                loadingState: 'loadingState',
                hotelsLength: hotelsLengthFactory,
            }],
            model,
        },
    ],
    storeOptions: {
        getters: {
            hasProductsAfterLoading(state) {
                return !!(state.hotelsLength && state.loadingState !== 'loading');
            },
            noToursFound(state) {
                return state.loadingState === 'finished' && !state.hotelsLength;
            },
            hasTours(state) {
                return state.loadingState !== 'loading' && state.hotelsLength;
            },
            isSyncLoadingFinished(state) {
                return state.loadingState !== 'loading';
            },
        },
    },
});