import { IWaterActivities } from '.';

const textDescriptionWaterSlides = 'это водный аттракцион, представляющий собой группу горок с водой, спускающихся в один бассейн';
const textDesciptionAquapark = 'это развлекательный комплекс, в котором имеется инфраструктура для занятия играми на воде и водные аттракционы с выделенным местом входа';

const dataWaterActivities: IWaterActivities[] = [
    {
        id: 1,
        name: 'Открытый бассейн',
        key: 'openPool',
        keyMerged: 'openPool',
        nameLayer: 'openPool',
    },
    {
        id: 2,
        name: 'Крытый бассейн',
        key: 'closePool',
        keyMerged: 'closePool',
        nameLayer: 'closePool',
    },
    {
        id: 3,
        name: 'Бассейн с подогревом',
        key: 'heatedPool',
        keyMerged: 'heatedPool',
        nameLayer: 'heatedPool',
    },
    {
        id: 4,
        name: 'Бассейн с морской водой',
        key: 'seaPool',
        keyMerged: 'seaPool',
        nameLayer: 'seaPool',
    },
    {
        id: 5,
        name: 'Водные горки',
        key: 'waterslides',
        keyMerged: 'waterslides',
        nameLayer: 'waterslides',
        tooltip: {
            label: 'Водные горки',
            description: textDescriptionWaterSlides,
        },
    },
    {
        id: 6,
        name: 'Аквапарк',
        key: 'aquapark',
        keyMerged: 'aquapark',
        nameLayer: 'aquapark',
        tooltip: {
            label: 'Аквапарк',
            description: textDesciptionAquapark,
        },
    },
];

export default dataWaterActivities;