/* eslint-disable */
var router;

const SearchRouter = function() {
	var self = this,
		bcbRouter = Backbone.Router.extend({
			routes: {
				"?" :  "default",
				"?(!)*params": "search",
				  "*path" :  "default"
			},
			initialize: function() {
				setTimeout(function() {
					Backbone.history.start();
				},1);
			},
			go:function(url, params, isTargetBlank = false) {
				if(document.location.hash == "") {
					params = $.extend({}, (params ? params : {}), {
						replace:true
					});
				}
				if(url.indexOf('#') == -1) {
					this.navigate(url, params);
				} else if(isTargetBlank) {
					window.open(url, '_blank');
				} else {
					document.location.href = url;
				}
			}
		});
	
	self.getInstance = function() {
		router = router ? router : new bcbRouter();
		return router;
	}
};

export default SearchRouter;