/* eslint-disable */
let SearchRequestFactory = function(tourCriteria, type, timeout, limit) {
	var params = {
		tourCriteria: tourCriteria
	};

	if(type) {
		params['type'] = type;
	}

	if(timeout) {
		params['timeout'] = timeout;
	}

	if(limit) {
		params['limit'] = limit;
	}

	return params;
};

export default SearchRequestFactory;
