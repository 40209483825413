import { IRawResort } from '../types/IResort';

export interface IResortRepositoryState {
    resorts: IRawResort[];
}

export interface IResortsRepositoryMutations {
    setResorts(state: IResortRepositoryState, resorts: IRawResort[]): void,
    resetResorts(state: IResortRepositoryState): void;
}

const ResortRepository = {
    namespaced: true,
    state: {
        resorts: [],
    },
    mutations: {
        setResorts(state: IResortRepositoryState, updatedResorts: IRawResort[] | undefined) {
            const resortsHash = state.resorts.reduce((acc, resort) => {
                acc[resort.id] = true;
                return acc;
            }, {} as Record<number, boolean>);
            const newResorts = updatedResorts?.filter((country) => !resortsHash[country.id]) || [];
            if (newResorts.length) {
                state.resorts = [...state.resorts, ...newResorts];
            }
        },
        resetResorts(state: IResortRepositoryState) {
            state.resorts = [];
        },
    },
};

export const NAMESPACE = 'resortsRepositoryName';

export default ResortRepository;
