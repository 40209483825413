import type { EmptySearchCurrentState } from '@ve/components/emptySearch/common/store/types/IEmptySearchStore';

export default ({
    hasCalendarMinPriceDate,
    loadingStateIsFinished,
}: {
    hasCalendarMinPriceDate: boolean;
    loadingStateIsFinished: boolean;
}): EmptySearchCurrentState | null => {
    if (loadingStateIsFinished) {
        if (hasCalendarMinPriceDate) {
            return 'calendarMinPriceDateSearch';
        }

        return 'defaultCriteriaSearch';
    }

    return null;
};