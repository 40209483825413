import { computed } from 'vue';

function useBudgetFilter(props: { prices: number[] }) {

    
    const minPrice = computed(() => (props.prices.length > 0 ? Math.min(...props.prices) : 0));

    const maxPrice = computed(() => (props.prices.length > 0 ? Math.max(...props.prices) : 0));

    return {
        minPrice,
        maxPrice,
    }

}


export default useBudgetFilter;