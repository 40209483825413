import { Store } from 'vuex';
import { BaseState } from '@ve/services/getStore/types';
import getStore from '@ve/services/getStore/getStore';

export const NAMESPACE = 'serpToursStore';
export default ({
    model,
}: {
    model: Backbone.Model;
}): Store<BaseState> => getStore({
    name: NAMESPACE,
    backboneMap: [
        {
            fields: [{
                beforeToursLoading: 'beforeToursLoading',
            }],
            model,
        },
    ],
});