/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';

let toLocalStorageTourCriteriaHydrator = function (tourCriteria) {
	let element,
		from,
		to,
		params = {};

	element = tourCriteria.get('departureCity');
	if (element) {
		params.departureCity = element.get('id');
	}

	element = tourCriteria.get('countries');
	if (element && element.length) {
		params.countries = element.pluck('id');
	}

	element = tourCriteria.get('resorts');
	if (element && element.length) {
		params.resorts = element.pluck('id');
	}

	element = tourCriteria.get('checkInDateRange');
	if (element) {
		params.checkInDateRange = {};
		from = element.get('from');
		to = element.get('to');
		if (from) {
			params.checkInDateRange.from = moment(from).format('YYYY-MM-DD');
		}
		if (to) {
			params.checkInDateRange.to = moment(to).format('YYYY-MM-DD');
		}
	}

	element = tourCriteria.get('nightRange');
	if (element) {
		params.nightRange = {};
		from = element.get('from');
		to = element.get('to');
		if (from) {
			params.nightRange.from = from;
		}
		if (to) {
			params.nightRange.to = to;
		}
	}

	element = tourCriteria.get('touristGroup');
	if (element) {
		params.touristGroup = element.toJSON();
	}

	return params;
};

export default toLocalStorageTourCriteriaHydrator;