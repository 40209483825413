import _ from 'underscore';
import { FirstPaymentDefinition } from '@ve/types';
import Backbone, { Model, Collection } from 'backbone';

type FirstPaymentDefinitionServiceClassModel = Model<{ operators: Collection<Model<FirstPaymentDefinition>>}>

const cloudHost = FRONTEND_IMAGES_HOST;

export default class FirstPaymentDefinitionServiceClass {
    store: FirstPaymentDefinition[];

    model: FirstPaymentDefinitionServiceClassModel;

    constructor() {
        this.store = [];
        this.model = new Backbone.Model({
            operators: new Backbone.Collection([]),
        });
    }

    setData(firstPaymentDefinitions: FirstPaymentDefinition[]):void {
        this.store = _.map(firstPaymentDefinitions, (firstPaymentDefinition) => ({
            operatorCover: `https://${cloudHost}/photos/operator/${firstPaymentDefinition.operator}.png`,
            ...firstPaymentDefinition,
        }));

        const collection = new Backbone.Collection(this.store);
        this.model.set('operators', collection);
    }

    getModelData():FirstPaymentDefinitionServiceClassModel {
        return this.model;
    }

    getData():FirstPaymentDefinition[] {
        return this.model?.get('operators')?.toJSON();
    }
}
