import { useNightsRangeStore } from '@entities/searchCriteria/nightsRange';

export default (modelPrefix: string) => {
    const { nightRange, setNightsRange } = useNightsRangeStore(modelPrefix);

    const updateStoreNightRangeFrom = (nights: number) => {
        const nightsTo = nightRange.value?.to;
        if (nightsTo) {
            setNightsRange({
                from: nights,
                to: nights > nightsTo ? nights : nightsTo,
            });
        }
    };

     const updateStoreNightRangeTo = (nights: number) => {
         const nightsFrom = nightRange.value?.from;
         if (nightsFrom) {
             setNightsRange({
                 from: nights < nightsFrom ? nights : nightsFrom,
                 to: nights,
             });
         }
    };

     return {
         updateStoreNightRangeFrom,
         updateStoreNightRangeTo,
     };
};