export default (params) => {
    const tourCriteria = {};

    if (params.nightFrom && params.nightTo) {
        tourCriteria.nightRange = {
            from: params.nightFrom,
            to: params.nightTo,
        };
    }

    return tourCriteria;
};