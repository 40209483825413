import FilterAttributeMethodsAbstract from '@ve/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { SerpHotelAdapted } from '@fr/vue/components/searchFilters/store/types';
import dataRatingsReviews from './dataRatingsReviews';
import { IReviewRating } from '../types';

class FilterAttributeRatingReviewsMethods extends FilterAttributeMethodsAbstract<IReviewRating, SerpHotelAdapted> {
    constructor() {
        super(dataRatingsReviews);
    }

    getCountAttribute(hotels: SerpHotelAdapted[]): Record<IReviewRating['key'], number> {
        return hotels.reduce((acc, hotel) => {
            const keysTypesRating = this.getKeyReviewsByRating(hotel.rating);
            keysTypesRating.forEach((typeRatingKey) => {
                acc[typeRatingKey]++;
            });
            return acc;
        }, this.getAttributeObjectCount());
    }

    getIsOneHotelHasProperty(hotels: SerpHotelAdapted[]): boolean {
        return !!hotels.find((hotel) => hotel.rating)?.rating;
    }

    getKeyReviewsByRating(rating: number): IReviewRating['key'][] {
        const reviews = this.getListFilterAttributes();
        const roundRating = Math.round(rating * 10) / 10;
        if (!roundRating) return ['noScore'];
        return reviews
            .filter((ratingReview) => roundRating >= +ratingReview.rating && +ratingReview.rating)
            .map((ratingReview) => ratingReview.key)
            .concat(['noScore']);
    }

    getRatingByKey(key: IReviewRating['key']): IReviewRating['rating'] | undefined {
        return this.getListFilterAttributes().find((attribute) => attribute.key === key)?.rating;
    }

    getReviewsRatingById(id: IReviewRating['id']): IReviewRating['rating'] | undefined {
        return this.getAttributeById(id)?.rating;
    }
}

export default new FilterAttributeRatingReviewsMethods().setFilterName('Оценка по отзывам');