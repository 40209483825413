
    import { Component } from 'vue-property-decorator';
    import Button from '@ve/components/button/common/Button';
    /**
     * TODO: компонент Button должен быть сделат через button и с возможностью принимать тип submit для встраивания в формы
     */
    @Component({
        name: 'ButtonDesktop',
    })
    export default class extends Button {
    }
