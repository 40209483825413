export default (tourCriteria) => {
    const params = {};
    const element = tourCriteria.get('meals');
    if (element && element.length) {
        params.meal = element.pluck('id').join('.');
    } else {
        params.meal = 'all';
    }

    return params;
};