import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { formatNumber } from '@ve/services/textModifiers';
import { IFilterPreviousState, IFilterAdditionalItem, TValueStateBudgetFilter } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const budgetFilterCount = (hotels: SerpHotelAdapted[], filtersState: IFilterPreviousState['previousStateFiltersSerp'] | null): IFilterAdditionalItem<TValueStateBudgetFilter> | null => {
    if (!filtersState) return null;
    const idFilterBudget = 12;
    const userMinPrice = filtersState.minPrice;
    const userMaxPrice = filtersState.maxPrice;
    const isUserTakenOneOfPrices = userMaxPrice || userMinPrice;
    let text = '';
    if (isUserTakenOneOfPrices) {
        if (userMinPrice && userMaxPrice) { // Пользователь поставил минимальную и максимальную цену
            const isOnesHotelsHasThisRange = !!hotels.find((hotel) => hotel.minPrice >= userMinPrice && hotel.minPrice <= userMaxPrice);
            text = `Бюджет: от ${formatNumber(userMinPrice)} до ${formatNumber(userMaxPrice)} ₽`;
            if (isOnesHotelsHasThisRange) {
                return {
                    id: idFilterBudget,
                    text,
                    isDisabled: false,
                    valueState: {
                        maxPrice: userMaxPrice,
                        minPrice: userMinPrice,
                    },
                };
            }

            return {
                id: idFilterBudget,
                text,
                isDisabled: true,
            };
        }
        const isMinPriceInThisRange = userMinPrice ? !!hotels.find((hotel) => hotel.minPrice >= userMinPrice) : false;
        const isMaxPriceInThisRange = userMaxPrice ? !!hotels.find((hotel) => hotel.minPrice <= userMaxPrice) : false;

        if (userMinPrice) {
            text = `Бюджет: от ${formatNumber(userMinPrice)} ₽`;
        }

        if (userMaxPrice) {
            text = `Бюджет: до ${formatNumber(userMaxPrice)} ₽`;
        }

        if (isMaxPriceInThisRange || isMinPriceInThisRange) {
            return {
                id: idFilterBudget,
                text,
                isDisabled: false,
                valueState: {
                    maxPrice: userMaxPrice,
                    minPrice: userMinPrice,
                },
            };
        }

        return {
            id: idFilterBudget,
            text,
            isDisabled: true,
        };
    }
    return null;
};