import { IBeachLine } from '../types';

const dataBeachLines = [{
    id: 1,
    key: 'firstLine',
    keyMerged: 'firstLine',
    name: '1-я линия: 0—200 метров',
    nameEvent: 'beach_line_1',
    nameLayer: 'Линия пляжа_1-я',
},
{
    id: 2,
    key: 'secondLine',
    keyMerged: 'secondLine',
    name: '2-я линия: 201—500 метров',
    nameEvent: 'beach_line_2',
    nameLayer: 'Линия пляжа_2-я',
},
{
    id: 3,
    key: 'thirdLine',
    keyMerged: 'thirdLine',
    name: '3-я линия: 501—800 метров',
    nameEvent: 'beach_line_3',
    nameLayer: 'Линия пляжа_3-я',
}] as IBeachLine[];

export default dataBeachLines;
