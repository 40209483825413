import { IRawRoom } from '../types/IRawRoom';

export interface IRoomsRepositoryState {
    rooms: IRawRoom[];
}

export interface IRoomsRepositoryMutations {
    setRooms(state: IRoomsRepositoryState, rooms: IRawRoom[]): void;
}

const RoomsRepository = {
    namespaced: true,
    state: {
        rooms: [],
    },
    mutations: {
        setRooms(state: IRoomsRepositoryState, rooms: IRawRoom[]) {
            const roomsHash = state.rooms.reduce((acc, room) => {
                acc[room.id] = true;
                return acc;
            }, {} as Record<string, boolean>);
            const newRooms = rooms?.filter((room) => !roomsHash[room.id]) || [];
            if (newRooms.length) {
                state.rooms = [...state.rooms, ...newRooms];
            }
        },
    },
};

export const RoomsRepositoryName = 'roomsRepositoryName';

export default RoomsRepository;
