import _ from 'underscore';
import { computed, Ref, unref } from 'vue';
import { MapForFiltersState } from '@ve/components/hotel/filters/common/store/types';
import { useState } from 'vuex-composition-helpers';

export default ({
                    selectedMeals,
                    storeName,
                }: {
    selectedMeals: Ref<number[]>;
    storeName: string;
}) => {
    const {
        mealsRepository,
    } = useState<MapForFiltersState>(storeName, ['mealsRepository']);

    const allowedMealTypes = [8, 1, 11, 2, 10, 3, 5, 7];

    const mealsReference = computed(() => {
        const mealsRepositoryData = unref(mealsRepository);
        if (!mealsRepositoryData?.length) return [];
        const mealsRepositoryById = _.indexBy(mealsRepositoryData, 'id');
        return allowedMealTypes.map((mealId) => mealsRepositoryById[mealId]);
    });

    const allMealsChecked = computed({
        get: () => !unref(selectedMeals).length,
        set: (value) => {
            if (value) {
                selectedMeals.value = [];
            }
        },
    });

    return {
        allMealsChecked,
        mealsReference,
    };
};