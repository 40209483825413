import { computed, unref, ComputedRef } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { IMapForFiltersSerpState, SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
import { IFilterAttribute } from '../types';
import FilterAttributeMethodsAbstract from '../filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';

type IReturnTypeFunction = {
    shouldShowFilterByLogicRestType: ComputedRef<boolean>,
}

export default function (filterAttributesMethods: FilterAttributeMethodsAbstract<IFilterAttribute, SerpHotelAdapted>): IReturnTypeFunction {
    const {
        filteredHotelsByRestType, syncHotels,
    } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['filteredHotelsByRestType', 'syncHotels']);

    const atLeastOneHotelHasPropertyOfFilteredHotelsByRestType = computed(() => filterAttributesMethods.getIsOneHotelHasProperty(unref(filteredHotelsByRestType)));
    const atLeastOneHotelHasPropertyOfAllHotels = computed(() => filterAttributesMethods.getIsOneHotelHasProperty(unref(syncHotels)));

    const shouldShowFilterByLogicRestType = computed(() => unref(atLeastOneHotelHasPropertyOfFilteredHotelsByRestType) && unref(atLeastOneHotelHasPropertyOfAllHotels));

    return {
        shouldShowFilterByLogicRestType,
    };
}