import { IFilterAttribute } from '@ve/components/filters/common/types';

export default abstract class FilterAttributeMethodsAbstract<T extends IFilterAttribute, E> {
    arrayAttributes: T[];

    filterName?: string;

    constructor(arrayAttributes: T[]) {
        this.arrayAttributes = arrayAttributes;
    }

    setFilterName(name: string): this {
        this.filterName = name;
        return this;
    }

    getKeyByIdAttribute(id: T['id']): T['key'] | undefined {
        return this.getListFilterAttributes().find((attribute) => +id === attribute.id)?.key;
    }

    getAttributeById(id: T['id']): T | undefined {
        return this.getListFilterAttributes().find((attribute) => +id === attribute.id);
    }

    getKeysAttributeByIds(ids: T['id'][]): T['key'][] {
        return ids.reduce((acc, id) => {
            const keyById = this.getKeyByIdAttribute(id);
            if (keyById) {
                acc.push(keyById);
            }
            return acc;
        }, [] as T['key'][]);
    }

    getTextAttributesByIds(ids: T['id'][]): string {
        return this.getListFilterAttributes()
            .filter((attribute) => ids.indexOf(attribute.id) !== -1)
            .map((attribute) => attribute.name)
            .join(', ');
    }

    getMergedAttributes(ids: T['id'][]): Record<T['keyMerged'], 0 | 1> {
       return this.getMergedAttributeByProperty(ids, 'keyMerged');
    }

    getMergedAttributesIdsByKeys(ids: T['id'][]): Record<T['key'], 0 | 1> {
        return this.getMergedAttributeByProperty(ids, 'key');
    }

    getIdsAttributesByObjectKeys(keys?: Record<T['key'], boolean>): T['id'][] {
        if (!keys) return [] as T['id'][];
        return this.getListFilterAttributes().reduce((acc, attribute) => {
            const valueAttribute: boolean = keys[attribute.key as T['key']];
            if (valueAttribute) {
                acc.push(attribute.id);
            }
            return acc;
        }, [] as T['id'][]);
    }

    getObjectKeysAttributesByIds(ids: T['id'][]): Record<T['key'], boolean> {
        if (!ids) return {} as Record<T['key'], boolean>;
        return Object.entries(this.getMergedAttributeByProperty(ids, 'key'))
            .map(([keyAttribute, valueAttribute]): [string, boolean] => [keyAttribute, !!valueAttribute])
            .reduce((acc, [keyAttribute, valueAttribute]: [string, boolean]) => {
                if (valueAttribute) {
                    acc[keyAttribute] = valueAttribute;
                }
                return acc;
        }, {} as Record<string, boolean>);
    }

    protected getMergedAttributeByProperty(ids: T['id'][], property: keyof T): Record<string, 0 | 1> {
        if (!ids) return {} as Record<string, never>;
        return this.getListFilterAttributes().reduce((acc, attribute) => {
            acc[attribute[property] as T['key']] = ids.indexOf(attribute.id) === -1 ? 0 : 1;
            return acc;
        }, {} as Record<string, 0 | 1>);
    }

    getAttributeObjectCount(): Record<T['key'], number> {
        return this.getListFilterAttributes()
            .reduce((acc, attribute) => {
            acc[attribute.key] = 0;
            return acc;
        }, {} as any);
    }

    abstract getCountAttribute(entities: E[]): Record<T['key'], number>;

    abstract getIsOneHotelHasProperty(entities: E[]): boolean;

    isOneEntityHasProperty(entities: E[]): boolean {
        return this.getIsOneHotelHasProperty(entities);
    }

    isSomeEntityHasProperty(entities: E[]): boolean {
        return this.getIsOneHotelHasProperty(entities);
    }

    getEventNameByIdAttribute(id: T['id']): T['nameEvent'] | undefined {
        return this.getAttributeById(id)?.nameEvent;
    }

    getLayerNameByIdAttribute(id: T['id']): T['nameLayer'] | undefined {
        return this.getAttributeById(id)?.nameLayer;
    }

    getListFilterAttributes(): T[] {
        return this.arrayAttributes;
    }
}