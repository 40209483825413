import { useState, useMutations } from 'vuex-composition-helpers';
import { unref, computed } from 'vue';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import filtersTourProductsModel, {
    IFiltersTourProductsMutations,
    IFiltersTourProductsState,
    NAMESPACE,
} from '../model/filtersTourProductsModel';

export default function () {
    registerModule.register(getStore(), filtersTourProductsModel, NAMESPACE);
    const {
        confirmationFilter,
    } = useState<IFiltersTourProductsState>(NAMESPACE, ['confirmationFilter']);
    const {
        setConfirmationFilter,
    } = useMutations<IFiltersTourProductsMutations>(NAMESPACE, ['setConfirmationFilter']);

    const confirmation = computed({
        get: () => unref(confirmationFilter),
        set: (value: IFiltersTourProductsState['confirmationFilter']) => {
            setConfirmationFilter(value);
        },
    });

    return {
        confirmation,
        setConfirmationFilter,
    };
}