import Backbone from 'backbone';
import { EmptyObject } from '@ve/types/EmptyObject';

interface DirectFlight {
    directFlight?: true,
}

export default (tourCriteria: Backbone.Model): DirectFlight | EmptyObject => {
    const params: DirectFlight | EmptyObject = {};
    const element: boolean | null = tourCriteria.get('directFlight');
    if (element) {
        params.directFlight = element;
    }

    return params;
};