import { computed } from 'vue';
import { format, milliseconds, differenceInDays } from 'date-fns';
import wordEnding from '@/js/common/Domain/Service/mixins/wordEnding';
import { useCheckInDateRangeStore } from '@entities/searchCriteria/checkInDate';
import { useNightsRangeStore } from '@entities/searchCriteria/nightsRange';
import updateStoreNightRange from './updateStoreNightRange';

export default (props: { modelPrefix: string }) => {
    const { nightRange } = useNightsRangeStore(props.modelPrefix);
    const { checkInDateRange } = useCheckInDateRangeStore(props.modelPrefix);
    const { updateStoreNightRangeFrom, updateStoreNightRangeTo } = updateStoreNightRange(props.modelPrefix);
    const checkInDateRangeLabel = computed(() => {
        const dateFrom = checkInDateRange.value?.from ?? false;
        const dateTo = checkInDateRange.value?.to ?? false;
        const label = {
            mainDateLabel: '',
            plusMinOneDayLabel: '',
        };
        const daysRange = 1;
        if (dateFrom && dateTo && dateFrom.getTime() !== dateTo.getTime()) {
            if (differenceInDays(dateTo, dateFrom) > daysRange * 2) {
                label.mainDateLabel = `${format(dateFrom, 'dd.MM')} по ${format(dateTo, 'dd.MM')}`;
                label.plusMinOneDayLabel = '';
            } else {
                const middleDate = new Date(dateTo.getTime() - milliseconds({ days: daysRange }));
                label.mainDateLabel = `${format(middleDate, 'dd.MM')}`;
                label.plusMinOneDayLabel = `(± ${daysRange} ${wordEnding(daysRange, 'день;дня;дней')})`;
            }
        } else if (dateFrom && dateTo) {
            label.mainDateLabel = `${format(dateFrom, 'dd.MM')}`;
            label.plusMinOneDayLabel = '';
        }

        return label;
    });

    const nightsLabel = computed(() => {
        const nightRangeFrom = nightRange.value?.from ?? false;
        const nightRangeTo = nightRange.value?.to ?? false;
        let label = '';

        if (nightRangeFrom && nightRangeTo && nightRangeFrom !== nightRangeTo) {
            label = `${nightRangeFrom} - ${nightRangeTo} ноч${wordEnding(nightRangeTo, 'ь;и;ей')}`;
        } else if (nightRangeFrom) {
            label = `${nightRangeFrom} ноч${wordEnding(nightRangeFrom, 'ь;и;ей')}`;
        }

        return label;
    });

    return {
        nightRange,
        updateStoreNightRangeFrom,
        updateStoreNightRangeTo,
        checkInDateRangeLabel,
        nightsLabel,
    };
};