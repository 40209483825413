import DepartureCityEntity from '../Entity/DepartureCity';

export default function DepartureCityFactory(data) {
    const departureCity = new DepartureCityEntity();
    let element;

    element = data.id;
    if (typeof element !== 'undefined') {
        departureCity.set('id', element);
    }

    element = data.name;
    if (typeof element !== 'undefined') {
        departureCity.set('name', element);
    }

    element = data.type;
    if (typeof element !== 'undefined') {
        departureCity.set('type', element);
    }

    element = data.countries;
    if (typeof element !== 'undefined') {
        departureCity.set('countries', element);
    }

    element = data.countriesWithDirectFlights;
    if (typeof element !== 'undefined') {
        departureCity.set('countriesWithDirectFlights', element);
    }

    departureCity.set('daysRange', 1);
    return departureCity;
}