/* eslint-disable */
export const subscribeEmail = options => {
	return new Promise((resolve, reject) => {
		$.ajax({
			url: '/subscriber/add',
			data: options,
			type: 'GET'
		}).done(response => {
			if (response && response.success === true) {
				resolve(response);
			} else {
				console.log('Something wrong');
				console.log(response.message);
				reject();
			}
		}).error(e => {
			console.log('Something wrong! AJAX ERROR! ', e);
			reject();
		});
	});
};