var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:[
        'checkbox-radio-item-common',
        `checkbox-radio-item-common_${_vm.checkBoxSize}`,
        {
            'checkbox-radio-item-common_white-space': _vm.isWhiteSpace,
            'checkbox-radio-item-common_disable': _vm.disabled,
            'checkbox-radio-item-common_hover': _vm.isHover && !_vm.disabled
        },
    ],attrs:{"data-role":"none"}},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"change":_vm.updateInput}}),_vm._v(" "),_c('div',{staticClass:"checkbox-radio-item-common__icon",class:`checkbox-radio-item-common__icon_${_vm.checkBoxSize}`}),_vm._v(" "),_c('div',{staticClass:"checkbox-radio-item-common__content"},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }