import departureCity from './tourCriteria/departureCity';
import countries from './tourCriteria/countries';
import checkInDateRange from './tourCriteria/checkInDateRange';
import nightRange from './tourCriteria/nightRange';
import touristGroup from './tourCriteria/touristGroup';
import meals from './tourCriteria/meals';
import resorts from './tourCriteria/resorts';
import hotelCategories from './tourCriteria/hotelCategories';
import hotels from './tourCriteria/hotels';

const tourCriteriaTourhunterHydrator = (tourCriteria) => {
    let tourCriteriaTourHunter = {
        ...departureCity(tourCriteria),
        ...countries(tourCriteria),
        ...checkInDateRange(tourCriteria),
        ...nightRange(tourCriteria),
        ...touristGroup(tourCriteria),
        ...hotelCategories(tourCriteria),
        ...meals(tourCriteria),
        ...hotels(tourCriteria),
    };

    tourCriteriaTourHunter.resorts = [];

    const hotelsData = tourCriteria.get('hotels');
    if (!hotelsData?.length) {
        tourCriteriaTourHunter = {
            ...tourCriteriaTourHunter,
            ...resorts(tourCriteria),
        };
    }
    return tourCriteriaTourHunter;
};

export default tourCriteriaTourhunterHydrator;