import { CHILD_SERVICE_OLD_MAP, ChildServiceId } from '@entities/hotel';
import { ChildServiceFilterItem } from '../types/ChildServiceFilterItem';

const childServiceFilters: ChildServiceFilterItem[] = [
    {
        id: 9,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Animators],
        keyMerged: 'children_animators',
        name: 'Детская анимация',
        nameLayer: 'Услуги для детей_Детская анимация',
        nameEvent: '',
    },
    {
        id: 10,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Pool],
        keyMerged: 'kidsPool',
        name: 'Детский бассейн',
        nameLayer: 'kidsPool',
        nameEvent: '',
    },
    {
        id: 11,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Menu],
        keyMerged: 'children_childmenu',
        name: 'Детское меню',
        nameLayer: 'Услуги для детей_Детское меню',
        nameEvent: '',
    },
    {
        id: 17,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Bed],
        keyMerged: 'children_childBed',
        name: 'Детские кроватки',
        nameLayer: 'Услуги для детей_Детские кроватки',
        nameEvent: '',
    },
    {
        id: 18,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Nanny],
        keyMerged: 'children_nanny',
        name: 'Услуги няни / Уход за детьми',
        nameLayer: 'Услуги для детей_Услуги няни (платно)',
        nameEvent: '',
    },
    {
        id: 16,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Clubs],
        keyMerged: 'children_clubs',
        name: 'Детские клубы',
        nameLayer: 'Услуги для детей_Детские мини-клуб',
        nameEvent: '',
    },
    {
        id: 12,
        key: CHILD_SERVICE_OLD_MAP[ChildServiceId.Playroom],
        keyMerged: 'children_playroom',
        name: 'Игровая комната',
        nameLayer: '',
        nameEvent: '',
    },
];

export default childServiceFilters;