/* eslint-disable */
$(() => {
	var initAppsflyerLink = function() {
		$('body').on('click', '.addGaId', function() {
			var $link = $(this),
				alreadyAdded = $link.data('gaIdAdded'),
				currentLink;

			if(!alreadyAdded && typeof(ga) !== "undefined") {
				currentLink = $link.attr('href');
				$link.attr('href', currentLink + '&af_ad_id=' + (ga.getAll ? ga.getAll()[0].get('clientId') : 'unknown'));
				$link.data('gaIdAdded', true);
			}
		});
	};
	initAppsflyerLink();
});