import { type ICountry } from '@entities/country/@/hotel';
import { type IResort } from '@entities/resort/@/hotel';
import getBeachType from '../lib/getBeachType';
import { type IHotel } from '../types';
import { type IRawHotel } from '../types/IRawHotel';
import getChildService from './getChildService';
import getRestType from './getRestType';

export default function (hotels: IRawHotel[], countries: ICountry[], resorts: IResort[]): IHotel[] {
    return hotels.reduce((acc, hotel) => {
        const hotelCountry = countries.find((country) => country.id === hotel.country);
        const hotelResort = resorts.find((resort) => resort.id === hotel.resort);
        if (hotelCountry && hotelResort) {
            acc.push(Object.freeze({
                ...hotel,
                country: hotelCountry,
                resort: hotelResort,
                childServices: getChildService(hotel.attributes),
                restTypes: getRestType(hotel.types),
                beachType: getBeachType(hotel.attributes),
            }));
        }
        return acc;
    }, [] as IHotel[]);
}