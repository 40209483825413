import { CheckInDateRange } from '@ve/types';
import Backbone from 'backbone';

export default function (checkInDateRange: Backbone.Model<CheckInDateRange>): CheckInDateRange | null {
    if (!checkInDateRange) return null;

    return {
        from: checkInDateRange.get('from'),
        to: checkInDateRange.get('to'),
    };
}