import imageProxy from '@/js/common/Domain/Service/imageProxy';
import CashbackHotelCardBannerView from '@/js/desktop/components/cashbackHotelCardBanner/cashbackHotelCardBanner';
import IsExclusiveBlock from '@ve/components/hotel/isExclusiveBlock/desktop/isExclusiveBlock.vue';
import trackImageErrors from '@/js/common/Domain/Service/trackImageErrors';
import ls from 'local-storage';
import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
import getStore from '@ve/services/getStore/getStore';
import Vue from 'vue';
import SearchPageHotelCardRightBlock from '@ve/components/serpHotelCard/SearchPageHotelCardRightBlock.vue';

const serpTrackingService = new SerpTrackingService();

export default class SearchPageHotelCardAbstract extends Backbone.Epoxy.View {
    selectTemplate() {
        return this.template;
    }

    initCashbackBanner(productType) {
        new CashbackHotelCardBannerView({
            el: $('.serpHotelCard__container', this.$el),
            productType,
        });
    }

    renderPhotos() {
        let imagesHtml = this.model.get('imagesHtml');
        const $modelElement = this.$el;
        const $imagesPlace = $('.imagesHtml', $modelElement);
        const $carousel = $('.js-images-carousel', $modelElement);

        if (!imagesHtml) {
            imagesHtml = this.generateImagesHtml({
                cover: this.model.get('cover'),
                photos: this.model.get('photos'),
            });
            this.model.set('imagesHtml', imagesHtml);
        }

        if (imagesHtml) {
            if ($imagesPlace.length) {
                $imagesPlace.html(imagesHtml);
            }

            if (_.isObject($carousel.data('jcarousel'))) {
                $carousel.jcarousel('reload', {
                    animation: 'fast',
                });
                $carousel.jcarousel('scroll', 0, false);
            }
        }
    }

    getPropsForHotelCardRightBlock() {}

    goToTourPage() {}

    isHotelHotView() {
        return false;
    }

    initSerpHotelCardRightBlock() {
        const store = getStore();
        const hotelCardRightBlockData = this.getPropsForHotelCardRightBlock();
        return new Vue({
            render: (h) => h(SearchPageHotelCardRightBlock, {
                props: {
                    hotelCardRightBlockData,
                },
                on: {
                    'go-to-target-page-button-click': (e) => {
                        if (this.isHotelHotView()) this.goToTourPage(e);
                        else this.goToHotel(e);
                    },
                    'mouse-over-serp-price-tooltip': () => {
                        serpTrackingService.serpPriceTooltipMouseOver();
                    },
                },
            }),
            store,
        }).$mount(this.el.querySelector('.js-right-block'));
    }

    initVueComponents() {
        const isExclusive = this.model.get('isExclusive');
        if (isExclusive) {
            this.IsExclusiveBlockVueComponent = new IsExclusiveBlock({
                el: $('.js-serpHotelCard__isExclusive', this.$el).get(0),
                propsData: {
                    isExclusive,
                },
            });
        }
        this.initSerpHotelCardRightBlock();
    }

    initCarousel() {
        $('.js-images-carousel', this.$el).jcarousel({
            list: '.list',
            items: 'li',
            wrap: 'both',
            vertical: false,
        }).on('jcarousel:visiblein', 'li', function () {
            const $element = $('a:not(.btn)', this);
            const imageUrl = $element.data('src');

            $element.css('background-image', `url("${imageUrl}")`);
            trackImageErrors([imageUrl]);
        });

        $('.js-images-carousel-control-prev', this.$el).jcarouselControl({
            target: '-=1',
        });

        $('.js-images-carousel-control-next', this.$el).jcarouselControl({
            target: '+=1',
        });
    }

    reRender() {
        this.render();
    }

    generateImagesHtml({ cover, photos }) {
        let html = '';
        const images = [].concat(
            cover || [],
            photos || [],
        );

        if (images?.length) {
            _.each(images, (photo) => {
                html += `<li><a href="#" data-src="${imageProxy(photo, {
                    width: 300,
                    height: 220,
                })}"></a></li>`;
            });

            const randomPhoto = images[Math.round(Math.random() * (images.length - 1))];
            html += `<li class="lastPhotoLink"><a class="img" href="#" data-src="${imageProxy(randomPhoto, {
                width: 300,
                height: 220,
            })}"></a><a href="#" class="btn">Смотреть все фото</a></li>`;
        }

        return html;
    }

    callYMGoals(event) {
        const $target = $(event.currentTarget);
        const $parent = $target.parents('.serpHotelCard');

        if ($parent.find('.serpHotelCard__discount').length) {
            serpTrackingService.discountClick();
        }

        if ($parent.find('.serpHotelCard__ordersCount').length) {
            serpTrackingService.orderCountClick();
        }

        if ($parent.find('.serpHotelCard__tip__less-places').length) {
            serpTrackingService.lessPlacesClick();
        }
    }

    sliderArrowClick() {
        serpTrackingService.sliderArrowClick();
    }

    buildUrlParams() {}

    goToHotel(event) {
        const urlParams = this.buildUrlParams();
        const $target = $(event.currentTarget);
        const target = ($('.oneColumn:visible').length) ? '_self' : '_blank';
        const hotelUrl = this.model.get('url');

        if ($target.hasClass('serpHotelCard__resort')) {
            serpTrackingService.resortClick();
        }

        if ($target.hasClass('distance')) {
            urlParams.toMap = true;
        }
        if ($target.hasClass('hotel-reviews')) {
            urlParams.toReviews = true;
        }
        window.open(`${hotelUrl}#?${$.param(urlParams)}`, target);
        ls.set('isAbsent', false);
        return false;
    }
}