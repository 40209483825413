import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';

@extendDecorator({
    defaults: {
        userAuthorized: false,
        loadingProfileData: false,
        logoutInProgress: false,
        activeTourHunters: '',
        accountItemShow: 0,
    },
})
export default class TopMenuBarModel extends Backbone.Epoxy.Model {}
