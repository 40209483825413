import 'jquery.cookie';
import 'jcarousel';
import Vue from 'vue';
import useVueCompositionApi from '@/js/common/plugins/use-vue-composition-api';

import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import LotteryBannerView from '@/js/desktop/components/search/lotteryBannerSerp/LotteryBanner';
import LifeHackBannerView from '@/js/common/components/lifeHackBanner';
import HotelOnMapView from '@/js/desktop/components/hotelOnMap/hotelOnMap';
import { DesktopSerpFilters } from '@widgets/serpToursProducts/desktop';
import commonCategoriesList from '@ve/components/filters/hotelCategoriesFilter/common/commonCategoiesList';

import SearchPageAbstract from './Abstract/SearchPageAbstract';

export default class SearchPageView extends SearchPageAbstract {
    initAdditionalViews() {
        this.initLotteryBannerForStatic();
        this.lifeHackBannerForStaticPlace();
    }

    initLotteryBannerForStatic() {
        const $staticLoteryPlaces = $('.lotteryBannerForStaticPlace', this.$el);

        $staticLoteryPlaces.each(function () {
            new LotteryBannerView({
                el: $(this),
            });
        });
    }

    lifeHackBannerForStaticPlace() {
        const $lifeHackBannerForStaticPlace = $('.lifeHackBannerForStaticPlace', this.$el);

        $lifeHackBannerForStaticPlace.each(function () {
            new LifeHackBannerView({
                el: $(this),
            });
        });
    }

    initScrolPagination() {
        let bottomListElement = $('#eop');

        $(window).scroll(() => {
            const scrollTopPosition = $(document).scrollTop();
            const windowHeight = $(window).height();

            this.model.set('scrollPosition', scrollTopPosition);

            if (!bottomListElement.length) {
                bottomListElement = $('#eop');
            }

            if (bottomListElement.length && bottomListElement.is(':visible') && scrollTopPosition + windowHeight + 320 > bottomListElement.position().top) {
                this.pageInc();
            }
        });
    }

    initDomEvents() {
        super.initDomEvents();
        this.initScrolPagination();
    }

    initialize(options) {
        super.initialize(options);
        this.initIsExclusiveState();
        this.dataLayerEvents();
    }

    initIsExclusiveState() {
        const isExclusiveFromUrl = this.searchFormViewModel.get('isExclusive').get('model');
        this.model.get('filterCriteria').set('isExclusive', isExclusiveFromUrl);
    }

    showAllCheapest() {
        this.model.set('sortStrategy', 'priceUp');
        this.clearFilter();
        this.showAll();
        return false;
    }

    showAll() {
        const fullHotels = this.model.get('asyncHotels');
        const fullTourProducts = this.model.get('asyncTourProducts');

        this.model.set('pageNumber', 1, { silent: true });
        this.collection.reset();

        this.model.set('syncHotels', new HotelsRepository(fullHotels.models));
        this.model.set('syncTourProducts', fullTourProducts);

        console.log('Show new async tours click');

        return false;
    }

    HotelOnMapViewConstructor() {
        return HotelOnMapView;
    }

    async initHotelMapView() {
        const { default: HotelsMapView } = await import(/* webpackChunkName: "HotelsMapView" */ '@/js/desktop/components/hotelsMap/HotelsMap');
        return HotelsMapView;
    }

    dataLayerEvents() {
        if (this.dataLayerAnalytics) {
            //fixed
            this.dataLayerAnalytics.generateEvent('cacheResults', !this.withoutStatic);
            this.dataLayerAnalytics.preSearchPagePath();
        }
    }

    initVueComponents() {
        useVueCompositionApi();
        this.initFilters();
        this.initRefreshLoader();
    }

    initFiltersComponent(store) {
        return new Vue({
            name: 'FiltersListSerp',
            render: (h) => h(DesktopSerpFilters, {
                props: {
                    categoriesList: commonCategoriesList,
                },
            }),
            store,
        }).$mount('.filters-list-serp');
    }
}