/* eslint-disable */
import '@/js/desktop/plugins/select2';

import './hotelSearchTooltip.css';

let HotelView = Backbone.View.extend({
	label: 'Отель',
	searchModel: null,
	scrollToElement: function () {
		var currenPosition = $(document).scrollTop(),
			$filtersBlock = $('.sideBlock.filters'),
			$isExclusiveBlock = $('.isExclusiveFilter.filters'),
			windowHeight = $(window).height(),
			headerHeight = $('.headerContainer').height(),
			menuHeight = $('.menuContainer').height(),
			elementPosition,
			self = this,
			scrollTo;

		/*Force Open filters*/
		$filtersBlock.addClass('filtersOpened');
		$isExclusiveBlock.addClass('filtersOpened');

		elementPosition = $('#js-hook-scroll-to-filer-search').offset().top;

		scrollTo = elementPosition;

		headerHeight ? scrollTo -= headerHeight : '';
		menuHeight ? scrollTo -= menuHeight : '';

		scrollTo -= 50;

		setTimeout(function () {
			$filtersBlock.addClass('filtersOpened');
			$isExclusiveBlock.addClass('filtersOpened');
		}, 1);

		if (elementPosition > currenPosition + windowHeight || elementPosition < currenPosition) {
			$("html,body").animate({scrollTop: scrollTo}, 200);
			return true;
		}

		return false;
	},
	initialize: function (options) {
		var self = this;

		this.searchModel = options.searchModel;
		this.pageType = options.pageType;

	},
});

export default HotelView;