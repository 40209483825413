
    import {
        defineComponent, ref, toRefs, unref,
    } from 'vue';

    export default defineComponent({
        name: 'ToggleListContent',
        props: {
            isShouldOpenToggleByDefault: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const { isShouldOpenToggleByDefault } = toRefs(props);
            const toggle = ref(unref(isShouldOpenToggleByDefault));

            return {
                toggle,
            };
        },
    });
