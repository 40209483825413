
    import { defineComponent, getCurrentInstance } from 'vue';
    import { useState } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';

    export default defineComponent({
        setup() {
            const { loadingState, syncHotels } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['loadingState', 'syncHotels']);

            return {
                syncHotels,
                loadingState,
            };
        },
    });
