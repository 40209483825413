import { DirectiveOptions } from 'vue';

interface HTMLElementExtended extends HTMLElement {
    clickOutsideEvent(event: MouseEvent): void;
}

export default {
    bind(el, binding, vnode) {
        const exEl = el as HTMLElementExtended;
        exEl.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target as Node)) && vnode.context) {
                const expression = binding.expression as keyof typeof vnode.context;
                vnode.context[expression](event);
            }
        };
        document.body.addEventListener('click', exEl.clickOutsideEvent);
    },
    unbind(el) {
        const exEl = el as HTMLElementExtended;
        document.body.removeEventListener('click', exEl.clickOutsideEvent);
    },
} as DirectiveOptions;