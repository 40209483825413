import { unref, nextTick } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
import toggleAnalyticsFilter from '@ve/components/filters/common/services/toggleAnalyticsFilter';
import SendAnalytics from '@ve/services/SendAnalytics';
import gaAsync from '@/js/common/Domain/Service/gaAsync';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
import FilterAttributeMethodsAbstract from '../filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { IFilterAttribute } from '../types';

interface useFilterAttributesAnalytics {
    takenAttributeHandler: (selectedAttributeId: number | undefined) => void;
    toggleAttributeHandler: (attributeId: number | null, checked: boolean) => void;
}
interface IOptionsAnalytics {
    typeEvent: string;
    nameAnyEventEng: string;
    nameAnyEventRu: string;
}

export default function (
        filterMethods: FilterAttributeMethodsAbstract<IFilterAttribute, any> | undefined,
        options?: IOptionsAnalytics,
    ): useFilterAttributesAnalytics {
    const { filteredHotels } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['filteredHotels']);
    const takenAttributeHandler = (selectedAttributeId: number | undefined) => {
        if (selectedAttributeId) {
            const nameEvent = filterMethods?.getEventNameByIdAttribute(selectedAttributeId);
            if (nameEvent) {
                console.log(nameEvent);
                gaAsync(() => {
                    ga('send', 'event', 'Search_Form', 'Additionally', nameEvent, { nonInteraction: 1 });
                });
            }
            if (options) {
                const { typeEvent } = options;
                if (nameEvent) {
                    SendAnalytics('sideBarFilter', { [typeEvent]: nameEvent });
                }
            }
            return;
        }
        if (options) {
            const { typeEvent, nameAnyEventEng } = options;
            SendAnalytics('sideBarFilter', { [typeEvent]: nameAnyEventEng });
        }
    };
    const toggleAttributeHandler = (attributeId: number | null, checked: boolean) => {
        if (attributeId) {
            const nameLayer = filterMethods?.getLayerNameByIdAttribute(attributeId);
            if (nameLayer) {
                nextTick(() => {
                    toggleAnalyticsFilter(nameLayer, checked, unref(filteredHotels));
                });
            }
            return;
        }
        if (options) {
            const { nameAnyEventRu } = options;
            nextTick(() => {
                toggleAnalyticsFilter(nameAnyEventRu, checked, unref(filteredHotels));
            });
        }
    };

    return {
        toggleAttributeHandler,
        takenAttributeHandler,
    };
}