import { getValuesFilterCriteriaByNew, getNewNameFieldsFilterCriteria } from '@/js/common/Domain/Service/AdaptersFilterCriteria';
import departureCity from './toRouter/departureCity';
import checkInDateRange from './toRouter/checkInDateRange';
import nightRange from './toRouter/nightRange';
import touristGroup from './toRouter/touristGroup';
import operators from './toRouter/operators';
import priceRange from './toRouter/priceRange';
import meals from './toRouter/meals';
import warranted from './toRouter/warranted';
import directFlights from './toRouter/directFlights';
import resorts from './toRouter/resorts';
import hotelCategories from './toRouter/hotelCategories';
import toCountry from './toRouter/toCountry';

export default (urlParams) => {
    const {
        tourCriteria,
        serpLink = false,
        filterCriteria,
        sort,
        identity,
        similar = false,
        sid,
        suuid,
        hsid,
        isExclusive = false,
        productType,
        typeConfirmation,
        operatorId,
        minPrice,
        oilTaxPrice,
    } = urlParams;
    let tourCriteriaParams = {
        ...departureCity(tourCriteria),
        ...checkInDateRange(tourCriteria),
        ...nightRange(tourCriteria),
        ...touristGroup(tourCriteria),
        ...operators(tourCriteria),
        ...warranted(tourCriteria),
        ...directFlights(tourCriteria),
    };

    if (!similar) {
        tourCriteriaParams = Object.assign(tourCriteriaParams, {
            ...priceRange(tourCriteria),
            ...meals(tourCriteria),
        });
    }
    const params = { ...tourCriteriaParams };
    if (identity) {
        params.identity = identity;
    }

    if (isExclusive) {
        params.isExclusive = isExclusive;
    }

    if (productType) {
        params.productType = productType;
    }
    if (typeConfirmation) {
        params.typeConfirmation = typeConfirmation;
    }

    if (sid) {
        params.sid = sid;
    }

    if (suuid) {
        params.suuid = suuid;
    }

    if (hsid) {
        params.hsid = hsid;
    }

    if (operatorId) {
        params.operatorId = operatorId;
    }

    if (minPrice) {
        params.minPrice = minPrice;
    }

    if (oilTaxPrice) {
        params.oilTaxPrice = oilTaxPrice;
    }
    if (serpLink) {
        params.serpLink = {
            ...tourCriteriaParams,
            ...resorts(tourCriteria),
            ...hotelCategories(tourCriteria),
            ...toCountry(tourCriteria),
        };
        const filterCriteriaForSerpLink = {};
        //если сюда пришли фильтры с новыми названиями, то преобразовываем их в старые названия для сохранения контракта данных с приложениями
        const newFilterCriteriaAttributes = getValuesFilterCriteriaByNew(filterCriteria.attributes);
        const allFilterCriteria = {
            ...filterCriteria.attributes,
            ...newFilterCriteriaAttributes,
        };
        _.each(allFilterCriteria, (filterValue, filterName) => {
            const isFilterCriteriaOldName = !getNewNameFieldsFilterCriteria.includes(filterName);
            if (filterValue && isFilterCriteriaOldName) {
                filterCriteriaForSerpLink[`f_${filterName}`] = filterValue;
            }
        });
        params.serpLink = Object.assign(params.serpLink, filterCriteriaForSerpLink);
        params.serpLink.sort = sort;
    }
    return params;
};