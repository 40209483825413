import { IOperator } from '@entities/operator/@/tourProduct';
import { IHotel } from '@entities/hotel/@/tourProduct';
import { IRawRoom } from '@entities/room/@/tourProduct';
import { TransferType, ITransfer } from '@entities/transfer/@/tourProduct';
import { IFirstPaymentDefinition } from '@entities/firstPaymentDefinition/@/tourProduct';
import { IRawTourProduct } from '../types/IRawTourProduct';
import { FlightType } from '../types/IFlightType';
import { THotelAvailable } from '../types/IHotelAvailable';
import { ITourProduct } from '../types/ITourProduct';
import { CountryCondition } from '../../country/types/ICountry';

export default class TourProduct implements ITourProduct {
    price: number;

    oilTax: number | null;

    hotelAvailable: THotelAvailable;

    hotel: IHotel | null = null;

    room: IRawRoom | null = null

    id: string;

    country: CountryCondition | null = null;

    operator: IOperator | null = null;

    rawTourProduct: IRawTourProduct;

    medicalInsurance: boolean;

    transferType: TransferType;

    firstPaymentDefinitions: IFirstPaymentDefinition[];

    availableTransfers: ITransfer[];

    constructor(rawTourProduct: IRawTourProduct) {
        this.rawTourProduct = rawTourProduct;
        this.price = rawTourProduct.price;
        this.id = rawTourProduct.id;
        this.hotelAvailable = rawTourProduct.hotelAvailable;
        this.oilTax = rawTourProduct.oilTax;
        this.medicalInsurance = rawTourProduct.medicalInsurance;
        this.transferType = rawTourProduct.transferType;
        this.firstPaymentDefinitions = [];
        this.availableTransfers = [];
    }

    get flightType(): FlightType {
        return this.rawTourProduct.flightType === 'regular' ? FlightType.regular : FlightType.charter;
    }

    get priceWithOilTax(): number {
        return (this.oilTax || 0) + this.price;
    }

    setOperator(operator: IOperator | null): void {
        this.operator = operator;
    }

    setHotel(hotel: IHotel | null): void {
        this.hotel = hotel;
    }

    setCountry(country: CountryCondition | null): void {
        this.country = country;
    }

    setRoom(room: IRawRoom | null): void {
        this.room = room;
    }
}