import { INightRangeStore } from '../types/INightsRangeStore';

export const NAMESPACE = 'nightsRangeStore';

export default {
    namespaced: true,
    state: () => ({
        nightRange: null,
    }),
    mutations: {
        setNightsRange: (state, nightRange) => {
            state.nightRange = nightRange;
        },
    },
} as INightRangeStore;