import _ from 'underscore';
import { ApiLeg } from '@ve/types/api/ApiLeg';
import { Airline } from '@ve/types/Airline';
import { Tariff } from '@ve/types/api/Tariff';
import { ApiLegLink } from '@ve/types/api/ApiLegLink';
import { Airport } from '@ve/types/Airport';

import flightTypeAdapter from '@/js/common/Domain/Service/searchTour/service/apiTourToTourAdapter/flightTypeAdapter';
import checkIsBaggage from './isBaggage';
import { LegInfo } from './types';

type LegKeys = {
    [key: string]: ApiLeg;
};

type AirlineKeys = {
    [key: string]: Airline;
};

type AirportKeys = {
    [key: string]: Airport;
};

type TariffKeys = {
    [key: string]: Tariff;
};

const baggageStatus = (isBaggage: boolean | null) => {
    if (isBaggage) {
        return 1;
    }
    if (isBaggage === null) {
        return 0;
    }
    return 2;
};

export default ({
                    legs, legKeys, airlineKeys, tariffKeys, airportKeys,
                }:
                    { legs: ApiLegLink[], legKeys: LegKeys, airlineKeys: AirlineKeys, airportKeys: AirportKeys, tariffKeys: TariffKeys }): LegInfo[][] => _.map(legs, (leg) => {
    const apiLegInfo = legKeys[leg.id];
    const legInfo = {
        ..._.pick(apiLegInfo, 'departureDate', 'arrivalDate', 'flightNumber', 'flightTime'),
        legId: leg.id,
        type: 'flight',
        transfer: leg.transfer,
        airline: airlineKeys[apiLegInfo.airline],
        airportDeparture: airportKeys[apiLegInfo.airportDeparture],
        airportArrival: airportKeys[apiLegInfo.airportArrival],
        flightType: flightTypeAdapter(apiLegInfo.flightType),
    };

    return leg.tariffs.map((tariff) => {
        const tariffInfo = tariffKeys[tariff.id];
        const isBusiness = tariffInfo.type === 'business';
        const isBaggage = checkIsBaggage(tariffInfo.baggage);

        return {
            id: `${apiLegInfo.id}~${tariff.id}`,
            additionalTax: tariff.price,
            leg: {
                ...legInfo,
                isBusiness,
                tariffId: tariff.id,
                baggageStatus: baggageStatus(isBaggage),
                description: {
                    isBusiness,
                    isBaggage,
                    flightNumber: apiLegInfo.flightNumber,
                    departure: apiLegInfo.departureDate,
                    arrival: apiLegInfo.arrivalDate,
                    baggagePlace: tariffInfo.baggage.places,
                    baggageWeight: tariffInfo.baggage.weight,
                    handLuggagePlace: tariffInfo.handLuggage.places,
                    handLuggageWeight: tariffInfo.handLuggage.weight,
                    isRefund: tariffInfo.cancellationPolicy.refundable,
                    isChange: tariffInfo.cancellationPolicy.changeable,
                },
            },
        };
    });
});