const forbiddenCashBackCountries = {
    turkey: 92,
};

const forbiddenCountriesIds = _.values(forbiddenCashBackCountries);
const forbiddenCountries = _.pairs(forbiddenCashBackCountries);

const isSearchCountry = (tourCriteria) => {
    if (tourCriteria?.countries?.length !== 1) {
        return false;
    }

    return !!~forbiddenCountriesIds.indexOf(tourCriteria?.countries[0]);
};

const isHotelTourCountry = (hotelInfo) => {
    if (!hotelInfo?.country) {
        return false;
    }

    return !!~forbiddenCountriesIds.indexOf(hotelInfo?.country);
};

export default (appConfig, location) => {
    const isForbiddenCountryLocation = !!_.find(forbiddenCountries, ([countryName, countryId]) => {
        const isMatchByCountryName = location.pathname.match(countryName) !== null;
        const isMatchByCountryId = location.hash.match(`toCountry=${countryId}`) !== null;
        return isMatchByCountryName || isMatchByCountryId;
    });

    const isForbiddenCountryByTourCriteria = isSearchCountry(appConfig?.defaultTourCriteria);
    const isForbiddenCountryByHotel = isHotelTourCountry(appConfig?.hotelInfo);

    return isForbiddenCountryLocation
        || isForbiddenCountryByTourCriteria
        || isForbiddenCountryByHotel;
};