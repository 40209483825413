/* eslint-disable */
var ProgressBarModel = Backbone.Epoxy.Model.extend({
	defaults: {
		value: 0,
		stepNumber: 0,
		stepTimeout: 0,
		targetValue: 0,
		animation: false,
		animator: null,
		framesPerSecond: 15
	},

	stopAnimation: function () {
		var targetValue = this.get('targetValue');
		clearInterval(this.get('animator'));
		this.set('animation', false);
		if (targetValue == 100) {
			clearInterval(this.get('stepTimeout'));
		}
	},

	startAnimation: function () {
		var targetValue = this.get('targetValue'),
			self = this;
		this.set('animation', true);

		if (targetValue == 0) {
			this.set('stepNumber', 0);
			clearInterval(this.get('stepTimeout'));
			this.set('stepTimeout', setInterval(function () {
				self.set('stepNumber', (self.get('stepNumber') + 1) % 3);
			}, 10000));
		}
	},

	computeds: {
		progressValue: {
			deps: ['value'],
			get: function (value) {
				value = Math.round(value * 10) / 10;
				return value;
			}
		},
	},

	targetValueChangedHandler: function (model, targetValue) {
		var self = this,
			value = this.get('value'),
			animation = this.get('animation'),
			animator = this.get('animator'),
			framesPerSecond = this.get('framesPerSecond'),
			animationUpdateTime = Math.round(1 / framesPerSecond * 1000);

		if (animation && value !== targetValue) {
			var moveToDistance = Math.abs(targetValue - value);
			if (animator) {
				clearInterval(animator);
			}
			animator = setInterval(function () {
				var value = self.get('value');
				var newValue = value + (Math.atan(Math.abs(targetValue - value) * 2) / Math.PI * 2) * (targetValue - value) / moveToDistance;
				self.set('value', newValue);
			}, animationUpdateTime);
			this.set('animator', animator);
		}
	},

	initialize: function () {
		this.on('change:targetValue', this.targetValueChangedHandler);
	}

});

var ProgressBarView = Backbone.Epoxy.View.extend({

	bindings: {
		':el': 'classes:{complete:progressComplete, animated:animation}',
		'.progressValue': 'css:{width:percentsValue}',
		'.percetage': 'text:roundedPercentValue',
		'.progressBarTitle': 'classes:{step1:step1, step2:step2, step3:step3}',
		'.progressStatusContainer .progressStatusContainer__tour-found': 'text: loaderTourFoundText',
		'.progressStatusContainer .progressStatusContainer__additional-tour-found': 'html: loaderAdditionalTourFoundText',
	},

	computeds: {
		step1: {
			deps: ['stepNumber'],
			get: function (stepNumber) {
				return stepNumber == 0;
			}
		},
		step2: {
			deps: ['stepNumber'],
			get: function (stepNumber) {
				return stepNumber == 1;
			}
		},
		step3: {
			deps: ['stepNumber'],
			get: function (stepNumber) {
				return stepNumber == 2;
			}
		},
		progressComplete: {
			deps: ['value', 'animation'],
			get: function (value, animation) {
				return value === 100 && !animation;
			}
		},
		roundedPercentValue: {
			deps: ['progressValue'],
			get: function (progressValue) {
				return Math.floor(progressValue) + '%';
			}
		},
		percentsValue: {
			deps: ['progressValue'],
			get: function (progressValue) {
				return progressValue + '%';
			}
		},
		loaderTourFoundText: {
			deps: ['numOfToursFiltered', 'numOfHotelsFiltered'],
            get: function (numOfToursFiltered, numOfHotelsFiltered) {
				if (!numOfToursFiltered || !numOfHotelsFiltered) return

                return `Найден${numOfToursFiltered.wordEnding(';о;о;')} ${numOfToursFiltered} тур${numOfToursFiltered.wordEnding(';а;ов;')} в ${numOfHotelsFiltered} отел${numOfHotelsFiltered.wordEnding('е;ях;ях')}`;
            }
		},
		loaderAdditionalTourFoundText: {
			deps: ['additionalHotelsFound'],
            get: function (additionalHotelsFound) {
				if (!additionalHotelsFound) return
				
				const link = `<a
				class="btn btnOrange btnFlat topButton" href="#"
				data-bind="event:['click', 'showAll']">Обновить<span class="hideInOneColumn">
					результаты</span></a>`;
                return `Мы нашли туры еще в <strong>${additionalHotelsFound}</strong> отел${additionalHotelsFound.wordEnding('ь;я;ей')}${link}`;
            }
		},
	},
	initialize: function (options) {
		if (!options.parent) return;

		this.model.mapState('numOfToursFiltered', options.parent);
		this.model.mapState('numOfHotelsFiltered', options.parent);
		this.model.mapState('additionalHotelsFound', options.parent);
	}
});

let ProgressBar = function () {
	this.Views = [];

	this.initialize = function () {
		this.model = new ProgressBarModel();
		var self = this;
		_.each(arguments[0], function (element) {
			self.addView(element);
		});
	};

	this.addView = function (element) {
		var view = new ProgressBarView({
			el: element?.el || element,
			model: this.model,
			parent: element?.parent || null
		});
		this.Views.push(view);
	};
	this.reset = function () {
		this.setValue(0);
	};
	this.complete = function () {
		this.setValue(100);
	};
	this.setValue = function (value) {
		this.model.stopAnimation();
		this.model.set('value', value);
		this.model.set('targetValue', value);
	};
	this.moveToValue = function (value) {
		this.model.startAnimation();
		this.model.set('targetValue', value);
	};
	this.initialize(arguments);
};

export default ProgressBar;