
    import { defineComponent, computed, unref } from 'vue';
    import { useState, useGetters } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import useVueProxy from '@ve/services/useVueProxy';
    import { NAMESPACE as productSearchStoreName } from '@ve/pages/serp/store/productsSearchStore';
    import { IProductsSearchStoreGetters } from '@ve/pages/serp/store/types/IProductsSearchStoreState';
    import { nameFilterSerpStore } from '../searchFilters/store/getFiltersSerpStore';
    import ButtonCommon from '../button/desktop/Button.vue';

    export default defineComponent({
        components: { ButtonCommon },
        name: 'EmptyResultByFilters',
        setup() {
            const { $root } = useVueProxy();
            const { filteredHotels, syncHotels } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['filteredHotels', 'syncHotels']);
            const { isSyncLoadingFinished } = useGetters<IProductsSearchStoreGetters>(productSearchStoreName, ['isSyncLoadingFinished']);

            const isUserFilteredHotelsToEmpty = computed(() => unref(syncHotels).length && !unref(filteredHotels).length);
            const resetFiltersHandler = () => {
                $root?.$emit('reset-filters-to-default');
            };
            return {
                isUserFilteredHotelsToEmpty,
                isSyncLoadingFinished,
                resetFiltersHandler,
            };
        },
    });
