
    import { Vue, Component } from 'vue-property-decorator';
    import { mapState } from 'vuex';
    import DroppedOrderBanner from '@ve/components/droppedOrderBanner/desktop/DroppedOrderBanner.vue';
    import { DroppedOrderBannerData, HotelInfo } from '../common/types';

    @Component({
        components: { DroppedOrderBanner },
        computed: {
            ...mapState('mapForDroppedOrderBannerWrapper', ['showDroppedOrderBanner', 'dataDroppedOrderBanner']),
        },
    })
    export default class DroppedOrderBannerWrapper extends Vue {
        private showDroppedOrderBanner!: boolean;

        private dataDroppedOrderBanner!: DroppedOrderBannerData | null;

        get hotelInfo():HotelInfo {
            return {
                hotelName: this.dataDroppedOrderBanner?.hotelName || '',
                hotelRate: this.dataDroppedOrderBanner?.hotelRate || '',
                hotelType: this.dataDroppedOrderBanner?.hotelType || '',
            };
        }

        get timerEnd():string {
            return this.dataDroppedOrderBanner?.timerEnd || '';
        }

        get link():string {
            return this.dataDroppedOrderBanner?.link || '';
        }
    }
