/* eslint-disable */
const WATER_ACTIVITIES_REPOSITORY = [
    {
        id: 60,
        title: "Открытый бассейн",
        description: "",
        name: "openPool",
    },
    {
        id: 59,
        title: "Крытый бассейн",
        description: "",
        name: "closePool",
    },
    {
        id: 174,
        title: "Бассейн с подогревом",
        description: "",
        name: "heatedPool",
    },
    {
        id: 173,
        title: "Бассейн с морской водой",
        description: "",
        name: "seaPool",
    },
    {
        id: 175,
        title: "Водные горки",
        description: "это водный аттракцион представляющий собой группу горок с водой, спускающихся в один бассейн.",
        name: "waterslides",
    },
    {
        id: 61,
        title: "Аквапарк",
        description: "это развлекательный комплекс, в котором имеется инфраструктура для занятия играми на воде и водные аттракционы с выделенным местом входа.",
        name: "aquapark",
    },
];

export default WATER_ACTIVITIES_REPOSITORY;