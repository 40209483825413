/* eslint-disable */
let FixMapPositionView = Backbone.View.extend({
	initMapPosition: function () {
		var self = this,
			$searchContainer = $('.searchContainer'),
			$mainColumnRight = $('.mainColumnRight'),
			$map = $('.mapLayer', $mainColumnRight),
			topBarHeight = 190;

		$(window).on("scroll resize", function () {
			var scrollPosition = $(this).scrollTop(),
				mapHeight = $map.outerHeight(true),
				mainColumnRightOffsetTop = $mainColumnRight.offset().top,
				searchContainerHeight = $searchContainer.outerHeight(),
				searchContainerOffsetTop = $searchContainer.offset().top;

			if(self.searchPageModel.get('viewMode') == "map") {
				if(scrollPosition + topBarHeight > mainColumnRightOffsetTop && $(window).width() >= 1240) {
					$mainColumnRight.addClass('mapLayer_fixed');
				} else {
					$mainColumnRight.removeClass('mapLayer_fixed');
				}

				if(scrollPosition + topBarHeight + mapHeight > searchContainerHeight + searchContainerOffsetTop) {
					$mainColumnRight.addClass('mapLayer_fixedBottom');
				} else {
					$mainColumnRight.removeClass('mapLayer_fixedBottom');
				}
			}
		});

		$(".showOnMap .serpFilterName").on("click", function () {
			setTimeout(function () {
				$(window).trigger("scroll");
			});
		});
	},
	initialize: function (options) {
		this.searchPageModel = options.searchPageModel;
		this.initMapPosition();
	}
});

export default FixMapPositionView;