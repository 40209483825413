import moment from 'moment';
import 'moment/locale/ru';

export default (tourCriteria) => {
    const params = {};
    const element = tourCriteria.get('checkInDateRange');
    if (element) {
        const from = element.get('from');
        const to = element.get('to');
        if (from) {
            params.dateFrom = moment(from).format('DD.MM.YYYY');
        }
        if (to) {
            params.dateTo = moment(to).format('DD.MM.YYYY');
        }
    }

    return params;
};