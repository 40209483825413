import { useState, useMutations } from 'vuex-composition-helpers';
import { unref, computed } from 'vue';
import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';
import filterHotelsModel, {
    IFilterHotersMutations,
    IFilterHotelsState,
    NAMESPACE,
} from '../model/filterHotelsModel';

export default function () {
    registerModule.register(getStore(), filterHotelsModel, NAMESPACE);
    const {
        childServiceFilterIds,
        restTypeFilterId,
        beachTypeFilterIds,
    } = useState<IFilterHotelsState>(NAMESPACE, ['childServiceFilterIds', 'restTypeFilterId', 'beachTypeFilterIds']);
    const {
        setChildServiceFilter,
        setRestTypeFilter,
        setBeachTypeFilter,
    } = useMutations<IFilterHotersMutations>(NAMESPACE, ['setChildServiceFilter', 'setRestTypeFilter', 'setBeachTypeFilter']);

    const childServiceFilter = computed({
        get: () => unref(childServiceFilterIds),
        set: (value: IFilterHotelsState['childServiceFilterIds']) => {
            setChildServiceFilter(value);
        },
    });

    const restTypeFilter = computed({
        get: () => unref(restTypeFilterId),
        set: (value: IFilterHotelsState['restTypeFilterId'] | 0) => {
            setRestTypeFilter(value);
        },
    });

    const beachTypeFilter = computed({
        get: () => unref(beachTypeFilterIds),
        set: (value: IFilterHotelsState['beachTypeFilterIds']) => {
            setBeachTypeFilter(value);
        },
    });

    return {
        childServiceFilter,
        setChildServiceFilter,
        beachTypeFilter,
        restTypeFilter,
        setRestTypeFilter,
        setBeachTypeFilter,
    };
}