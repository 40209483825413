/* eslint-disable */
var Topmenu = function(options) {
	this.updateItemsAmount = function() {
		var items = [];

		this.$primaryMenuItems.detach();
		this.$secondaryItemsContaner.show();

		this.$primaryItemsContaner.prepend(this.$primaryMenuItems);

		if(this.$secondaryItemsContaner.length && this.$secondaryItemsContaner.position().top != 0) {
			while(this.$secondaryItemsContaner.position().top != 0 && this.$primaryMenuItems.filter(':visible').length) {
				items.unshift(this.$primaryMenuItems.filter(':visible').last().detach());
			}
		}

		this.$secondaryItemsContanerItem.prepend(items);

		if(items.length || this.$secondaryMenuItems.length) {
			this.$secondaryItemsContaner.show();
		} else {
			this.$secondaryItemsContaner.hide();
		}
	};

	this.initEvents = function() {
		$(window).on('resize', $.proxy(this.updateItemsAmount, this));
		$(document).on('topMenuBarChanged', $.proxy(this.updateItemsAmount, this));
	};

	this.moveSecondary = function() {
		this.$secondaryMenuItems.detach();
		this.$secondaryItemsContanerItem.append(this.$secondaryMenuItems);
	};

	this.init = function() {
		this.$el = $(options.el);
		this.$el.show();
		this.$primaryMenuItems = $('.menu-item', this.$el).filter(':not(.secondaryItemsContainer, .secondary)');
		this.$secondaryMenuItems = $('.menu-item.secondary', this.$el).filter(':not(.secondaryItemsContainer)');
		this.$primaryItemsContaner = $('.primaryItemsContainer', this.$el);
		this.$secondaryItemsContaner = $('.secondaryItemsContainer', this.$el);
		this.$secondaryItemsContanerItem =  $('.gr-item', this.$secondaryItemsContaner);

		this.moveSecondary();
		this.initEvents();
		this.updateItemsAmount();
	};

	this.init();
};

$(function() {
	var topmenu = new Topmenu({
		el: '.menuContainer .sectionsMenu'
	})
});