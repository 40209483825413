/* eslint-disable */
import 'jquery.cookie';
import '@/js/desktop/plugins/select2';

import wildcardDomain from "@/js/common/Domain/Service/wildcardDomain";

let regionPopup = {
	changeRegion: function (regionId, departureCityId, regionDomain) {
		var self = this;
		self.hideRegionDialog ? self.hideRegionDialog() : '';

		$.ajax({
			url: '/region/set',
			data: {
				id: regionId
			},
			dataType: "json",
			type: "POST"
		})
			.done(function (response) {
				var currentHashParams,
					locationHash;
				if (response.success == true) {
					locationHash = document.location.hash;
					if (locationHash !== "" && locationHash.indexOf('fromCity') !== -1 && departureCityId) {
						currentHashParams = $.unparam(document.location.hash.slice(2));
						currentHashParams.fromCity = departureCityId;
					}
					if (regionDomain) {
						var newHash = currentHashParams ? ("#?" + $.param(currentHashParams)) : '',
							newLocation = '\/\/' + regionDomain + document.location.pathname;
						document.location.href = newLocation + newHash;
						if (regionDomain == location.hostname) {
							document.location.reload();
						}
					} else {
						document.location.reload();
					}

				} else {
					console.log("Error! Something wrong");
					console.log(response.message);
				}
			});
	},
	hideRegionDialog: function () {
		$("#regionPopup.ui-dialog-content").dialog("close");
	},
	initPanelBehavior: function () {
		var $window = $(window),
			self = this,
			$regionPanel = $("#regionPanel"),
			$regionBtn = $(".topMenuBar .region > span"),
			$menuContainer = $('.menuContainer'),
			$scrollToTopArrow = $('.scrollToTop'),
			$searchFormContainerWapper = $('.searchFormFliedContainer'),
			$topMenuBar = $(".topMenuBar"),
				$faqWidget = $(".faq-lotery-container")
		;

		$('#siteMenu .region').on('click', function () {
			var maxPanelHeight,
				$header = $(".header"),
				panelOpened = $header.hasClass("panelOpen"),
				_panelHeight;

			if (panelOpened) {
				$header.removeClass("panelOpen");
				_panelHeight = 0;
				$regionBtn.removeClass("open");
				$(".select2-drop-active").select2("close");
			} else {
				maxPanelHeight = $window.height() - 50;
				_panelHeight = self.panelHeight();
				_panelHeight = _panelHeight > maxPanelHeight ? maxPanelHeight : _panelHeight;

				$header.addClass("panelOpen");
				$(".select2-drop-active").select2("close");
				$regionBtn.addClass("open");
			}
			$regionPanel.height(_panelHeight);
			$menuContainer.css('top', _panelHeight);
		});

		$("#regionPanelClose").on('click', function () {
			$(".header").removeClass("panelOpen");
			$regionPanel.height(0);
			$menuContainer.css('top', 0);
			$regionBtn.removeClass("open");
			$(".select2-drop-active").select2("close");
		});

		if ($searchFormContainerWapper.length) {
			$window.scroll(function (event) {
				var st = $(this).scrollTop(),
					fixPosition,
					menuHeight = $menuContainer.hasClass('stuckUp') ? $topMenuBar.height() : 0,
					$searchFormContainerWapperPosition = $searchFormContainerWapper.offset().top;
				fixPosition = $searchFormContainerWapperPosition - menuHeight - 15;

				if (!$faqWidget.length) $faqWidget = $(".faq-lotery-container");

				if (st > fixPosition) {
					$searchFormContainerWapper.addClass("fixedTop");
					if ($(".scrollToTop").length) $faqWidget.addClass("faqWidget-left");
					$scrollToTopArrow.addClass('visible');
				} else {
					$searchFormContainerWapper.removeClass("fixedTop").removeClass('minimized');
					$scrollToTopArrow.removeClass('visible');
					$faqWidget.removeClass("faqWidget-left");
				}
			});
		}
	},
	panelHeight: function () {
		var siteWidth = $("body").width(), panelHeight;
		if (siteWidth > 1345) {
			panelHeight = 595;
		} else if (siteWidth < 1000) {
			panelHeight = 825;
		} else {
			panelHeight = 627;
		}
		return panelHeight;
	},
	togglePanelVisibilityInit: function () {
		var $searchFormContainerWapper = $('.searchFormFliedContainer');

		$('.showToggle', $searchFormContainerWapper).on('click', function () {
			$searchFormContainerWapper.toggleClass('minimized');
		});
	},
	initRegionPanel: function () {
		var $regionPanel = $("#regionPanel");

		$("body").prepend($regionPanel);

		this.initPanelBehavior();
		this.togglePanelVisibilityInit();
	},
	initAutoselectRegionPopup: function () {
		var $window = $(window),
			$regionMsg = $("#regionMsg"),
			$regionBtn = $(".topMenuBar .region > span"),
			$regionPanel = $("#regionPanel"),
			$menuContainer = $('.menuContainer'),
			self = this;

		$.ajax({
			method: "POST",
			data: "",
			url: "/region/askChangeApplication"
		}).done(function (response) {
			if (response.result && !$.cookie("regionMsgClose")) {
				$regionMsg.show();
				$("#newRegionName").text(response.result.name);
				$("#newRegionBtn").attr({
					"href": response.result.url,
					"data-id": response.result.id,
					"data-departureid": response.result.departureCityId
				});
			}
		});

		$(".regionPanelShow").on("click", function () {
			var maxPanelHeight = $window.height() - 176,
				_panelHeight = self.panelHeight();

			_panelHeight = _panelHeight > maxPanelHeight ? maxPanelHeight : _panelHeight;

			$(".header").addClass("panelOpen");
			$(".select2-drop-active").select2("close");
			$regionMsg.hide();
			$regionBtn.addClass("open");
			$regionPanel.height(_panelHeight);
			$menuContainer.css('top', _panelHeight);
		});

		$(".regionMsg_close").on("click", function () {
			$.cookie('regionMsgClose', 'true', {path: '/', expires: 999, domain: wildcardDomain()});
			$regionMsg.hide();
		});

		$('#newRegionBtn').on('click', function (e) {
			var regionId = $(this).data('id'),
				regionDomain = $(this)[0].hostname,
				departureId = $(this).data('departureid');
			self.changeRegion(regionId, departureId, regionDomain);
			return false;
		});
	},
	initRegionSearch: function () {
		var self = this;

		function firstLetter(ind) {
			var $regions = $(".regionsPanelList li:not(.hidden)"),
				letterFix = "а", letterFirst,
				flag = false;

			if ($(".letterFirst").length !== 0) {
				$regions.find(".letterFirst").remove();
			}
			$regions.each(function (i, elm) {

				letterFirst = $(elm).find("span").text().slice(0, 1).toLowerCase();

				if (letterFirst != letterFix) {
					letterFix = letterFirst;
					flag = false;
				}

				if (letterFix == letterFirst && !flag) {
					if ($(elm).find(".letterFirst").length == 0) {
						$(elm).prepend("<span class='letterFirst'>" + letterFix + "</span>");
					}
					flag = true;
				}

			});
		}

		firstLetter();

		$("#regionFilter").on("keyup", function () {
			var search = $(this).val().toLowerCase(),
				$regions = $(".regionsPanelList li"),
				region, i;

			if (search != "") {
				$regions.addClass("hidden").hide();

				$regions.each(function (i, elm) {
					var $elm = $(elm),
						departureCityName = $elm.find("span").text().toLowerCase();
					if (departureCityName.indexOf(search) !== -1) {
						$elm.removeClass("hidden").show();
					}
				});
			} else {
				$regions.removeClass("hidden").show();
			}
			firstLetter();
			if ($(".regionsPanelList li:not(.hidden)").length == 0) {
				$(".regionNotFound").show();
			} else {
				$(".regionNotFound").hide();
			}
		});

		$("#regionForm").submit(function () {
			var thisLi = $(".regionsPanelList li:not(.hidden)");
			if (thisLi.length == 1) {
				var regionId = thisLi.data('id'),
					regionDomain = thisLi.find('a')[0].hostname,
					departureId = thisLi.data('departureid');
				self.changeRegion(regionId, departureId, regionDomain);
			}
			return false;
		});
	},
	initRegionsListBehavior: function () {
		var self = this;

		$('.regionsPanelList li').on('click', function (e) {
			var regionId = $(this).data('id'),
				regionDomain = $(this).find('a')[0].hostname,
				departureId = $(this).data('departureid');
			self.changeRegion(regionId, departureId, regionDomain);
			return false;
		});
	},
	init: function () {
		this.initAutoselectRegionPopup();
		this.initRegionSearch();
		this.initRegionsListBehavior();
		this.initRegionPanel();
	}
};

$(function () {
	regionPopup.init()
});