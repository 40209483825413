import { TrackJS } from 'trackjs';

import uuid from '@/js/common/Domain/Service/uuid';
import marketingClient from '@/js/common/Domain/Service/marketingClient';
import trackingStartSearchData from './trackingStartSearchData';
import trackingSearchFilters from './trackingSearchFilters';
import trackingStartEmptySearchData from './trackingStartEmptySearchData';
import trackIntelligentSearchDataHydrator from './trackIntelligentSearchData';
import trackingCalendarDataHydrator from './trackingCalendarData';
import trackingVisitHydrator from './trackingVisit';
import trackingTourPageDataHydrator from './trackingTourPageData';
import trackingHotelSearchDataHydrator from './trackingHotelSearchData';
import trackingSearchDataHydrator from './trackingSearchData';
import trackHotelPageRoomCategories from './trackHotelPageRoomCategories';
import trackingSerpHotelPosition from './trackingSerpHotelPosition';
import trackingHotelOffersSearchData from './trackingHotelOffersSearchData';
import trackingRailwayToursSearchData from './trackingRailwayToursSearchData';
import trackingCoreWebVitalsHydrator from './trackingCoreWebVitalsHydrator';

import trackHotelView from './trackHotelView';

let searchUuid = null;
let filteredSearchUuid = null;
let originalSearchUuid = null;
let deferredFiltersChange = false;

export default {
    setCustomerUuid(customerUuid) {
        marketingClient.setCustomerUuid(customerUuid);
    },
    getSearchUuid() {
        return searchUuid;
    },
    getClientUuid() {
        return marketingClient?.getClientUuid();
    },
    getCustomerUuid() {
        return marketingClient?.getCustomerUuid();
    },
    get originalSearchUuid() {
        return originalSearchUuid || searchUuid;
    },
    async trackHotelOffersSearch(options) {
        const data = await trackingHotelOffersSearchData(options, searchUuid);
        this.pushTrTrack('trackSearch', data);
    },
    async trackRailwayToursSearch(options) {
        const data = await trackingRailwayToursSearchData(options, searchUuid);
        this.pushTrTrack('trackSearch', data);
    },
    async trackSearch(options) {
        const data = await trackingSearchDataHydrator(options, searchUuid);
        this.pushTrTrack('trackSearch', data);
    },
    async hotelSearchSessions(options) {
        const data = await trackingHotelSearchDataHydrator(options);
        this.pushTrTrack('trackHotelPage', data);
    },
    async tourPageSession(options) {
        const data = await trackingTourPageDataHydrator(options);
        this.pushTrTrack('trackTourPage', data);
    },
    async trackCalendar(options) {
        const data = await trackingCalendarDataHydrator(options);
        this.pushTrTrack('trackCalendar', data);
    },
    async trackStartSearch(options) {
        originalSearchUuid = null;
        this.generateSearchUuid();
        const data = await trackingStartSearchData({
            searchOptions: options,
            searchUuid,
        });

        this.pushTrTrack('trackSearchStart', data);

        if (deferredFiltersChange) {
            this.trackSearchFilters(deferredFiltersChange);
            deferredFiltersChange = null;
        }
    },
    async trackStartEmptySearch(options) {
        if (!originalSearchUuid) {
            originalSearchUuid = searchUuid;
        }
        this.generateSearchUuid();

        const data = await trackingStartEmptySearchData({
            searchOptions: options,
            searchUuid,
            originalSearchUuid,
        });
        this.pushTrTrack('trackEmptySearchStart', data);
    },
    async trackSearchFilters(options) {
        let data;

        this.generateFilteredSearchUuid();

        if (!searchUuid) {
            deferredFiltersChange = options;
            this.generateSearchUuid();
        } else {
            data = await trackingSearchFilters(options, searchUuid, filteredSearchUuid);
            this.pushTrTrack('trackSearchFilters', data);
        }
    },
    trackTourHunters(tourCriteria) {
        const hotelId = tourCriteria.get('hotels')?.at(0)?.get('id');

        if (!hotelId) {
            TrackJS.track('tourHunter track error: hotelId is undefined');
            return;
        }

        const data = {
            filteredSearchUuid,
            searchUuid,
            hotelId,
        };

        this.pushTrTrack('trackTourHunters', data);
    },
    trackSerpHotelPosition(options) {
        const data = trackingSerpHotelPosition(
            options,
            searchUuid,
            filteredSearchUuid,
        );

        this.pushTrTrack('trackSerpHotelPosition', data);
    },
    async trackHotelPageRoomCategories(tourDescriptionsData) {
        const pageRoomCategoriesData = await trackHotelPageRoomCategories(tourDescriptionsData, searchUuid);
        if (pageRoomCategoriesData) {
            this.pushTrTrack('trackHotelPageRoomCategories', pageRoomCategoriesData);
        }
    },
    async visitSession(options) {
        const data = await trackingVisitHydrator(options);
        this.pushTrTrack('trackVisit', data);
    },

    async trackIntelligentSearch(options) {
        const data = await trackIntelligentSearchDataHydrator(options);
        this.pushTrTrack('trackIntelligentSearch', data);
    },

    trackHotelView({ hotelId }) {
        const data = trackHotelView({
            hotelId,
        });
        this.pushTrTrack('addHotelView', data);
    },
    generateSearchUuid() {
        searchUuid = uuid();
    },
    generateFilteredSearchUuid() {
        filteredSearchUuid = uuid();
    },
    coreWebVitals(options) {
        this.pushTrTrack('trackCoreWebVitals', trackingCoreWebVitalsHydrator(options));
    },
    pushTrTrack(type, data) {
        if (marketingClient) {
            marketingClient.track({ type, data });
        }
    },
};