/* eslint-disable */
import template from './template.html';

let ExpiredPopupView = Backbone.View.extend({
	expireTimeoutValue: 1800000, //TODO
	expireTimeout:null,
	searchDataChanged:function() {
		clearTimeout(this.expireTimeout);
		this.expireTimeout = setTimeout($.proxy(this.showExpirePopup, this), this.expireTimeoutValue)
	},
	showExpirePopup:function() {
		var self = this;
		if(!$('.ui-widget-overlay:visible').length){
			$('#expired.ui-dialog-content').dialog('open');
		} else {
			clearTimeout(self.expireTimer);
			this.expireTimer = setTimeout($.proxy(self.showExpirePopup, self), self.expireTimeoutValue);
		}
	},
	initPopup: function() {
		var self = this,
			$expired = this.$expired;

		$expired.correctPopupPosition().dialog({
			autoOpen: false,
			dialogClass: 'expiredPopup',
			modal: true,
			draggable: false,
			resizable: false,
			open: function(){
				$expired.on('click', '.popupClose', function(){
					self.searchDataChanged();
					$('#expired.ui-dialog-content').dialog( "close" );
				});
				$expired.on('click', '#btnToMainPage', function(){
					window.location.href = '/';
				});
				$expired.on('click', '#btnUpdateSearch', function(){
					$expired.dialog( "close" );
					self.updateResultCallback();
				});
			}
		});
	},
	initModelEvents:function() {
		this.searchPageViewModel.on('change:searchData', $.proxy(this.searchDataChanged, this))
	},
	render: function() {
		this.$el.append(template());
	},
	initialize:function(options) {
		this.searchPageViewModel = options.searchPageViewModel;
		this.updateResultCallback = options.updateResultCallback;
		this.render();
		this.$expired = $('#expired', this.$el);
		this.initModelEvents();
		this.initPopup();
	}
});

export default ExpiredPopupView;