/* eslint-disable */
import { propsDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import moment from 'moment';
import 'moment/locale/ru';

@propsDecorator({
    defaults: {
        searchData: null,
        selectedDates: null,
        calendarData: [],
        offset: 0,
        emptyDates: [],
        addCollToSlide: 8,
        mobileViewDateCheck: false,
        mobileTourInfoPrice: false,
    },
    numOfCalendarDays: 31,
    computeds: {
        calendarMinPrice() {
            const currentPrices = this.get('calendarData');
            if (currentPrices && currentPrices.length) {
                return _.min(_.pluck(currentPrices, 'price'), price => (_.isNull(price) || !price ? Infinity : price));
            }
            return false;
        },
        calendarMaxPrice() {
            const currentPrices = this.get('calendarData');
            if (currentPrices && currentPrices.length) {
                return _.max(_.pluck(currentPrices, 'price'));
            }
            return false;
        },
        nightsLabel() {
            const searchData = this.get('searchData');
            const nights = searchData ? searchData.get('nightRange') : null;
            const nightsFrom = nights ? nights.get('from') : null;
            const nightsTo = nights ? nights.get('to') : null;
            let label = '';

            if (searchData && nights && nightsFrom && nightsTo) {
                if (nightsFrom === nightsTo) {
                    label = `${nightsTo} ноч${nightsTo.wordEnding('ь;и;ей')}`;
                } else {
                    label = `${nightsFrom}-${nightsTo} ноч${nightsTo.wordEnding('ь;и;ей')}`;
                }
            }

            return label;
        },
        touristLabel() {
            if (!this.get('searchData')) return;

            const searchData = this.get('searchData');
            const touristGroup = searchData.get('touristGroup').toJSON();
            let text = 'при размещении ';
            const kids = touristGroup.infants + touristGroup.kids;

            text += `${touristGroup.adults} взросл${touristGroup.adults.wordEnding('ого;ых;ых')}`;
            if (kids) {
                text += ` и ${kids} ${kids.wordEnding('ребенка;детей;детей')}`;
            }

            return text;
        },
        expandedDatesObject() {
            const searchData = this.get('searchData');

            if (searchData) {
                const { numOfCalendarDays } = this;
                const offset = this.get('offset');
                const checkInDateRange = searchData.get('checkInDateRange');
                let dateFrom = moment(checkInDateRange.get('from'));
                const currentDate = moment().add(1, 'days').startOf('day');
                let dateTo = moment(checkInDateRange.get('to'));
                const needDates = numOfCalendarDays - 1;
                const datesDiff = dateTo.diff(dateFrom, 'days');
                const needDatesDiff = needDates - datesDiff;
                const dateFromDiff = Math.floor(needDatesDiff / 2);
                const dateToDiff = dateFromDiff + (needDatesDiff % 2);

                dateFrom.subtract(dateFromDiff, 'days');
                dateTo.add(dateToDiff, 'days');

                if (dateFrom.diff(currentDate, 'days') < 0) {
                    dateFrom = currentDate.clone();
                    dateTo = currentDate.clone().add(needDates, 'days');
                }

                dateFrom = dateFrom.add(offset * numOfCalendarDays, 'days');
                dateTo = dateTo.add(offset * numOfCalendarDays, 'days');

                return {
                    from: dateFrom,
                    to: dateTo,
                };
            }

            return null;
        },
        expandedDates() {
            const expandedDatesObject = this.get('expandedDatesObject');

            if (expandedDatesObject) {
                return {
                    from: expandedDatesObject.from.toDate(),
                    to: expandedDatesObject.to.toDate(),
                };
            }

            return null;
        },
        prevDatesAvailable() {
            const currentDate = moment().add(1, 'days').startOf('day');
            const expandedDatesObject = this.get('expandedDatesObject');
            return expandedDatesObject && expandedDatesObject.from.diff(currentDate, 'days') > 0;
        },
    },
})
export default class PriceCalendarModel extends Backbone.Epoxy.Model {}
