/* eslint-disable */
let generateIdService = function(options) {
	var length = options.length,
		vocabulary = ['1','2','3','4','5','6','7','8','9','0','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
		result = '',
		vocabularyLength = vocabulary.length;

		_.times(length, function() {
			result += vocabulary[Math.floor(Math.random()*vocabularyLength)];
		});
		return result;
};

export default generateIdService;