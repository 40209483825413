/* eslint-disable */
import TouristGroupObjectValue from '../ObjectValue/TouristGroup';

const TouristGroupFactory = function (data) {
	let touristGroup = {};

	if (!((data.adults && _.isNaN(+data.adults)) || (data.kids && _.isNaN(+data.kids)) || (data.infants && _.isNaN(+data.infants)))) {
		touristGroup.adults = +data.adults;
		if (touristGroup.adults < 1) {
			touristGroup.adults = 1;
		}

		if (touristGroup.adults > 6) {
			touristGroup.adults = 6;
		}

		touristGroup.kids = +data.kids || 0;

		touristGroup.infants = +data.infants || 0;

		if (touristGroup.kids < 0) {
			touristGroup.kids = 0;
		}

		if (touristGroup.infants < 0) {
			touristGroup.infants = 0;
		}


		if (touristGroup.kids > 3) {
			touristGroup.kids = 3;
		}

		if (touristGroup.infants > 3) {
			touristGroup.infants = 3 - touristGroup.kids;
		}
		if (_.isArray(data.ages)) {
			touristGroup.ages = data.ages
		}

		return new TouristGroupObjectValue(touristGroup);
	}
	return null;
};

export default TouristGroupFactory;