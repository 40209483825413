import MindBoxService from './MindBoxService';
import preset from './presetMindBox';

import { ItrackViewHotel } from './MindBoxTypes';

class MindBoxServiceDesktop extends MindBoxService {
    public trackViewHotelAdapter(hotelPageView: any): ItrackViewHotel | void {
        const tourCriteria = hotelPageView.currentSearchModel;
        const tourOffer = hotelPageView.model.get('minPriceTourOffer');
        const hotelOffer = hotelPageView.model.get('minPriceHotelOffer');
        const railwayOffer = hotelPageView.model.get('minPriceRailway');
        const filterCriteria = hotelPageView.model.get('filterCriteria');

        return {
            pricerailwayFrom: this.hydratorService.getProductPrice(railwayOffer),
            pricehotelFrom: this.hydratorService.getProductPrice(hotelOffer),
            priceFrom: this.hydratorService.getProductPrice(tourOffer),
            filterCriteria,
            tourCriteria,
        };
    }
}

/**
 * singleton implementation by ES6,
 * whenever you import the above ES6 module, you’re guaranteed to get the same version of the mindBoxService
 */
const mindBoxService = new MindBoxServiceDesktop(preset);

export default mindBoxService;