import moment from 'moment';
import 'moment/locale/ru';
import { TrackJS } from 'trackjs';

import CheckInDateRangeFactory from '@/js/common/Domain/Factory/checkInDateRange/CheckInDateRange';
import gaAsync from '@/js/common/Domain/Service/gaAsync';
import tourCriteriaService from '@/js/common/Domain/Service/tourCriteria';
import { CheckInDateRangeStore, NAMESPACE as CHECK_IN_DATE_RANGE_STORE } from '@entities/searchCriteria/checkInDate';
import getStore from '@ve/services/getStore/getStore';

const CheckInDateRangeView = Backbone.View.extend({
    label: 'Дата вылета',
    $dateInput() {
        this.$dateInput = $('.date', this.$el);
    },
    scrollToElement() {
        const currenPosition = $(document).scrollTop();
        const windowHeight = $(window).height();
        const headerHeight = $('.headerContainer').height();
        const menuHeight = $('.menuContainer').height();
        const elementPosition = this.$dateInput.offset().top;
        let scrollTo;

        scrollTo = elementPosition;

        if (headerHeight) {
            scrollTo -= headerHeight;
        }
        if (menuHeight) {
            scrollTo -= menuHeight;
        }

        scrollTo -= 50;

        setTimeout(() => {
            this.showDropdown(this.$el);
        }, 1);

        if (elementPosition > currenPosition + windowHeight || elementPosition < currenPosition) {
            $('html, body').animate({ scrollTop: scrollTo }, 200);
            return true;
        }

        return false;
    },
    dateFromOld: null,
    dateToOld: null,
    departureCityData: null,
    $nightRangeEl: null,
    daysRange: null,
    gaTime: 0,
    gaTimer: null,
    enableCloseTimeout: false,
    $dateFlexibleCheckbox() {
        this.$dateFlexibleCheckbox = $('#dateFlexible');
    },
    $daysFlexible() {
        this.$daysFlexible = $('#daysFlexible');
    },
    getDatepicker() {
        return $('#dateRangeContainer');
    },
    hideDropdown($element) {
        $element.parents('.formControl').find('.formDropdownContent:visible').hide();
        $element.parents('.formControl').find('.formInput.open').removeClass('open');
        clearInterval(this.gaTimer);
    },
    showDropdown($element) {
        $element.parents('.formControl').find('.formDropdownContent:hidden').show();
        $element.parents('.formControl').find('.formInput').addClass('open');
        clearInterval(this.gaTimer);
        this.dataLayerAnalyticsEvent();
    },
    dataLayerAnalyticsEvent() {
        if (this.dataLayerAnalytics) {
            this.dataLayerAnalytics.searchFieldsEvent('datesOpen');
        }
    },
    chooseCalendarNumber() {
        const windowWidth = $(window).width();
        let numOfCalendars = 2;

        if (windowWidth < 705) {
            numOfCalendars = 1;
        }

        return numOfCalendars;
    },
    datepickerParams() {
        return {
            numberOfMonths: this.chooseCalendarNumber(),
            minDate: '+1d',
            maxDate: '+1y',
            dateFormat: 'dd.mm',
        };
    },
    getMiddleDate(checkInDateRange) {
        const checkInDateRangeFrom = checkInDateRange.get('from');
        const checkInDateRangeTo = checkInDateRange.get('to');
        const dateDiff = moment(checkInDateRangeTo).diff(checkInDateRangeFrom, 'days');

        return moment(checkInDateRangeFrom).clone().add(dateDiff / 2, 'days').toDate();
    },
    datePickerSetDate(checkInDateRange) {
        const middleDate = this.getMiddleDate(checkInDateRange);
        const daysRange = 1;
        const checkInDateRangeFrom = checkInDateRange.get('from');
        const checkInDateRangeTo = checkInDateRange.get('to');

        if (checkInDateRangeFrom.getTime() !== checkInDateRangeTo.getTime()) {
            this.$dateFlexibleCheckbox.prop('checked', true);
            this.daysRange = daysRange;
        } else {
            this.$dateFlexibleCheckbox.prop('checked', false);
            this.daysRange = 0;
        }

        this.$daysFlexible.text(`${daysRange} ${daysRange.wordEnding('день;дня;дней')}`);
        this.getDatepicker().datepicker('setDate', middleDate);

        this.updateDateText();
    },
    updateDateText() {
        let datesText;
        const checkInDateRange = this.model.get('model');
        const dateFrom = checkInDateRange.get('from');
        const dateTo = checkInDateRange.get('to');
        const daysRange = 1;
        let middleDate;

        if (dateFrom.getTime() !== dateTo.getTime()) {
            if (moment(dateTo).diff(moment(dateFrom), 'days') === daysRange * 2) {
                middleDate = this.getMiddleDate(checkInDateRange);
                datesText = `с ${moment(middleDate).format('DD.MM')} ± ${daysRange} ${daysRange.wordEnding('день;дня;дней')}`;
            } else {
                datesText = `c ${moment(dateFrom).format('DD.MM')} по ${moment(dateTo).format('DD.MM')}`;
            }
        } else {
            datesText = `с ${moment(dateFrom).format('DD.MM.YYYY')}`;
        }

        this.$dateInput.val(datesText);
    },
    datePickerChooseDate() {
        const datepickerDate = this.getDatepicker().datepicker('getDate');

        this.datesChanged(datepickerDate);
    },
    otherSourceDataChanged() {
        let middleDate;
        const checkInDateRange = this.model.get('model');

        if (checkInDateRange) {
            middleDate = this.getMiddleDate(checkInDateRange);
            this.datesChanged(middleDate);
        }
    },
    datesChanged(date) {
        const daysRange = 1;
        const daysRangeSelected = this.$dateFlexibleCheckbox.is(':checked');
        let checkInDateRange;

        if (daysRangeSelected) {
            checkInDateRange = CheckInDateRangeFactory({
                from: date,
                daysFlexible: daysRange,
            });

            this.daysRange = daysRange;
        } else {
            checkInDateRange = CheckInDateRangeFactory({
                from: date,
                to: date,
            });

            this.daysRange = 0;
        }

        if (checkInDateRange && checkInDateRange.get('from') && checkInDateRange.get('to')) {
            let departureVal = '';
            if (this.dateFromOld === null) {
                this.dateFromOld = checkInDateRange.get('from');
            }

            if (this.dateToOld === null) {
                this.dateToOld = checkInDateRange.get('to');
            }
            if (this.dateFromOld.getTime() !== checkInDateRange.get('from').getTime()) {
                this.dateFromOld = checkInDateRange.get('from');
                departureVal += `c ${moment(this.dateFromOld).format('DD.MM')}`;
            }
            if (this.dateToOld.getTime() !== checkInDateRange.get('to').getTime()) {
                this.dateToOld = checkInDateRange.get('to');
                if (departureVal.length) departureVal += ' ';
                departureVal += `по ${moment(this.dateToOld).format('DD.MM')}`;
            }
            if (departureVal.length) {
                if (departureVal.length) {
                    if (document.location.pathname === '/') {
                        gaAsync(() => {
                            ga('send', 'event', 'Search form main', 'Departure_from_to', departureVal, { nonInteraction: 1 });
                        });
                    } else {
                        gaAsync(() => {
                            ga('send', 'event', 'Search form serp', 'Departure_from_to', departureVal, { nonInteraction: 1 });
                        });
                    }
                }
            }

            this.$daysFlexible.text(`${daysRange} ${daysRange.wordEnding('день;дня;дней')}`);
            this.model.set('model', checkInDateRange, { initiator: 'view' });

            this.updateDateText();
            this.getDatepicker().datepicker('refresh');

            console.log(date, daysRange, daysRangeSelected);
            console.log(`Changed CheckInDateRange to${checkInDateRange.get('from')}-${checkInDateRange.get('to')}`);
        } else {
            console.log(date, daysRange, daysRangeSelected);
            TrackJS.track('Wrong date from datepicker');
        }
    },
    openNights() {
        this.hideDropdown(this.$el);
        this.$nightRangeEl.addClass('open');
    },
    dateRangeInit() {
        const datepickerParams = this.datepickerParams(this);

        this.getDatepicker().datepicker(datepickerParams)
            .bind('change', (e) => {
                tourCriteriaService.addTourCriteriaChangedField('checkInDateRange');
                this.datePickerChooseDate();
                this.openNights(e);
            });

        this.$dateFlexibleCheckbox.on('change', () => {
            tourCriteriaService.addTourCriteriaChangedField('checkInDateRange');
            this.otherSourceDataChanged();
        });

        const $formInput = this.$dateInput.parents('.formInput');
        $formInput.click(() => {
            if (!this.$el.hasClass('disable')) {
                if ($formInput.hasClass('open')) {
                    this.hideDropdown($formInput);
                } else {
                    this.showDropdown($formInput);
                }
            }
        });

        $(document).click((event) => {
            if (!($(event.target).parents('.tour-duration').length
                || $(event.target).parents('.ui-datepicker').length
                || $(event.target).hasClass('ui-datepicker-prev')
                || $(event.target).hasClass('ui-datepicker-next')
                || $(event.target).parent().hasClass('ui-datepicker-prev')
                || $(event.target).parent().hasClass('ui-datepicker-next')
            )) {
                this.hideDropdown(this.$el);
            }
        });
    },
    modelChanged(event, model, context) {
        if (!context || context.initiator !== 'view') {
            if (!model) {
                this.$el.addClass('disable');
            } else {
                this.$el.removeClass('disable');
                this.datePickerSetDate(model);
            }
        }
        this.updateCheckInDateRangeStore(model);
    },
    updateCheckInDateRangeStore(model) {
        if (model) {
            const store = getStore();
            store.commit(`${CHECK_IN_DATE_RANGE_STORE}${this.modelPrefix}/setCheckInDateRange`, {
                from: model.get('from'),
                to: model.get('to'),
            });
        }
    },
    departureCityChanged(event, model, context) {
        console.log('departureCityChanged', event, model, context);
    },
    initEvents() {
        this.model.on('change:model', $.proxy(this.modelChanged, this));
        this.departureCityData.on('change:model', $.proxy(this.otherSourceDataChanged, this));
    },
    initVuexStore() {
        const store = getStore();
        store.registerModule(`${CHECK_IN_DATE_RANGE_STORE}${this.modelPrefix}`, CheckInDateRangeStore);
    },
    initialize(options) {
        this.$dateInput();

        this.departureCityData = options.departureCityData;
        this.$nightRangeEl = $(options.nightRangeEl);
        this.dataLayerAnalytics = options.dataLayerAnalytics;
        this.modelPrefix = options.modelPrefix ? options.modelPrefix : '';
        this.$dateFlexibleCheckbox();
        this.$daysFlexible();
        this.initEvents();
        this.dateRangeInit();
        this.initVuexStore();
    },
});

export default CheckInDateRangeView;
