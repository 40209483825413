
    import {
        defineComponent, computed, unref, SetupContext,
    } from 'vue';
    import useResortFilter from '@ve/pages/serp/components/filters/resortFilter/common/use/useResortFilter';
    import useResortFilterWrapper from '@ve/pages/serp/components/filters/resortFilter/common/use/useResortFilterWrapper';
    import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
    import useVueProxy from '@ve/services/useVueProxy';
    import ResortFilter from './ResortFilter.vue';

    export default defineComponent({
        name: 'ResortsFilterWrapper',
        components: {
            ResortFilter,
        },
        setup(props, { root }: SetupContext) {
            const { $root } = useVueProxy();
            const serpTrackingService = new SerpTrackingService();
            const { resorts: resortsInSearch, resortRepository, countries } = useResortFilterWrapper();

            const { popularResorts, otherResorts } = useResortFilter(resortRepository);

            const selectedResorts = (resortsIds: number[]) => {
                serpTrackingService.filterResortClick();
                $root?.$emit('change-resorts-by-filter', resortsIds);
            };

            const nameCountryResorts = computed(() => (unref(countries)[0]?.name));

            return {
                serpTrackingService,
                resortsInSearch,
                popularResorts,
                otherResorts,
                nameCountryResorts,
                selectedResorts,
            };
        },
    });
