export enum OperatorId {
    NTK = 81,
    OneTouchTravel = 230,
    RussianExpress = 98,
    SunMar = 48,
    MyAgent = 226, //travelata
    Travelata = 226,
    TezTour = 50,
    AnexTour = 5,
    AleanTour = 122,
    BiblioGlobus = 11,
    Paks = 92,
}