/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';

let tourHunterTourCriteriaHydrator = function (tourCriteriaTH) {
	var tourCriteriaJson = _.pick(tourCriteriaTH, 'countries', 'departureCity', 'hotelCategories', 'hotels', 'meals', 'nightRange', 'resorts', 'touristGroup'),
		checkInDateRange = tourCriteriaTH['checkInDateRange'];

	if (checkInDateRange) {
		tourCriteriaJson['checkInDateRange'] = {
			from: moment(checkInDateRange['from'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
			to: moment(checkInDateRange['to'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
		}
	}

	return tourCriteriaJson;
};

export default tourHunterTourCriteriaHydrator;