import { ChildServiceFilterItem } from '../types/ChildServiceFilterItem';
import { RestTypeFilterItem } from '../types/RestTypeFilterItem';
import { BeachTypeFilterItem } from '../types/BeachTypeFilterItem';

export interface IFilterHotelsState {
    childServiceFilterIds: ChildServiceFilterItem['id'][];
    restTypeFilterId: RestTypeFilterItem['id'] | 0;
    beachTypeFilterIds: BeachTypeFilterItem['id'][];
}

export interface IFilterHotersMutations {
    setChildServiceFilter(state: IFilterHotelsState, value: ChildServiceFilterItem['id'][]): void;
    setRestTypeFilter(state: IFilterHotelsState, value: RestTypeFilterItem['id'] | 0): void;
    setBeachTypeFilter(state: IFilterHotelsState, value: BeachTypeFilterItem['id'][]): void;
}

const DefaultState = (): IFilterHotelsState => ({
    childServiceFilterIds: [],
    restTypeFilterId: 0,
    beachTypeFilterIds: [],
});

export default {
    namespaced: true,
    state: DefaultState(),
    mutations: {
        setChildServiceFilter(state: IFilterHotelsState, value: ChildServiceFilterItem['id'][]) {
            state.childServiceFilterIds = value;
        },
        setRestTypeFilter(state: IFilterHotelsState, value: RestTypeFilterItem['id'] | 0) {
            state.restTypeFilterId = value;
        },
        setBeachTypeFilter(state: IFilterHotelsState, value: BeachTypeFilterItem['id'][]) {
            state.beachTypeFilterIds = value;
        },
    },
};

export const NAMESPACE = 'filterHotelsNameModel';
