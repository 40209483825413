/* eslint-disable */
import { TrackJS } from "trackjs";
import TourCriteriaObjectValue from '../ObjectValue/TourCriteria';
import CountriesRepository from '../Repository/Countries';
import tourCriteriaService from '../Service/tourCriteria';
import TourCriteriaFactory from '../Factory/TourCriteria';
import ResortsRepository from '../Repository/Resorts';
import HotelsRepository from '../Repository/Hotels';
import HotelCategoriesRepository from '../Repository/HotelCategories';

const SearchFormModel = TourCriteriaObjectValue.extend({
	initialize:function(params) {
		this.countriesRepository = params && params.countries.get('countriesRepository') ? params.countries.get('countriesRepository') : false;
	},
	getTourCriteria:function() {
		var tourCriteria = {},
			attribute,
			attributeNames = this.attributes;

		for(var i in attributeNames) {
			attribute = this.get(i);
			if(attribute !== null) {
				tourCriteria[i] = this.get(i).get('model');
			}
		}
		return new TourCriteriaObjectValue(tourCriteria);
	},
	setTourCriteria: function(tourCriteria, preventAdditionalLoading) {
		function setAttributes(attributeNames) {
			var someIsNotNull = false;
			_.each(attributeNames, function(attributeName) {
				attribute = tourCriteria.get(attributeName);
				if(self.get(attributeName) !== null && (
						(typeof attribute === 'object' && !_.isEmpty(attribute)) || attribute
				)) {
					let setField = self.get(attributeName);
					if (setField) {
						someIsNotNull = true;
						setField.set('model', attribute, {initiator: 'controller'});
					} else {
						TrackJS.console.log(attributeName, attribute?.toJSON?.() || attribute);
						TrackJS.track('Set attribure error');
					}
				}
			});
			return someIsNotNull;
		}

		var	attribute,
			self = this,
			allAttributes = _.keys(tourCriteria.attributes),
			mainAttributes = ['departureCity', 'countries', 'resorts', 'hotels'],
			otherAttributes = _.difference(allAttributes, mainAttributes);

		if(setAttributes(mainAttributes) && !preventAdditionalLoading) {
			this.model = this;
			this.departureCityChanged(null, tourCriteria.get('departureCity'));
		}
		setAttributes(otherAttributes);
	},
	departureCityChanged: function(event, model, context) {
		var self = this;
		if(model !== null && (!context || context.initiator !== 'controller')) {
			console.log('departureCity changed to ', model.get('name'));

			this.model.get('countries').setNull();
			this.model.get('resorts').setNull();
			this.model.get('hotels').setNull();

			model.getCountries({
				success: function(countries) {
					var formCountriesModel = self.model.get('countries'),
						selectedCountries = _.intersection(countries.models, formCountriesModel.get('oldModel').models),
						defaultTourCriteriaCountry = countries.min(function(country){
							return country.get('position');
						}),
						firstPopular;

					formCountriesModel.set('collection', countries);

					if(selectedCountries.length) {
						formCountriesModel.set('model', new CountriesRepository(selectedCountries), {initiator: 'formModel'});
					} else if(defaultTourCriteriaCountry) {
						formCountriesModel.set('model', new CountriesRepository([defaultTourCriteriaCountry]), {initiator: 'formModel'});
					} else {
						firstPopular = countries.findWhere({isPopular: true});
						if (firstPopular) {
							formCountriesModel.set('model', new CountriesRepository([firstPopular]), {initiator: 'formModel'});
						} else {
							formCountriesModel.set('model', new CountriesRepository([countries.at(0)]), {initiator: 'formModel'});
						}
					}
				}
			}, {
				countriesRepository:this.countriesRepository
			});
		}
	},

	setCountryTourCriteria:function(model) {
		var countryTourCriteria,
			departureCityType = this.model.get('departureCity').get('model').get('type'),
			countryTourCriteriaFields = departureCityType ? ['hotelCategories', 'meals'] : ['nightRange', 'hotelCategories', 'meals'],
			countryTourCriteriaChangedFields = tourCriteriaService.getTourCriteriaChangedFields();

		countryTourCriteria = _.pick((departureCityType ? model.at(0).get(departureCityType + 'TourCriteria') : model.at(0).get('tourCriteria')), _.difference(countryTourCriteriaFields, countryTourCriteriaChangedFields));

		if(!_.isEmpty(countryTourCriteria)) {
			this.setTourCriteria(new TourCriteriaFactory(countryTourCriteria));
		}
	},
	setResortsCriteria:function(resortCriteria) {
		var countries = this.get('countries').get('model'),
			countryTourCriteriaFields = [/*'priceRange',*/ 'nightRange', 'hotelCategories', 'meals'],
			countryTourCriteriaChangedFields = tourCriteriaService.getTourCriteriaChangedFields(),
			countryTourCriteria = countries.at(0).get('tourCriteria');

		if(resortCriteria) {
			countryTourCriteria = $.extend({}, countryTourCriteria, resortCriteria);
		}

		countryTourCriteria = _.pick(countryTourCriteria, _.difference(countryTourCriteriaFields, countryTourCriteriaChangedFields));

		if(!_.isEmpty(countryTourCriteria)) {
			this.setTourCriteria(new TourCriteriaFactory(countryTourCriteria));
		}
	},
	countriesChanged: function(event, model, context) {
		var self = this;

		if(model !== null && model.length && (!context || context.initiator !== 'controller')) {
			console.log('Country changed to ', model.pluck('name').join(', '));

			this.model.setCountryTourCriteria(model);

			this.model.get('resorts').setNull();
			this.model.get('hotels').setNull();

			model.forEach((currentModel) => {
				currentModel.getResorts({
					success(resorts) {
						const formResortsModel = self.model.get('resorts');
						const previousResorts = formResortsModel.get('oldModel');
						let selectedResorts = [];

						if (previousResorts !== null) {
							selectedResorts = _.intersection(resorts.models, previousResorts.models);
						}

						formResortsModel.set('collection', resorts);
						formResortsModel.set('model', new ResortsRepository(selectedResorts), {initiator: 'formModel'});
					},
				});
			});
		}
	},
	resortsChangedForCriteria: function(event, model, context) {
		var countries = this.model.get('countries').get('model');

		if(model !== null && (!context || context.initiator !== 'controller')) {
			if (countries.at(0).get('id') == 92) {
				if (model.length == 1 && model.get(2185)) {
					this.model.setResortsCriteria({
						meals: [],
						hotelCategories: []
					});
				} else {
					this.model.setResortsCriteria();
				}
			}
		}
	},
	resortsChanged: function(event, model, context) {
		var self = this,
			formHotelsModel = self.model.get('hotels'),
			hotelCategories = self.model.get('hotelCategories').get('model'),
			previousHotels,
			selectedHotels = [],
			hotels,
			currentTourCriteria,
			tourCriteriaCountries,
			tourCriteriaResorts;

		if(model !== null && (!context || context.initiator !== 'controller')) {
			currentTourCriteria = self.model.getTourCriteria();
			tourCriteriaCountries = currentTourCriteria.get('countries');
			tourCriteriaResorts = currentTourCriteria.get('resorts');
			console.log('Resorts changed to ', model.pluck('name').join(', '));

			this.model.get('hotels').setNull();
			previousHotels = formHotelsModel.get('oldModel');

			if(previousHotels !== null && previousHotels.length) {
				previousHotels.each(function(previousHotel) {
					var resort = previousHotel.get('resort') ? previousHotel.get('resort') : previousHotel,
						country = resort.get('country');

					if(country == tourCriteriaCountries.at(0) && (!tourCriteriaResorts.length || tourCriteriaResorts.contains(resort))) {
						selectedHotels.push(previousHotel);
					}
				});
			}
			formHotelsModel.set('model', new HotelsRepository(selectedHotels));
		}
	},
	hotelsChanged: function(event, hotels, context) {
		console.log('hotelsChanged');
		var currentHotelCategories = this.model.get('hotelCategories').get('model'),
			hotelWithoutCategory = false,
			additionalCategories = [];

		if(hotels && !hotels.isEmpty()) {
			hotels.each(function(hotel) {
				var hotelCategory = hotel.get('category'),
					hotelCategoryId = hotelCategory ? hotelCategory.get('id') : false;

				if(!hotelCategory) {
					hotelWithoutCategory = true;
				} else {
					switch (hotelCategoryId) {
						case 8:
							hotelCategory = HotelCategoriesRepository.repository.get(7);
							break;
						case 9:
							hotelCategory = HotelCategoriesRepository.repository.get(4);
							break;
						case 1:
						case 5:
							hotelCategory = HotelCategoriesRepository.repository.get(2);
							break;
					}
					if (!(currentHotelCategories && currentHotelCategories.contains(hotelCategory))) {
						additionalCategories.push(hotelCategory);
					}
				}
			});

			if(additionalCategories.length > 0) {
				if(currentHotelCategories && currentHotelCategories.length) {
					additionalCategories = additionalCategories.concat(currentHotelCategories.models);
				}
				this.model.get('hotelCategories').set('model', new HotelCategoriesRepository(additionalCategories));
			} else if(hotelWithoutCategory) {
				this.model.get('hotelCategories').set('model', new HotelCategoriesRepository([]));
			}
		}

	},
	tourCriteriaForSimilarResultsSki: function(tourCriteria) {
		var selectedCountries = tourCriteria.get('countries'),
			tourCriteriaResortsClone = selectedCountries && selectedCountries.at(0) && !_.isEmpty(selectedCountries.at(0).get('resorts')) ? selectedCountries.at(0).get('resorts').clone() : null,
			tourCriteriaForSimilarResults = this.tourCriteriaForSimilarResults(tourCriteria);


		if(tourCriteriaResortsClone) {
			tourCriteriaForSimilarResults.set('resorts', tourCriteriaResortsClone);
		}

		return tourCriteriaForSimilarResults;
	},
	tourCriteriaForSimilarResults: function(tourCriteria) {
		var tourCriteriaClone = tourCriteria.clone(),
			checkInDateRange = tourCriteriaClone.get('checkInDateRange').clone(),
			nightRange = tourCriteriaClone.get('nightRange').clone(),
			selectedCountries = tourCriteriaClone.get('countries'),
			departureCity = tourCriteriaClone.get('departureCity'),
			departureCityType = departureCity ? departureCity.get('type') : null,
			countryTourCriteria = selectedCountries && selectedCountries.at(0) ? (departureCityType ? selectedCountries.at(0).get(departureCityType + 'TourCriteria') : selectedCountries.at(0).get('tourCriteria')) : null;

		tourCriteriaClone.set('resorts', null);
		tourCriteriaClone.set('priceRange', null);
		tourCriteriaClone.set('hotelCategories', null);
		tourCriteriaClone.set('hotels', null);
		tourCriteriaClone.set('meals', null);

		checkInDateRange.set('from', new Date(tourCriteriaClone.get('checkInDateRange').get('from').getTime()));
		checkInDateRange.set('to', new Date(tourCriteriaClone.get('checkInDateRange').get('to').getTime()));

		if(checkInDateRange && checkInDateRange.get('to').getTime() - checkInDateRange.get('from').getTime() < 172800000) {
			checkInDateRange.get('from').setDate(checkInDateRange.get('from').getDate() - 3);
			checkInDateRange.get('to').setDate(checkInDateRange.get('to').getDate() + 3);

			tourCriteriaClone.set('checkInDateRange', checkInDateRange);
		}

		if(nightRange && countryTourCriteria && countryTourCriteria.nightRange) {
			if(nightRange.get('from') > parseInt(countryTourCriteria.nightRange.from, 10)) {
				nightRange.set('from', parseInt(countryTourCriteria.nightRange.from, 10));
			}

			if(nightRange.get('to') < parseInt(countryTourCriteria.nightRange.to, 10)) {
				nightRange.set('to', parseInt(countryTourCriteria.nightRange.to, 10));
			}

			tourCriteriaClone.set('nightRange', nightRange);
		}

		return tourCriteriaClone;
	}
});

export default SearchFormModel;