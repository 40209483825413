import { TrackJS } from 'trackjs';

const filterLinks = {
    'first-line': {
        firstLine: true,
    },
    'swimming-pool': {
        pool: true,
    },
    'low-price': {
        minPrice: 100,
        maxPrice: 30000,
    },
    'high-price': {
        minPrice: 30000,
        maxPrice: 5000000,
    },
    'heated-swimpool': {
        heatedPool: true,
    },
    'sea-waterpool': {
        seaPool: true,
    },
    'indoor-pool': {
        closePool: true,
    },
    spaAndHealth: {
        spaAndHealth: true,
    },
    jacuzzi: {
        jacuzzi: true,
    },
    'water-park': {
        aquapark: true,
    },
    popular: {
        better: true,
        rating: 4,
    },
    center: {
        nearTheCenter: true,
    },
    'own-beach': {
        beachOwner: true,
    },
    children: {
        withKids: true,
    },
    animals: {
        withAnimals: true,
    },
    mountains: {
        mountains: true,
    },
    eco: {
        eco: true,
    },
};
export default (postFilters) => {
    const adaptedPostFilters = {};

    _.each(postFilters, (postFilter) => {
        const translatedFilter = filterLinks[postFilter];
        if (translatedFilter) {
            Object.assign(adaptedPostFilters, translatedFilter);
        } else {
            TrackJS.track(`No filter with name ${postFilter}`);
        }
    });

    return adaptedPostFilters;
};