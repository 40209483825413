import { IFilterAttributeConfirmation } from '../types/IConfirmationFilter';

export interface IFiltersTourProductsState {
    confirmationFilter: IFilterAttributeConfirmation['id'] | 0;
}

export interface IFiltersTourProductsMutations {
    setConfirmationFilter: (state: IFiltersTourProductsState, value: IFilterAttributeConfirmation['id'] | 0) => void;
}

const DefaultState = (): IFiltersTourProductsState => ({
    confirmationFilter: 0,
});

export default {
    namespaced: true,
    state: DefaultState(),
    mutations: {
        setConfirmationFilter(state: IFiltersTourProductsState, value: IFilterAttributeConfirmation['id'] | 0) {
            state.confirmationFilter = value;
        },
    },
};

export const NAMESPACE = 'filterTourProductsModel';
