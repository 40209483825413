/* eslint-disable */
export default function getHotelsWithSamePrice({firstHotelsPrices, foundHotels}) {

    if (foundHotels?.models?.length) {
        return foundHotels.filter((hotel) => {
            const hotelId = hotel.get('id');
            const firstHotelPrice = firstHotelsPrices[hotelId];

            return firstHotelPrice && firstHotelPrice === hotel.get('minPrice');
        });
    }

    return [];
}