import { ICheckInDateRangeStore } from '../types/ICheckInDateRangeStore';

export const NAMESPACE = 'checkInDateRangeStore';

export default {
    namespaced: true,
    state: () => ({
        checkInDateRange: null,
    }),
    mutations: {
        setCheckInDateRange: (state, checkInDateRange) => {
            state.checkInDateRange = checkInDateRange;
        },
    },
} as ICheckInDateRangeStore;