import {
    computed, Ref, SetupContext, toRefs, unref,
} from 'vue';

export default (props: {
    selected: boolean;
}, ctx: SetupContext): {
    isFilterSelected: Ref<boolean>;
} => {
    const {
        selected,
    } = toRefs(props);

    const isFilterSelected = computed({
        get: () => unref(selected),
        set: (isSelectedValue) => ctx.emit('change', isSelectedValue),
    });

    return {
        isFilterSelected,
    };
};