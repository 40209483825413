import { Component, Prop, Vue } from 'vue-property-decorator';
import { TButtonColorType } from './types';

export type Size = 'small' | 'medium' | 'large';
type Display = 'inline' | 'block';
type AvailableClasses = {
    'button_secondary'?: boolean;
    'button_transparent'?: boolean;
    'button_third'?: boolean;
    'button_small'?: boolean;
    'button_medium'?: boolean;
    'button_large'?: boolean;
    'button_two-strings'?: boolean;
    'button_three-strings'?: boolean;
    'button_disabled'?: boolean;
    'button_centered'?: boolean;
    'button_block'?: boolean;
    'button_no-wrap-text'?: boolean;
}

@Component
export default class extends Vue {
    @Prop(String) readonly upperText?: string;

    @Prop(String) readonly bottomText?: string;

    @Prop(Boolean) readonly disabled?: boolean;

    @Prop(Boolean) readonly centered?: boolean;

    @Prop({ default: 'medium' }) readonly size!: Size;

    @Prop({ default: 'primary' }) readonly type!: TButtonColorType;

    @Prop({ default: 'inline' }) readonly display!: Display;

    @Prop() isActive!: boolean;

    @Prop({ default: false }) readonly enabledNoWrapText!: boolean

    get classes(): AvailableClasses {
        const classes: AvailableClasses = {};

        // {'button_two-strings': hasTwoStrings, 'button_three-strings': hasThreeStrings}
        if (this.upperText && this.bottomText) {
            classes['button_three-strings'] = true;
        } else if (this.upperText || this.bottomText) {
            classes['button_two-strings'] = true;
        }

        if (this.size === 'large') {
            classes.button_large = true;
        } else if (this.size === 'small') {
            classes.button_small = true;
        } else if (this.size === 'medium') {
            classes.button_medium = true;
        }

        if (this.type === 'secondary') {
            classes.button_secondary = true;
        } else if (this.type === 'transparent') {
            classes.button_transparent = true;
        } else if (this.type === 'third') {
            classes.button_third = true;
        }

        if (this.disabled) {
            classes.button_disabled = true;
        }

        if (this.centered) {
            classes.button_centered = true;
        }

        if (this.display === 'block') {
            classes.button_block = true;
        }

        if (this.enabledNoWrapText) {
            classes['button_no-wrap-text'] = true;
        }

        return classes;
    }

    get activeClass(): string {
        if (this.isActive) {
            if (this.type === 'secondary') {
                return 'button_secondary-active';
            }
        }
        return '';
    }

    buttonClick(event: Event): void {
        if (!this.disabled) {
            this.$emit('click', event);
        }
    }
}