import 'jquery.cookie';
import { ExWindow } from '@ve/types';
import { format } from 'date-fns';

import getABTestValue, { IgetAbTestValue } from '@/js/common/Domain/Service/getABTestValue';
import isMobileVersion from '@/js/common/Service/isMobileVersion';

declare let $: {
    cookie(payload: string): string
};

declare let window: ExWindow;

interface ImainMetaData {
    timestamp: number
}

export interface ImainMetaDataReturn {
    'ga_id': string | number
    'ya_id': string | number
    'ab_tests': IgetAbTestValue[] | null
    source: 'mobweb' | 'desktop' | null
    domain: string
    timestamp: string | null
}

export default (options: ImainMetaData): ImainMetaDataReturn => {
    const getSource = (isMobile: boolean | null) => {
        if (isMobile === null) {
            return null;
        }

        return isMobile
            ? 'mobweb'
            : 'desktop';
    };

    const abTests = getABTestValue();

    return {
        ga_id: $.cookie('_ga') || 0,
        ya_id: $.cookie('_ym_uid') || 0,
        domain: window.location.host,
        source: getSource(isMobileVersion()),
        timestamp: options.timestamp ? format(options.timestamp, 'yyyy-MM-dd HH:mm:ss') : null,
        ab_tests: abTests,
    };
};