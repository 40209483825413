/* eslint-disable */
import { TrackJS } from "trackjs";

const errorHandlerService = function (message, errorObj) {
	TrackJS.console?.log('call errorHandlerService');
	if (typeof console != "undefined" && typeof console.log == "function") {
		console.log('Something wrong');
		console.log(message);
		if(errorObj?.errorThrown) {
			console.log(errorObj.errorThrown);
		}
	}

	return this;
};

export default errorHandlerService;