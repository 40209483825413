import moment from 'moment';
import 'moment/locale/ru';
import 'jquery.cookie';
import trackingGetProductsCount from '@/js/common/Domain/Service/tracking/trackingGetProductsCount';
import trackingDefaultDataTypesHydrator from './trackingDefaultDataTypes';
import getQtyParams from './trackingHotelSearchData/getQtyParams';

const trackingHotelSearchDataHydrator = async (options) => {
    const {
        toursCountForSearchInterval = 0,
        tourCriteria,
        dynamicPackageMinPrice = null,
        tourMinPrice = null,
        tourMinPriceOilTax,
        tourMinPriceId = null,
        tourMinPriceOperatorId = null,
        originalSearchUuid = null,
        serpCheapestPrice = null,
        serpCheapestPriceOilTax = null,
        serpCheapestPriceId = null,
        serpCheapestPriceOperatorId = null,
        productTypeByFilterCriteria = null,
    } = options;
    const timeFirstTours = options.firstCacheTime ? Math.round((options.firstCacheTime - options.timestamp) / 1000) : 0;
    const timeSearchFinished = options.searchFinishedTime ? Math.round((options.searchFinishedTime - options.timestamp) / 1000) : 0;
    const toursCount = options.foundTours.length;
    const { aviaToursCount, hotelOffersCount, railwayToursCount } = trackingGetProductsCount(options.foundTours);
    const fromCity = tourCriteria.get('departureCity').get('id');
    let countries = tourCriteria.get('countries');
    let resorts = tourCriteria.get('resorts');
    let hotels = tourCriteria.get('hotels');
    const checkInDateRange = tourCriteria?.get('checkInDateRange') || 0;
    const nightRange = tourCriteria?.get('nightRange') || 0;
    const touristGroup = tourCriteria?.get('touristGroup') || 0;
    const trackingDefaultDataTypes = await trackingDefaultDataTypesHydrator(options);

    countries = countries?.length ? countries.pluck('id') : [];
    resorts = resorts?.length ? resorts.pluck('id') : [];
    hotels = hotels?.length ? hotels.pluck('id') : [];

    return {
        toursCountForSearchInterval,
        timeFirstTours,
        timeSearchFinished,
        toursCount,
        aviaToursCount,
        hotelOffersCount,
        railwayToursCount,
        fromCity,
        tourMinPrice,
        tourMinPriceOilTax,
        tourMinPriceId,
        tourMinPriceOperatorId,
        originalSearchUuid,
        serpCheapestPrice,
        serpCheapestPriceOilTax,
        serpCheapestPriceId,
        serpCheapestPriceOperatorId,
        productTypeByFilterCriteria,
        dynamicPackageMinPrice: dynamicPackageMinPrice ? Math.round(dynamicPackageMinPrice) : null,
        ...trackingDefaultDataTypes,
        toCountry: countries[0] || 0,
        toCity: resorts[0] || 0,
        hotel: hotels[0] || 0,
        dateFrom: checkInDateRange ? moment(checkInDateRange.get('from')).format('YYYY-MM-DD') : 0,
        dateTo: checkInDateRange ? moment(checkInDateRange.get('to')).format('YYYY-MM-DD') : 0,
        nightFrom: nightRange?.get('from') || 0,
        nightTo: nightRange?.get('to') || 0,
        adults: touristGroup?.get('adults') || 0,
        kids: touristGroup?.get('kids') || 0,
        infants: touristGroup?.get('infants') || 0,
        ...getQtyParams(options.foundTours),
    };
};

export default trackingHotelSearchDataHydrator;