// eslint-disable-next-line no-shadow
export enum ChildServiceId {
    Animators = 9,
    Pool = 10,
    Menu = 11,
    Playroom = 12,
    Clubs = 16,
    Bed = 17,
    Nanny = 18,
}

export type ChildServiceOld = 'children_animators' | 'children_pool' | 'children_childmenu' | 'children_playroom' | 'children_clubs' | 'children_childBed' | 'children_nanny';

export const CHILD_SERVICE_OLD_MAP: Record<ChildServiceId, ChildServiceOld> = {
    9: 'children_animators',
    10: 'children_pool',
    11: 'children_childmenu',
    12: 'children_playroom',
    16: 'children_clubs',
    17: 'children_childBed',
    18: 'children_nanny',
};