import Backbone from 'backbone';
import { EmptyObject } from '@ve/types/EmptyObject';

export interface DirectFlight {
    transport: {
        flights: {
            direct: true,
        },
    },
}

export function directFlights(tourCriteria: Backbone.Model): DirectFlight | EmptyObject {
    let tourCriteriaJson: DirectFlight | EmptyObject = {};
    const element: boolean = tourCriteria.get('directFlight');
    if (element) {
        tourCriteriaJson = {
            transport: {
                flights: {
                    direct: element,
                },
            },
        };
    }
    return tourCriteriaJson;
}