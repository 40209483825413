
    import {
        defineComponent, computed, unref, getCurrentInstance, Ref, ComputedRef,
    } from 'vue';
    import DirectFlightFilter from '@ve/components/filters/directFlightFilter/desktop/DirectFlightFilter.vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import SingleListFilter from '@ve/components/searchFilters/filterList/desktop/SingleListFilter.vue';
    import { useState } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import useDirectFlightFilter from '@ve/components/filters/directFlightFilter/desktop/use/useDirectFlightFilter';
    import abTests from '@/js/common/Domain/Service/abTests';

    interface IDirectFlightFilterSerpReturn {
        isFilterSelected: Ref<boolean>,
        startSearchByDirectFlightFilter: (value: boolean) => void,
        isPairOfDepartureCityAndArrivalCountryAreValid: ComputedRef<boolean>,
        directFlightFilterInBVersion: boolean,
    }

    export default defineComponent({
        name: 'direct-flight-filter-desktop-serp',
        components: {
            DirectFlightFilter,
            FilterListItem,
            SingleListFilter,
        },
        setup(): IDirectFlightFilterSerpReturn {
            const { directFlight } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['directFlight']);
            const isFilterSelected = computed(() => unref(directFlight));

            const directFlightFilterInBVersion = abTests.hasTest('IN2-953');

            const {
                isPairOfDepartureCityAndArrivalCountryAreValid,
            } = useDirectFlightFilter();

            const proxy = getCurrentInstance()?.proxy;
            const startSearchByDirectFlightFilter = (value: boolean) => {
                proxy?.$root.$emit('start-search-by-direct-flight-filter', value);
            };

            return {
                isFilterSelected,
                startSearchByDirectFlightFilter,
                isPairOfDepartureCityAndArrivalCountryAreValid,
                directFlightFilterInBVersion,
            };
        },
    });
