/* eslint-disable */
import MarketingClient from 'marketing-app-js-vendor';
import config from '@fr/config/appConfig'
import {TrackJS} from "trackjs";

let clientUuid = window.appConfig.clientUuid;

if(!clientUuid) {
	TrackJS.track("No clientUuid provided!!");
}

let marketingClient = null;

try {
	marketingClient = new MarketingClient({
		config: config.marketingClientConfig,
		clientUuid: clientUuid
	});
} catch(err) {
	TrackJS.track(err);
}

export default marketingClient;