import { useState, useMutations, useGetters } from 'vuex-composition-helpers';
import { computed, unref } from 'vue';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import ResortRepository, {
    NAMESPACE,
    IResortsRepositoryMutations,
    IResortRepositoryState,
} from '../model/ResortRepository';

export default function () {
    registerModule.register(getStore(), ResortRepository, NAMESPACE);

    const { resorts } = useState<IResortRepositoryState>(NAMESPACE, ['resorts']);
    const { setResorts, resetResorts } = useMutations<IResortsRepositoryMutations>(NAMESPACE, ['setResorts', 'resetResorts']);

    return {
        resetResorts,
        setResorts,
        resorts,
    };
}