/* eslint-disable */
import visaMessageTemplate from './template.html';

let VisaWarningView = Backbone.View.extend({
	cookieName:'visaWarningShowCountriesForHotTours',
	visaCountries:[],
	searchPageViewModel:null,
	events: {
		'click .close' : 'closeVisaMessage',
		'click .readMore' : 'openVisaCountriesInfo'
	},
	$warningText:function() {
		this.$warningText = $('.text', this.$el);
	},
	$visaConditionsPopup:function() {
		var self = this;
		this.$visaConditionsPopup = $('#visaConditions');
		this.$visaConditionsPopup.correctPopupPosition().dialog({
			autoOpen: false,
			width: 500,
			dialogClass: 'visaConditionsPopup',
			modal: true,
			draggable: false,
			resizable: false,
			open: function () {
				self.$visaConditionsPopup.off('click', '.popupClose').one('click', '.popupClose', function(){
					self.$visaConditionsPopup.dialog( "close" );
				});
			}
		});
	},
	closeVisaMessage:function() {
		this.addCountriesToCloseList(this.visaCountries);
		this.hideWarning();
	},
	addCountriesToCloseList:function(countries) {
		var countriesId = _.pluck(countries, 'id'),
			countriesIdsFromCookie = this.getClosedCountriesIds(),
			countriesToCookies;

		countriesToCookies = _.union(countriesIdsFromCookie, countriesId);
		$.cookie(this.cookieName, countriesToCookies.join('_'), {path: '/', domain:'all', expires: 20});
	},
	getClosedCountriesIds:function() {
		var countriesFromCookie = $.cookie(this.cookieName),
			countriesIds = [];

		if(countriesFromCookie) {
			countriesIds = _.map(countriesFromCookie.split('_'), function(id) {
				return parseInt(id, 10);
			});
		}

		return countriesIds;
	},
	showWarningCondition:function() {
		var visaCountriesIds = _.pluck(this.visaCountries, 'id'),
			countriesFromCookie = this.getClosedCountriesIds();

		if(_.intersection(visaCountriesIds, countriesFromCookie).length !== visaCountriesIds.length) {
			this.showWarning();
		}

	},
	hideWarning:function() {
		this.$el.slideUp(300);
	},
	showWarning:function() {
		this.$el.slideDown(300);
	},
	generateHTMLForVisaInfoPopup:function() {
		var html = '',
			selectedCountries = this.searchPageViewModel.get('searchData').get('countries');

		selectedCountries.each(function(country) {
			var countryName = country.get("name"),
				needVisa = country.get('visaRequired') || country.get('visaOnline'),
				visaText = country.get("visaRequired") ? (country.get("visaOnline") ? "онлайн-виза" : "виза")  : "без визы",
				visaDays = country.get('visaDays'),
				visaDaysText = needVisa ? (visaDays + ' рабочи' + visaDays.wordEnding('й;х;х') + ' ' + visaDays.wordEnding('день;дня;дней')) : '-';

			html += '<tr>' +
					'<td>' + countryName + '</td>' +
					'<td>' + visaText + '</td>' +
					'<td>' + visaDaysText + '</td>' +
				'</tr>';
		});
		this.$visaConditionsPopup.find('.countriesData').html(html);
	},
	openVisaCountriesInfo:function() {
		this.generateHTMLForVisaInfoPopup();
		this.$visaConditionsPopup.dialog('open');
	},
	searchDataChanged:function(event, searchData) {
		var searchCountries = searchData.get('countries'),
			visaCountries = searchCountries.filter(function(country) {
				return (country.get('visaRequired') || country.get('visaOnline')) && country.get('visaDays');
			});

		this.visaCountries = visaCountries;

		if(visaCountries.length) {
			this.$warningText.html(visaMessageTemplate({
				totalCountriesCount:searchCountries.length,
				countries:visaCountries
			}));
			this.showWarningCondition();
		} else {
			this.hideWarning();
		}
	},
	initModelEvents:function() {
		this.searchPageViewModel.on('change:searchData', $.proxy(this.searchDataChanged, this));
	},
	initialize:function(options) {
		this.searchPageViewModel = options.searchPageViewModel;
		this.$warningText();
		this.$visaConditionsPopup();

		this.initModelEvents();
	}
});

export default VisaWarningView;