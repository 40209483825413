import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import account from '@/js/desktop/components/account';

import codeTemplate from './codeTemplate';

import template from './assets/template.html';
import './assets/styles.scss';

import forbiddenUseOnIntercomPage from './forbiddenUseOnIntercomPage';

/**
 *  (50 мс / проверка) * 100 = 5 секунд;
 *  checkLaunchLimit - лимит количества проверок
 *  attempts - инициализация попыток
 *  timePerCheckLaunch - timeout на одну попытку (мс).
 *  state = [
 *      'init' - устанавливается один раз, при инициализации интеркома;
 *      'inProgress' - устанавливается каждый раз, во время процесса открытия интеркома;
 *      'waiting' - состояние простоя (после закрытия виджета интеркома);
 *      'opened' - состояние, когда интерком развернут (после показа интеркома, внутри события);
 *      'awaitingClosure' - состояние, когда интерком ожидает закрытия - необходимо, когда одновременно несколько событий,
 *      чтобы верно их обработать, если уже были выполнены некоторые действия;
 *  ]
 *
**/
@extendDecorator({
    attempts: 1,
    checkLaunchLimit: 100,
    state: 'init',
    timePerCheckLaunch: 50,
    template,
})
class IntercomChatView {
    /**
     * Event на клик нельзя выносить в events компоненты, т.к. необходимо иметь возможность вызывать (открывать)
     * извне компоненты чат интеркома.
     */
    constructor() {
        const {
            location: {
                search,
                pathname,
            },
        } = window;

        if (~search.indexOf('intercom') || pathname === '/intercom') {
            this.initialize();
        }

        $('body')
            .on('click', '.js-open-intercom-chat', this.initialize.bind(this));
    }

    initialize() {
        switch (this.state) {
            case 'init': {
                this.state = 'inProgress';

                codeTemplate('vpghn5ej');
                this.displayLoadingStub();
                this.openIntercomOnBooted();
                break;
            }

            case 'inProgress':
            case 'opened': {
                this.closeIntercom();
                break;
            }

            case 'waiting': {
                this.state = 'inProgress';
                this.openIntercom();
                break;
            }

            default: {
                break;
            }
        }
    }

    initIntercomEvents() {
        window.Intercom?.('onShow', () => {
            this.hideLoadingOnOpenChat();

            switch (this.state) {
                case 'inProgress': {
                    this.state = 'opened';
                    break;
                }

                case 'awaitingClosure': {
                    window.Intercom?.('hide');
                    break;
                }

                default: {
                    break;
                }
            }
        });

        window.Intercom?.('onHide', () => {
            this.hideLoadingStub();

            this.state = 'waiting';
        });
    }

    openIntercomOnBooted() {
        setTimeout(() => {
            if (this.attempts > this.checkLaunchLimit) {
                return;
            }

            if (!window.Intercom?.booted) {
                this.attempts += 1;
                this.openIntercomOnBooted();

                return;
            }

            this.initIntercomEvents();
            this.openIntercom();
        }, this.timePerCheckLaunch);
    }

    @forbiddenUseOnIntercomPage
    displayLoadingStub() {
        const isStubAbsent = $('.js-intercom-loading-stub').length === 0;

        if (isStubAbsent) {
            $('body').append(this.template());

            $('.js-intercom-loading-stub-close')
                .on('click', this.closeIntercom.bind(this));
        }
    }

    hideLoadingStub() {
        const $loader = $('.js-intercom-loading-stub');

        if ($loader.length) {
            $loader.remove();
        }
    }

    /**
     * Метод для обертки в setTimout моб. версии сайта.
     */
    hideLoadingOnOpenChat() {
        this.hideLoadingStub();
    }

    closeIntercom() {
        this.state = 'awaitingClosure';
        window.Intercom?.('hide');

        this.hideLoadingStub();
    }

    openIntercom() {
        const model = account.model.toJSON();
        const isAuthorized = model.authorized;

        if (isAuthorized) {
            window.Intercom?.call(null, 'boot', {
                name: 'Авторизированный пользователь',
                user_id: model.hash,
                customer_id: model.id,
                showcase: 'Travelata',
            });
        }

        window.Intercom?.('show');
    }
}

export default IntercomChatView;
