import { EmptyObject } from '@ve/types/EmptyObject';

interface DirectFlight {
    directFlight?: true,
}

export default (params: {[key: string]: string}): DirectFlight | EmptyObject => {
    const tourCriteria: DirectFlight | EmptyObject = {};
    const element: string | undefined = params.directFlight;
    if (element) {
        tourCriteria.directFlight = !!element as true;
    }
    return tourCriteria;
};