import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import getTomorrow from './getTomorrow';
import getDefaultDate from './getDefaultDate';
import createDateByFormat from './createDateByFormat';
import { TCheckInDateRangeResult, TDateOptions, TDefaultDateRange } from '..';
import errorHandlerService from '../../../Service/errorHandler';

export default (date: TCheckInDateRangeResult, defaultDateParams: TDefaultDateRange | null, dateOptions: TDateOptions | undefined): TCheckInDateRangeResult | null => {
    const tomorrow = getTomorrow();
    const defaultDate = getDefaultDate(defaultDateParams);
    const maxDifferenceDays = 30;
    if (date.from && date.to) {
        const isDateFromAfterDateTo = isAfter(date.from, date.to);
        if (isDateFromAfterDateTo) {
            [date.from, date.to] = [date.to, date.from];
        }
        const isAllowedOldDateCorrection = !(dateOptions && dateOptions.disableOldDateCorrection);
        if (isAllowedOldDateCorrection) {
            const isAllDatesIsOld = isBefore(date.from, tomorrow) && isBefore(date.to, tomorrow);
            if (isAllDatesIsOld) {
                date.from = createDateByFormat(defaultDate.from);
                date.to = createDateByFormat(defaultDate.to);
            } else if (isBefore(date.from, tomorrow)) {
                date.from = tomorrow;
            }
        }
        if (date.to && date.from && differenceInDays(date.to, date.from) > maxDifferenceDays) {
            errorHandlerService(`Date from: ${date.from} and dateTo: ${date.to} has more than ${maxDifferenceDays} days of difference!`);
            date.to = addDays(date.from, maxDifferenceDays);
        }

        return date;
    }
    return null;
};