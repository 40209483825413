import { REST_TYPE_OLD_MAP, RestTypeId } from '@fr/fsd/entities/hotel';
import { RestTypeFilterItem } from '../types/RestTypeFilterItem';

const restTypeFilters: RestTypeFilterItem[] = [
    {
        id: RestTypeId.Beach,
        name: 'Пляжный',
        key: REST_TYPE_OLD_MAP[RestTypeId.Beach],
        keyMerged: 'restBeach',
        tooltip: {
            label: 'Пляжный отдых',
            description: 'Отели, находящиеся вблизи пляжа (менее 3 км). Или отели с трансфером до пляжа и обратно. Для любителей размеренного отдыха.',
        },
    },
    {
        id: RestTypeId.City,
        name: 'Городской',
        key: REST_TYPE_OLD_MAP[RestTypeId.City],
        keyMerged: 'restCity',
        tooltip: {
            label: 'Городской отдых',
            description: 'Отели, находящиеся в крупных городах или в пригороде (менее 10 км от города).  Подходит, если вы любите прогулки по уютным улочкам и посещение достопримечательностей.',
        },
    },
    {
        id: RestTypeId.Mountain,
        name: 'Горный/Горнолыжный',
        key: REST_TYPE_OLD_MAP[RestTypeId.Mountain],
        keyMerged: 'mountains',
        tooltip: {
            label: 'Горнолыжный отдых',
            description: 'Отели, находящиеся в курортной зоне, на расстоянии до 10 км от подъёмника. Или отели с трансфером до подъёмника и обратно. Для всех, кто катается или просто любит горы.',
        },
    },
    {
        id: RestTypeId.Wellness,
        name: 'Оздоровительный',
        key: REST_TYPE_OLD_MAP[RestTypeId.Wellness],
        keyMerged: 'wellness',
        tooltip: {
            label: 'Оздоровительный отдых',
            description: 'Отели с оздоровительными программами (сертифицированные врачи, оборудование, спец. питание). Подходит всем, независимо от возраста.',
        },
    },
    {
        id: RestTypeId.Eco,
        name: 'Загородный/Экологичный',
        key: REST_TYPE_OLD_MAP[RestTypeId.Eco],
        keyMerged: 'eco',
        tooltip: {
            label: 'Загородный отдых',
            description: 'Отели находятся вдали от города в лесу, на берегу озера или горах. Подходит для всех, кто любит спокойный и неторопливый отдых.',
        },
    },
];

export default restTypeFilters;