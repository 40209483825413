import departureCity from './toRouter/departureCity';
import toCountry from './toRouter/toCountry';
import resorts from './toRouter/resorts';
import checkInDateRange from './toRouter/checkInDateRange';
import nightRange from './toRouter/nightRange';
import touristGroup from './toRouter/touristGroup';
import hotelCategories from './toRouter/hotelCategories';
import operators from './toRouter/operators';
import meals from './toRouter/meals';
import hotels from './toRouter/hotels';
import priceRange from './toRouter/priceRange';
import directFlights from './toRouter/directFlights';
import warranted from './toRouter/warranted';

export default (tourCriteria, otherCriteria) => ({
    ...departureCity(tourCriteria),
    ...toCountry(tourCriteria),
    ...resorts(tourCriteria),
    ...checkInDateRange(tourCriteria),
    ...nightRange(tourCriteria),
    ...touristGroup(tourCriteria),
    ...hotelCategories(tourCriteria),
    ...operators(tourCriteria),
    ...meals(tourCriteria),
    ...hotels(tourCriteria),
    ...priceRange(tourCriteria),
    ...directFlights(tourCriteria),
    ...warranted(tourCriteria),
    ...otherCriteria,
});