const prepareDepartureCity = (element) => {
    const updatedParams = {};

    if (element) {
        updatedParams.departureCity = {
            id: element.get('id'),
            name: element.get('name'),
        };
    }

    return updatedParams;
};

const prepareCountries = (element) => {
    const updatedParams = {};

    if (element?.length) {
        updatedParams.countries = element.pluck('id');
    }

    return updatedParams;
};

const prepareResorts = (element) => {
    const updatedParams = {};

    if (element?.length) {
        updatedParams.resorts = element.pluck('id');
    }

    return updatedParams;
};

const prepareHotels = (element) => {
    const updatedParams = {};

    if (element?.length) {
        updatedParams.hotels = element.pluck('id');
    }

    return updatedParams;
};

const prepareCheckInDateRange = (element) => {
    const updatedParams = {};

    if (element) {
        const from = element.get('from');
        const to = element.get('to');

        updatedParams.checkInDateRange = {};

        if (from) {
            updatedParams.checkInDateRange.from = $.datepicker.formatDate('dd.mm.yy', from);
        }

        if (to) {
            updatedParams.checkInDateRange.to = $.datepicker.formatDate('dd.mm.yy', to);
        }
    }

    return updatedParams;
};

const prepareNightRange = (element) => {
    const updatedParams = {};

    if (element) {
        updatedParams.nightRange = {};
        const from = element.get('from');
        const to = element.get('to');

        if (from) {
            updatedParams.nightRange.from = from;
        }
        if (to) {
            updatedParams.nightRange.to = to;
        }
    }

    return updatedParams;
};

const prepareTouristGroup = (element) => {
    const updatedParams = {};

    if (element) {
        updatedParams.touristGroup = {};

        const adults = element.get('adults');
        const kids = element.get('kids');
        const infants = element.get('infants');
        const ages = element.get('ages');

        if (adults) {
            updatedParams.touristGroup = {
                adults,
                kids,
                infants,
                ages,
            };
        }
    }

    return updatedParams;
};

export default (tourCriteria) => {
    const departureCity = prepareDepartureCity(tourCriteria.get('departureCity'));
    const countries = prepareCountries(tourCriteria.get('countries'));
    const resorts = prepareResorts(tourCriteria.get('resorts'));
    const hotels = prepareHotels(tourCriteria.get('hotels'));
    const checkInDateRange = prepareCheckInDateRange(tourCriteria.get('checkInDateRange'));
    const nightRange = prepareNightRange(tourCriteria.get('nightRange'));
    const touristGroup = prepareTouristGroup(tourCriteria.get('touristGroup'));

    return {
        ...departureCity,
        ...countries,
        ...resorts,
        ...hotels,
        ...checkInDateRange,
        ...nightRange,
        ...touristGroup,
    };
};
