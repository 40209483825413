import Vue from 'vue';

const createVueComponent = (svgPath: string) => Vue.component('svg-vue', {
        data: () => ({
            svg: '',
        }),
        async created() {
            const response = await fetch(svgPath);
            this.svg = await response.text();
        },
        template: '<g v-html="svg"></g>',
    });

export default (svgPath: string): any => createVueComponent(svgPath);
