import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import PartialPaymentTourBannerView from '@/js/common/components/partialPayment/partialPaymentTourBanner/PartialPaymentTourBanner';
import PartialPaymentBannerDgView from '@/js/desktop/components/partialPayment/partialPaymentBannerDg/PartialPaymentBannerDg';

import './assets/styles.scss';
import template from './assets/template.html';

@extendDecorator({
    partialPaymentBannerDg: null,
    template,
})
export default class PartialPaymentTourBannerDesktopView extends PartialPaymentTourBannerView {
    initialize(options = {}) {
        super.initialize(options);
    }

    openPopup(event) {
        event.preventDefault();

        this.partialPaymentBannerDg = new PartialPaymentBannerDgView({
            countryName: this.viewModel.get('countryName'),
            tourProducts: this.viewModel.get('tourProducts'),
        });

        this.partialPaymentBannerDg?.dialog?.show?.();
    }
}
