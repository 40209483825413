/* eslint-disable */
import ls from '@/js/common/Domain/Service/local-storage';

import marketingClient from '@/js/common/Domain/Service/marketingClient';

// Заглушка, пока запрос не нужен, возможно в будущем понадобится. Либо будет принято решение о удалении.
// import requestService from './request';
const requestService = () => {};

var userInterestsUpdateInterval = 60000,
    clientId = null,
    userInterestsData = {},
    userInterestsGroups = [],
    startTimeout = function () {
        var self = this;

        setInterval(function () {
            loadInterests();
        }, userInterestsUpdateInterval);
    },
    init = function () {
        var userInterestsDataFromLocalStorage = ls.get('userInterestsData');

        if (userInterestsDataFromLocalStorage) {
            userInterestsData = userInterestsDataFromLocalStorage;
            userInterestsGroups = _.keys(userInterestsData)
            .map(function (userInterestGroup) {
                return parseInt(userInterestGroup, 10);
            });
        }

        if (marketingClient) {
            clientId = marketingClient.getClientUuid();

            if (clientId) {
                loadInterests();
                startTimeout();
            }
        }
    };

const userInterestsService = {
    getInterestsGroups: function () {
        return userInterestsGroups;
    },
    getInterestsData: function () {
        return userInterestsData;
    }
};

const loadInterests = () => {
    const options = {
        url: '/userActivity/searchClientProfile',
        data: {
            clientId,
        },
    };

    requestService(options, {
        success(response) {
            if (!response.success || !response?.data?.clientInterests) {
                return;
            }

            const clientInterests = _
                .map(response.data.clientInterests, (interest) => ({
                    weight: interest.weight - 1,
                    userInterestGroup: interest.userInterestGroup,
                }));

            userInterestsData = _.indexBy(clientInterests, 'userInterestGroup');
            userInterestsGroups = _.keys(userInterestsData)
                .map((userInterestGroup) => parseInt(userInterestGroup, 10));

            ls.set('userInterestsData', userInterestsData);
        },
    });
};

init();

export default userInterestsService;
