import { ITourProduct } from '../types/ITourProduct';
import { TConfirmation } from '../types/IConfirmation';
import { FlightType } from '../types/IFlightType';
import { HotelAvailable } from '../types/IHotelAvailable';

const getTypeConfirmation = (tourProduct: ITourProduct): TConfirmation | '' => {
    if (tourProduct.hotelAvailable === HotelAvailable.all) return 'quick';
    if (tourProduct.hotelAvailable === HotelAvailable.byRequest && tourProduct.flightType === FlightType.charter) return 'long';
    if (tourProduct.hotelAvailable === HotelAvailable.byRequest && tourProduct.flightType === FlightType.regular) return 'cancel';

    return '';
};

const getTypeConfirmationByPriority = (tourProducts: ITourProduct[]): TConfirmation | '' => {
    const typesConfirmations = tourProducts.map((tourProduct) => getTypeConfirmation(tourProduct));
    if (typesConfirmations.includes('quick')) return 'quick';
    if (typesConfirmations.includes('long')) return 'long';
    if (typesConfirmations.includes('cancel')) return 'cancel';
    return '';
};

const isTourProductsHasConfirmation = (tourProducts: ITourProduct[], type: TConfirmation) => tourProducts
    .some((tourProduct) => getTypeConfirmation(tourProduct) === type);

const isTourProductsHasConfirmations = (allTourProducts: ITourProduct[]) => isTourProductsHasConfirmation(allTourProducts, 'quick') || isTourProductsHasConfirmation(allTourProducts, 'long');

export {
    isTourProductsHasConfirmations,
    getTypeConfirmationByPriority,
    isTourProductsHasConfirmation,
    getTypeConfirmation,
};