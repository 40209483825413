/* eslint-disable */
import Backbone from 'backbone';

var mapField = function(field, fromModel, fieldName) {
	var self = this;
	this.set(fieldName || field, fromModel.get(field));
	fromModel.on('change:' + field, function(model, value) {
		self.set(fieldName || field, value);
	});
};

Backbone.Model.prototype.mapState = function(fields, fromModel) {
	var self = this;
	switch (typeof fields) {
		case 'string':
			mapField.call(this, fields, fromModel);
			break;
		case 'object':
			_.each(fields, function(field, key) {
				mapField.call(self, field, fromModel, _.isArray(fields) ? false : key);
			});
	}
};

export default Backbone;
