/* eslint-disable */
import 'jquery.cookie';

import { propsDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';

import template from './assets/template.html';
import './assets/cashbackDg.scss';

@propsDecorator({
    template,
})
export default class CashbackDg extends Backbone.Epoxy.View {
    setCookie() {
        $.cookie(this.cookieName, 1, {
            path: '/',
            expires: 999,
            domain: wildcardDomain(),
        });
    }

    dialogOpen() {
        if (!this.$dialog) return;

        this.$dialog
            .correctPopupPosition()
            .dialog({
                width: 400,
                height: 472,
                autoOpen: true,
                resizable: false,
                dialogClass: 'cashbackDg',
                modal: true,
            }).on('click', '.popupClose', () => this.closeDialog());

        $('body').on('click', '.ui-widget-overlay', (e) => {
            const {target} = e;
            if ($(target).is('.ui-widget-overlay')) this.closeDialog();
        });
    }

    closeDialog() {
        this.$dialog.dialog('close');
        this.setCookie();
    }

    appendBanner() {
        this.$dialog = $(this.template());
        $('body').append(this.$dialog);
    }

    initialize(options) {
        this.cookieName = options.cookieName;

        if ($.cookie(this.cookieName)) return;
        this.appendBanner();
        this.dialogOpen();
    }
}
