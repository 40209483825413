function forbiddenUseOnIntercomPage(target, name) {
    if (window.location.pathname === '/intercom') {
        // eslint-disable-next-line no-param-reassign
        target[name] = () => {};
    }

    return target[name];
}

export default forbiddenUseOnIntercomPage;
