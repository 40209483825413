
    import { defineComponent, SetupContext } from 'vue';
    import useNoToursInStatic from './useNoToursInStatic';

    export default defineComponent({
        name: 'NoToursInStaticBanner',
        props: {
            isMarginShouldBeAdded: {
                type: Boolean,
                default: false,
            },
        },
        setup(_props, ctx: SetupContext) {
            return useNoToursInStatic(ctx);
        },
    });
