export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('priceRange');
    if (element) {
        tourCriteriaJson.priceRange = {};
        const from = element.get('from');
        const to = element.get('to');
        if (from) {
            tourCriteriaJson.priceRange.from = from;
        }
        if (to) {
            tourCriteriaJson.priceRange.to = to;
        }
    }

    return tourCriteriaJson;
};