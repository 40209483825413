module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div>\n    <div class="partialPaymentBannerDg__header">\n        '+
((__t=( countryName ))==null?'':__t)+
'\n    </div>\n\n    <div class="partialPaymentBannerDg__subHeader">\n        По доступным турам есть возможность предоплаты\n    </div>\n\n    ';
 _.each(operators, function(operator) { 
__p+='\n        <div class="partialPaymentBannerDg__conditions">\n            <div class="partialPaymentBannerDg__operator">\n                <img class="partialPaymentBannerDg__operator-cover"\n                     src="'+
((__t=( operator.operator.cover ))==null?'':__t)+
'"\n                     alt="'+
((__t=( operator.operator.id ))==null?'':__t)+
'"\n                />\n            </div>\n\n            <div class="partialPaymentBannerDg__conditions-block">\n                ';
 _.each(operator.firstPaymentDefinitions, function(firstPaymentDefinition, index) { 
__p+='\n                    <p ';
 if(index !== operator.firstPaymentDefinitions.length - 1) { 
__p+='class="bottom-space"';
 } 
__p+='>\n                        При оплате\n                        <label>\n                            '+
((__t=( Math.round(firstPaymentDefinition.firstPaymentPart * 100) ))==null?'':__t)+
'%\n                        </label>, более чем\n\n                        <label>\n                            за '+
((__t=( firstPaymentDefinition.minDaysAvailableBeforeCheckInDate ))==null?'':__t)+
'\n                            '+
((__t=( firstPaymentDefinition.minDaysAvailableBeforeCheckInDate.wordEnding('день;дня;дней') ))==null?'':__t)+
'\n                        </label>\n\n                        до вылета нужно внести остаток\n\n                        ';
 if(firstPaymentDefinition.daysAfterReservationForFullPayment) { 
__p+='\n                            <label>\n                                в течение\n                                '+
((__t=( firstPaymentDefinition.daysAfterReservationForFullPayment ))==null?'':__t)+
'\n                                '+
((__t=( firstPaymentDefinition.daysAfterReservationForFullPayment.wordEnding('дня;дней;дней') ))==null?'':__t)+
'\n                            </label>\n\n                            после подтверждения тура.\n                        ';
 } else { 
__p+='\n                            не позднее чем\n\n                            <label>\n                                за '+
((__t=( firstPaymentDefinition.daysBeforeCheckInDateForFullPayment ))==null?'':__t)+
' '+
((__t=(
                                firstPaymentDefinition.daysBeforeCheckInDateForFullPayment.wordEnding('дня;дней;дней') ))==null?'':__t)+
'\n                            </label>\n\n                            до вылета.\n                        ';
 } 
__p+='\n                    </p>\n                ';
 }) 
__p+='\n            </div>\n        </div>\n    ';
 }) 
__p+='\n\n    <div class="partialPaymentBannerDg__additionalText">\n        <strong>Внимание!</strong> В некоторых случаях туроператор оставляет за собой право изменить условия по срокам оплаты. В\n        случае повышения курса валют доплата будет пересчитана по новому курсу туроператора\n    </div>\n</div>\n';
}
return __p;
};
