import 'moment/locale/ru';

import Vue from 'vue';
import { NightsRangeDropdown } from '@features/searchCriteria/selectNightsRange';
import getStore from '@ve/services/getStore/getStore';
import { NightsRangeStore, NAMESPACE as NIGHTS_RANGE_STORE } from '@entities/searchCriteria/nightsRange';
import NightRangeFactory from '@/js/common/Domain/Factory/NightRange';

const NightRangeView = Backbone.View.extend({
    label: 'Количество ночей',

    setAdditionalLabel(nights) {
        let labelText = 'на ';

        if (nights.from === nights.to) {
            labelText += `${nights.to} ноч${nights.from.wordEnding('ь;и;ей')}`;
        } else {
            labelText += `${nights.from}-${nights.to} ноч${nights.to.wordEnding('ь;и;ей')}`;
        }

        this.$numOfNights.text(labelText);
    },

    scrollToElement() {
        const currenPosition = $(document).scrollTop();
        const windowHeight = $(window).height();
        const headerHeight = $('.headerContainer').height();
        const menuHeight = $('.menuContainer').height();
        const elementPosition = this.$el.offset().top;
        let scrollTo = elementPosition;

        if (headerHeight) {
            scrollTo -= headerHeight;
        }
        if (menuHeight) {
            scrollTo -= menuHeight;
        }

        scrollTo -= 50;

        setTimeout(() => {
            if (!this.nightsRangeDropdown) {
                this.initNightsRangeDropdown();
            }
            this.nightsRangeDropdown.$refs.dropDownRef.open();
        }, 1);

        const isHiddenForm = elementPosition > currenPosition + windowHeight || elementPosition < currenPosition;
        if (isHiddenForm) {
            $('html,body').animate({ scrollTop: scrollTo }, 200);
            return true;
        }

        return false;
    },

    $numOfNights() {
        this.$numOfNights = this.$el.find('.numOfNights');
    },

    toggleDropdown() {
        if (!this.$el.hasClass('disable')) {
            this.$el.toggleClass('open');
            if (this.$el.hasClass('open')) {
                this.dataLayerAnalyticsEvent();
            }
            if (!this.nightsRangeDropdown) {
                this.initNightsRangeDropdown();
            }
            this.nightsRangeDropdown.$refs.dropDownRef.toggle();
        }
    },

    dataLayerAnalyticsEvent() {
        if (this.dataLayerAnalytics) {
            this.dataLayerAnalytics.searchFieldsEvent('nightsOpen');
        }
    },

    modelChanged(event, model, context) {
        if (model === null) {
            this.$el.addClass('disable');
        } else {
            this.$el.removeClass('disable');
            this.setAdditionalLabel({
                from: model.get('from'),
                to: model.get('to'),
            });
            if (!context.modifiedByStore) {
                const store = getStore();
                store.commit(`${NIGHTS_RANGE_STORE}${this.modelPrefix}/setNightsRange`, {
                    from: model.get('from'),
                    to: model.get('to'),
                });
            }
        }
    },

    initNightsRangeDropdown() {
        const store = getStore();
        [this.nightsRangeDropdown] = new Vue({
            name: 'NightsRangeDropdown',
            render: (h) => h(NightsRangeDropdown, {
                props: {
                    modelPrefix: this.modelPrefix,
                },
            }),
            store,
        }).$mount(this.el.querySelector('.formDropdownContent.nights')).$children;
    },

    initNightsRangeObserver() {
        const observer = new MutationObserver((mutations) => {
            if (mutations.some((mutation) => mutation.target.classList.contains('open'))) {
                if (!this.nightsRangeDropdown) {
                    this.initNightsRangeDropdown();
                }
                this.nightsRangeDropdown.$refs.dropDownRef.open();
            }
        });
        const config = { attributes: true };
        observer.observe(this.$el[0], config);
    },

    initDomEvents() {
        $('> .formInput', this.$el).on('click', this.toggleDropdown.bind(this));

        $(document).on('click', (event) => {
            const $dropdowns = this.$el.filter('.open');
            const clickedContainer = $(event.target).parents('.formDropdown');
            const clickedDropdown = $(event.target);
            if (!$(event.target).parents('.formDropdown.nights').length) {
                this.nightsRangeDropdown?.$refs.dropDownRef.close();
            }
            const $hideDropdowns = $dropdowns.not(clickedContainer).not(clickedDropdown);
            $hideDropdowns.removeClass('open');
        });

        this.$el.addClass('disable');
    },

    initModelEvents() {
        this.model.on('change:model', this.modelChanged.bind(this));
    },

    initVuexStore() {
        const store = getStore();
        store.registerModule(`${NIGHTS_RANGE_STORE}${this.modelPrefix}`, NightsRangeStore);
        store.watch((state) => state[`${NIGHTS_RANGE_STORE}${this.modelPrefix}`].nightRange, (state) => {
            this.model.set('model', NightRangeFactory(state), { modifiedByStore: true });
        });
    },
    initialize(options) {
        this.dataLayerAnalytics = options.dataLayerAnalytics;
        this.modelPrefix = options.modelPrefix ? options.modelPrefix : '';
        this.initDomEvents();
        this.initModelEvents();
        this.initNightsRangeObserver();
        this.initVuexStore();
        this.$numOfNights();
    },
});

export default NightRangeView;