/* eslint-disable */
import errorHandlerService from '../Service/errorHandler';
import 'jquery.cookie';

const TourCriteriaObjectValue = Backbone.Model.extend({
	defaults: {
		departureCity:null,
		operators:null,
		countries:null,
		resorts:null,
		checkInDateRange:null,
		nightRange:null,
		touristGroup:null,
		priceRange:null,
		meals:null,
		hotels:null,
		hotelCategories:null,
		hotelAttributes:null,
		hotelTypes:null,
		sort:null,
		warranted: null,
		directFlight: null,
	},
	isValid: function() {
		var tourCriteria = this,
			valid = true;

		if(!tourCriteria.get('departureCity')) {
			errorHandlerService('No departure city');
			valid = false;
		}
		if(!(tourCriteria.get('countries') && tourCriteria.get('countries').length)) {
			errorHandlerService('No country');
			valid = false;
		}
		if(!tourCriteria.get('touristGroup')) {
			errorHandlerService('No touristGroup');
			valid = false;
		} else {
			if(!tourCriteria.get('touristGroup').get('adults')) {
				errorHandlerService('No adults in touristGroup');
				valid = false;
			}
		}

		if(!tourCriteria.get('nightRange')) {
			if(typeof $.cookie !== "undefined") {
				console.log('RegionId:', $.cookie('tA_r_i'));
			}
			errorHandlerService('No nightRange');
			valid = false;
		} else {
			if(!tourCriteria.get('nightRange').get('from') || !tourCriteria.get('nightRange').get('to')) {
				errorHandlerService('No nightRange fields');
				valid = false;
			}
		}

		if(!tourCriteria.get('checkInDateRange')) {
			errorHandlerService('No checkInDateRange');
			valid = false;
		}

		return valid;
	}
});

export default TourCriteriaObjectValue;