module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(countries.length > 1) { 
__p+='\r\n	Для посещения некоторых стран в выбранном списке необходимо оформить визу. <span class="readMore">Подробнее</span>\r\n';
 } else { 
__p+='\r\n	Документы для оформления визы в\r\n	';
 if(totalCountriesCount == 1) { 
__p+='\r\n		эту страну\r\n	';
 } else { 
__p+='\r\n		страну <strong>'+
((__t=(countries[0].get("name") ))==null?'':__t)+
'</strong>\r\n	';
 } 
__p+='\r\n	необходимо подать <strong>не позднее '+
((__t=(countries[0].get("visaDays") ))==null?'':__t)+
'\r\n	'+
((__t=(countries[0].get("visaDays").wordEnding("день;дня;дней") ))==null?'':__t)+
'</strong> до вылета (для граждан РФ). <span class="readMore">Подробнее</span>\r\n';
 } 
__p+='';
}
return __p;
};
