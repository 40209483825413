// bug of old version of TS
// eslint-disable-next-line no-shadow
export enum storeModuleNames {
    MAP_FOR_ROUTES = 'mapForRoutes',
    ROUTES_PARAMS_STORE = 'routesParamsStore',
 }

export const apiURL = '/';

export const endpoints = {
    intelligentSearch: 'intelligentSearch',
};

export const productTypes = {
    ALL: 'all',
    TOUR_OFFER: 'tourOffer',
    RAILWAY_TOUR: 'railwayTour',
    HOTEL_OFFER: 'hotelOffer',
};

export const operators = {
    MY_AGENT: 226,
};
