import { ValuesOf } from '@ve/types/helpers';
import { IFilterAttribute } from '@ve/components/filters/common/types';

// eslint-disable-next-line no-shadow
export enum idFacility {
    lonelyMan = 1,
    bayLocated = 2,
    adultsOnly = 3,
    withKids = 4,
    forCouples = 5,
    airConditioner = 6,
    wifi = 7,
    withAnimals = 8,
    spaAndHealth = 13,
    saunaBathHammam = 15,
    jacuzzi = 19,
    parking = 14,
}

export type keysFacilities = keyof typeof idFacility
export type idsFacilities = ValuesOf<idFacility>;

export type IFilterFacility = IFilterAttribute<idsFacilities, keysFacilities>;