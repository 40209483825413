import { useStore } from 'vuex-composition-helpers';
import { groupBy } from 'underscore';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
import { IFilterApplyingItem } from '../types';

export default function () {
    const store = useStore();
    const applyingHandler = (idsTakenApplyingFilter: number[], applyingFilterItems: IFilterApplyingItem[]) => {
        const filterApplyingItemsByAvailable = applyingFilterItems.filter((applyingItem) => idsTakenApplyingFilter.indexOf(applyingItem.id) !== -1);
        const groupedFilterApplyingProductsByActionName = groupBy(filterApplyingItemsByAvailable, (applyingItem) => applyingItem.nameAction);
        // eslint-disable-next-line no-restricted-syntax
        for (const nameAction in groupedFilterApplyingProductsByActionName) {
            if (Array.isArray(groupedFilterApplyingProductsByActionName[nameAction][0].valueState)) {
                const allValueStateByOneActionName = groupedFilterApplyingProductsByActionName[nameAction].reduce((acc, applyingItem) => {
                    const valueStateArray = applyingItem.valueState as number[];
                    acc.push(...valueStateArray);
                    return acc;
                }, [] as number[]);
                store.dispatch(`${nameFilterSerpStore}/${nameAction}`, allValueStateByOneActionName);
            } else {
                store.dispatch(`${nameFilterSerpStore}/${nameAction}`, groupedFilterApplyingProductsByActionName[nameAction][0].valueState);
            }
        }
    };

    return {
        applyingHandler,
    };
}