
    import { defineComponent } from 'vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import FiltersListSection from '@ve/components/searchFilters/filterList/desktop/FiltersListSection.vue';

    export default defineComponent({
        name: 'SingleListFilter',
        components: {
            FilterListItem,
            FiltersListSection,
        },
    });
