
    import { defineComponent, computed, unref } from 'vue';
    import { IMapForFiltersSerpState, IMapForFiltersSerpActions } from '@ve/components/searchFilters/store/types';
    import { useState, useActions } from 'vuex-composition-helpers';
    import ButtonDesktop from '@ve/components/button/desktop/Button.vue';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
    import wordEnding from '@/js/common/Domain/Service/mixins/wordEnding';

    export default defineComponent({
        components: {
            ButtonDesktop,
        },
        setup() {
            const { syncHotels, isPopularityFilterActive } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['syncHotels', 'isPopularityFilterActive']);
            const { action_isPopularityFilterActive: setPopularityOffersFilter } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_isPopularityFilterActive']);
            const serpTrackingService = new SerpTrackingService();

            const hotelsAllText = computed(() => `Доступно ${unref(syncHotels).length} предложени${wordEnding(unref(syncHotels).length, 'е;я;й')}`);

            const activateAllOffersVisible = () => {
                setPopularityOffersFilter(false);
                serpTrackingService.allOffersClick();
            };

            return {
                isPopularityFilterActive,
                setPopularityOffersFilter,
                activateAllOffersVisible,
                hotelsAllText,
            };
        },
    });

