import { TouristGroup } from '@ve/types';
import Backbone from 'backbone';

export default (model: Backbone.Model): TouristGroup | null => {
    if (!model) return null;
    return {
        adults: model?.get('adults'),
        kids: model?.get('kids'),
        infants: model?.get('infants'),
    };
};
