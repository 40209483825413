export default (tourCriteria) => {
    const params = {};
    const element = tourCriteria.get('touristGroup');
    if (element) {
        const {
            adults, kids, infants, ages,
        } = element.toJSON();

        if (adults) {
            params.adults = adults;
        }
        if (kids) {
            params.kids = kids;
        }
        if (infants) {
            params.infants = infants;
        }
        if (ages?.length) {
            params.ages = ages;
        }
    }

    return params;
};