import { YANDEX_METRIKA_ID } from '@/js/common/Constants/globalParams';
import { IShowMoreRoomInfo } from './types';

type GoalType = 'click' | 'focus' | 'input' | 'other';

export default class AbstractTrackingService {
    protected trackingServicePage = '';

    setTrackingGoal(goal: string, goalType: GoalType, params?: Record<string, any>): void {
        if (!window.ym) {
            return;
        }

        if (params) {
            window.ym(YANDEX_METRIKA_ID, 'reachGoal', goal, params);
        } else {
            window.ym(YANDEX_METRIKA_ID, 'reachGoal', goal);
        }

        console.log(`${goalType} ${goal}`);
    }

    searchFormHotelNameSearchClick(): void {
        this.setTrackingGoal('searchform_hotelname_search', 'click');
    }

    searchFormDestinationFieldAutocomplete(isAutocompleteSuccess: boolean, searchFormDestinationInputText: string): void {
        const params = {
            isAutocompleteSuccess,
            searchFormDestinationInputText,
        };
        this.setTrackingGoal('searchform_destination_field_autocomplete', 'input', params);
    }

    public hotelThClick(isUserAuth: boolean, clickType: string): void {
        this.setTrackingGoal('tourhunter_click', 'click', {
            funnel_step: this.trackingServicePage,
            user_authorization: isUserAuth,
            placement_banner: false,
            type_of_tourhunter: 'hotels',
            add_to_tourhunter: clickType === 'addToTh',
        });
    }

    protected bannerThTracking(isUserAuth: boolean, subscribeType: 'region' | 'hotels'): void {
        this.setTrackingGoal('tourhunter_click', 'click', {
            funnel_step: this.trackingServicePage,
            user_authorization: isUserAuth,
            placement_banner: true,
            type_of_tourhunter: subscribeType,
            add_to_tourhunter: true,
        });
    }

    public trackAuth(): void {
        this.setTrackingGoal('login', 'click');
    }

    showMoreRoomInfo(params?: IShowMoreRoomInfo): void {
        this.setTrackingGoal('show_more_room_info', 'click', params);
    }
}