export default Backbone.Epoxy.Model.extend({
    defaults: {
        id: null,
        type: null,
        price: null,

        discount: null,
        description: null,
        hash: null,
        firstPayment: 1,
        medicalInsurance: null,
        transferType: null,
        tourCriteria: null,
        actualizeInProgress: false,
        firstPaymentDefinition: null,
        isExclusive: false,
        nights: null,
        nightsInHotel: null,
        tour: null,
        hotelOffer: null,
        railwayTour: null,
        similarProducts: [],
    },
    computeds: {
        tourProducts: {
            deps: ['similarProducts'],
            get: (similarProducts) => _.filter(similarProducts, (similarProduct) => similarProduct.get('type') === 'TourProduct'),
        },
        hotelOfferProducts: {
            deps: ['similarProducts'],
            get: (similarProducts) => _.filter(similarProducts, (similarProduct) => similarProduct.get('type') === 'HotelOfferProduct'),
        },
        railwayProducts: {
            deps: ['similarProducts'],
            get: (similarProducts) => _.filter(similarProducts, (similarProduct) => similarProduct.get('type') === 'RailwayTourProduct'),
        },
        cheapestTourProduct: {
            deps: ['tourProducts'],
            get(tourProducts) {
                if (this.get('type') === 'TourProduct') {
                    return this;
                }
                return tourProducts?.[0] || null;
            },
        },
        cheapestHotelOfferProduct: {
            deps: ['hotelOfferProducts'],
            get(hotelOfferProducts) {
                if (this.get('type') === 'HotelOfferProduct') {
                    return this;
                }
                return hotelOfferProducts?.[0] || null;
            },
        },
        cheapestRailwayProduct: {
            deps: ['railwayProducts'],
            get(railwayProducts) {
                if (this.get('type') === 'RailwayTourProduct') {
                    return this;
                }
                return railwayProducts?.[0] || null;
            },
        },
        product: {
            deps: ['tour', 'hotelOffer', 'railwayTour'],
            get(tour, hotelOffer, railwayTour) {
                return tour || hotelOffer || railwayTour || null;
            },
        },
        dateFrom: {
            deps: ['product'],
            get: (product) => product.get('checkInDateRange').get('from').getTime(),
        },
        freeCancellationHotelOffer: {
            deps: ['hotelOfferProducts', 'hotelOffer'],
            get(hotelOfferProducts, hotelOffer) {
                return !!this.getCancellationPolicy(hotelOffer)?.freeCancellationDateBefore
                    || !!_.find(hotelOfferProducts, (similarHotelOffer) => !!this.getCancellationPolicy(similarHotelOffer)?.freeCancellationDateBefore);
            },
        },
    },
    getCancellationPolicy: (product) => (product?.get('hotelOffer') || product)?.get('cancellationPolicy'),
});