export default Backbone.Epoxy.Model.extend({
    defaults: {
        id: null,
        price: null,
        checkInDate: null,
        hotelAvailable: null,
        medicalInsurance: null,
        maxHistoricalPrice: null,
        nights: null,
        touristGroup: null,
        meal: null,
        country: null,
        resort: null,
        hotel: null,
        hotelCategory: null,
        operator: null,
        sortRate: null,
        firstPaymentDefinitions: null,
        cashbackAvailable: null,
        discount: null,
        room: null,
        cancellationPolicy: null,
    },

    computeds: {
        startDate: {
            deps: ['checkInDate'],
            get: (checkInDate) => checkInDate,
        },
        paymentCashBackHotel: {
            deps: ['price'],
            get(price) {
                const maxCashBackMir = 20000;
                const paymentCashBackMirHotel = Math.ceil(price * 0.8);
                const cashBackMir = price - paymentCashBackMirHotel;
                return (cashBackMir > maxCashBackMir) ? price - maxCashBackMir : paymentCashBackMirHotel;
            },
        },
    },
});
