import { watch } from 'vue';
import {
    useState, useActions,
} from 'vuex-composition-helpers';
import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
import useFilterHotelsModel from './useFilterHotelsModel';

export default function () {
    const {
        childServiceFilter,
        setChildServiceFilter,
        setRestTypeFilter,
        restTypeFilter,
        setBeachTypeFilter,
        beachTypeFilter,
    } = useFilterHotelsModel();
    const {
        childServiceFilterIds: takenServiceChildBackbone,
        restType: restTypeFilterIdBackbone,
        takenBeachType: takenBeachTypeBacbkone,
    } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['childServiceFilterIds', 'restType', 'takenBeachType']);
    const {
        action_childServiceFilterIds: setServiceChildBackbone,
        action_restType: setRestTypeFilterBackbone,
        action_takenBeachType: action_takenBeachTypeBacbkone,
    } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_childServiceFilterIds', 'action_restType', 'action_takenBeachType']);

    watch(childServiceFilter, (value) => {
        setServiceChildBackbone(value);
    });
    watch(takenServiceChildBackbone, (value) => {
        setChildServiceFilter(value);
    }, { immediate: true });

    watch(restTypeFilter, (value) => {
        setRestTypeFilterBackbone(value);
    });

    watch(restTypeFilterIdBackbone, (value) => {
        setRestTypeFilter(value || 0);
    }, { immediate: true });

    watch(takenBeachTypeBacbkone, (value) => {
        setBeachTypeFilter(value);
    }, { immediate: true });

    watch(beachTypeFilter, (value) => {
        action_takenBeachTypeBacbkone(value);
    });
}