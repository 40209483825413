import { HotelCategoryForFilter } from '@ve/components/filters/hotelCategoriesFilter/common/types';

export default [
    {
        id: 7,
        stars: 5,
        label: '5 звёзд',
    },
    {
        id: 4,
        stars: 4,
        label: '4 звезды',
    },
    {
        id: 3,
        stars: 3,
        label: '3 звезды',
    },
    {
        id: 2,
        stars: 2,
        label: '1—2 звезды',
    },
    {
        id: 0,
        stars: 0,
        label: 'Без звезд',
    },
] as HotelCategoryForFilter[];