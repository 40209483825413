
    import { defineComponent, computed, unref } from 'vue';
    import SendAnalytics from '@ve/services/SendAnalytics';
    import useFilterAttributesHotel from '@ve/components/filters/common/use/useFilterAttributesHotel';
    import FilterListRender from '@ve/components/filters/common/components/FilterListRender/FilterListRender.vue';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import SingleListFilter from '@ve/components/searchFilters/filterList/desktop/SingleListFilter.vue';
    import useLogicRestType from '@ve/components/filters/common/use/useLogicRestType';
    import FilterAttributeBeachOwnerMethods from './filterMethods/FilterAttributeBeachOwnerMethods';

    export default defineComponent({
        name: 'BeachOwnerFilterWrapper',
        components: {
            FilterListRender,
            FilterPostWrapper,
            SingleListFilter,
        },
        setup() {
            const {
                selectedAttributes,
                filteredHotels,
                syncHotels,
                isPopularityFilterActive,
            } = useFilterAttributesHotel('BeachFilter');

            const sendAnalyticsBeachOwner = () => SendAnalytics('sideBarFilter', { beach: 'beach_owner' });

            return {
                selectedAttributes,
                filteredHotels,
                syncHotels,
                sendAnalyticsBeachOwner,
                FilterAttributeBeachOwnerMethods,
                isPopularityFilterActive,
                ...useLogicRestType(FilterAttributeBeachOwnerMethods),
            };
        },
    });
