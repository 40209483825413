import { IOperator } from '../types/IOperator';
import Operator from './Operator';

export interface IOperatorState {
    operators: IOperator[];
}

export interface IOperatorMutations {
    setOperators(state: IOperatorState, operators: IOperator[] | undefined): void;
    resetOperators(state: IOperatorState): void;
}

const operatorStore = {
    namespaced: true,
    state: {
        operators: [],
    },
    mutations: {
        setOperators(state: IOperatorState, operators: IOperator[] | undefined, imagesDomain: string): void {
            const operatorEntitiesIds = state.operators.map((operator) => operator.id);
            const operatorsWithoutCurrentOperators = operators?.filter((operator) => operatorEntitiesIds.indexOf(operator.id) === -1) || [];
            const operatorsEntity = operatorsWithoutCurrentOperators.map((operator) => new Operator(operator, imagesDomain));
            if (operatorsEntity.length) {
                state.operators = [...state.operators, ...operatorsEntity];
            }
        },
        resetOperators(state: IOperatorState): void {
            state.operators = [];
        },
    },
    getters: {
        getEntities: (state: IOperatorState): IOperator[] => state.operators,
    },
};

export const NAMESPACE = 'OperatorRepository';

export default operatorStore;
