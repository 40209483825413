/* eslint-disable */
import moment from 'moment';
import 'moment/locale/ru';
import ResortsRepository from '../Repository/Resorts';
import HotelCategoriesRepository from '../Repository/HotelCategories';

let processor;
const sociomanticManager = (function (d, w) {
    const debug = false;
    let sociomanticAvailable = false;

    const checkDomainName = function () {
        const availableDomain = 'travelata.ru';
        const currentHost = document.location.hostname;

        if (~currentHost.indexOf(availableDomain)) {
            sociomanticAvailable = true;
        }
    };

    const startTrack = function () {
        checkDomainName();

        const token = w.sociomanticToken || null;
        processor = typeof sociomantic === 'object'
        && typeof w.sociomantic.sonar === 'object'
        && typeof w.sociomantic.sonar.adv === 'object'
        && typeof w.sociomantic.sonar.adv[token] === 'object' ? window.sociomantic.sonar.adv[token] : {
            clear() {
            },
            track() {
            },
        };
    };

    const initialize = function () {
        const token = 'travelata-ru';
        const s = d.createElement('script');
        const x = d.getElementsByTagName('script')[0];

        s.async = true;
        s.src = `${d.location.protocol === 'https:' ? 'https://' : 'http://'
        }eu-sonar.sociomantic.com/js/2010-07-01/adpan/${token}`;

        s.onload = s.onreadystatechange = function () {
            startTrack();
        };
    };

    const notifyNewProduct = function (product) {
        if (sociomanticAvailable) {
            processor.clear();
            w.product = product;
            if (debug) {
                console.log(w.product);
            }
            processor.track();
        } else if (typeof console !== 'undefined' && typeof console.log === 'function') {
            console.log('sociomantic Call emulate:', product);
        }
    };

    const notifyLead = function (transactionId) {
        const lead = { transaction: transactionId };
        if (sociomanticAvailable) {
            processor.clear();
            w.lead = lead;
            if (debug) {
                console.log(w.lead);
            }
            processor.track();
        } else if (typeof console !== 'undefined' && typeof console.log === 'function') {
            console.log('sociomantic Call emulate:', lead);
        }
    };

    const notifySearch = function (country, city) {
        if (sociomanticAvailable) {
            processor.clear();
            let query = `Тур в ${country}`;
            if (city.length > 0) {
                query += `, ${city}`;
            }

            w.search = {
                country,
                city,
                type: 1,
                query,
            };

            if (debug) {
                console.log(w.search);
            }
            processor.track();
        } else if (typeof console !== 'undefined' && typeof console.log === 'function') {
            console.log('sociomantic Call emulate:', country, city);
        }
    };

    var getWeekOfYear = function (searchDate) {
        // Copy date so don't modify original
        d = new Date(searchDate);
        //d.setHours(0,0,0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        const yearStart = new Date(d.getFullYear(), 0, 1);
        // Calculate full weeks to nearest Thursday
        const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    };

    const notifyCategory = function (departureCity, country, city, searchDate) {
        if (sociomanticAvailable) {
            processor.clear();

            const category = [departureCity, country];

            if (city.length > 0) {
                category.push(city);
            }

            category.push(getWeekOfYear(searchDate.getTime()));

            w.product = {
                category,
                date: Math.round(searchDate.getTime() / 1000),
            };

            if (debug) {
                console.log(w.product);
            }
            processor.track();
        } else if (typeof console !== 'undefined' && typeof console.log === 'function') {
            console.log('sociomantic Call emulate:', departureCity, country, city, searchDate);
        }
    };

    const notifyCheckout = function (productId, price) {
        if (sociomanticAvailable) {
            processor.clear();
            w.basket = {
                products: [
                    {
                        identifier: productId,
                        amount: price,
                        currency: 'RUB',
                        quantity: 1,
                    },
                ],
            };
            if (debug) {
                console.log(w.basket.products);
            }
            processor.track();
        } else if (typeof console !== 'undefined' && typeof console.log === 'function') {
            console.log('sociomantic Call emulate:', productId, price);
        }
    };

    const notifyNewSell = function (productId, price, trnId) {
        if (sociomanticAvailable) {
            processor.clear();
            w.basket = {
                products: [
                    {
                        identifier: productId,
                        amount: price,
                        currency: 'RUB',
                        quantity: 1,
                    },
                ],
                transaction: trnId,
                amount: price,
                currency: 'RUB',
            };

            if (debug) {
                console.log(w.basket);
                console.log(w.basket.products);
            }
            processor.track();
        } else if (typeof console !== 'undefined' && typeof console.log === 'function') {
            console.log('sociomantic Call emulate:', productId, price, trnId);
        }
    };

    const getHotelDescription = function () {
        const description = jQuery('article').find('.content-inner').first().text()
            .replace(/^\s+|\s+$/g, '');
        return description.length > 90 ? `${description.substr(0, 87)}...` : description;
    };

    const getUTCDate = function (date) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    initialize();

    return {
        notifyHotelPage(tourProduct, searchCriteria) {
            const checkInDateRange = searchCriteria.get('checkInDateRange');
            const product = tourProduct.get('product');
            const hotelName = window.appConfig.hotelInfo.name;
            const departureCityName = searchCriteria.get('departureCity').get('name');
            const resort = ResortsRepository.repository.at(0);
            const resortName = resort.get('name');
            const countryName = resort.get('country').get('name');
            const hotelCategoryName = HotelCategoriesRepository.repository.get(window.appConfig.hotelInfo.category).get('name');
            const touristGroup = product.get('touristGroup');
            const numOfPeople = parseInt(touristGroup.adults, 10) + parseInt(touristGroup.kids, 10);
            const price = product.get('price');
            const originalPrice = product.get('originalPrice');
            const amount = Math.round((originalPrice && originalPrice > price ? originalPrice : price) / numOfPeople);
            const packageDateFrom = product.get('checkInDateRange').get('from');
            const url = `${document.location.protocol}//${document.location.host}${document.location.pathname}#?dateFrom=${moment(checkInDateRange.get('from')).format('DD.MM.YYYY')}&dateTo=${moment(checkInDateRange.get('to')).format('DD.MM.YYYY')}`;

            const notifyProduct = {
                identifier: tourProduct.get('id'),
                price: Math.round(price / numOfPeople),
                brand: `${hotelName} ${hotelCategoryName}, ${resortName}`,
                amount,
                currency: 'RUB',
                category: [departureCityName, countryName, resortName, getWeekOfYear(getUTCDate(checkInDateRange.get('from')).getTime())],
                fn: `${hotelName} ${hotelCategoryName}`,
                description: `Купите тур в ${hotelName} ${hotelCategoryName}, ${resortName}. Лучшие цены, удобный сервис, поторопитесь!`,
                url,
                photo: window.hotelPhoto,
                date: Math.round(getUTCDate(packageDateFrom).getTime() / 1000),
                valid: Math.round(getUTCDate(packageDateFrom).getTime() / 1000),
            };

            notifyNewProduct(notifyProduct);
        },
        notifyCategory(tourCriteria) {
            const departureCity = tourCriteria.get('departureCity').get('name');
            const country = tourCriteria.get('countries').at(0).get('name');
            const city = tourCriteria.get('resorts') && tourCriteria.get('resorts').length ? tourCriteria.get('resorts').at(0).get('name') : '';
            const searchDate = getUTCDate(tourCriteria.get('checkInDateRange').get('from'));
            notifyCategory(departureCity, country, city, searchDate);
        },
        notifyNewCheckout(productId, amount) {
            notifyCheckout(productId, amount);
        },
        notifyNewLead(transactionId) {
            notifyLead(transactionId);
        },
        notifyNewSell(productId, amount, trnId) {
            notifyNewSell(productId, amount, trnId);
        },
    };
}(document, window));

export default sociomanticManager;
