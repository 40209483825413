
    import {
        defineComponent, PropType,
    } from 'vue';
    import Tooltip from '@ve/components/tooltip/Tooltip.vue';

    export default defineComponent({
        name: 'hint-tooltip',
        props: {
            typeIcon: {
                type: String as PropType<'gray' | 'pink'>,
                default: 'gray',
            },
            typeTooltip: {
                type: String as PropType<'white' | 'black'>,
                default: 'white',
            },
            contentPosition: {
                type: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
                default: 'top',
            },
        },
        components: {
            Tooltip,
        },
    });
