import '@/js/common/plugins/polyfill';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_ru';

import '@/css/desktop/layout.scss';

import '@/js/common/Domain/Service/disableConsole';
import setGaAb from '@/js/common/Domain/Service/setGaAb';
import '@/js/common/plugins/jquery-ui';
import '@/js/common/plugins/trackjs';
import '@/js/common/Domain/Service/tracking/trackingUserVisit';
import '@/js/common/Domain/Service/tracking/trackingCoreWebVitals';
import '@/js/common/Domain/Service/mixins/mixins';
import '@/js/common/Domain/Model/ExtendEpoxyView';
import '@/js/common/Domain/Model/BackboneMapState';
import '@/js/desktop/plugins/jquery.serialize-object.min';
import { globalTrackingErrorsVue } from '@app/lib';
import './common';

globalTrackingErrorsVue();

setGaAb();