
    import {
        computed,
        defineComponent,
        PropType,
        toRefs,
        unref,
    } from 'vue';
    import { TSizesInputCommon } from '@ve/components/inputs/common/index';
    import { asyncSvgLoader } from '@shared/lib';
    import { IconBase } from '@shared/ui';
    import checkBoxIcon from './img/checkbox.svg';
    import checkBoxDisabled from './img/disabledSvg.svg';
    import checkBoxIconMedium from './img/checkboxMedium.svg';

    export default defineComponent({
        components: {
            IconBase,
            checkBoxIconDisabled: asyncSvgLoader(checkBoxDisabled),
            checkBoxIconSmall: asyncSvgLoader(checkBoxIcon),
            checkBoxIconMedium: asyncSvgLoader(checkBoxIconMedium),
        },
        props: {
            isChecked: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isDisabledIcon: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: '',
            },
            value: {
                type: [String, Boolean, Number],
                required: true,
            },
            hideOverflowText: {
                type: Boolean,
                default: true,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
            isWhiteSpace: {
                type: Boolean,
                default: true,
            },
            checkBoxSize: {
                type: String as PropType<TSizesInputCommon>,
                default: 'small',
            },
        },
        setup(props, { emit }) {
            const { value } = toRefs(props);
            const updateInput = (event: any) => {
                emit('change', event);
                emit('selected', { value: unref(value), checked: event.target.checked });
            };
            const iconClassList = computed(() => ({
                'checkbox-item-common__icon-disabled': props.disabled,
                'checkbox-item-common__icon_hover': props.isHover,
                'checkbox-item-common__icon-small': props.checkBoxSize === 'small',
                'checkbox-item-common__icon-medium': props.checkBoxSize === 'medium',
            }));
            const iconSize = computed(() => (props.checkBoxSize === 'medium' ? 20 : 16));

            return {
                updateInput,
                iconClassList,
                iconSize,
            };
        },
    });
