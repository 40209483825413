import trackingStartSearchDataHydrator from './trackingStartSearchData';

const trackingStartEmtySearchData = async (options) => {
    const trackingStartEmptySearchData = {};
    const { originalSearchUuid } = options;
    const { productType } = options.searchOptions;
    const trackingStartSearchData = await trackingStartSearchDataHydrator(options);

    _.extend(trackingStartEmptySearchData, {
        originalSearchUuid,
        productType,
    }, trackingStartSearchData);

    return trackingStartEmptySearchData;
};

export default trackingStartEmtySearchData;