/* eslint-disable */
import './banners.css';
import './bannerTypes.css';
import wildcardDomain from "@/js/common/Domain/Service/wildcardDomain";

let BannerComponent = Backbone.View.extend({
    Banner: {
        options: {
            bannerWith: 700,
            overlayClick: true,
            condition: function () {
                // функция которая проверяет условие для показа модального окна
                return true
            },
            $selector: ".js-popupBanner",
            showConfirmMessage: false,
            formValidate: {
                errorElement: 'div',
                errorClass: 'error',
                rules: {
                    email: {
                        required: true,
                        email: true
                    }
                },
                messages: {
                    email: {
                        required: "Обязательное поле",
                        email: "Пожалуйста, введите действительный адрес электронной почты. Например johndoe@domain.com"
                    }
                }
            }
        },
        couponBannerTemplate: '<div class="js-popupBanner">\n\t<div class="popupClose js-banner-close"><i class="icon icon-i16_x"></i></div>\n\t<figure></figure>\n\t<p class="couponBanner_presentsText">Подарки любимым туристам!</p>\n\t<p class="couponBanner_presentsTextHeader">Вжух, и вам купон!</p>\n\t<p class="couponBanner_discountText">Cкидка 600 рублей</p>\n\t<p class="couponBanner_discountNote">При бронировании тура, стоимостью от 30 000 руб.</p>\n\t<p class="couponBanner_bookingText">Бронируйте тур по лучшей цене!</p>\n\t<p class="couponBanner_couponCode">600Trav18</p>\n</div>',
        init: function () {
            var banner = this,
                inputName,
                btnClass = "",
                bannerContent;

            switch (banner.options.dialogClass) {
                case "takeCouponPopup" :
                    bannerContent = banner.couponBannerTemplate;
                    break;
            }

            $("body").append(bannerContent);

            $(banner.options.$selector).correctPopupPosition().dialog({
                dialogClass: banner.options.dialogClass + " banner",
                width: banner.options.bannerWith,
                resizable: false,
                open: function () {
                    if (banner.options.overlayClick) {
                        $('.ui-widget-overlay').on("click", function (e) {
                            if ($(e.target).is('.ui-widget-overlay')) banner.close();
                        })
                    }
                }
            }).dialog("open").on("click",".js-banner-close", $.proxy(banner.close, banner)).end()
                .find("form").on("submit", $.proxy(banner.validation, banner));
        },
        close: function () {
            $(this.options.$selector).dialog("close");
            this.setCookie()
        },
        validation: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var banner = this,
                $form = $(banner.options.$selector).find("form");
            $form.validate(banner.options.formValidate);
            if ($form.valid() && !$form.hasClass('sending')) {
                $form.addClass('sending');
                $.ajax({
                    url: banner.options.action,
                    method: "POST",
                    data: $form.serialize()
                }).success(function (data) {
                    banner.nextStep(data.success);
                    banner.setCookie();
                    $form.removeClass('sending');
                }).error(function () {
                    banner.nextStep(false);
                    $form.removeClass('sending');
                })
            }
        },
        nextStep: function (success) {
            var $window = $(this.options.$selector),
                result = ".compile-result.";
            $window.find("input").hide();
            result += success ? "complete" : "no-valid";
            if(this.options.showConfirmMessage && result){
                this.showConfirmWindow()
            }else {
                $window.find(result).show();
                if (result === ".compile-result.no-valid") {
                    $window.find(result).on("click", function () {
                        $window.find("input").show()
                            .end().find(result).hide();
                    })
                }
            }

        },
        setCookie: function () {
            $.cookie(this.options.cookieName, 1, {path: '/', expires: 999, domain: wildcardDomain()});
        },
        showConfirmWindow: function () {
            var $window = $(this.options.$selector);
            $window.dialog({
                width: 464,
                height: 226
            })
                .empty()
                .html("<div class=\'popupClose js-banner-close\'><i class=\'icon icon-i16_x\'></i></div>\n<div class=\'tanksBanner\'>\n    <h1>Спасибо!</h1>\n    <p>Подтвердите, пожалуйста, подписку в письме, которое мы отправили на указанный email</p>\n    <button class=\'btn btnOrange js-banner-close\'>OK</button>\n</div>\n");
        }
    },
    initialize: function (options) {
        var banner = this.Banner;
        $.extend(banner.options, options);
        if (banner.options.condition()) {
            banner.init();
        }
    }
});

export default BannerComponent;
