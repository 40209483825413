/* eslint-disable */
const TouristGroupObjectValue = Backbone.Model.extend({
	defaults: {
		adults: null,
		kids: 0,
		infants:0,
		ages: [],
	}
});

export default TouristGroupObjectValue;