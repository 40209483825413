module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cls-itu-range">\n    ';
 var datesAmount = calendarData.length,
    selectedDate = selectedDates.get('from').getTime() == selectedDates.get('to').getTime() ? selectedDates.get('from'): 0;
    
__p+='\n\n    ';
_.each(calendarData, function(dateData, index) { 
__p+='\n\n    <div class="cls-itu-column'+
((__t=( !today.diff(dateData.date) ? ' disabled' : ''))==null?'':__t)+
' '+
((__t=( !dateData.price ? ' not-price' : ''))==null?'':__t)+
''+
((__t=(selectedDate && selectedDate.getTime() == dateData.date._d.getTime()  ? ' current' : '' ))==null?'':__t)+
'" data-date="'+
((__t=(dateData.date.format('YYYY-MM-DD') ))==null?'':__t)+
'" data-price="'+
((__t=(dateData.price.formatNumber()))==null?'':__t)+
'">\n        <div class="cls-display">\n            ';
 if(dateData.price) { 
__p+='\n            ';
 var percent = (calendarMaxPrice != calendarMinPrice) ? ((dateData.price -
            calendarMinPrice)*100/(calendarMaxPrice - calendarMinPrice)) : 50;
            var columnType = "";
            if(dateData.price == calendarMinPrice){
            columnType = "cls-col-minPrice"
            }else if(dateData.price == calendarMaxPrice){
            columnType = "cls-col-maxPrice"
            }
            
__p+='\n\n            <div class="cls-d-ind '+
((__t=(columnType))==null?'':__t)+
'" style="height:'+
((__t=(percent))==null?'':__t)+
'%;">\n                <div class="cls-d-ind__tip">\n                    От '+
((__t=(dateData.price.formatNumber() ))==null?'':__t)+
' <span class="rubl">a</span>\n                </div>\n            </div>\n            ';
 } else { 
__p+='\n\n            <div class="cls-d-no-price  '+
((__t=( ~emptyDates.indexOf(dateData.date.format('YYYY-MM-DD')) ? 'cls-d-have-not-tours' : ''))==null?'':__t)+
'"></div>\n            ';
 } 
__p+='\n        </div>\n        <div class="cls-value'+
((__t=(_.contains([6,7], parseInt(dateData.date.format('E'), 10)) ? ' weekend' : '' ))==null?'':__t)+
'">\n            '+
((__t=(dateData.date.format('D') ))==null?'':__t)+
', '+
((__t=(dayOfWeek[dateData.date.format('e')] ))==null?'':__t)+
'\n        </div>\n    </div>\n\n\n    ';
 if(index < (datesAmount-1)) { 
__p+='\n    ';
 if(dateData.date.format('M') != calendarData[index+1].date.format('M')) { 
__p+='\n    <div class="cls-itd-range-label"><span>'+
((__t=(dateData.date.format('MMMM YYYY') ))==null?'':__t)+
'</span></div>\n</div>\n<div class="cls-itu-range">\n    ';
 } 
__p+='\n    ';
 } else if(index == (datesAmount-1)) { 
__p+='\n    <div class="cls-itd-range-label"><span>'+
((__t=(dateData.date.format('MMMM YYYY') ))==null?'':__t)+
'</span></div>\n    ';
 } 
__p+='\n    ';
 }) 
__p+='\n</div>';
}
return __p;
};
