var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"checkbox-item-common",class:{
        'checkbox-item-common_medium': _vm.checkBoxSize === 'medium',
        'checkbox-item-common_disabled': _vm.disabled,
        'checkbox-item-common_white-space': _vm.isWhiteSpace,
    },attrs:{"title":_vm.label}},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.isChecked,"value":_vm.value},on:{"change":_vm.updateInput}}),_vm._v(" "),_c('div',{staticClass:"checkbox-item-common__icon",class:_vm.iconClassList},[_c('span',{staticClass:"icon"},[_c('icon-base',{staticClass:"checkBox-icon-base",attrs:{"width":_vm.iconSize,"height":_vm.iconSize}},[(_vm.isDisabledIcon)?[(_vm.isDisabledIcon)?_c('check-box-icon-disabled'):_vm._e()]:[(_vm.checkBoxSize === 'medium')?_c('check-box-icon-medium'):_vm._e(),_vm._v(" "),(_vm.checkBoxSize === 'small')?_c('check-box-icon-small'):_vm._e()]],2)],1)]),_vm._v(" "),_c('span',{staticClass:"checkbox-item-common__text",class:{
            'checkbox-item-common__text_hide-overflow-text': _vm.hideOverflowText,
        }},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }