import { useState, useMutations, useGetters } from 'vuex-composition-helpers';
import { computed, unref } from 'vue';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import TourProductsRepository, {
    NAMESPACE,
    ITourProductsRepositoryMutations,
    ITourProductsRepositoryState,
} from '../model/TourProductsRepository';

export default function () {
    registerModule.register(getStore(), TourProductsRepository, NAMESPACE);
    const { tourProducts } = useState<ITourProductsRepositoryState>(NAMESPACE, ['tourProducts']);
    const { setTourProducts, resetTourProducts } = useMutations<ITourProductsRepositoryMutations>(NAMESPACE, ['setTourProducts', 'resetTourProducts']);

    return {
        tourProducts,
        resetTourProducts,
        setTourProducts,
    };
}