import type { EmptySearchCurrentState } from '@ve/components/emptySearch/common/store/types/IEmptySearchStore';
import fromAlternativeSearch from '@ve/components/emptySearch/common/store/stateTransitions/fromAlternativeSearch';

export default ({
    hasTours,
    loadingStateIsFinished,
    prevDepartureCityIsDifferentFromCurrentDepartureCity,
    hasCalendarMinPriceDate,
    searchWithFlexibleDates,
    hasPriceCalendar,
}: {
    loadingStateIsFinished: boolean,
    hasTours: boolean;
    prevDepartureCityIsDifferentFromCurrentDepartureCity: boolean;
    hasCalendarMinPriceDate: boolean;
    searchWithFlexibleDates: boolean;
    hasPriceCalendar: boolean;
}): EmptySearchCurrentState | null => {
    if (hasTours) {
        return 'userSearchHasTours';
    }

    if (loadingStateIsFinished) {
        if (prevDepartureCityIsDifferentFromCurrentDepartureCity) {
            return 'alternativeSearch';
        }

        return fromAlternativeSearch({
            hasTours,
            loadingStateIsFinished,
            hasCalendarMinPriceDate,
            searchWithFlexibleDates,
            hasPriceCalendar,
        });
    }

    return null;
};