/* eslint-disable */
import differencesTourCriteria from "../differencesTourCriteria";
import generateIdService from "../generateId";

var SearchSessionModel = Backbone.Model.extend({
	defaults: {
		searchId: 0,
		hotelSearchId:0
	}
});

let searchSession = {
	model: new SearchSessionModel(),
	getSearchId: function () {
		return this.model.get('searchId');
	},
	setSearchId: function (searchId) {
		this.model.set('searchId', searchId);
	},
	getHotelSearchId: function () {
		return this.model.get('hotelSearchId');
	},
	setHotelSearchId: function (hotelSearchId) {
		this.model.set('hotelSearchId', hotelSearchId);
	},
	generateNewSearchId: function () {
		var searchId = generateIdService({
			length: 10
		});

		this.model.set('searchId', searchId);

		return searchId;
	},
	generateNewHotelSearchId: function () {
		var hotelSearchId = generateIdService({
			length: 10
		});

		this.model.set('hotelSearchId', hotelSearchId);

		return hotelSearchId;
	},
	changeSearchIdForNewSearch: function (options) {
		var oldSearchCriteria = options.oldSearchCriteria,
			newSearchCriteria = options.newSearchCriteria;

		if (differencesTourCriteria({
				oldSearchCriteria: oldSearchCriteria,
				newSearchCriteria: newSearchCriteria,
				differenceList: ["departureCity", "countries", "hotels", "resorts", "checkInDateRange", "nightRange", "touristGroup", 'meals', 'hotelCategories']
			})) {
			this.generateNewSearchId();
		}
	},
	changeHotelSearchIdForNewSearch: function (options) {
		var oldSearchCriteria = options.oldSearchCriteria,
			newSearchCriteria = options.newSearchCriteria;

		if (differencesTourCriteria({
			oldSearchCriteria: oldSearchCriteria,
			newSearchCriteria: newSearchCriteria,
			differenceList: ["departureCity", "checkInDateRange", "nightRange", "touristGroup", 'meals', 'hotelCategories']
		})) {
			this.generateNewHotelSearchId();
		}
	}
};

export default searchSession;