/* eslint-disable */
import { TrackJS } from 'trackjs';

/**
 * Сервис для мока localStorage, в случае, если он недоступен.
 * Проблемы связаны с приватным режимом и в целом, если недоступен local Storage.
 * @type {{set: (function(): boolean), get: (function(): undefined)}}
 */

let ls = {
    get: () => undefined,
    set: () => true,
};

try {
    ls = require('local-storage');
} catch (error) {
    console.warn('localStorage is not available now');
    TrackJS.track('Tracking: localStorage is not available now');
}

export default ls;
