/* eslint-disable */
import ADVCakeHydrator from "../Hydrator/ADVCake";

window.advcake_callback = window.advcake_callback || function(){};
let advcake_data;

let ADVCakeService = function(pageType) {
    advcake_data = {pageType:pageType};

    this.updateSearchData = function(tourCriteria, price) {
        var trackData = ADVCakeHydrator(tourCriteria);

        $.extend(advcake_data, {search:trackData});

        if(pageType == "hotel" && advcake_data['hotel'] && price) {
            advcake_data['hotel']['price'] = price;
        }

        advcake_callback(advcake_data);
    };

    if((pageType == "country" || pageType == "hotel") && typeof window.ADVCakeData !== "undefined") {
        $.extend(advcake_data, window.ADVCakeData);
    }

    advcake_callback(advcake_data);
};

export default ADVCakeService;