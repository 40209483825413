import Cookies from 'js-cookie';
import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';
import axios from 'axios';

import { IHotelOfferTariff } from '@ve/components/hotel/hotelOffersTariffsPopup/common/types';
import MindBoxDataHydrator from './MindBoxDataHydrator';
import { ItrackViewHotel } from './MindBoxTypes';

interface IMindBoxServiceConstruct {
    webPushEndpointId: string
    endpointId: string
    pointOfContactSystemName: string
    firebaseMessagingSenderId: string
}

interface ExWindow extends Window {
    mindbox: any
}

type TSerpCategory = 'serp_main' | 'serp_hotel' | 'serp_hot' | 'serp_railway';

declare let window: ExWindow;

export default abstract class MindBoxService {
    public mindBoxTrackTimeoutHotelPage = 5000;

    public mindBoxDebounceTimeHotelPage = 2000;

    private endpointId: string;

    private webPushEndpointId: string;

    private pointOfContactSystemName: string;

    private firebaseMessagingSenderId: string;

    private mindboxDeviceUUID: string | undefined;

    protected hydratorService: MindBoxDataHydrator;

    private shouldTrackTourPage = true;

    constructor(params: IMindBoxServiceConstruct) {
        this.endpointId = params.endpointId;
        this.webPushEndpointId = params.webPushEndpointId;
        this.pointOfContactSystemName = params.pointOfContactSystemName;
        this.firebaseMessagingSenderId = params.firebaseMessagingSenderId;

        this.hydratorService = new MindBoxDataHydrator();
        this.initMindBox();
        this.initWebPushAgreement();
    }

    private initMindBox(): void {
        const mindbox = window.mindbox || function () {
            // eslint-disable-next-line prefer-rest-params
            window.mindbox.queue.push(arguments);
        };
        window.mindbox = mindbox;
        mindbox.queue = mindbox.queue || [];

        window.mindbox('create', {
            endpointId: this.webPushEndpointId,
            pointOfContactSystemName: this.pointOfContactSystemName,
            firebaseMessagingSenderId: this.firebaseMessagingSenderId,
        });

        const mindScript = document.createElement('script');
        mindScript.src = 'https://api.mindbox.ru/scripts/v1/tracker.js';
        mindScript.async = true;
        document.getElementsByTagName('head')[0].appendChild(mindScript);
    }

    private initWebPushAgreement(): void {
        const isNotificationWindowWasShow = Cookies.get('isNotificationWindowWasShow') === '1';
        const timeOutAppearanceOfNotification = 3000;

        if (!isNotificationWindowWasShow) {
            setTimeout(
                () => {
                    const mindboxWindow = window.mindbox;
                    mindboxWindow('webpush.create');
                    mindboxWindow('webpush.migrate', {
                        migrateOperation: 'MigrateWebPush',
                    });
                    mindboxWindow('webpush.subscribe', {
                        getSubscriptionOperation: 'GetWebPushSubscription',
                        subscribeOperation: 'SubscribeToWebpush',
                        onGranted() {
                            Cookies.set('isNotificationWindowWasShow', '1', { domain: wildcardDomain() });
                        },
                        onDenied() {
                        },
                    });
                },
                timeOutAppearanceOfNotification,
            );
        }
    }

    private async getUuidMindbox(): Promise<string> {
        return new Promise((resolve) => {
            if (this.mindboxDeviceUUID) {
                resolve(this.mindboxDeviceUUID);
            }

            const timer = setInterval(() => {
                this.mindboxDeviceUUID = Cookies.get('mindboxDeviceUUID');

                if (this.mindboxDeviceUUID) {
                    clearTimeout(timer);
                    resolve(this.mindboxDeviceUUID);
                }
            }, 500);
        });
    }

    private async operation(operationType: string, rawOperationData: Record<string, any>) {
        const url = 'https://api.mindbox.ru/v3/operations/async';
        const operationData = JSON.stringify(rawOperationData);
        const deviceUUID = await this.getUuidMindbox();
        const params = {
            endpointId: this.endpointId,
            operation: operationType,
            deviceUUID,
        };

        axios({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url,
            data: operationData,
            params,
        });
    }

    /**
     * abstract method
     */
    public trackViewHotelAdapter(hotelPageView: any): void | ItrackViewHotel {}

    public trackViewHotel(payload: ItrackViewHotel): void {
        const {
            idHotel,
            filterCriteria,
            tourCriteria,
            pricerailwayFrom,
            pricehotelFrom,
            priceFrom,
        } = payload;

        const customFields = this.hydratorService.fieldsForHotelPage(tourCriteria, filterCriteria);

        this.operation('Website.ViewHotel', {
            viewProduct: {
                product: {
                    ids: {
                        hotels: idHotel,
                    },
                },
                customerAction: {
                    customFields: {
                        ...customFields,
                        pricerailwayFrom,
                        pricehotelFrom,
                        priceFrom,
                    },
                },
            },
        });
    }

    public trackViewSerpClick(
        serpCategory: TSerpCategory,
        { searhPageModel, collectionViewModel }: { searhPageModel: Backbone.Model, collectionViewModel: Backbone.Collection },
        ): void {
        const customFields = this.hydratorService.fieldsForSerpClick(serpCategory, {
            searhPageModel, collectionViewModel,
        });

        this.operation('Website.ViewCategory', {
            viewProductCategory: {
                productCategory: {
                    ids: {
                        hotels: customFields.countryId[0],
                    },
                },
                customerAction: {
                    customFields,
                },
            },
        });
    }

    public trackViewSerp(serpCategory: TSerpCategory, searhPageModel: Backbone.Model): void {
        const customFields = this.hydratorService.fieldsForSerp(serpCategory, searhPageModel);

        this.operation('Website.ViewCategory', {
            viewProductCategory: {
                productCategory: {
                    ids: {
                        hotels: customFields.countryId[0],
                    },
                },
                customerAction: {
                    customFields,
                },
            },
        });
    }

    public trackViewTourPageConstructor(tourPageModel: Backbone.Model): void {
        const cheapestProduct = tourPageModel.get('cheapestTourProduct');

        if (!cheapestProduct || !this.shouldTrackTourPage) {
            return;
        }

        this.shouldTrackTourPage = false;

        const customFields = this.hydratorService.fieldsForTourPage(cheapestProduct, tourPageModel);

        this.operation('TourPage', {
            customerAction: {
                customFields,
            },
        });
    }

    public trackViewTourPage(tourPageModel: Backbone.Model): void {
        const selectedTourProduct = tourPageModel.get('selectedTourProduct');

        if (!selectedTourProduct || !this.shouldTrackTourPage) {
            return;
        }

        this.shouldTrackTourPage = false;

        const customFields = this.hydratorService.fieldsForTourPage(selectedTourProduct);

        this.operation('TourPage', {
            customerAction: {
                customFields,
            },
        });
    }

    public trackGoToCheckout(tourPageModel: Backbone.Model, quoteUuid: string, priceDetailsViewModel: Backbone.Model): void {
        const customFields = this.hydratorService.fieldsForCheckoutRedirect(tourPageModel, quoteUuid, priceDetailsViewModel);

        this.operation('Checkout', {
            customerAction: {
                customFields,
            },
        });
    }

    public trackGoToCheckoutHotelOffer(hotelPageModel: Backbone.Model, tariff: IHotelOfferTariff): void {
        const customFields = this.hydratorService.fieldsForCheckoutRedirectHotellOffer(hotelPageModel, tariff);

        this.operation('Checkout', {
            customerAction: {
                customFields,
            },
        });
    }
}
