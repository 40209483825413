import { useState, useMutations } from 'vuex-composition-helpers';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import HotelsRepository, {
    IHotelRepositoryMutations,
    IHotelsRepositoryState,
    NAMESPACE,
} from '../model/HotelsRepository';

export default function () {
    registerModule.register(getStore(), HotelsRepository, NAMESPACE);
    const { hotels } = useState<IHotelsRepositoryState>(NAMESPACE, ['hotels']);
    const { setHotels } = useMutations<IHotelRepositoryMutations>(NAMESPACE, ['setHotels']);

    return {
        hotels,
        setHotels,
    };
}