import IntercomChat from '@/js/common/components/intercomChat/intercomChat';

import template from './template.html';
import './intercomChat.scss';

$(document).ready(() => {
    const $el = document.createElement('div');
    $el.className = 'intercomChat';
    $el.innerHTML = template();

    document.body.appendChild($el);

    new IntercomChat();
});
