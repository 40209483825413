import MealEntity from '../Entity/Meal';

const MealsRepository = Backbone.Collection.extend({
    model: MealEntity,
    getById(ids) {
        let meals = [];
        if (_.isArray(ids)) {
            _.each(ids, (id) => {
                const meal = this.get(id);
                if (meal) {
                    meals.push(meal);
                }
            });
        } else {
            meals = this.get(ids);
        }
        return meals;
    },
});

const repository = new MealsRepository([
    { id: 1, name: 'Всё включено', code: 'AI' },
    { id: 2, name: 'Завтрак', code: 'BB' },
    { id: 3, name: 'Завтрак, обед, ужин', code: 'FB' },
    { id: 4, name: 'Завтрак, обед, ужин, с напитками', code: 'FB+' },
    { id: 5, name: 'Завтрак + ужин', code: 'HB' },
    { id: 6, name: 'Завтрак + ужин с напитками', code: 'HB+' },
    { id: 7, name: 'Без питания', code: 'RO' },
    { id: 8, name: 'Ультра все включено', code: 'UAI' },
    { id: 9, name: 'По программе', code: 'PRG' },
    { id: 10, name: 'Завтрак + обед', code: 'LHB' },
    { id: 11, name: 'Всё включено (без алкоголя)', code: 'AI(noAlc)' },
    { id: 12, name: 'Обед', code: 'Lunch' },
    { id: 13, name: 'Ужин', code: 'Dinner' },
    { id: 14, name: 'Комплекс лечебных процедур, без питания', code: 'ROT' },
    { id: 15, name: 'Завтрак + комплекс лечебных или SPA процедур', code: 'BBT' },
    { id: 16, name: 'Завтрак и ужин + комплекс лечебных или SPA процедур', code: 'HBT' },
    { id: 17, name: 'Завтрак, обед и ужин + комплекс лечебных или SPA процедур', code: 'FBT' },
]);

MealsRepository.repository = repository;

export default MealsRepository;