import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { FilterAttributeHotelMethodsByAll } from './FilterAttributeHotelMethodsByAll';
import { IAttributeHotel, IFilterType } from './index';
import dataAttributesHotel from './dataAttributesHotel';
import FilterAttributeMethodsAbstract from '../filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';

export default class FilterAttributeHotelMethodsByType extends FilterAttributeMethodsAbstract<IAttributeHotel, SerpHotelAdapted> {
    filtersMethods: FilterAttributeMethodsAbstract<IAttributeHotel, SerpHotelAdapted>;

    constructor(typeFilter: IFilterType) {
        super(dataAttributesHotel.filter((attribute) => attribute.typeFilter === typeFilter));
        this.filtersMethods = new FilterAttributeHotelMethodsByAll(this.getListFilterAttributes());
    }

    getIsOneHotelHasProperty(hotels: SerpHotelAdapted[]): boolean {
        return this.filtersMethods.getIsOneHotelHasProperty(hotels);
    }

    getCountAttribute(hotels: SerpHotelAdapted[]): Record<IAttributeHotel['key'], number> {
        return this.filtersMethods.getCountAttribute(hotels);
    }
}