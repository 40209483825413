const getTomorrowFunc = (): () => Date => {
    let tomorrow: null | Date = null;

    return () => {
        if (tomorrow) return tomorrow;
        tomorrow = new Date();
        tomorrow.setHours(0);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    };
};

const getTomorrow = getTomorrowFunc();

export default getTomorrow;