import { MutationTree } from 'vuex';

export interface BudgetFilterState {
    minPriceFilter: number | null;
    maxPriceFilter: number | null;
}

export interface BudgetFilterMutations extends MutationTree<BudgetFilterState> {
    setMaxPrice(state: BudgetFilterState, value: number | null): void;
    setMinPrice(state: BudgetFilterState, value: number | null): void;
}

export interface BudgetFilterStore {
    state: BudgetFilterState,
    mutations: BudgetFilterMutations,
}

export const NAMESPACE = 'budgetFilterStore';

export default {
    namespaced: true,
    state: {
        maxPriceFilter: null,
        minPriceFilter: null,
    },
    mutations: {
        setMaxPrice(state, value) {
            state.maxPriceFilter = value;
        },
        setMinPrice(state, value) {
            state.minPriceFilter = value;
        },
    },
} as BudgetFilterStore;