
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import moment, { Moment } from 'moment';
    import ButtonDesktop from '@ve/components/button/desktop/Button.vue';
    import CountdownTimer from '@ve/components/countdownTimer/CountdownTimer.vue';
    import { HotelInfo } from '../common/types';

    @Component({
        components: { CountdownTimer, ButtonDesktop },
    })
    export default class DroppedOrderBanner extends Vue {
        @Prop() readonly show!: boolean;

        @Prop() readonly timerEnd!: string;

        @Prop() readonly link!: string;

        @Prop() readonly hotelInfo!: HotelInfo;

        timerInterval: null | ReturnType<typeof setTimeout> = null;

        now: Moment = moment();

        mounted(): void {
            this.startTimer();
        }

        beforeDestroy(): void {
            this.endTimer();
        }

        startTimer(): void {
            this.timerInterval = setInterval(() => {
                this.refreshTime();
            }, 1000);
        }

        endTimer(): void {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
        }

        private refreshTime(): void {
            this.now = moment();
        }

        get endTime(): Moment {
            return moment(this.timerEnd);
        }

        get hotelName(): string {
            return this.hotelInfo?.hotelName || '';
        }

        get hotelRate(): string {
            const r = this.hotelInfo?.hotelRate;
            if (r && r !== '0') return r;
            return '';
        }

        get hotelType(): string {
            return this.hotelInfo?.hotelType || '';
        }

        get bannerType(): string {
            if (this.now.isBefore(this.endTime)) {
                return 'withTimer';
            }
            return 'withoutTimer';
        }

        get titleText(): string {
            if (this.bannerType === 'withTimer') {
                return 'Завершите бронирование. Цена на тур может измениться через';
            }
            return 'Завершите бронирование. Цена на тур могла измениться';
        }

        get buttonText(): string {
            if (this.bannerType === 'withTimer') {
                return 'Забронировать';
            }
            return 'Проверить цену';
        }

        private onButtonClick() {
            window.location.assign(this.link);
        }
    }
