import { TWaterActivitiesIds, idWaterActivity } from '@ve/pages/serp/components/filters/filterWaterActivities/common';

export default (ids: number[]): TWaterActivitiesIds[] => {
    const result: TWaterActivitiesIds[] = [];
    if (~ids.indexOf(60)) { // Открытый бассейн
        result.push(idWaterActivity.openPool);
    }
    if (~ids.indexOf(59)) { // Крытый бассейн
        result.push(idWaterActivity.closePool);
    }
    if (~ids.indexOf(174)) { // Бассейн с подогревом
        result.push(idWaterActivity.heatedPool);
    }
    if (~ids.indexOf(173)) { // Бассейн с морской водой
        result.push(idWaterActivity.seaPool);
    }
    if (~ids.indexOf(175)) { // Водные горки
        result.push(idWaterActivity.waterslides);
    }
    if (~ids.indexOf(61)) { // Аквапарк
        result.push(idWaterActivity.aquapark);
    }
    return result;
};
