import {
 computed, unref, ComputedRef, Ref, WritableComputedRef,
} from 'vue';
import { useState, useActions } from 'vuex-composition-helpers';
import { IMapForFiltersSerpActions, IMapForFiltersSerpState, SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { IAttributeHotel } from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsHotelBoolean';
import { filterAttributeHotelMethodsByAll } from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsHotelBoolean/FilterAttributeHotelMethodsByAll';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';

interface UseFilterAttributesHotelI {
    filteredHotels: Ref<SerpHotelAdapted[]>;
    syncHotels: Ref<SerpHotelAdapted[]>;
    selectedAttributes: WritableComputedRef<number[]>;
    isPopularityFilterActive: Ref<boolean>;
}

export default function (typeFilter: IAttributeHotel['typeFilter']): UseFilterAttributesHotelI {
    const {
        takenHotelAttributes, filteredHotels, syncHotels, isPopularityFilterActive,
    } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['takenHotelAttributes', 'filteredHotels', 'syncHotels', 'isPopularityFilterActive']);
    const { action_takenHotelAttributes: setHotelAttributes } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_takenHotelAttributes']);

    const selectedAttributes = computed({
        get: () => filterAttributeHotelMethodsByAll.filterIdsByTypeFilter(unref(takenHotelAttributes), typeFilter),
        set: (idsAttributesByFilter: number[]) => {
            const attributesHotelWithoutAttributesFilter = filterAttributeHotelMethodsByAll.filterIdsWithoutTypeFilter(unref(takenHotelAttributes), typeFilter);
            setHotelAttributes([...attributesHotelWithoutAttributesFilter, ...idsAttributesByFilter]);
        },
    });
    return {
        selectedAttributes,
        filteredHotels,
        syncHotels,
        isPopularityFilterActive,
    };
}