
    import { Ref, defineComponent } from 'vue';
    import useFilterSelected from '@ve/components/filters/common/use/useFilterSelected';
    import CheckBoxInput from '@ve/components/inputs/CheckBoxInput.vue';
    import TextCommon from '@ve/components/inputs/TextCommon.vue';

    interface IDirectFlightFilterReturn {
        isFilterSelected: Ref<boolean>,
    }

    export default defineComponent({
        name: 'direct-flight-filter',
        components: {
            TextCommon,
            CheckBoxInput,
        },
        props: {
            selected: {
                type: Boolean,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, ctx): IDirectFlightFilterReturn {
            return {
                ...useFilterSelected(props, ctx),
            };
        },
    });
