import { BeachTypeId } from '../types/IBeachType';

function getBeachType(ids: number[]): BeachTypeId | 0 {
    if (ids.includes(136)) { //Песчаный
        return BeachTypeId.SandBeach;
    }
    if (ids.includes(137)) { //Галечный
        return BeachTypeId.PebbleBeach;
    }
    if (ids.includes(211)) { //Песчано-галечный
        return BeachTypeId.SandPebbleBeach;
    }
    return 0;
}

export default getBeachType;