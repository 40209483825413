import type { EmptySearchCurrentState } from '@ve/components/emptySearch/common/store/types/IEmptySearchStore';

export default ({
    hasTours,
    loadingStateIsFinished,
    hasCalendarMinPriceDate,
    searchWithFlexibleDates,
    hasPriceCalendar,
}: {
    hasTours: boolean;
    loadingStateIsFinished: boolean;
    hasCalendarMinPriceDate: boolean;
    searchWithFlexibleDates: boolean;
    hasPriceCalendar: boolean;
}): EmptySearchCurrentState | null => {
    if (hasTours) {
        return 'alternativeSearchHasTours';
    }

    if (loadingStateIsFinished) {
        if (hasCalendarMinPriceDate) {
            return 'calendarMinPriceDateSearch';
        }

        if (searchWithFlexibleDates || !hasPriceCalendar) {
            return 'defaultCriteriaSearch';
        }

        return 'asyncSearchWithFlexibleDates';
    }

    return null;
};