import { viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import PopupWrapperView from '@/js/desktop/components/popup/PopupWrapper';

import firstPaymentDefinitionService from '@/js/common/Domain/Service/firstPaymentDefinition';
import prepareOperatorsIdsFromTourProductsService from '@/js/common/Domain/Service/prepareOperatorsIdsFromTourProducts';

import template from './assets/template.html';
import './assets/styles.scss';

@viewModelDecorator({
    defaults: {
        countryName: '',
        tourProducts: [],
    },
})
export default class PartialPaymentBannerDgView extends Backbone.Epoxy.View {
    initialize(options) {
        this.initModel(options);
        this.initDg();
    }

    initModel(options) {
        this.viewModel.set('countryName', options?.countryName);
        this.viewModel.set('tourProducts', options?.tourProducts);
    }

    initDg() {
        this.initCurrentFirstPaymentDefinition();

        this.dialog = new PopupWrapperView({
            el: template({
                countryName: this.viewModel.get('countryName'),
                operators: this.viewModel.get('operators'),
            }),
            width: 600,
            dialogClass: 'partialPaymentBannerDg',
        });
    }

    initCurrentFirstPaymentDefinition() {
        const firstPaymentDefinitions = firstPaymentDefinitionService.getData();

        const tourProducts = this.viewModel.get('tourProducts');
        const operatorsIds = prepareOperatorsIdsFromTourProductsService(tourProducts);

        const operators = _.reduce(operatorsIds, (accOperators, tourOperatorId) => {
            const currentOperator = _.reduce(firstPaymentDefinitions, (acc, firstPaymentDefinition) => {
                if (tourOperatorId && tourOperatorId !== firstPaymentDefinition.operator) {
                    return acc;
                }

                const itemIndex = _
                    .findIndex(acc, (item) => item.operator.id === firstPaymentDefinition.operator);

                const currentFirstPaymentDefinition = _.pick(firstPaymentDefinition,
                    'daysAfterReservationForFullPayment',
                    'daysBeforeCheckInDateForFullPayment',
                    'minDaysAvailableBeforeCheckInDate',
                    'firstPaymentPart');

                if (itemIndex === -1) {
                    acc.push({
                        operator: {
                            id: firstPaymentDefinition?.operator,
                            cover: firstPaymentDefinition?.operatorCover,
                        },

                        firstPaymentDefinitions: [currentFirstPaymentDefinition],
                    });

                    return acc;
                }

                acc[itemIndex].firstPaymentDefinitions.push(currentFirstPaymentDefinition);
                return acc;
            }, []);

            return accOperators.concat(currentOperator);
        }, []);

        this.viewModel.set('operators', operators);
    }
}
