import HotelCategoriesRepository from '@/js/common/Domain/Repository/HotelCategories';

export default (params) => {
    const tourCriteria = {};

    const element = params.hotelClass;
    if (element) {
        tourCriteria.hotelCategories = element.split('.');
    } else if (element === 'all') {
        tourCriteria.hotelCategories = HotelCategoriesRepository.repository.clone();
    }

    return tourCriteria;
};