/* eslint-disable */
import requestService from '@/js/common/Domain/Service/request';
import mindBoxService from '@/js/common/Service/mindBoxService/MindBoxServiceDesktop';
import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';
import { extendDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import { TrackJS } from 'trackjs';

@viewModelDecorator({
    defaults() {
        return {
            loading: false,
            EmailWasSend: false,
            $form: null,
        };
    },
})
@extendDecorator({
    bindings: {
        ':el': 'classes: { success: EmailWasSend, loading: loading }',
        button: 'classes: { loading: loading }',
    },
})
export default class SubscribeEmailFormView extends Backbone.Epoxy.View {
    initialize() {
        this.initDOMEvents();
        this.dataLayerAnalytics = new DataLayerAnalytics();
    }

    getTypeBanner() {
        const typesPages = {
            searchPage: 'sideBanner',
            atlasPage: 'atlas',
            main: 'mainpage',
            railwaySearchPage: 'sideBanner',
        };
        const currentTypePage = window?.appConfig?.pageType;
        const typePage = typesPages[currentTypePage];

        if (!typePage) {
            TrackJS.track('unknown subscribe form');
        }

        return typePage || 'unknown';
    }

    initDOMEvents() {
        setTimeout(() => {
            const $forms = this.$el.find('form');
            $forms.each((i, form) => {
                const $form = $(form);
                $form.validate({
                    errorElement: 'div',
                    rules: {
                        email: {
                            required: true,
                            email: true,
                            remote: '/validate/emailValidate',
                        },
                    },
                    messages: {
                        email: {
                            required: 'Обязательное поле',
                            email: 'Неправильный емейл',
                            remote: 'Неправильный емейл',
                        },
                    },
                });
                $form.on('click', 'button', async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if ($form.valid() && !this.viewModel.get('loading')) {
                        this.viewModel.set('emailError', false);
                        await this.formSubmit($form.find('input').val());
                    }
                });
                this.viewModel.set('$form', $form);
            });
        }, 100);
    }

    /**
     * нужно поменять на post
     */
    async formSubmit(email) {
        this.viewModel.set('loading', true);
        const mindBoxUuid = await mindBoxService.getUuidMindbox();
        requestService({
            type: 'GET',
            url: '/subscriber/add',
            data: {
                email,
                context: this.getTypeBanner(),
                mindboxUid: mindBoxUuid,
            },
        }).done((data) => {
            this.viewModel.set('loading', false);
            if (data && data.success) {
                this.viewModel.set('EmailWasSend', true);
                this.dataLayerAnalytics.subscribeEmail();
            } else {
                const $form = this.viewModel.get('$form');
                const $validator = $form?.validate();
                $validator?.showErrors({
                    email: 'Произошла ошибка. Попробуйте использовать другой адрес электронной почты.',
                });
            }
        }).fail(() => {
            this.viewModel.set('loading', false);
        });
    }
}
