import { TProductType } from '@ve/components/serpHotelCard/types';
import { IBackboneProduct } from '@ve/types/backboneModels/Domain/ObjectValue';
import { TouristGroup } from '@ve/types';
import { productTypes } from '@fr/constants';

export default (hotelCardRightBlockDataForProps: {
    product: IBackboneProduct,
    productType: TProductType,
    productPrice: number,
}): {
    touristGroup: TouristGroup,
    checkInDate: string,
    nights: number,
    productPrice: number,
    productType: string,
    tooltipBottomText: string,
} => {
    const getTooltipBottomText = () => {
        switch (hotelCardRightBlockDataForProps.productType) {
            case productTypes.HOTEL_OFFER:
                return 'В стоимость проживания обычно входит: номер в отеле, питание — по выбору';
            case productTypes.RAILWAY_TOUR:
                return 'В стоимость тура обычно входит: номер в отеле, питание, Ж/Д билеты, страховка, трансфер — по выбору';
            default:
                return 'В стоимость тура обычно входит: номер в отеле, питание, перелёты, страховка, трансфер — по выбору';
        }
    };
    return {
        touristGroup: hotelCardRightBlockDataForProps.product.get('touristGroup'),
        checkInDate: hotelCardRightBlockDataForProps.product.get('checkInDate'),
        nights: hotelCardRightBlockDataForProps.product.get('nights'),
        productPrice: hotelCardRightBlockDataForProps.productPrice,
        productType: hotelCardRightBlockDataForProps.productType,
        tooltipBottomText: getTooltipBottomText(),
    };
};