export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('meals');
    if (element) {
        if (element.length) {
            const meals = element.pluck('id');
            if (_.contains(meals, 3)) {
                meals.push(4);
                meals.push(17);
            }
            if (_.contains(meals, 5)) {
                meals.push(6);
                meals.push(16);
            }
            if (_.contains(meals, 7)) {
                meals.push(14);
            }
            if (_.contains(meals, 2)) {
                meals.push(12);
                meals.push(13);
                meals.push(15);
                meals.push(9);
            }
            if (_.contains(meals, 1)) {
                meals.push(8);
                meals.push(11);
            }
            tourCriteriaJson.meals = _.uniq(meals);
        } else {
            tourCriteriaJson.meals = [];
        }
    }

    return tourCriteriaJson;
};