import { IFilterAttribute } from '@ve/components/filters/common/types';

import { ValuesOf } from '@ve/types/helpers';

// eslint-disable-next-line no-shadow
export enum idWaterActivity {
    openPool = 1,
    closePool = 2,
    heatedPool = 3,
    seaPool = 4,
    waterslides = 5,
    aquapark = 6,
}

export type TWaterActivitiesKeys = keyof typeof idWaterActivity;

export type TWaterActivitiesIds = ValuesOf<idWaterActivity>;

export type IWaterActivities = IFilterAttribute<TWaterActivitiesIds, TWaterActivitiesKeys>;