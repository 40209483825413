/* eslint-disable */
import 'jquery.cookie';
import moment from 'moment';
import 'moment/locale/ru';
import AbstractPageModel from '@/js/desktop/Domain/Abstract/AbstractPageModel';
import HotelsSortedRepo from '@/js/common/Domain/Repository/HotelsSorted';
import TourCriteriaObjectValue from '@/js/common/Domain/ObjectValue/TourCriteria';
import tourHunterService from '@/js/common/Domain/Service/tourHunter';
import toRouterHydrator from '@/js/common/Domain/Hydrator/toRouter';
import tourCriteriaService from '@/js/common/Domain/Service/tourCriteria';
import ecommerceService from '@/js/common/Domain/Service/ecommerce';
import regionHasOfficesService from '@/js/common/Domain/Service/regionHasOffices';
import getRegionTypeService from '@/js/common/Domain/Service/getRegionType';
import { propsDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import { LIMIT_HOTELS_VIEW } from '@/js/common/Constants/searchPageConstants';

import FilterAttributeRatingReviewsMethods from '@ve/components/filters/ratingReviewsFilter/filterMethods/FilterAttributeRatingReviewsMethods';
import { filterAttributeHotelMethodsByAll } from '@ve/components/filters/common/filterMethods/filterAttributeMethodsHotelBoolean/FilterAttributeHotelMethodsByAll';

let filtersValueOld = null;
const filterTitles = [];
let filterRatingValueOld = null;
const filterRatingTitle = null;
const filtersCount = {};
const filterNames = [];
const reviewHtml = '<span class="filterReviewLineDefault"></span><span class="filterReviewLineDefault"></span><span class="filterReviewLineDefault"></span><span class="filterReviewLineDefault"></span><span class="filterReviewLineDefault"></span>';
const isOperatorFiltered = () => ~window.location.hash.indexOf('&operators=');
const defaultMinPrice = 100;
const defaultMaxPrice = 10000000;

@propsDecorator({
    hotelsCollection: null,
    defaults: {
        searchData: new TourCriteriaObjectValue(),
        blurToursColumn: false,
        beforeToursLoading: true,
        nothingFoundFormOpened: false,
        showAdditionalFilterCriteria: false,
        loadingState: 'loading', //"loading", "asyncLoading", "finished"
        loadingInProgress: false,
        syncHotels: null,
        asyncHotels: null,
        syncTourProducts: null,
        asyncTourProducts: null,
        pageNumber: 0,
        sortStrategy: 'recommend',
        sortStrategyChanged: false,
        priceFilterTipClosed: !!$.cookie('priceFilterTipClosed'),
        viewMode: 'standart',
        scrollPosition: 0,
        tourCriteriaLoaded: false,

        promoBannerShowHot: $.cookie('promoBannerShowHotClosed') !== 'true',
        promoBannerShowEarlyBooking: $.cookie('promoBannerEarlyBookingClosed') !== 'true',
        promoBannerShowSearchOperator: isOperatorFiltered() && ($.cookie('promoBannerSearchOperatorClosed') !== 'true'),

        tourHunterSubscribeInProgress: false,

        regionType: null,
        regionWithOffices: null,
        showChildrenFilters: false,

        /*fromComputeds*/
        scoreReviewsLocation: reviewHtml,
        scoreReviewsService: reviewHtml,
        scoreReviewsMeal: reviewHtml,

        ratingLocationMax: 5,
        ratingServiceMax: 5,
        ratingMealMax: 5,

        ratingByLocationCountOverall: 0,
        ratingByServiceCountOverall: 0,
        ratingByMealCountOverall: 0,

        defaultMinPrice,
        defaultMaxPrice,
        unfilteredMinPrice: defaultMinPrice,
        unfilteredMaxPrice: defaultMaxPrice,
        sliderMinPrice: defaultMinPrice,
        sliderMaxPrice: defaultMaxPrice,
        minPrice: 0,
        maxPrice: 0,
        numOfToursFiltered: 0,
        // empty serp model
        foundByAnotherDate: '',
        visibleSimilarTours: null,
        showHotelSearchTooltip: null,
        covidBanner: false,
        countryIsClose: false,
        lastBlockedTourCriteriaByCountry: [],
    },
    computeds: {
        tourhunterAlreadySubscribed() {
            const searchData = this.get('searchData');
            let addedCurrentThToFavorites = false;

            addedCurrentThToFavorites = tourHunterService.criteriaIsSubscribed(searchData);

            return addedCurrentThToFavorites;
        },
        isRegion() {
            const departureCity = this.get('searchData').get('departureCity');

            return !!(departureCity && departureCity.get('id') > 2);
        },
        allOperatorsUrl() {
            const searchData = this.get('searchData');
            let params;
            let url = '';
            if (searchData) {
                params = toRouterHydrator(searchData);
                params.operators = [];
                url = `#?${$.param(params)}`;
            }

            return url;
        },
        fromMoscowUrl() {
            const searchData = this.get('searchData');
            let params;
            let url = '';

            if (searchData) {
                params = toRouterHydrator(searchData);
                params.fromCity = 2;
                url = `#?${$.param(params)}`;
            }

            return url;
        },
        onlyOneHotelSelected() {
            const searchData = this.get('searchData');
            let onlyOneHotelSelected = false;
            let hotels;

            if (searchData) {
                hotels = searchData.get('hotels');
                if (hotels && hotels.length === 1) {
                    onlyOneHotelSelected = hotels.at(0);
                }
            }

            return onlyOneHotelSelected;
        },
        oneHotelSelectedUrl() {
            const oneHotelSelected = this.get('onlyOneHotelSelected');
            let oneHotelSelectedUrl = '';

            if (oneHotelSelected) {
                oneHotelSelectedUrl = `/goto/hotel/${oneHotelSelected.get('id')}`;
            }

            return oneHotelSelectedUrl;
        },
        oneHotelSelectedName() {
            const oneHotelSelected = this.get('onlyOneHotelSelected');
            let oneHotelSelectedName = '';

            if (oneHotelSelected) {
                oneHotelSelectedName = oneHotelSelected.get('name');
            }

            return oneHotelSelectedName;
        },
        promoBannerShowComputed: {
            deps: ['promoBannerShowHot'],
            get(promoBannerShowHot) {
                return promoBannerShowHot;
            },
            set(value) {
                $.cookie('promoBannerShowHotClosed', !value, { path: '/', expires: 999, domain: 'all' });
                return {
                    promoBannerShowHot: value,
                };
            },
        },
        promoBannerEarlyBookingShowComputed: {
            deps: ['promoBannerShowEarlyBooking'],
            get(promoBannerShowEarlyBooking) {
                return promoBannerShowEarlyBooking;
            },
            set(value) {
                $.cookie('promoBannerEarlyBookingClosed', !value, { path: '/', expires: 999, domain: 'all' });
                return {
                    promoBannerShowEarlyBooking: value,
                };
            },
        },
        promoBannerSearchOperatorShowComputed: {
            deps: ['promoBannerShowSearchOperator'],
            get(promoBannerShowSearchOperator) {
                return promoBannerShowSearchOperator && isOperatorFiltered();
            },
            set(value) {
                $.cookie('promoBannerSearchOperatorClosed', !value, { path: '/', expires: 999, domain: 'all' });
                return {
                    promoBannerShowSearchOperator: value && isOperatorFiltered(),
                };
            },
        },
        isHotTours() {
            return false;
        },
        changedFiltersPropose() {
            const self = this;
            let changedFilters = tourCriteriaService.getMaybeWrongFields(this.get('searchData'));
            const template = _.template('<li><%= name %></li>');
            let html = $();

            changedFilters = changedFilters.slice(0, 3);

            _.each(changedFilters, (field) => {
                if (field !== 'priceRange') {
                    const view = self.searchFormViewModel.get(field).get('view');
                    let element;

                    if (!_.isEmpty(view) && view.label !== 'бюджет') {
                        element = $(template({ name: view.label }));

                        element.on('click', (e) => {
                            view.scrollToElement(e);
                        });

                        html = html.add(element);
                    }
                }
            });
            return html;
        },
        operatorSearch() {
            const searchData = this.get('searchData');
            const operators = searchData ? searchData.get('operators') : false;

            return operators && operators.length ? operators.length : false;
        },
        operatorClearEnabled() {
            const changedFiltersPropose = this.get('changedFiltersPropose');
            const operatorSearch = this.get('operatorSearch');

            return !!(_.isEmpty(changedFiltersPropose) && operatorSearch);
        },
        alternativeCountryName: {
            deps: ['searchData'],
            get: (searchData) => (searchData.get('countries') !== null ? searchData.get('countries').at(0).get('name') : ''),
        },
        showSortPannelWhenScroll() {
            // earlybooking
            const navbar = $('.searchContainer_navbar');

            if (navbar.length) {
                return (this.get('scrollPosition') > (navbar.offset().top));
            }

            return false;
        },
        filteredHotelsByRestType() {
            let hotels = this.get('syncHotels')?.models || [];
            hotels = this.filterByRestType(hotels);
            return hotels;
        },
        filteredHotelsWithoutAttrs() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutRating() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutRatingByLocation() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutRatingByService() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutBeachLine() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutRatingByMeal() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutBeachType() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutPrice() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            let minPrice = Infinity;
            let maxPrice = 0;
            if (!_.isEmpty(hotels)) {
                _.each(hotels, (hotel) => {
                    const price = hotel.get('priceWithOilTax');
                    if (price < minPrice) {
                        minPrice = price;
                    }

                    if (price > maxPrice) {
                        maxPrice = price;
                    }
                });
            }
            this.set('unfilteredMinPrice', minPrice);
            this.set('unfilteredMaxPrice', maxPrice);

            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutOperator() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            return hotels;
        },
        filteredHotelsWithoutDistanceLift() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },
        filteredHotelsWithoutRestType() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterHotelsByConfirmation(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },

        filteredHotelsWithoutConfirmation() {
            let hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            hotels = this.filterByHotelFacilities(hotels);
            hotels = this.filterByPopularityOffers(hotels);
            hotels = this.filterByBeachType(hotels);
            hotels = this.filterByAttrs(hotels);
            hotels = this.filterByServiceChild(hotels);
            hotels = this.filterByRestType(hotels);
            hotels = this.filterByWaterActivities(hotels);
            hotels = this.filterByRating(hotels);
            hotels = this.filterByTypeDistanceLift(hotels);
            hotels = this.filterByPrice(hotels);
            hotels = this.filterByBeachLine(hotels);
            hotels = this.filterByRatingNoScore(hotels);
            hotels = this.filterByRatingByLocation(hotels);
            hotels = this.filterByRatingByService(hotels);
            hotels = this.filterByRatingByMeal(hotels);
            hotels = this.filterByFreeCancellation(hotels);
            hotels = this.filterByTourOperators(hotels);
            return hotels;
        },

        filteredHotels() {
            let hotels = this.get('filteredHotelsWithoutRating') ? this.get('filteredHotelsWithoutRating') : [];
            hotels = this.filterByRating(hotels);
            return new this.HotelsSortedRepository(hotels);
        },
        filteredHotelsWithoutRatingByLocationChange: {
            deps: ['filteredHotelsWithoutRatingByLocation'],
            get(filteredHotelsWithoutRatingByLocation) {
                const hotels = filteredHotelsWithoutRatingByLocation || [];
                let ratingByLocationCount = 0;
                let maxRating = 0;

                if (!_.isEmpty(hotels)) {
                    _.each(hotels, (hotel) => {
                        const ratingLocation = hotel.get('ratingDetails') ? hotel.get('ratingDetails').hotel : false;
                        if (ratingLocation) {
                            ratingByLocationCount++;
                            if (ratingLocation > maxRating) {
                                maxRating = Math.round(ratingLocation);
                            }
                        }
                    });
                }
                this.set('ratingByLocationCount', ratingByLocationCount);
                this.set('ratingLocationMax', maxRating);
            },

        },
        filteredHotelsWithoutRatingByServiceChange() {
            const hotels = this.get('filteredHotelsWithoutRatingByService') ? this.get('filteredHotelsWithoutRatingByService') : [];
            let ratingByServiceCount = 0;
            let maxRating = 0;

            if (!_.isEmpty(hotels)) {
                _.each(hotels, (hotel) => {
                    const ratingService = hotel.get('ratingDetails') ? hotel.get('ratingDetails').service : false;
                    if (ratingService) {
                        ratingByServiceCount++;
                    }
                    if (ratingService > maxRating) {
                        maxRating = Math.round(ratingService);
                    }
                });
            }
            this.set('ratingByServiceCount', ratingByServiceCount);
            this.set('ratingServiceMax', maxRating);
        },
        filteredHotelsWithoutRatingByMealChange() {
            const hotels = this.get('filteredHotelsWithoutRatingByMeal') ? this.get('filteredHotelsWithoutRatingByMeal') : [];
            let ratingByMealCount = 0;
            let maxRating = 0;
            if (!_.isEmpty(hotels)) {
                _.each(hotels, (hotel) => {
                    const ratingMeal = hotel.get('ratingDetails') ? hotel.get('ratingDetails').meal : false;
                    if (ratingMeal) {
                        ratingByMealCount++;
                    }
                    if (ratingMeal > maxRating) {
                        maxRating = Math.round(ratingMeal);
                    }
                });
            }
            this.set('ratingByMealCount', ratingByMealCount);
            this.set('ratingMealMax', maxRating);
        },

        filterCountsOverAll() {
            const hotels = this.get('syncHotels') ? this.get('syncHotels').models : [];
            const self = this;

            let ratingByLocationCountOverall = 0;
            let ratingByServiceCountOverall = 0;
            let ratingByMealCountOverall = 0;
            let isExclusiveCountOverall = 0;

            self.resetCountersToZero();
            if (hotels.length) {
                _.each(hotels, (hotel) => {

                    const isExclusive = hotel.get('isExclusive');
                    if (isExclusive) {
                        isExclusiveCountOverall++;
                    }

                    const ratingByLocation = hotel.get('ratingDetails') ? hotel.get('ratingDetails').hotel : false;
                    if (ratingByLocation) {
                        ratingByLocationCountOverall++;
                    }
                    const ratingByService = hotel.get('ratingDetails') ? hotel.get('ratingDetails').service : false;
                    if (ratingByService) {
                        ratingByServiceCountOverall++;
                    }
                    const ratingByMeal = hotel.get('ratingDetails') ? hotel.get('ratingDetails').meal : false;
                    if (ratingByMeal) {
                        ratingByMealCountOverall++;
                    }
                    _.each(filtersCount, (value, key) => {
                        hotel.get(key) ? filtersCount[key]++ : 0;
                    });
                });
            }

            _.each(filtersCount, (value, key) => {
                self.set(`${key}CountOverall`, filtersCount[key]);
            });

            this.set('ratingByLocationCountOverall', ratingByLocationCountOverall);
            this.set('ratingByServiceCountOverall', ratingByServiceCountOverall);
            this.set('ratingByMealCountOverall', ratingByMealCountOverall);
            this.set('isExclusiveCountOverall', isExclusiveCountOverall);

            return false;
        },
        minPriceSyncHotels() {
            const hotels = this.get('syncHotels');
            let minPrice = Infinity;

            if (hotels && hotels.length) {
                hotels.each((hotel) => {
                    const price = hotel.get('minPrice');
                    if (price < minPrice) {
                        minPrice = price;
                    }
                });
                console.log('minPriceSyncHotels', minPrice);
                return minPrice;
            }
            return false;
        },
        minPriceAsyncHotels() {
            const hotels = this.get('asyncHotels');
            let minPrice = Infinity;

            if (hotels && hotels.length) {
                _.each(hotels, (hotel) => {
                    const price = hotel.get('minPrice');
                    if (price < minPrice) {
                        minPrice = price;
                    }
                });
                console.log('minPriceAsyncHotels', minPrice);
                return minPrice;
            }
            return false;
        },
        newResultPriceDifference() {
            const minPriceSync = this.get('minPriceSyncHotels');
            const minPriceAsync = this.get('minPriceAsyncHotels');
            let priceDifference;

            if (minPriceSync > 0 && minPriceAsync > 0 && minPriceAsync <= minPriceSync - 1000) {
                priceDifference = minPriceSync - minPriceAsync;
                console.log('newResultPriceDifference', priceDifference);
                return priceDifference;
            }
            return false;
        },
        toursLeftText() {
            const numOfHotelsPerPage = LIMIT_HOTELS_VIEW;
            const pageNumber = this.get('pageNumber');
            const hotels = this.get('filteredHotels');
            let hotelsLeft;
            let hotelsLeftNumber;
            let maxPages;

            if (hotels && hotels.length) {
                maxPages = Math.ceil(hotels.length / numOfHotelsPerPage);

                hotelsLeft = hotels.length - (pageNumber * numOfHotelsPerPage);
                hotelsLeftNumber = hotelsLeft > numOfHotelsPerPage ? numOfHotelsPerPage : hotelsLeft;

                if (pageNumber <= maxPages) {
                    return `${hotelsLeftNumber} отел${hotelsLeftNumber.wordEnding('ь;я;ей')}`;
                }
                return false;
            }
            return false;
        },
        pageIncAvaliable() {
            const numOfHotelsPerPage = LIMIT_HOTELS_VIEW;
            const pageNumber = this.get('pageNumber');
            const hotels = this.get('filteredHotels');
            let maxPages;

            if (hotels && hotels.length) {
                maxPages = Math.ceil(hotels.length / numOfHotelsPerPage);

                if (pageNumber < maxPages) {
                    return true;
                }
                return false;
            }
            return false;
        },

        enableFilters() {
            const syncHotels = this.get('syncHotels');
            return syncHotels && syncHotels.length;
        },
        fromCityLabel() {
            if (this.get('searchData').get('departureCity') !== null) {
                return this.get('searchData').get('departureCity').get('name');
            }
            return false;
        },
        toCountryLabel() {
            if (this.get('searchData').get('countries') !== null) {
                return this.get('searchData').get('countries').pluck('name').slice(0, 3)
                    .join(', ');
            }
            return false;
        },
        toCountryFromCity: {
            deps: ['toCountryLabel', 'fromCityLabel'],
            get: (toCountryLabel, fromCityLabel) => {
                const { toCountryName = '', departureCity = { nameFrom: '' } } = window.appConfig;

                if (!toCountryLabel && !fromCityLabel) return `Туры ${toCountryName} ${departureCity.nameFrom}`;
                if (!toCountryLabel && fromCityLabel) return `Туры ${toCountryName} ${fromCityLabel}`;
                if (toCountryLabel && !fromCityLabel) return `Туры ${toCountryLabel} ${departureCity.nameFrom}`;

                return `${toCountryLabel} ${fromCityLabel}`;
            },

        },
        resortsLabel() {
            if (this.get('searchData').get('resorts') !== null && this.get('searchData').get('resorts').length) {
                return this.get('searchData').get('resorts').pluck('name').slice(0, 4)
                    .join(', ');
            }
            return false;
        },
        hotelCategoriesLabel() {
            if (this.get('searchData').get('hotelCategories') !== null && this.get('searchData').get('hotelCategories').length) {
                return this.get('searchData').get('hotelCategories').pluck('name').join(', ');
            }
            return false;
        },
        dateLabel() {
            var from;
            var
                to;
            if (this.get('searchData').get('checkInDateRange') !== null) {
                var from = this.get('searchData').get('checkInDateRange').get('from');
                var to = this.get('searchData').get('checkInDateRange').get('to');

                return from.getTime() != to.getTime() ? `с ${$.datepicker.formatDate('dd.mm', from)} по ${$.datepicker.formatDate('dd.mm', to)}` : $.datepicker.formatDate('dd.mm', from);
            }
            return false;
        },
        nightsLabelOriginal() {
            let from;
            let
                to;
            if (this.get('searchData').get('nightRange') !== null) {
                from = this.get('searchData').get('nightRange').get('from');
                to = this.get('searchData').get('nightRange').get('to');

                return from != to ? (`на ${from}-${to} ноч${to.wordEnding('ь;и;ей')}`) : (`${to} ноч${to.wordEnding('ь;и;ей')}`);
            }
            return false;
        },
        nightsLabel() {
            const nightsChanged = _.contains(tourCriteriaService.getTourCriteriaChangedFields(), 'nightRange');
            const nightsLabelOriginal = this.get('nightsLabelOriginal');

            if (nightsLabelOriginal && nightsChanged) {
                return nightsLabelOriginal;
            }
            return false;
        },
        budgetLabel() {
            var from;
            var to;
            const budgetChanged = _.contains(tourCriteriaService.getTourCriteriaChangedFields(), 'priceRange');

            if (this.get('searchData').get('priceRange') && budgetChanged) {
                var from = this.get('searchData').get('priceRange').get('from');
                var to = this.get('searchData').get('priceRange').get('to');

                return from != to ? (`${from.formatNumber()}<span class="rubl">a</span> - ${to.formatNumber()} <span class="rubl">a</span>`) : (`${from.formatNumber()}<span class="rubl">a</span>`);
            }
            return false;
        },
        adults() {
            if (this.get('searchData').get('touristGroup') !== null) {
                return this.get('searchData').get('touristGroup').get('adults');
            }
            return false;
        },
        kids() {
            if (this.get('searchData').get('touristGroup') !== null) {
                return this.get('searchData').get('touristGroup').get('kids');
            }
            return false;
        },
        infants() {
            let infants;
            const touristGroup = this.get('searchData').get('touristGroup');
            if (touristGroup !== null) {
                infants = touristGroup.get('infants');
                return infants || 0;
            }
            return false;
        },
        numOfToursFound() {
            const products = this.get('syncTourProducts');
            if (products !== null && products.length > 0) {
                return products.length;
            }
            return false;
        },
        numOfHotelsFound() {
            const hotels = this.get('syncHotels');

            if (hotels !== null && hotels.length > 0) {
                const currentTourCriteria = this.get('searchData');

                if (currentTourCriteria && currentTourCriteria.get('countries') && currentTourCriteria.get('resorts') && typeof (window.APRT_SEND) !== 'undefined') {
                    const firstTourProduct = hotels.at(0).get('tourProducts').at(0);
                    const departureCity = currentTourCriteria.get('departureCity').get('name');
                    const country = currentTourCriteria.get('countries').at(0).get('name');
                    const resorts = currentTourCriteria.get('resorts');
                    const resortModel = resorts && resorts.at(0) || null;
                    const resortName = resortModel && resortModel.get('name') || null;
                    const categories = currentTourCriteria.get('hotelCategories');
                    const classModel = categories.length && categories.min((element) => element.get('stars')) || null;
                    const classId = classModel && classModel.get('stars') || 0;
                    const tourists = firstTourProduct.get('tour').get('touristGroup');
                    const nbra = parseInt(tourists.adults);
                    const nbrc = tourists.kids;
                    const nbri = tourists.infants || 0;
                    const checkin_date = moment(currentTourCriteria.get('checkInDateRange').get('from')).format('YYYY-MM-DD');
                    const checkout_date = moment(currentTourCriteria.get('checkInDateRange').get('to')).format('YYYY-MM-DD');

                    const params = {
                        pageType: 3,
                        searchTickets: {
                            type: 'hotel',
                            class: classId,
                            from: departureCity,
                            country,
                            dateFrom: checkin_date,
                            dateTill: checkout_date,
                            count: nbra,
                            countChildren: nbrc,
                            countBaby: nbri,
                        },
                    };
                    if (resortName) {
                        params.dest = resortName;
                    }
                    window.APRT_SEND(params);
                }
                return hotels.length;
            }
            return false;
        },
        numOfHotelsFiltered() {
            if (this.get('filteredHotels') !== null) {
                return this.get('filteredHotels').length;
            }
            return false;
        },
        additionalToursFound() {
            let newTours;
            const syncTourProducts = this.get('syncTourProducts');
            const asyncTourProducts = this.get('asyncTourProducts');

            if (syncTourProducts !== null && asyncTourProducts !== null) {
                newTours = asyncTourProducts.length - syncTourProducts.length;
                if (newTours > 0) {
                    return newTours;
                }
                return false;
            }
            return false;
        },
        additionalHotelsFound() {
            let newHotels;
            const syncHotels = this.get('syncHotels');
            const asyncHotels = this.get('asyncHotels');

            if (syncHotels !== null && asyncHotels !== null) {
                newHotels = asyncHotels.length - syncHotels.length;
                if (newHotels >= syncHotels.length * 0.1) {
                    return newHotels;
                }
                return false;
            }
            return false;
        },
    },
    quantityField: 'toursQuantity',
})
export default class SearchPageModel extends AbstractPageModel {
    filterByAttrs(hotels) {
        const filters = this.get('filterCriteria');
        const atributesIds = filters.get('takenHotelAttributes');
        const filtersNamesAtributes = filterAttributeHotelMethodsByAll.getKeysAttributeByIds(atributesIds);
        _.each(filterNames, (filterName) => {
            if (filters.get(filterName)) {
                hotels = _.filter(hotels, (hotel) => hotel.get(filterName) === true);
            }
        });
        _.each(filtersNamesAtributes, (filterName) => {
            hotels = _.filter(hotels, (hotel) => hotel.get(filterName));
        });
        return hotels;
    }

    // эти методы определяет public/travelata/v2/js/modules/atlas/View/SearchPageReviews.js
    filterByRatingNoScore(hotels) {
        return hotels;
    }

    filterByRatingByLocation(hotels) {
        return hotels;
    }

    filterByRatingByService(hotels) {
        return hotels;
    }

    filterByRatingByMeal(hotels) {
        return hotels;
    }

    // конец методов для SearchPageReviews.js
    filterByRating(hotels) {
        const filters = this.get('filterCriteria');
        const typeRatingFilter = filters.get('typeFilterRating');
        const rating = FilterAttributeRatingReviewsMethods.getReviewsRatingById(typeRatingFilter);
        const ratingFilter = +rating;
        if (ratingFilter) {
            hotels = _.filter(hotels, (hotel) => {
                const ratingHotel = Math.round(hotel.get('rating') * 10) / 10;
                return ratingHotel >= ratingFilter;
            });
        }
        return hotels.slice(0);
    }

    filterByFreeCancellation(hotels) {
        const filters = this.get('filterCriteria');
        const freeCancellation = filters.get('freeCancellation');
        if (freeCancellation) {
            hotels = _.filter(hotels, (item) => item.get('tourProducts').at(0).get('tour').get('freeCancellation'));
        }
        return hotels.slice(0);
    }

    filteredHotelsChanged() {
        const hotels = this.get('filteredHotels') ? this.get('filteredHotels').models : [];
        const self = this;
        let numOfToursFiltered = 0;
        let price;
        let minPrice = Infinity;
        let maxPrice = 0;

        self.resetCountersToZero();

        if (!_.isEmpty(hotels)) {
            _.each(hotels, (hotel) => {
                _.each(filtersCount, (value, key) => {
                    hotel.get(key) ? filtersCount[key]++ : 0;
                });

                price = hotel.get('priceWithOilTax');

                if (price < minPrice) {
                    minPrice = price;
                }

                if (price > maxPrice) {
                    maxPrice = price;
                }
                numOfToursFiltered += hotel.get(self.quantityField);
            });
        }

        this.set('minPrice', minPrice);
        this.set('maxPrice', maxPrice);
        this.set('numOfToursFiltered', numOfToursFiltered);

        _.each(filtersCount, (value, key) => {
            self.set(`${key}Count`, filtersCount[key]);
        });

        if (self.hotelsCollection) {
            self.hotelsCollection ? self.hotelsCollection.reset() : '';
            this.set('pageNumber', 1, { silent: true });
            this.incCollection();


            const filtersValue = filterTitles.join(',');
            const formName = 'Search form serp';

            if (formName.length && filtersValue !== filtersValueOld) {
                filtersValueOld = filtersValue;
                if (filtersValue.length) {
                    gaAsync(() => {
                        ga('send', 'event', formName, 'criteria_comfort', filtersValue, { nonInteraction: 1 });
                    });
                }
            }

            if (formName.length && filterRatingTitle !== null && filterRatingTitle !== filterRatingValueOld) {
                filterRatingValueOld = filterRatingTitle;
                if (filterRatingTitle.length) {
                    gaAsync(() => {
                        ga('send', 'event', formName, 'criteria_reviews', filterRatingTitle, { nonInteraction: 1 });
                    });
                }
            }
        }
    }

    callECommerce(newHotels) {
        const collection = this.hotelsCollection;
        ecommerceService.impressions({
            collection,
            hotels: newHotels,
            category: 'tour',
            pageType: 'serpTour',
            departureCityName: this.get('searchData')?.get('departureCity')?.get('name'),
        });
    }

    pageInc() {
        if (this.get('pageIncAvaliable')) {
            this.set('pageNumber', this.get('pageNumber') + 1);
        }
        return false;
    }

    incCollection() {
        this.systemInfoBenchmark?.startBench('serpToursProductsRenderTime');
        const numOfHotelsPerPage = LIMIT_HOTELS_VIEW;
        const pageNumber = this.get('pageNumber');
        const hotels = this.get('filteredHotels');
        const covidBanner = this.get('covidBanner');
        let addHotels;

		if (covidBanner) {
			if (this.hotelsCollection?.length) {
				this.hotelsCollection?.reset();
			}
			return;
		}

        if (hotels && hotels.length) {
            addHotels = hotels.models.slice((pageNumber - 1) * numOfHotelsPerPage, numOfHotelsPerPage * pageNumber);
            this.hotelsCollection.add(addHotels);
            setTimeout(() => {
                this.callECommerce(addHotels);
            }, 1);
        }

        this.systemInfoBenchmark?.stopBenchmarking('serpToursProductsRenderTime');
    }

    updateRenderedCollection() {
        console.time('updateRenderedCollection');
        const numOfHotelsPerPage = LIMIT_HOTELS_VIEW;
        const pageNumber = this.get('pageNumber');
        const hotels = this.get('filteredHotels');
        const covidBanner = this.get('covidBanner');
		let renderedHotels;

        if (covidBanner) {
            if (this.hotelsCollection?.length) {
                this.hotelsCollection?.reset();
            }
            return;
        }

        if (hotels && hotels.length) {
            hotels.sort();
            renderedHotels = hotels.models.slice(0, numOfHotelsPerPage * pageNumber);
            this.hotelsCollection.reset(renderedHotels);
        }

        console.timeEnd('updateRenderedCollection');
    }

    initEvents() {
        const self = this;
        self.on('change:loadingState', function () {
            //if(value != "finished") return;
            const hotels = self.get('filteredHotels').models;
            const hotelIds = [];
            const currentTourCriteria = this.get('searchData');

            if (hotels.length) {
                const maxHotelIds = hotels.length < 5 ? hotels.length : 5;
                for (let i = 0; i < maxHotelIds; i++) {
                    hotelIds.push(hotels[i].get('id'));
                }
            }
        });
    }

    createAttrCounter() {
        const attributes = this.filterCriteria.toJSON();
        const notAttributes = ['beachType', 'minPrice', 'maxPrice', 'rating', 'operators', 'ratingLocation', 
                                'ratingService', 'ratingMeal', 'freeCancellation', 'typeFilterRating', 'typeLines', 
                                'takenHotelAttributes', 'typeDistanceLift', 'restType', 'takenBeachType', 'takenWaterActivities', 'isPopularityFilterActive', 'takenFacilities', 'typeConfirmation', 'childServiceFilterIds'];
        let i;
        for (i in notAttributes) {
            delete attributes[notAttributes[i]];
        }
        for (i in attributes) {
            filtersCount[i] = 0;
            filterNames.push(i);
            this.set(`${i}Count`, 0);
            this.set(`${i}CountOverall`, 0);
        }
    }

    resetCountersToZero() {
        for (const i in filtersCount) {
            filtersCount[i] = 0;
        }
    }

    initModelEvents() {
        const self = this;

        this.on('change:pageNumber', $.proxy(this.incCollection, this));
        this.on('change:filteredHotels', $.proxy(this.filteredHotelsChanged, this));

        tourHunterService.getActiveSubscribes().on('add remove reset', (collection, lastSubscribe) => {
            self.recompute('tourhunterAlreadySubscribed');
        });
    }

    initialize(options) {
        const self = this;
        this.hotelsCollection = options.hotelsCollection;
        this.filterCriteria = options.filterCriteria;
        this.searchFormViewModel = options.searchFormViewModel;
        this.systemInfoBenchmark = options.systemLogInfo;

        getRegionTypeService((regionType) => {
            self.set('regionType', regionType);
        });
        regionHasOfficesService((value) => {
            self.set('regionWithOffices', value);
        });
        this.initEvents();
        this.initModelEvents();
        this.createAttrCounter();

        this.HotelsSortedRepository = HotelsSortedRepo.extend({
            comparator(model1, model2) {
                return this.strategies[self.get('sortStrategy')](model1, model2);
            },
        });
    }
}
