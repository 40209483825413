/* eslint-disable */
import { extendDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import './assets/style.scss';
import template from './assets/template.html';

@viewModelDecorator({
    defaults: {
        type: 'serp',
        alternativeCountryName: '',
        countryIsClose: false,
        text: {
            hotel: {
                label: 'Этот отель пока недоступен для путешествий из России',
                main: 'К сожалению, сейчас нет доступных предложений по выбранному отелю. Предлагаем вам посмотреть другие отели той же категории в доступных странах',
            },
            serp: {
                label: 'Эта страна пока недоступна для путешествий из России',
                main: 'К сожалению, сейчас нет предложений по вашему запросу. Ниже мы подобрали для вас отели по направлению: ',
            }
        }
    },
})
@extendDecorator({
    bindings: {
        '.js-closed-country-banner-wrap': 'toggle: countryIsClose',
        '.js-country-closed-name': 'toggle: not(equal(type, "hotel")), text: alternativeCountryName',
    },
    template,
})
export default class ClosedCountryBanner extends Backbone.Epoxy.View {
    initialize(options) {
        if (options.type) {
            this.viewModel.set('type', options.type);
        }
        this.initViewModel();
        this.render();
    }

    initViewModel() {
        this.viewModel.mapState('countryIsClose', this.model);
        if (this.viewModel.get('type') !== 'hotel') {
            this.viewModel.mapState('alternativeCountryName', this.model);
        }
    }

    render() {
        const attr = this.viewModel.attributes;
        this.$el.html(this.template(attr));
    }
}
