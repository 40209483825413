/* eslint-disable */
export default options => target => {
	const original = target.prototype.initialize;
	target.prototype.initialize = function (...args) {
		const Model = Backbone.Epoxy.Model.extend(options);
		this.viewModel = new Model();
		if (typeof original === 'function') {
			return original.apply(this, args);
		}
	};
}