/* eslint-disable */
import CountryEntity from '../Entity/Country';

const CountryFactory = function(data) {
	var country = new CountryEntity(),
		tourCriteria,
		element;

	element = data['id'];
	if(typeof element != "undefined") {
		country.set('id', element);
	}
	
	element = data['name'];
	if(typeof element != "undefined") {
		country.set('name', element);
	}

	element = data['position'];
	if(typeof element != "undefined") {
		country.set('position', element);
	}

	element = data['isPopular'];
	if(typeof element != "undefined") {
		country.set('isPopular', element ? true : false);
	}

	element = data['visaDays'];
	if(typeof element != "undefined") {
		country.set('visaDays',  element);
	}
	
	element = data['visaOnline'];
	if(typeof element != "undefined" && data['visaDays'] > 0) {
		country.set('visaOnline',  element);
	}
	
	element = data['visaRequired'];
	if(typeof element != "undefined" && data['visaDays'] > 0) {
		country.set('visaRequired',  element);
	}

	element = data['capitalTourCriteria'];
	if(typeof element != "undefined") {
		country.set('capitalTourCriteria',  element);
	}

	element = data['regionTourCriteria'];
	if(typeof element != "undefined") {
		country.set('regionTourCriteria',  element);
	}


	element = data['nameEng'];
	if(typeof element != "undefined") {
		country.set('nameEng', element);
	}

	element = data['tourCriteria'];
	if(element) {
		country.set('tourCriteria', element);
	}

	return country;
};

export default CountryFactory;