/* eslint-disable */
export const getAlternativeCountry = (blockCountries, countryModel) => {
    const countryId = countryModel.get('id');
    const blockedCountry = blockCountries.data.find(country => country.id === countryId);

    if (!blockedCountry?.alternativeCountry) {
        return countryModel.getRussia();
    }

    const { alternativeCountry: alternativeCountryId } = blockedCountry;
    const alternativeCountry = countryModel.getCountryModelById(alternativeCountryId);

    return alternativeCountry || countryModel.getRussia();
};
