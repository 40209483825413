/* eslint-disable */
import setAdditionalMetaOptions from '@/js/common/Service/setAdditionalMetaOptions';
import requestService from './request';

let cache = {};
_.mixin({
    chunk(array, chunkSize) {
        const chunks = [];
        const arrayCopy = array.slice();

        while (arrayCopy.length) {
            chunks.push(arrayCopy.splice(0, chunkSize));
        }
        return chunks;
    },
});


export default async function getMinPriceByCriteriasService(options = {}) {
    const {tourCriterias, clearCache} = options;
    const prices = [];
    const promises = [];
    const tourCriteriaHashesForRequest = [];
    const tourCriteriaHashes = [];

    //for tests
    if (clearCache) {
        cache = {};
        return null;
    }

    if (!(tourCriterias && tourCriterias instanceof Array)) {
        throw new Error('No criteria provided');
    }

    _.each(tourCriterias, (tourCriteria) => {
        const tourCriteriaHash = JSON.stringify(tourCriteria);
        const priceFromCache = cache[tourCriteriaHash];

        tourCriteriaHashes.push(tourCriteriaHash);

        if (!_.isUndefined(priceFromCache)) {
            if (priceFromCache instanceof Promise) {
                promises.push(priceFromCache);
            } else {
                prices.push({
                    criteria: tourCriteriaHash,
                    minPrice: cache[tourCriteriaHash],
                });
            }
        } else {
            tourCriteriaHashesForRequest.push(tourCriteriaHash);
        }
    });

    const requestPricesPack = (criterias) => {
        const requestOptions = {
            url: `${window.appConfig.apiGatewayUrl}/frontend/prices/searchMinPriceByCriterias`,
            method: 'POST',
            data: {
                tourCriterias: criterias,
                ...setAdditionalMetaOptions(),
            },
        };

        return new Promise((res) => {
            requestService(requestOptions, {
                success(response) {
                    const data = response.result || [];
                    if (response.success === true && response.result && response.result.length) {
                        _.each(response.result, (criteriaPrice) => {
                            cache[criteriaPrice.criteria] = criteriaPrice.minPrice;
                        });
                        res(data);
                    } else {
                        res([]);
                    }
                },
                error() {
                    res([]);
                },
            });
        });
    };

    if (tourCriteriaHashesForRequest.length) {
        _.each(_.chunk(tourCriteriaHashesForRequest, 5), (criterias) => {
            const promise = requestPricesPack(criterias);
            promises.push(promise);
            _.each(criterias, (criteria) => {
                cache[criteria] = promise;
            });
        });
    }
    const response = await Promise.all(promises);

    const asyncPrices = _.chain(response)
        .flatten()
        .filter(item => ~tourCriteriaHashes.indexOf(item.criteria))
        .uniq(item => item.criteria)
        .value();

    return prices.concat(asyncPrices);
}