import trackingDefaultDataTypesHydrator from '@/js/common/Domain/Service/tracking/trackingDefaultDataTypes';

let tourProductsData = [];
let lastSearchUuid = null;

export default async (tourDescriptionsData, searchUuid) => {
    if (searchUuid !== lastSearchUuid) {
        tourProductsData = [];
        lastSearchUuid = searchUuid;
    }
    const newTourDescriptionsData = _.filter(tourDescriptionsData, (tourDescription) => !~tourProductsData.indexOf(tourDescription.identity));
    if (newTourDescriptionsData?.length) {
        tourProductsData = tourProductsData.concat(_.pluck(newTourDescriptionsData, 'identity'));

        return {
            tourDescriptionsData: newTourDescriptionsData,
            searchUuid,
            ...await trackingDefaultDataTypesHydrator({
                timestamp: (new Date()).getTime(),
            }),
        };
    }

    return null;
};