/* eslint-disable */
//global methods
'use strict';
import gaAsync from "@/js/common/Domain/Service/gaAsync";
import SubscribeEmailFormView from "@/js/desktop/components/subscribeForm/subscribeFormOther";

import '@/css/common/flags.scss';
import wildcardDomain from "@/js/common/Domain/Service/wildcardDomain";


var LayoutScripts = {
    initSubscribes: function() {
        var $subscribes = $('.commonSubscribe form').not('.added'),
                subscribeXHR;

        if($subscribes.length) {
            $subscribes.each(function() {
                var self = this;

                $(this).addClass('added');

                $(this).find('.sendAgain').click(function() {
                    $(self).removeClass('error success disabled');
                    return false;
                });

                $(this).submit(function() {
                    var $form = $(this),
                            ajaxType = $form.attr('method') == "POST" ? "POST" : "GET",
                            submitUrl = $form.attr('action'),
                            self = this,
                            errorText = 'Произошла ошибка. Попробуйте использовать другой адрес электронной почты.',
                            $errorElement;

                    if(!$form.hasClass('disabled')) {
                        $form.validate({
                            errorElement: 'div',
                            errorClass: 'error',
                            rules: {
                                email: {
                                    required: true,
                                    email: true
                                }
                            },
                            messages: {
                                email: {
                                    required: "Обязательное поле",
                                    email: "Пожалуйста, введите действительный адрес электронной почты. Например johndoe@domain.com"
                                }
                            },
                            errorPlacement: function(error, element) {
                                if(element.is(":checkbox")) {
                                    element.closest('label').after(error);
                                } else if(element.parent().hasClass('container-input__index')) {
                                    error.insertAfter(element.parent());
                                } else {
                                    error.insertAfter(element);
                                }
                            }
                        });

                        if($form.find('input[name="useragreement"]').length) {
                            $form.find('input[name="useragreement"]').rules("add", {
                                required: true,
                                messages: {
                                    required: "Пожалуйста, примите соглашение",
                                }
                            });
                        }

                        if($form.valid() && !$(this).hasClass('disabled')) {
                            $(this).addClass('disabled');
                            if(subscribeXHR && subscribeXHR.readyState != 4) {
                                subscribeXHR.abort();
                            }

                            subscribeXHR = jQuery.ajax({
                                url: submitUrl,
                                dataType: 'json',
                                type: ajaxType,
                                data: $form.serialize(),
                                success: function(response) {
                                    if(response.success === true) {
                                        $form.addClass('success');
                                        $form.find('input[name="email"]').val('');
                                        //$form.find('input[name="useragreement"]').prop('checked', false);
                                        setTimeout(function() {
                                            $form.removeClass('success');
                                            $form.removeClass('disabled');
                                        }, 5000);

                                        gaAsync(function() {
                                            ga('send', 'event', 'Subscription', document.location.pathname)
                                        });
                                    } else {
                                        $errorElement = $form.find('input[name="email"]').parent().find('.error[for="email"]');
                                        $form.removeClass('disabled');

                                        if($errorElement.length) {
                                            $errorElement.text(errorText).show();
                                        } else if($form.find('input[name="email"]').parent().hasClass('container-input__index')) {

                                            if($form.find('input[name="email"]').parent().next().hasClass('.error')) {
                                                $form.find('input[name="email"]').parent().next().remove();
                                            }

                                            $('<div class="error">' + errorText + '</div>').insertAfter($form.find('input[name="email"]').parent());
                                        } else  {
                                            $errorElement = $('<div class="error">' + errorText + '</div>');
                                            $form.find('input[name="email"]').after($errorElement);
                                        }
                                        //$form.addClass('error');

                                        $form.find('input[name="email"]').addClass('error');

                                        if(typeof console != "undefined" && typeof console.log == "function") {
                                            console.log('Something wrong');
                                            console.log(response.message);
                                        }
                                    }
                                },
                                error: function(XMLHttpRequest, textStatus, errorThrown) {
                                    $form.addClass('error');
                                }
                            });
                        }
                    }
                    return false;
                });
            });
        }
    },
    initHiddenLinks: function() {
        $('.wrapper, .footer, .inner, .top-banner-wrap-js').on('click, mousedown', 'a[data-url]', function() {
            $(this).attr('href', $(this).attr('data-url'));
        });
    },
    scrollTo: function(position, time) {
        position = position ? position : 0;
        time = time ? time : 300;
        $("html, body").animate({
            scrollTop: position
        }, 300);

    },
    initEvents: function() {
        var self = this;
        $('#footerScrollTop').click(function() {
            self.scrollTo(0, 600);
        });

        $(function() {
            var press = location.hash;

            if($(".staticPage").length && press !== "") {
                $('html, body').animate({
                    scrollTop: $('[data-press=' + press.slice(1) + ']').eq(0).position().top - 10
                }, 1000);
            }
        });

         $('.wrapper, .footer, .atlasContainer').on('click, mousedown', '[data-jump]', function() {
            var topMargin, pos;
            topMargin = ($(this).attr("data-jump-top")) ? $(this).attr("data-jump-top") : 40;
            pos = $("." + $(this).attr("data-jump") + ":first").offset().top - topMargin;
            self.scrollTo(pos, 600);
            return false;
        });

        $("#siteMenu a").on("click", function() {
            var siteSection = $(this).attr("href");

            switch(siteSection) {
                case "/search":
                    gaAsync(function() {
                        ga('send', 'event', 'Buttons', 'Tabs', 'All_Tours', {'nonInteraction': 1})
                    });
                    break;
                case "/tury":
                    gaAsync(function() {
                        ga('send', 'event', 'Buttons', 'Tabs', 'Hot_Tours', {'nonInteraction': 1})
                    });
                    break;
                case "/ski":
                    gaAsync(function() {
                        ga('send', 'event', 'Buttons', 'Tabs', 'Ski_Tours', {'nonInteraction': 1})
                    });
                    break;
                case "/earlybooking":
                    gaAsync(function() {
                        ga('send', 'event', 'Buttons', 'Tabs', 'Earlybooking_Tours', {'nonInteraction': 1})
                    });
                    break;
                case "/newyear-tours":
                    gaAsync(function() {
                        ga('send', 'event', 'Buttons', 'Tabs', 'Newyear_Tours', {'nonInteraction': 1})
                    });
                    break;
            }

        });

        $("#menuLink").click(function() {
            $(this).addClass("open");
            $("#siteMenu").slideDown(function() {
                $('body').one('click', function() {
                    $("#menuLink").removeClass('open');
                    $("#siteMenu").slideUp(function() {
                        $(this).removeAttr('style');
                    })
                })
            });
        });

        $(".serpFilterDropdown").click(function() {
            $(this).toggleClass("active");
        });

        $(".serpFilterName").click(function() {
            $(".serpFilterDropdown").removeClass("active");
        });

        $(document).click(function(event) {
            var $dropdowns = $(".serpFilterDropdown.active"),
                    $currentTarget = $(event.target).parents('.serpFilterDropdown');

            $dropdowns.not($currentTarget).removeClass('active');
        });

        if($(".searchContainer:not(.disableLoaderScroll) .anim.loader").length == 1) {
            var $preloader = $(".preloader-wrap"),
                    resultsHeight = $('#flyingResultTextSearch .bg').height(),
                    headerHeight = $('.header .headerContainer').height(),
                    loaderTop = $(".searchContainer .anim.loader").offset().top,
                    containerPadding = parseInt($(".searchContainer:visible").css('padding-top').slice(0, -2)) + parseInt($(".searchContainer:visible").css('padding-bottom').slice(0, -2)),
                    topTriggerOffset = loaderTop - (resultsHeight ? resultsHeight : 0) - (headerHeight ? headerHeight : 0),
                    topOffset = parseInt($(".searchContainer .anim.loader .inner").css('padding-top').slice(0, -2)),
                    blockHeight = $(".preloader-wrap").height();
            $(window).scroll(function() {
                if($(window).scrollTop() > topTriggerOffset) {
                    $preloader.addClass("preloaderFixed");
                    loaderTop = $(".searchContainer .anim.loader").offset().top;
                    topTriggerOffset = loaderTop - (resultsHeight ? resultsHeight : 0) - (headerHeight ? headerHeight : 0);
                    var blockAbout = $(".aboutAtlasCountry").height();
                    blockAbout = blockAbout ? blockAbout : 0;
                    var topPos = $(window).scrollTop() - topTriggerOffset + topOffset,
                            maxTop = $('.searchContainer:visible').height() + $('.searchContainer:visible').offset().top - blockHeight - loaderTop - topOffset + containerPadding + blockAbout;
                    if(topPos > maxTop) {
                        topPos = maxTop;
                    }
                    $preloader.css({
                        "bottom": "auto",
                        "top": topPos
                    });
                } else {
                    $preloader.removeClass("preloaderFixed");
                }
            });
        }

        $('body').on('click', '.wrapper', function(e) {
            if(!$(e.target).parents('.focused').length && !$(e.target).hasClass('.focused'))
                $('.focused').removeClass('focused');
        });
    },
    initAdvantages: function() {
        $('.wrapper').on('click', '#advantages', function(e) {
            var wh = $(window).height(),
                    wtop = $(window).scrollTop(),
                    h = $(this).height(),
                    top = $(this).offset().top;
            if(top - 75 > wtop && wtop + wh < top + h) {
                e.preventDefault();
                e.stopPropagation();
                $('html, body').animate({
                    scrollTop: top - 75
                }, 500);
            }
        });
    },
    initSmartLinks: function() {
        $(".smLink").each(function() {
            var n = "", i = "", a = "", t = "", o = "", p = "",
                    d = $(this).attr($(this).attr("title") ? "title" : "href");

            $(this).attr("itemprop") && (p = "itemprop='" + $(this).attr("itemprop") + "'");
            $(this).attr("target") && (o = "target='" + $(this).attr("target") + "'");
            $(this).attr("alt") && (t = "title='" + $(this).attr("alt") + "'");
            $(this).attr("id") && (n = "id='" + $(this).attr("id") + "'");
            $(this).attr("class") && (a += " " + $(this).attr("class"));
            $(this).replaceWith("<a href='" + d + "' " + o + p + " class='" + a + "' " + i + " " + n + " " + t + " " + ">" + $(this).html() + "</a>")
        });
    },
    initDynamicSmartLinksListener: function() {
        setInterval(function(){
            $(".dynamicSmLink:not(.processed)").each(function() {
                var n = "", i = "", a = "", t = "", o = "", p = "",
                        d = $(this).attr($(this).attr("title") ? "title" : "href");

                $(this).attr("itemprop") && (p = "itemprop='" + $(this).attr("itemprop") + "'");
                $(this).attr("target") && (o = "target='" + $(this).attr("target") + "'");
                $(this).attr("alt") && (t = "title='" + $(this).attr("alt") + "'");
                $(this).attr("id") && (n = "id='" + $(this).attr("id") + "'");
                $(this).attr("class") && (a += " " + $(this).attr("class"));
                $(this).replaceWith("<a href='" + d + "' " + o + p + " class='" + a + " processed' " + i + " " + n + " " + t + " " + ">" + $(this).html() + "</a>")
            });
        }, 1000);
    },
    initMobileDesktopSwitch: function() {
        if($.cookie('backToMobile') == 1) {
            $('body').scrollTop(0);
        }

        $('.switchToMobile').on('click', function(e) {
            var $link = $(e.currentTarget);

            $.getJSON($link.attr('href'), function(data) {
                if(data.success) {
                    $('body').scrollTop(0);
                    $.cookie('backToMobile', 0, {path: '/', domain: wildcardDomain()});
                    location.reload();
                }
            });
            e.preventDefault();
            return false;
        });
    },
    initPopupWhyEvents: function() {
        $('.popupWhy .smLink').on('click', function(e) {
            var href = $(e.currentTarget).attr('href');
            var timeout = setTimeout(function() {
                window.location.href = href;
            }, 2000);
            gaAsync(function() {
                ga('send', 'event', 'Popup', 'why us', 'link clicked', {
                    'hitCallback': function() {
                        clearTimeout(timeout);
                        window.location.href = href;
                    }
                });
            });
            e.preventDefault();
            return false;
        });
        $('.popupWhy').on('mouseenter', function(e) {
            gaAsync(function() {
                ga('send', 'event', 'Popup', 'why us', 'banner shown');
            });
        });
    },
    initEmailSubscribe: function() {
        new SubscribeEmailFormView({
            el: '.subscribeEmail'
        });
	},
    init: function() {
        this.initSubscribes();
        this.initHiddenLinks();
        this.initEvents();
        this.initAdvantages();
        this.initSmartLinks();
        this.initDynamicSmartLinksListener();
        this.initMobileDesktopSwitch();
        this.initPopupWhyEvents();
		this.initEmailSubscribe();	
    }
};

$(() => {
    LayoutScripts.init();
});
