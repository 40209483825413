/* eslint-disable */
import moment from 'moment';
import 'moment/locale/ru';

import { arrayMove } from './index';

export const checkOnBlock = (tourCriteria, blockCountries) => {
	if (!blockCountries || !blockCountries?.data?.length) return false;

	const countries = tourCriteria.get('countries').models;
	const checkInDateRange = tourCriteria.get('checkInDateRange');
	const dateFrom = checkInDateRange.get('from');
	const dateTo = checkInDateRange.get('to');
	let blockedCounter = 0;
	let notBlockedCountryIndex = 0;

	_.each(countries, (country, index) => {
		const id = country.get('id');
		const blockCountry = _.findWhere(blockCountries.data, {
			id,
		});

		if (blockCountry) {
			let compareDate;

			if (dateFrom.getTime() !== dateTo.getTime()) compareDate = dateTo;
			else compareDate = dateFrom;

			const isBlockedTour = moment(moment(compareDate).format('YYYY-MM-DD')).isSameOrBefore(blockCountry.disabledBefore);

			if (isBlockedTour) blockedCounter++;
		} else {
			notBlockedCountryIndex = index;
		}
	});

	const isCovidCountries = blockedCounter === countries.length;

	if (!isCovidCountries && countries.length > 1 && notBlockedCountryIndex > 0) {
		const movedCountries = arrayMove(tourCriteria.get('countries').models, notBlockedCountryIndex, 0);
		if (movedCountries?.length) {
			tourCriteria.get('countries').models = movedCountries;
		}
	}

	return isCovidCountries;
};
