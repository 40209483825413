import { propsDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import TouristGroupView from '@/js/common/components/touristGroupView/TouristGroupView';

import dropDownTemplate from './templates/dropDownTemplate.html';
import './styles/touristGroupView.scss';

@propsDecorator({
    template: dropDownTemplate,
})

export default class TouristGroupDesktop extends TouristGroupView {
    initSpinner() {
        this.fromField.spinner({
            min: 1,
            max: 6,
            icons: { down: 'ui-icon-minus', up: 'ui-icon-plus' },
        }).on('spinstop', this.controlChanged.bind(this))
            .on('change', this.updateButton);
    }

    updateButtons() {
        this.updateButton.call(this.fromField);
    }

    updateButton(event, params) {
        const value = params && params.value ? params.value : $(this).val();
        const min = $(this).spinner('option', 'min');
        const max = $(this).spinner('option', 'max');
        const $decreaseButton = $(this).parent().find('.ui-spinner-down');
        const $increaseButton = $(this).parent().find('.ui-spinner-up');
        if (value <= min) {
            $decreaseButton.addClass('ui-state-disabled');
        } else {
            $decreaseButton.removeClass('ui-state-disabled');
        }

        if (value >= max) {
            $increaseButton.addClass('ui-state-disabled');
        } else {
            $increaseButton.removeClass('ui-state-disabled');
        }
    }

    getAdults() {
        return parseInt(this.fromField.spinner('value'), 10) || this.model?.get('model')?.get('adults');
    }

    setValue(model) {
        const { adults, kids, infants } = model.toJSON();
        this.setLabel({ adults, kids, infants });
    }

    modelChanged(event, model, context) {
        if (context?.initiator !== 'view') {
            if (model === null) {
                this.$el.addClass('disable');
            } else {
                this.$el.removeClass('disable');
                this.updateChildesList(model);
                this.setValue(model);
            }
        }
        if (model !== null) {
            this.fromField.spinner('value', model.get('adults'));
            this.updateButtons();
        }
    }

    initDOMVariables() {
        this.fromField = $('.tour-adults', this.$el);
        this.$inputText = $('.formInputPlace', this.$el);
    }

    initDomEvents() {
        this.$el.addClass('disable');
        $('> .formInput', this.$el).on('click', () => {
            if (!this.$el.hasClass('disable')) {
                this.$el.toggleClass('open');
                this.dataLayerAnalyticsEvent();
            }
        });
        $(document).on('click', (event) => {
            const $dropdowns = this.$el.filter('.open');
            const $clickedDropdown = $(event.target);
            const $clickedContainer = $clickedDropdown.parents('.formDropdown');
            const $hideDropdowns = $dropdowns.not($clickedContainer).not($clickedDropdown);

            $hideDropdowns.removeClass('open');
        });

        this.$el.addClass('disable');
    }

    render() {
        this.$el.html(this.template({ cid: this.cid }));
    }

    initialize(options = {}) {
        this.$openLink = $(options.openLink);
        super.initialize(options);
        this.initSpinner();
    }
}