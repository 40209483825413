
    import { defineComponent, computed, unref } from 'vue';
    import { useState, useActions } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import FilterListRender from '@ve/components/filters/common/components/FilterListRender/FilterListRender.vue';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import FiltersListSection from '@ve/components/searchFilters/filterList/desktop/FiltersListSection.vue';
    import useLogicRestType from '@ve/components/filters//common/use/useLogicRestType';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import FilterAttributeDistanceLiftMethods from './filtersMethods/FilterAttributeDistanceLiftMethods';
    import { DistanceFilterId } from './types';

    export default defineComponent({
        components: {
            FiltersListSection,
            FilterPostWrapper,
            FilterListRender,
        },
        setup() {
            const {
                typeDistanceLift,
                filteredHotelsWithoutDistanceLift,
                syncHotels,
                isPopularityFilterActive,
            } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['typeDistanceLift', 'filteredHotelsWithoutDistanceLift', 'syncHotels', 'isPopularityFilterActive']);

            const { action_typeDistanceLift: setTypeDistanceLift } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_typeDistanceLift']);

            const selectedDistanceLift = computed({
                get: () => unref(typeDistanceLift),
                set: (values: DistanceFilterId[]) => {
                    setTypeDistanceLift(values);
                },
            });

            return {
                selectedDistanceLift,
                filteredHotelsWithoutDistanceLift,
                syncHotels,
                FilterAttributeDistanceLiftMethods,
                ...useLogicRestType(FilterAttributeDistanceLiftMethods),
                isPopularityFilterActive,
            };
        },
    });
