
    import { defineComponent, computed, unref } from 'vue';
    import { useState, useActions } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState, IMapForFiltersSerpActions } from '@ve/components/searchFilters/store/types';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import FiltersListSection from '@ve/components/searchFilters/filterList/desktop/FiltersListSection.vue';
    import FilterListRender from '@ve/components/filters/common/components/FilterListRender/FilterListRender.vue';
    import useLogicRestType from '@ve/components/filters/common/use/useLogicRestType';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import FilterAttributeBeachLineMethods from './filterMethods/FilterAttributeBeachLineMethods';
    import useFilterAttributesAnalytics from '../common/use/useFilterAttributesAnalytics';

    export default defineComponent({
        components: {
            FilterPostWrapper,
            FilterListRender,
            FiltersListSection,
        },
        setup() {
            const {
                typeLines,
                filteredHotelsWithoutBeachLine,
                syncHotels,
                isPopularityFilterActive,
            } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['typeLines', 'filteredHotelsWithoutBeachLine', 'syncHotels', 'isPopularityFilterActive']);
            const { action_typeLines: setTypeLines } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_typeLines']);

            const selectedLines = computed({
                get: () => unref(typeLines),
                set: (values) => {
                    setTypeLines(values);
                },
            });

            return {
                FilterAttributeBeachLineMethods,
                selectedLines,
                filteredHotelsWithoutBeachLine,
                syncHotels,
                isPopularityFilterActive,
                ...useFilterAttributesAnalytics(FilterAttributeBeachLineMethods, { nameAnyEventEng: 'beach_line_any', typeEvent: 'beach', nameAnyEventRu: 'Линия пляжа_Любая' }),
                ...useLogicRestType(FilterAttributeBeachLineMethods),
            };
        },
    });
