import {
    onCLS,
    onFID,
    onLCP,
    onINP,
    Metric,
} from 'web-vitals';
import trackingService from './tracking';

const { coreWebVitals } = trackingService;
const coreWebVitalsTrack = coreWebVitals.bind(trackingService);
const trackProxy = (options: Metric) => coreWebVitalsTrack(options);

onCLS(trackProxy);
onFID(trackProxy);
onLCP(trackProxy);
onINP(trackProxy);
