
    import {
        defineComponent, PropType,
    } from 'vue';

    export default defineComponent({
        name: 'Tooltip',
        props: {
            typeTooltip: {
                type: String as PropType<'white' | 'black'>,
                default: 'white',
            },
            contentPosition: {
                type: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
                default: 'top',
            },
        },
    });
