
    import { defineComponent, PropType } from 'vue';
    import InputCommon from '@ve/components/inputs/InputCommon.vue';
    import clickOutside from '@ve/services/directives/clickOutside';
    import Loader from '@ve/components/loader/common/Loader.vue';
    import ButtonCommon from '@fr/vue/components/button/desktop/Button.vue';
    import gaAsync from '@/js/common/Domain/Service/gaAsync';
    import useFilterBySearchHotel from '../common/use/useFilterBySearchHotel';
    import DropDownList from './components/DropDownList.vue';
    import HotelItemFilter from './components/HotelItem.vue';

    export default defineComponent({
        name: 'FilterSearchHotel',
        props: {
            takenHotels: {
                type: Array as PropType<{ id: number, name: string }[]>,
                default: () => [],
            },
            foundHotels: {
                type: Array as PropType<{ id: number, name: string }[]>,
                default: () => [],
            },
        },
        directives: {
            clickOutside,
        },
        components: {
            InputCommon,
            DropDownList,
            HotelItemFilter,
            Loader,
            ButtonCommon,
        },
        setup(props, context) {
            const sendAnalitics = () => {
                gaAsync(() => {
                    ga('set', 'dimension10', 'Hotel');
                });
            };
            return {
                ...useFilterBySearchHotel(props, context),
                sendAnalitics,
            };
        },
    });
