import departureCity from './fromRouter/departureCity';
import countries from './fromRouter/countries';
import operators from './fromRouter/operators';
import resorts from './fromRouter/resorts';
import checkInDateRange from './fromRouter/checkInDateRange';
import nightRange from './fromRouter/nightRange';
import touristGroup from './fromRouter/touristGroup';
import hotelCategories from './fromRouter/hotelCategories';
import meals from './fromRouter/meals';
import hotels from './fromRouter/hotels';
import priceRange from './fromRouter/priceRange';
import directFlights from './fromRouter/directFlights';
import warranted from './fromRouter/warranted';
import isExclusive from './fromRouter/isExclusive';

import '../../plugins/jquery.unparam.min';

export default (urlParams) => {
    if (typeof urlParams !== 'string') {
        return null;
    }

    const params = $.unparam(urlParams);

    const tourCriteria = {
        ...departureCity(params),
        ...countries(params),
        ...operators(params),
        ...resorts(params),
        ...checkInDateRange(params),
        ...nightRange(params),
        ...touristGroup(params),
        ...hotelCategories(params),
        ...meals(params),
        ...hotels(params),
        ...priceRange(params),
        ...directFlights(params),
        ...warranted(params),
        ...isExclusive(params),
    };

    const other = _.omit(params, 'fromCity', 'toCountry', 'operators', 'toCity', 'dateFrom', 'dateTo', 'dateFlexible', 'nightFrom', 'nightTo',
        'adults', 'ages', 'kids', 'infants', 'hotelClass', 'meal', 'hotels', 'priceFrom', 'priceTo', 'directFlight', 'warranted', 'isExclusive');

    return {
        tourCriteria,
        other,
    };
};