import { useState, useMutations } from 'vuex-composition-helpers';
import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';

import BudgetFilterModel, { NAMESPACE, type BudgetFilterMutations, type BudgetFilterState } from '../model/BudgetFilterStore';

function useBudgetFilterModel() {
    registerModule.register(getStore(), BudgetFilterModel, NAMESPACE);
    const { maxPriceFilter, minPriceFilter } = useState<BudgetFilterState>(NAMESPACE, ['maxPriceFilter', 'minPriceFilter']);
    const { setMinPrice, setMaxPrice } = useMutations<BudgetFilterMutations>(NAMESPACE, ['setMaxPrice', 'setMinPrice']);

    return {
        maxPriceFilter,
        minPriceFilter,
        setMinPrice,
        setMaxPrice,
    };
}

export default useBudgetFilterModel;