import getCustomerHash from '@/js/desktop/components/account/service/getCustomerHash';
import getMainMetaData, { ImainMetaDataReturn } from '@/js/common/Domain/Service/tracking/getMainMetaData';

export interface ItrackingDefaultDataTypes extends ImainMetaDataReturn {
    'customer_hash': string | null
}

export default async (options: { timestamp: number}): Promise<ItrackingDefaultDataTypes> => ({
    ...getMainMetaData(options),
    customer_hash: await getCustomerHash(),
});