module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="WWcontent_row'+
((__t=( currentDate?' active':'' ))==null?'':__t)+
''+
((__t=( waterTemperature?'':' noWater' ))==null?'':__t)+
'">\r\n	<div class="WWcontent_col1_1 '+
((__t=( type ))==null?'':__t)+
'"><span>'+
((__t=( label ))==null?'':__t)+
'</span></div>\r\n	';

	if(type == 'daily'){
	
__p+='<div class="WWcontent_col2"><span>'+
((__t=( shortDate ))==null?'':__t)+
'</span></div>';

	}
	
__p+='\r\n	';

	if(airTemperature !== null){
	airTemperature = Math.round(airTemperature);
	
__p+='<div class="WWcontent_col3"><i class="icon '+
((__t=( icon ))==null?'':__t)+
'"></i><span>'+
((__t=( (airTemperature > 0?'+':'') + airTemperature ))==null?'':__t)+
'</span></div>';

	}
	
__p+='\r\n	';

	if(waterTemperature !== null){
	waterTemperature = Math.round(waterTemperature);
	
__p+='<div class="WWcontent_col3"><i class="icon icon-i16_water2"></i><span>'+
((__t=( (waterTemperature > 0?'+':'') + waterTemperature ))==null?'':__t)+
'</span></div>';

	}
	
__p+='\r\n</div>';
}
return __p;
};
