import { IFilterFacility } from '.';

const facilitiesAttributesHotelData: IFilterFacility[] = [
    {
        id: 1,
        key: 'lonelyMan',
        name: 'Отель принимает одиноких мужчин',
        keyMerged: 'lonelyMan',
        nameLayer: 'Удобства_Принимает одиноких мужчин',
        nameEvent: '',
    },
    {
        id: 6,
        key: 'airConditioner',
        keyMerged: 'airConditioner',
        name: 'Кондиционер',
        nameLayer: 'Удобства_Кондиционер',
        nameEvent: 'airConditioner',
    },
    {
        id: 7,
        key: 'wifi',
        keyMerged: 'wifi',
        name: 'Wi-Fi (Интернет)',
        nameLayer: 'Удобства_Интернет',
        nameEvent: 'wifi',
    },
    {
        id: 13,
        key: 'spaAndHealth',
        keyMerged: 'spaAndHealth',
        name: 'Спа / Оздоровительный центр',
        nameLayer: 'Удобства_Спа',
        nameEvent: '',
    },
    {
        id: 2,
        key: 'bayLocated',
        keyMerged: 'bayLocated',
        name: 'Отель расположен в бухте',
        nameLayer: '',
        nameEvent: '',
    },
    {
        id: 3,
        key: 'adultsOnly',
        keyMerged: 'adultsOnly',
        name: 'Только для взрослых',
        nameLayer: '',
        nameEvent: '',
    },
    {
        id: 4,
        key: 'withKids',
        keyMerged: 'withKids',
        name: 'Отдых с детьми',
        nameLayer: 'Удобства_Отдых с детьми',
        nameEvent: '',
    },
    {
        id: 5,
        key: 'forCouples',
        keyMerged: 'forCouples',
        name: 'Идеально для пар',
        nameLayer: 'Удобства_Идеально для пар',
        nameEvent: '',
    },
    {
        id: 8,
        key: 'withAnimals',
        keyMerged: 'withAnimals',
        name: 'Отдых с животными',
        nameLayer: 'Удобства_Отдых с животными',
        nameEvent: '',
    },
    {
        id: 14,
        key: 'parking',
        keyMerged: 'parking',
        name: 'Парковка',
        nameLayer: '',
        nameEvent: '',
    },
    {
        id: 15,
        key: 'saunaBathHammam',
        keyMerged: 'saunaBathHammam',
        name: 'Сауна / Баня / Хаммам',
        nameLayer: 'saunaBathHammam',
        nameEvent: '',
    },
    {
        id: 19,
        key: 'jacuzzi',
        keyMerged: 'jacuzzi',
        name: 'Джакузи',
        nameLayer: 'jacuzzi',
        nameEvent: '',
    },
];

export default facilitiesAttributesHotelData;