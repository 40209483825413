
    import {
        defineComponent,
    } from 'vue';
    import CheckBoxInput from '@ve/components/inputs/CheckBoxInput.vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import TextCommon from '@ve/components/inputs/TextCommon.vue';
    import HintTooltip from '@ve/components/hintTooltip/desktop/HintTooltip.vue';
    import useIsExclusiveFilter from '../common/use/useIsExclusiveFilter';

    export default defineComponent({
        name: 'is-exclusive-filter-serp',
        components: {
            HintTooltip,
            TextCommon,
            FilterListItem,
            CheckBoxInput,
        },
        setup() {
            return {
                ...useIsExclusiveFilter(),
            };
        },
    });
