/* eslint-disable */
import AjaxRequestManager from '../AjaxRequestManager';
import EntityManager from '../EntityManager';
import ResortsRepository from '../Repository/Resorts';
import ResortEntity from '../Entity/Resort';
import CountryFactory from '../Factory/Country';
import ResortFactory from "../Factory/Resort";
import CountriesRepository from "../Repository/Countries";
import HotelsRepository from "../Repository/Hotels";
import HotelFactory from "../Factory/Hotel";
import backboneModelDeepInstanceOf from "@/js/common/Domain/Service/backboneModelDeepInstanceOf";


const DestinationService = function(params) {
    var entityManager = new EntityManager(),
        ajaxRequestManager = new AjaxRequestManager({
			abortPrevious:false
		}),
        userInputTimeout,
        cache = {},
        globalCallback = {},
        self = this;

    this.getOptions = function(term, callback) {
        term = term.toLowerCase();
        delete(globalCallback.error);
        delete(globalCallback.success);
        globalCallback = callback;

        if (cache[term]) {
            callback && callback.success ? callback.success(cache[term]): null;
        } else {
            ajaxRequestManager.getData({
                url: window.appConfig.intelligentSearch,
                retriesAvailable: true,
                data: {
                    term: term
                }
            }, {
                success: function(data) {
                    var data = self.processData(data);

                    cache[term] = data;
                    callback && callback.success ? callback.success(data) : null;
                },
                error: function () {}
            });
        }

        clearTimeout(userInputTimeout);
        userInputTimeout = setTimeout(function() {
            console.log('IntelligentSearchInput:', term);
        }, 1000);
    };

    this.processData = function(destinationsData) {
        var data = [],
            processResort = function(resort) {
                var country,
                    countryResorts;

                country = resort.get('country');
                countryResorts = backboneModelDeepInstanceOf({model:country}) ? country.get('resorts') : null;
                if(!countryResorts) {
                    country.set('resorts', new ResortsRepository(resort));
                } else if(!countryResorts.contains(resort)){
                    countryResorts.add(resort);
                }
            };

        destinationsData = _.map(destinationsData, function(destination) {
            var factory,
                hotelResort,
                repository;

            switch (destination.type) {
                case 'country':
                    factory = CountryFactory;
                    repository = CountriesRepository.repository;
                    break;
                case 'resort':
                    factory = ResortFactory;
                    repository = ResortsRepository.repository;
                    break;
                case 'hotel':
                    factory = HotelFactory;
                    repository = HotelsRepository.repository;
                    break;
            }

            destination = entityManager.add({
                factory:factory,
                repository:repository
            }, destination);

            if(destination instanceof ResortEntity) {
                processResort(destination);
            }

            return destination;
        });

        return destinationsData;
    };

    return this;
};

export default DestinationService;