import { IReviewRating } from '../types';

const dataReviewsRatings: IReviewRating[] = [
    {
        id: 0,
        rating: '0.0',
        key: 'noScore',
        keyMerged: 'noScore',
        name: 'Все оценки',
    },
    {
        id: 1,
        rating: '4.5',
        key: 'best',
        keyMerged: 'best',
        name: 'Превосходно и выше',
    },
    {
        id: 2,
        rating: '4.0',
        key: 'better',
        keyMerged: 'better',
        name: 'Очень хорошо и выше',
    },
    {
        id: 3,
        rating: '3.5',
        key: 'good',
        keyMerged: 'good',
        name: 'Хорошо и выше',
    },
];

export default dataReviewsRatings;
