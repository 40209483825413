
    import { defineComponent } from 'vue';
    import CountryInfo from '../common/components/CountryInfo/CountryInfo.vue';
    import useCountriesInfo from '../common/composition/useCountriesInfo';

    const storeModule = 'mapForCountryConditions';

    export default defineComponent({
        components: {
            CountryInfo,
        },
        setup() {
            const { viewBannerCountry, infoCurrentCountry } = useCountriesInfo(storeModule);

            return {
                viewBannerCountry,
                infoCurrentCountry,
            };
        },
    });
