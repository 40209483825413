import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import DepartureCitiesRepository from '@/js/common/Domain/Repository/DepartureCities';
import SearchFormFieldModel from '@/js/common/Domain/Model/SearchFormField';
import SearchFormModel from '@/js/common/Domain/Model/SearchForm';
import ResortsRepository from '@/js/common/Domain/Repository/Resorts';
import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import OperatorsRepository from '@/js/common/Domain/Repository/Operators';

export default () => new SearchFormModel({
        destination: new SearchFormFieldModel(),
        departureCity: new SearchFormFieldModel({
            collection: DepartureCitiesRepository.repository,
        }),
        countries: new SearchFormFieldModel({
            collection: new CountriesRepository(),
            model: new CountriesRepository(),
        }),
        resorts: new SearchFormFieldModel({
            collection: new ResortsRepository(),
            model: new ResortsRepository(),
        }),
        hotels: new SearchFormFieldModel({
            model: new HotelsRepository(),
        }),
        operators: new SearchFormFieldModel({
            model: new OperatorsRepository(),
        }),
        checkInDateRange: new SearchFormFieldModel({
            model: false,
        }),
        nightRange: new SearchFormFieldModel({
            model: false,
        }),
        touristGroup: new SearchFormFieldModel({
            model: false,
        }),
        hotelCategories: new SearchFormFieldModel({
            model: false,
        }),
        meals: new SearchFormFieldModel({
            model: false,
        }),
        priceRange: new SearchFormFieldModel({
            model: false,
        }),
        directFlight: new SearchFormFieldModel({
            model: false,
        }),
        warranted: new SearchFormFieldModel({
            model: false,
        }),
        isExclusive: new SearchFormFieldModel({
            model: false,
        }),
    });