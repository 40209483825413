export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('hotelCategories');
    const hotelCategoriesWithoutStars = [5, 6, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    if (element) {
        if (element.length) {
            const hotelCategories = element.pluck('id');
            if (_.contains(hotelCategories, 7) && !_.contains(hotelCategories, 8)) {
                hotelCategories.push(8);
            }
            if (_.contains(hotelCategories, 4) && !_.contains(hotelCategories, 9)) {
                hotelCategories.push(9);
            }
            if (_.contains(hotelCategories, 2) && !_.contains(hotelCategories, 1)) {
                hotelCategories.push(1);
            }
            if (_.contains(hotelCategories, 0)) {
                hotelCategories.push(...hotelCategoriesWithoutStars);
            }
            tourCriteriaJson.hotelCategories = hotelCategories;
        } else {
            tourCriteriaJson.hotelCategories = [];
        }
    }

    return tourCriteriaJson;
};