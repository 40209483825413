import {
    computed, toRefs, unref, SetupContext,
} from 'vue';
import FilterAttributeMethods from '@ve/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { IFilterAttribute } from '@ve/components/filters/common/types';
import CheckBoxInputListWithOneSelect from '@ve/components/inputs/CheckBoxInputListWithOneSelect.vue';
import RadioInput from '@ve/components/inputs/RadioInput.vue';

interface IUseFilterListRender {
    selected: number[] | number,
    filteredEntities: Array<any>,
    allEntities: Array<any>,
    filterAttributeMethods: FilterAttributeMethods<IFilterAttribute, any>;
}

export default function (props: IUseFilterListRender, emit: SetupContext['emit']) {
    const { selected, filteredEntities, allEntities } = toRefs(props);
    const { filterAttributeMethods } = props;
    const isModelValuesArray = computed(() => Array.isArray(unref(selected)));
    const anySelectedModelArray = computed<boolean>({
        get: () => {
            const selectedIds = unref(selected) as number[];
            return !selectedIds.length;
        },
        set: () => {
            emit('change', []);
            emit('selectedAny');
        },
    });
    const anySelectedModelNumber = computed<number>({
        get: () => {
            const selectedId: number = unref(selected) as number;
            return selectedId;
        },
        set: () => {
            emit('change', 0);
            emit('selectedAny');
        },
    });

    const selectedValues = computed<number | number[]>({
        get: () => unref(selected),
        set: (value: number[] | number) => {
            emit('change', value);
        },
    });
    const selectedOneAttribute = ({ value, checked }: { value: number | string, checked: boolean }) => {
        if (checked) {
            emit('selectedOn', value);
            return;
        }
        emit('selectedOff', value);
    };
    const countFilteredEntities = computed(() => filterAttributeMethods.getCountAttribute(unref(filteredEntities)));
    const countAllEntities = computed(() => filterAttributeMethods.getCountAttribute(unref(allEntities)));
    const isDisabledAttributeFilter = (key: string, id: number) => {
        const objectCountFilteredEntities: Record<string, number> = unref(countFilteredEntities);
        if (unref(isModelValuesArray)) {
            const selectedIds = unref(selected) as number[];
            return !objectCountFilteredEntities[key] && selectedIds.indexOf(id) === -1;
        }
        return !objectCountFilteredEntities[key];
    };
    const isVisibleAttributeFilter = (key: string, allEntitiesCount: Record<string, number>) => {
        const isOneEntityHasAttribute = allEntitiesCount[key];
        return isOneEntityHasAttribute;
    };

    const listRender = computed(() => filterAttributeMethods.getListFilterAttributes()
        .filter((filterItem) => isVisibleAttributeFilter(filterItem.key, unref(countAllEntities))));

    return {
        anySelectedModelArray,
        anySelectedModelNumber,
        selectedValues,
        selectedOneAttribute,
        countFilteredEntities,
        listRender,
        countAllEntities,
        ComponentBoxList: unref(isModelValuesArray) ? CheckBoxInputListWithOneSelect : RadioInput,
        isDisabledAttributeFilter,
        isVisibleAttributeFilter,
        isModelValuesArray,
    };
}