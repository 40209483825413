import TourProductObjectValue from '../ObjectValue/TourProduct';
import TourFactory from './Tour';
import Product from './Product';

export default class extends Product {
    constructor(data, resorts) {
        super(data);

        this.type = 'TourProduct';

        let element = data?.tour?.id || null;
        if (typeof element !== 'undefined') {
            this.id = element;
        }

        element = data.hash;
        if (typeof element !== 'undefined') {
            this.hash = element;
        }

        element = data.tour;
        if (typeof element !== 'undefined') {
            this.tour = new TourFactory(data.tour, resorts);
        }

        element = data.transferType;
        if (typeof element !== 'undefined') {
            this.transferType = element;
        }

        element = data.firstPaymentDefinition;
        if (typeof element !== 'undefined') {
            this.firstPaymentDefinition = element;
        }

        element = data.firstPaymentDefinitions;

        if (typeof element !== 'undefined') {
            this.firstPaymentDefinitions = element;
        }

        element = data.allFirstPaymentDefinitions;
        if (typeof element !== 'undefined') {
            this.allFirstPaymentDefinitions = element;
        }

        element = data.availableTransfers;
        if (typeof element !== 'undefined') {
            this.tour.set('availableTransfers', element);
        }

        if(data.tour?.nights?.tour) {
            this.nights = data.tour.nights.tour
        }

        if(data.tour?.nights?.hotel) {
            this.nightsInHotel = data.tour.nights.hotel;
        }


        return new TourProductObjectValue(this);
    }
}