
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import moment from 'moment';

    @Component({
        name: 'CountdownTimer',
    })
    export default class CountdownTimer extends Vue {
        @Prop() readonly endTime!: string;

        timerInterval: null | ReturnType<typeof setTimeout> = null

        time = ''

        mounted():void {
            this.startTimer();
        }

        beforeDestroy():void {
            this.endTimer();
        }

        startTimer():void {
            this.refreshTime();
            this.timerInterval = setInterval(() => {
                this.refreshTime();
            }, 1000);
        }

        endTimer():void {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
        }

        private refreshTime():void {
            let seconds = 0;
            const now = moment();
            const endTime = moment(this.endTime);
            if (now.isBefore(endTime)) {
                seconds = endTime.diff(moment(), 'seconds');
            }

            let format = 'HH:mm:ss';
            if (seconds < 3600) {
                format = 'mm:ss';
            }
            this.time = moment.utc(seconds * 1000).format(format);
        }
    }
