/* eslint-disable */
import TourCriteriaObjectValue from "../ObjectValue/TourCriteria";
import moment from "moment";
import { TrackJS } from 'trackjs';
import wildcardDomain from "@/js/common/Domain/Service/wildcardDomain";
import backboneModelDeepInstanceOf from "@/js/common/Domain/Service/backboneModelDeepInstanceOf";

export default class DataLayerAnalytics {
	constructor(options) {
		if(options){
			this.isHotTourView = options.isHotTourView;
			this.bannersStep = options.bannersStep || null;
			if(options.step && options.maxStep){
				this.basicEventsWasInit = false;
				this.step = options.step;
				this.setStart();
				this.setMaxStep(options.maxStep);
				this.tab_id = moment().format("x");
				this.initEvents(["tab_id","step", "start", "maxStep"]);
			}
		}
		this.top20hotelsIds = [];
		this.isWl = 0;
	}

	setMaxStep(maxStep) {
		let maxStepFormCookies = $.cookie('DataLayerAnalyticsMaxStep');
		if (!maxStepFormCookies || +maxStepFormCookies < maxStep) {
			this.maxStep = maxStep;
			$.cookie('DataLayerAnalyticsMaxStep', this.maxStep,{path: '/', domain: wildcardDomain() })
		}
	};

	setStart() {
		if (!$.cookie('DataLayerAnalyticsStart')) {
			this.start = moment().format("DD-MM-YYYY HH:mm");
			$.cookie('DataLayerAnalyticsStart', this.start);
		}
	};

	initEvents(events) {
		for (let i in events) {
			this.dataLayerEvent(events[i])
		}
	};

	dataLayerEvent(name) {
		if (!this.hasOwnProperty(name)) {
			return
		}
		let obj = {
			event: name
		};
		obj[name] = this[name];
		window.dataLayer.push(obj);
	};

	initBasicEvents(tourCriteria) {
		if (tourCriteria instanceof TourCriteriaObjectValue || this.basicEventsWasInit) {
			let date = tourCriteria.get("checkInDateRange").toJSON();
			date.from = moment(date.from).format("DD-MM-YYYY");
			date.to = moment(date.to).format("DD-MM-YYYY");
			this.date = this.createRangeEvent(date);
			this.cityFrom = tourCriteria.get("departureCity").get("id");
			this.countryTo = this.createRangeEvent(tourCriteria.get("countries").pluck("id"));
			this.hotelClass = this.createRangeEvent(createStarsArray(tourCriteria.get("hotelCategories").pluck("stars")));
			this.touristGroup = this.createRangeEvent(tourCriteria.get("touristGroup").toJSON());
			this.night = this.createRangeEvent(tourCriteria.get("nightRange").toJSON());

			this.initEvents(['cityFrom', 'countryTo', 'hotelClass', 'touristGroup', 'night', 'date']);
			this.basicEventsWasInit = true;
		}
		function createStarsArray(stars) {
			let result = {},
				starsValues = [2, 3, 4, 5];
			stars = stars || [];
			for (let i in starsValues) {
				result[starsValues[i] + "stars"] = stars.length ? ~stars.indexOf(starsValues[i]) ? 1 : 0 : 1
			}
			return result;
		}

	};
	initBasicEventsHotel(tourCriteria){
		if (tourCriteria instanceof TourCriteriaObjectValue || this.basicEventsWasInit){
			this.hid = window.appConfig.hotelInfo.id;
			this.initEvents(['hid']);
			this.initBasicEvents(tourCriteria);
		}
	}
	createRangeEvent(data = []) {
		let result = [],
			isArray = _.isArray(data);
		_.each(data, (item, value) => {
			let obj = {
				value: item
			};
			if (!isArray) {
				obj.type = value;
			}
			result.push(obj);
		});
		return result
	};

	static toggleFilter(checked, value) {
		let event = checked ? "filterOn" : "filterOff";
		window.dataLayer.push({
			event: event,
			value: value
		})
	};

	preSearchPagePath(){
		this.generateEvent('preSearchPagePath', window.location.pathname);
	};

    actualizationComplete(time) {
        window.dataLayer.push({
            event: 'actualization_complete',
            time_to_act: time,
        });
    }

    actualizationFail(httpCode, time) {
        let type = 'unknown_error';

        if (httpCode === 404) type = httpCode;
        if (httpCode === 410) type = 'outdated';

        window.dataLayer.push({
            event: 'actualization_failed',
            time_to_fail: time,
            type,
        });
    }

	generateEvent(name, value) {
		if (!name) {
			return
		}
		this[name] = value;
		this.initEvents([name]);
	};
	clickSearchButtonEvent(){
		let typeSearchButtonEventName = this.isHotTourView ? 'clickHotButton' : "clickSearchButton";
		this.generateEvent(typeSearchButtonEventName, '');
	}
	searchCountEvent(count){
		let searchCountEventName = this.isHotTourView ? 'hotCount' : "searchCount";
		this.generateEvent(searchCountEventName, count)
	}
	generateTop20Event(filteredHotels){
		if(this.isHotTourView || !this.bannersStep) return;
		this.top20hotelsIds = [];
		for (let i = 0; this.top20hotelsIds.length < 20; i++ ){
			let hotel = filteredHotels?.at?.(i) || filteredHotels[i];
			const hotelId = filteredHotels[i] ? hotel.id : hotel?.get('id');
			if (hotelId){
				this.top20hotelsIds.push(hotelId);
				if(!((i + 1) % this.bannersStep)){
					this.top20hotelsIds.push(0);
				}
			}else break;
		}

		let value = {
			prop: [{type: "top20", value: this.top20hotelsIds.join()}]
		};
		this.generateEvent("search", value);
	}
	filterCountEvent(count){
		let filterCountEventName = this.isHotTourView ? 'filterHotCount' : "filterCount";
		this.generateEvent(filterCountEventName, count)
	}
	initTourProduct(tourProduct, type) {
		if (!type || this.basicEventsWasInit) {
			return
		}
		this[type] = type;
		let tour = tourProduct.get("tour"),
			value = {
				cityFrom: tour.get("departureCity").get("id"),
				countryTo: window.appConfig.hotelInfo.country,
				hid: window.appConfig.hotelInfo.id,
				price: tour.get("price"),
				taxoil: tour.get("oilTax"),
				nights: tour.get("nights"),
				date: moment(tour.get("checkInDate"), "YYYY-MM-DD").format("DD-MM-YYYY"),
				operator: tour.get("operator").name,
				room: tour.get("room").name,
				adults: tour.get("touristGroup").adults,
				kids: tour.get("touristGroup").kids,
				infants: tour.get("touristGroup").infants
			};
		value = this.createRangeEvent(value);
		value.push({
			type: 'identity',  id: tourProduct.get("id")
		});
		this.generateEvent(type, value);
		this.basicEventsWasInit = true;
	}
	searchFieldsEvent(value){
		let type = this.isHotTourView ? 'hotTour' : 'tour';
		let params = {
			prop: [{
				type,
				value
			}]
		};
		this.generateEvent('searchFields', params)
	}
	searchClickEvent(controller = {}){
		let hotelIndex = (controller.collectionView && controller.collectionView.collection ) ? controller.collectionView.collection.indexOf(controller.model) : null;
		if(!backboneModelDeepInstanceOf({model: controller.model}) || !(controller.model.get('tourProducts') && controller.model.get('tourProducts').at)){
			return
		}
		let tour = controller.model.get('tourProducts').at(0).get('tour');
		if(!backboneModelDeepInstanceOf({model:tour})){
			return;
		}

		let	value = {
			hotelIndex: ++hotelIndex,
			hid: controller.model.get("id"),
			price: controller.model.get('minPrice'),
			taxoil: tour.get("oilTaxIsAbsent") ? 999999 : tour.get("oilTax"),
		};

		value = this.createRangeEvent(value);
		this.generateEvent("searchClick", value);
	}
    sendHotelOfferStatistic(hotelOffer) {
        const optionsHotel = {
            hotel_id: hotelOffer.get('hotel'),
            hotel_name: window.appConfig?.hotelInfo?.name,
            hotel_price: hotelOffer.get('price'),
            hotel_dates: hotelOffer.get('checkInDate'),
            hotel_nights: hotelOffer.get('nights'),
        };
        this.generateEvent('hotel_page_click', optionsHotel);
    }

    actualizationStatistic(timeSystemInfoBenchark, actualizeState) {
        const actualizeTimeName = 'actualizeTime';

        try {
            const benchmarkStatus = timeSystemInfoBenchark.getStatus(actualizeTimeName);

            if (
                (actualizeState === 'notActualized' || actualizeState === 'inProgress')
                && (!benchmarkStatus || benchmarkStatus === 'done')
            ) {
                timeSystemInfoBenchark.startBench(actualizeTimeName);
            } else if (actualizeState === 'completed' && benchmarkStatus === 'inProgress') {
                timeSystemInfoBenchark.stopBenchmarking(actualizeTimeName);

                const time = timeSystemInfoBenchark.getResult(actualizeTimeName);
                this.actualizationComplete(time);
            }
        } catch (e) {
            console.error(e);
            TrackJS.track(e);
        }
    }

    openSerpFilters() {
        window.dataLayer.push({
            event: 'filter_open',
            is_wl: this.isWl,
        });
    }

    applySerpFilters(model) {
        const filterCriteria = model.get('filterCriteria');

        window.dataLayer.push({
            filter_travelata_exclusive_only_regular_flights: filterCriteria.get('isExclusive') ? 1 : 0,
            filter_page: this.step,
            is_wl: this.isWl,
            event: 'filter_applyed',
        });
    }

    openControlPage() {
        window.dataLayer.push({
            filter_type: 'travelata_exclusive_only_regular_flights',
            filter_interaction: 'click',
            filter_page: this.step,
            is_wl: this.isWl,
            event: 'filter_interaction',
        });
    }

    checkBoxOn() {
        window.dataLayer.push({
            filter_type: 'travelata_exclusive_only_regular_flights',
            filter_interaction: 'checkbox_on',
            filter_page: this.step,
            is_wl: this.isWl,
            event: 'filter_interaction',
        });
    }

	trackVisibleBlockAgentLogin(options) {
		const { visibleCopyLink, visibleButtonLogin, operatorName } = options; 
		window.dataLayer.push({
			'copy_link': visibleCopyLink ? 1 : 0,
			'agency_button': visibleButtonLogin ? 1 : 0,
			'operator': operatorName,
			'event': 'b2bshown'
		});
	}

	trackClickBlockAgentLoginButton(operatorName) {
		window.dataLayer.push({
			'clickto': 'agency_button',
			'operator': operatorName,
			'event': 'b2bclicked'
		});
	}
	trackClickBlockAgentLoginCopyLink(operatorName) {
		window.dataLayer.push({
			'clickto': 'copy_link',
			'operator': operatorName,
			'event': 'b2bclicked'
		  });
	}

	subscribeEmail() {
		window.dataLayer.push({
			'event': 'GAEvent',
			'eCategory': 'forms',
			'eAction': 'send',
			'eLabel': 'subscribe'
		});
	}
}
