/* eslint-disable */
const group = <Item>(legs: Item[][], chain: Item[] = [], groupedLegs: Item[][] = []): Item[][] => {
    const legsCopy = [...legs];
    const legsPair = legsCopy.shift();

    if(legsPair?.length) {
        legsPair.forEach((leg) => {
            const chainCopy = [...chain];
            chainCopy.push(leg);

            if (legsCopy.length) {
                group(legsCopy, chainCopy, groupedLegs);
            } else {
                groupedLegs.push(chainCopy);
            }
        });
    }

    return groupedLegs;
};

export default group;