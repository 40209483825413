import { IRawTourProduct } from '../types/IRawTourProduct';

export interface ITourProductsRepositoryState {
    tourProducts: IRawTourProduct[];
}

export interface ITourProductsRepositoryMutations {
    setTourProducts(state: ITourProductsRepositoryState, hotels: IRawTourProduct[]): void;
    resetTourProducts(state: ITourProductsRepositoryState): void;
}

const TourProductsRepository = {
    namespaced: true,
    state: {
        tourProducts: [],
    },
    mutations: {
        setTourProducts(state: ITourProductsRepositoryState, updatedTourProducts: IRawTourProduct[] | undefined) {
            const tourProductsHash = state.tourProducts.reduce((acc, tourProduct) => {
                acc[tourProduct.id] = true;
                return acc;
            }, {} as Record<string, boolean>);
            const newTourProducts = updatedTourProducts?.filter((tourProduct) => !tourProductsHash[tourProduct.id]) || [];
            if (newTourProducts.length) {
                state.tourProducts = [...state.tourProducts, ...newTourProducts];
            }
        },
        resetTourProducts(state: ITourProductsRepositoryState) {
            state.tourProducts = [];
        },
    },
};

export const NAMESPACE = 'tourProductsRepositoryName';

export default TourProductsRepository;