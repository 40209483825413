// eslint-disable-next-line no-shadow
export enum RestTypeId {
    Beach = 1,
    City = 2,
    Mountain = 3,
    Wellness = 4,
    Eco = 11,
}

export type RestTypeOld = 'restBeach' | 'restCity' | 'restMountainSki' | 'restWellness' | 'restEco'

export const REST_TYPE_OLD_MAP: Record<RestTypeId, RestTypeOld> = {
    1: 'restBeach',
    2: 'restCity',
    3: 'restMountainSki',
    4: 'restWellness',
    11: 'restEco',
};