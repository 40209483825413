
    import {
        defineComponent, PropType, ref, watch, toRefs, unref,
    } from 'vue';
    import { maska } from 'maska';
    import _ from 'underscore';

    export default defineComponent({
        directives: {
            maska,
        },
        name: 'InputCommon',
        props: {
            value: {
                type: [String, Number],
                default: '',
                required: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            placeholderValue: {
                type: [String, Number],
                required: false,
            },
            timeDebounce: {
                type: Number,
                default: 0,
                required: false,
            },
            isFocus: {
                type: Boolean,
                default: false,
                required: false,
            },
            mask: {
                type: [Array, String] as PropType<string[] | string>,
                required: false,
            },
            customPlaceholder: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        setup(props, context) {
            const { timeDebounce } = props;
            const { value } = toRefs(props);
            const { isFocus } = toRefs(props);
            const isCurrentFocus = ref<boolean>(unref(isFocus));
            const inputText = ref<string | number>('');
            const updateValue = () => {
                context.emit('input', unref(inputText));
            };
            const handlerDebounce = _.debounce(() => {
                updateValue();
            }, timeDebounce);
            const foucusOutHandler = () => {
                context.emit('focusOutInput');
                isCurrentFocus.value = false;
            };
            const focusHandler = () => {
                context.emit('focusInput');
                isCurrentFocus.value = true;
            };
            watch(isFocus, (_val: boolean) => {
                isCurrentFocus.value = _val;
            });
            watch(value, (_val: string | number) => {
                inputText.value = _val;
            }, { immediate: true });
            return {
                handlerDebounce,
                foucusOutHandler,
                focusHandler,
                inputText,
                isCurrentFocus,
            };
        },
    });
