module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="accountTopMenuBar__dropdownButton"><i class="icon icon-i16_who2"></i> <span class="accountTopMenuBar__userName"></span></div>\r\n<div class="accountTopMenuBar__dropdown">\r\n	<ul class="accountTopMenuBar__linksMenu">\r\n		<li><a target="_blank" href="'+
((__t=( accountDomain ))==null?'':__t)+
'#/orders">Заказы</a></li>\r\n		<li><a target="_blank" href="'+
((__t=( accountDomain ))==null?'':__t)+
'#/tourhunter">Турхантер <span class="accountTopMenuBar__tourHunterCount"></span></a></li>\r\n		<li><a target="_blank" href="'+
((__t=( accountDomain ))==null?'':__t)+
'#/settings">Настройки</a></li>\r\n		<li class="accountTopMenuBar__logoutWrapper"><span class="accountTopMenuBar__logout">Выйти <i class="icon"></i></span></li>\r\n	</ul>\r\n</div>';
}
return __p;
};
