import { useFiltersTourProductsModel } from '@features/tourProduct/filterTourProducts';
import { useOperatorFilterModel } from '@features/operator/filterByOperator';
import { initBackboneConnectionFiltersHotels } from '@features/hotel/filterHotels';
import { watch } from 'vue';
import {
    useState, useActions,
} from 'vuex-composition-helpers';
import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';

export default function () {
    initBackboneConnectionFiltersHotels();
    const {
        confirmation,
        setConfirmationFilter,
    } = useFiltersTourProductsModel();
    const {
        setOperatorFilter,
        takenOperators,
    } = useOperatorFilterModel();
    const {
        typeConfirmation: typeConfirmationBackbone,
        operators: operatorsBackbone,
    } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['typeConfirmation', 'operators']);
    const {
        action_typeConfirmation: setConfirmationBackbone,
        action_operators: setOperatorsBackbone,
    } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_typeConfirmation', 'action_operators']);

    watch(operatorsBackbone, (value) => {
        setOperatorFilter(value);
    }, { immediate: true });

    watch(takenOperators, (value) => {
        setOperatorsBackbone(value);
    });

    watch(confirmation, (value) => {
        setConfirmationBackbone(value);
    });

    watch(typeConfirmationBackbone, (value) => {
        setConfirmationFilter(value);
    }, { immediate: true });
}