var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{class:[
    'text-common',
    `text-common__${_vm.typeText}`,
    `text-common_text-${_vm.size}`,
    _vm.isHover ? `text-common__${_vm.typeText}_hover` :  '',
    _vm.disable ? `text-common__${_vm.typeText}_hover` :  '',
]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }