import { unref, watch } from 'vue';
import {
    useState, useGetters, useMutations, useActions,
} from 'vuex-composition-helpers';
import { DepartureCity } from '@ve/types';
import { IMapForSearchDataState } from '@ve/pages/serp/store';

import { NAMESPACE as MAP_FOR_SEARCH_DATA } from '@ve/pages/serp/store/SearchDataStore';
import { NAMESPACE as EMPTY_SEARCH_STORE } from '@ve/components/emptySearch/common/store/EmptySearchStore';
import { NAMESPACE as ALTERNATIVE_SEARCH_STORE_NAME } from '@ve/components/alternativeDeparture/common/store/AlternativeSearchStore';
import { NAMESPACE as SERP_PAGE_STATE } from '@ve/pages/serp/store/serpPageStateStore';
import { NAMESPACE as MAP_FOR_PRODUCTS_SEARCH } from '@ve/pages/serp/store/productsSearchStore';

import type {
    IEmptySearchGetters,
    IEmptySearchState,
} from '@ve/components/emptySearch/common/store/types/IEmptySearchStore';
import {
    IAlternativeSearchActions,
    IAlternativeSearchGetters,
} from '@ve/components/alternativeDeparture/common/store/types/AlternativeSearchStore';

export default (context: any) => {
    const {
        searchDepartureCity,
    } = useState<IMapForSearchDataState>(MAP_FOR_SEARCH_DATA, [
        'searchDepartureCity',
    ]);

    const {
        noToursFound,
        hasTours,
    } = useGetters(MAP_FOR_PRODUCTS_SEARCH, [
        'noToursFound',
        'hasTours',
    ]);

    const {
        currentState,
    } = useState<IEmptySearchState>(EMPTY_SEARCH_STORE, [
        'currentState',
    ]);

    const {
        currentStateIsAlternativeSearchAndHasTours,
    } = useGetters<IEmptySearchGetters>(EMPTY_SEARCH_STORE, [
        'currentStateIsAlternativeSearchAndHasTours',
    ]);

    const {
        idDefaultDepartureCity,
    } = useState(ALTERNATIVE_SEARCH_STORE_NAME, [
        'idDefaultDepartureCity',
    ]);

    const {
        viewModeIsMap,
    } = useGetters(SERP_PAGE_STATE, [
        'viewModeIsMap',
    ]);

    const {
        getAvailableCitiesWithoutEmptyDepartures: departureCities,
        getNameCurrentDepartureCity: currentDepartureCity,
    } = useGetters<IAlternativeSearchGetters>(ALTERNATIVE_SEARCH_STORE_NAME, [
        'getAvailableCitiesWithoutEmptyDepartures',
        'getNameCurrentDepartureCity',
    ]);

    const {
        clearAlternativeSearchData,
        noToursFoundForCurrentDeparture,
    } = useActions<IAlternativeSearchActions>(ALTERNATIVE_SEARCH_STORE_NAME, [
        'clearAlternativeSearchData',
        'noToursFoundForCurrentDeparture',
    ]);

    const {
        setDepartureCity,
    } = useMutations(ALTERNATIVE_SEARCH_STORE_NAME, [
        'setDepartureCity',
    ]);

    watch(currentState, (state) => {
        if (state === 'alternativeSearch') {
            clearAlternativeSearchData();
            context.emit('startAlternativeSearch', unref(idDefaultDepartureCity));
        }
    });

    watch([
        noToursFound,
        currentStateIsAlternativeSearchAndHasTours,
    ], ([
        noToursFoundValue,
        currentStateIsAlternativeSearchAndHasToursValue,
    ]) => {
        if (noToursFoundValue && currentStateIsAlternativeSearchAndHasToursValue) {
            noToursFoundForCurrentDeparture();
            context.emit('startAlternativeSearch', unref(idDefaultDepartureCity));
        }
    });

    const changeCity = (city: DepartureCity) => {
        setDepartureCity(city.id);
        context.emit('startAlternativeSearch', city.id);
    };

    return {
        departureCities,
        currentDepartureCity,
        changeCity,
        recommendedVisible: hasTours,
        visibleBanner: currentStateIsAlternativeSearchAndHasTours,
        searchDepartureCity,
        viewModeIsMap,
    };
};
