module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cashbackHotelBanner">\n    <div class="cashbackHotelBanner__container js-banner-width js-rounded">\n        <div class="cashbackHotelBanner__container__logos">\n            <div class="cashbackHotelBanner__container__mir-logo"></div>\n            ×\n            <div class="cashbackHotelBanner__container__rostourism-logo"></div>\n        </div>\n\n        <div class="cashbackHotelBanner__container-label">\n            ';
 if(page === 'hotelPage') { 
__p+='\n                Оплачивайте путешествие картой МИР и получите кешбэк 20%\n            ';
 } else { 
__p+='\n                ';
 if(productType === 'tour') { 
__p+='\n                    Покупая тур, получите кешбэк 20% за проживание и перелёт\n                ';
 } 
__p+='\n                ';
 if(productType === 'hotelOffer') { 
__p+='\n                    Получите кешбэк 20% по карте МИР за проживание\n                ';
 } 
__p+='\n                ';
 if(productType === 'railwayTour') { 
__p+='\n                    Покупая тур, получите кешбэк 20% за проживание и жд-билеты\n                ';
 } 
__p+='\n            ';
 } 
__p+='\n        </div>\n\n        <div class="cashbackHotelBanner__container-arrow js-arrow-position"></div>\n\n            <a href="https://travelata-promo.ru/mir-cashback"\n               class="cashbackHotelBanner__container-link"\n               target="_blank">\n                Узнать больше\n                <i class="icon icon-i16_next"></i>\n            </a>\n            \n        </div>\n    </div>\n</div>\n';
}
return __p;
};
