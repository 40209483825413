import { computed, unref } from 'vue';
import {
    useState,
} from 'vuex-composition-helpers';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
import { type IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';

import { initFilterBudgetBackboneVuex } from '@features/filterByBudget';
import { filterTourProductsByHotels } from '@features/tourProduct/filterTourProducts';
import { useSearchTourProducts } from '@features/tourProduct/searchTourProducts';
import { groupTourProductsByHotel, getUniqHotelsFromGroup } from '@entities/tourProduct';
import initConnectionBackboneVuex from './initConnectionBackboneVuex';

export default function () {
    initFilterBudgetBackboneVuex();
    initConnectionBackboneVuex();
    const {
        searchedTourProducts,
    } = useSearchTourProducts();
    const {
        syncHotels,
        isPopularityFilterActive,
        filteredHotelsWithoutConfirmation,
        filteredHotelsWithoutOperator,
        filteredHotelsWithoutRestType: filteredHotelsWithoutRestTypeBackbone,
        filteredHotels: filteredHotelsBackbone,
        filteredHotelsWithoutPrice: filteredHotelsWithoutPriceBackbone,
        filteredHotelsWithoutBeachType: filteredHotelsWithoutBeachTypeBacbkone,
    } = useState<IMapForFiltersSerpState>(nameFilterSerpStore,
        [
            'syncHotels',
            'typeConfirmation',
            'isPopularityFilterActive',
            'filteredHotelsWithoutConfirmation',
            'filteredHotelsWithoutOperator',
            'filteredHotels',
            'filteredHotelsWithoutRestType',
            'filteredHotelsWithoutPrice',
            'filteredHotelsWithoutBeachType',
        ]);

    const { touristGroup } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['touristGroup']);

    const groupedTourProductsWithoutConfirmation = computed(() => {
        const filteredTourProductsWithoutConfirmationByHotels = filterTourProductsByHotels(unref(searchedTourProducts), unref(filteredHotelsWithoutConfirmation));
        return groupTourProductsByHotel(filteredTourProductsWithoutConfirmationByHotels);
    });

    const groupedTourProductsWithoutOperators = computed(() => {
        const filteredTourProductsWithoutOperatorsByHotels = filterTourProductsByHotels(unref(searchedTourProducts), unref(filteredHotelsWithoutOperator));
        return groupTourProductsByHotel(filteredTourProductsWithoutOperatorsByHotels);
    });

    const groupedFilteredTourProducts = computed(() => {
        const filteredTourProductsByHotels = filterTourProductsByHotels(unref(searchedTourProducts), unref(filteredHotelsBackbone));
        return groupTourProductsByHotel(filteredTourProductsByHotels);
    });
    const filteredHotelsWithoutRestType = computed(() => {
        const filteredTourProductsWithoutRestType = filterTourProductsByHotels(unref(searchedTourProducts), unref(filteredHotelsWithoutRestTypeBackbone));
        const groupedTourProducts = groupTourProductsByHotel(filteredTourProductsWithoutRestType);
        return getUniqHotelsFromGroup(groupedTourProducts);
    });

    const filteredHotelsWithoutBeachType = computed(() => {
        const filteredTourProductsWithoutRestType = filterTourProductsByHotels(unref(searchedTourProducts), unref(filteredHotelsWithoutBeachTypeBacbkone));
        const groupedTourProducts = groupTourProductsByHotel(filteredTourProductsWithoutRestType);
        return getUniqHotelsFromGroup(groupedTourProducts);
    })

    const tourProductsPrices = computed(() => {
        return unref(filteredHotelsWithoutPriceBackbone).map((hotel) => hotel.minPrice);
    });

    const groupedAllTourProducts = computed(() => groupTourProductsByHotel(unref(searchedTourProducts)));
    const filteredHotels = computed(() => getUniqHotelsFromGroup(unref(groupedFilteredTourProducts)));
    const allHotels = computed(() => getUniqHotelsFromGroup(unref(groupedAllTourProducts)));

    return {
        touristGroup,
        groupedAllTourProducts,
        groupedTourProductsWithoutConfirmation,
        groupedTourProductsWithoutOperators,
        filteredHotelsWithoutRestType,
        filteredHotelsWithoutBeachType,
        filteredHotels,
        allHotels,
        groupedFilteredTourProducts,
        tourProductsPrices,
        isPopularityFilterActive,
    };
}