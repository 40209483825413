
    import {
        defineComponent,
        ref,
        onMounted,
        onUnmounted,
        toRefs,
        unref,
        computed,
        Ref,
    } from 'vue';

    const OFFSET_TOP = 135;

    export default defineComponent({
        props: {
            watchElementClass: {
                type: String,
                required: true,
            },
            containerClass: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const {
                watchElementClass,
                containerClass,
            } = toRefs(props);

            const sticky = ref(false);
            const visible = ref(false);
            const top = ref(0);

            const elRef = ref() as Ref<HTMLElement>;
            const $watchElement = computed(() => (document.getElementsByClassName(unref(watchElementClass))[0]) as HTMLElement);
            const $container = computed(() => (document.getElementsByClassName(unref(containerClass))[0]) as HTMLElement);
            let oldScrollPosition = 0;

            const calculatePosition = async () => {
                const lookElementPosition = unref($watchElement).getBoundingClientRect().top;
                const lookElementHeight = unref($watchElement).offsetHeight;
                const lookElementBottomPosition = lookElementPosition + lookElementHeight;

                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                const windowHeight = window.innerHeight;

                if (lookElementBottomPosition < 0) {
                    sticky.value = true;

                    const currentElementHeight = unref(elRef).offsetHeight;
                    const currentElementBottomPosition = currentElementHeight + OFFSET_TOP;

                    const containerElementPosition = unref($container).getBoundingClientRect().top;
                    const containerElementHeight = unref($container).offsetHeight;
                    const containerElementBottomPosition = containerElementPosition + containerElementHeight;

                    if (currentElementBottomPosition < containerElementBottomPosition) {
                        visible.value = true;

                        const currentElementTop = Math.abs(parseInt(unref(elRef).style.top, 10));
                        let newTop = currentElementTop + currentScrollPosition - oldScrollPosition;

                        const maxTop = currentElementHeight - windowHeight + OFFSET_TOP;

                        if (newTop < 0) {
                            newTop = 0;
                        } else if (newTop > maxTop) {
                            newTop = maxTop > 0 ? maxTop : 0;
                        }

                        top.value = newTop;
                    } else {
                        visible.value = false;
                    }
                } else {
                    sticky.value = false;
                }

                oldScrollPosition = currentScrollPosition;
            };

            const scrollToTop = () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            };

            onMounted(() => {
                window.addEventListener('scroll', calculatePosition);
                window.addEventListener('resize', calculatePosition);
            });

            onUnmounted(() => {
                window.removeEventListener('scroll', calculatePosition);
                window.removeEventListener('resize', calculatePosition);
            });

            const style = computed(() => ({
                top: `-${unref(top)}px`,
                marginTop: `${OFFSET_TOP}px`,
            }));

            return {
                sticky,
                elRef,
                visible,
                style,
                scrollToTop,
            };
        },
    });
