/* eslint-disable */
import WATER_ACTIVITIES_REPOSITORY
    from '@/js/common/components/filterCriteria/waterActivities/WaterActivitiesRepository';
import ResortsRepository from '@/js/common/Domain/Repository/Resorts';
import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import errorHandlerService from '@/js/common/Domain/Service/errorHandler';
import HotelCategoriesRepository from '@/js/common/Domain/Repository/HotelCategories';
import TourProductsRepository from '@/js/common/Domain/Repository/TourProducts';
import HotelOffersRepository from '@/js/common/Domain/Repository/HotelOffers';
import { getChildService, getRestType, getBeachType } from '@entities/hotel';

import { 
    getDateOfLastHotelChange, 
    getPropertyWaterActivities, 
    getPropertyTypeDistanceLift,
    getPropertyFacilitiesAttributes
} from './helpers';

export default (data) => {
    const hotel = {};
    let resort;
    let country;
    let element;

    element = data.id;
    if (typeof element !== 'undefined') {
        hotel.id = element;
        hotel.url = `/goto/hotel/${element}`;
    }

    element = data.userInterestGroups;
    if (typeof element !== 'undefined') {
        hotel.userInterestGroups = element;
    }

    element = data.name;
    if (typeof element !== 'undefined') {
        hotel.name = element;
    }

    element = data.coords;
    if (typeof element !== 'undefined') {
        hotel.map = {
            lat: element.latitude || element.lat,
            lon: element.longitude || element.lon,
        };
    }

    element = data.rating || 0;
    if (typeof element !== 'undefined') {
        hotel.rating = element;

        if (data.rating && data.rating.toFixed(1) >= 3.8 && data.reviewsQty > 5) {
            hotel.highlyRated = true;
        }
    }

    element = data.ratingDetails;
    if (typeof element !== 'undefined') {
        hotel.ratingDetails = element;
    }

    element = data.reviewsNumber;
    if (typeof element !== 'undefined') {
        hotel.reviewsQty = element;
    }

    element = data.toursQuantity;
    if (typeof element !== 'undefined') {
        hotel.toursQuantity = element;
    }

    element = data.photos;
    if (typeof element !== 'undefined') {
        hotel.photos = element;
    }

    element = data.cover;
    if (typeof element !== 'undefined') {
        hotel.cover = element;
    }

    element = data.ordersNumber;
    if (typeof element !== 'undefined') {
        hotel.ordersCount = element;
    }

    element = data.resort;
    if (typeof element !== 'undefined') {
        resort = ResortsRepository.repository.get(element);
        if (typeof (resort) === 'undefined' && ResortsRepository.repository.length) {
            errorHandlerService(`No resort with id ${resort} but found in hotel`);
        }
        hotel.resort = resort || element;
    }

    element = data.country;
    if (typeof element !== 'undefined') {
        country = CountriesRepository.repository.get(element);
        if (typeof (country) === 'undefined' && CountriesRepository.repository.length) {
            errorHandlerService(`No country with id ${element} but found in hotel`);
        }
        hotel.country = country || element;
    }

    element = data.hotelCategory;
    if (element) {
        let hotelCategory = HotelCategoriesRepository.repository.get(element);
        if (!hotelCategory && typeof element === 'number') {
            hotelCategory = HotelCategoriesRepository.repository.get(0);
        }
        hotel.category = hotelCategory || element;
    }

    element = data.types;
    hotel.restTypes = getRestType(element);
    if (typeof element !== 'undefined') {
        hotel.withKids = _.contains(element, 5);
        hotel.forCouples = _.contains(element, 8);
        hotel.cityTours = _.contains(element, 2);
        hotel.wellness = _.contains(element, 4);
        hotel.mountains = _.contains(element, 3);
        hotel.eco = _.contains(element, 11);
    }

    element = data.beachLine;
    if (element) {
        hotel.beachLine = element;
    }

    hotel.dateOfLastHotelChange = getDateOfLastHotelChange(data.builtDate, data.renovationDate);
    hotel.beachOwner = !!~data.attributes?.indexOf(134);
    hotel.excursions = data.hotelCategory === 23;

    element = data.attributes;
    if (typeof element !== 'undefined') {
        hotel.airConditioner = _.contains(element, 83) || _.contains(element, 212) || _.contains(element, 233);
        hotel.wifi = _.contains(element, 74) || _.contains(element, 88) || _.contains(element, 226) || _.contains(element, 227) || _.contains(element, 234) || _.contains(element, 237);
        hotel.nearTheLift = _.contains(element, 213) || _.contains(element, 225);
        hotel.sandBeach = _.contains(element, 136);
        hotel.pebbleBeach = _.contains(element, 137);
        hotel.sandPebbleBeach = _.contains(element, 211);
        hotel.adultsOnly = _.contains(element, 217);
        hotel.bayLocated = _.contains(element, 264);
        hotel.parking = _.contains(element, 69) || _.contains(element, 263);
        hotel.saunaBathHammam = _.contains(element, 148) || _.contains(element, 149);
        hotel.forCouples = hotel.forCouples
            || _.contains(element, 169)
            || _.contains(element, 220)
            || _.contains(element, 217);

        hotel.beachType = getBeachType(element);

        hotel.distanceLift = getPropertyTypeDistanceLift(element);
        hotel.waterActivities = getPropertyWaterActivities(element);
        hotel.childServices = getChildService(element);
        hotel.children_animators = _.contains(element, 229);
        hotel.children_pool = _.contains(element, 111);
        hotel.children_waterslides = _.contains(element, 175);
        hotel.children_aquapark = _.contains(element, 61);
        hotel.children_playroom = _.contains(element, 109);
        hotel.lonelyMan = !_.contains(element, 250);
        hotel.jacuzzi = _.contains(element, 185) || _.contains(element, 64);
        hotel.children_seaEnter = hotel.sandBeach
            && hotel.withKids
            && !hotel.pebbleBeach
            && !hotel.sandPebbleBeach
            && !_.contains(element, 138)
            && !_.contains(element, 139)
            && !_.contains(element, 140);

        hotel.children_childmenu = _.contains(element, 163);
        hotel.children_childBed = _.contains(element, 114) || _.contains(element, 246) || _.contains(element, 247); //346
        hotel.children_nanny = _.contains(element, 113) || _.contains(element, 110);
        hotel.children_clubs = _.contains(element, 112);
        hotel.withKids = hotel.withKids
            || _.contains(element, 109)
            || _.contains(element, 229)
            || hotel.children_clubs
            || hotel.children_animators;
        hotel.nearTheLiftDescription = '';
        hotel.conditionerDescription = '';

        // Кондиционер (бесплатно) - 83
        // Кондиционер (платно) - 212
        // Кондиционер (нет данных о стоимости) - 233

        if (_.contains(element, 83)) {
            hotel.conditionerDescription = 'Бесплатно';
        }
        if (_.contains(element, 212)) {
            hotel.conditionerDescription = 'Платно';
        }
        if (_.contains(element, 233)) {
            hotel.conditionerDescription = '';
        }

        hotel.wifiDescription = '';
        //Интернет в номере (платно); 74
        //Интернет в номере (бесплатно); 226
        //Интернет в номере (нет данных о стоимости); 234
        //Интернет/Wi-Fi на территории (платно); 88
        //Интернет/Wi-Fi на территории (бесплатно); 227
        //Интернет/Wi-Fi на территории (нет данных о стоимости); 237
        const roomPaid = _.contains(element, 74);
        const roomFree = _.contains(element, 226);
        const roomUnknown = _.contains(element, 234);
        const lobbyPaid = _.contains(element, 88);
        const lobbyFree = _.contains(element, 227);
        const lobbyUnknown = _.contains(element, 237);
        if (roomPaid && !roomFree && !roomUnknown && lobbyPaid && !lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере платно, в лобби платно';
        }
        if (roomPaid && !roomFree && !roomUnknown && !lobbyPaid && lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере платно, в лобби бесплатно';
        }
        if (roomPaid && !roomFree && !roomUnknown && !lobbyPaid && !lobbyFree && lobbyUnknown) {
            hotel.wifiDescription = 'В номере есть, в лобби есть';
        }
        if (!roomPaid && roomFree && !roomUnknown && lobbyPaid && !lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере бесплатно, в лобби платно';
        }
        if (!roomPaid && roomFree && !roomUnknown && !lobbyPaid && lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере бесплатно, в лобби бесплатно';
        }
        if (!roomPaid && roomFree && !roomUnknown && !lobbyPaid && !lobbyFree && lobbyUnknown) {
            hotel.wifiDescription = 'В номере есть, в лобби есть';
        }
        if (!roomPaid && !roomFree && roomUnknown && lobbyPaid && !lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере есть, в лобби платно';
        }
        if (!roomPaid && !roomFree && roomUnknown && !lobbyPaid && lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере есть, в лобби бесплатно';
        }
        if (!roomPaid && !roomFree && roomUnknown && !lobbyPaid && !lobbyFree && lobbyUnknown) {
            hotel.wifiDescription = 'В номере есть, в лобби есть';
        }
        if (!roomPaid && !roomFree && !roomUnknown && lobbyPaid && !lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере нет, в лобби платно';
        }
        if (!roomPaid && !roomFree && !roomUnknown && !lobbyPaid && lobbyFree && !lobbyUnknown) {
            hotel.wifiDescription = 'В номере нет, в лобби бесплатно';
        }
        if (!roomPaid && !roomFree && !roomUnknown && !lobbyPaid && !lobbyFree && lobbyUnknown) {
            hotel.wifiDescription = 'В номере нет, в лобби есть';
        }

        hotel.withKidsDescription = 'Отдых с детьми';
        // Детский бассейн - 111
        // Детский клуб - 112
        // Водные горки -175
        // Кроватка - 114
        // Детское меню - 163
        let withKidsAttrsCounter = 0;
        const withKidsDescriptionsArray = [];
        if (_.contains(element, 111)) {
            withKidsDescriptionsArray.push('Детский бассейн');
            withKidsAttrsCounter++;
        }
        if (_.contains(element, 112)) {
            withKidsDescriptionsArray.push('Детский клуб');
            withKidsAttrsCounter++;
        }
        if (_.contains(element, 175)) {
            withKidsDescriptionsArray.push('Водные горки');
            withKidsAttrsCounter++;
        }
        if (_.contains(element, 114)) {
            if (withKidsDescriptionsArray.length < 3) {
                withKidsDescriptionsArray.push('Кроватка');
            }
            withKidsAttrsCounter++;
        }
        if (_.contains(element, 163)) {
            if (withKidsDescriptionsArray.length < 3) {
                withKidsDescriptionsArray.push('Детское меню');
            }
            withKidsAttrsCounter++;
        }
        if (withKidsAttrsCounter > 2) {
            hotel.withKids = true;
        }
        if (withKidsDescriptionsArray.length) {
            hotel.withKidsDescription = withKidsDescriptionsArray.join(', ');
        }
        if (hotel.adultsOnly && hotel.withKids) {
            hotel.adultsOnly = false;
            hotel.withKids = false;
        }

        hotel.beachDescription = '';
        // Шезлонги и зонтики на пляже (бесплатно) - 103
        // Шезлонги и зонтики на пляже (платно) - 104
        // Шезлонги и зонтики на пляже (нет данных о стоимости) - 243
        if (_.contains(element, 103)) {
            hotel.beachDescription = 'Шезлонги и зонтики бесплатно';
        } else if (_.contains(element, 104)) {
            hotel.beachDescription = 'Шезлонги и зонтики платно';
        } else if (_.contains(element, 243)) {
            hotel.beachDescription = 'Шезлонги и зонтики есть';
        }
        if (_.isArray(element)) {
            WATER_ACTIVITIES_REPOSITORY.forEach((item) => {
                if (_.isArray(item.id)) {
                    item.id.forEach((id) => {
                        if (~element.indexOf(id)) {
                            hotel[item.name] = true;
                        }
                    });
                } else if (~element.indexOf(item.id)) {
                    hotel[item.name] = true;
                }
            });
        }

        if (_.contains(element, 186) // бассейн
        || _.contains(element, 60) // открытый бассейн
        || _.contains(element, 59) // крытый бассейн
        || _.contains(element, 174) // с подогревом
        || _.contains(element, 173) // c морской водой
        || _.contains(element, 111) // детский бассейн
        || _.contains(element, 151)) { // термальный бассейн
            hotel.pool = true;
        }

        if (_.contains(element, 166)) {
            hotel.withAnimals = true;
        }

        if (_.contains(element, 147)) {
            hotel.spaAndHealth = true;
        }
    }

    element = data.distances;
    const { placement } = data;

    if ((typeof element !== 'undefined'
            && ((element.beachMax > 0 && element.beachMax < 500) || (element.beach > 0 && element.beach < 500)))
            || (typeof placement !== 'undefined' && placement > 0)
        ) {
        hotel.nearTheBeach = true;
        hotel.nearTheBeachDescription = '';
        if (placement === 1) {
            hotel.nearTheBeachDescription = '1-я линия';
        } else if (placement === 2) {
            hotel.nearTheBeachDescription = '1-я линия, пляж через дорогу';
        }
    }
    hotel.nearTheCenterDescription = '';
    if (typeof element !== 'undefined' && element.center > 0 && element.center < 1000) {
        hotel.nearTheCenter = true;
    }

    if (typeof element !== 'undefined') {
        if (typeof element.beachMax !== 'undefined'
            && element.beachMax !== null
            && element.beachMax !== 0
            && (element.beach === null || element.beach === 0)
        ) {
            element.beach = element.beachMax;
        }
        hotel.distances = element;
    }

    if ((typeof element !== 'undefined' && ((element.lift > 0 && element.lift <= 500)))) {
        hotel.nearTheLift = true;
    }

    hotel.facilities = getPropertyFacilitiesAttributes(data.types, data.attributes)

    hotel.tourProducts = new TourProductsRepository();
    hotel.hotelOffers = new HotelOffersRepository();

    return hotel;
};