import gaAsync from '@/js/common/Domain/Service/gaAsync';

const FilterCriteriaModel = Backbone.Epoxy.Model.extend({
    defaults: {
        nearTheLift: false,
        cashback: false,

        isExclusive: false,
        excursions: false,
        nearTheBeach: false,
        mountains: false,
        eco: false,
        beachType: 0,
        minPrice: null,
        maxPrice: null,

        operators: [],
        wellness: false,
        pool: false,
        animation: false,
        familyRooms: false,
        diving: false,
        freeSafe: false,
        fitnessCenter: false,
        freeMiniBar: false,
        skiEquipment: false,
        honeyMoon: false,
        waterSports: false,
        nightClub: false,
        personsWithDisabilities: false,
        noSmokingRooms: false,
        smokingRooms: false,
        isPopularityFilterActive: false,
        ratingLocation: 0,
        ratingService: 0,
        ratingMeal: 0,
        typeLines: [],
        typeDistanceLift: [],
        typeFilterRating: 0,
        takenHotelAttributes: [],
        takenBeachType: [],
        takenWaterActivities: [],
        typeConfirmation: 0,
        takenFacilities: [],
        childServiceFilterIds: [],
        restType: 0,
    },
    computeds: {
        operatorChanged() {
            const operatorValue = this.get('operators');
            if (operatorValue) {
                gaAsync(() => {
                    ga('send', 'event', 'Search_Form', 'Additionally', 'operators', { nonInteraction: 1 });
                });
            }
        },
        cashbackChanged: {
            deps: ['cashback'],
            get(cashbackValue) {
                if (!cashbackValue) return;

                gaAsync(() => {
                    ga('send', 'event', 'Search_Form', 'Additionally', 'cashback', {
                        nonInteraction: 1,
                    });
                });
            },
        },
    },
    clearFilters() {
        this.set({
            nearTheLift: false,
            nearTheBeach: false,
            isExclusive: false,
            excursions: false,
            mountains: false,
            eco: false,
            cashback: false,
            wellness: false,
            pool: false,
            animation: false,
            familyRooms: false,
            diving: false,
            freeSafe: false,
            fitnessCenter: false,
            freeMiniBar: false,
            skiEquipment: false,
            honeyMoon: false,
            waterSports: false,
            nightClub: false,
            personsWithDisabilities: false,
            noSmokingRooms: false,
            smokingRooms: false,
            isPopularityFilterActive: false,
            beachType: 0,
            minPrice: null,
            maxPrice: null,
            operators: [],
            typeLines: [],
            typeDistanceLift: [],
            takenWaterActivities: [],
            typeConfirmation: 0,
            typeFilterRating: 0,
            takenHotelAttributes: [],
            takenBeachType: [],
            takenFacilities: [],
            childServiceFilterIds: [],
            restType: 0,
        }, {
            initiator: 'clearFilter',
        });
    },
});

export default FilterCriteriaModel;
