/* eslint-disable */
export const getTestimonialsPages = (testimonialsPage, testimonialsTotal) => {
	return new Promise((resolve, reject) => {
		$.ajax({
			url: '/testimonials/get-more',
			data: {
				page: testimonialsPage,
				total: testimonialsTotal,
			},
		})
		.done(response => {
			if (response.success) {
				resolve(response);
			} else {
				reject();
				throw new Error('getTestimonialsPages request ', response.message);
			}
		})
		.fail(e => {
			reject();
			throw new Error('getTestimonialsPages request ', e);
		});
	});
};
