import FilterMethods from '@ve/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { type IHotel } from '@entities/hotel';
import beachTypeFilters from '../config/beachTypeFilters';
import { type BeachTypeFilterItem } from '../types';

export default class BeachTypeFilterMethods extends FilterMethods<BeachTypeFilterItem, IHotel> {
    getCountAttribute(entities: IHotel[]): Record<BeachTypeFilterItem['key'], number> {
       return entities.reduce((acc, hotel) => {
        this.getListFilterAttributes().forEach((beachTypeFilter) => {
            const isHotelHasAttribute = hotel.beachType === beachTypeFilter.id;
            if (isHotelHasAttribute) {
                acc[beachTypeFilter.key]++;
            }
        });
        return acc;
       }, this.getAttributeObjectCount());
    }

    getIsOneHotelHasProperty(entities: IHotel[]): boolean {
        return entities
        .some((hotel) => this.getListFilterAttributes()
        .some((beachTypeFilter) => hotel.beachType === beachTypeFilter.id));
    }

    isSomeHotelHasBeachType(hotels: IHotel[]): boolean {
        return this.getIsOneHotelHasProperty(hotels);
    }
}
const beachTypeFilterMethods = new BeachTypeFilterMethods(beachTypeFilters).setFilterName('Тип пляжа');

export {
    beachTypeFilterMethods,
};
