import AbstractTrackingService from '@ve/services/analytics/yandexAnalytics/abstractTrackingService';

export default class IndexTrackingService extends AbstractTrackingService {
    private static _instance: IndexTrackingService;

    constructor() {
        super();
        if (IndexTrackingService._instance) {
            return IndexTrackingService._instance;
        }
        IndexTrackingService._instance = this;
    }
}
