/* eslint-disable */
import PriceRangeObjectValue from '../ObjectValue/PriceRange';

const PriceRangeFactory = function(data) {
	var priceRange = {};

	if(!((data['from'] && _.isNaN(parseInt(data['from'],10)))
		|| (data['to'] && _.isNaN(parseInt(data['to'],10))))) {

		data = {
			from:parseInt(data['from'] > 0 ? data['from'] : 0, 10),
			to:parseInt(data['to'] > 0 ? data['to'] : 0, 10)
		};

		if(data['from'] < 100) {
			data['from'] = 100;
		}

		if(data['to'] < 50000) {
			data['to'] = 50000;
		}

		if(data['from'] > data['to']) {
			priceRange = {
				from: data['to'],
				to: data['from']
			};
		} else {
			priceRange = {
				from: data['from'],
				to: data['to']
			};
		}
		return new PriceRangeObjectValue(priceRange);
	} else {
		return null;
	}
};

export default PriceRangeFactory;