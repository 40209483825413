import { TrackJS } from 'trackjs';
import getABTestValue from '@/js/common/Domain/Service/getABTestValue';

import errorHandlerService from './errorHandler';
import requestService from './request';

export default class SessionStatusService {
    constructor(sessionId, callback, params, url = '/frontend/tours/asyncSearch/') {
        //params.middleRequestsBehavior == everyJob, halfJobs

        this.url = url;
        this.waitingTime = 60000;
        this.responseDelay = 3000;
        this.statusTimeout = null;
        this.statusXHR = null;
        this.middleAlreadySent = false;

        this._getStatus({
            params, sessionId, callback,
        });
        this._initTimeout(params, callback);
    }

    _initTimeout(params, callback) {
        this.globalStatusTimeout = setTimeout(() => {
            clearTimeout(this.statusTimeout);
            if (this.statusXHR && this.statusXHR.readyState !== 4) {
                this.statusXHR.abort();
            }

            //callback.error(null,'Waiting time is up!',null);
            errorHandlerService('Search session waiting time is up!');

            //get all tours even time is up
            if (params.asyncStatus) {
                params.asyncStatus.jobsSuccess = params.asyncStatus.jobsCount;
            }
            callback.success();
        }, this.waitingTime);

        params.globalStatusTimeouts.push(this.globalStatusTimeout);
    }

    _getStatus({
                   params, sessionId, callback,
               }) {
        const {
            originalSearchUuid, searchUuid, asyncStatus, statusTimeouts,
        } = params;
        this.statusXHR = this._getSessionStatusAjax({
            sessionId,
            searchUuid,
            originalSearchUuid,
            callback: {
                success: (response) => {
                    let lastJobsSuccess = Infinity;
                    const data = response.result ? response.result : response.data;

                    if (response.success === true && asyncStatus) {
                        if (data.jobsSuccess > data.jobsCount) {
                            console.log(`jobsSuccess: ${data.jobsSuccess}, jobsCount: ${data.jobsCount}`);
                            TrackJS.track('Wrong jobsCount');
                        }

                        lastJobsSuccess = asyncStatus.jobsSuccess;
                        asyncStatus.jobsCount = parseInt(data.jobsCount, 10);
                        asyncStatus.jobsSuccess = parseInt(data.jobsSuccess, 10);
                        asyncStatus.operators = data?.tracking?.operators || {};
                    }
                    if (response.success === true && data.jobsCount === data.jobsSuccess) {
                        clearTimeout(this.globalStatusTimeout);
                        callback?.success?.();
                    } else {
                        this.statusTimeout = setTimeout(() => {
                            this._getStatus({
                                params, sessionId, callback,
                            });
                        }, this.responseDelay);

                        statusTimeouts.push(this.statusTimeout);

                        if (response.success !== true) {
                            callback?.error?.(null, response.message, null);
                            return;
                        }

                        switch (params.middleRequestsBehavior) {
                            case 'halfJobs':
                                if (data.jobsSuccess > data.jobsCount / 2 && !this.middleAlreadySent) {
                                    this.middleAlreadySent = true;
                                    callback?.middleRequest?.();
                                }
                                break;
                            case 'everyJob':
                            default:
                                if (lastJobsSuccess < data.jobsSuccess) {
                                    callback?.middleRequest?.();
                                }
                        }
                    }
                },
                error: () => {
                    this.statusTimeout = setTimeout(() => {
                        this._getStatus({
                            params, sessionId, callback,
                        });
                    }, this.responseDelay);

                    statusTimeouts.push(this.statusTimeout);
                },
            },
        });

        params.asyncAjaxRequests.push(this.statusXHR);
        params.ajaxRequests.push(this.statusXHR);
    }

    _getSessionStatusAjax(options) {
        const requestOptions = {
            cache: false,
            data: {
                searchUuid: options.searchUuid,
                abTests: getABTestValue(),
            },
            url: `${window.appConfig.apiGatewayUrl}${this.url}${encodeURIComponent(options.sessionId)}`,
        };

        return requestService(requestOptions, options.callback);
    }
}