/* eslint-disable */
import { TrackJS } from "trackjs";
import moment from "moment";
import 'moment/locale/ru';
import 'jquery.cookie';

import AjaxRequestManager from '../AjaxRequestManager';
import CheckInDateRangeFactory from '../Factory/checkInDateRange/CheckInDateRange';
import CountryFactory from '../Factory/Country';
import TourCriteriaFacroty from '../Factory/TourCriteria';
import HotelsRepository from '../Repository/Hotels';
import CountriesRepository from '../Repository/Countries';
import DepartureCitiesRepository from '../Repository/DepartureCities';
import EntityManager from '../EntityManager'
import DepartureCityFactory from '../Factory/DepartureCity';

import CheckInDateRangeObjectValue from '../ObjectValue/CheckInDateRange';

import wildcardDomain from "@/js/common/Domain/Service/wildcardDomain";

const ajaxRequestManager = new AjaxRequestManager({
	useCache: true
});
/*
* тур критерия формируется по приоритетам в порядке следования
* - из window.defaultTourCriteria - SearchController->changeRouteInit()
* - из url SeachController->changeRoute()
* - из localStorage - в методе SearchController->getAdditionalData()
* - если все это пустое то в this.loadTourCriteriaWithFormData в берется минимальная по position страна
**/
let tourCriteriaService = {
	loadTourCriteriaWithFormData: function (params, callback) {
		var setTourCriteria = params.setTourCriteria ? _.clone(params.setTourCriteria) : {},
			referenceUrl = params.referenceUrl ? params.referenceUrl : false,
			countriesRepository = params.countriesRepository ? params.countriesRepository : false,
			selectedDepartureCityId,
			selectedCountryId,
			setCheckInDateRange,
			today = moment();

		setCheckInDateRange = CheckInDateRangeFactory(setTourCriteria['checkInDateRange'], null, {
			disableOldDateCorrection: true
		});

		if (!setCheckInDateRange || !setCheckInDateRange.get('from') || !setCheckInDateRange.get('to') || (moment(setCheckInDateRange.get('from')) < today && moment(setCheckInDateRange.get('to')) < today)) {
			delete(setTourCriteria['checkInDateRange']);
		}

		selectedDepartureCityId = typeof(window.appConfig.defaultDepartureCityId) !== "undefined" ? window.appConfig.defaultDepartureCityId : (typeof(window.appConfig.defaultTourCriteria) !== "undefined" && window.appConfig.defaultTourCriteria.departureCity ? window.appConfig.defaultTourCriteria.departureCity : null);
		selectedCountryId = typeof(window.appConfig.defaultCountryId) !== "undefined" ? window.appConfig.defaultCountryId : (typeof(window.appConfig.defaultTourCriteria) !== "undefined" && window.appConfig.defaultTourCriteria.countries ? window.appConfig.defaultTourCriteria.countries[0] : null);

		this.getRemoteFormData({
				success: function (departureCities, countries) {
					var tourCriteriaCountries = setTourCriteria && setTourCriteria.countries && setTourCriteria.countries.length ? setTourCriteria.countries : ((typeof selectedCountryId !== "undefined" && selectedCountryId) ? [selectedCountryId] : null),
						defaultTourCriteriaCountryId = tourCriteriaCountries && tourCriteriaCountries.length ? tourCriteriaCountries[0] : null,
						tourCriteriaResorts = setTourCriteria && setTourCriteria.resorts && setTourCriteria.resorts.length ? setTourCriteria.resorts : (typeof defaultResorts !== "undefined" ? defaultResorts : null),
						tourCriteriaDepartureCityId = setTourCriteria && setTourCriteria.departureCity ? setTourCriteria.departureCity : selectedDepartureCityId,
						defaultTourCriteria,
						tourCriteriaType,
						tourCriteriaCountry,
						tourCriteriaDepartureCity,
						success = function () {
							callback && callback.success ? callback.success(new TourCriteriaFacroty(defaultTourCriteria, {
								defaultTourCriteria: defaultTourCriteria
							})) : '';
						},
						error = function () {
							callback && callback.error ? callback.error(new TourCriteriaFacroty(defaultTourCriteria, {
								defaultTourCriteria: defaultTourCriteria
							})) : ''
						},
						getHotels = function () {
							HotelsRepository.repository.getHotels({
								tourCriteria: defaultTourCriteria,
								callback: {
									success: success,
									error: error
								}
							});
						};

					if (defaultTourCriteriaCountryId && countries.get(defaultTourCriteriaCountryId)) {
						tourCriteriaCountry = countries.get(defaultTourCriteriaCountryId);
					} else {
						tourCriteriaCountry = countries.min(function (country) {
							return country.get('position');
						});
						tourCriteriaCountries = [tourCriteriaCountry.get('id')];
					}

					if (tourCriteriaDepartureCityId && departureCities?.get(tourCriteriaDepartureCityId)) {
						tourCriteriaDepartureCity = departureCities.get(tourCriteriaDepartureCityId);
					} else {
						tourCriteriaDepartureCity = departureCities.get(selectedDepartureCityId) || departureCities.get(2);
						tourCriteriaDepartureCityId = tourCriteriaDepartureCity?.get('id');
					}

					tourCriteriaType = tourCriteriaDepartureCity.get('type');

					defaultTourCriteria = $.extend({}, tourCriteriaCountry.get(tourCriteriaType + 'TourCriteria'), setTourCriteria, {
						departureCity: tourCriteriaDepartureCityId,
						countries: tourCriteriaCountries,
						resorts: tourCriteriaResorts ? tourCriteriaResorts : null
					});

					if (!_.isEmpty(defaultTourCriteria.hotels)) {
						tourCriteriaCountry.getResorts({
							success: getHotels,
							error: getHotels
						});
					} else {
						tourCriteriaCountry.getResorts({
							success: success,
							error: error
						});
					}
				}
			},
			{
				referenceUrl: referenceUrl,
				countriesRepository: countriesRepository
			});
	},
	getRemoteFormData: function (callback, params) {
		var referenceUrl = params && params.referenceUrl ? params && params.referenceUrl : false,
			countriesRepository = params && params.countriesRepository ? params.countriesRepository : CountriesRepository.repository,
			entityManager = new EntityManager(),
			options = {
				url: window.appConfig && window.appConfig.getFormData ? (window.appConfig.gatewayUrl + (referenceUrl ? referenceUrl : window.appConfig.getFormData)) : '',
				retriesAvailable: true,
				dataType: "jsonp"
			};

		ajaxRequestManager.getData(options, {
			success: function (data) {
				var countries = _.map(data.destinationListPositions, function (destinationListPosition) {
					var country = destinationListPosition.country;

					$.extend(country, {
						position: destinationListPosition.position,
						isPopular: destinationListPosition.popular,
						capitalTourCriteria: destinationListPosition.capitalTourCriteria,
						regionTourCriteria: destinationListPosition.regionTourCriteria,
					});
					return country;
				});

				entityManager.add({
					factory: CountryFactory,
					repository: countriesRepository
				}, countries, {merge: false});

				data.departureCities = _.sortBy(data.departureCities, function (departureCity) {
					return departureCity.id <= 2 ? departureCity.id : departureCity.name;
				});

				entityManager.add({
					factory: DepartureCityFactory,
					repository: DepartureCitiesRepository.repository
				}, data.departureCities, {merge: false});

				DepartureCitiesRepository.repository.each(function (departureCity) {
					departureCity.set('countries', countriesRepository);
				});

				if (data.departureCities.length && countries.length) {
					callback && callback.success ? callback.success(DepartureCitiesRepository.repository, countriesRepository) : '';
				} else {
					//console.log(data);
					console.log(options);
					TrackJS.track("No countries and departure cities returned!!");
				}
			},
			error: function (textStatus) {
				callback && callback.error ? callback.error() : '';
			}
		});
	},
	addTourCriteriaChangedField: function (fieldName) {
		var changedFields = [],
			changedFieldsFromCookie = $.cookie('tourCriteriaChanged');

		if (changedFieldsFromCookie && changedFieldsFromCookie !== '') {
			changedFields = changedFieldsFromCookie.split('.');
		}

		if (_.indexOf(changedFields, fieldName) === -1) {
			changedFields.push(fieldName);
		}

		$.cookie('tourCriteriaChanged', changedFields.join('.'), {path: '/', domain: wildcardDomain()});
	},
	getTourCriteriaChangedFields: function () {
		var changedFields = [],
			changedFieldsFromCookie = $.cookie('tourCriteriaChanged');

		if (changedFieldsFromCookie && changedFieldsFromCookie !== '') {
			changedFields = changedFieldsFromCookie.split('.');
		}

		return changedFields;
	},
	getMaybeWrongFields: function (tourCriteria) {
		var difference = [],
			country = tourCriteria.get('countries'),
			departureCity = tourCriteria.get('departureCity'),
			departureCityType = departureCity ? departureCity.get('type') : null,
			countryTourCriteria = country && country.at(0) ? (departureCityType ? country.at(0).get(departureCityType + 'TourCriteria') : country.at(0).get('tourCriteria')) : null,
			element,
			criteriaPriority,

			currentDate;

		countryTourCriteria = _.clone(countryTourCriteria);

		if (countryTourCriteria) {
			if (typeof(window.appConfig.defaultTourCriteria) != "undefined" && window.appConfig.defaultTourCriteria.checkInDateRange) {
				countryTourCriteria.checkInDateRange = new CheckInDateRangeFactory(window.appConfig.defaultTourCriteria.checkInDateRange);
			}

			if (typeof(window.appConfig.defaultTourCriteria) != "undefined" && window.appConfig.defaultTourCriteria.priceRange) {
				countryTourCriteria.priceRange = window.appConfig.defaultTourCriteria.priceRange;
			}

			if (countryTourCriteria.checkInDateRange && !(countryTourCriteria.checkInDateRange instanceof CheckInDateRangeObjectValue)) {
				countryTourCriteria.checkInDateRange = new CheckInDateRangeFactory(countryTourCriteria.checkInDateRange)
			}

			element = tourCriteria.get('priceRange');
			if (element !== null) {
				element.get('from') > countryTourCriteria['priceRange'].from || element.get('to') < countryTourCriteria['priceRange'].to ? difference.push({
					name: 'priceRange',
					priority: 1
				}) : null;
			}

			element = tourCriteria.get('hotelCategories');
			if (element !== null && element.length) {
				_.intersection(element.pluck('id'), countryTourCriteria.hotelCategories).length !== countryTourCriteria.hotelCategories.length ? difference.push({
					name: 'hotelCategories',
					priority: 2
				}) : null;
				countryTourCriteria.hotelCategories.length === 0 ? difference.push({
					name: 'hotelCategories',
					priority: 2
				}) : null;
			}

			element = tourCriteria.get('meals');
			if (element !== null && element.length) {
				_.intersection(element.pluck('id'), countryTourCriteria.meals).length !== countryTourCriteria.meals.length ? difference.push({
					name: 'meals',
					priority: 3
				}) : null;
				countryTourCriteria.meals.length === 0 ? difference.push({name: 'meals', priority: 3}) : null;
			}

			element = tourCriteria.get('hotels');
			if (element !== null && element.length) {
				difference.push({name: 'hotels', priority: 4});
			}

			element = tourCriteria.get('resorts');
			if (element !== null && element.length) {
				difference.push({name: 'resorts', priority: 5});
			}

			element = tourCriteria.get('checkInDateRange');
			if (element !== null && countryTourCriteria.checkInDateRange) {
				currentDate = new Date();

				if (element.get('from').getTime() - currentDate.getTime() > 15811200000/*half of year*/) {
					criteriaPriority = 1.5;
				} else {
					criteriaPriority = 6;
				}

				if (element.get('from').getTime() > countryTourCriteria.checkInDateRange.get('from').getTime() || element.get('to').getTime() < countryTourCriteria.checkInDateRange.get('to').getTime()) {
					difference.push({name: 'checkInDateRange', priority: criteriaPriority});
				}
			}

			element = tourCriteria.get('nightRange');
			if (element !== null) {
				element.get('from') > countryTourCriteria['nightRange'].from || element.get('to') < countryTourCriteria['nightRange'].to ? difference.push({
					name: 'nightRange',
					priority: 7
				}) : null;
			}

			difference = _.chain(difference)
				.sortBy(function (field) {
					return field.priority;
				})
				.map(function (field) {
					return field.name;
				}).value();
		}

		/* hack for ski page*/
		if (window.appConfig.isSki) {
			difference = _.without(difference, 'nightRange');
		}

		return difference;
	},
	clearTourCriteriaChangedFields: function () {
		$.cookie('tourCriteriaChanged', '', {path: '/', domain: wildcardDomain()});
	}
};

export default tourCriteriaService;
