
    import { defineComponent, unref, computed } from 'vue';
    import { useState, useActions } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import FilterListRender from '@ve/components/filters/common/components/FilterListRender/FilterListRender.vue';
    import ToggleListContent from '@ve/components/filters/common/components/toggleListContent/ToggleListContent.vue';
    import useFilterAttributesAnalytics from '@ve/components/filters/common/use/useFilterAttributesAnalytics';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import FiltersListSection from '@ve/components/searchFilters/filterList/desktop/FiltersListSection.vue';
    import FilterAttributeMethodsWaterActivities from '../common/FilterMethodsWaterActivities';

    export default defineComponent({
        components: {
            ToggleListContent,
            FilterListRender,
            FiltersListSection,
            FilterPostWrapper,
        },
        setup() {
            const {
                filteredHotels, syncHotels, takenWaterActivities, isPopularityFilterActive,
            } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['filteredHotels', 'syncHotels', 'takenWaterActivities', 'isPopularityFilterActive']);
            const { action_takenWaterActivities: setWaterActivities } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_takenWaterActivities']);

            const waterActivities = computed({
                get: () => unref(takenWaterActivities),
                set: (idsWaterActivities) => setWaterActivities(idsWaterActivities),
            });

            const atLeastOneHotelHasWaterActivitiesOfAllHotels = computed(() => FilterAttributeMethodsWaterActivities.getIsOneHotelHasProperty(unref(syncHotels)));

            return {
                waterActivities,
                FilterAttributeMethodsWaterActivities,
                atLeastOneHotelHasWaterActivitiesOfAllHotels,
                filteredHotels,
                syncHotels,
                isPopularityFilterActive,
                ...useFilterAttributesAnalytics(FilterAttributeMethodsWaterActivities),
            };
        },
    });
