import _ from 'underscore';

import { ApiRoute } from '@ve/types/api/ApiRoute';
import { Route } from '@ve/types/Route';
import { Airline } from '@ve/types/Airline';
import { ApiLeg } from '@ve/types/api/ApiLeg';
import { Tariff } from '@ve/types/api/Tariff';
import { Airport } from '@ve/types/Airport';
import { Leg } from '@ve/types/Leg';
import { LegInfo } from '@/js/common/Domain/Service/searchTour/service/apiTourToTourAdapter/types';

import groupVariants from './groupVariants';
import legsAdapter from './legsAdapter';

export default ({
                    routes: apiRoutes,
                    legs,
                    tariffs,
                    airlines,
                    airports,
                }:
                    { routes: ApiRoute[], legs: ApiLeg[], tariffs: Tariff[], airlines: Airline[], airports: Airport[] }): Route[] => {
    const referencesIndex = {
        legKeys: _.indexBy(legs, 'id'),
        tariffKeys: _.indexBy(tariffs, 'id'),
        airlineKeys: _.indexBy(airlines, 'id'),
        airportKeys: _.indexBy(airports, 'id'),
    };
    return _.reduce(apiRoutes, (routes, apiRoute) => {
        const thereAvailableLegs = legsAdapter({
            ...referencesIndex,
            legs: apiRoute.there.legs,
        });
        const backAvailableLegs = legsAdapter({
            ...referencesIndex,
            legs: apiRoute.back.legs,
        });

        const groupedThereLegs = groupVariants(thereAvailableLegs);
        const groupedBackLegs = groupVariants(backAvailableLegs);

        const groupedLegs = groupVariants([groupedThereLegs, groupedBackLegs]);

        groupedLegs.forEach((groupedLeg) => {
            const [thereLegs, backLegs] = groupedLeg;

            const collectLegInfo = (legsData: LegInfo[]) => legsData.reduce((legsInfo, leg) => {
                legsInfo.id.push(leg.id);
                legsInfo.legs.push(leg.leg);
                return {
                    ...legsInfo,
                    totalPrice: legsInfo.totalPrice + leg.additionalTax,
                };
            }, {
                id: [] as string[],
                totalPrice: 0,
                legs: [] as Leg[],
            });

            const there = collectLegInfo(thereLegs);
            const back = collectLegInfo(backLegs);

            const route = {
                pk: `${apiRoute.id}__${there.id.join('_')}__${back.id.join('_')}`,
                routeId: apiRoute.id,
                additionalTax: there.totalPrice + back.totalPrice,
                there: {
                    availableTransfers: apiRoute.there.transfers,
                    isDirect: there.legs.length === 1,
                    legs: there.legs,
                    routeTime: apiRoute.there.routeTime,
                    extraBaggage: apiRoute.there.extraBaggage,
                },
                back: {
                    availableTransfers: apiRoute.back.transfers,
                    isDirect: back.legs.length === 1,
                    legs: back.legs,
                    routeTime: apiRoute.back.routeTime,
                    extraBaggage: apiRoute.back.extraBaggage,
                },
            };
            routes.push(route);
        });

        return routes;
    }, [] as Route[]);
};
