<script langs='ts'>
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            name: {
                type: String,
                required: true,
            },
            id: {
                type: Number,
                required: true,
            },
        },
    });
</script>

<template lang='pug'>
    a.alternative-departure__item(@click="$emit('change', {name, id})") {{ name }}
</template>

<style lang='scss'>
    .alternative-departure__item  {
        font-family: openSansSemibold;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px dashed #059BCE;
        margin-right: 15px;
        margin-bottom: 12px;
        color: #059bce;
        height: 20px;
            &:hover {
                color: #73CEED;
            }
            &:active {
                color: #006F94;
            }
            &:visited {
                color: #059bce;
            }
    }

</style>