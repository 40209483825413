
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import RefreshLoader from '@ve/components/refreshLoader/desktop/RefreshLoader.vue';

    @Component({
        name: 'RefreshLoaderWrapper',
        components: { RefreshLoader },
    })
    export default class RefreshLoaderWrapper extends Vue {
        @Prop({ default: '' }) readonly storeModuleName!: string;

        @Prop({ default: '' }) readonly showPropName!: string;

        get show():boolean {
            return this.$store.state?.[this.storeModuleName]?.[this.showPropName] || false;
        }
    }
