import AbstractFilterMethods from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { IHotel } from '@entities/hotel';
import childServiceFilters from '../config/childServiceFilters';
import { ChildServiceFilterItem } from '../types/ChildServiceFilterItem';

export default class ChildServiceFilterMethods extends AbstractFilterMethods<ChildServiceFilterItem, IHotel> {
    getCountAttribute(entities: IHotel[]): Record<ChildServiceFilterItem['key'], number> {
       return entities.reduce((acc, hotel) => {
        this.getListFilterAttributes().forEach((serviceChildAttribute) => {
            const isHotelHasAttribute = hotel.childServices.includes(serviceChildAttribute.id);
            if (isHotelHasAttribute) {
                acc[serviceChildAttribute.key]++;
            }
        });
        return acc;
       }, this.getAttributeObjectCount());
    }

    getIsOneHotelHasProperty(entities: IHotel[]): boolean {
        return entities
        .some((hotel) => this.getListFilterAttributes()
        .some((serviceChildAttribute) => hotel.childServices.includes(serviceChildAttribute.id)));
    }

    isSomeHotelHasChildService(hotels: IHotel[]): boolean {
        return this.getIsOneHotelHasProperty(hotels);
    }
}
const childServiceFilterMethods = new ChildServiceFilterMethods(childServiceFilters).setFilterName('Услуги для детей');

export {
    childServiceFilterMethods,
};
