import { useState } from 'vuex-composition-helpers';
import { computed, unref } from 'vue';
import { IMapForSearchDataState, IMapForSearchFormState } from '@ve/pages/serp/store';
import { NAMESPACE as MAP_FOR_SEARCH_DATA } from '@ve/pages/serp/store/SearchDataStore';

import { Resort } from '@ve/types';

export default function () {
    const { resorts } = useState<IMapForSearchFormState>('mapForSearchForm', ['resorts']);
    const { searchCountries: countries } = useState<IMapForSearchDataState>(MAP_FOR_SEARCH_DATA, ['searchCountries']);
    const resortRepository = computed((): Resort[] => unref(countries)[0]?.resorts || []);

    return {
        resorts,
        resortRepository,
        countries,
    };
}
