/* eslint-disable */
import requestService from "@/js/common/Domain/Service/request";

var regionType = null,
	ajaxInProgress = false;

let getRegionTypeService = function(callback) {
	var ajaxOptions,
		$document;

	callback = callback ? callback : function(){};

	if(_.isNull(regionType)) {
		$document = $(document);

		$document.one('getRegionTypeComplete', function () {
			callback(regionType);
		});

		if(!ajaxInProgress) {
			ajaxOptions = {
				url: '/regions/typeOfRegion',
				dataType: "json",
				type: "GET"
			};

			requestService(ajaxOptions, {
				success: function (response) {
					if (response.success === true && response.result && response.result.type) {
						regionType = response.result.type;
					} else {
						regionType = 'unknown';
					}
					$document.trigger('getRegionTypeComplete');
				},
				error: function () {
					regionType = 'unknown';
					$document.trigger('getRegionTypeComplete');
				}
			});
			ajaxInProgress = true;
		}
	} else {
		callback(regionType);
	}
};

export default getRegionTypeService;