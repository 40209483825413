/* eslint-disable */
import errorHandlerService from './Service/errorHandler';

const AjaxRequestManager = function (options) {
    const self = this;
    const abortPrevious = options?.abortPrevious !== undefined ? options.abortPrevious : true;
    const useCache = options?.useCache !== undefined ? options.useCache : false;
    const allAjaxRequests = [];
    const ajaxRequests = {};
    const cache = {};
    let cacheKey;

    self.getData = function (getDataOptions = {}, callback) {
        const retriesData = {
            retryTimeout: null,
            attempts: getDataOptions?.numOfAttempts || 3,
            currentAttempt: 1,
        };
        const retriesAvailable = getDataOptions.retriesAvailable || false;
        const extendedOptions = {
            type: 'GET',
            ..._.omit(getDataOptions, 'retriesAvailable', 'numOfAttempts'),
        };

        if (useCache) {
            cacheKey = $.param(extendedOptions);
        }

        if (abortPrevious) {
            self.abortAll(extendedOptions);
        }

        function nestedCall() {
            ajaxRequests[extendedOptions.url] = $.ajax(extendedOptions).success((response) => {
                if (response.success === true) {
                    if (useCache) {
                        cache[cacheKey] = response;
                    }
                    if (callback?.success) {
                        callback.success(!_.isUndefined(response.result) ? response.result : response.data, response, 200);
                    }
                } else {
                    errorHandlerService(response.message);
                    if (callback?.error) {
                        callback.error(!_.isUndefined(response.result) ? response.result : response.data, response, 200);
                    }
                }
            }).error((XMLHttpRequest, textStatus, errorThrown) => {
                const {
                    status,
                } = XMLHttpRequest;

                if (status && status < 600 && status >= 500) {
                    if (retriesData.retryTimeout) {
                        clearTimeout(retriesData.retryTimeout);
                    }
                    if (textStatus === 'error') {
                        retriesData.currentAttempt++;

                        if (retriesData.currentAttempt <= retriesData.attempts && retriesAvailable) {
                            retriesData.retryTimeout = setTimeout(() => {
                                nestedCall();
                            }, 2000);
                        }
                    }
                }

                errorHandlerService(textStatus, {
                    XMLHttpRequest,
                    textStatus: errorThrown,
                    errorThrown,
                });

                if (callback?.error) {
                    callback.error(textStatus, null, status);
                }
            });
            allAjaxRequests.push(ajaxRequests[extendedOptions.url]);
        }

        if (useCache && cache[cacheKey]) {
            if (callback?.success) {
                callback.success(!_.isUndefined(cache[cacheKey].result) ? cache[cacheKey].result : cache[cacheKey].data, cache[cacheKey], 200);
            }
        } else {
            nestedCall();
        }

        return ajaxRequests[extendedOptions.url];
    };

    self.abortAll = function (abortAllOptions) {
        if (ajaxRequests[abortAllOptions.url] && ajaxRequests[abortAllOptions.url].readyState !== 4) {
            ajaxRequests[abortAllOptions.url].abort();
        }
    };

    self.abortAllRequests = function () {
        _.each(allAjaxRequests, (ajaxRequest) => {
            if (ajaxRequest && ajaxRequest.readyState !== 4) {
                ajaxRequest.abort();
            }
        });
    };
};

export default AjaxRequestManager;