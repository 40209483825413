/* eslint-disable */
import 'jcarousel';
import 'jquery.cookie';
import ls from 'local-storage';
import getStore from '@ve/services/getStore/getStore';
import { TrackJS } from 'trackjs';
import Vue from 'vue';
import useVueCompositionApi from '@/js/common/plugins/use-vue-composition-api';
import '@/js/common/components/emptySerp/found-by-another-date.css';
import '@/js/common/components/emptySerp/view-similar-tours.css';
import getTourProductsFromHotels from '@/js/common/Domain/Service/getTourProductsFromHotels';
import firstPaymentDefinitionService from '@/js/common/Domain/Service/firstPaymentDefinition';
import getTourProductFirstPaymentDefinitions from '@/js/common/Domain/Service/hotelSearch/services/getTourProductFirstPaymentDefinitions';
import abTests from '@/js/common/Domain/Service/abTests';
import { checkOnBlock, getAlternativeCountry, getBlockCountries } from '@/js/common/Domain/Service';
import Search from '@/js/common/Domain/Service/Search';
import ADVCakeService from '@/js/common/Domain/Service/ADVCake';
import TourCriteriaObjectValue from '@/js/common/Domain/ObjectValue/TourCriteria';
import fromRouterHydrator from '@/js/common/Domain/Hydrator/fromRouter';
import toRouterHydrator from '@/js/common/Domain/Hydrator/toRouter';
import SearchFormAutocompleteView from '@/js/desktop/components/searchFormAutocomplete/SearchFormAutocomplete';
import SearchPageView from '@/js/desktop/View/SearchPage';
import ProgressBar from '@/js/desktop/components/progressBar/progress-bar';
import PriceCalendarView from '@/js/common/components/PriceCalendar/PriceCalendar';
import FinishSearchWithEmptySerpService from '@/js/common/Domain/Service/FinishSearchWithEmptySerp';
import SearchRouter from '@/js/common/Domain/Router/SearchRouter';
import searchSessionService from '@/js/common/Domain/Service/search/searchSession';
import tourCriteriaService from '@/js/common/Domain/Service/tourCriteria';
import toLocalStorageTourCriteriaHydrator from '@/js/common/Domain/Hydrator/toLocalStorageTourCriteria';
import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import toRouterHotelHydrator from '@/js/common/Domain/Hydrator/toRouterHotel';

import trackingService from '@/js/common/Domain/Service/tracking/tracking';
import mindBoxService from '@/js/common/Service/mindBoxService/MindBoxServiceDesktop';
import gaAsync from '@/js/common/Domain/Service/gaAsync';
import sociomanticManager from '@/js/common/Domain/Service/sociomantic';
import PriceRangeFactory from '@/js/common/Domain/Factory/PriceRange';
import WeatherWidgetView from '@/js/desktop/components/weatherWidget/weather-widget';
import ExpiredPopupView from '@/js/desktop/components/expiredPopup/ExpiredPopup';
import VisaWarningView from '@/js/desktop/components/search/visaWarning/VisaWarning';
import NoToursBannerView from '@/js/common/components/no-tours-banner/no-tours-banner';
import ClosedCountryBanner from '@/js/common/components/closedCountryBanner';
import searchHistoryService from '@/js/common/Domain/Service/searchHistory';
import differencesTourCriteria from '@/js/common/Domain/Service/differencesTourCriteria';
import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';
import SearchRequestFactory from '@/js/common/Domain/Factory/SearchRequest';
import generalLeadLoader from '@/js/desktop/components/generalLead/generalLeadLoader';
import { DESKTOP_SEARCH_PAGE_BANNER_STEP } from '@/js/common/Constants/bannerSteps';
import getStaticImagesUrls from '@/js/common/Domain/Service/getStaticImagesUrls';
import trackImageErrors from '@/js/common/Domain/Service/trackImageErrors';
import CountriesBannerInfoDesktop from '@ve/components/countriesBannerInfo/desktop/CountriesBannerInfoDesktop.vue';
import AlternativeDeparture from '@ve/components/alternativeDeparture/desktop/AlternativeDeparture.vue';
import NoToursInStaticDesktop from '@ve/components/banners/noToursInStaticBanner/NoToursInStaticDesktop.vue';
import NoToursInStaticStore from '@ve/components/banners/noToursInStaticBanner/store';
import initAlternativeSearch from '@ve/components/alternativeDeparture/common/initAlternativeSearch';
import SearchDataStore from '@ve/pages/serp/store/SearchDataStore';
import PopularityOffersBanner from '@ve/pages/serp/components/popularityOffersBanner/PopularityOffersBanner.vue';
import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
import productsSearchStore from '@ve/pages/serp/store/productsSearchStore';
import collectionLengthFactory from '@ve/services/getStore/utils/collectionLengthFactory';
import serpPageStateStore from '@ve/pages/serp/store/serpPageStateStore';
import priceCalendarStore from '@ve/components/priceCalendar/common/store/priceCalendarStore';
import registerModule from '@ve/services/registerModule';

import { NAMESPACE as ALTERNATIVE_SEARCH_STORE_NAME } from '@ve/components/alternativeDeparture/common/store/AlternativeSearchStore';
import EmptySearchStore, { NAMESPACE as EMPTY_SEARCH_STORE } from '@ve/components/emptySearch/common/store/EmptySearchStore';
import AbstractSearchController from '@/js/desktop/View/Abstract/AbstractSearchController';
import { NAMESPACE as MAP_FOR_SEARCH_FORM } from '@ve/pages/serp/store/SearchFormStore';
import { productTypes } from '@fr/constants';
import filterByPopularityOffers from './filters/filterByPopularityOffers';

const ADVCake = new ADVCakeService('search');

const SearchController = AbstractSearchController.extend({
    currentSearchModel: new TourCriteriaObjectValue(),
    progressBarTimer: 0,
    weatherWidget: null,
    tourCriteriaForSearchByDefaultsCriteria: null,
    view: null,
    fromRouterHydrator,
    toRouterHydrator,
    initAdditionalViews() {

    },
    initAnalytics() {
        this.serpTrackingService = new SerpTrackingService();
    },
    initDataAnalyticsService() {
        this.dataLayerAnalytics = new DataLayerAnalytics({
            step: 'searchPage',
            maxStep: 1,
            bannersStep: DESKTOP_SEARCH_PAGE_BANNER_STEP,
        });
    },
    initSearchForm() {
        this.searchFormView = new SearchFormAutocompleteView();
    },
    initModels() {
        console.time('init');

        this.initSearchForm();
        this.model = this.searchFormView.model;

        this.initSearchPageView();
    },
    initSearchPageView() {
        this.view = new SearchPageView({
            el: '#forSearchView',
            searchFormViewModel: this.model,
            dataLayerAnalytics: this.dataLayerAnalytics,
        });
    },
    initComponents() {
        const self = this;

        this.progressBar = new ProgressBar({ el: '#searchProgressBar', parent: this.view.model });

        this.weatherWidget = new WeatherWidgetView({ el: '#weatherWidget' });

        this.initPriceCalendar();
        new ExpiredPopupView({
            el: '#expiredContainer',
            searchPageViewModel: this.view.model,
            updateResultCallback() {
                self.formSubmit({ goToHotelPage: false });
            },
        });

        new VisaWarningView({
            el: '#visaWarning',
            searchPageViewModel: this.view.model,
        });

        this.noToursBanner = new NoToursBannerView({
            el: '.js-component-no-tours-banner',
            model: this.view.model,
            controllerModel: this.model,
        });

        this.closedCountryBanner = new ClosedCountryBanner({
            el: '.js-component-closed-country-banner',
            model: this.view.model,
            controllerModel: this.mode,
        });

        this.initVueComponents();

        this.initFinishSearchWithEmptySerp();

        generalLeadLoader.setModel(this.model);
        this.initAdditionalViews();
    },
    initFinishSearchWithEmptySerp() {
        this.searchFinish = new FinishSearchWithEmptySerpService(
            this.searchService,
            this,
            {
                productType: 'TourProduct',
                store: getStore(),
            },
        );
    },
    initPriceCalendar() {
        this.priceCalendar = new PriceCalendarView({
            searchViewModel: this.view.model,
            checkInDateRange: this.model.get('checkInDateRange'),
            parent: this,
        });

        this.initPriceCalendarStore();
    },
    initCountryInfo(store) {
        new Vue({
            name: 'CountriesBannerInfo',
            el: '.js-countries-banner-info',
            render: (h) => h(CountriesBannerInfoDesktop),
            store,
        });
    },
    activateAlternativeSearch(store, context, component) {
        initAlternativeSearch(store, context, component);
    },
    initVueComponents() {
        useVueCompositionApi();
        const store = getStore({
            name: 'mapForCountryConditions',
            backboneMap: [
                {
                    fields: [{
                        countries: {
                            field: 'searchData',
                            action: (model, searchData) => searchData?.get('countries')?.pluck('id'),
                        },
                    }],
                    model: this.view.model,
                },
            ],
        });

        SearchDataStore({ model: this.view.model });

        productsSearchStore({
            model: this.view.model,
            hotelsLengthFactory: collectionLengthFactory('syncHotels'),
        });

        serpPageStateStore({
            model: this.view.model,
        });

        this.initEmptySearchStore(store);
        this.activateAlternativeSearch(store, this, AlternativeDeparture);

        this.initCountryInfo(store);
        this.initPopularityOffersBanner(store);
        this.initNoToursInStaticBanner();
    },
    initPriceCalendarStore() {
        priceCalendarStore({
            model: this.priceCalendar.model,
        });
    },
    initEmptySearchStore(store) {
        registerModule.register(
            store,
            EmptySearchStore,
            EMPTY_SEARCH_STORE,
        );
    },
    initPopularityOffersBanner(store) {
        new Vue({
            name: 'PopularityOffersBanner',
            el: '#popularity-offers-banner',
            render: (h) => h(PopularityOffersBanner),
            store,
        });
    },
    initNoToursInStaticBanner() {
        const store = NoToursInStaticStore({
            model: this.view.model,
        });

        if (!window.appConfig.noToursFound) {
            return;
        }

        new Vue({
            name: 'NoToursInStaticDesktop',
            render: (h) => h(NoToursInStaticDesktop, {
                on: {
                    'update-search-click': () => {
                        this.formSubmit();
                    },
                },
            }),
            store,
        }).$mount('.js-serp-no-static-banner');
    },
    changeFilterAsCriteriaDebounce: _.debounce(function (event, model, context) {
        this.changeFilterAsCriteria(event, model, context);
    }, 1000),
    changeFilterAsCriteria(event, model, context) {
        if (context && (context.initiator === 'view' || context.initiator === 'filtersPopover')) {
            this.formSubmit({ goToHotelPage: false, openBooking: false });
        }
    },
    getUpdateUrl() {
        const currentTourCriteria = this.model.getTourCriteria();
        const otherCriterias = this.view.getOtherCriterias();
        const paramsForRouter = this.toRouterHydrator(currentTourCriteria, otherCriterias);

        return `?${$.param(paramsForRouter)}`;
    },
    updateUrl(model, value, context, options) {
        const url = this.getUpdateUrl();
        const router = new SearchRouter().getInstance();

        router.go(url, options || {});
    },

    updateFirstPaymentDefinitions() {
        const syncHotels = this.view.model.get('syncHotels');

        if (syncHotels?.length) {
            const tourProducts = getTourProductsFromHotels(syncHotels);
            const firstPaymentDefinitions = getTourProductFirstPaymentDefinitions(tourProducts);

            firstPaymentDefinitionService.setData(firstPaymentDefinitions);
        }
    },

    updateSyncHotelsHandler() {
        this.updateFirstPaymentDefinitions();
    },

    goSearchWithClearStore(...args) {
        this.newUserSearchState();
        this.goSearch(...args);
    },
    newUserSearchState() {
        const store = getStore();
        store.commit(`${ALTERNATIVE_SEARCH_STORE_NAME}/setDefaultState`);
        store.dispatch(`${EMPTY_SEARCH_STORE}/startNewUserSearch`);
    },

    initEvents() {
        const router = new SearchRouter().getInstance();
        const $toursList = $('.staticContainer.hotelsContainer');

        router.on('route', this.changeRouteInit.bind(this));

        this.model.get('departureCity').on('change:model', this.model.departureCityChanged.bind(this));
        this.model.get('countries').on('change:model', this.model.countriesChanged.bind(this));
        this.model.get('resorts').on('change:model', this.changeFilterAsCriteriaDebounce.bind(this));
        this.model.get('hotels').on('change:model', this.model.hotelsChanged.bind(this));

        this.model.get('meals').on('change:model', this.changeFilterAsCriteriaDebounce.bind(this));
        this.model.get('hotelCategories').on('change:model', this.changeFilterAsCriteriaDebounce.bind(this));
        this.model.get('directFlight').on('change:model', this.changeFilterAsCriteria.bind(this));
        this.model.get('warranted').on('change:model', this.changeFilterAsCriteria.bind(this));

        this.view.model.on('change:syncHotels', this.updateSyncHotelsHandler.bind(this));
        this.view.model.on('change:sortStrategy', this.updateUrl.bind(this));
        this.view.model.get('filterCriteria').on('change', this.updateUrl.bind(this));

        this.view.model.get('filterCriteria').on('change', this.deactivatePopularityOffersFilterByTakenFilters.bind(this));
        this.currentSearchModel.on('change', () => {
            const store = getStore();
            const isPairOfDepartureCityAndArrivalCountryAreValid = store.getters[`${MAP_FOR_SEARCH_FORM}/isPairOfDepartureCityAndArrivalCountryAreValid`];
            if (!isPairOfDepartureCityAndArrivalCountryAreValid) {
                this.resetDirectFlightFilter();
            }
            this.goSearchWithClearStore.call(this, this.currentSearchModel);
        });
        this.model.on('goAsyncSearch', this.goSearchWithClearStore.bind(this));
        this.model.on('startSearch', this.formSubmit.bind(this));
        $('#startSearch, .startSearch').on('click', this.formSubmit.bind(this));
        /* init gallery for static*/

        if (!window.appConfig.noToursFound) {
            $('.js-images-carousel', $toursList).jcarousel({
                list: '.list',
                items: 'li',
                wrap: 'both',
                vertical: false,
            }).on('jcarousel:visiblein', 'li', function () {
                const $element = $('a:not(.btn)', this);
                const imageUrl = $element.data('src');
                if (imageUrl) {
                    trackImageErrors([imageUrl]);
                    $element.css('background-image', `url("${imageUrl}")`);
                }
            });

            $('.js-images-carousel-control-prev', $toursList).jcarouselControl({
                target: '-=1',
            });

            $('.js-images-carousel-control-next', $toursList).jcarouselControl({
                target: '+=1',
            });

            const imagesUrls = getStaticImagesUrls({
                $el: $toursList.find('ul.imagesHtml').find('li:first-child').find('a'),
            });
            trackImageErrors(imagesUrls);
        }
    },

    resetDirectFlightFilter() {
        this.currentSearchModel.set('directFlight', false, { silent: true });
        this.model.get('directFlight')?.set({ model: false });
        this.updateUrl();
    },

    startSearchIfControlChanged: null,
    deactivatePopularityOffersFilterByTakenFilters(model) {
        const allHotels = this.view.model.get('syncHotels');
        const filteredHotels = this.view.model.get('filteredHotels');
        const isResetPopularityOffers = allHotels?.length && filteredHotels?.length < 10 && model.get('isPopularityFilterActive');
        if (isResetPopularityOffers) {
            this.view.model.get('filterCriteria').set('isPopularityFilterActive', false);
            this.serpTrackingService.popularOffersByFiltersLessTen();
        }
    },
    getAdditionalData() { // abstract
        return {};
    },
    startSearchForce() { // abstract
        return false;
    },
    resetModel() {
        this.model.get('countries').setNull();
        this.model.get('resorts').setNull();
        this.model.get('hotels').setNull();
        this.model.get('checkInDateRange').setNull();
        this.model.get('nightRange').setNull();
        this.model.get('touristGroup').setNull();
        this.model.get('hotelCategories').setNull();
        this.model.get('meals').setNull();
        this.model.get('priceRange').setNull();
    },
    /**
     *
     * @param type search - пользователь зашел с критерией | default - без критерии
     * @param url
     */
    changeRouteInit(type, url) {
        const self = this;
        const controlChanged = function () {
            self.view.model.set('beforeToursLoading', false);
            self.changeRouteInit = self.changeRoute;
            self.changeRoute(type, url, additionalData.setTourCriteria);
        };
        var additionalData = typeof window.appConfig.defaultTourCriteria !== 'undefined' ? window.appConfig.defaultTourCriteria : {};

        url = _.isArray(url) ? url[0] : url;

        additionalData = { setTourCriteria: $.extend({}, additionalData, this.getAdditionalData()) };
        this.startSearchIfControlChanged = controlChanged;

        if (type === 'search' || this.startSearchForce()) {
            this.view.model.set('beforeToursLoading', false);
            this.view.model.set('tourCriteriaLoaded', true);
            self.changeRouteInit = self.changeRoute;
            this.changeRoute(type, url, additionalData.setTourCriteria);
        } else {
            this.resetModel();
            searchSessionService.generateNewSearchId();

            tourCriteriaService.loadTourCriteriaWithFormData(additionalData, {
                success: (tourCriteria) => {
                    this.model.setTourCriteria(tourCriteria);
                    this.view.model.on('change:sortStrategy change:viewMode', controlChanged);
                    this.view.model.set('tourCriteriaLoaded', true);
                    this.view.model.get('filterCriteria').on('change', controlChanged);
                    $('.showMoreBtn').one('click', controlChanged);
                    this.model.trigger('formDataLoaded');
                    this.view.model.trigger('formDataLoaded');
                    this.view.model.trigger('initPageWithoutCriteria');
                },
            });
        }
    },
    storeCriteria(tourCriteria) {
        const paramsForLocalStorage = toLocalStorageTourCriteriaHydrator(tourCriteria);

        ls.set('tourCriteria', paramsForLocalStorage);
    },
    mindboxTracking() {
        mindBoxService.trackViewSerp('serp_main', this.model, this.view.model);
    },
    changeRoute(type, url, additionalData) {
        let setTourCriteria = {};
        const self = this;
        const loadTourCriteriaWithFormData = function (params, callback) {
            tourCriteriaService.loadTourCriteriaWithFormData(params, {
                success(tourCriteria) {
                    self.model.setTourCriteria(tourCriteria);
                    callback();
                    self.model.trigger('formDataLoaded');
                },
                error() {
                },
            });
        };

        if (type === 'search') {
            setTourCriteria = this.fromRouterHydrator(_.isArray(url) ? url[0] : url);
        } else {
            if (additionalData) {
                setTourCriteria.tourCriteria = additionalData;
            }
            tourCriteriaService.clearTourCriteriaChangedFields();
        }

        self.view.setOtherCriterias(setTourCriteria.other);
        setTourCriteria = $.extend({}, this.getAdditionalData(), setTourCriteria.tourCriteria);

        this.resetModel();

        loadTourCriteriaWithFormData({ setTourCriteria }, () => {
            const currentTourCriteria = self.model.getTourCriteria();

            self.updateUrl(null, null, null, { replace: true });

            self.storeCriteria(currentTourCriteria);
            self.currentSearchModel.set(currentTourCriteria.attributes);
            self.view.model.set('searchData', currentTourCriteria);
            self.view.scrollToTop();
            self.model.trigger('formDataLoaded');
        });
    },
    weatherUpdate(tourCriteria) {
        if (this.weatherWidget) {
            if (tourCriteria) {
                this.weatherWidget.updateData(tourCriteria);
            } else {
                this.weatherWidget.updateData(this.model.getTourCriteria());
            }
        }
    },
    weatherReset() {
        if (this.weatherWidget) {
            this.weatherWidget.updateData();
        }
    },

    getUrlParams(tourCriteria) {
        return {
            tourCriteria,
            productType: productTypes.TOUR_OFFER,
        };
    },
    formSubmit(params) {
        if (params?.currentTarget && $(params.currentTarget).hasClass('js-scroll-to-top') && $(document).scrollTop() > 0) {
            this.view.scrollToTop();
        }

        const store = getStore();
        store.commit('mapForFiltersSerp/setDefaultHotelsFilter');
        this.view.filters.resetPreviousFilters();

        const goToHotelPage = !(params && params.goToHotelPage === false);
        if (this.model.getTourCriteria().isValid() && this.searchFormView.isValid()) {
            const self = this;
            const departureCityType = this.model.get('departureCity').get('model').get('type');
            const { priceRange } = CountriesRepository.repository.at(0)?.get(`${departureCityType}TourCriteria`);
            this.weatherReset();
            console.log('Submit search form');
            this.view.model.set('beforeToursLoading', false);

            this.model.get('priceRange').set('model', new PriceRangeFactory(priceRange));
            searchSessionService.changeSearchIdForNewSearch({
                oldSearchCriteria: this.view.model.get('searchData'),
                newSearchCriteria: this.model.getTourCriteria(),
            });
            if (differencesTourCriteria({
                oldSearchCriteria: this.view.model.get('searchData'),
                newSearchCriteria: this.model.getTourCriteria(),
            })) {
                this.view.model.trigger('differencesFormCriteria');
                this.view.filters.showPreviousFilters();
            }
            const currentTourCriteria = this.model.getTourCriteria();
            const currentTourCriteriaAttributes = currentTourCriteria.attributes;
            let paramsForRouter;
            const router = new SearchRouter().getInstance();
            const forceChangeTrigger = _.isEqual(this.currentSearchModel.attributes, currentTourCriteria.attributes);
            let goToUrl;

            $.cookie('markSearchSource', true, { path: '/', domain: 'all' });

            this.storeCriteria(currentTourCriteria);

            if (params && params.currentTarget && $(params.currentTarget).is('#startSearch')
                && currentTourCriteria.get('countries').length === 1) {
                searchHistoryService.addSearch(currentTourCriteria);
            }

            if (goToHotelPage && currentTourCriteria.get('hotels') && currentTourCriteria.get('hotels').length === 1) {
                this.callFormHotelNameSearchClickMetric();
                paramsForRouter = toRouterHotelHydrator(this.getUrlParams(currentTourCriteria));
                goToUrl = `/goto/hotel/${currentTourCriteria.get('hotels').at(0).get('id')}#?${$.param(paramsForRouter)}`;
                router.go(goToUrl);
            } else {
                if (params && params.openBooking === false) {
                    self.updateUrl();
                } else {
                    self.updateUrl();
                    if (this.dataLayerAnalytics) {
                        this.dataLayerAnalytics.clickSearchButtonEvent();
                    }
                }

                currentTourCriteriaAttributes.track = 1;
                this.currentSearchModel.set(currentTourCriteriaAttributes, {
                    resetLoader: true,
                    initiator: params?.initiator || null,
                });

                //to force search with same params
                if (forceChangeTrigger) {
                    this.currentSearchModel.trigger('change', this.currentSearchModel);
                }

                this.view.model.set('subscribe', '0');

                this.view.model.set('searchData', currentTourCriteria);
                this.model.trigger('submitSearch');
            }
        }
    },
    modifyTourCriteria(tourCriteria) {
        return tourCriteria;
    },
    analyticsCall(tourCriteria) {
        if (this.dataLayerAnalytics.sid !== searchSessionService.getSearchId()) {
            const value = {
                prop: [{
                    type: 'hotel_ids',
                    value: tourCriteria.get('hotels')?.pluck('id')?.join() || [],
                }, {
                    type: 'resorts',
                    value: tourCriteria.get('resorts')?.pluck('name')?.join() || [],
                }],
            };

            this.dataLayerAnalytics.generateEvent('checkHotelResort', value);
            this.dataLayerAnalytics.generateEvent('sid', searchSessionService.getSearchId());
            this.dataLayerAnalytics.initBasicEvents(tourCriteria);
        }
    },
    prepareSearchRequests(tourCriteria) {
        return [new SearchRequestFactory(tourCriteria)];
    },
    startSearchTracking(tourCriteria, type) {
        const timestamp = (new Date()).getTime();

        if (type === 'userSearch') {
            trackingService.trackStartSearch({
                tourCriteria,
                timestamp,
                productType: 'TourProduct',
            });
        } else {
            trackingService.trackStartEmptySearch({
                tourCriteria,
                timestamp,
                productType: 'TourProduct',
            });
        }

        this.mindboxTracking();
    },
    searchScrollHandler() {
        const newUrl = window.location.href;
        const isSuccessSearch = !!~newUrl?.indexOf('fromCity')
            || !!~newUrl?.indexOf('toCountry');

        if (isSuccessSearch) {
            document.removeEventListener('scroll', this.scrollHandlerWithContext);
            return;
        }

        window.appConfig.noToursFound = false;
        window.appConfig.hideUpdatePriceBanner = true;
        this.formSubmit();
    },
    initSearchOnScroll() {
        const currentUrl = window.location.href;
        const isDynamicSearch = !!~currentUrl?.indexOf('fromCity')
            || !!~currentUrl?.indexOf('toCountry');

        if (isDynamicSearch) return;
        this.scrollHandlerWithContext = () => this.searchScrollHandler();
        document.addEventListener('scroll', this.scrollHandlerWithContext);
    },
    destroy() {
        document.removeEventListener('scroll', this.scrollHandlerWithContext);
    },
    manualyChangeCountry(tourCriteria, blockCountries) {
        const countryModel = tourCriteria.get('countries').at(0);
        const alternativeCountry = getAlternativeCountry(blockCountries, countryModel);
        const newCountryModel = new Backbone.Collection([alternativeCountry]);

        this.view.model.get('lastBlockedTourCriteriaByCountry')?.push(tourCriteria.clone());
        tourCriteria.set('countries', newCountryModel);
        this.model.setTourCriteria(tourCriteria); // will start new goSearch by events
        this.model.trigger('startSearch', { initiator: 'manual' });
    },
    activatePopularityOffersFilters(tourCriteria, hotels) {
        const idTurkey = 92;
        const idUAE = 68;
        const idThailand = 87;
        const popularityHotelsCountries = [idTurkey, idUAE, idThailand];
        const takenCountryId = tourCriteria.get('countries').at(0).get('id');
        const isCurrentCountryHasPopularityHotels = popularityHotelsCountries.includes(takenCountryId);
        const filteredHotelsByPopularityOffers = filterByPopularityOffers(hotels.models);
        const isUserCameWithoutFilterCriteriaParams = !Object.entries(this.filterCriteriaFirst || {}).find(([key, value]) => {
            if ((Array.isArray(value) && value.length)) {
                return true;
            }
            return !Array.isArray(value) && !!value;
        });
        const {
            hotelCategories, resorts, meals, warranted, directFlight,
        } = this.searchCriteriaFirst?.tourCriteria;
        const isUserCameWithoutSearchFilterCriteriaParams = hotelCategories?.includes('all') && !resorts?.length && meals.includes('all') && !warranted && !directFlight;
        const isActivatePopularityOffersFilter = isCurrentCountryHasPopularityHotels
            && (abTests.hasTest('IN-3463v2') || takenCountryId === idTurkey)
            && isUserCameWithoutFilterCriteriaParams
            && isUserCameWithoutSearchFilterCriteriaParams;

        if (isActivatePopularityOffersFilter && filteredHotelsByPopularityOffers.length > 10) {
            this.view.model.get('filterCriteria').set('isPopularityFilterActive', true);
        }
        if (isActivatePopularityOffersFilter && filteredHotelsByPopularityOffers.length < 10) {
            this.serpTrackingService.popularOffersLessTen();
        }
    },

    async goSearch(tourCriteria, params) {
        this.countDownTimerForUpdatingMinutesAfterSearchStarted();
        let searchServiceParamsCollection;
        const self = this;
        self.view.model.set('covidBanner', false);
        self.view.model.off('change:sortStrategy change:viewMode', this.startSearchIfControlChanged);
        self.view.model.get('filterCriteria').off('change', this.startSearchIfControlChanged);

        try {
            const blockCountries = await getBlockCountries();
            const lastTourCriteria = this.view.model.get('lastBlockedTourCriteriaByCountry')?.slice(0).pop();
            const isLastCountryWasBlocked = lastTourCriteria && checkOnBlock(lastTourCriteria, blockCountries);
            const isCurrentCountryIsBlocked = checkOnBlock(tourCriteria, blockCountries);

            if (isLastCountryWasBlocked) {
                this.view.model.set('countryIsClose', true);
            } else {
                this.view.model.set('countryIsClose', false);
            }

            if (isCurrentCountryIsBlocked) {
                this.manualyChangeCountry(tourCriteria, blockCountries);

                return;
            }

            if (params?.initiator === 'manual') {
                this.view.model.get('lastBlockedTourCriteriaByCountry')?.pop();
            }
        } catch (error) {
            TrackJS.track(error);
        }

        if (tourCriteria.isValid() && tourCriteria.get('countries') && tourCriteria.get('countries').length) {  // Stryker disable next-line
            ADVCake.updateSearchData(tourCriteria);

            this.progressBar.reset();
            this.progressBar.moveToValue(50);

            tourCriteria = this.modifyTourCriteria(tourCriteria);
            self.view.model.trigger('change:syncHotels');

            searchServiceParamsCollection = this.prepareSearchRequests(tourCriteria);

            self.view.model.set('loadingState', 'loading');
            self.view.model.set('loadingInProgress', true);

            const finished = {
                sync: false,
                async: false,
            };

            clearInterval(this.progressBarTimer);
            this.progressBarTimer = setInterval(() => {
                if (finished.sync) {
                    const asyncStatus = self.searchService.getAsyncStatus();
                    const { jobsCount } = asyncStatus;
                    const jobsSuccess = asyncStatus.jobsCount;
                    let jobPercents = 50;
                    if (jobsCount && jobsSuccess !== jobsCount) {
                        jobPercents += (50 / jobsCount) * (jobsSuccess + 1);
                    } else {
                        jobPercents = 100;
                    }
                    if (jobPercents === 100) {
                        clearInterval(self.progressBarTimer);
                    }
                    self.progressBar.moveToValue(jobPercents);
                }
            }, 1000);

            this.startSearchTracking(tourCriteria, this.isUserSearch() ? 'userSearch' : 'emptySearch');
            self.searchService.getOffersData({
                searchUuid: trackingService.getSearchUuid(),
                clientUuid: trackingService.getClientUuid(),
                customerUuid: trackingService.getCustomerUuid(),
                originalSearchUuid: trackingService.originalSearchUuid,
                requests: searchServiceParamsCollection,
                callback: {
                    sync: (hotels, tourProducts) => {
                        this.initHotelOptions(hotels.models);
                        if (hotels.length > 0) {
                            if (!finished.async) {
                                self.view.model.set('loadingState', 'asyncLoading');
                            }
                            this.activatePopularityOffersFilters(tourCriteria, hotels);
                            self.view.model.set('asyncTourProducts', null);
                            self.view.model.set('asyncHotels', null);
                            self.view.collection.reset();
                            self.view.model.set('pageNumber', 1, { silent: true });
                            self.view.model.set('syncHotels', hotels);
                            self.view.model.set('loadingInProgress', false);
                            self.view.model.trigger('change:syncHotels');
                            self.view.model.updateRenderedCollection();
                        } else {
                            self.view.model.set('syncHotels', hotels, { silent: true });
                        }
                        self.view.model.set('syncTourProducts', tourProducts, { silent: true });
                        self.view.model.trigger('change:syncTourProducts', self.view.model, tourProducts);

                        self.view.model.set('firstHotelsPrices', hotels.reduce((memo, hotel) => (memo[hotel.get('id')] = hotel.get('minPrice')) && memo, {}));
                        finished.sync = true;
                        if (finished.async) {
                            if (self.view.model.get('loadingInProgress')) {
                                self.view.collection.reset();
                                self.view.model.set('pageNumber', 1, { silent: true });
                                if (hotels.length === 0) {
                                    self.view.model.trigger('change:syncHotels');
                                }
                            }
                            self.view.model.updateRenderedCollection();
                            self.view.model.set('loadingInProgress', false);
                            clearInterval(self.progressBarTimer);
                            self.progressBar.complete();
                            self.view.model.set('loadingState', 'finished');
                        }
                        console.log('changed', 'syncHotels', 'syncTourProducts');
                    },
                    middleRequest: (updatedHotels, newHotelsLength) => {
                        const tourProducts = self.view.model.get('syncTourProducts');
                        self.view.model.trigger('change:syncTourProducts', self.view.model, tourProducts);
                        this.initHotelOptions(updatedHotels);
                        if (newHotelsLength) {
                            self.view.model.trigger('change:syncHotels');
                        }

                        if (updatedHotels.length) {
                            self.view.model.set('loadingInProgress', false);
                            self.view.model.updateRenderedCollection();
                            if (finished.async) {
                                clearInterval(self.progressBarTimer);
                                self.progressBar.complete();
                                self.view.model.set('loadingState', 'finished');
                            } else {
                                self.view.model.set('loadingState', 'asyncLoading');
                            }
                        }
                    },
                    async: (updatedHotels, newHotelsLength, options) => {
                        const hotels = self.view.model.get('syncHotels');
                        const firstHotelsPrices = self.view.model.get('firstHotelsPrices');
                        const tourProducts = self.view.model.get('syncTourProducts');
                        self.view.model.trigger('change:syncTourProducts', self.view.model, tourProducts);
                        const operatorsTime = self.searchService.getAsyncStatus().operators;

                        this.initHotelOptions(updatedHotels);

                        if (newHotelsLength) {
                            self.view.model.trigger('change:syncHotels');
                        }

                        if (updatedHotels.length) {
                            self.view.model.updateRenderedCollection();
                        }

                        if (this.isUserSearch()) {
                            trackingService.trackSearch({
                                tourCriteria,
                                foundHotels: hotels || {},
                                firstHotelsPrices,
                                foundTourProducts: tourProducts || null,
                                timestamp: options.startSearchTime,
                                firstCacheTime: options.firstCacheTime,
                                searchFinishedTime: options.searchFinishedTime,
                                scannedTours: options.scannedTours,
                                error: options.error,
                                operatorsTime,
                            });
                        }

                        if (self.dataLayerAnalytics && self.dataLayerAnalytics.searchCountEvent) {
                            self.dataLayerAnalytics.searchCountEvent((hotels && hotels.length) ? hotels.length : 0);
                            self.dataLayerAnalytics.generateTop20Event(hotels?.length ? self.view.model.get('filteredHotels') : []);
                        }

                        if (hotels && hotels.length === 0) {
                            gaAsync(() => {
                                ga('send', 'event', 'SearchResults', 'No_Results', document.location.pathname + document.location.hash, { nonInteraction: 1 });
                            });
                        } else {
                            sociomanticManager.notifyCategory(tourCriteria);
                            if (hotels) {
                                gaAsync(() => {
                                    ga('send', 'event', 'SearchResults', hotels.length, document.location.pathname + document.location.hash, { nonInteraction: 1 });
                                });
                            }
                        }
                        finished.async = true;

                        if (finished.sync) {
                            if (self.view.model.get('loadingInProgress')) {
                                self.view.collection.reset();
                                self.view.model.set('pageNumber', 1, { silent: true });
                                if (updatedHotels.length === 0) {
                                    self.view.model.trigger('change:syncHotels');
                                }
                            }
                            self.view.model.updateRenderedCollection();
                            self.view.model.set('loadingInProgress', false);
                            clearInterval(self.progressBarTimer);
                            self.progressBar.complete();
                            self.view.model.set('loadingState', 'finished');
                        }
                        console.log('changed', 'asyncHotels', 'asyncTourProducts', 'loadingState');
                    },

                },
            });
            this.weatherUpdate();

            this.analyticsCall(tourCriteria);
        }
    },
    isUserSearch() {
        const store = getStore();
        return ['userSearch', 'userSearchHasTours'].includes(store.state[EMPTY_SEARCH_STORE].currentState);
    },
    initHotelOptions(hotels) {
        _.each(hotels, (hotel) => {
            hotel.initHotelOptions();
        });
    },
    initSearchService() {
        this.searchService = new Search();
    },
    initFirstUrlParams() {
        this.filterCriteriaFirst = this.view.getFilterCriteriaByUrl($.unparam(window.location.hash.slice(2)));
        this.searchCriteriaFirst = this.fromRouterHydrator(window.location.hash.slice(2));
    },
    initialize() {
        this.initializeMetrics();
        this.initSearchPageHotelCardRightBlockTimer();
        this.initDataAnalyticsService();
        this.initAnalytics();
        this.initSearchService();
        this.initModels();
        this.initComponents();
        this.initEvents();
        this.initFirstUrlParams();
    },
});

export default SearchController;