/* eslint-disable camelcase */
import moment from 'moment';
import _ from 'underscore';
import 'moment/locale/ru';
import { IgetAbTestValue } from '@/js/common/Domain/Service/getABTestValue';

interface ItrackingTourCriteria {
    tourCriteria: Backbone.Model
    productType?: string
}

export interface ItrackingTourCriteriaReturn extends Record<string, IgetAbTestValue[] | string | number | null | undefined> {
    fromCity: number
    dateFrom: string | number
    dateTo: string | number
    nightFrom: number
    nightTo: number
    hotelCategory_all: number
    hotelCategory_5: number
    hotelCategory_4: number
    hotelCategory_3: number
    hotelCategory_2: number
    hotelCategory_1: number
    hotelCategory_0: number
    hotelCategory_restBase: number
    hotelCategory_pension: number
    hotelCategory_sanatorium: number
    hotelCategory_apartments: number
    hotelCategory_hostel: number
    meal_all: number
    meal_uai: number
    meal_ai: number
    meal_ai_no_alc: number
    meal_bb: number
    meal_lhb: number
    meal_fb: number
    meal_hb: number
    meal_ro: number
    adults: number
    kids: number
    infants: number
    operator: string | number
    directFlight: number
}

const trackingTourCriteriaHydrator = (options: ItrackingTourCriteria): ItrackingTourCriteriaReturn => {
    const trackingTourCriteriaData: Record<string, string | number> = {};
    const { tourCriteria, productType } = options;
    const fromCity = tourCriteria?.get('departureCity')?.get('id');
    const directFlight = tourCriteria?.get('directFlight');
    let countries = tourCriteria?.get('countries');
    let resorts = tourCriteria?.get('resorts');
    let hotels = tourCriteria?.get('hotels');
    const checkInDateRange = tourCriteria ? tourCriteria.get('checkInDateRange') : 0;
    const nightRange = tourCriteria ? tourCriteria.get('nightRange') : 0;
    const touristGroup = tourCriteria ? tourCriteria.get('touristGroup') : 0;
    const hotelCategories = tourCriteria ? tourCriteria.get('hotelCategories') : 0;
    const meals = tourCriteria ? tourCriteria.get('meals') : 0;
    const operator = tourCriteria && tourCriteria.get('operators') && tourCriteria.get('operators').length ? tourCriteria.get('operators').at(0).get('id') : 0;

    countries = countries && countries.length ? countries.pluck('id') : [];
    countries.length = 3;
    _.each(countries, (country, index) => {
        trackingTourCriteriaData[`toCountry${index}`] = country || 0;
    });

    resorts = resorts && resorts.length ? resorts.pluck('id') : [];
    resorts.length = 5;
    _.each(resorts, (resort, index) => {
        trackingTourCriteriaData[`toCity${index}`] = resort || 0;
    });

    hotels = hotels && hotels.length ? hotels.pluck('id') : [];
    hotels.length = 5;
    _.each(hotels, (hotel, index) => {
        trackingTourCriteriaData[`hotel${index}`] = hotel || 0;
    });

    const getCategories = () => {
        if (productType === 'HotelOfferProduct') {
           return {
                hotelCategory_2: hotelCategories?.length && hotelCategories.get(2) ? 1 : 0,
                hotelCategory_1: hotelCategories?.length && hotelCategories.get(1) ? 1 : 0,
           };
        }

        return {
            hotelCategory_2: hotelCategories?.length && hotelCategories.get(2) ? 1 : 0,
            hotelCategory_1: hotelCategories?.length && hotelCategories.get(2) ? 1 : 0,
        };
    };

    _.extend(trackingTourCriteriaData, {
        fromCity,
        dateFrom: checkInDateRange ? moment(checkInDateRange.get('from')).format('YYYY-MM-DD') : 0,
        dateTo: checkInDateRange ? moment(checkInDateRange.get('to')).format('YYYY-MM-DD') : 0,
        nightFrom: nightRange ? nightRange.get('from') : 0,
        nightTo: nightRange ? nightRange.get('to') : 0,
        hotelCategory_all: !(hotelCategories && hotelCategories.length) ? 1 : 0,
        hotelCategory_5: hotelCategories?.length && hotelCategories.get(7) ? 1 : 0,
        hotelCategory_4: hotelCategories?.length && hotelCategories.get(4) ? 1 : 0,
        hotelCategory_3: hotelCategories?.length && hotelCategories.get(3) ? 1 : 0,
        ...getCategories(),
        hotelCategory_0: hotelCategories?.length && hotelCategories.get(0) ? 1 : 0,

        hotelCategory_restBase: hotelCategories && hotelCategories.length && hotelCategories.get(19) ? 1 : 0,
        hotelCategory_pension: hotelCategories && hotelCategories.length && hotelCategories.get(17) ? 1 : 0,
        hotelCategory_sanatorium: hotelCategories && hotelCategories.length && hotelCategories.get(16) ? 1 : 0,
        hotelCategory_apartments: hotelCategories && hotelCategories.length && hotelCategories.get(5) ? 1 : 0,
        hotelCategory_hostel: hotelCategories && hotelCategories.length && hotelCategories.get(10) ? 1 : 0,
        meal_all: !(meals && meals.length) ? 1 : 0,
        meal_uai: meals && meals.length && meals.get(8) ? 1 : 0,
        meal_ai: meals && meals.length && meals.get(1) ? 1 : 0,
        meal_ai_no_alc: meals && meals.length && meals.get(11) ? 1 : 0,
        meal_bb: meals && meals.length && meals.get(2) ? 1 : 0,
        meal_lhb: meals && meals.length && meals.get(10) ? 1 : 0,
        meal_fb: meals && meals.length && meals.get(3) ? 1 : 0,
        meal_hb: meals && meals.length && meals.get(5) ? 1 : 0,
        meal_ro: meals && meals.length && meals.get(7) ? 1 : 0,
        adults: touristGroup ? touristGroup.get('adults') : 0,
        kids: touristGroup ? touristGroup.get('kids') : 0,
        infants: touristGroup ? touristGroup.get('infants') : 0,
        operator: operator || 0,
        directFlight: directFlight ? 1 : 0,
    });

    return trackingTourCriteriaData as ItrackingTourCriteriaReturn;
};

export default trackingTourCriteriaHydrator;