import AbstractFilterMethods from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { IHotel } from '@entities/hotel';
import restTypeFilters from '../config/restTypeFilters';
import { RestTypeFilterItem } from '../types/RestTypeFilterItem';

export default class RestTypeFilterMethods extends AbstractFilterMethods<RestTypeFilterItem, IHotel> {
    getCountAttribute(entities: IHotel[]): Record<RestTypeFilterItem['key'], number> {
       return entities.reduce((acc, hotel) => {
        this.getListFilterAttributes().forEach((restTypeAttribute) => {
            const hotelHasRestType = hotel.restTypes.includes(restTypeAttribute.id);
            if (hotelHasRestType) {
                acc[restTypeAttribute.key]++;
            }
        });
        return acc;
       }, this.getAttributeObjectCount());
    }

    getIsOneHotelHasProperty(entities: IHotel[]): boolean {
        return entities
        .some((hotel) => this.getListFilterAttributes()
        .some((restTypeAttribute) => hotel.restTypes.includes(restTypeAttribute.id)));
    }

    isSomeHotelHasRestType(hotels: IHotel[]): boolean {
        return this.getIsOneHotelHasProperty(hotels);
    }
}
const restTypeFilterMethods = new RestTypeFilterMethods(restTypeFilters).setFilterName('Тип отдыха');

export {
    restTypeFilterMethods,
};
