/* eslint-disable */
import './popoverStack.css';

var popoverStack,
	popoverTimeoutDefault = 15000;

var PopoverItemModel = Backbone.Model.extend({
	defaults:{
		view:null,
		popoverClass:null,
		popoverTimeout:null
	}
});
var PopoverItemCollection = Backbone.Collection.extend({
	model: PopoverItemModel
});

var PopoverItemView = Backbone.Epoxy.View.extend({
	el:'<li></li>',
	template:_.template('<div class="popoverStackItem <%=popoverClass %>">\n\t<i class="icon icon-i16_x popoverStackItem__closeButton"></i>\n\t<div class="popoverStackItem__html">\n\t</div>\n</div>'),
	events:{
		'click .popoverStackItem__closeButton' : 'closePopover',
		'click .popoverStackItem_close' : 'closePopover'
	},
	startCountDown:function() {
		var self = this;
		setTimeout(function() {
			self.closePopover();
		}, this.model.get('popoverTimeout'));
	},
	closePopover:function() {
		var self = this;
		self.$el.addClass('popoverStackItem_outerAnimate');
		setTimeout(function() {
			self.$el.hide();
		}, 800)
	},
	render:function() {
		var model = this.model.toJSON(),
			$popover = $(this.template(model)),
			self = this;

		$('.popoverStackItem__html', $popover).html(model.view.$el);

		//timeouts to prevent animation bugs
		setTimeout(function() {
			self.$el.append($popover);
			self.$el.css({
				marginBottom:-self.$el.height() - 15
			});
			setTimeout(function() {
				self.$el.addClass('popoverStackItem_animated');
				self.$el.css({
					marginBottom:0
				});

			},10);
		},1);
	},
	initialize:function(options) {
		this.collectionView = options.collectionView;

		this.render();
		this.startCountDown();
		this.model.get('view').closePopoverExported = $.proxy(this.closePopover, this);
	}
});

var PopoverStack = Backbone.Epoxy.View.extend({
	el:'<ul class="popoverStack" data-bind="collection:$collection"></ul>',
	itemView:PopoverItemView,
	collection:new PopoverItemCollection(),
	push:function(options) {
		var collectionItem = this.collection.add({
			view:options.view,
			popoverClass:options.popoverClass,
			popoverTimeout:options.popoverTimeout || popoverTimeoutDefault
		});
	},
	renderStack:function() {
		$('body').append(this.$el);
	},
	initialize:function(options) {
		this.renderStack();
	}
});

let popoverStackView = new PopoverStack();

export default popoverStackView;