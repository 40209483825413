/* eslint-disable */
import HotelCategoryEntity from '../Entity/HotelCategory';

const HotelCategoriesRepository = Backbone.Collection.extend({
    model: HotelCategoryEntity,
    getById(ids) {
        let hotelCategories = [];

        if (_.isArray(ids)) {
            _.each(ids, (id) => {
                const hotelCategory = this.get(id);
                if (hotelCategory) {
                    hotelCategories.push(hotelCategory);
                }
            });
        } else {
            hotelCategories = this.get(ids);
        }
        return hotelCategories;
    },
});

const repository = new HotelCategoriesRepository([
    {id: 0, name: '', stars: 0},
    {id: 1, name: '1*', stars: 1},
    {id: 2, name: '2*', stars: 2},
    {id: 3, name: '3*', stars: 3},
    {id: 4, name: '4*', stars: 4},
    {id: 5, name: 'Апартаменты', stars: 0},
    {id: 6, name: 'Виллы', stars: 0},
    {id: 7, name: '5*', stars: 5},
    {id: 8, name: '5* (HV-1)', stars: 5},
    {id: 9, name: '4* (HV-2)', stars: 4},
    {id: 10, name: 'Хостел', stars: 0},
    {id: 11, name: 'Вилла', stars: 0},
    {id: 12, name: 'Клубный отель', stars: 0},
    {id: 13, name: 'Апарт-отель', stars: 0},
    {id: 14, name: 'Гостиничный компл.', stars: 0},
    {id: 15, name: 'Курортный город', stars: 0},
    {id: 16, name: 'Санаторий', stars: 0},
    {id: 17, name: 'Пансионат', stars: 0},
    {id: 18, name: 'Дом отдыха', stars: 0},
    {id: 19, name: 'База отдыха', stars: 0},
    {id: 20, name: 'Гостевой дом', stars: 0},
    {id: 21, name: 'Гостиница', stars: 0},
    {id: 22, name: 'Бутик-отель', stars: 0},
    {id: 23, name: 'Экскурсионная программа', stars: 0},
]);

HotelCategoriesRepository.repository = repository;

export default HotelCategoriesRepository;