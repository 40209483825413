export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('resorts');

    if (element) {
        if (element.length) {
            tourCriteriaJson.resorts = element.pluck('id');
        } else {
            tourCriteriaJson.resorts = [];
        }
    }

    return tourCriteriaJson;
};