import { IOperator } from '@entities/operator';

export interface IFilterOperatorState {
    operatorsFilter: IOperator['id'][];
}

export interface IFilterOperatorMutations {
    setOperatorFilter: (state: IFilterOperatorState, value: number[]) => void;
}

const DefaultState = (): IFilterOperatorState => ({
    operatorsFilter: [],
});

export default {
    namespaced: true,
    state: DefaultState(),
    mutations: {
        setOperatorFilter(state: IFilterOperatorState, value: IOperator['id'][]) {
            state.operatorsFilter = value;
        },
    },
};

export const NAMESPACE = 'operatorFilterModelName';
