type benchMarkState = 'inProgress' | 'done' | null

interface Ibenchmark {
    state: benchMarkState
    time: Date,
}

export default class SystemInfoBenchmark {
    private benchMarksInstances: Record<string, Ibenchmark | null>

    private benchMarksTimes: Record<string, number | null>

    constructor() {
        this.benchMarksInstances = {};
        this.benchMarksTimes = {};
    }

    startBench(benchName: string): void {
        if (this.benchMarksInstances[benchName]?.state === 'inProgress') {
            throw new Error(`${benchName} is already started`);
        }

        const start = new Date();
        this.benchMarksInstances[benchName] = { state: 'inProgress', time: start };
    }

    stopBenchmarking(benchName: string): void {
        const end = new Date();
        const benchMarkInstance = this.benchMarksInstances[benchName];

        if (!benchMarkInstance) {
            throw new Error(`${benchName} is not started`);
        }

        if (benchMarkInstance.state === 'done') {
            throw new Error(`${benchName} alredy ended`);
        }

        const time = end.getTime() - benchMarkInstance.time.getTime();

        this.benchMarksTimes[benchName] = time;
        this.benchMarksInstances[benchName] = { state: 'done', time: end };
    }

    getResult(benchName: string): number | null {
        if (this.benchMarksInstances[benchName]?.state === 'inProgress') {
            throw new Error(`${benchName} is not ending`);
        }

        const time = this.benchMarksTimes[benchName];

        if (!time && time !== 0) {
            console.warn(`${benchName} is undefined`);
            return null;
        }

        return time;
    }

    getStatus(benchName: string): benchMarkState {
        const benchMarkInstance = this.benchMarksInstances[benchName];

        if (!benchMarkInstance) {
            return null;
        }

        return benchMarkInstance.state;
    }
}