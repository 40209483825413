export default (params) => {
    const tourCriteria = {};
    const kids = 0;
    const infants = 0;

    let element = params.adults;
    if (element) {
        tourCriteria.touristGroup = {
            adults: element,
            kids,
            infants,
        };

        if (params.kids || params.infants) {
            element = params.kids;
            if (element) {
                tourCriteria.touristGroup.kids = element;
            }

            element = params.infants;
            if (element) {
                tourCriteria.touristGroup.infants = element;
            }
            if (params.ages) {
                tourCriteria.touristGroup.ages = _.values(params.ages);
            }
        }
    }

    return tourCriteria;
};