import '@/js/desktop/components/topMenuBar';
import '@/js/desktop/components/account';
import '@/js/desktop/components/intercomChat/intercomChat';
import '@/js/desktop/components/lotteryButton/lotteryButton';
import '@/js/common/components/appsflyer/initLinks';
import '@/js/desktop/components/banners';
import gaAsync from '@/js/common/Domain/Service/gaAsync';
import '@/js/desktop/components/generalLead/generalLeadLoader';
import '@/js/desktop/components/droppedOrderBanner';
import '@/js/common/Service/mindBoxService/MindBoxServiceDesktop';
import '@ve/components/skolkovo/desktop/acitvateSkolkovo';

gaAsync(() => {});
