import FilterAttributeMethodsAbstract from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import { getTypeConfirmationByPriority, TourProduct } from '@entities/tourProduct';
import { IFilterAttributeConfirmation } from '../types/IConfirmationFilter';

class FilterAttributesMethodsConfirmation extends FilterAttributeMethodsAbstract<IFilterAttributeConfirmation, TourProduct[]> {
    getCountAttribute(groupedTourProducts: TourProduct[][]): Record<IFilterAttributeConfirmation['key'], number> {
        return groupedTourProducts.reduce((acc, tourProducts) => {
            const typeConfirmation = getTypeConfirmationByPriority(tourProducts);
            if (typeConfirmation) acc[typeConfirmation]++;
            return acc;
        }, this.getAttributeObjectCount() as Record<IFilterAttributeConfirmation['key'], number>);
    }

    getIsOneHotelHasProperty(groupedTourProducts: TourProduct[][]): boolean {
        return groupedTourProducts.some((tourProducts) => {
            const typeConfirmation = getTypeConfirmationByPriority(tourProducts);
            return typeConfirmation === 'quick' || typeConfirmation === 'long';
        });
    }
}
export default FilterAttributesMethodsConfirmation;