/* eslint-disable */
import AjaxRequestManager from '../AjaxRequestManager';
import CountriesRepository from '../Repository/Countries'

let ajaxRequestManager = new AjaxRequestManager();

const DepartureCityEntity = Backbone.Model.extend({
	defaults: {
		id: null,
		name: null,
		type:null,
		daysRange:null,
		countries: null,
		countriesWithDirectFlights: [],
	},
	getCountries: function(callback, params) {
		var countriesRepository = params && params.countriesRepository ? params.countriesRepository : false,
			countries = countriesRepository ? countriesRepository : CountriesRepository.repository;

		if(countries !== null && countries.length) {
			callback && callback.success ? callback.success(countries) : '';
			return countries;
		}
	}
});

export default DepartureCityEntity;
