import { useState, useMutations } from 'vuex-composition-helpers';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import OperatorRepository, {
    NAMESPACE,
    IOperatorMutations,
    IOperatorState,
} from '../model/OperatorRepository';

export default function () {
    registerModule.register(getStore(), OperatorRepository, NAMESPACE);
    const { operators } = useState<IOperatorState>(NAMESPACE, ['operators']);
    const { resetOperators, setOperators } = useMutations<IOperatorMutations>(NAMESPACE, ['setOperators', 'resetOperators']);

    return {
        resetOperators,
        setOperators,
        operators,
    };
}