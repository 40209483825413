import addDays from 'date-fns/addDays';

export default function createDateByFormat(date: string | number | undefined | null | Date): null | Date {
    if (!date) return null;

    if (date instanceof Date) return date;

    const days = +date;
    const isDateNumber = days && typeof days === 'number';
    if (isDateNumber) {
        const currentDate = new Date();
        return addDays(currentDate, days);
    }

    if (typeof date === 'string') {
        const dateStroke = date;
        const minimalDateLength = 10;
        const cuttingStroke = dateStroke.substr(0, minimalDateLength);

        const isStrokeHasFormatDot = cuttingStroke.includes('.') && cuttingStroke.length === 10;
        const isStrokeHasFormatLine = cuttingStroke.includes('-') && cuttingStroke.length === 10;

        if (isStrokeHasFormatDot) {
            const datePartsByFormatDot = cuttingStroke.split('.');
            return new Date(+datePartsByFormatDot[2], +datePartsByFormatDot[1] - 1, +datePartsByFormatDot[0]);
        }
        if (isStrokeHasFormatLine) {
            const datePartsByFormatLine = cuttingStroke.split('-');
            return new Date(+datePartsByFormatLine[0], +datePartsByFormatLine[1] - 1, +datePartsByFormatLine[2]);
        }
    }
    return null;
}