export default (tourCriteria) => {
    const params = {};
    const element = tourCriteria.get('priceRange');
    if (element) {
        const from = element.get('from');
        const to = element.get('to');
        if (from) {
            params.priceFrom = from;
        }
        if (to) {
            params.priceTo = to;
        }
    }

    return params;
};