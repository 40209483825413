import { YANDEX_METRIKA_ID } from '@/js/common/Constants/globalParams';

export default function trackingCountryConditions(nameBanner) {
    const dataParams = { widgetinteraction: nameBanner };
    if (window.ym) {
        window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'widget_interaction', dataParams || {});
    }
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'widgetinteraction',
            widgetName: nameBanner,
        });
    }
}