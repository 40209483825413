import { iconConfirmationData } from '@fr/fsd/entities/tourProduct';
import { IFilterAttributeConfirmation } from '../types/IConfirmationFilter';

const filterAttributesData: IFilterAttributeConfirmation[] = [
    {
        id: 1,
        key: 'quick',
        name: iconConfirmationData.quick.label,
        keyMerged: 'quick',
        tooltip: {
            label: iconConfirmationData.quick.label,
            description: iconConfirmationData.quick.description,
        },
    },
    {
        id: 2,
        key: 'long',
        name: iconConfirmationData.long.label,
        keyMerged: 'long',
        tooltip: {
            label: iconConfirmationData.long.label,
            description: iconConfirmationData.long.description,
        },
    },
];

export default filterAttributesData;