/* eslint-disable */
export default function(foundHotels) {
	var hotelsWithoutOil = [];
	if (foundHotels?.models?.length) {
		foundHotels.models.forEach(function (model) {
			var tourProducts = model.get('tourProducts'),
				firstTourProduct;
			if (tourProducts && tourProducts.models && tourProducts.models.length) {
				firstTourProduct = tourProducts.models[0].get("tour");
				if (firstTourProduct.get("oilTaxIsAbsent") === true) {
					hotelsWithoutOil.push(model.get('id'))
				}
			}
		})
	}
	return {
		hotelsWithoutOil: foundHotels ? hotelsWithoutOil.length : undefined
	}
};