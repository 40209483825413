import { TConfirmation } from '../types/IConfirmation';

const iconConfirmationData: Record<TConfirmation, { label: string, description: string }> = {
    cancel: {
        label: 'Высокая вероятность отмены',
        description: 'Есть высокая вероятность, что туроператор не подтвердит данный тур. В случае если тур не будет подтвержден в течение 72 часов, мы оперативно предложим альтернативы или сделаем возврат денежных средств.',
    },
    quick: {
        label: 'Туры в наличии',
        description: 'По информации от туроператора есть гарантированные места в отеле и на рейсе. Такие туры раскупают быстрее всего. Подтверждаются почти сразу без изменения цены после оплаты.',
    },
    long: {
        label: 'Туры под запрос',
        description: 'Доступность наличия конкретного номера будет уточняться у туроператора. В случае если тур не будет подтвержден в течение 72 часов, мы оперативно предложим альтернативы или сделаем возврат денежных средств.',
    },
};

export default iconConfirmationData;