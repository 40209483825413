import { DistanceFilterId } from '@ve/components/filters/distanceLiftFilter/types';

export default (numbers: number[]): DistanceFilterId | 0 => {
    if (numbers.indexOf(215) !== -1 || numbers.indexOf(214) !== -1 || numbers.indexOf(238) !== -1) { //Автобус — от 500 м
        return 3;
    }
    if (numbers.indexOf(225) !== -1) { // Ski in — прямо у входа
        return 1;
    }
    if (numbers.indexOf(213) !== -1) { //Пешая доступность — до 500 м
        return 2;
    }
    return 0;
};
