export default (params) => {
    const tourCriteria = {};
    if (params.priceFrom && params.priceTo) {
        tourCriteria.priceRange = {
            from: params.priceFrom,
            to: params.priceTo,
        };
    }

    return tourCriteria;
};