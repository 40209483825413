import getStore from '@ve/services/getStore/getStore';
import DroppedOrderBannerWrapper from '@ve/components/droppedOrderBanner/desktop/DroppedOrderBannerWrapper.vue';
import DroppedOrderBannerCookieTracker from './DroppedOrderBannerCookieTracker';

export default class DroppedOrderBanner extends Backbone.Epoxy.View {
    initialize() {
        this.store = this.initVueStore();
        this.initDroppedOrderBannerCookieTracker();
        this.refreshShowParam();
        this.toggleTopBanner();
        this.fixTopBannerStyles();

        this.initVuexSubscribe();
        this.initVueComponents();
    }

    initDroppedOrderBannerCookieTracker() {
        new DroppedOrderBannerCookieTracker({
            onUpdate: (newData) => {
                this.store.commit('mapForDroppedOrderBannerWrapper/setDataDroppedOrderBanner', newData);
            },
        });
    }

    initVueStore() {
        return getStore({
            name: 'mapForDroppedOrderBannerWrapper',
            storeOptions: {
                state: {
                    showDroppedOrderBanner: false,
                    dataDroppedOrderBanner: null,
                },
                mutations: {
                    setShowDroppedOrderBanner: (state, payload) => {
                        state.showDroppedOrderBanner = payload;
                    },
                    setDataDroppedOrderBanner: (state, payload) => {
                        state.dataDroppedOrderBanner = payload;
                    },
                },
            },
        });
    }

    initVuexSubscribe() {
        this.store.subscribe((mutation, state) => {
            const mType = mutation.type;
            if (mType === 'mapForDroppedOrderBannerWrapper/setDataDroppedOrderBanner') {
                this.refreshShowParam();
            } else if (mType === 'mapForDroppedOrderBannerWrapper/setShowDroppedOrderBanner') {
                this.toggleTopBanner();
                this.fixTopBannerStyles();
            }
        });
    }

    refreshShowParam() {
        const data = this.store.state.mapForDroppedOrderBannerWrapper.dataDroppedOrderBanner;
        const show = data && window.appConfig.pageType !== 'tourPage';
        this.store.commit('mapForDroppedOrderBannerWrapper/setShowDroppedOrderBanner', show);
    }

    toggleTopBanner() {
        const showTopBanner = !this.store.state.mapForDroppedOrderBannerWrapper.showDroppedOrderBanner;
        const el = $('.top-banner-js');
        if (showTopBanner) {
            el.css('display', 'flex');
        } else {
            el.hide();
        }
    }

    fixTopBannerStyles() {
        const showTopBanner = !this.store.state.mapForDroppedOrderBannerWrapper.showDroppedOrderBanner;
        const el = $('body');
        if (showTopBanner) {
            el.removeClass('hasDroppedOrderBanner');
        } else {
            el.addClass('hasDroppedOrderBanner');
        }
    }

    initVueComponents() {
        this.droppedOrderBannerWrapper = new DroppedOrderBannerWrapper({
            store: this.store,
        }).$mount();
        $('header .header .menuContainer').prepend(this.droppedOrderBannerWrapper.$el);
    }
}