

    import {
        computed,
        defineComponent,
        PropType,
        toRefs,
        unref,
    } from 'vue';
    import { TSizesInputCommon } from './common';
    import CheckBoxInputCommon from './common/CheckBoxInputCommon.vue';

    export default defineComponent({
        name: 'check-box-input',
        model: {
            prop: 'modelValue',
            event: 'change',
        },
        components: {
            CheckBoxInputCommon,
        },
        props: {
            modelValue: {
                type: Boolean,
                required: true,
            },
            label: {
                type: String,
                default: '',
            },
            hideOverflowText: {
                type: Boolean,
                default: true,
            },
            value: {
                type: Boolean,
                default: false,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isWhiteSpace: {
                type: Boolean,
                default: true,
            },
            checkBoxSize: {
                type: String as PropType<TSizesInputCommon>,
                default: 'small',
            },
        },
        setup(props, { emit }) {
            const { modelValue } = toRefs(props);
            const updateInput = ({ target }: { target: HTMLInputElement}) => {
                emit('change', target.checked);
            };
            const isChecked = computed(() => unref(modelValue));

            return {
                updateInput,
                isChecked,
            };
        },
    });

