/* eslint-disable */
import NightRangeObjectValue from '../ObjectValue/NightRange';

const NightRangeFactory = function(data) {
	var nightRange = {};
	
	if(data['from'] && !_.isNaN(parseInt(data['from'],10) &&
		(!data['to'] || !_.isNaN(parseInt(data['to'],10))))) {
	
		data = {
			from:parseInt(data['from'], 10),
			to:data['to'] ? parseInt(data['to'], 10) : parseInt(data['from'], 10)
		};

		if(data['from'] < 1) {
			data['from'] = 1;
		}

		if(data['to'] < 1) {
			data['to'] = 1;
		}

		if(data['from'] > 29) {
			data['from'] = 29;
		}

		if(data['to'] > 29) {
			data['to'] = 29;
		}

		if(parseInt(data['from'], 10) > parseInt(data['to'], 10)) {
			nightRange = {
				from: data['to'],
				to: data['from']
			};
		} else {
			nightRange = {
				from: data['from'],
				to: data['to']
			};
		}
		return new NightRangeObjectValue(nightRange);
	} else {
		return null;
	}
		
};
export default NightRangeFactory;