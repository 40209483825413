import { BaseState } from '@ve/services/getStore/types';

export default (state: BaseState) => {
    const {
        checkInDateRange,
    } = state;

    if (!checkInDateRange) return false;

    return checkInDateRange.from.getTime() !== checkInDateRange.to.getTime();
};