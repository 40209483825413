
    import {
        defineComponent, PropType, toRefs, unref, computed, SetupContext,
    } from 'vue';
    import CheckBoxInputListWithOneSelect from '@ve/components/inputs/CheckBoxInputListWithOneSelect.vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import TextCommon from '@ve/components/inputs/TextCommon.vue';

    export default defineComponent({
        name: 'resort-filter-item',
        components: {
            FilterListItem,
            CheckBoxInputListWithOneSelect,
            TextCommon,
        },
        model: {
            prop: 'model',
            event: 'change',
        },
        props: {
            idResort: {
                type: [String, Number],
                required: true,
            },
            model: Array as PropType<string[] | number[]>,
        },
        setup(props, { emit }: SetupContext) {
            const { model } = toRefs(props);
            const selectedResorts = computed({
                get: () => unref(model),
                set: (_value) => {
                    emit('change', _value);
                },
            });
            return {
                selectedResorts,
            };
        },
    });
