import { IFilterAttribute } from '@fr/vue/components/filters/common/types';
import FilterAttributeMethods from '@ve/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import _ from 'underscore';
import { IOperator } from '@entities/operator';

export default class extends FilterAttributeMethods<IFilterAttribute, IOperator[]> {
    constructor(OperatorData: IOperator[] | undefined) {
        const adaptsDataOperator: IFilterAttribute[] = OperatorData?.map((operator) => ({
            id: operator.id,
            name: operator.nameRu || operator.name,
            key: String(operator.id),
            keyMerged: String(operator.id),
            secondName: operator.name,
        })) || [];
        super(adaptsDataOperator);
        this.setFilterName('Туроператоры');
    }

    getCountAttribute(operatorsGrouped: IOperator[][]): Record<IFilterAttribute['name'], number> {
        return operatorsGrouped.reduce((acc, operators) => {
            const addedTourOperators: string[] = [];
            operators.forEach((operator) => {
                if (typeof operator !== 'number' && operator) {
                    const operatorFilterAttribute = this.getAttributeById(operator.id);
                    if (operatorFilterAttribute && addedTourOperators.indexOf(operatorFilterAttribute.key) === -1) {
                        acc[operatorFilterAttribute.key]++;
                        addedTourOperators.push(operatorFilterAttribute.key);
                    }
                }
            });
            return acc;
        }, this.getAttributeObjectCount() as Record<IFilterAttribute['name'], number>);
    }

    getIsOneHotelHasProperty(operatorsGrouped: IOperator[][]): boolean {
        return operatorsGrouped.some((operators) => operators.some((operator) => {
                if (typeof operator !== 'number' && operator) {
                    return !!operator.id;
                }
                return false;
            }));
    }
}