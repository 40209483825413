import { Store } from 'vuex';
import { BaseState } from '@ve/services/getStore/types';
import getStore from '@ve/services/getStore/getStore';
import { BackboneSearchPageModel } from '@ve/types/backboneModels/BackboneSearchPageModel';
import { PriceCalendarDataItem } from '@ve/components/priceCalendar/common/types/PriceCalendarDataItem';

export const NAMESPACE = 'priceCalendarStore';
export default ({
    model,
}: {
    model: BackboneSearchPageModel;
}): Store<BaseState> => getStore({
    name: NAMESPACE,
    backboneMap: [
        {
            fields: [{
                minPrice: 'calendarMinPrice',
                calendarData: 'calendarData',
            }],
            model,
        },
    ],
    storeOptions: {
        getters: {
            minPriceDate(state) {
                const {
                    minPrice,
                    calendarData,
                } = state;

                return minPrice && calendarData ? calendarData.find((data: PriceCalendarDataItem) => data.price === state.minPrice)?.date : null;
            },
        },
    },
});