
    import {
        defineComponent, computed, toRefs, unref, SetupContext, PropType,
    } from 'vue';

    import { TSizesInputCommon } from './common';

    export default defineComponent({
        name: 'radio-input-common',
        model: {
            prop: 'model',
            event: 'change',
        },
        props: {
            isHover: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: '',
            },
            value: {
                type: [Number, String],
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            model: {
                type: [Number, String],
            },
            isWhiteSpace: {
                type: Boolean,
                default: true,
            },
            checkBoxSize: {
                type: String as PropType<TSizesInputCommon>,
                default: 'small',
            },
        },
        setup(props, { emit }: SetupContext) {
            const {
                model, value,
            } = toRefs(props);
            const updateInput = () => {
                emit('change', unref(value));
            };

            const isChecked = computed(() => unref(value) === unref(model));

            return {
                isChecked,
                updateInput,
            };
        },
    });

