
    import { defineComponent, computed, unref } from 'vue';
    import { useState, useActions } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState, IMapForFiltersSerpActions } from '@ve/components/searchFilters/store/types';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import FilterAttributeRatingReviewsMethods from './filterMethods/FilterAttributeRatingReviewsMethods';
    import FilterListRender from '../common/components/FilterListRender/FilterListRender.vue';
    import Rating from '../../icons/rating/Rating.vue';
    import FiltersListSection from '../../searchFilters/filterList/desktop/FiltersListSection.vue';
    import { idsRating } from './types';

    export default defineComponent({
        components: {
            FilterPostWrapper,
            FilterListRender,
            FiltersListSection,
            Rating,
        },
        setup() {
            const {
                filteredHotelsWithoutRating, typeFilterRating, syncHotels, isPopularityFilterActive,
            } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['filteredHotelsWithoutRating', 'typeFilterRating', 'syncHotels', 'isPopularityFilterActive']);
            const {
                action_typeFilterRating: changeTypeFilterRating,
            } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_typeFilterRating']);

            const selectedReviewRating = computed({
                get: () => unref(typeFilterRating),
                set: (value: idsRating) => {
                    changeTypeFilterRating(value);
                },
            });

            const isHasOneRatingByAllHotels = computed(() => FilterAttributeRatingReviewsMethods.getIsOneHotelHasProperty(unref(syncHotels)));

            return {
                selectedReviewRating,
                filteredHotelsWithoutRating,
                syncHotels,
                isPopularityFilterActive,
                FilterAttributeRatingReviewsMethods,
                isHasOneRatingByAllHotels,
            };
        },
    });
