import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';

export default function noMirService(cookieName) {
    const existNoMirUrlParam = document.location.hash.indexOf('nomir=true') !== -1 || document.location.search.indexOf('nomir=true') !== -1;
    if (existNoMirUrlParam) {
        $.cookie(cookieName, 1, {
            path: '/',
            expires: 999,
            domain: wildcardDomain(),
        });
    }
}