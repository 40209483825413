import ls from 'local-storage';

import { propsDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import searchSessionService from '@/js/common/Domain/Service/search/searchSession';
import toRouterHotelHydrator from '@/js/common/Domain/Hydrator/toRouterHotel';
import gaAsync from '@/js/common/Domain/Service/gaAsync';

import './assets/hotelOnMap.scss';
import { productTypes } from '@fr/constants';
import template from './assets/template.html';

@propsDecorator({
    template,
    bindings: {
        ':el': 'toggle: isOpened',
        '.js-button': 'toggle: buttonText',
        '.js-title a': 'text: name',
        '.js-subtitle': 'text: resortName',
        '.js-distance': 'text: distanceText',
        '.js-button > a': 'text: buttonText',
        '.js-rating-value > b': 'text: ratingValue',
        '.js-booking-text': 'text: bookingText, toggle: bookingTextAvailable',
        '.js-stars': 'html: htmlStarsIcons',
        '.js-carousel-links': 'html: codeImagesHtml',
        '.js-rating-value': 'classes: { "active": ratingExist }, attr: { color: ratingUnsigned }',
        '.js-places-label': 'classes: { "active": lessPlaces }',
    },
    computeds: {
        htmlStarsIcons: {
            deps: ['category'],
            get(category) {
                const $html = $('<div></div>');

                _.times(category.get('stars'), () => {
                    $html.append('<i class="mapHotelCard__container__stars-icon"></i>');
                });

                return $html.html();
            },
        },

        codeImagesHtml: {
            deps: ['imagesHtml'],
            get: (imagesHtml) => imagesHtml || '',
        },

        ratingExist: {
            deps: ['rating'],
            get: (rating) => rating > 0,
        },

        ratingValue: {
            deps: ['rating'],
            get: (rating) => Math.round(rating * 10) / 10,
        },

        tourInfo: {
            deps: ['tourProducts'],
            get: (tourProducts) => tourProducts?.at(0)?.get('tour'),
        },

        lessPlaces: {
            deps: ['tourProducts'],
            get: (tourProducts) => {
                const models = tourProducts?.models || [];
                return !!_.find(models, (model) => model?.get('tour')?.get('lessPlaces') === true);
            },
        },

        resortName: {
            deps: ['resort'],
            get(resort) {
                if (resort instanceof Backbone.Model) {
                    return resort.get('name');
                }

                return '';
            },
        },

        distanceText: {
            deps: ['distances'],
            get(distances) {
                const items = [{
                    key: 'beach',
                    label: 'до пляжа',
                }, {
                    key: 'lift',
                    label: 'до подъемника',
                }];

                return _.reduce(items, (memo, { key, label }) => {
                    if (!distances?.[key] || distances[key] === 0 || memo) return memo;

                    if (distances[key] >= 1000) {
                        return `${parseFloat((distances[key] / 1000).toFixed(1))} км. ${label}`;
                    }

                    return `${distances[key]} м. ${label}`;
                }, '');
            },
        },

        bookingText: {
            deps: ['ordersCount'],
            get: (ordersCount) => `Забронировано ${ordersCount} раз${ordersCount?.wordEnding(';а;')} за месяц`,
        },

        bookingTextAvailable: {
            deps: ['ordersCount'],
            get: (ordersCount) => ordersCount > 0,
        },

        buttonText: {
            deps: ['tourInfo'],
            get(tourInfo) {
                if (tourInfo) {
                    return `от ${tourInfo.get('priceWithOilTax').formatNumber()} ₽`;
                }

                return '';
            },
        },

        ratingUnsigned: {
            deps: ['rating'],
            get(rating) {
                return Math.floor(rating.toFixed(1));
            },
        },
    },
    model: new Backbone.Model({
        isOpened: false,
    }),
})
export default class HotelOnMap extends Backbone.Epoxy.View {
    render() {
        this.$el.html(this.template);
        this.applyBindings();
    }

    initDOMEvents() {
        $('.js-close-card', this.$el).on('click', this.changeHotelOnMapVisible.bind(this, false));

        $('.js-carousel-links', this.$el).on('mousedown', 'a', this.hotelClick.bind(this));
        $('.js-hotel-page-link', this.$el).mousedown(this.hotelClick.bind(this));
        $('.serpHotelCard__container a:not(.goto-ua-page, .notChange)', this.$el).mousedown(this.hotelClick.bind(this));
    }

    setOptions(options) {
        this.$el = options?.$el;
        this.parentModel = options?.parentModel;
        this.collectionView = options?.collectionView;

        _.map(options?.data, (value, key) => {
            this.model.set(key, value);
        });
    }

    reRender(options) {
        this.setOptions(options);

        this.render();
        this.initDOMEvents();
    }

    trackOpenHotel() {
        gaAsync(() => {
            ga('send', 'event', 'Hotel_Card', 'Visit_the_hotel', 'date_price', {
                nonInteraction: 1,
            });
        });
    }

    hrefUrl(event) {
        const tourProduct = this.parentModel?.get('tourProducts')?.at(0);
        const tourCriteria = tourProduct?.get('tourCriteria')
            || this.parentModel?.get('searchData');

        const identity = tourProduct?.get('id');

        const hotelUrl = this.parentModel.get('url')
            || `/goto/hotel/${this.parentModel.get('id')}`;

        const urlParams = {
            tourCriteria,
            productType: productTypes.TOUR_OFFER,
            isExclusive: this.collectionView?.model?.get('filterCriteria')?.get('isExclusive') || false,
            similar: !!this.parentModel.get('similar'),
        };

        const paramsForRouter = toRouterHotelHydrator(urlParams);

        if (identity) {
            paramsForRouter.identity = identity;
        }

        paramsForRouter.sid = searchSessionService.getSearchId();

        $(event.currentTarget).attr('href', `${hotelUrl}#?${$.param(paramsForRouter)}`);
        $(event.currentTarget).attr('target', ($('.oneColumn:visible').length) ? '_self' : '_blank');

        ls.set('isAbsent', false);
        this.trackOpenHotel();
    }

    dataLayerEvents() {
        if (!this.dataLayerAnalytics) {
            return;
        }

        this.dataLayerAnalytics.searchClickEvent(this);
    }

    hotelClick(event) {
        this.dataLayerEvents();
        this.hrefUrl(event);
    }

    changeHotelOnMapVisible(isOpened = false) {
        this.model.set('isOpened', isOpened);
    }
}
