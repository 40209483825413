/* eslint-disable */
const ResortEntity = Backbone.Model.extend({
	defaults: {
		id: null,
		name: null,
		country: null,
		isPopular: false,
		atFiltering: true,
		minPrice: null
	}
});

export default ResortEntity;