/* eslint-disable */
import ls from 'local-storage';
import 'jquery.cookie';

import CountryEntity from '@/js/common/Domain/Entity/Country';
import CountriesRepository from '@/js/common/Domain/Repository/Countries';
import ResortEntity from '@/js/common/Domain/Entity/Resort';
import ResortsRepository from '@/js/common/Domain/Repository/Resorts';
import HotelEntity from '@/js/common/Domain/Entity/Hotel';
import HotelsRepository from '@/js/common/Domain/Repository/Hotels';
import getMinPriceByCriteriasService from '@/js/common/Domain/Service/getMinPriceByCriterias';
import DestinationService from '@/js/common/Domain/Service/Destination';
import DestinationSearchFieldView from '@/js/desktop/View/destination/DestinationSearchFieldView';
import tourCriteriasForMinPricesHydrator from '@/js/common/Domain/Hydrator/tourCriteriasForMinPrices';
import trackingService from '@/js/common/Domain/Service/tracking/tracking';
import IndexTrackingService from '@ve/pages/index/analytics/yandexAnalytics/indexTrackingService';
import DestinationModel from './DestinationModel';

const DestinationItemView = Backbone.View.extend({
    el: '<li class="item decoratedSimpleCheckbox"></li>',
    events: {
        click: 'selectItem',
    },
    selectItem(event) {
        const $selectedItem = this.$el;
        let setItems = [];
        const selectedData = this.model.get('itemsData');
        const currentSelectedResorts = this.parentModel.get('selectedItems');
        const addMulti = this.parentModel.get('addMulti');

        if (this.selectedAfterManualInput()) {
            this.trackIntelligentSearch();
        }

        this.parent.emptyDestination = false;

        if (selectedData instanceof CountryEntity) {
            this.parentModel.set('selectedItems', new CountriesRepository(selectedData), {
                initiator: 'destView',
                updateList: true,
            });
            this.parentView.scrollListToTop();
            $.cookie('countrySelected', 1, {path: '/'});
        }

        if (selectedData instanceof ResortEntity) {
            if (addMulti) {
                if (currentSelectedResorts instanceof ResortsRepository && currentSelectedResorts.at(0).get('country') === selectedData.get('country')) {
                    if (currentSelectedResorts.contains(selectedData)) {
                        setItems = currentSelectedResorts.without(selectedData);
                        $selectedItem.removeClass('selected');
                    } else {
                        setItems = setItems.concat(currentSelectedResorts.models, selectedData);
                        $selectedItem.addClass('selected');
                    }
                } else {
                    setItems.push(selectedData);
                    $selectedItem.addClass('selected');
                }
            } else {
                setItems.push(selectedData);
                this.parentModel.set('controlActivated', false);
            }

            if (setItems && setItems.length) {
                this.parentModel.set('selectedItems', new ResortsRepository(setItems), {
                    initiator: 'destView',
                    updateList: true,
                });
            } else {
                this.parentModel.set('selectedItems', new CountriesRepository(selectedData.get('country')), {
                    initiator: 'destView',
                    updateList: true,
                });
            }

            $.cookie('countrySelected', 1, { path: '/' });
        }

        if (selectedData instanceof HotelEntity) {
            this.parentModel.set('selectedItems', new HotelsRepository(selectedData), { initiator: 'destView' });
            this.parentModel.set('controlActivated', false);
        }

        event.stopPropagation();
        event.preventDefault();
    },
    selectedAfterManualInput() {
        const inputValue = this.parentModel.get('inputString');
        const originalLabelChanged = this.parentModel.get('originalLabelChanged');

        return originalLabelChanged && !_.isEmpty(inputValue);
    },
    trackIntelligentSearch() {
        const inputValue = this.parentModel.get('inputString');
        const selectedData = this.model.get('itemsData');

        trackingService.trackIntelligentSearch({
            inputValue,
            selectedData,
        });
    },
    updateMinPrice() {
        const itemsData = this.model.get('itemsData');
        const minPrice = itemsData ? itemsData.get('minPrice') : false;

        if (minPrice) {
            this.$minPrice.html(`от ${minPrice ? minPrice.formatNumber() : ''} р./чел.`);
            this.$minPrice.addClass('show');
        } else {
            this.$minPrice.removeClass('show');
        }
    },
    generateItemDOM() {
        const getVisaInfo = function (country) {
            if (country.get('visaOnline')) {
                return 'онлайн-виза';
            }
            if (country.get('visaRequired')) {
                return 'виза';
            }
            return 'без визы';
        };
        const type = this.model.get('type');
        let selectedCountryName;
        const itemsData = this.model.get('itemsData');
        const selectedItems = this.model.get('selectedItems');
        let selectedOnlyOneCountry;
        let text = '';
        let $minPrice = '';

        switch (type) {
            case 'selectAll':
                selectedOnlyOneCountry = selectedItems && selectedItems.length === 1 && selectedItems instanceof CountriesRepository && selectedItems.at(0) instanceof CountryEntity;
                selectedCountryName = selectedOnlyOneCountry ? selectedItems.at(0).get('name') : itemsData.get('name');

                this.$el.append(`<label><div class="checkbox-container"><input type="checkbox" class="checkboxInput" ${selectedOnlyOneCountry ? 'checked' : ''}><div class="checkbox"></div><span>Все курорты<i>${selectedCountryName ? (`, ${selectedCountryName}`) : ''}</i></span></div></label>`);
                break;
            default:
                if (itemsData instanceof CountryEntity || itemsData instanceof ResortEntity) {
                    this.$minPrice = $('<span class="minPrice"></span>');

                    ({$minPrice} = this);
                    this.updateMinPrice();
                }

                text += `<label>
                            <div class="checkbox-container">
                                <input type="checkbox" class="checkboxInput"${selectedItems && selectedItems && selectedItems.contains(itemsData) ? ' checked' : ''}>
                                <div class="checkbox"></div>`;

                if (itemsData instanceof CountryEntity) {
                    text += `<span>${itemsData.get('name')}<span class="visaLabel">${getVisaInfo(itemsData)}</span></span>`;
                }

                if (itemsData instanceof ResortEntity) {
                    text += `<span>${itemsData.get('name')}, <i>${itemsData.get('country').get('name')}</i></span>`;
                }

                if (itemsData instanceof HotelEntity) {
                    text += `<span>${itemsData.get('name')}</span>`;
                }
                text += '</div></label>';

                this.$el.append($minPrice);
                this.$el.append(text);
        }
    },
    initModelEvents() {
        const itemsData = this.model.get('itemsData');
        if (this.$minPrice && (itemsData instanceof CountryEntity || itemsData instanceof ResortEntity)) {
            itemsData.on('change:minPrice', $.proxy(this.updateMinPrice, this));
        }
    },
    initialize(options) {
        this.parentModel = options.parentModel;
        this.parentView = options.parentView;
        this.model = new Backbone.Model({
            type: options.type,
            itemsData: options.itemsData,
            selectedItems: options.selectedItems ? options.selectedItems : null,
        });
        this.parent = options.parent;
        this.generateItemDOM();
        this.initModelEvents();
    },
});

const ListOfOptionsView = Backbone.View.extend({
    generateOptionsList(options, context) {
        const inputValue = this.model.get('inputString');
        const $optionsList = $('<ul></ul>');
        let currentPopularCategory = false;
        let resortsOrDestinations;
        let selectedItems = this.model.get('selectedItems');
        const self = this;

        let $selectAllItem = '';

        selectedItems = selectedItems && selectedItems.length ? selectedItems : null;
        const selectedOnlyOneCountry = selectedItems && selectedItems.length === 1 && selectedItems instanceof CountriesRepository;

        if (context && context.resortsListWithFirstAll) {
            if (options && options.length && options[0] instanceof ResortEntity) {
                $selectAllItem = new DestinationItemView({
                    parentModel: this.model,
                    parentView: this,
                    itemsData: options[0].get('country'),
                    selectedItems,
                    type: 'selectAll',
                    parent: self.parent,
                });
            } else if (selectedOnlyOneCountry) {
                $selectAllItem = new DestinationItemView({
                    parentModel: this.model,
                    parentView: this,
                    selectedItems,
                    type: 'selectAll',
                    parent: self.parent,
                });
            }

            $optionsList.append($selectAllItem.$el);
        }

        if (options && options.length) {
            this.indexTrackingService.searchFormDestinationFieldAutocomplete(true, inputValue);
            _.each(options, (option, index) => {
                let $item;
                const optionIsPopular = option.get('isPopular');

                if (context && context.showPopularLabels && !(selectedItems && selectedItems.contains(option)) && currentPopularCategory !== optionIsPopular) {
                    currentPopularCategory = optionIsPopular;
                    resortsOrDestinations = (option && option instanceof ResortEntity) ? 'курорты' : 'направления';
                    $optionsList.append(`<li class="category"><span>${currentPopularCategory ? (`Популярные ${resortsOrDestinations}`) : `Остальные ${resortsOrDestinations}`}</span></li>`);
                }

                if (option instanceof CountryEntity
                    || option instanceof ResortEntity
                    || option instanceof HotelEntity) {
                    $item = new DestinationItemView({
                        parentModel: self.model,
                        parentView: self,
                        itemsData: option,
                        selectedItems,
                        type: 'item',
                        parent: self.parent,
                    });
                }

                if ($item && options[index + 1] && options[index + 1].constructor !== option.constructor) {
                    $item.$el.addClass('lastOfType');
                }

                $optionsList.append($item.$el);
            });

            this.$el.removeClass('loading');
            this.$el.append($optionsList);
            this.$errorPlace.removeClass('invalid');
        } else if (options && options.length === 0 && !(context && context.resortsListWithFirstAll)) {
            this.indexTrackingService.searchFormDestinationFieldAutocomplete(false, inputValue);
            this.$errorPlace.addClass('invalid');
            this.$el.append(this.nothingFoundLabel());
        } else if (context && context.resortsListWithFirstAll) {
            this.$el.addClass('loading');
            this.$errorPlace.removeClass('invalid');
            this.$el.append($optionsList);
        }
    },
    nothingFoundLabel() {
        return '<div class="nothingFound">Ничего не найдено</div>';
    },
    updateOptionsList(models, model, context) {
        const optionsList = this.model.get('optionsList');

        this.$el.empty();
        this.generateOptionsList(optionsList, context);
    },
    selectItemByModel(model) {
        const items = $('ul li', this.$el);
        const selectItem = _.find(items, item => $(item).data('item') === model);

        $(selectItem).trigger('select');
    },
    scrollListToTop() {
        this.$el.scrollTop(0);
    },
    controlActivatedChanged() {
        this.scrollListToTop();
    },
    initModelEvents() {
        this.model.on('change:optionsList', $.proxy(this.updateOptionsList, this));
        this.model.on('change:controlActivated', $.proxy(this.controlActivatedChanged, this));
    },
    initialize(options) {
        this.indexTrackingService = new IndexTrackingService();
        this.$errorPlace = options.$errorPlace ? options.$errorPlace : null;
        this.initModelEvents();
        this.parent = options.parent;
    },
});

const DestinationView = Backbone.View.extend({
    destinationLabel: 'Страна, курорт или отель',
    searchFieldView: null,
    listOfOptionsView: null,
    countriesData: null,
    resortsData: null,
    hotelsData: null,
    emptyDestination: false,
    directionChanged(event, model, context) {
        let countriesData;
        let resortsData;
        let hotelsData;
        if (!this.emptyDestination) {
            if (!context || context.initiator !== 'destView') {
                countriesData = this.countriesData.get('model');
                resortsData = this.resortsData.get('model');
                hotelsData = this.hotelsData.get('model');

                if (model === null || (model && model.length === 0)) {
                    if (countriesData == null) {
                        this.model.set('selectedItems', null);
                    } else if (model instanceof HotelsRepository) {
                        if (hotelsData && hotelsData.length) {
                            this.model.set('selectedItems', hotelsData);
                        } else if (resortsData && resortsData.length) {
                            this.model.set('selectedItems', resortsData);
                        } else if (countriesData && countriesData.length) {
                            this.model.set('selectedItems', countriesData);
                        }
                    } else if (model instanceof ResortsRepository) {
                        if (hotelsData && hotelsData.length) {
                            this.model.set('selectedItems', hotelsData);
                        } else if (resortsData && resortsData.length) {
                            this.model.set('selectedItems', resortsData);
                        } else if (countriesData && countriesData.length) {
                            this.model.set('selectedItems', countriesData);
                        }
                    }
                } else if (hotelsData && hotelsData.length) {
                    this.model.set('selectedItems', hotelsData);
                } else if (resortsData && resortsData.length) {
                    this.model.set('selectedItems', resortsData);
                } else if (countriesData && countriesData.length) {
                    this.model.set('selectedItems', countriesData);
                } else {
                    this.model.set('selectedItems', false);
                }
            }
        } else {
            this.model.set('selectedItems', []);
        }
    },
    getOptionsVariants() {
        const inputValue = this.model.get('inputString');
        const selectedItems = this.model.get('selectedItems');
        const originalLabelChanged = this.model.get('originalLabelChanged');
        const self = this;

        if (originalLabelChanged && !_.isEmpty(inputValue)) {
            this.destinationService.getOptions(inputValue, {
                success(optionsList) {
                    const optionsListSorted = _.sortBy(optionsList, option => !(selectedItems && selectedItems.length && selectedItems.contains(option)));
                    self.model.set('optionsList', optionsListSorted);
                    self.updateSelection();
                    self.updateListOfOptions();
                },
            });
        } else {
            this.updateListOfOptions();
        }
    },
    updateSelection() {
        const optionsList = this.model.get('optionsList');
        const firstItem = optionsList && optionsList.length ? optionsList[0] : null;
        const firstItemName = firstItem ? firstItem.get('name') : null;
        const inputValue = this.model.get('inputString');

        if (firstItemName && firstItemName.toLowerCase() === inputValue.toLowerCase()) {
            if (firstItem instanceof CountryEntity) {
                this.emptyDestination = false;
                this.model.set('selectedItems', new CountriesRepository(firstItem), {initiator: 'destView'});
            } else if (firstItem instanceof ResortEntity) {
                this.emptyDestination = false;
                this.model.set('selectedItems', new ResortsRepository(firstItem), {initiator: 'destView'});
            } else if (firstItem instanceof HotelEntity) {
                this.emptyDestination = false;
                this.model.set('selectedItems', new HotelsRepository(firstItem), {initiator: 'destView'});
            }
        }
    },
    getOptionsList(callback) {
        const inputValue = this.model.get('inputString');
        const self = this;
        let countries = CountriesRepository.repository;
        const selectedResorts = this.model.get('selectedResorts');
        let selectedCountry = this.model.get('selectedCountry');
        const selectedHotels = this.model.get('selectedHotels');
        const originalLabelChanged = this.model.get('originalLabelChanged');
        const sortCountryResorts = function (resorts) {
            return resorts.sortBy((resort) => {
                const resortsSelected = selectedResorts ? selectedResorts.contains(resort) : 0;
                return ((!resortsSelected * 1).toString() + (!resort.get('isPopular') * 1).toString() + resort.get('name')).toLowerCase();
            });
        };

        if (inputValue === '') {
            countries = countries.sortBy((country) => {
                const countrySelected = (selectedCountry && selectedCountry.at(0) === country) ? 1 : 0;
                return (!countrySelected).toString() + (country.get('position') / 1000).toFixed(3);
            });
            callback({
                value: countries,
                context: {showPopularLabels: true},
            });
        } else if (!originalLabelChanged && (selectedCountry || selectedResorts)) {
            selectedCountry = selectedCountry ? selectedCountry.at(0) : selectedResorts.at(0).get('country');

            self.model.set('optionsList', [], {showPopularLabels: true, resortsListWithFirstAll: true});
            selectedCountry.getResorts({
                success(countryResorts) {
                    const countryResortsSorted = sortCountryResorts(countryResorts);
                    callback({
                        value: countryResortsSorted,
                        context: {
                            showPopularLabels: true,
                            resortsListWithFirstAll: true,
                        },
                    });
                },
            });
        } else if (!originalLabelChanged && selectedHotels) {
            callback({
                value: selectedHotels.models,
                context: {
                    showPopularLabels: false,
                },
            });
        }
    },
    updateListOfOptions() {
        const self = this;

        this.getOptionsList((data) => {
            self.model.set('optionsList', data.value, data.context);
        });
    },
    inputChanged(model, item, context) {
        if (context.initiator === 'destView') {
            this.getOptionsVariants();
        }
    },
    selectedItemsChangedByView(event, model, context) {
        const selectedItems = this.model.get('selectedItems');
        let country = [];
        let resorts = [];
        let hotels = [];

        if (context && context.initiator === 'destView') {
            if (selectedItems && selectedItems.length) {
                if (selectedItems instanceof HotelsRepository) {
                    hotels = selectedItems.models;
                    country = selectedItems.at(0).get('country');
                } else if (selectedItems instanceof ResortsRepository) {
                    resorts = selectedItems.models;
                    country = selectedItems.at(0).get('country');
                } else if (selectedItems instanceof CountriesRepository) {
                    country = selectedItems.models;
                }

                this.resortsData.set('model', new ResortsRepository(resorts), {initiator: 'destView'});
                this.countriesData.set('model', new CountriesRepository(country), {initiator: 'destView'});
                this.resortsData.set('model', new ResortsRepository(resorts), {initiator: 'destView'});
                this.hotelsData.set('model', new HotelsRepository(hotels));
            } else {
                this.countriesData.set('model', null, {initiator: 'destView'});
            }
        }
    },
    controlActivatedChanged() {
        this.updateListOfOptions();
    },
    selectItemByModel(model) {
        this.listOfOptionsView.selectItemByModel(model);
    },
    selectedItemsChanged(event, model, context) {
        this.selectedItemsChangedByView(event, model, context);
        if (context && context.updateList) {
            this.updateListOfOptions();
            this.model.trigger('change:optionsList', null, null, {
                showPopularLabels: true,
                resortsListWithFirstAll: true,
            });
        }
    },
    clearMinPrices() {
        CountriesRepository.repository.each((country) => {
            country.set('minPrice', null);
        });

        ResortsRepository.repository.each((resort) => {
            resort.set('minPrice', null);
        });
    },
    getMinPrices() {
        const country = this.countriesData.get('model').at(0);
        const self = this;
        const countries = country.collection.filter(countryItem => _.isNull(countryItem.get('minPrice')) && countryItem.get('isPopular'));
        const resorts = country.get('resorts');
        const filteredResorts = resorts && resorts.length ? resorts.filter(resort => _.isNull(resort.get('minPrice')) && resort.get('isPopular')) : [];
        const departureCity = this.departureCity.get('model').get('id');

        const getMinPriceByCriteria = async function (params) {
            const tourCriterias = tourCriteriasForMinPricesHydrator({
                departureCity: params.departureCity,
                countries: params.countries,
                resorts: params.resorts,
                currentTourCriteria: self.parentModel.getTourCriteria(),
            });

            if (tourCriterias && tourCriterias.length) {
                const data = await getMinPriceByCriteriasService({
                    tourCriterias,
                });

                _.each(params.resorts, (resort) => {
                    resort.set('minPrice', 0, {
                        silent: true,
                    });
                });

                _.each(params.countries, (countryItem) => {
                    countryItem.set('minPrice', 0, {
                        silent: true,
                    });
                });

                _.each(data, (destinationPrice) => {
                    const criteria = JSON.parse(destinationPrice.criteria);

                    if (!_.isEmpty(criteria.resorts) && criteria.resorts.length === 1) {
                        _.find(params.resorts, resort => resort.get('id') === criteria.resorts[0]).set('minPrice', Math.round(destinationPrice.minPrice / 2));
                    } else {
                        _.find(params.countries, countryItem => countryItem.get('id') === criteria.countries[0]).set('minPrice', Math.round(destinationPrice.minPrice / 2));
                    }
                });
            }
        };

        if (resorts && resorts.length) {
            getMinPriceByCriteria({
                departureCity,
                countries,
                resorts: filteredResorts,
            });
        } else {
            country.once('change:resorts', (model, countryResorts) => {
                const popularResorts = countryResorts.filter(resort => !resort.get('minPrice') && resort.get('isPopular'));
                getMinPriceByCriteria({
                    departureCity,
                    countries,
                    resorts: popularResorts,
                });
            });
        }
    },
    departureCityChanged() {
        const countries = this.model.get('collection');
        if (countries && countries.length) {
            this.clearMinPrices();
            this.getMinPrices();
        }
    },
    nightRangeChanged(event, model, context) {
        if (model !== null && context.initiator === 'view') {
            this.clearMinPrices();
        }
    },
    checkInDateRangeChanged(event, model, context) {
        if (model !== null && context.initiator === 'view') {
            this.clearMinPrices();
        }
    },
    countriesDataChanged(model, value) {
        const self = this;
        clearTimeout(this.countriesDataChangedTimeout);
        this.countriesDataChangedTimeout = setTimeout(() => {
            if (value) {
                self.getMinPrices();
            }
        }, 1);
    },
    initModelEvents() {
        this.countriesData.on('change:model', $.proxy(this.directionChanged, this));
        this.resortsData.on('change:model', $.proxy(this.directionChanged, this));
        this.hotelsData.on('change:model', $.proxy(this.directionChanged, this));
        this.model.on('change:inputString', $.proxy(this.inputChanged, this));
        this.model.on('change:selectedItems', $.proxy(this.selectedItemsChanged, this));
        this.model.on('change:controlActivated', $.proxy(this.controlActivatedChanged, this));
    },

    initModelEventsForMinPrice() {
        this.departureCity.on('change:model', $.proxy(this.departureCityChanged, this));
        this.nightRange.on('change:model', $.proxy(this.nightRangeChanged, this));
        this.checkInDateRange.on('change:model', $.proxy(this.checkInDateRangeChanged, this));
        this.countriesData.on('change:model', $.proxy(this.countriesDataChanged, this));
    },
    isValid() {
        if (this.emptyDestination) {
            this.searchFieldView.showEmptyDestinationError();
            return false;
        }
        this.searchFieldView.hideEmptyDestinationError();
        return true;
    },
    getDestinationModel() {
        return DestinationModel;
    },
    getDestinationSearchFieldView() {
        return DestinationSearchFieldView;
    },
    renderLabel() {
        $('.formInput > label', this.$el).text(this.destinationLabel);
    },
    initialize(options) {
        const destinationViewModel = new (this.getDestinationModel())();
        this.model = destinationViewModel;

        this.destinationService = new DestinationService();
        this.countriesData = options.countriesData ? options.countriesData : null;
        this.resortsData = options.resortsData ? options.resortsData : null;
        this.hotelsData = options.hotelsData ? options.hotelsData : null;
        this.parentModel = options.parentModel ? options.parentModel : null;


        if (options.emptyByDefault && !ls.get('tourCriteria')) {
            this.emptyDestination = true;
        }

        if (options.departureCity && options.nightRange && options.checkInDateRange) {
            this.departureCity = options.departureCity ? options.departureCity : null;
            this.nightRange = options.nightRange ? options.nightRange : null;
            this.checkInDateRange = options.checkInDateRange ? options.checkInDateRange : null;
            this.initModelEventsForMinPrice();
        }
        this.searchFieldView = new (this.getDestinationSearchFieldView())({
            el: $('.formInputPlace', this.$el),
            parent: this,
            model: destinationViewModel,
            dataLayerAnalytics: options.dataLayerAnalytics,
        });

        this.listOfOptionsView = new ListOfOptionsView({
            el: $('.formDropdownContent', this.$el),
            $errorPlace: $('.formInputPlace', this.$el),
            parent: this,
            model: destinationViewModel,
        });

        this.renderLabel();
        this.initModelEvents();
    },
});

export default DestinationView;