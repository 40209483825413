/* eslint-disable */
import {TrackJS} from 'trackjs';
import OperatorsRepository from '@/js/common/Domain/Repository/Operators';
import CheckInDateRangeFactory from './checkInDateRange/CheckInDateRange';
import MealsRepository from '../Repository/Meals';
import DepartureCitiesRepository from '../Repository/DepartureCities';
import DepartureCityFactory from './DepartureCity';
import CountryFactory from './Country';
import CountriesRepository from '../Repository/Countries';
import errorHandlerService from '../Service/errorHandler';
import TourObjectValue from '../ObjectValue/Tour';
import DataProxy from '../Service/DataProxy';

export default function TourFactory(data, resorts) {
    const tour = {};
    let departureCity;
    let resort;
    let country;
    let meal;
    let dateParts;
    let element;

    element = data.sortRate;
    if (typeof element !== 'undefined') {
        tour.sortRate = element;
    }

    element = data.nights;
    if (typeof element !== 'undefined') {
        tour.nights = element.tour;
        tour.nightsInHotel = element.hotel;
    }

    element = data.checkInDate;
    if (typeof element !== 'undefined' && tour.nights) {
        tour.checkInDate = element;
        tour.checkInDateRange = new DataProxy(() => {
            element = data.checkInDate;

            dateParts = element.split('-');

            return CheckInDateRangeFactory({
                from: element,
                to: new Date((new Date(dateParts[0], dateParts[1] - 1, dateParts[2])).getTime() + tour.nights * 3600 * 24 * 1000 + 3600 * 4 * 1000),
            });
        });
    }

    element = data.meal;
    if (typeof element !== 'undefined') {
        meal = MealsRepository.repository.get(element);
        tour.meal = meal || element;
    }

    element = data.departureCity;
    if (typeof element !== 'undefined') {
        if (DepartureCitiesRepository.repository.length) {
            departureCity = element.id ? DepartureCitiesRepository.repository.get(element.id) : null;
        }
        tour.departureCity = departureCity || new DepartureCityFactory(element);
    }

    element = data.country;
    if (typeof element !== 'undefined') {
        if (CountriesRepository.repository.length) {
            country = element.id ? CountriesRepository.repository.get(element.id) : null;
        }
        tour.country = country || new CountryFactory(element);
    }

    element = data.resort;
    if (typeof element !== 'undefined') {
        resort = resorts?.get(element);

        if (typeof (resort) === 'undefined' && resorts?.length) {
            errorHandlerService(`No resort ${element.id}:${element.name} on hotel ${data.hotel.id}:${data.hotel.name} but found in tour. Resort: ${data.resort}`);
            TrackJS.console.log(data);

            TrackJS.track('No resort in repository');
        }

        tour.resort = resort || element;
    }

    element = data.hotel;
    if (typeof element !== 'undefined') {
        tour.hotel = element;

        element = data.hotelCategory?.name;
        if (typeof element !== 'undefined') {
            tour.hotel.hotelCategoryName = element;
        }
    }

    element = data.cashbackAvailable;
    if (typeof element !== 'undefined') {
        tour.cashbackAvailable = element;
    }

    element = data.operator;
    if (typeof element !== 'undefined') {
        tour.operator = OperatorsRepository.repository.add(element).toJSON() || null;
    }

    element = data.price;
    if (typeof element !== 'undefined') {
        tour.price = element;
    }

    element = data.oilTax;
    if (typeof element !== 'undefined') {
        tour.oilTax = element;
        tour.oilTaxIsAbsent = element === null;
    }

    if (data.price) {
        tour.priceWithOilTax = tour.price + (tour.oilTax || 0);
    }

    element = data.routes;
    if (typeof element !== 'undefined') {
        tour.routes = element;
    }

    element = data.room;
    if (typeof element !== 'undefined') {
        tour.room = element;
    }

    element = data.touristGroup;
    if (typeof element !== 'undefined') {
        tour.touristGroup = element;
    }

    element = data.transfers;
    if (typeof element !== 'undefined') {
        tour.transfers = element;
    }

    element = data.transfer;
    if (typeof element !== 'undefined') {
        tour.transfer = element;
    }

    element = data.hotelAvailable;
    if (typeof element !== 'undefined') {
        tour.hotelAvailable = element;
    }

    if ((typeof data.hotelAvailable !== 'undefined' && data.hotelAvailable === 2)
        || (typeof data.isLessTicketsQty !== 'undefined' && data.isLessTicketsQty)) {
        tour.lessPlaces = true;
    }

    element = data.flightSpecificationPrice;
    if (typeof element !== 'undefined') {
        tour.flightSpecificationPrice = element;
    }

    element = data.freeCancellation;
    if (element) {
        tour.freeCancellation = element;
    }

    element = data.flightType;
    if (element) {
        tour.flightType = element;
    }

    if (data.originalPrice && tour.priceWithOilTax) {
        const {priceWithOilTax} = tour;
        const {originalPrice} = data;
        tour.originalPrice = originalPrice;
        tour.discount = priceWithOilTax < originalPrice ? (1 - priceWithOilTax / originalPrice) : 0;
    }

    return new TourObjectValue(tour);
};
