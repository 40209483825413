/* eslint-disable */
import DepartureCityEntity from '../Entity/DepartureCity';
import EntityManager from '../EntityManager';
import DepartureCityFactory from '../Factory/DepartureCity'

const DepartureCitiesRepository = Backbone.Collection.extend({
	model: DepartureCityEntity,
	addEntry:function(departureCities) {
		var departureCitiesModelsArray = [],
			entityManager = new EntityManager();

		departureCitiesModelsArray = entityManager.add({
			factory: DepartureCityFactory,
			repository: repository
		}, departureCities);

		this.add(departureCities);
	}
});

let repository = new DepartureCitiesRepository();

repository.init = function(departureCities) {
	var departureCitiesModelsArray = [];
	_.each(departureCities, function(departureCity) {
		var departureCityModel = new DepartureCityFactory(departureCity);
		departureCitiesModelsArray.push(departureCityModel);
	});
	this.set(departureCitiesModelsArray);
};

repository.getDepartureCities = function(callback) {
	var self = this;

	if(self.length) {
		callback && callback.success ? callback.success(self) : '';
	}
};

DepartureCitiesRepository.repository = repository;

export default DepartureCitiesRepository;
