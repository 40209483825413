import Cookies from 'js-cookie';
import isMobileVersion from '@/js/common/Service/isMobileVersion';

export interface IgetAbTestValue {
    uuid: string
    slug: string
    version: string
}

export default (): IgetAbTestValue[] | null => {
    const platform = isMobileVersion() ? 'mobile' : 'desktop';
    const cookie = Cookies.get(`ab_tests_${platform}`);

    return cookie ? JSON.parse(cookie) : null;
};
