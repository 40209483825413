/* eslint-disable */
import CountriesRepository from "@/js/common/Domain/Repository/Countries";
import ResortsRepository from "@/js/common/Domain/Repository/Resorts";
import HotelsRepository from "@/js/common/Domain/Repository/Hotels";

let DestinationModel = Backbone.Epoxy.Model.extend({
	defaults: {
		controlActivated: false,
		selectedItems: false,
		optionsList: null,
		inputString: ''
	},
	computeds: {
		selectedCountry: function () {
			var selectedItems = this.get('selectedItems');

			if (selectedItems && selectedItems.length && selectedItems instanceof CountriesRepository) {
				return selectedItems;
			} else {
				return false;
			}
		},
		selectedResorts: function () {
			var selectedItems = this.get('selectedItems');

			if (selectedItems && selectedItems.length && selectedItems instanceof ResortsRepository) {
				return selectedItems;
			} else {
				return false;
			}
		},
		selectedHotels: function () {
			var selectedItems = this.get('selectedItems');

			if (selectedItems && selectedItems.length && selectedItems instanceof HotelsRepository) {
				return selectedItems;
			} else {
				return false;
			}
		},
		inputHint: function () {
			var inputStringValue = this.get('inputString'),
				optionsList = this.get('optionsList'),
				firstListItemName = optionsList && optionsList.length ? optionsList[0].get('name') : null,
				hintPart,
				controlActivatedState = this.get('controlActivated');


			if (inputStringValue == '') {
				return false;
			} else {
				if (controlActivatedState && firstListItemName && firstListItemName.toLowerCase().indexOf(inputStringValue.toLowerCase()) == 0 && inputStringValue.length !== firstListItemName.length) {
					return firstListItemName.substr(inputStringValue.length);
				} else if (controlActivatedState && firstListItemName && firstListItemName.toLowerCase().indexOf(inputStringValue.toLowerCase()) == 0) {
					return 0;
				} else {
					return false;
				}
			}
		},
		currentInputSelected: function () {
			var inputStringValue = this.get('inputString'),
				selectedItems = this.get('selectedItems'),
				selectedItemValue;

			if (selectedItems && selectedItems.length == 1 && inputStringValue && inputStringValue !== '') {
				selectedItemValue = selectedItems.at(0).get('name');
				if (selectedItemValue.toLowerCase() == inputStringValue.toLowerCase()) {
					return true;
				}
			}

			return false;
		},
		addMulti: function () {
			var selectedResorts = this.get('selectedResorts'),
				selectedCountry = this.get('selectedCountry'),
				originalLabelChanged = this.get('originalLabelChanged');

			return (selectedResorts || selectedCountry) && !originalLabelChanged;
		},
		label: function () {
			var selectedHotels = this.get('selectedHotels'),
				selectedResorts = this.get('selectedResorts'),
				selectedCountry = this.get('selectedCountry'),
				label = '';

			if (selectedHotels) {
				if (selectedHotels.length == 1) {
					label = selectedHotels.at(0).get('name');
				} else {
					label = selectedHotels.length + ' отел' + selectedHotels.length.wordEnding('ь;я;ей');
				}
			} else if (selectedResorts) {
				if (selectedResorts.length == 1) {
					label = selectedResorts.at(0).get('name') + ", " + selectedResorts.at(0).get('country').get('name');
				} else {
					label = selectedResorts.length + ' курорт' + selectedResorts.length.wordEnding(';а;ов') + ", " + selectedResorts.at(0).get('country').get('name');
				}
			} else if (selectedCountry) {
				label = selectedCountry.at(0).get('name');
			}

			return label;
		},
		originalLabelChanged: function () {
			var inputStringValue = this.get('inputString'),
				currentLabel = this.get('label');

			if (typeof inputStringValue == "string" && typeof currentLabel == "string" && currentLabel.toLowerCase() !== inputStringValue.toLowerCase()) {
				return true;
			} else {
				return false;
			}
		}
	}
});

export default DestinationModel;