import { viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import checkLotteryService from '@/js/common/Domain/Service/checkLottery';
import ecommerceService from '@/js/common/Domain/Service/ecommerce';

import template from './template.html';

import './lotteryButton.scss';

@viewModelDecorator({
    default: {
        hide: false,
        lotteryUrl: null,
    },
})
class LotteryButton extends Backbone.Epoxy.View {
    initialize() {
        checkLotteryService((lotteryData) => {
            if (!lotteryData) return;

            this.viewModel.set('lotteryUrl', lotteryData.checkoutLink);

            this.createWidget();
            this.initDOMEvent();

            ecommerceService.lotteryImpressions({
                lotteryId: lotteryData.id,
                promotionType: 'lottery__prizeIcon',
            });
        });
    }

    initDOMEvent() {
        $(window).scroll(() => {
            const $scrollButton = $('.scrollToTop');

            if (!$scrollButton.length) return;

            $('.lottery-button-container').toggle($scrollButton.is(':hidden'));
        });

        $(document).on('click', '.js-open-lottery', $.proxy(this.openLotteryWindow, this));
    }

    createWidget() {
        $('body').append(template());
    }

    lotteryPrizeClick() {
        checkLotteryService((lotteryData) => {
            ecommerceService.lotteryClick({
                lotteryId: lotteryData.id,
                promotionType: 'lottery__popup',
            });
        });
    }

    openLotteryWindow() {
        const lotteryUrl = this.viewModel.get('lotteryUrl');

        window.open(lotteryUrl);
        this.lotteryPrizeClick();
    }
}

setTimeout(() => {
    new LotteryButton();
}, 50);