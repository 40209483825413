import { ICountry } from '@entities/country/@/resort';
import { IRawResort, IResort } from '../types/IResort';

export default function (resorts: IRawResort[], countries: ICountry[]): IResort[] {
    return resorts.reduce((acc, resort) => {
        const countryResort = countries.find((country) => country.id === resort.country);
        if (countryResort) {
            acc.push(Object.freeze({
                ...resort,
                country: countryResort,
            }));
        }
        return acc;
    }, [] as IResort[]);
}