import { IAttributeHotel } from './index';

const dataAttributesHotel: IAttributeHotel[] = [
    {
        id: 20,
        key: 'beachOwner',
        keyMerged: 'beachOwner',
        name: 'Собственный пляж отеля',
        typeFilter: 'BeachFilter',
        nameLayer: '',
        nameEvent: '',
    },
    {
        id: 21,
        key: 'nearTheCenter',
        keyMerged: 'nearTheCenter',
        name: 'Близко к центру',
        typeFilter: 'NearCenterFilter',
        nameLayer: '',
        nameEvent: '',
    },
];

export default dataAttributesHotel;