import _ from 'underscore';
import { SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import { KeyOfTypes } from '@ve/types/helpers';
import FilterAttributeMethodsAbstract from '../filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
import dataAttributesHotel from './dataAttributesHotel';
import { IAttributeHotel, IFilterType } from './index';

class FilterAttributeHotelMethodsByAll extends FilterAttributeMethodsAbstract<IAttributeHotel, SerpHotelAdapted> {
    getIsOneHotelHasProperty(hotels: SerpHotelAdapted[]): boolean {
        const isHasProperty = !!this.getListFilterAttributes().find((attributeHotel) => _.some(hotels, (hotel: any) => hotel[attributeHotel.key]))?.key;
        return isHasProperty;
    }

    getCountAttribute(hotels: SerpHotelAdapted[]): Record<IAttributeHotel['key'], number> {
        return this.getListFilterAttributes()
            .reduce((acc, attributeHotel) => {
                hotels.forEach((hotel) => {
                    if (hotel[attributeHotel.key]) {
                        acc[attributeHotel.key]++;
                    }
                });
            return acc;
        }, this.getAttributeObjectCount());
    }

    filterIdsByTypeFilter(ids: number[], typeFilter: IFilterType): IAttributeHotel['id'][] {
        return this.getListFilterAttributes()
            .filter((attributeHotel) => ids.indexOf(attributeHotel.id) !== -1 && attributeHotel.typeFilter === typeFilter)
            .map((attributeHotel) => attributeHotel.id);
    }

    filterIdsWithoutTypeFilter(ids: number[], typeFilter: IFilterType): IAttributeHotel['id'][] {
        return this.getListFilterAttributes()
            .filter((attributeHotel) => ids.indexOf(attributeHotel.id) !== -1 && attributeHotel.typeFilter !== typeFilter)
            .map((attributeHotel) => attributeHotel.id);
    }
}

const filterAttributeHotelMethodsByAll = new FilterAttributeHotelMethodsByAll(dataAttributesHotel);

export {
    filterAttributeHotelMethodsByAll,
    FilterAttributeHotelMethodsByAll,
};
