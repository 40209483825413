import { RestTypeId } from '../types/RestType';

export default function (types: number[]): RestTypeId[] {
    const result: RestTypeId[] = [];
    if (!types) return [];

    if (types.includes(1)) result.push(RestTypeId.Beach);

    if (types.includes(2)) result.push(RestTypeId.City);

    if (types.includes(3)) result.push(RestTypeId.Mountain);

    if (types.includes(4)) result.push(RestTypeId.Wellness);

    if (types.includes(11)) result.push(RestTypeId.Eco);

    return result;
}