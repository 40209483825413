import { ExWindow } from '@ve/types';
import AbTests from './abTests/AbTests';

declare let window: ExWindow;
const abTestsDevice = new AbTests();

//используем document ready потому что для domContentLoaded выполняется позже чем ready
$(() => {
    //do work
    const { abTests } = window.appConfig;
    abTestsDevice.init({
        abTests,
    });
});
export default abTestsDevice;
