/* eslint-disable */
import postFilterAdapter from './services/postFIlterAdapter';
import { getValuesFilterCriteriaByOld } from '@/js/common/Domain/Service/AdaptersFilterCriteria';
import getStore from '@ve/services/getStore/getStore';

import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';

import WaterActivitiesDesktop
    from '@/js/desktop/components/filterCriteria/components/waterActivitiesDesktop/WaterActivitiesDesktop';

import '@/css/common/components/warranted-icon.scss';

const FilterCriteriaView = Backbone.Epoxy.View.extend({
    el: '.sideBlock.filters',
    bindings: {
        '.nearTheBeach label': 'classes:{checked:nearTheBeach}',
        '.nearTheBeach input': 'checked:nearTheBeach',

        '.excursions label': 'classes:{checked:excursions}',
        '.excursions input': 'checked:excursions',
        '.cashback label': 'classes:{checked:cashback}',
        '.cashback input': 'checked:cashback',
        '.mountains label': 'classes:{checked:mountains}',
        '.mountains input': 'checked:mountains',
        '.eco label': 'classes:{checked:eco}',
        '.eco input': 'checked:eco',
        '.wellness label': 'classes:{checked:wellness}',
        '.wellness input': 'checked:wellness',
        '.pool label': 'classes:{checked:pool}',
        '.pool input': 'checked:pool',
        '.animation label': 'classes:{checked:animation}',
        '.animation input': 'checked:animation',
        '.familyRooms label': 'classes:{checked:familyRooms}',
        '.familyRooms input': 'checked:familyRooms',
        '.diving label': 'classes:{checked:diving}',
        '.diving input': 'checked:diving',
        '.freeSafe label': 'classes:{checked:freeSafe}',
        '.freeSafe input': 'checked:freeSafe',
        '.fitnessCenter label': 'classes:{checked:fitnessCenter}',
        '.fitnessCenter input': 'checked:fitnessCenter',
        '.freeMiniBar label': 'classes:{checked:freeMiniBar}',
        '.freeMiniBar input': 'checked:freeMiniBar',
        '.skiEquipment label': 'classes:{checked:skiEquipment}',
        '.skiEquipment input': 'checked:skiEquipment',
        '.honeyMoon label': 'classes:{checked:honeyMoon}',
        '.honeyMoon input': 'checked:honeyMoon',
        '.waterSports label': 'classes:{checked:waterSports}',
        '.waterSports input': 'checked:waterSports',
        // '.spaAndHealth label': 'classes:{checked:spaAndHealth}',
        // '.spaAndHealth input': 'checked:spaAndHealth',
        '.nightClub label': 'classes:{checked:nightClub}',
        '.nightClub input': 'checked:nightClub',
        '.personsWithDisabilities label': 'classes:{checked:personsWithDisabilities}',
        '.personsWithDisabilities input': 'checked:personsWithDisabilities',
        '.noSmokingRooms label': 'classes:{checked:noSmokingRooms}',
        '.noSmokingRooms input': 'checked:noSmokingRooms',
        '.smokingRooms label': 'classes:{checked:smokingRooms}',
        '.smokingRooms input': 'checked:smokingRooms',

        '.locationFilterReview label': 'classes:{checked:ratingLocation}',
        '.serviceFilterReview label': 'classes:{checked:ratingService}',
        '.mealFilterReview label': 'classes:{checked:ratingMeal}',

        '.locationFilterReview .filterReviewLinesWrapper': 'html:scoreReviewsLocation',
        '.serviceFilterReview .filterReviewLinesWrapper': 'html:scoreReviewsService',
        '.mealFilterReview .filterReviewLinesWrapper': 'html:scoreReviewsMeal',
        '.locationFilterReview .filterReviewLinesWrapperStatic': 'html:scoreReviewsStatic',
        '.serviceFilterReview .filterReviewLinesWrapperStatic': 'html:scoreReviewsStatic',
        '.mealFilterReview .filterReviewLinesWrapperStatic': 'html:scoreReviewsStatic',

        '.sandBeach label': "classes:{checked:equal(beachType,'sandBeach')}",
        '.sandBeach input': "setUnset:['change','beachType','sandBeach'], checkedSet:['change','beachType','sandBeach']",
        '.pebbleBeach label': "classes:{checked:equal(beachType,'pebbleBeach')}",
        '.pebbleBeach input': "setUnset:['change','beachType','pebbleBeach'], checkedSet:['change','beachType','pebbleBeach']",
        '.beachAny input': 'attr: { checked: (beachType) }',

        '.openPool input': 'checked:openPool',
        '.closePool input': 'checked:closePool',
        '.heatedPool input': 'checked:heatedPool',
        '.seaPool input': 'checked:seaPool',
        '.waterslides input': 'checked:waterslides',
        '.aquapark input': 'checked:aquapark',
    },
    initCompopents() {
        new WaterActivitiesDesktop({
            el: $('.waterActivities', this.$el),
            searchPageModel: this.searchPageModel,
        });
    },
    initialize(options) {
        this.model = options.model;
        this.searchPageModel = options.searchPageModel;
        this.searchFormViewModel = options.searchFormViewModel;
        this.dataLayerAnalytics = options.dataLayerAnalytics;
        this.initModelEvents();
        this.initCompopents();
    },
    offUnusedFilters(model, value) {
        const filteredHotes = this.searchPageModel.get('filteredHotels');
        const self = this;

        if (filteredHotes && filteredHotes.length) {
            for (const i in self.model.attributes) {
                if (self.model.attributes.hasOwnProperty(i) && self.searchPageModel.attributes.hasOwnProperty(`${i}Count`) && self.model.get(i) && !self.searchPageModel.get(`${i}Count`)) {
                    self.model.set(i, false);
                }
            }
        }
    },
    setDefaultTourCriteriaPostFiltersToModel() {
        const postFilters = window?.appConfig?.defaultTourCriteria?.postFilters;

        if (postFilters && !document.location.hash) {
            const filterCriteria = postFilterAdapter(postFilters);
            const filterCriteriaNewModelFields = getValuesFilterCriteriaByOld(filterCriteria)
            this.model.set({...filterCriteria, ...filterCriteriaNewModelFields});
        }
    },
    initModelEvents() {
        const self = this;
        this.model.on('change', $.proxy(this.offUnusedFilters, this));
        this.searchPageModel.on('change:filteredHotels', () => {
            setTimeout(() => {
                self.offUnusedFilters();
            }, 0);
        });

        this.model.on('change:ratingLocation', $.proxy(this.updateScoreReviewsLocation, this));
        this.searchPageModel.on('change:ratingLocationMax', $.proxy(this.updateScoreReviewsLocation, this));

        this.model.on('change:ratingService', $.proxy(this.updateScoreReviewsService, this));
        this.searchPageModel.on('change:ratingServiceMax', $.proxy(this.updateScoreReviewsService, this));

        this.model.on('change:ratingMeal', $.proxy(this.updateScoreReviewsMeal, this));
        this.searchPageModel.on('change:ratingMealMax', $.proxy(this.updateScoreReviewsMeal, this));
    },
    events: {
        'click .locationFilterReview .labelTag.checked': 'ratingLocationUnset',
        'click .serviceFilterReview .labelTag.checked': 'ratingServiceUnset',
        'click .mealFilterReview .labelTag.checked': 'ratingMealUnset',
        'click .locationFilterReview .filterReviewLinesWrapper span': 'ratingLocationClick',
        'click .serviceFilterReview .filterReviewLinesWrapper span': 'ratingServiceClick',
        'click .mealFilterReview .filterReviewLinesWrapper span': 'ratingMealClick',
        'click .locationFilterReview .filterReviewLinesWrapperStatic span': 'ratingLocationClick',
        'click .serviceFilterReview .filterReviewLinesWrapperStatic span': 'ratingServiceClick',
        'click .mealFilterReview .filterReviewLinesWrapperStatic span': 'ratingMealClick',
        'click .dynamicFilters input[type=checkbox]': 'filterClick',
        'click .filtersList__item-extra': 'extraHandler',
        'click .ratingFilter .checkbox': 'radioHandler',
        'click .beachAny .checkbox': 'beachAny',
    },
    beachAny() {
        this.model.set('beachType', 0);
    },
    extraHandler(e) {
        const eventsTargetElements = this.getTargetsData(e);
        this.setValueAsRadioInput(eventsTargetElements);
    },
    radioHandler(e) {
        e.preventDefault();

        const { targetsCheckbox, filtersList } = this.getTargetsData(e);

        if (!targetsCheckbox.is(':checked')) {
            this.setValueAsRadioInput({ targetsCheckbox, filtersList });
        }
    },
    setValueAsRadioInput({ targetsCheckbox, filtersList }) {
        _.each(filtersList.children(), ($filter) => {
            const $input = $('input', $filter);

            $input
                .prop('checked', false)
                .trigger('change');
        });

        targetsCheckbox
            .prop('checked', true)
            .trigger('change');
    },
    getTargetsData(e) {
        const targetsParent = $(e.target).parents('.filtersList__item');

        return {
            targetsCheckbox: $('input', targetsParent),
            filtersList: $(e.target).parents('.filtersList'),
        };
    },
    filterClick(e) {
        if (!this.dataLayerAnalytics) {
            return;
        }
        const self = this;
        const $this = $(e.target);
        const checked = $this.is('.js-all-filters-value') ? true : $this.is(':checked');
        const value = $this.data('layer-name');
        setTimeout(() => {
            //ожидание пересчета всех счетчиков
            DataLayerAnalytics.toggleFilter(checked, value);
            const filteredHotels = self.searchPageModel.get('filteredHotels') || [];
            self.dataLayerAnalytics.filterCountEvent(filteredHotels.length);
            if (filteredHotels.length) {
                self.dataLayerAnalytics.generateTop20Event(filteredHotels);
            }
        }, 100);
    },
    preventCheckboxUncheck(event) {
        if (!$(event.target).is(':checked')) {
            event.preventDefault();
        }
    },
    analyticsEvents(value) {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'sideBarFilter',
            beach: value,
        });
    },
    viewModel: new (Backbone.Epoxy.Model.extend({
        defaults: {
            scoreReviewsLocation: '',
            scoreReviewsService: '',
            scoreReviewsMeal: '',
            scoreReviewsStatic: '<span data-rv="1" class="filterReviewLineDefault"></span><span data-rv="2" class="filterReviewLineDefault"></span><span data-rv="3" class="filterReviewLineDefault"></span><span data-rv="4" class="filterReviewLineDefault"></span><span data-rv="5" class="filterReviewLineDefault"></span>',
        },
    }))(),

    resetPreviousFilters() {
        const store = getStore();
        store.commit('previousFilterStore/setDefaultPreviousStore');
    },

    showPreviousFilters() {
        const store = getStore();
        store.commit('previousFilterStore/setSyncProducts', 0);
        store.commit('previousFilterStore/setFilterPreviousStateFilters', { ...store.state.mapForFiltersSerp });
        this.model.clearFilters();
    },

    updateScoreReviewsLocation() {
        let html = '';
        let index = 1;
        const ratingLocation = this.model.get('ratingLocation');
        const ratingLocationMax = this.searchPageModel.get('ratingLocationMax');

        for (var i = 0; i < ratingLocation; i++) {
            if (index <= ratingLocationMax) {
                html += `<span data-rv="${index}" class="filterReviewLine r${ratingLocation}"></span>`;
                index++;
            }
        }
        for (var i = 0; i < 5 - ratingLocation; i++) {
            if (index <= ratingLocationMax) {
                html += `<span data-rv="${index}" class="filterReviewLineDefault"></span>`;
            }
            index++;
        }

        this.viewModel.set('scoreReviewsLocation', html);
    },
    updateScoreReviewsService() {
        let html = '';
        let index = 1;
        const ratingService = this.model.get('ratingService');
        const ratingServiceMax = this.searchPageModel.get('ratingServiceMax');

        for (let i = 0; i < ratingService; i++) {
            if (index <= ratingServiceMax) {
                html += `<span data-rv="${index}" class="filterReviewLine r${ratingService}"></span>`;
            }
            index++;
        }
        for (let i = 0; i < 5 - ratingService; i++) {
            if (index <= ratingServiceMax) {
                html += `<span data-rv="${index}" class="filterReviewLineDefault"></span>`;
            }
            index++;
        }
        this.viewModel.set('scoreReviewsService', html);
    },
    updateScoreReviewsMeal() {
        let html = '';
        let index = 1;
        const ratingMeal = this.model.get('ratingMeal');
        const ratingMealMax = this.searchPageModel.get('ratingMealMax');

        for (var i = 0; i < ratingMeal; i++) {
            if (index <= ratingMealMax) {
                html += `<span data-rv="${index}" class="filterReviewLine r${ratingMeal}"></span>`;
            }
            index++;
        }
        for (var i = 0; i < 5 - ratingMeal; i++) {
            if (index <= ratingMealMax) {
                html += `<span data-rv="${index}" class="filterReviewLineDefault"></span>`;
            }
            index++;
        }
        this.viewModel.set('scoreReviewsMeal', html);
    },
    ratingLocationUnset() {
        this.model.set('ratingLocation', 0);
    },
    ratingServiceUnset() {
        this.model.set('ratingService', 0);
    },
    ratingMealUnset() {
        this.model.set('ratingMeal', 0);
    },
    ratingLocationClick(e) {
        const selectedRating = $(e.currentTarget).data('rv');
        this.model.set('ratingLocation', selectedRating);
    },
    ratingServiceClick(e) {
        const selectedRating = $(e.currentTarget).data('rv');
        this.model.set('ratingService', selectedRating);
    },
    ratingMealClick(e) {
        const selectedRating = $(e.currentTarget).data('rv');
        this.model.set('ratingMeal', selectedRating);
    },
});

export default FilterCriteriaView;
