/* eslint-disable */
import { propsDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import moment from 'moment';
import 'moment/locale/ru';
import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';
import setAdditionalMetaOptions from '@/js/common/Service/setAdditionalMetaOptions';
import AjaxRequestManager from '../../Domain/AjaxRequestManager';
import tourCriteriaJsonHydrator from '../../Domain/Hydrator/tourCriteriaJson';
import CheckInDateRangeFactory from '../../Domain/Factory/checkInDateRange/CheckInDateRange';
import tourCriteriaService from '../../Domain/Service/tourCriteria';
import CheckInDateRangeObjectValue from '../../Domain/ObjectValue/CheckInDateRange';
import trackingService from '../../Domain/Service/tracking/tracking';
import calendarDataTemplate from './calendarDataTemplate.html';
import priceCalendarTemplate from './priceCalendarTemplate.html';
import PriceCalendarModel from './PriceCalendarModel';

import './priceCalendar.css';

const ajaxRequestManager = new AjaxRequestManager();

/**
 * для высоких показателей CLS, нужно отрисовывать часть компоненты еще до js на фронте. Поэтому помимо основных states,
 * есть loading состояние отрисованное на php.
 */
@propsDecorator({
    ajaxRequestManager,
    el: priceCalendarTemplate(),
    calendarDataTemplate,
    model: null,
    searchViewModel: null,
    preventSearchDataUpdate: false,
    events: {
        'click .js-click-start-search': 'clickStartSearchHandler',
    },
})
@viewModelDecorator({
    defaults: {
        state: 'dynamic' //loading, stubForStaticLoader
    },
})
export default class PriceCalendarView extends Backbone.Epoxy.View {
    $nightsLabel() {
        this.$nightsLabel = $('.nightsLabel', this.$el);
    }

    $touristLabel() {
        this.$touristLabel = $('.touristLabel', this.$el);
    }

    $minPriceLabel() {
        this.$minPriceLabel = $('.priceLabelMin', this.$el);
    }

    $minPriceThing() {
        this.$minPriceThing = $('.minPriceThing', this.$el);
    }

    $maxPriceThing() {
        this.$maxPriceThing = $('.maxPriceThing', this.$el);
    }

    $maxPriceLabel() {
        this.$maxPriceLabel = $('.priceLabelMax', this.$el);
    }

    $calendarData() {
        this.$calendarData = $('.calendarData', this.$el);
    }

    $previousDates() {
        this.$previousDates = $('.cls__nav-left .icon-i16_arrow-left', this.$el);
    }

    $nextDates() {
        this.$nextDates = $('.cls__nav-right .icon-i16_arrow-right', this.$el);
    }

    setState(state) {
        this.viewModel.set('state', state);
    }

    showCalendar() {
        this.$el.slideDown();
    }

    hideCalendar() {
        this.$el.slideUp();
    }

    clickStartSearchHandler(e) {
       e.preventDefault();

       this.emitStartSearch();
    }

    emitStartSearch(e) {
        this.parent.formSubmit();
    }

    navigation() {
        const self = this;
        return {
            hideAll() {
                self.$nextDates.hide();
                self.$previousDates.hide();
            },
            hideLeft() {
                self.$previousDates.hide();
            },
            showAll() {
                this.showRight();
                self.$previousDates.show();
            },
            showRight() {
                self.$nextDates.show();
            },
        };
    }

    addCalendarData(data) {
        const self = this;
        const calendarData = self.model.get('calendarData');
        let dateColl;
        let index;
        if (calendarData && calendarData?.length && data) {
            const dateString = data.date?.format('YYYY-MM-DD');
            dateColl = _.findWhere(calendarData, { dateStr: dateString });
            if (_.isObject(dateColl) && data.price) {
                index = _.findLastIndex(calendarData, { dateStr: dateString });
                calendarData[index].price = data.price;
                this.clearEmptyDate(dateString);
                this.model.set('calendarData', calendarData);
                this.model.trigger('change:calendarData', this.model, calendarData);
                this.renderSlider();
            }
        }
    }

    getCalendarData(params, callback) {
        const tourCriteria = params.searchData;
        const calendarTourCriteria = tourCriteria.clone();
        let startCalendarTime;
        const self = this;

        this.model.get('searchData').set('checkInDateRange', tourCriteria.get('checkInDateRange'));
        this.model.recompute('expandedDatesObject');

        calendarTourCriteria.set('checkInDateRange', new CheckInDateRangeObjectValue(params.slideMoveDates || this.model.get('expandedDates')));

        const data = {
            ..._.pick(tourCriteriaJsonHydrator(calendarTourCriteria), 'checkInDateRange', 'countries', 'departureCity', 'resorts', 'hotels', 'nightRange', 'meals', 'hotelCategories', 'operators', 'touristGroup'),
            ...setAdditionalMetaOptions(),
        };

        const options = {
            url: `${window.appConfig.apiGatewayUrl}/frontend/prices/searchLatest`,
            data,
        };

        if (data.countries) {
            startCalendarTime = new Date().getTime();
            ajaxRequestManager.abortAll(options);
            if (!params.notCenterCurrent) {
                this.setState('loading');
            }
            self.navigation().hideAll();
            ajaxRequestManager.getData(options, {
                success(responseData, fullData) {
                    let calendarData = fullData.result;
                    if (!calendarData || !calendarData.length) {
                        self.hideCalendar();
                        return;
                    }
                    calendarData = _.map(calendarData, dateData => ({
                        date: moment(dateData.checkindate, 'YYYY-MM-DD'),
                        dateStr: dateData.checkindate,
                        price: Math.round(dateData.price),
                    }));

                    self.trackCalendar({
                        tourCriteria: calendarTourCriteria,
                        calendarData,
                        startCalendarTime,

                    });

                    self.successGetCalendarData(calendarData, params);
                    if (_.isFunction(callback)) {
                        callback(calendarData);
                    }
                },
                error() {
                    if (_.isFunction(callback)) {
                        callback();
                    }
                },
            });
        } else {
            self.hideCalendar();
        }
    }

    trackCalendar(options) {
        const currentTime = new Date().getTime();

        trackingService.trackCalendar({
            datesWithPricesAmount: _.filter(options.calendarData, calendarItem => calendarItem.price).length,
            tourCriteria: options.tourCriteria,
            timestamp: options.startCalendarTime,
            timeFinished: currentTime,
        });
    }

    successGetCalendarData(calendarData, params) {
        const self = this;
        if (params.notClearCalendarDate) {
            const modelCalendarData = self.model.get('calendarData');
            let index;
            for (const i in calendarData) {
                index = _.findLastIndex(modelCalendarData, {dateStr: calendarData[i].dateStr});
                if (index >= 0) {
                    modelCalendarData[index].price = calendarData[i].price;
                }
            }
            self.model.set('calendarData', modelCalendarData);
            self.model.trigger('change:calendarData', self.model,  modelCalendarData);
        } else {
            self.model.set('calendarData', calendarData);
        }
        self.showCalendar();
        this.setState('dynamic');
        self.renderSlider(params);
    }

    dateSelected(e) {
        const $dateCol = $(e.currentTarget);
        const dateStr = $dateCol.data('date');
        const date = moment(dateStr, 'YYYY-MM-DD');
        if (!$dateCol.hasClass('disabled') && !$dateCol.find('.cls-d-have-not-tours').length) {
            this.dataLayerAnalyticsEvent($dateCol.hasClass('not-price') ? 'clickEmptyCalendar' : 'clickPriceCalendar');
            this.setActiveCollByDateStr(dateStr);
            this.startNewSearchByDate(date);
        }
    }

    dataLayerAnalyticsEvent(eventName) {
        this.dataLayerAnalytics.generateEvent(eventName, '');
    }

    setActiveCollByDateStr(dateStr) {
        this.$calendarData.find('.cls-itu-column.current').removeClass('current');
        this.$calendarData.find(`.cls-itu-column[data-date=${dateStr}]`).addClass('current');
    }

    startNewSearchByDate(date) {
        const dateFrom = date;
        const dateTo = dateFrom.clone();
        const checkInDateRange = {
            from: dateFrom._d,
            to: dateTo._d,
        };
        const newCheckInDateRange = new CheckInDateRangeFactory(checkInDateRange);
        this.searchViewModel.set('sortStrategy', 'priceUp');
        this.model.set('selectedDates', newCheckInDateRange);
        this.checkInDateRange.set('model', newCheckInDateRange);
        tourCriteriaService.addTourCriteriaChangedField('checkInDateRange');
        this.preventSearchDataUpdate = true;
        this.parent.formSubmit({
            openBooking: false,
        });
        this.preventSearchDataUpdate = false;
    }

    renderCalendar() {
        const html = this.calendarDataTemplate({
            today: moment().startOf('day'),
            calendarMinPrice: this.model.get('calendarMinPrice'),
            calendarMaxPrice: this.model.get('calendarMaxPrice'),
            selectedDates: this.model.get('selectedDates'),
            calendarData: this.model.get('calendarData') || [],
            emptyDates: this.model.get('emptyDates'),
            dayOfWeek: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
        });
        this.$calendarData.html(html);
        this.$calendarData.find('.cls-itu-column').on('click', $.proxy(this.dateSelected, this));
    }

    changedCalendarData() {
        this.renderCalendar();
    }

    changedNightsLabel(model, nightsLabel) {
        this.$nightsLabel.text(nightsLabel);
    }

    changedTouristLabel(model, text) {
        this.$touristLabel.text(text);
    }

    changedOffset(model) {
        this.getCalendarData({
            searchData: model.get('searchData'),
            dontClearMinPrice: true,
        });
    }

    changedSearchData(model) {
        this.model.set('offset', 0, {silent: true});
        this.getCalendarData({
            searchData: model.get('searchData'),
        });
    }

    prevDatesAvailableChanged(model, prevDatesAvailable) {
        if (this.model.numOfCalendarDays > 15) return;
        if (prevDatesAvailable) {
            this.$previousDates.removeClass('disabled');
        } else {
            this.$previousDates.addClass('disabled');
        }
    }

    changedCalendarMaxPrice(model, maxPrice) {
        let label = '';
        const minPrice = this.model.get('calendarMinPrice');

        if (maxPrice === minPrice) {
            this.$maxPriceThing.hide();
            if (!maxPrice) {
                this.$minPriceThing.hide();
            } else {
                this.$minPriceThing.show();
            }
            this.$minPriceThing.addClass('middle');
        } else {
            this.$maxPriceThing.show();
            this.$minPriceThing.show();
            this.$minPriceThing.removeClass('middle');
        }

        if (maxPrice) {
            label = maxPrice.formatNumber();
        }
        this.$maxPriceLabel.text(label);
    }

    changedCalendarMinPrice(model, minPrice) {
        let label = '';

        if (minPrice) {
            label = minPrice.formatNumber();
        }
        this.$minPriceLabel.text(label);
    }

    initOuterModelsEvents() {
        const self = this;
        this.searchViewModel.on('change:searchData', (viewModel, searchData) => {
            if (!self.preventSearchDataUpdate) {
                self.model.set('searchData', searchData);
                self.model.set('selectedDates', searchData.get('checkInDateRange'));
            } else {
                self.preventSearchDataUpdate = false;
            }
        });

        this.searchViewModel.on('initPageWithoutCriteria', () => {
            this.setState('stubForStaticLoader');
            this.showCalendar();
        })
    }

    initModelEvents() {
        this.model.on('change:searchData', $.proxy(this.changedSearchData, this));
        this.model.on('change:nightsLabel', $.proxy(this.changedNightsLabel, this));
        this.model.on('change:touristLabel', $.proxy(this.changedTouristLabel, this));
        this.model.on('change:calendarData', $.proxy(this.changedCalendarData, this));
        this.model.on('change:offset', $.proxy(this.changedOffset, this));
        this.model.on('change:prevDatesAvailable', $.proxy(this.prevDatesAvailableChanged, this));
        this.model.on('change:calendarMaxPrice', $.proxy(this.changedCalendarMaxPrice, this));
        this.model.on('change:calendarMinPrice', $.proxy(this.changedCalendarMinPrice, this));

    }

    initDOMEvents() {
        const self = this;
        if (this.model.numOfCalendarDays > 15) {
            this.$previousDates.on('click', () => {
                if (this.viewModel.get('state') === 'stubForStaticLoader') {
                    return this.emitStartSearch();
                }

                self.$calendarData.animate({
                    left: 0,
                }, 400, () => {
                    const $firstColl = self.$calendarData.find('.cls-itu-range:first .cls-itu-column:first');
                    const lastDateFrom = moment($firstColl.attr('data-date'), 'YYYY-MM-DD');
                    const lastDateTo = lastDateFrom.clone();
                    let minDay = 0;

                    self.getCalendarData({
                        searchData: self.model.get('searchData'),
                        slideMoveDates: (function () {
                            const today = moment().add(1, 'days').startOf('day');
                            if (lastDateFrom.diff(today, 'days') >= 8) {
                                minDay = 8;
                            } else {
                                minDay = lastDateFrom.diff(today, 'days');
                            }
                            return {
                                from: lastDateTo.subtract(minDay, 'days').toDate(),
                                to: lastDateFrom.add(30 - minDay, 'days').toDate(),
                            };
                        }()),
                        notCenterCurrent: true,
                        notMove: !minDay,
                        lastCollIndex: (7 + minDay),
                    });
                });
            });
            this.$nextDates.on('click', () => {
                if (this.viewModel.get('state') === 'stubForStaticLoader') {
                    return this.emitStartSearch();
                }

                self.$calendarData.animate({
                    left: (function () {
                        let result = 0;
                        $('.cls-itu-range', self.$el).each(function (index) {
                            const $visible = $(this).find('.cls-itu-column[data-visible=true]:last');
                            if ($visible.length) {
                                result += $visible.nextAll('.cls-itu-column').length;
                            } else if (index > 0) {
                                result += $(this).find('.cls-itu-column[data-visible=false]').length;
                            }
                        });
                        return self.$calendarData.position().left - (result * self.collWith);
                    }()),
                }, 400, () => {
                    const lastDateFrom = moment(self.$calendarData.find('.cls-itu-column[data-visible=false]:last').attr('data-date'), 'YYYY-MM-DD');
                    const lastDateTo = lastDateFrom.clone();
                    self.getCalendarData({
                        searchData: self.model.get('searchData'),
                        slideMoveDates: {
                            from: lastDateFrom.subtract(22, 'days').toDate(),
                            to: lastDateTo.add(8, 'days').toDate(),
                        },
                        notCenterCurrent: true,
                    });
                });
            });
        } else {
            this.$previousDates.on('click', () => {
                let offset;
                if (self.model.get('prevDatesAvailable')) {
                    offset = self.model.get('offset');
                    self.model.set('offset', --offset);
                }
            });
            this.$nextDates.on('click', () => {
                let offset = self.model.get('offset');
                self.model.set('offset', ++offset);
            });
        }
        $(window).resize(() => {
            self.renderSlider();
        });
    }

    $calendarContainer() {
        this.$calendarContainer = $('#priceCalendarContainer');
    }

    render() {
        this.$calendarContainer.html(this.$el);
    }

    setCurrentPosition(params) {
        const {collWith} = this;
        let position = 0;
        let visible = 0;
        const lastCollIndex = params.lastCollIndex || 15;
        const {$cols} = this;

        if (!params.notMove) {
            // Устанавливаем положение слайдера относительно выбранной даты
            for (let i = 0; i < lastCollIndex; i++) {
                if ($cols.eq(i).is('.current') && !params.notCenterCurrent) {
                    break;
                } else if (i >= 7) {
                    position -= collWith;
                    visible++;
                }
            }
        }
        // отмечаем дата параметром видимые и невидимые элементы
        $cols.each(function (index) {
            $(this).attr('data-visible', !(index < visible || index >= (visible + 15)));
        });
        return params.notMove ? 0 : position;
    }

    renderSlider(params = {}) {
        if (this.model.numOfCalendarDays <= 15) return;
        this.$cols = $(this.$calendarData.find('.cls-itu-column'));
        const {$calendarData} = this;
        const width = this.createSliderWith();
        const left = this.setCurrentPosition(params);

        $calendarData.css({
            left,
            width,
        });
        const $firstVisibleColl = this.$calendarData.find('.cls-itu-column[data-visible=true]:first');
        this.navigation()[moment().add(1, 'day').diff(moment($firstVisibleColl.attr('data-date'), 'YYYY-MM-DD'), 'days') ? 'showAll' : 'showRight']();
    }

    createSliderWith() {
        const self = this;
        let sliderWidth = 0;
        self.collWith = self.$calendarData.find('.cls-itu-column:first').outerWidth();
        self.$calendarData.find('.cls-itu-range').each(function () {
            const width = $(this).find('.cls-itu-column').length * self.collWith;
            sliderWidth += width;
            $(this).width(width);
        });
        this.model.set('sliderWidth', sliderWidth);
        return sliderWidth;
    }

    clearEmptyDate(hasPriceDate) {
        const currentEmptyDates = this.model.get('emptyDates');
        this.model.set('emptyDates', currentEmptyDates.filter((date) => date !== hasPriceDate));
    }

    setDateNotTours(date) {
        this.model.get('emptyDates').push(date);
        this.model.set('mobileViewDateCheck', false);
        if (this.model.get('calendarData') && this.model.get('calendarData').length) {
            this.model.trigger('change:calendarData', this.model, this.model.get('calendarData'));
            this.renderSlider();
        }
    }

    initialize(options) {
        this.model = options?.model || new PriceCalendarModel();
        this.dataLayerAnalytics = new DataLayerAnalytics();
        this.model.numOfCalendarDays = options.numOfCalendarDays || this.model.numOfCalendarDays;
        this.$calendarContainer();
        this.render();
        this.parent = options.parent;
        this.searchViewModel = this.parent.view.model;
        this.checkInDateRange = this.parent.model.get('checkInDateRange');

        this.$nightsLabel();
        this.$touristLabel();
        this.$minPriceLabel();
        this.$minPriceThing();
        this.$maxPriceThing();
        this.$maxPriceLabel();
        this.$calendarData();
        this.$previousDates();
        this.$nextDates();
        this.initModelEvents();
        this.initOuterModelsEvents();
        this.initDOMEvents();
    }
}