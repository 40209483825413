import './accountPopups.css';

import 'jquery.cookie';

import requestService from '@/js/common/Domain/Service/request';
import trackingService from '@/js/common/Domain/Service/tracking/tracking';
import AccountTopMenuBar from './topMenuBar/TopMenuBar';
import accountModel from './accountModel';

class Controller extends Backbone.View {
    initialize() {
        this.initModel();
        this.getProfileData();

        $(() => {
            this.initComponents();
        });
    }

    initModel() {
        this.model = accountModel;
    }

    static setCustomerId(customerId) {
        const options = {
            url: '/setCustomerId',
            type: 'POST',
            data: {
                customerId,
            },
        };
        requestService(options);
    }

    getProfileData() {
        const options = {
            url: `${window.appConfig.accountUrl}/api/customer/profile`,
            type: 'get',
            xhrFields: { withCredentials: true },
        };

        this.model.set('profileRequestInProgress', true);

        requestService(options, {
            success: (response) => {
                const { data } = response;
                if (response.success && data) {
                    this.model.set('email', response.data.email);
                    this.model.set('name', response.data.name);
                    this.model.set('hash', response.data.hash);
                    this.model.set('id', response.data.id);
                    this.model.set('customerUuid', response.data.uuid);
                    Controller.setCustomerId(response.data.id);
                    this.model.set('authorized', true);

                    trackingService.setCustomerUuid(this.model.get('customerUuid'));
                }
                this.model.set('profileRequestInProgress', false);
                this.model.trigger('profileDataReceived');
                this.model.set('profileDataReceived', 1);
            },
            error: () => {
                this.model.set('profileRequestInProgress', false);
                this.model.trigger('profileDataReceived');
                this.model.set('profileDataReceived', 1);
            },
        });
    }

    initComponents() {
        this.topMenuBar = new AccountTopMenuBar({
            el: '#accountTopMenuBar',
            model: this.model,
            parent: this,
        });
    }

    async lazyAuthorizeInit() {
        const { default: AccountAuthorize } = await import(/* webpackChunkName: "AccountDialogs" */ './authorize/Authorize');

        if (!this.authorize) {
            this.authorize = new AccountAuthorize({
                model: this.model,
                parent: this,
            });
        }
    }

    async lazyRegistrationInit() {
        const { default: AccountRegistration } = await import(/* webpackChunkName: "AccountDialogs" */ './registration/Registration');

        if (!this.registration) {
            this.registration = new AccountRegistration({
                model: this.model,
                parent: this,
            });
        }
    }

    async lazyForgotPasswordInit() {
        const { default: AccountForgotPassword } = await import(/* webpackChunkName: "AccountDialogs" */ './forgotPassword/ForgotPassword');

        if (!this.forgotPassword) {
            this.forgotPassword = new AccountForgotPassword({
                model: this.model,
            });
        }
    }
}

const account = new Controller({
    el: 'body',
});

export default account;
