import moment from 'moment';
import 'moment/locale/ru';
import CheckInDateRangeFactory from '@/js/common/Domain/Factory/checkInDateRange/CheckInDateRange';
import trackingService from '@/js/common/Domain/Service/tracking/tracking';
import SearchRequestFactory from '@/js/common/Domain/Factory/SearchRequest';
import TourCriteriaFactory from '@/js/common/Domain/Factory/TourCriteria';

import { NAMESPACE as MAP_FOR_SEARCH_DATA } from '@ve/pages/serp/store/SearchDataStore';
import { NAMESPACE as MAP_FOR_PRICE_CALENDAR } from '@ve/components/priceCalendar/common/store/priceCalendarStore';
import { NAMESPACE as EMPTY_SEARCH_STORE } from '@ve/components/emptySearch/common/store/EmptySearchStore';
import { NAMESPACE as MAP_FOR_PRODUCTS_SEARCH } from '@ve/pages/serp/store/productsSearchStore';

export default class EmptySerpService {
    constructor(searchService, controller, options = {}) {
        this.searchService = searchService;
        this.controller = controller;
        this.productType = options.productType;
        this.store = options.store;

        this.initStoreEvents(options.store);
    }

    initStoreEvents(store) {
        this.updateCurrentStateWatchers(store);
        this.watchCurrentState(store);
    }

    updateCurrentStateWatchers(store) {
        store.watch((state) => [
            state[MAP_FOR_PRODUCTS_SEARCH].loadingState,
            state[MAP_FOR_PRODUCTS_SEARCH].hotelsLength,
        ], () => {
            store.dispatch(`${EMPTY_SEARCH_STORE}/goNextState`);
            this.updatePriceInPriceCalendar();
        });
    }

    watchCurrentState(store) {
        store.watch((state) => state[EMPTY_SEARCH_STORE].currentState, (state) => {
            //TODO: для тестирования. Удалю перед релизом
            console.log('=====================> currentState', state);
            this.changeState(state);
        });
        //TODO: для тестирования. Удалю перед релизом
        console.log('=====================> currentState', store.state[EMPTY_SEARCH_STORE].currentState);
    }

    changeState(state) {
        switch (state) {
            case 'calendarMinPriceDateSearch':
                this.startSearchCalendarMinPrice();
                break;
            case 'asyncSearchWithFlexibleDates':
                this.startAsyncSearchWithFlexibleDates();
                break;
            case 'defaultCriteriaSearch':
                this.startSearchByDefaultTourCriteria();
                break;
            default:
        }

        if (!['userSearch', 'userSearchHasTours'].includes(state)) {
            this.setNoToursDate();
        }

        if ([
            'asyncSearchWithFlexibleDates',
            'calendarMinPriceDateSearch',
            'defaultCriteriaSearch',
        ].includes(state)) {
            this.showNoToursBanner();
        }

        if (state === 'userSearch') {
            this.hideNoToursBanner();
            this.clearSimilarTours();
            this.clearFoundByAnotherDate();
        }

        if (state === 'calendarMinPriceDateSearchHasTours') {
            this.minPriceDateSearchHasTours();
        }

        if (state === 'defaultCriteriaSearchHasTours') {
            this.defaultCriteriaSearchHasTours();
        }
    }

    clearFoundByAnotherDate() {
        this.controller.view.model.set('foundByAnotherDate', null);
    }

    minPriceDateSearchHasTours() {
        const minPriceDate = this.getMinPriceDate();
        this.controller.view.model.set('foundByAnotherDate', minPriceDate.format('DD MMMM'));
        this.controller.priceCalendar?.setActiveCollByDateStr(minPriceDate.format('YYYY-MM-DD'));
        this.controller.priceCalendar?.renderSlider({ lastCollIndex: 23 });
    }

    clearSimilarTours() {
        this.controller.view.model.set('visibleSimilarTours', null);
    }

    defaultCriteriaSearchHasTours() {
        this.controller.view.model.set('visibleSimilarTours', this.defaultTourCriteriaText());
    }

    setNoToursDate() {
        const checkInDateRangeIsFlexible = this.checkInDateRangeIsFlexible();
        const checkInDate = this.getCheckInDate().from;

        if (!checkInDateRangeIsFlexible) {
            this.controller.priceCalendar?.setDateNotTours(
                moment(checkInDate.getTime()).format('YYYY-MM-DD'),
            );
        }
    }

    updatePriceInPriceCalendar() {
        const { currentState } = this.store.state[EMPTY_SEARCH_STORE];

        if (currentState === 'userSearchHasTours') {
            const checkInDateRangeIsFlexible = this.checkInDateRangeIsFlexible();
            const checkInDate = this.getCheckInDate().from;
            const minPrice = this.getToursMinPrice();

            if (!checkInDateRangeIsFlexible && minPrice) {
                this.controller.priceCalendar?.addCalendarData({
                    date: moment(checkInDate),
                    price: minPrice,
                });
            }
        }
    }

    getToursMinPrice() {
        return this.controller.view.model.get('minPrice');
    }

    showNoToursBanner() {
        this.controller.noToursBanner.showBanner(
            this.getMinPriceDate(),
        );
    }

    hideNoToursBanner() {
        this.controller.noToursBanner.hideBanner();
    }

    startAsyncSearchWithFlexibleDates() {
        const {
            controller,
        } = this;
        const tourCriteriaForAsyncWithoutTours = this.getSearchData();

        const checkInDateRange = CheckInDateRangeFactory({
            from: this.controller.view.model.get('searchData').get('checkInDateRange').get('to'),
            daysFlexible: 1,
        });
        tourCriteriaForAsyncWithoutTours.set('checkInDateRange', checkInDateRange);

        controller.view.model.set({
            loadingState: 'loading',
        });

        controller.progressBar.reset();
        controller.progressBar.moveToValue(95);

        trackingService.trackStartEmptySearch({
            tourCriteria: tourCriteriaForAsyncWithoutTours,
            timestamp: (new Date()).getTime(),
            productType: this.productType,
        });

        this.searchService.getOffersData({
            searchUuid: trackingService.getSearchUuid(),
            originalSearchUuid: trackingService.originalSearchUuid,
            requests: [new SearchRequestFactory(tourCriteriaForAsyncWithoutTours, 'asyncWithoutTours')],
            callback: {
                success: () => {
                    //console.warn('запрос в прайстатистик');
                    // запрос на данные календаря в кеш (прайстатистик)
                    controller.progressBar.moveToValue(99);
                    if (controller.priceCalendar) {
                        controller.priceCalendar.getCalendarData({
                            searchData: tourCriteriaForAsyncWithoutTours,
                            notCenterCurrent: true,
                            notClearCalendarDate: true,
                            slideMoveDates: {
                                from: moment(checkInDateRange.get('from')).toDate(),
                                to: moment(checkInDateRange.get('to')).toDate(),
                            },
                        }, this.loadersHide.bind(this));
                    } else {
                        this.loadersHide();
                    }
                },
                error: () => {
                    this.loadersHide();
                },
            },
        });
    }

    getMinPriceDate() {
        return this.store.getters[`${MAP_FOR_PRICE_CALENDAR}/minPriceDate`];
    }

    checkInDateRangeIsFlexible() {
        return this.store.getters[`${MAP_FOR_SEARCH_DATA}/checkInDateRangeIsFlexible`];
    }

    getCheckInDate() {
        return this.store.state[MAP_FOR_SEARCH_DATA].checkInDateRange;
    }

    getSearchData() {
        return this.controller.view.model.get('searchData').clone();
    }

    startSearchByDefaultTourCriteria() {
        const defaultTourCriteria = this.generateDefaultsTourCriteria(this.getSearchData());

        //console.warn('запускаем посик по дефолтным критериям');
        this.controller.goSearch(defaultTourCriteria);
    }

    loadersHide() {
        const { controller } = this;
        controller.progressBar.complete();
        controller.view.model.set('loadingState', 'finished');
    }

    startSearchCalendarMinPrice() {
        const minPriceDate = this.getMinPriceDate().toDate();
        const currentTourCriteria = this.getSearchData();

        const newCheckInDateRange = new CheckInDateRangeFactory({
            from: minPriceDate,
            to: minPriceDate,
        });

        currentTourCriteria.set('checkInDateRange', newCheckInDateRange);

        // Старт поиска по минимальной дате календаря

        //console.warn('Старт поиска по минимальной дате календаря');
        this.controller.goSearch(currentTourCriteria);
    }

    defaultTourCriteriaText() {
        let text = '';
        const tourCriteria = this.generateDefaultsTourCriteria(this.getSearchData());

        const touristGroup = tourCriteria.get('touristGroup').toJSON();
        touristGroup.babies = touristGroup.kids + touristGroup.infants;
        text += `${tourCriteria.get('countries').models[0].get('name')}, c `;
        text += `${moment(tourCriteria.get('checkInDateRange').get('from')).format('DD.MM.YYYY')} на `;
        text += `${tourCriteria.get('nightRange').get('from')} - ${tourCriteria.get('nightRange').get('to')} ночей и `;
        text += `${touristGroup.adults} ${touristGroup.adults.wordEnding('взрослый;взрослых;взрослых')}`;
        if (touristGroup.babies) {
            text += ` c ${touristGroup.babies} ${touristGroup.babies.wordEnding('ребенок;детьми;детьми')}`;
        }
        return text;
    }

    generateDefaultsTourCriteria(tourCriteria) {
        const { controller } = this;
        const defaultTourCriteria = controller.view.model.get('searchData').get('countries').at(0).get(`${controller.view.model.get('searchData').get('departureCity').get('type')}TourCriteria`);
        const countries = [
            tourCriteria.get('countries').models[0].get('id'),
        ];
        const departureCity = tourCriteria.get('departureCity');
        const touristGroup = tourCriteria.get('touristGroup').toJSON();

        return new TourCriteriaFactory(_.extend({}, defaultTourCriteria, {
            countries,
            departureCity,
            touristGroup,
        }));
    }
}