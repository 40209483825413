import {
    computed,
    ComputedRef,
    Ref,
    unref,
} from 'vue';
import { Resort } from '@ve/types';

export default function (resortRepository: ComputedRef<Resort[]>, textValue?: Ref<string>) {
    const availableResorts = computed(() => unref(resortRepository).filter((resort) => resort.atFiltering));
    const filteredRepository = computed(() => {
        if (!unref(textValue)?.length) {
            return unref(availableResorts);
        }
        return unref(availableResorts).filter((resort) => resort.name.toLowerCase().indexOf(unref(textValue || '').toLowerCase()) !== -1);
    });
    const popularResorts = computed(() => unref(filteredRepository).filter((resort) => resort.isPopular));
    const otherResorts = computed(() => unref(filteredRepository).filter((resort) => !resort.isPopular));

    return {
        filteredRepository,
        popularResorts,
        otherResorts,
    };
}
