import 'jquery.cookie';

import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';
import getABTestValue from '@/js/common/Domain/Service/getABTestValue';
import { TrackJS } from 'trackjs';
import requestService from './request';

export default (tourCriteriaForTourRequest, callback) => {
    const tourCriteria = _.clone(tourCriteriaForTourRequest);

    if ($.cookie('markSearchSource')) {
        tourCriteria.source = 'userSearch';
        $.removeCookie('markSearchSource', { path: '/', domain: wildcardDomain() });
    }

    TrackJS.console.log('Data for request in session');
    TrackJS.console.log(tourCriteriaForTourRequest);

    const options = {
        cache: false,
        data: {
            ...tourCriteria,
            abTests: getABTestValue(),
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        type: 'POST',
        url: `${window.appConfig.apiGatewayUrl}/frontend/tours/asyncSearch`,
    };

    return requestService(options, callback);
};