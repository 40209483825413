
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { AvailableClasses } from './types';

    @Component({
        name: 'LoaderComponent',
    })
    export default class extends Vue {
        @Prop({ default: 'small' }) size!: string;

        @Prop({ default: 'inline' }) type!: string;

        @Prop({ default: '' }) borderColor!: string;

        get classes(): AvailableClasses {
            const classes: AvailableClasses = {};

            switch (this.size) {
                case 'medium':
                    classes['loader-component__loader_medium'] = true;
                    break;
                case 'large':
                    classes['loader-component__loader_large'] = true;
                    break;
                case 'extra-small':
                    classes['loader-component__loader_extra-small'] = true;
                    break;
                case 'medium-extra':
                    classes['loader-component__loader_medium-extra'] = true;
                    break;
                default:
                    classes['loader-component__loader_small'] = true;
            }

            if (this.type === 'block') {
                classes['loader-component_block'] = true;
            }

            if (this.borderColor === 'white') {
                classes['loader-component__loader_border-color-white'] = true;
            }

            return classes;
        }
    }
