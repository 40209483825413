
    import { defineComponent, ref } from 'vue';
    import CheckBoxInput from '@ve/components/inputs/CheckBoxInput.vue';

    export default defineComponent({
        name: 'hotel-item',
        components: {
            CheckBoxInput,
        },
        props: {
            showClick: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, context) {
            const { showClick } = props;
            const isClicked = ref<boolean>(showClick);
            const clickItemHandler = () => {
                context.emit('clickItem');
            };
            return {
                clickItemHandler,
                isClicked,
            };
        },
    });
