import popularityHotels from './popularityHotels';

export default function (hotels) {
    if (!hotels) return [];

    hotels = _.filter(hotels, (hotel) => {
        const idHotel = hotel.get('id');
        const isHotelPopularity = popularityHotels[+idHotel];
        return !!isHotelPopularity;
    });
    return hotels;
}