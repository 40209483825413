import { format, parse } from 'date-fns';

export default Backbone.Epoxy.Model.extend({
    defaults: {
        checkInDate: null,
        departureCity: null,
        country: null,
        discount: null,
        hotel: null,
        sortRate: null,
        meal: null,
        nights: null,
        nightsInHotel: null,
        operator: null,
        originalPrice: null,
        price: null,
        priceWithOilTax: null,
        resort: null,
        roomDescription: null,
        routes: null,
        oilTax: null,
        oilTaxIsAbsent: null,
        hotelAvailable: null,
        touristGroup: null,
        transfers: null,
        transfer: null,
        availableTransfers: null,
        lessPlaces: null,
        flightType: null,
        freeCancellation: false,
    },

    computeds: {
        startDate: {
            deps: ['routes', 'checkInDate'],
            get: (routes, checkInDate) => {
                const routeStartDate = routes && routes.length && routes[0].there?.legs?.[0].departureDate ? routes[0].there.legs[0].departureDate : null;

                if (routeStartDate) {
                    return format(parse(routeStartDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy-MM-dd');
                }

                return checkInDate;
            },
        },
        paymentCashBackTour: {
            deps: ['priceWithOilTax'],
            get(priceWithOilTax) {
                const maxCashBackMir = 20000;
                const paymentCashBackMirTour = Math.ceil(priceWithOilTax * 0.8);
                const cashBackMir = priceWithOilTax - paymentCashBackMirTour;
                const paymentMaxCashBack = (cashBackMir > maxCashBackMir) ? priceWithOilTax - maxCashBackMir : paymentCashBackMirTour;
                return paymentMaxCashBack;
            },
        },
    },

});
