import userInterestsService from '../userInterests';
import trackingDefaultDataTypesHydrator from './trackingDefaultDataTypes';
import trackingTourCriteriaHydrator from './trackingTourCriteria';
import getHotelsWithoutOil from './getHotelsWithoutOil';
import getHotelsWithSamePrice from './trackingSearchData/getHotelsWithSamePrice';

const trackingSearchDataHydrator = async (options, uuid) => {
    const trackingSearchData = {};
    const timeFirstTours = options.firstCacheTime ? Math.round((options.firstCacheTime - options.timestamp) / 1000) : 0;
    const timeSearchFinished = options.searchFinishedTime ? Math.round((options.searchFinishedTime - options.timestamp) / 1000) : 0;
    const foundHotels = options?.foundHotels || [];
    const firstHotelsPrices = options?.firstHotelsPrices || {};
    const hotelsLength = foundHotels.length || 0;
    const foundTourProducts = options.foundTourProducts ? options.foundTourProducts.models : [];
    const { scannedTours } = options;
    const { error } = options;
    const trackingDefaultDataTypes = await trackingDefaultDataTypesHydrator(options);
    const trackingTourCriteria = trackingTourCriteriaHydrator(options);
    const operators = _.countBy(foundTourProducts, (tourProduct) => tourProduct.get('tour').get('operator').id);
    const firstCheapestHotels = getHotelsWithSamePrice({
        firstHotelsPrices,
        foundHotels,
    });
    const firstCheapestHotelsLength = firstCheapestHotels?.length || 0;

    _.extend(trackingSearchData, {
        productType: 'TourProduct',
        searchUuid: uuid,
        timeFirstTours,
        timeSearchFinished,
        hotelsLength,
        firstCheapestHotelsLength,
        scannedTours,
        operators: JSON.stringify(operators),
        operatorsTime: JSON.stringify(options.operatorsTime || {}),
        hasPersonalProfile: userInterestsService.getInterestsGroups().length ? 1 : 0,
        error,
    }, trackingDefaultDataTypes, trackingTourCriteria, getHotelsWithoutOil(foundHotels));

    return trackingSearchData;
};

export default trackingSearchDataHydrator;