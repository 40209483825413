import getABTestValue, { IgetAbTestValue } from '@/js/common/Domain/Service/getABTestValue';
import { ExWindow } from '@ve/types';

declare let window: ExWindow;

const getAbTestsByDataLayer = (abTests: IgetAbTestValue[]): Record<string, string> => {
    const MAX_AB_TEST_AMOUNT = 10;
    const array = [...Array(MAX_AB_TEST_AMOUNT).keys()];

    return array.reduce((acc, index) => {
        const defaultValue = 'a_0';
        const abTest = abTests[index];
        const prettifiedAbTest = abTest
            ? `${abTest.version}_${abTest.uuid}`
            : defaultValue;

        return { ...acc, [`ab_uuid_${index + 1}`]: prettifiedAbTest };
    }, {});
};

export default (): void => {
    window.dataLayer = window.dataLayer || [];

    const abTestsData = getAbTestsByDataLayer(getABTestValue() || []);
    const dataLayersData = {
        event: 'exp_def_n',
        pType: window.appConfig.pageType || 'infoPage',
        ...abTestsData,
    };

    window.dataLayer.push(dataLayersData);
};
