/* eslint-disable */
import ResortEntity from '../Entity/Resort';
import EntityManager from '../EntityManager';
import ResortFactory from '../Factory/Resort';

const ResortsRepository = Backbone.Collection.extend({
    model: ResortEntity,
    addEntry(resorts, additionalParams) {
        let resortsModelsArray = [];
            const entityManager = new EntityManager();

        resortsModelsArray = entityManager.add({
            factory: ResortFactory,
            repository,
        }, resorts, additionalParams);

        this.add(resortsModelsArray);
    },
    getById(resortsIds) {
        return resortsIds.map((idResort) => this.get(idResort));
    }
});

let repository = new ResortsRepository();

repository.init = function (resorts, defaultCountrySelected) {
    const resortsModelsArray = [];
        const CountriesRepository = require('./Countries').default;
        let country = CountriesRepository.repository.get(defaultCountrySelected);

    _.each(resorts, (resort) => {
        resort.country = defaultCountrySelected;
        const resortModel = new ResortFactory(resort);
        resortsModelsArray.push(resortModel);
    });
    this.set(resortsModelsArray);

    if (defaultCountrySelected) {
        if (!country) {
            CountriesRepository.repository.add({ id: defaultCountrySelected });
            country = CountriesRepository.repository.get(defaultCountrySelected);
        }
        country.set('resorts', new ResortsRepository(resortsModelsArray));
    }
};

ResortsRepository.repository = repository;

export default ResortsRepository;