import Backbone from 'backbone';
import { AsyncSearchStatus, ISearch } from '@/js/common/Domain/Service/search/types/ISearch';
import { ISearchOptions } from '@/js/common/Domain/Service/search/types/ISearchOptions';
import { Timeout } from '@ve/types/Timeout';

type jqXHR = JQuery.jqXHR;

type RequestType = 'sync' | 'async';

const XHR_NOT_SENT = 0;
const XHR_IS_DONE = 4;

export default abstract class AbstractSearch implements ISearch {
    hotelsRepositorySync?: Backbone.Collection;

    ajaxRequests: jqXHR[] = [];

    syncAjaxRequests: jqXHR[] = [];

    asyncAjaxRequests: jqXHR[] = [];

    statusTimeouts: Timeout = [];

    globalStatusTimeouts: Timeout = [];

    requestTimeouts: Timeout = [];

    startSearchTime: number | null = null;

    firstCacheTime: number | null = null;

    searchFinishedTime: number | null = null;

    asyncStatus: AsyncSearchStatus = { jobsCount: 0, jobsSuccess: 0, operators: {} };

    abstract getOffersData(options: ISearchOptions): void;

    public getAsyncStatus(): AsyncSearchStatus {
        return this.asyncStatus;
    }

    protected reset(): void {
        this.abortAllAjax();
        this.clearAllTimeouts();
        this.clearRepositories();
    }

    private isRequestCompleted(request: jqXHR) {
        return request.readyState === XHR_IS_DONE || request.readyState === XHR_NOT_SENT;
    }

    protected allAjaxAreCompleted(type: RequestType): boolean {
        const requests = type === 'async' ? this.asyncAjaxRequests : this.syncAjaxRequests;
        let allAreCompleted = true;
        requests.forEach((request) => {
            if (!this.isRequestCompleted(request)) {
                allAreCompleted = false;
            }
        });
        return allAreCompleted;
    }

    protected clearRepositories(): void {
        this.hotelsRepositorySync?.reset();
    }

    private clearAllTimeouts() :void {
        this.statusTimeouts.forEach((statusTimeout) => {
            clearTimeout(statusTimeout);
        });
        this.globalStatusTimeouts.forEach((globalStatusTimeout) => {
            clearTimeout(globalStatusTimeout);
        });
        this.statusTimeouts = [];
        this.globalStatusTimeouts = [];
        this.clearRequestTimeouts();

        this.startSearchTime = null;
        this.firstCacheTime = null;
        this.searchFinishedTime = null;
    }

    protected clearRequestTimeouts(): void {
        this.requestTimeouts.forEach((requestTimeout) => {
            clearTimeout(requestTimeout);
        });
        this.requestTimeouts = [];
    }

    private abortAllAjax(): void {
        this.ajaxRequests.forEach((ajaxRequest) => {
            if (ajaxRequest.readyState !== XHR_IS_DONE) {
                ajaxRequest.abort();
            }
        });
        this.ajaxRequests.length = 0;
        this.syncAjaxRequests.length = 0;
        this.asyncAjaxRequests.length = 0;
        this.asyncStatus = { jobsCount: 0, jobsSuccess: 0, operators: {} };
    }
}