/* eslint-disable */
var lotteryData = null,
	lotteryStatusAjax = false;

let checkLotteryService = function(callback) {
	var self = this,
		getLotteryStatus = function() {
			var options = {
				url:window.appConfig.gatewayUrl + '/apiV1/lottery/findActive',
				dataType: 'jsonp',
				data: {
					isMobile:checkLotteryService.isMobile ? 1 : 0
				}
			};

			lotteryStatusAjax = $.ajax(options);
		};

	callback = callback ? callback : function(){};

	if(_.isNull(lotteryData)) {
		if(!lotteryStatusAjax) {
			getLotteryStatus();
		}

		$.when(lotteryStatusAjax).then(function(response) {
				if(response.success && response.data) {
				lotteryData = response.data;
			} else {
				lotteryData = false;
			}
			callback(lotteryData);
		}, function() {
			lotteryData = false;
			callback(lotteryData);
		});
	} else {
		callback(lotteryData);
	}

	return lotteryData;
};

checkLotteryService.isMobile = false;

setTimeout(function() {
	checkLotteryService();
}, 1);

export default checkLotteryService;