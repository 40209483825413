import _ from 'underscore';
import {
 ApiRoute, ApiTourSeprHotel, TourProduct, Tariff, ApiLeg, FirstPaymentDefinition, Airline,
} from '@ve/types';
import matchRoutesByIds from '@/js/common/Domain/Service/searchTour/service/apiTourToTourAdapter/matchRoutesByIds';
import matchFirstPaymentDefinitionsByIds
    from '@/js/common/Domain/Service/searchTour/service/apiTourToTourAdapter/matchFirstPaymentDefinitionsByIds';
import apiTourToTourAdapter from '../searchTour/service/apiTourToTourAdapter';

export default ({
 tours, routes, tariffs, legs, airlines, firstPaymentDefinitions,
}: {
    tours: ApiTourSeprHotel[],
    routes: ApiRoute[],
    tariffs: Tariff[],
    legs: ApiLeg[],
    airlines: Airline[],
    firstPaymentDefinitions: FirstPaymentDefinition[]
}): TourProduct[] => {
    const routesKeys = _.indexBy(routes, 'id');
    const firstPaymentDefinitionsKeys = _.indexBy(firstPaymentDefinitions, 'id');

    return _.map(tours, (tour) => apiTourToTourAdapter({
        ...tour,
        firstPaymentDefinitions: matchFirstPaymentDefinitionsByIds({
            firstPaymentDefinitionIds: tour.firstPaymentDefinitions,
            firstPaymentDefinitionReferenceIndex: firstPaymentDefinitionsKeys,
        }),
        routes: matchRoutesByIds({
            routeIds: tour.routes,
            routeReferenceIndex: routesKeys,
        }),
        tariffs,
        legs,
        airlines,
    }));
};
