import CountryEntity from '@/js/common/Domain/Entity/Country';
import ResortEntity from '@/js/common/Domain/Entity/Resort';
import HotelEntity from '@/js/common/Domain/Entity/Hotel';
import trackingDefaultDataTypesHydrator from './trackingDefaultDataTypes';

const trackIntelligentSearchDataHydrator = async (options) => {
    const { selectedData } = options;
    const id = selectedData.get('id');
    const term = options.inputValue;
    const timestamp = new Date().getTime();
    const trackingDefaultDataTypes = await trackingDefaultDataTypesHydrator({ timestamp });
    let type;

    if (selectedData instanceof CountryEntity) {
        type = 'country';
    } else if (selectedData instanceof ResortEntity) {
        type = 'resort';
    } else if (selectedData instanceof HotelEntity) {
        type = 'hotel';
    }

    return {
        type,
        id,
        term,
        ...trackingDefaultDataTypes,
        /*Clear ab_identity_value types to prevent bug because ab_identity_value in GBQ have int type instead of string*/
        ab_identity: null,
        ab_identity_value: null,
    };
};

export default trackIntelligentSearchDataHydrator;