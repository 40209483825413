export default (tourCriteria) => {
    const tourCriteriaJson = {};
    const element = tourCriteria.get('countries');
    if (element) {
        if (element.length) {
            tourCriteriaJson.country = element.at(0).get('id');
        }
    }

    return tourCriteriaJson;
};