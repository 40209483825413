/* eslint-disable */
import {extendDecorator, viewModelDecorator} from '@/js/common/Domain/Service/mixins/decorators';

import './tooltipPopover.scss';

@viewModelDecorator({
    defaults: {
        show: false,
        contentHtml: '',
    },
})
@extendDecorator({
    el: "<div class='tooltipPopover'><div class='tooltipPopover__inner'></div></div>",
    bindings: {
        ':el': 'toggle: show',
        '.tooltipPopover__inner': 'html: contentHtml',
    },
})
export default class TooltipPopover extends Backbone.Epoxy.View {
    initialize(options) {
        this.initModelEvents(options);
        this.initDomEvents(options);
        this.setPosition(options.position);
        this.render(options);
    }

    initModelEvents(options) {
        if (options.model && options.htmlField) {
            this.viewModel.mapState({
                contentHtml: options.htmlField,
            }, options.model);
        }
    }

    initDomEvents(options) {
        const $parentElement = options.$activateElement;
        if ($parentElement && $parentElement.length) {
            $parentElement
                .on('mouseenter', () => this.viewModel.set('show', true))
                .on('mouseleave', () => this.viewModel.set('show', false));
        }
    }

    setPosition(position) {
        switch (position) {
            case 'bottom': {
                this.$el.addClass('tooltipPopover-positionBottom');
                break;
            }
            default:
                this.$el.addClass('tooltipPopover-positionTop');
        }
    }

    render(options) {
        const $parentElement = options.$activateElement;
        if ($parentElement && $parentElement.length) {
            if ($parentElement.css('position') === 'static' || $parentElement.css('position') === '') {
                $parentElement.css('position', 'relative');
            }

            $parentElement.append(this.$el);
        }
    }
}