/* eslint-disable */
export const sendGenLidOrder = options => new Promise((resolve, reject) => {
		$.ajax({
			url: '/order/leaveGeneralRequest',
			data: options,
			dataType: 'json',
			type: 'POST',
		}).done(response => {
			if (response.success === true) {
				resolve(response);
				$.ajax({
					url: '/subscriber/addGenLead',
					data: { email: options.customer.email },
					dataType: 'json',
					type: 'POST',
				});
			} else {
				console.log('Something wrong');
				console.log(response.message);
				reject();
			}
		}).error(e => {
			console.log('Something wrong! AJAX ERROR! ', e);
			reject();
		});
	});
