import { useState, useMutations } from 'vuex-composition-helpers';
import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';

import NightsRangeStore, { NAMESPACE as NIGHTS_RANGE_STORE } from '@entities/searchCriteria/nightsRange/model/NightsRangeStore';
import {
    INightRangeStoreMutations,
    INightRangeStoreState,
} from '../types/INightsRangeStore';

export default (modelPrefix: string) => {
    registerModule.register(getStore(), NightsRangeStore, `${NIGHTS_RANGE_STORE}${modelPrefix}`);
    const { nightRange } = useState<INightRangeStoreState>(`${NIGHTS_RANGE_STORE}${modelPrefix}`, ['nightRange']);
    const { setNightsRange } = useMutations<INightRangeStoreMutations>(`${NIGHTS_RANGE_STORE}${modelPrefix}`, ['setNightsRange']);

    return {
        nightRange,
        setNightsRange,
    };
};