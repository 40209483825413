/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';
import ResortEntity from "../Entity/Resort";
import gaAsync from "./gaAsync";
import getPageTypeService from "./getPageType";


window.dataLayer = window.dataLayer || [];

let ecommerceService = {
	getData:function(params) {
		var hotel = params.product,
			category = params.category,
			departureCityName = params.departureCityName,
			position = params.position,
			firstOffer,
			id,
			resort,
			resortName,
			country,
			countryName,
			dateFrom,
			nights,
			touristGroup,
			adults,
			kids,
			roomName,
			mealName,
			operatorName,
			infants;

		resort = hotel.get('resort');
		resortName = resort instanceof ResortEntity ? resort.get('name') : (resort ? resort.name : null);
		country = resort instanceof ResortEntity ? resort.get('country') : hotel.get('country');
		countryName = country ? country.get('name') : '';

		switch(category) {
			case 'tour':
				firstOffer = hotel.get('tourProducts').at(0);
				id = firstOffer.get('id');
				dateFrom = moment(firstOffer.get('tour').get('checkInDateRange').get('from')).format('DD_MM_YYYY');
				nights = firstOffer.get('tour').get('nights');
				touristGroup = firstOffer.get('tour').get('touristGroup');
				roomName = firstOffer.get('tour').get('room').name;
				mealName = firstOffer.get('tour').get('meal').get('name');
				adults = touristGroup.adults;
				kids = touristGroup.kids;
				infants = touristGroup.infants;
				operatorName = firstOffer.get('tour').get('operator').name;
				break;

			case 'hotel':
				firstOffer = hotel.get('hotelOffers').at(0);
				id = firstOffer.get('identity');
				dateFrom = moment(firstOffer.get('tourCriteria').get('checkInDateRange').get('from')).format('DD_MM_YYYY');
				nights = firstOffer.get('tourCriteria').get('nightRange').get('from');
				touristGroup = firstOffer.get('tourCriteria').get('touristGroup');
				roomName = firstOffer.get('roomName');
				mealName = firstOffer.get('meal').get('name');
				adults = touristGroup.get('adults');
				kids = touristGroup.get('kids');
				infants = touristGroup.get('infants');
				operatorName = "Biblio Globus";
				break;
		}

		return {
			id: id,
			name: hotel.get('name') + '|' + hotel.get('id'),
			category: departureCityName + '/' + countryName + '/' + resortName + '/' + hotel.get('category').get('name') + '/' + roomName + '|' + mealName,
			brand: operatorName,
			variant: nights + '|' + dateFrom + '|' + adults + '_' + kids + '_' + infants,
			price: hotel.get('minPrice'),
			position: position
		};
	},
	getHotelPageData:function(params) {
		var tourProduct = params.tourProduct,
			hotelInfo = params.hotelInfo,
			category = params.category,
			departureCityName = params.departureCityName,
			position = params.position,
			dateFrom,
			dateRange,
			nights,
			touristGroup,
			adults,
			kids,
			infants,
			price,
			id,
			operatorName,
			roomName,
			mealName;

		switch(category) {
			case 'tour':
				const product = tourProduct.get('product')
					|| tourProduct.get('tour')
					|| tourProduct.get('hotelOffer');

				nights = product.get('nights');
				dateFrom = moment(product.get('checkInDateRange').get('from')).format('DD_MM_YYYY');
				touristGroup = product.get('touristGroup');
				adults = touristGroup.adults;
				kids = touristGroup.kids;
				infants = touristGroup.infants;
				price = tourProduct.get('finalPrice');
				mealName = product.get('meal').get('name');
				id = tourProduct.get('id');
				operatorName = product.get('operator').name;
				roomName = product.get('room').name;
				break;

			case 'hotel':
				dateRange = tourProduct.get('tourCriteria').get('dateRange');
				nights = moment(dateRange.get('to')).diff(dateRange.get('from'), 'days');
				dateFrom = moment(dateRange.get('from')).format('DD_MM_YYYY');
				adults = tourProduct.get('adults');
				kids = tourProduct.get('kids');
				infants = tourProduct.get('infants');
				roomName = tourProduct.get('roomName');
				mealName = tourProduct.get('meal').get('name');
				price = tourProduct.get('price');
				id = tourProduct.get('identity');
				operatorName = "Biblio Globus";
				break;
		}

		return {
			id:id,
			name: hotelInfo.name + '|' + hotelInfo.id ,
			category: departureCityName  + '/'+ hotelInfo.countryName + '/' + hotelInfo.resortName + '/' + hotelInfo.categoryText + '/' + roomName + '|' + mealName,
			brand: operatorName,
			variant: nights + '|' + dateFrom + '|'  + adults + '_' + kids + '_' + infants,
			price:price,
			position:position
		};
	},
	impressions: function(params) {
		var impressions = [],
			hotels = params.hotels ? params.hotels : [],
			departureCityName = params.departureCityName,
			collection = params.collection,
			category = params.category,
			pageType = params.pageType,
			self = this;

		if(hotels && hotels.length) {
			impressions = hotels.map(function (hotel) {
				return $.extend({}, self.getData({
					product: hotel,
					category: category,
					departureCityName: departureCityName,
					position:collection.indexOf(hotel)
				}), {
					list: pageType
				});
			});


			dataLayer.push({
				'ecommerce': {
					'currencyCode': 'RUB',
					'impressions': impressions
				}
			});

			if (typeof(gaAsync) !== 'undefined') {
				gaAsync(function () {
					ga('set', 'currencyCode', 'RUB');

					_.each(impressions, function (impression) {
						ga('ec:addImpression', impression);
					});

					ga('send', 'pageview');
				});
			}
		}
	},
	productClick: function(params) {
		var product = params.product,
			category = params.category,
			departureCityName = params.departureCityName,
			collection = params.collection,
			pageType = params.pageType,
			ecProduct = this.getData({
				product:product,
				category:category,
				departureCityName:departureCityName,
				position:collection.indexOf(product)
			});

		dataLayer.push({
			'event': 'productClick',
			'ecommerce': {
				'click': {
					'actionField': {list: pageType},      // Optional list property.
					'products': [ecProduct]
				}
			}
		});

		if(typeof(gaAsync) !== 'undefined' && product) {
			gaAsync(function() {
				ga('ec:addProduct', ecProduct);
				ga('ec:setAction', 'click', {list: pageType});
				ga('send', 'event', 'UX', 'click', 'Results');
			});
		}
	},
	productClickHotel: function(params) {
		var product = params.product,
			category = params.category,
			collection = params.collection,
			departureCityName = params.departureCityName,
			pageType = params.pageType,
			ecProduct = this.getHotelPageData({
				tourProduct:product,
				category:category,
				hotelInfo:hotelInfo,
				departureCityName:departureCityName,
				position: collection.indexOf(product)
			});

		dataLayer.push({
			'event': 'productClick',
			'ecommerce': {
				'click': {
					'actionField': {list: pageType},      // Optional list property.
					'products': [ecProduct]
				}
			}
		});

		if(typeof(gaAsync) !== 'undefined' && product) {
			gaAsync(function() {
				ga('ec:addProduct', ecProduct);
				ga('ec:setAction', 'click', {list: pageType});
				ga('send', 'event', 'UX', 'click', 'Results');
			});
		}
	},
	hotelImpressions:function(params) {
		var impressions = [],
			tourProducts = params.tourProducts ? params.tourProducts : [],
			collection = params.collection,
			departureCityName = params.departureCityName,
			category = params.category,
			pageType = params.pageType,
			hotelInfo = params.hotelInfo,
			self = this;

		if(typeof(gaAsync) !== 'undefined' && tourProducts && tourProducts.length) {
			impressions = tourProducts.map(function(tourProduct) {
				return $.extend({}, self.getHotelPageData({
					tourProduct:tourProduct,
					category:category,
					hotelInfo:hotelInfo,
					departureCityName:departureCityName,
					position: collection.indexOf(tourProduct)
				}), {
					list: pageType
				});
			});

			dataLayer.push({
				'ecommerce': {
					'currencyCode': 'RUB',
					'impressions': impressions
				}
			});
			gaAsync(function(){
				ga('set', 'currencyCode', 'RUB');

				_.each(impressions, function(impression) {
					ga('ec:addImpression', impression);
				});

				ga('send', 'pageview');
			});
		}
	},
	lotteryImpressions: function(data) {
		var pageType = getPageTypeService();
		dataLayer.push({
			'ecommerce': {
				'promoView': {
					'promotions': [             // Array of promoFieldObjects.
						{
							'id': data.lotteryId,         // ID or Name is required.
							'name': 'lottery',    // Дата окончания розыгрыша
							'creative': data.promotionType, // Берем из описаний аттача отсюда https://jira.travelata.ru/browse/TRAV-5145 все до .png
							'position': pageType  // Берем стандартный тип страницы, например main, serp, hotelpage
						}]
				}
			}
		});
	},
	lotteryClick: function(data) {
		var pageType = getPageTypeService();
		dataLayer.push({
			'event': 'promotionClick',
			'ecommerce': {
				'promoClick': {
					'promotions': [
						{
							'id': data.lotteryId,
							'name': 'lottery',
							'creative': data.promotionType,
							'position': pageType
						}]
				}
			}
		});
	},
	productDetails: function(params) {
		var tourProduct = params.tourProduct,
			category = params.category,
			hotelInfo = params.hotelInfo,
			departureCityName = params.departureCityName,
			pageType = params.pageType,
			self = this,
			data;

		if(typeof(gaAsync) !== 'undefined' && tourProduct) {
			data = self.getHotelPageData({
				tourProduct:tourProduct,
				category:category,
				hotelInfo:hotelInfo,
				departureCityName:departureCityName,
				position: 0
			});
			if(params.deletePosition){
				delete data.position
			}
			dataLayer.push({
				'ecommerce': {
					'detail': {
						'actionField': {'list': pageType},    // 'detail' actions have an optional list property.
						'products': [data]
					}
				}
			});

			gaAsync(function(){
				ga('set', 'currencyCode', 'RUB');

				ga('ec:addProduct', data);
				ga('ec:setAction', 'detail', {
					'list': pageType
				});

				ga('send', 'pageview');
			});
		}
	},
	toursLoadCompleteEvent:function() {
		dataLayer.push({
				'event': 'tourLoadComplete'
		});
	},
	addTourToCart: function(params) {
		var tourProduct = params.tourProduct,
			hotelInfo = params.hotelInfo,
			category = params.category,
			departureCityName = params.departureCityName,
			self = this,
			ecProduct;

		if(tourProduct) {
			ecProduct = $.extend({}, self.getHotelPageData({
				tourProduct:tourProduct,
				category:category,
				hotelInfo:hotelInfo,
				departureCityName:departureCityName,
				position: 0
			}), {
				quantity: 1
			});
			if(params.deletePosition){
				delete ecProduct.position
			}
			dataLayer.push({
				'event': 'addToCart',
				'ecommerce': {
					'currencyCode': 'RUB',
					'add': {                                // 'add' actionFieldObject measures.
						'products': [ecProduct]
					}
				}
			});

			if(typeof(gaAsync) !== 'undefined') {
				gaAsync(function () {
					ga('set', 'currencyCode', 'RUB');

					ga('ec:addProduct', ecProduct);

					ga('ec:setAction', 'add', {
						'list': category
					});

					ga('send', 'event', 'UX', 'click', 'add to cart');
				});
			}
		}
	}
};

export default ecommerceService;