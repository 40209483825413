
    import {
        defineComponent, computed, unref,
    } from 'vue';
    import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import { useState } from 'vuex-composition-helpers';
    import WarrantedFilter from '@ve/components/filters/warrantedFilter/desktop/WarrantedFilter.vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import SingleListFilter from '@ve/components/searchFilters/filterList/desktop/SingleListFilter.vue';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import useVueProxy from '@ve/services/useVueProxy';

    export default defineComponent({
        name: 'WarrantedFilterDesktopSerp',
        components: {
            WarrantedFilter,
            FilterListItem,
            SingleListFilter,
        },
        setup() {
            const { $root } = useVueProxy();
            const { quickConfirmation } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['quickConfirmation']);
            const isFilterSelected = computed(() => unref(quickConfirmation));
            const startSearchByQuickInformation = (value: boolean) => {
                $root?.$emit('start-search-by-quick-confirmation', value);
            };
            return {
                isFilterSelected,
                startSearchByQuickInformation,
            };
        },
    });
