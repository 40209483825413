import { NAMESPACE as MAP_FOR_SEARCH_DATA } from '@ve/pages/serp/store/SearchDataStore';
import { NAMESPACE as MAP_FOR_PRODUCTS_SEARCH } from '@ve/pages/serp/store/productsSearchStore';
import { NAMESPACE as MAP_FOR_PRICE_CALENDAR } from '@ve/components/priceCalendar/common/store/priceCalendarStore';
import { NAMESPACE as ALTERNATIVE_SEARCH_STORE_NAME } from '@ve/components/alternativeDeparture/common/store/AlternativeSearchStore';
import { NAMESPACE as SERP_PAGE_STATE } from '@ve/pages/serp/store/serpPageStateStore';

import type {
    IEmptySearchStore,
} from './types/IEmptySearchStore';

import calculateNextStateTransition from './stateTransitions';

export const NAMESPACE = 'emptySearchStore';
export default {
    namespaced: true,
    state: () => ({
        currentState: 'userSearch',
    }),
    getters: {
        currentStateIsAlternativeSearchAndHasTours(state) {
            return state.currentState === 'alternativeSearchHasTours';
        },
    },
    mutations: {
        setCurrentState(state, newState) {
            state.currentState = newState;
        },
    },
    actions: {
        async startNewUserSearch({ commit }) {
            commit('setCurrentState', 'userSearch');
        },

        goNextState({
            commit,
            state,
            rootState,
            rootGetters,
        }) {
            const { currentState } = state;
            const loadingStateIsFinished = rootState[MAP_FOR_PRODUCTS_SEARCH]?.loadingState === 'finished';
            const hasTours = rootGetters[`${MAP_FOR_PRODUCTS_SEARCH}/hasProductsAfterLoading`];
            const calendarMinPriceDate = rootGetters[`${MAP_FOR_PRICE_CALENDAR}/minPriceDate`];
            const checkInDateRangeIsFlexible = rootGetters[`${MAP_FOR_SEARCH_DATA}/checkInDateRangeIsFlexible`];
            const prevDepartureCityIsDifferentFromCurrentDepartureCity = rootGetters[`${ALTERNATIVE_SEARCH_STORE_NAME}/prevDepartureCityIsDifferentFromCurrentDepartureCity`];
            const covidBanner = rootState[SERP_PAGE_STATE]?.covidBanner;
            const hasPriceCalendar = !!rootState[MAP_FOR_PRICE_CALENDAR];

            if (covidBanner) return;

            const changedState = calculateNextStateTransition({
                currentState,
                loadingStateIsFinished,
                prevDepartureCityIsDifferentFromCurrentDepartureCity,
                hasTours: !!hasTours,
                hasCalendarMinPriceDate: !!calendarMinPriceDate,
                searchWithFlexibleDates: !!checkInDateRangeIsFlexible,
                hasPriceCalendar,
            });

            if (changedState) {
                commit('setCurrentState', changedState);
            }
        },
    },
} as IEmptySearchStore;
