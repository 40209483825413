import { Baggage } from '@ve/types/api/Baggage';

export default (baggage: Baggage): boolean | null => {
    if (baggage?.status === 'notIncluded') {
        return false;
    } if (baggage?.status === 'included') {
        return true;
    }

    return null;
};