import TourCriteriaObjectValue from '../ObjectValue/TourCriteria';
import SortFactory from './Sort';
import TouristGroupFactory from './TouristGroup';
import EntityManager from '../EntityManager';
import NightRangeFactory from './NightRange';
import CheckInDateRangeFactory from './checkInDateRange/CheckInDateRange';
import PriceRangeFactory from './PriceRange';
import errorHandlerService from '../Service/errorHandler';
import CountriesRepository from '../Repository/Countries';
import ResortsRepository from '../Repository/Resorts';
import HotelsRepository from '../Repository/Hotels';
import MealsRepository from '../Repository/Meals';
import HotelCategoriesRepository from '../Repository/HotelCategories';
import DepartureCitiesRepository from '../Repository/DepartureCities';
import OperatorsRepository from '../Repository/Operators';

const TourCriteriaFactory = function (data, options) {
    const tourCriteria = new TourCriteriaObjectValue();
    const defaultTourCriteriaInner = options && options.defaultTourCriteria ? options.defaultTourCriteria : undefined;
    const entityManager = new EntityManager();
    let departureCity;
    let availableCountries;
    let availableResorts;
    let countries;
    let resorts;
    let hotelCategories;
    let meals;
    let operators;
    let hotels;
    let element;

    element = data.sort;
    if (element) {
        tourCriteria.set('sort', new SortFactory(element));
    }

    element = data.touristGroup;
    if (element) {
        tourCriteria.set('touristGroup', new TouristGroupFactory(element));
    }

    element = data.nightRange;
    if (element) {
        tourCriteria.set('nightRange', new NightRangeFactory(element));
    }

    element = data.checkInDateRange;
    if (element) {
        const superDateRange = {
            from: 7,
            to: 14,
        };
        const defaultTourCriteriaCheckInDateRange = window?.defaultTourCriteria?.checkInDateRange || superDateRange;

        tourCriteria.set('checkInDateRange',
            new CheckInDateRangeFactory(
                element,
                defaultTourCriteriaInner?.checkInDateRange || defaultTourCriteriaCheckInDateRange,
                options,
            ));
    }

    element = data.priceRange;
    if (element) {
        tourCriteria.set('priceRange', new PriceRangeFactory(element));
    }

    element = data.departureCity;
    if (element) {
        departureCity = entityManager.get(DepartureCitiesRepository, element);
        if (!departureCity) {
            errorHandlerService(`No departure city with id: ${element}`);
        }
        tourCriteria.set('departureCity', departureCity);
    }

    element = data.countries;
    if (_.isArray(element) && tourCriteria.get('departureCity') !== null) {
        countries = [];
        _.each(element, (countryId) => {
            const country = entityManager.get(CountriesRepository, countryId);
            if (!country) {
                errorHandlerService(`No country with id: ${countryId}`);
            } else {
                countries.push(country);
            }
        });

        tourCriteria.set('countries', new CountriesRepository(countries));
    }

    element = data.resorts;
    if (_.isArray(element) && tourCriteria.get('countries') !== null) {
        resorts = [];
        availableCountries = tourCriteria.get('countries');
        _.each(element, (resortId) => {
            const resort = entityManager.get(ResortsRepository, resortId);
            if (!resort) {
                errorHandlerService(`No resort with id: ${resortId}`);
            } else if (!availableCountries.contains(resort.get('country'))) {
                errorHandlerService(`No such resort with id: ${resortId} for this countries`);
            } else {
                resorts.push(resort);
            }
        });

        tourCriteria.set('resorts', new ResortsRepository(resorts));
    }

    element = data.hotels;
    if (_.isArray(element) && tourCriteria.get('countries') !== null) {
        hotels = [];
        availableResorts = tourCriteria.get('resorts');
        availableCountries = tourCriteria.get('countries');
        _.each(element, (hotelId) => {
            const hotel = entityManager.get(HotelsRepository, hotelId);
            if (!hotel) {
                errorHandlerService(`No hotel with id: ${hotelId}`);
            } else if (!availableCountries.contains(hotel.get('resort').get('country'))) {
                errorHandlerService(`No such hotel with id: ${hotel.get('id')} for this country`);
            } else if (availableResorts !== null && !availableResorts.contains(hotel.get('resort'))) {
                errorHandlerService(`No such hotel with id: ${hotel.get('id')} for this resorts`);
            } else {
                hotels.push(hotel);
            }
        });

        tourCriteria.set('hotels', new HotelsRepository(hotels));
    }

    element = data.meals;
    if (_.isArray(element)) {
        meals = [];
        _.each(element, (mealId) => {
            const meal = entityManager.get(MealsRepository, mealId);
            if (!meal) {
                if (mealId !== 'all') {
                    errorHandlerService(`No meal with id: ${mealId}`);
                }
            } else {
                meals.push(meal);
            }
        });

        tourCriteria.set('meals', new MealsRepository(meals));
    }

    element = data.operators;
    if (_.isArray(element)) {
        operators = [];
        _.each(element, (operatorId) => {
            let operator = entityManager.get(OperatorsRepository, operatorId);
            if (!operator) {
                errorHandlerService(`No operator with id: ${operatorId}`);
                //add fake operator if not exist
                operator = new Backbone.Model({
                    id: operatorId,
                });
            }

            operators.push(operator);
        });

        tourCriteria.set('operators', new OperatorsRepository(operators));
    }

    element = data.hotelCategories;
    if (_.isArray(element)) {
        hotelCategories = [];
        _.each(element, (hotelCategoryId) => {
            const hotelCategory = entityManager.get(HotelCategoriesRepository, hotelCategoryId);
            if (!hotelCategory) {
                if (hotelCategoryId !== 'all') {
                    errorHandlerService(`No hotelCategory with id: ${hotelCategoryId}`);
                }
            } else {
                hotelCategories.push(hotelCategory);
            }
        });

        tourCriteria.set('hotelCategories', new HotelCategoriesRepository(hotelCategories));
    }

    element = data.warranted;
    if (element) {
        tourCriteria.set('warranted', true);
    }

    element = data.isExclusive;
    if (element) {
        tourCriteria.set('isExclusive', true);
    }

    element = data.productType;
    if (element) {
        tourCriteria.set('productType', element);
    }

    element = data.directFlight;
    if (element) {
        tourCriteria.set('directFlight', element);
    }

    return tourCriteria;
};

export default TourCriteriaFactory;
