module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="lottery-banner" style="background-image:url(\''+
((__t=(bannerUrlProxied ))==null?'':__t)+
'\')">\r\n	<div class="lottery-banner__imagePart">\r\n		<div class="lottery-banner__rating">\r\n			<i class="lottery-banner__rating-likeIcon icon-i16_like"></i> '+
((__t=(hotel.rating ))==null?'':__t)+
'\r\n			<div class="lottery-banner__rating-reviewsCount">'+
((__t=(hotel.reviewsQty ))==null?'':__t)+
' отзыв'+
((__t=(hotel.reviewsQty.wordEnding(';а;ов') ))==null?'':__t)+
'</div>\r\n			<div class="lottery-banner__rating-luxury">luxury</div>\r\n		</div>\r\n		<div class="lottery-banner__hotel-title">\r\n			';
 if(hotel.stars) { 
__p+='\r\n			<div class="lottery-banner__hotel-stars">\r\n				';
 for(var i = 1; i<= hotel.stars; i++) { 
__p+='\r\n				<i class="icon icon-i16_features"></i>\r\n				';
 } 
__p+='\r\n			</div>\r\n			';
 } 
__p+='\r\n			<p>'+
((__t=( hotel.name ))==null?'':__t)+
'</p>\r\n			<div class="lottery-banner__hotel-resort">'+
((__t=( hotel.countryName ))==null?'':__t)+
', '+
((__t=( hotel.cityName ))==null?'':__t)+
'</div>\r\n		</div>\r\n		<div class="lottery-banner__vipTourLabel"></div>\r\n	</div>\r\n	<div class="lottery-banner__textPart">\r\n		<h2>Ваш тур мечты '+
((__t=(direction ))==null?'':__t)+
'!</h2>\r\n		<p>Беспроигрышная акция — каждому участнику скидка <b>'+
((__t=(couponAmount ))==null?'':__t)+
' рубл'+
((__t=(couponAmount.wordEnding('ь;я;ей') ))==null?'':__t)+
'</b></p>\r\n		<a href="'+
((__t=(checkoutLink ))==null?'':__t)+
'" target="_blank" class="lottery-banner__link">Выиграйте тур за '+
((__t=( price ))==null?'':__t)+
' рубл'+
((__t=(price.wordEnding('ь;я;ей') ))==null?'':__t)+
'</a>\r\n	</div>\r\n</div>';
}
return __p;
};
