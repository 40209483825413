/* eslint-disable */
import 'jquery.cookie';
import ls from 'local-storage';
import moment from 'moment';

import { getCountryCover, getHotelCover, getResortCover } from '@/js/common/Service/getCover';
import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';
import toSearchHistoryLocalStorageTourCriteriaHydrator
    from '@/js/common/Domain/Hydrator/toSearchHistoryLocalStorageTourCriteria';

let searchHistoryPrivate;

class SearchHistoryPrivate {
    constructor() {
        this.localStorageKey = 'tr_historySearch';
        this.searchData = {};
    }

    setSearchData(searchData) {
        this.searchData = searchData;
    }

    checkExpired(searchCriteria) {
        const currentDate = moment();

        return moment(searchCriteria.checkInDateRange.from, 'DD.MM.YYYY') > currentDate;
    }

    getActualHistory() {
        const historyFromLocalStorage = ls.get(this.localStorageKey);

        if (historyFromLocalStorage) {
            return _.filter(historyFromLocalStorage, this.checkExpired);
        }

        return [];
    }

    getDirectionName(direction) {
        return `${direction.pluck('name').join(', ')}`;
    }

    getDescription() {
        const { searchData } = this;

        const hotels = searchData.get('hotels');
        const resorts = searchData.get('resorts');

        if (hotels?.length) {
            return `${this.getDirectionName(hotels)} `;
        }

        if (resorts?.length === 1) {
            return this.getDirectionName(resorts);
        }

        const countries = searchData.get('countries');

        if (resorts?.length > 1) {
            return `${this.getDirectionName(countries)}, ${resorts.length} курорт${resorts.length.wordEnding(';а;ов')}`;
        }

        return `${this.getDirectionName(countries)}`;
    }

    findCurrentSearchInStore(localStorageSearches, searchDataForLocalStorage) {
        return _.find(localStorageSearches, (item) => {
            const itemWithoutCover = _.omit(item, ['cover', 'description']);

            return _.isEqual(itemWithoutCover, searchDataForLocalStorage);
        });
    }

    saveToLocalStorage() {
        const { searchData } = this;
        let localStorageSearches = this.getActualHistory();

        const searchDataForLocalStorage = toSearchHistoryLocalStorageTourCriteriaHydrator(searchData);
        const search = _.find(localStorageSearches,
            (localStorageSearch) => _.isEqual(localStorageSearch, searchDataForLocalStorage));
        const searchIndex = localStorageSearches.indexOf(search);
        const isFoundCurrentSearch = this.findCurrentSearchInStore(localStorageSearches, searchDataForLocalStorage);

        if (isFoundCurrentSearch) {
            return;
        }

        searchDataForLocalStorage.description = this.getDescription();

        if (search) {
            localStorageSearches.splice(searchIndex, 1);
        }

        localStorageSearches.unshift(searchDataForLocalStorage);
        localStorageSearches = localStorageSearches.slice(0, 5);

        ls.set(this.localStorageKey, localStorageSearches);
    }

    isCoverExist(search) {
        return !!search?.cover?.length;
    }

    isOneResort(search) {
        return search?.resorts?.length === 1;
    }

    isEmptyHotels(search) {
        return !search?.hotels?.length;
    }

    isOnlyResort(search) {
        return this.isOneResort(search)
            && this.isEmptyHotels(search);
    }

    updateSearchCountryCover(search, covers) {
        const isNotEmptyHotels = !this.isEmptyHotels(search);
        const isCoverExist = this.isCoverExist(search);
        const isOneResort = this.isOneResort(search);

        /**
         * Бизнес-кейс:
         * Если в тур критерии:
         * - одна страна;
         * - одна страна и несколько курортов ( > 1);
         * то обновить cover на cover страны.
         */
        if (isCoverExist || isNotEmptyHotels || isOneResort) {
            return search;
        }

        return {
            ...search,
            cover: covers[search?.countries[0]],
        };
    }

    updateSearchResortCover(search, covers) {
        const isNotOnlyResort = !this.isOnlyResort(search);
        const isCoverExist = this.isCoverExist(search);

        /**
         * Бизнес-кейс:
         * Если в тур критерии:
         * - один курорт;
         * то обновить cover на cover курорта.
         */
        if (isCoverExist || isNotOnlyResort) {
            return search;
        }

        return {
            ...search,
            cover: covers[search?.resorts?.[0]],
        };
    }

    updateSearchHotelCover(search, covers) {
        const isHotelsEmpty = this.isEmptyHotels(search);
        const isCoverExist = this.isCoverExist(search);

        /**
         * Бизнес-кейс:
         * Если в тур критерии:
         * - есть отель;
         * то обновить cover на cover отеля.
         */
        if (isCoverExist || isHotelsEmpty) {
            return search;
        }

        return {
            ...search,
            cover: covers[search?.hotels[0]],
        };
    }

    async findCovers(ids, asyncMethod) {
        const promises = _.map(ids, (countryId) => asyncMethod(countryId));
        const covers = await Promise.all(promises);

        return _.reduce(covers, (acc, cover, index) => {
            const countryId = ids[index];
            acc[countryId] = cover?.data || '';

            return acc;
        }, {});
    }

    async getCountriesCovers(searchesWithoutCover) {
        const countriesIds = _.reduce(searchesWithoutCover, (acc, searchItem) => {
            const isOneResorts = this.isOneResort(searchItem);
            const isEmptyHotels = this.isEmptyHotels(searchItem);

            if (isEmptyHotels && !isOneResorts) {
                const countryId = searchItem.countries[0];
                acc.push(countryId);
            }

            return acc;
        }, []);

        const uniqCountriesIds = _.uniq(countriesIds);
        return this.findCovers(uniqCountriesIds, getCountryCover);
    }

    async getResortsCovers(searchesWithoutCover) {
        const resortsIds = _.reduce(searchesWithoutCover, (acc, searchItem) => {
            const resortId = searchItem.resorts?.[0];
            const isOnlyResort = this.isOnlyResort(searchItem);

            if (isOnlyResort) {
                acc.push(resortId);
            }

            return acc;
        }, []);

        const uniqResortsIds = _.uniq(resortsIds);
        return this.findCovers(uniqResortsIds, getResortCover);
    }

    async getHotelsCovers(searchesWithoutCover) {
        const hotelsIds = _.reduce(searchesWithoutCover, (acc, searchItem) => {
            const hotelId = searchItem.hotels?.[0];
            const isHotelsExist = searchItem.hotels?.length > 0;

            if (isHotelsExist) {
                acc.push(hotelId);
            }

            return acc;
        }, []);

        const uniqHotelsIds = _.uniq(hotelsIds);
        return this.findCovers(uniqHotelsIds, getHotelCover);
    }

    async updateCoversSearches() {
        let localStorageSearches = this.getActualHistory();
        localStorageSearches = localStorageSearches.slice(0, 5);

        const searchesWithoutCover = _.filter(localStorageSearches,
            (localStorageSearch) => !localStorageSearch?.cover);

        const countriesCovers = await this.getCountriesCovers(searchesWithoutCover);
        const resortsCovers = await this.getResortsCovers(searchesWithoutCover);
        const hotelsCovers = await this.getHotelsCovers(searchesWithoutCover);

        const updatedLocalStorageSearch = _.map(localStorageSearches, (localStorageSearch) => {
            let updatedSearch = localStorageSearch;

            if (Object.keys(countriesCovers)?.length) {
                updatedSearch = this.updateSearchCountryCover(updatedSearch, countriesCovers);
            }

            if (Object.keys(resortsCovers)?.length) {
                updatedSearch = this.updateSearchResortCover(updatedSearch, resortsCovers);
            }

            if (Object.keys(hotelsCovers)?.length) {
                updatedSearch = this.updateSearchHotelCover(updatedSearch, hotelsCovers);
            }

            return updatedSearch;
        });

        ls.set(this.localStorageKey, updatedLocalStorageSearch);
    }

    saveLastSearch() {
        const { searchData } = this;
        const searchDataForLocalStorage = toSearchHistoryLocalStorageTourCriteriaHydrator(searchData);

        $.cookie('last_search_criteria', JSON.stringify(searchDataForLocalStorage), {
            domain: wildcardDomain(),
            expires: 1,
            path: '/',
        });
    }
}

searchHistoryPrivate = searchHistoryPrivate || new SearchHistoryPrivate();

export default {
    addSearch(searchData) {
        searchHistoryPrivate.setSearchData(searchData);

        searchHistoryPrivate.saveToLocalStorage();
        searchHistoryPrivate.saveLastSearch();
    },

    getLastSearches() {
        return searchHistoryPrivate.getActualHistory();
    },

    saveLastSearch(searchData) {
        searchHistoryPrivate.setSearchData(searchData);

        searchHistoryPrivate.saveLastSearch();
    },

    async updateCoversSearches() {
        await searchHistoryPrivate.updateCoversSearches();
    },
};
