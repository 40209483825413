import ls from 'local-storage';
import 'jquery.cookie';
import 'jcarousel';
import Vue from 'vue';

import { extendDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import gaAsync from '@/js/common/Domain/Service/gaAsync';
import searchSessionService from '@/js/common/Domain/Service/search/searchSession';
import trackingService from '@/js/common/Domain/Service/tracking/tracking';
import SerpTrackingService from '@ve/pages/serp/analytics/yandexAnalytics/serpTrackingService';
import mindBoxService from '@/js/common/Service/mindBoxService/MindBoxServiceDesktop';
import LifeHackBannerView from '@/js/common/components/lifeHackBanner/LifeHackBanner';
import checkLotteryService from '@/js/common/Domain/Service/checkLottery';
import toRouterHotelHydrator from '@/js/common/Domain/Hydrator/toRouterHotel';
import ecommerceService from '@/js/common/Domain/Service/ecommerce';
import LotteryBannerView from '@/js/desktop/components/search/lotteryBannerSerp/LotteryBanner';
import TourHunterSubscribeView from '@/js/desktop/components/search/tourHunterSubscribe/TourHunterSubscribe';
import ThFavoritesView from '@/js/desktop/components/thFavorites/thFavorites';
import { productTypes } from '@fr/constants';

import '@/js/desktop/View/searchPageHotel/serpHotelCard/serpHotelCard.scss';
import '@/js/common/components/reviewsColor/reviewsColor.css';
import '@/css/desktop/tooltip.scss';

import componentsBannerTemplate
    from '@view/desktop/travelata/layout/parts/search/search__tour-usually-includes-js.phtml';

import '@/js/desktop/components/search/useFilters/styles/useFilters.css';
import '@/js/desktop/components/search/useFilters/styles/useFiltersBnr.scss';
import filterBannerTemplate from '@/js/desktop/components/search/useFilters/search__use-filters-banner-js.phtml';

import appBannerTemplate from '@/js/desktop/components/search/appBanner';
import genleadBannerTemplate from '@view/desktop/travelata/layout/parts/search/search__regions-msg.phtml';

import CashbackPartialPaymentTourBannerView
    from '@/js/desktop/components/partialPayment/cashbackPartialPaymentTourBanner/cashbackPartialPaymentTourBanner';
import PartialPaymentTourBannerView
    from '@/js/desktop/components/partialPayment/partialPaymentTourBanner/partialPaymentTourBanner';

import paylateBnr from '@/js/desktop/components/search/paylate/paylateBnr/paylateBnr';
import { DESKTOP_SEARCH_PAGE_BANNER_STEP } from '@/js/common/Constants/bannerSteps';

import checkTourCashbackAvailable from '@/js/common/Domain/Service/checkTourCashbackAvailable';
import SearchPageHotelCardAbstract from '@/js/desktop/View/Abstract/SearchPageHotelCardAbstract';

import TooltipPopoverHtml from '@/js/common/components/tooltip/htmlTooltip/HtmlTooltip';
import '@/css/common/components/warranted-icon.scss';
import abTests from '@/js/common/Domain/Service/abTests';
import preparePropsForHotelCardRightBlock from '@ve/components/serpHotelCard/services/preparePropsForHotelCardRightBlock';
import { getTypeConfirmationByPriority } from '@entities/tourProduct';
import { DesktopIconConfirmation } from '@entities/tourProduct/desktop';
import hotelTemplate from './searchPageHotel/hotelTemplate.html';

const SearchPageHotelViewModel = Backbone.Epoxy.Model.extend({
    defaults: {
        hotelIndex: null,
        componentsOrder: null,
        regionType: null,
        regionWithOffices: null,
        searchPageModel: null,
        subscribeBannerView: null,
        subscribeBanner: null,
        lifeHackBannerEmailWasSend: false,
        hidePartialBanner: false,
        subscribeActivatePopularityOffersFilters: false,
    },
    computeds: {
        filterBanner: {
            deps: ['hotelIndex', 'componentsOrder'],
            get(hotelIndex, componentsOrder) {
                return this.checkBannerPosition(hotelIndex, componentsOrder, 'filterBanner') ? filterBannerTemplate({ hotelIndex }) : '';
            },
        },
        lotteryBanner: {
            deps: ['hotelIndex', 'componentsOrder'],
            get(hotelIndex, componentsOrder) {
                let $lotteryBanner = '';

                if (this.checkBannerPosition(hotelIndex, componentsOrder, 'lottery')) {
                    $lotteryBanner = (new LotteryBannerView()).$el;
                }

                return $lotteryBanner;
            },
        },
        genleadBanner: {
            deps: ['hotelIndex', 'componentsOrder', 'regionType', 'regionWithOffices'],
            get(hotelIndex, componentsOrder, regionType, regionWithOffices) {
                const subscribeBanner = this.checkBannerPosition(hotelIndex, componentsOrder, 'subscribe');

                return (subscribeBanner && regionType === 'region' && regionWithOffices && document.location.pathname !== '/search') ? genleadBannerTemplate() : '';
            },
        },
        componentsBanner: {
            deps: ['hotelIndex', 'componentsOrder'],
            get(hotelIndex, componentsOrder) {
                return this.checkBannerPosition(hotelIndex, componentsOrder, 'tourIncludes') ? componentsBannerTemplate({ hotelIndex }) : '';
            },
        },
        appBanner: {
            deps: ['hotelIndex', 'componentsOrder'],
            get(hotelIndex, componentsOrder) {
                return this.checkBannerPosition(hotelIndex, componentsOrder, 'appBanner') ? appBannerTemplate() : '';
            },
        },
        lifeHackBanner: {
            deps: ['hotelIndex', 'componentsOrder'],
            get(hotelIndex, componentsOrder) {
                let $banner = '';
                if (this.checkBannerPosition(hotelIndex, componentsOrder, 'lifeHackBanner')) {
                    $banner = (new LifeHackBannerView({
                        model: this,
                    })).$el;
                }
                return $banner;
            },
        },
        paylateBnr: {
            deps: ['hotelIndex', 'componentsOrder'],
            get(hotelIndex, componentsOrder) {
                let $banner = '';

                if (this.checkBannerPosition(hotelIndex, componentsOrder, 'paylateBnr')) {
                    $banner = paylateBnr(hotelIndex);
                }

                return $banner;
            },
        },
        conditionalBanner: {
            deps: ['subscribeBanner', 'genleadBanner'],
            get: (subscribeBanner, genleadBanner) => (subscribeBanner && !genleadBanner ? subscribeBanner : genleadBanner),
        },
        visibleBanners: {
            deps: ['subscribeActivatePopularityOffersFilters'],
            get: (subscribeActivatePopularityOffersFilters) => !subscribeActivatePopularityOffersFilters,
        },
    },
    calculateSybscribeBanner() {
        let $subscribeBanner = '';
        let subscribeBannerView;
        const hotelIndex = this.get('hotelIndex');
        const componentsOrder = this.get('componentsOrder');
        const searchPageModel = this.get('searchPageModel');

        if (this.checkBannerPosition(hotelIndex, componentsOrder, 'subscribe')) {
            subscribeBannerView = new TourHunterSubscribeView({
                trackingService: new SerpTrackingService(),
                searchPageModel,
                searchFormViewModel: this.searchFormViewModel,
                hotelIndex,
            });
            $subscribeBanner = this.subscribeBannerTemplate || subscribeBannerView.$el;
        }

        this.set('subscribeBannerView', subscribeBannerView);
        this.set('subscribeBanner', $subscribeBanner);
    },
    checkBannerPosition(index, componentsOrder, componentName) {
        const step = DESKTOP_SEARCH_PAGE_BANNER_STEP;
        const componentsLength = step * componentsOrder.length;
        let elLength;
        let componentsOrderElName;

        componentsOrderElName = componentsOrder[((index - step + 1) / step) % componentsOrder.length];
        if (_.isArray(componentsOrderElName)) {
            elLength = componentsOrderElName.length;
            const elIndex = parseInt((index / componentsLength), 10) % elLength;
            componentsOrderElName = componentsOrderElName[elIndex];
        }
        return componentsOrderElName === componentName;
    },
    initModelEvents() {
        this.on('change:componentsOrder', $.proxy(this.calculateSybscribeBanner, this));
    },
    initialize(options) {
        this.searchFormViewModel = options.searchFormViewModel;
        this.subscribeBannerTemplate = options.subscribeBannerTemplate;
        this.initModelEvents();
    },
});

@extendDecorator({
    el: '<div class="serpHotelCard"></div>',
    hotelTemplate,
    data: null,
    template: null,
    searchFormViewModel: null,
    bindings: {
        '.serpHotelCard': 'classes:{isExclusive: isExclusive}',
        '.js-componentsBanner': 'html: componentsBanner',
        '.js-filterBanner': 'html: filterBanner',
        '.js-lotteryBanner': 'html: lotteryBanner',
        '.js-conditionalBanner': 'html: conditionalBanner',
        '.js-appBanner': 'html: appBanner',
        '.js-lifeHackBanner': 'html: lifeHackBanner',
        '.js-paylateBnr': 'html: paylateBnr',
        '.js-visible-banners-hotel-card': 'toggle: visibleBanners',
    },
    events: {
        'click .serpHotelCard__container': 'setInCookieMinPrice',
        'click .goToHotel': 'goToHotel',
        'click .js-images-carousel-control-prev': 'sliderArrowClick',
        'click .js-images-carousel-control-next': 'sliderArrowClick',
        'click .serpHotelCard__container a:not(.goto-ua-page, .notChange, .cashbackPartialPaymentTourBanner-cashbackSection__link)': 'callYMGoals',
        'mousedown .serpHotelCard__container a:not(.goto-ua-page, .notChange, .cashbackPartialPaymentTourBanner-cashbackSection__link)': 'hotelClick',
        'click .regionsMsg__btn span': 'openGeneralLead',
        'click .thSubscribeBtn': 'submitTourHunter',
        'click .js-th-button': 'submitTourHunter',
        'submit .js-th-form': 'submitTourHunter',
        'click .banner-close': 'closeBanner',
    },
})
export default class SearchPageHotelView extends SearchPageHotelCardAbstract {
    closeBanner(e) {
        const { currentTarget } = e;
        const banerName = $(currentTarget).data('banner');
        const bannerPosition = $(currentTarget).data('index') + 1;
        const parent = currentTarget.parentElement;

        if (!parent) return;

        window.dataLayer.push({
            event: 'selectContent',
            selectContent:
                {
                    prop: [
                        { type: 'engageBanner', value: banerName },
                    ],
                    prop2: [
                        { type: 'position', value: bannerPosition },
                    ],
                },
        });

        $(parent).remove();
    }

    trackingHotelClick() {
        trackingService.trackSerpHotelPosition({
            hotels: this.collectionView.collection,
            selectedHotel: this.model,
            sortStrategy: this.collectionView.model.get('sortStrategy'),
        });
    }

    mindBoxTrackingHotelClick() {
        mindBoxService.trackViewSerpClick('serp_main', {
            searhPageModel: this.model,
            collectionViewModel: this.collectionView.model,
        });
    }

    hotelClick(e) {
        this.trackingHotelClick();
        this.mindBoxTrackingHotelClick();
        this.dataLayerEvents();
        this.hrefUrl(e);
    }

    async openGeneralLead() {
        const tourCriteria = this.collectionView.model.get('searchData');

        const { default: generalLead } = await import(/* webpackChunkName: "general-lead" */ '@/js/desktop/components/generalLead/general-lead.js');

        generalLead.open(tourCriteria ? {
            tourCriteria,
        } : {});
    }

    setInCookieMinPrice() {
        $.cookie('hotelMinPrice', this.model.get('priceWithOilTax'), {
            expires: 1 / 24 / 60 / 3,
            path: '/',
            domain: 'all',
        });
    }

    componentsList() {
        const enableLottery = checkLotteryService();
        const tourhunterAlreadySubscribed = this.collectionView.model.get('tourhunterAlreadySubscribed');
        let componentsOrder = [
            'filterBanner',
            'paylateBnr',
            'subscribe',
            'lottery',
            'tourIncludes',
            'lifeHackBanner',
            'appBanner',
        ];

        componentsOrder = enableLottery ? componentsOrder : _.without(componentsOrder, 'lottery');
        componentsOrder = !tourhunterAlreadySubscribed ? componentsOrder : _.without(componentsOrder, 'subscribe');

        return componentsOrder;
    }

    render() {
        let template;
        const data = this.model.toJSON({ computed: true });
        const hotelIndex = this.collectionView.collection.indexOf(this.model);

        if (this.collectionView) {
            template = this.selectTemplate();
        }

        data.originalHotelObject = this.model;

        this.viewModel = new SearchPageHotelViewModel({
            hotelIndex,
            componentsOrder: this.componentsList(),
            regionType: this.collectionView.model.get('regionType'),
            regionWithOffices: this.collectionView.model.get('regionWithOffices'),
            searchPageModel: this.collectionView.model,
            searchFormViewModel: this.searchFormViewModel,
            subscribeBannerTemplate: this.subscribeBannerTemplate || null,
            subscribeActivatePopularityOffersFilters: this.collectionView?.filters?.model.get('isPopularityFilterActive'),
        });
        data.hotelIndex = hotelIndex;

        if (this.hotelMapView) {
            this.hotelMapView.reRender({
                data,
                $el: this.$el,
                parentModel: this.model,
                collectionView: this.collectionView,
            });
        } else {
            this.$el.html(template(data));
        }

        setTimeout(() => {
            this.applyBindings();
            this.viewModel.calculateSybscribeBanner();
        }, 0);

        this.data = data;

        this.initCarousel();
        this.renderPhotos();
        this.initSubViews();
        this.initVueComponents();
        this.initConfirmation();
        this.showBottomBanner();
    }

    getPropsForHotelCardRightBlock() {
        const tourProduct = this.model.get('tourProducts').at(0);
        const tour = tourProduct.get('tour');

        return preparePropsForHotelCardRightBlock({
            product: tour,
            productPrice: tour.get('priceWithOilTax'),
            productType: productTypes.TOUR_OFFER,
        });
    }

    showBottomBanner() {
        const { isVisible: isVisiblePartialPayment } = this.getPartialPaymentData();
        const hasTourCashbackAvailable = checkTourCashbackAvailable(this.model.get('tourProducts'));

        const isCashbackAvailable = window?.appConfig?.cashbackAvailable
            && hasTourCashbackAvailable;

        if (isCashbackAvailable) {
            if (isVisiblePartialPayment) {
                this.initCashbackPartialPaymentBanner();
                return;
            }

            this.initCashbackBanner('tour');
            return;
        }

        if (isVisiblePartialPayment) {
            this.initPartialPaymentBanner();
        }
    }

    getPartialPaymentData() {
        const tourProducts = this.model.get('tourProducts');
        const tourProduct = tourProducts?.at(0);

        const isVisible = !!tourProduct.get('firstPaymentDefinition')?.id;
        const countryName = this.model.get('country').get('name');

        return {
            isVisible,
            countryName,
            tourProducts,
        };
    }

    initConfirmation() {
        if (abTests.hasTest('IN-3998')) {
            this.el.querySelector('.js-warranted-icon')?.remove();
            new Vue({
                render: (h) => h(DesktopIconConfirmation, {
                    props: {
                        typeConfirmation: this.getTypeConfirmationByTours(),
                        size: 'big',
                        withTooltip: true,
                    },
                }),
            }).$mount(this.el.querySelector('.js-confirmation-icon'));
        }
    }

    initPartialPaymentBanner() {
        const { countryName, tourProducts } = this.getPartialPaymentData();
        this.$el.addClass('partialPayment');

        new PartialPaymentTourBannerView({
            el: this.$el.find('.partialPayment__banner'),
            countryName,
            tourProducts,
        });
    }

    initCashbackPartialPaymentBanner() {
        const { countryName, tourProducts } = this.getPartialPaymentData();
        this.$el.addClass('cashbackPartialPayment');

        new CashbackPartialPaymentTourBannerView({
            el: this.$el.find('.cashbackPartialPayment__banner'),
            countryName,
            tourProducts,
        });
    }

    initSubViews() {
        new ThFavoritesView({
            model: this.model,
            searchPageModel: this.collectionView.model,
            el: $('.addToFavorites', this.$el),
        });

        this.warrantyTooltip = new TooltipPopoverHtml({
            el: $('.js-warranted-icon', this.$el),
        });
    }

    initSubscribes() {
        this.model.on('change:minPrice', $.proxy(this.reRender, this));
        this.model.on('change:tourProducts', $.proxy(this.reRender, this));

        checkLotteryService(() => {
            this.viewModel.set('componentsOrder', this.componentsList());
        });

        if (this.collectionView) {
            this.collectionView.model.on('change:tourhunterAlreadySubscribed', this.updateSubscribes.bind(this));
            this.collectionView.model.on('change:regionType', (model, value) => {
                this.viewModel.set('regionType', value);
            });
            this.collectionView.model.on('change:regionWithOffices', (model, value) => {
                this.viewModel.set('regionWithOffices', value);
            });
        }
    }

    updateSubscribes() {
        const subscribeBannerView = this.viewModel.get('subscribeBannerView');
        const $tourhunterBlock = this.viewModel.get('subscribeBanner');

        if (!$tourhunterBlock || !subscribeBannerView || !subscribeBannerView.currentSubscribeBanner()) {
            this.viewModel.set('componentsOrder', this.componentsList());
        }
    }

    getComponentsData() {
        checkLotteryService(() => {
            this.viewModel.set('componentsOrder', this.componentsList());
        });
    }

    initialize(options) {
        this.serpTrackingService = new SerpTrackingService();
        this.collectionView = options.collectionView;
        this.dataLayerAnalytics = options.collectionView.dataLayerAnalytics;
        this.template = options.template ? options.template : this.hotelTemplate;
        this.hotelMapView = options.hotelMapView;
        this.model.$element = this.$el;

        this.render();
        this.initSubscribes();
        this.getComponentsData();
    }

    getTypeConfirmationByTours() {
        const tourProducts = this.model.get('tourProducts')?.models.map((tourProduct) => tourProduct.get('tour').attributes);
        const typeConfirmation = getTypeConfirmationByPriority(tourProducts || []);

        return typeConfirmation;
    }

    trackClickConfirmationTours() {
        if (abTests.hasTest('IN-3998')) {
            this.serpTrackingService.clickTourButtonWithConfirmation(this.getTypeConfirmationByTours());
        }
    }

    hrefUrl(event) {
        this.trackClickConfirmationTours();
        const tourProduct = this.model.get('tourProducts').at(0);
        const identity = tourProduct.get('id');
        const urlParams = this.buildUrlParams();
        const hotelUrl = this.model.get('url');
        const target = ($('.oneColumn:visible').length) ? '_self' : '_blank';
        const $target = $(event.currentTarget);

        if (typeof identity !== 'undefined') {
            if (event.which === 2) {
                gaAsync(() => {
                    ga('send', 'event', 'Hotel_Card', 'Visit_the_hotel', 'date_price', { nonInteraction: 1 });
                });
            }
        } else if (event.which === 2 && $target.parent().parent().parent().hasClass('serpHotel_Image')) {
            gaAsync(() => {
                ga('send', 'event', 'Hotel_Card', 'Visit_the_hotel', 'image_hotel', { nonInteraction: 1 });
            });
        }
        if ($target.hasClass('distance')) {
            urlParams.toMap = true;
        }
        $(event.currentTarget).attr('href', `${hotelUrl}#?${$.param(urlParams)}`);
        $(event.currentTarget).attr('target', target);
        ls.set('isAbsent', false);
    }

    buildUrlParams() {
        const tourProduct = this.model.get('tourProducts').at(0);
        const urlParams = {
            tourCriteria: tourProduct.get('tourCriteria'),
            productType: productTypes.TOUR_OFFER,
            similar: !!this.model.get('similar'),
            isExclusive: this.collectionView.model.filterCriteria.get('isExclusive'),
            identity: tourProduct.get('id'),
            minPrice: this.model.get('priceWithOilTax'),
            oilTaxPrice: tourProduct.get('tour').get('oilTax') || (tourProduct.get('tour').get('oilTax') === 0 ? '0' : null),
            operatorId: tourProduct.get('tour').get('operator').id,
            sid: searchSessionService.getSearchId(),
            suuid: trackingService.getSearchUuid(),
        };
        return toRouterHotelHydrator(urlParams);
    }

    offerClick() {
        ecommerceService.productClick({
            product: this.model,
            category: 'tour',
            pageType: 'serpTour',
            collection: this.collectionView.collection,
            departureCityName: this.collectionView.model.get('searchData').get('departureCity').get('name'),
        });
    }

    dataLayerEvents() {
        if (!this.dataLayerAnalytics) {
            return;
        }
        this.dataLayerAnalytics.searchClickEvent(this);
    }

    isHotelHotView() {
        return false;
    }
}
