/* eslint-disable */
import TouristGroupFactory from '@/js/common/Domain/Factory/TouristGroup';
import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';
import { propsDecorator, viewModelDecorator } from '@/js/common/Domain/Service/mixins/decorators';
import childesAgeFactory from '@/js/common/Domain/Factory/childesAgeFactory';
import { NAMESPACE_TOURIST_GROUP, TouristGroupModel } from '@entities/searchCriteria/touristGroup';
import ChildView from './ChildView';
import ChildesCollection from './ChildesCollection';

const MAX_CHILDES = 3;

@propsDecorator({
    itemView: ChildView,
    bindings: {
        '.js-collection': 'collection:$collection',
    },
})

@viewModelDecorator({
    defaults: {
        showMessage: false,
        selectIsOpen: false,
    },
})

export default class TouristGroupView extends Backbone.Epoxy.View {
    setLabel({
                 adults, kids, infants, $input,
             }) {
        // метод рендерит надпись в самой форме
        let kidsLabel;
        const adultsLabel = `${adults} взросл${(+adults).wordEnding('ый;ых;ых')}`;
        const kidsTotal = (kids || 0) + (infants || 0);

        if (kidsTotal > 0) {
            kidsLabel = kids > 0 ? 'ребенок;детей;детей' : 'младенец;младенца;младенцев';
        }
        const $inputText = $input || this.$inputText;
        $inputText.find('p').text(adultsLabel);

        if (kidsLabel) {
            $inputText.find('span').text(` и ${kidsTotal} ${kidsTotal.wordEnding(kidsLabel)}`).show();
            $inputText.find('p').removeClass('withoutKids');
        } else {
            $inputText.find('span').text('').hide();
            $inputText.find('p').addClass('withoutKids');
        }
    }

    controlChanged(e) {
        // запись выбранных данных в модель
        const data = this.getTouristsFromCollection(e ? $(e.currentTarget).prev() : null);
        this.setLabel(data);
        this.model.set('model', new TouristGroupFactory(data), { initiator: 'view' });
        this.addEmptyChildSelect();
    }

    getTouristsFromCollection($input) {
        // $input нужет так как браузер не успевает проставить свойство и событие наступает раньше
        const collection = this.collection?.pluck('model');
        const adults = this.getAdults($input);
        let infants = 0;
        let kids = 0;
        const ages = [];
        collection.forEach((item) => {
            const age = +item?.get('age');
            if (age >= 0 && age < 2) {
                infants++;
                ages.push(age);
            } else if (age >= 2 && age <= 17) {
                kids++;
                ages.push(age);
            }
        });
        return {
            adults,
            infants,
            kids,
            ages,
        };
    }

    initSetTouristGroupVuex() {
        registerModule.register( getStore(), TouristGroupModel, `${NAMESPACE_TOURIST_GROUP}`);
        this.setTouristGroupVuex(this.model.get('model') || null);
        this.model.on('change:model', () => {
            this.setTouristGroupVuex(this.model.get('model') || null);
        });
    }

    setTouristGroupVuex(touristGroup) {
        const store = getStore();
        store.commit(`${NAMESPACE_TOURIST_GROUP}/setAdults`, touristGroup?.get('adults') || 0);
        store.commit(`${NAMESPACE_TOURIST_GROUP}/setInfants`, touristGroup?.get('infants') || 0);
        store.commit(`${NAMESPACE_TOURIST_GROUP}/setKids`, touristGroup?.get('kids') || 0);
        store.commit(`${NAMESPACE_TOURIST_GROUP}/setAges`, touristGroup?.get('ages')?.map((age) => +age) || []);
    }

    initModelEvents() {
        this.model.on('change:model', this.modelChanged.bind(this));
    }

    dataLayerAnalyticsEvent() {
        this?.dataLayerAnalytics?.searchFieldsEvent('touristGroupOpen');
    }

    updateChildesList(model) {
        const childesArray = [];
        const { infants, kids, ages } = model.toJSON();
        const addChild = ({ type, age }) => {
            let childesCount = 0;
            for (let i = 0; i < type; i++) {
                if (childesCount > MAX_CHILDES) break;
                childesArray.push(childesAgeFactory({ age: isNaN(age) ? +ages[i] : +age }));
                childesCount++;
            }
        };

        const childes = (infants || 0) + (kids || 0);
        const setDefaultAges = childes !== ages?.length;

        if (!setDefaultAges) {
            addChild({ type: childes });
        } else {
            addChild({ type: kids, age: 11 });
            addChild({ type: infants, age: 1 });
        }
        if (childesArray.length) {
            this.collection.reset(childesArray);
        }
        this.addEmptyChildSelect();
        if (setDefaultAges) {
            this.controlChanged();
        }
    }

    addEmptyChildSelect() {
        if (this.collection.length < this.MAX_CHILDES) {
            this.viewModel.set({ showMessage: false });

            if (!this.collection.findWhere({ model: null })) {
                this.collection.add(childesAgeFactory({ age: null }));
            }
        } else {
            this.viewModel.set({ showMessage: true });
        }
    }

    removeChild(model) {
        this.collection.remove(model);
        this.controlChanged();
        if (this.collection.length === this.MAX_CHILDES - 1) {
            this.addEmptyChildSelect();
        }
    }

    initialize(options = {}) {
        this.MAX_CHILDES = MAX_CHILDES;
        this.collection = new ChildesCollection();
        this.dataLayerAnalytics = options.dataLayerAnalytics;
        this.render();
        this.initDOMVariables();
        this.initDomEvents();
        this.initModelEvents();
        this.initSetTouristGroupVuex();
    }
}