import { IOperator } from '../types/IOperator';

export default class Operator implements IOperator {
    id: number;

    name: string;

    cover: string;

    nameRu: string | null;

    constructor({ id, name, nameRu }: IOperator, imagesDomain: string) {
        this.id = id;
        this.name = name;
        this.nameRu = nameRu;
        this.cover = `${imagesDomain}/photos/operator/${this.id}.png`;
    }
}