

    import {
        computed,
        defineComponent,
        PropType,
        toRefs,
        unref,
    } from 'vue';

    import { TSizesInputCommon } from './common';
    import CheckBoxInputCommon from './common/CheckBoxInputCommon.vue';

    export default defineComponent({
        name: 'check-box-input',
        model: {
            prop: 'modelValue',
            event: 'change',
        },
        components: {
            CheckBoxInputCommon,
        },
        props: {
            modelValue: {
                type: Array as PropType<number[] | string[]>,
                required: true,
            },
            label: {
                type: String,
                default: '',
            },
            hideOverflowText: {
                type: Boolean,
                default: true,
            },
            value: {
                type: [String, Number],
                required: true,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            checkBoxSize: {
                type: String as PropType<TSizesInputCommon>,
                default: 'small',
            },
            isDisabledIcon: {
                type: Boolean,
                default: false,
            },
            isWhiteSpace: {
                type: Boolean,
                default: true,
            },
        },
        setup(props, { emit }) {
            const { modelValue, value } = toRefs(props);
            const updateInput = ({ target }: { target: HTMLInputElement }) => {
                const newValue = [...unref(modelValue)];
                if (target.checked) {
                    newValue.push(unref(value));
                } else {
                    newValue.splice(newValue.indexOf(unref(value)), 1);
                }

                emit('change', newValue);
            };

            const isChecked = computed(() => !!~unref(modelValue).indexOf(unref(value)));

            return {
                isChecked,
                updateInput,
            };
        },
    });

