/* eslint-disable */
import './thSuccessPopup.css';
import PopupWrapperView from "@/js/desktop/components/popup/PopupWrapper";
import moment from 'moment';

var tourHunterPopup,
	TourHunterPopupViewModel = Backbone.Epoxy.Model.extend({
		defaults: {
			thLkUrl:window.appConfig.accountUrl + '#/tourhunter',
			tourCriteria:null
		},
		computeds:{
			hotelText: {
				deps:['tourCriteria'],
				get:function(tourCriteria) {
					var hotels = tourCriteria ? tourCriteria.get('hotels') : false,
						hotel;

					if(hotels && hotels.length) {
						hotel = hotels.at(0);
						return hotel.get('name') + ', ' +  hotel.get('category').get('name');
					} else {
						return false;
					}
				}
			},
			departureCityText:{
				deps:['tourCriteria'],
				get:function(tourCriteria) {
					var departureCity = tourCriteria ? tourCriteria.get('departureCity') : false;
					return departureCity ? departureCity.get('name') : '';
				}
			},
			checkInDateRangeText:{
				deps:['tourCriteria'],
				get:function(tourCriteria) {
					var checkInDateRange = tourCriteria ? tourCriteria.get('checkInDateRange') : false,
						checkInDateRangeFrom = checkInDateRange ? moment(checkInDateRange.get('from')) : false,
						checkInDateRangeTo = checkInDateRange ? moment(checkInDateRange.get('to')) : false,
						checkInDateRangeText = '';

					if(checkInDateRangeFrom && checkInDateRangeTo) {
						checkInDateRangeText = checkInDateRangeFrom.format('DD.MM.YY');

						if (checkInDateRangeFrom.diff(checkInDateRangeTo)) {
							checkInDateRangeText += ' по ' + checkInDateRangeTo.format('DD.MM.YY');
						}
					}

					return checkInDateRangeText
				}
			},
			nightsText:{
				deps:['tourCriteria'],
				get:function(tourCriteria) {
					var nightRange = tourCriteria ? tourCriteria.get('nightRange') : false,
						nightRangeFrom = nightRange ? nightRange.get('from') : false,
						nightRangeTo = nightRange ? nightRange.get('to') : false,
						nightsText = 'на ';

					if(nightRangeFrom && nightRangeTo) {
						if (nightRangeFrom == nightRangeTo) {
							nightsText += nightRangeFrom + ' ноч' + nightRangeFrom.wordEnding('ь;и;ей');
						} else {
							nightsText += nightRangeFrom + '-' + nightRangeTo + ' ноч' + nightRangeTo.wordEnding('ь;и;ей');
						}
					}

					return nightsText;
				}
			}
		}
	}),
	TourHunterPopup = Backbone.Epoxy.View.extend({
		el: "<div class=\'thSuccessPopup bs_container\'>\n\t<i class=\'thSuccessPopup__image\'></i>\n\t<h2>Теперь вы следите за ценами</h2>\n\t<h5 class=\'thSuccessPopup__hotelText\'></h5>\n\t<p>Город вылета: <span class=\'thSuccessPopup__departureCityText\'></span><br>\n\t\tс <span class=\'thSuccessPopup__checkInDateRangeText\'></span></p>\n\t<small class=\'text-muted thSuccessPopup__nightsText\'></small>\n\t<div class=\'thSuccessPopup__goToLk\'>Перейти в Личный кабинет</div>\n</div>",
		viewModel:new TourHunterPopupViewModel(),
		events: {
			'click .thSuccessPopup__goToLk': 'goToLk'
		},
		bindings: {
			'.thSuccessPopup__hotelText': 'toggle:hotelText, text:hotelText',
			'.thSuccessPopup__departureCityText': 'text:departureCityText',
			'.thSuccessPopup__checkInDateRangeText': 'text:checkInDateRangeText',
			'.thSuccessPopup__nightsText': 'text:nightsText'
		},
		goToLk:function() {
			window.open(this.viewModel.get('thLkUrl'), '_blank');
		},
		tourCriteria:null,
		$tourHunterPopupDialog:null,
		show: function () {
			this.$tourHunterPopupDialog.show();
		},
		hide: function () {
			this.$tourHunterPopupDialog.hide();
		},
		initPopup: function () {
			this.$tourHunterPopupDialog = new PopupWrapperView({
				el: this.$el,
				dialogClass: 'thSuccessPopup__container'
			});
		},

		initialize: function (options) {
			this.initPopup();
		}
	});

let thSuccessPopupView = function(options) {
	if(!tourHunterPopup) {
		tourHunterPopup = new TourHunterPopup(options);
	}
	if(options && options.tourCriteria) {
		tourHunterPopup.viewModel.set('tourCriteria', options.tourCriteria);
	}

	return tourHunterPopup;
};

export default thSuccessPopupView;