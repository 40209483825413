import { TrackJS } from 'trackjs';

if (window.location.host.indexOf('travelata.ru') >= 0) {
    TrackJS.install({
        token: '75a86b0292914129b1aca0065daf8acd',
        application: 'travelata',
    });
}

Promise._unhandledRejectionFn = (rejectError) => {
    if (rejectError instanceof Error) {
        throw rejectError;
    }
};
