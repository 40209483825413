import { TrackJS } from 'trackjs';
import Vue from 'vue';

export default function () {
    Vue.config.errorHandler = (err, vueComponent) => {
        const componentName = vueComponent?.$vnode?.componentOptions?.tag || vueComponent?.$vnode?.tag;
        console.error(err);
        console.error('Error in component: ', componentName);
        TrackJS.track(err);
    };
    Vue.config.warnHandler = (textWarning, vueComponent, path) => {
        const componentName = vueComponent?.$vnode?.componentOptions?.tag || vueComponent?.$vnode?.tag;
        const message = `[vue-warn] message: ${textWarning}, name: ${componentName}`;
        console.error(message, path);
        TrackJS.track(message);
    };
}