import Backbone from 'backbone';
import { IBackboneProduct } from '@ve/types/backboneModels/Domain/ObjectValue';

interface ITrackingGetProuctsCountReturnType {
    aviaToursCount: number,
    hotelOffersCount: number,
    railwayToursCount: number,
}

export default (foundTours: Backbone.Collection<IBackboneProduct>): ITrackingGetProuctsCountReturnType => {
    let aviaToursCount = 0;
    let hotelOffersCount = 0;
    let railwayToursCount = 0;
    foundTours.forEach((product) => {
        const productType = product.get('type');
        switch (productType) {
            case 'HotelOfferProduct':
                hotelOffersCount++;
                break;
            case 'RailwayTourProduct':
                railwayToursCount++;
                break;
            default: aviaToursCount++;
        }
    });
    return {
        aviaToursCount,
        hotelOffersCount,
        railwayToursCount,
    };
};