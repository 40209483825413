import type { EmptySearchCurrentState } from '@ve/components/emptySearch/common/store/types/IEmptySearchStore';

import fromUserSearch from './fromUserSearch';
import fromAlternativeSearch from './fromAlternativeSearch';
import fromAsyncSearchWithFlexibleDates from './fromAsyncSearchWithFlexibleDates';
import fromCalendarMinPriceDateSearch from './fromCalendarMinPriceDateSearch';
import fromDefaultCriteriaSearch from './fromDefaultCriteriaSearch';

export default ({
    currentState,
    loadingStateIsFinished,
    hasCalendarMinPriceDate,
    hasTours,
    searchWithFlexibleDates,
    prevDepartureCityIsDifferentFromCurrentDepartureCity,
    hasPriceCalendar,
}: {
    currentState: EmptySearchCurrentState;
    loadingStateIsFinished: boolean;
    hasTours: boolean;
    hasCalendarMinPriceDate: boolean;
    searchWithFlexibleDates: boolean;
    prevDepartureCityIsDifferentFromCurrentDepartureCity: boolean;
    hasPriceCalendar: boolean;
}): EmptySearchCurrentState | null => {
    switch (currentState) {
        case 'userSearch':
            return fromUserSearch({
                hasTours,
                loadingStateIsFinished,
                prevDepartureCityIsDifferentFromCurrentDepartureCity,
                hasCalendarMinPriceDate,
                searchWithFlexibleDates,
                hasPriceCalendar,
            });
        case 'alternativeSearch':
            return fromAlternativeSearch({
                hasTours,
                loadingStateIsFinished,
                hasCalendarMinPriceDate,
                searchWithFlexibleDates,
                hasPriceCalendar,
            });
        case 'asyncSearchWithFlexibleDates':
            return fromAsyncSearchWithFlexibleDates({
                loadingStateIsFinished,
                hasCalendarMinPriceDate,
            });
        case 'calendarMinPriceDateSearch':
            return fromCalendarMinPriceDateSearch({
                hasTours,
                loadingStateIsFinished,
            });
        case 'defaultCriteriaSearch':
            return fromDefaultCriteriaSearch({
                hasTours,
                loadingStateIsFinished,
            });
        default:
            return null;
    }
};