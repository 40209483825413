import MealsRepository from '@/js/common/Domain/Repository/Meals';

export default (params) => {
    const tourCriteria = {};

    const element = params.meal;
    if (element) {
        tourCriteria.meals = element.split('.');
    } else if (element === 'all') {
        tourCriteria.meals = MealsRepository.repository.clone();
    }

    return tourCriteria;
};