/**
 * Сервис для получения массива firstPaymentDefinition из всех tourProduct.
 * @param tourProducts
 * @returns {*[]|*}
 */
import _ from 'underscore';

//todo: переименовать getTourProductFirstPaymentDefinitions -> getUniqFirstPaymentDefinitionsByProductsWithOperatorName

const getTourProductFirstPaymentDefinitions = (tourProducts) => {
    if (!tourProducts?.length) {
        return [];
    }

    return tourProducts.reduce((acc, tourProduct) => {
        let allFirstPaymentDefinitions = tourProduct.get?.('allFirstPaymentDefinitions') || tourProduct.allFirstPaymentDefinitions || [];

        if (tourProduct.get?.('hotelOffer')) {
            const allFirstPaymentDefinitionsOfHotelOffers = tourProduct.get?.('hotelOffer').get?.('allFirstPaymentDefinitions') || [];
            allFirstPaymentDefinitions = [...allFirstPaymentDefinitions, ...allFirstPaymentDefinitionsOfHotelOffers];
        }

        _.each(allFirstPaymentDefinitions, (firstPaymentDefinition) => {
            const isAddedItem = _.findWhere(acc, {
                id: firstPaymentDefinition.id,
            });

            if (!_.isEmpty(firstPaymentDefinition) && !isAddedItem) {
                acc.push({
                    ...firstPaymentDefinition,
                    operatorName: tourProduct.get?.('tour')?.get('operator')?.name || '',
                });
            }
        });

        return acc;
    }, []);
};

export default getTourProductFirstPaymentDefinitions;
