
    import { defineComponent } from 'vue';
    import AlternativeDeparutreItem from '../common/components/AlternativeDepartureItem.vue';
    import useAlternativeDeparture from '../common/composition/useAlternativeDeparture';

    export default defineComponent({
        components: {
            AlternativeDeparutreItem,
        },
        name: 'AlternativeDeparutre',
        setup(props, context) {
            return {
                ...useAlternativeDeparture(context),
            };
        },

    });
