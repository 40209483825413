/* eslint-disable */
const SortFactory = function(data) {
	var sort = 'recommended';

	switch (data) {
		case 'priceAsc': sort = 'priceAsc'; break;
		case 'priceDesc': sort = 'priceDesc'; break;
		case 'reviewsRating': sort = 'reviewsRating'; break;
		case 'reviewsCount': sort = 'reviewsCount'; break;
		default: sort = 'recommended';
	}
	return sort;
};

export default SortFactory;