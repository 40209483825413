/* eslint-disable */
import AjaxRequestManager from '@/js/common/Domain/AjaxRequestManager';

const frontendCacheTime = 3600000; //1 hour
let blockCountries = {
    data: null,
    time: null,
};

let currentPromiseLoading = null;

export const getBlockCountries = () => {
    const ajaxRequestManager = new AjaxRequestManager();

    if (!currentPromiseLoading) {
        currentPromiseLoading = new Promise((resolve, reject) => {
            const currentTime = (new Date()).getTime();

            if (blockCountries.data && blockCountries.time && currentTime - blockCountries.time < frontendCacheTime) {
                resolve({
                    data: blockCountries.data,
                    success: true,
                });
            } else {
                ajaxRequestManager.getData({
                    url: `${window.appConfig.gatewayUrl}/apiV1/country/getDisabledCountries`,
                    retriesAvailable: true,
                    type: 'GET',
                }, {
                    success(response) {
                        currentPromiseLoading = null;
                        blockCountries = {
                            data: response,
                            time: currentTime,
                        };
                        resolve({
                            data: response,
                            success: true,
                        });
                    },

                    error(message, response) {
                        currentPromiseLoading = null;
                        console.log('Response data:', response || 'no data');
                        console.log('Response message:', message || 'no message');
                        reject(new Error('Get data from /apiV1/country/getDisabledCountries error'));
                    },
                });
            }
        });
    }
    return currentPromiseLoading;
};
