import { filterByBudget } from '@features/filterByBudget';
import { filterHotelByChildService, filterHotelByRestType, filterHotelByBeachType } from '@features/hotel/filterHotels';
import { filterByConfirmation } from '@features/tourProduct/filterTourProducts';
import filterByPopularityOffers from './filters/filterByPopularityOffers';

export default class AbstractPageModel extends Backbone.Epoxy.Model {
    filterByBeachType(hotels, takenBeachType) {

        const filters = this?.get('filterCriteria');
        const takenBeachTypes = filters?.get('takenBeachType') || takenBeachType;
        if(!takenBeachTypes?.length) return hotels;
        return _.filter(hotels, (hotel) => {
            return filterHotelByBeachType({ beachType: hotel.get('beachType') }, takenBeachTypes)
        });
    }

    filterByPopularityOffers(hotels) {
        const filters = this.get('filterCriteria');
        if (filters.get('isPopularityFilterActive')) {
            hotels = filterByPopularityOffers(hotels);
        }
        return hotels;
    }

    filterByBeachLine(hotels) {
        const filters = this.get('filterCriteria');
        const takenTypesLines = filters.get('typeLines');
        if (takenTypesLines.length > 0) {
            hotels = _.filter(hotels, (hotel) => {
                const hotelBeachLine = +hotel.get('beachLine');
                return takenTypesLines.includes(hotelBeachLine);
            });
        }
        return hotels.slice(0);
    }

    filterByRestType(hotels, restTypeId) {
        const filters = this.get('filterCriteria');
        const restTypeFilterId = filters?.get('restType') || restTypeId;

        if (restTypeFilterId) {
            hotels = _.filter(hotels, (hotel) => filterHotelByRestType({ restTypes: hotel.get('restTypes') }, restTypeFilterId));
        }
        return hotels.slice(0);
    }

    filterByTypeDistanceLift(hotels) {
        const distanceIds = this.get('filterCriteria')?.get('typeDistanceLift');
        if (distanceIds.length > 0) {
            return _.filter(hotels, (hotel) => distanceIds.includes(hotel.get('distanceLift')));
        }
        return hotels.slice(0);
    }

    filterByWaterActivities(hotels, takeWaterAcivities) {
        const filterWaterActivities = this.get('filterCriteria')?.get('takenWaterActivities') || takeWaterAcivities;
        if (filterWaterActivities.length > 0) {
            hotels = _.filter(hotels, (hotel) => {
                const waterActivitiesHotel = hotel.get('waterActivities');
                return _.every(filterWaterActivities, (filterWaterActivity) => waterActivitiesHotel.includes(filterWaterActivity));
            });
        }
        return hotels.slice(0);
    }

    filterByTourOperators(hotels, takenOperators) {
        const operatorIds = this.get('filterCriteria')?.get('operators') || takenOperators;
        if (operatorIds.length === 0) return hotels.slice(0);
        hotels = hotels.filter((hotel) => hotel.get('tourProducts').models.some((tourProduct) => {
            const tour = tourProduct.get('tour');
            const { id } = tour.get('operator');
            return operatorIds.includes(id);
        }));
        return hotels.slice(0);
    }

    filterHotelsByConfirmation(hotels) {
        const filters = this.get('filterCriteria');
        const typeConfirmation = filters.get('typeConfirmation');

        if (!typeConfirmation) return hotels;

        return hotels.filter((hotel) => hotel.get('tourProducts').some((tourProduct) => filterByConfirmation(tourProduct.get('tour').attributes, typeConfirmation)));
    }

    filterByServiceChild(hotels, childServiceFilterIds) {
        const takenChildServices = this?.get('filterCriteria')?.get('childServiceFilterIds') || childServiceFilterIds;
        return _.filter(hotels, (hotel) => filterHotelByChildService({ childServices: hotel.get('childServices') }, takenChildServices));
    }

    filterByPrice(hotels) {
        const minPrice = this.get('filterCriteria').get('minPrice');
        const maxPrice = this.get('filterCriteria').get('maxPrice');
        return hotels.filter((hotel) => filterByBudget(hotel.get('minPrice'), minPrice, maxPrice));
    }

    filterByHotelFacilities(hotels, takenFacilities) {
        const filterFacilities = this?.get('filterCriteria')?.get('takenFacilities') || takenFacilities;
        if (filterFacilities.length > 0) {
            hotels = _.filter(hotels, (hotel) => {
                const facilitiesHotel = hotel.get('facilities');
                return _.every(filterFacilities, (filterFacility) => facilitiesHotel.includes(filterFacility));
            });
        }
        return hotels?.slice(0);
    }
}