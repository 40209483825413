import { IMapForFiltersSerpState, SerpHotelAdapted } from '@ve/components/searchFilters/store/types';
import getTakenFiltersStructure from './getTakenFiltersStructure';
import { IFilterApplyingItem } from '../types';

interface IApplyingFiltersList {
    availableFiltersAttributes: IFilterApplyingItem[],
    unAvailableFiltersAttributes: IFilterApplyingItem[],
}

const getAvailableFields = (objectCount: Record<string, number>, takenAttributes: Record<string, 1 | 0>): Record<string, boolean> | null => {
    const arrayCountValues = Object.entries(objectCount);
    const arrayTakenValue = Object.entries(takenAttributes);

    const objectTakenAvailableValues = arrayCountValues.reduce((acc, countValue) => {
        const [keyNameCount, isAvailableCount] = countValue;
        const isUserTakenAvailableValue = arrayTakenValue.find(([keyName, isTaken]) => keyNameCount === keyName && isTaken && isAvailableCount);
        if (isUserTakenAvailableValue) {
            acc[keyNameCount] = true;
        }
        return acc;
    }, {} as Record<string, boolean>);
    return Object.entries(objectTakenAvailableValues).length ? objectTakenAvailableValues : null;
};

const getInaccessibleFields = (objectCount: Record<string, number>, takenAttributes: Record<string, 1 | 0>): Record<string, boolean> | null => {
    const arrayCountValues = Object.entries(objectCount);
    const arrayTakenValue = Object.entries(takenAttributes);

    const objectTakenUnAvailableValues = arrayCountValues.reduce((acc, countValue) => {
        const [keyNameCount, isAvailableCount] = countValue;
        const isUserTakenUnAvailableValues = arrayTakenValue.find(([keyName, isTaken]) => keyNameCount === keyName && isTaken && !isAvailableCount);
        if (isUserTakenUnAvailableValues) {
            acc[keyNameCount] = true;
        }
        return acc;
    }, {} as Record<string, boolean>);
    return Object.entries(objectTakenUnAvailableValues).length ? objectTakenUnAvailableValues : null;
};

const getApplyingItem = (applyingItem: IFilterApplyingItem, objectCount: Record<string, boolean> | null): IFilterApplyingItem | null => {
    if (objectCount) {
        const idsByKeys = applyingItem.filterAttributeMethod.getIdsAttributesByObjectKeys(objectCount);
        return {
            ...applyingItem,
            valueState: Array.isArray(applyingItem.valueState) ? idsByKeys : idsByKeys[0],
        };
    }
    return null;
};

const getTakenAttributes = (applyingItem: IFilterApplyingItem) => applyingItem.filterAttributeMethod.getMergedAttributesIdsByKeys(Array.isArray(applyingItem.valueState) ? applyingItem.valueState : [applyingItem.valueState]);

export default <T = SerpHotelAdapted>(filtersState: IMapForFiltersSerpState | null, applyingItems: IFilterApplyingItem[], entities: T[]): IApplyingFiltersList => {
    const filtersApplyingItems = getTakenFiltersStructure(filtersState, applyingItems);
    const availableFiltersAttributes: IFilterApplyingItem[] = [];
    const unAvailableFiltersAttributes: IFilterApplyingItem[] = [];
    filtersApplyingItems.forEach((filterApplyingItem) => {
        const objectCount = filterApplyingItem.filterAttributeMethod.getCountAttribute(entities); // Возращает объект с посчитанными полями(вовзращается все поля фильтра) -> { firstLine: 20, secondLine: 0, thirdLine: 10 };
        const takenAttributes = getTakenAttributes(filterApplyingItem); // Возвращает объект со всеми полями фильтра. 1 - означает что это поле было выбрано, 0 - поле не было выбранно -> { firstLine: 0, secondLine: 1, thirdLine: 1 };

        const objectCountAvailableFields = getAvailableFields(objectCount, takenAttributes); // Исходя из полученных полей в объекте, выбираем те поля которые выбранны и которые существуют в текущей выдачи -> { firstLine: false, secondLine: false, thirdLine: true };
        const applyingItemAvailable = getApplyingItem(filterApplyingItem, objectCountAvailableFields); // Исходя из выбранных и существующих полей, формируем объект в который устанавливаем существующие выбранные значения

        if (applyingItemAvailable) {
            availableFiltersAttributes.push(applyingItemAvailable);
        }

        const objectCountUnAvailableFields = getInaccessibleFields(objectCount, takenAttributes);
        const applyingItemUnAvailable = getApplyingItem(filterApplyingItem, objectCountUnAvailableFields);

        if (applyingItemUnAvailable) {
            unAvailableFiltersAttributes.push(applyingItemUnAvailable);
        }
    });
    return {
        availableFiltersAttributes,
        unAvailableFiltersAttributes,
    };
};
