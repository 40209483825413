import { watch } from 'vue';

import {
    useState, useActions,
} from 'vuex-composition-helpers';

import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
import useBudgetFilterModel from './useBudgetFilterModel';

export default function () {
    const {
        maxPriceFilter, minPriceFilter, setMaxPrice, setMinPrice,
    } = useBudgetFilterModel();

    const { minPrice, maxPrice } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['minPrice', 'maxPrice']);

    const {
        action_maxPrice: setMaxPriceBackbone,
        action_minPrice: setMinPriceBackbone,
    } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_maxPrice', 'action_minPrice']);

    watch(maxPriceFilter, (value) => {
        setMaxPriceBackbone(value);
    });
    watch(minPriceFilter, (value) => {
        setMinPriceBackbone(value);
    });

    watch(minPrice, (value) => {
        setMinPrice(value);
    }, { immediate: true });

    watch(maxPrice, (value) => {
        setMaxPrice(value);
    }, { immediate: true });
}