export default (tourCriteria) => {
    const params = {};
    const element = tourCriteria.get('hotelCategories');
    if (element && element.length) {
        params.hotelClass = element.pluck('id').join('.');
    } else {
        params.hotelClass = 'all';
    }

    return params;
};