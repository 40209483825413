import userInterestsService from '@/js/common/Domain/Service/userInterests';
import trackingDefaultDataTypesHydrator from './trackingDefaultDataTypes';
import trackingTourCriteriaHydrator from './trackingTourCriteria';

export default async (options) => {
    const trackingStartSearchData = {};
    const { searchUuid } = options;
    const { productType } = options.searchOptions;
    const trackingDefaultDataTypes = await trackingDefaultDataTypesHydrator(options.searchOptions);
    const trackingTourCriteria = trackingTourCriteriaHydrator(options.searchOptions);

    _.extend(trackingStartSearchData, {
        searchUuid,
        productType,
        hasPersonalProfile: userInterestsService.getInterestsGroups().length ? 1 : 0,
    }, trackingDefaultDataTypes, trackingTourCriteria);

    return trackingStartSearchData;
};