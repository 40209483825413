import { useState } from 'vuex-composition-helpers';
import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';
import CheckInDateRangeStore, { NAMESPACE as CHECK_IN_DATE_RANGE_STORE } from '../model/CheckInDateRangeStore';
import { ICheckInDateRangeStoreState } from '../types';

export default (modelPrefix: string) => {
    registerModule.register(getStore(), CheckInDateRangeStore, `${CHECK_IN_DATE_RANGE_STORE}${modelPrefix}`);
    const { checkInDateRange } = useState<ICheckInDateRangeStoreState>(`${CHECK_IN_DATE_RANGE_STORE}${modelPrefix}`, ['checkInDateRange']);
    return {
        checkInDateRange,
    };
};