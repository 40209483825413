import {
 computed, unref, watch, ref,
} from 'vue';
import { useState } from 'vuex-composition-helpers';
import {
    useOperatorRepository, Operator,
} from '@entities/operator';
import { getGroupedOperators, groupTourProductsByHotel } from '@entities/tourProduct';
import { useSearchTourProducts } from '@features/tourProduct/searchTourProducts';
import { FilterAttributeMethodsOperators } from '@features/operator/filterByOperator';
import getApplyingAttributesCount from '../services/getApplyingAttributesCount';
import { IFilterApplyingItem, IFilterPreviousState } from '../types';

export default function () {
    const { operators } = useOperatorRepository();
    const { previousStateFiltersSerp } = useState<IFilterPreviousState>('previousFilterStore', ['previousStateFiltersSerp']);
    const asyncApplyingItemTourOperators = ref<IFilterApplyingItem | null>(null);
    const { searchedTourProducts } = useSearchTourProducts();

    watch([previousStateFiltersSerp, operators], (state) => { // Запоминаем последних операторов, при сбросе фильтров
        const [filtersState, _operators] = state as [IFilterPreviousState['previousStateFiltersSerp'], Operator[]];
        if (!filtersState) {
            asyncApplyingItemTourOperators.value = {
                id: 11,
                nameAction: 'action_operators',
                nameState: 'operators',
                filterAttributeMethod: new FilterAttributeMethodsOperators(_operators),
                valueState: [],
            };
        }
    });

    const tourOperatorsGroupped = computed(() => getGroupedOperators(groupTourProductsByHotel(unref(searchedTourProducts))));

    const tourOpeatorsApplyingItems = computed(() => [
        unref(asyncApplyingItemTourOperators),
    ].filter((item) => !!item) as IFilterApplyingItem[]);

    const tourOperatorApplyingFilterList = computed(() => getApplyingAttributesCount<Operator[]>(unref(previousStateFiltersSerp), unref(tourOpeatorsApplyingItems), unref(tourOperatorsGroupped)));

    return {
        tourOperatorApplyingFilterList,
    };
}