import { Metric } from 'web-vitals';

import _ from 'underscore';
import getMainMetaData, { ImainMetaDataReturn } from './getMainMetaData';
import trackingPages, { ITrackingPages } from './visitsTracking/trackingPages';

interface ItrackingCoreWebVitalsHydrator
extends Partial<ImainMetaDataReturn>, ITrackingPages {
    type: string
    value: number
    userAgent: string
}

export default (data: Metric): ItrackingCoreWebVitalsHydrator => {
    let value;
    switch (data.name) {
        case 'CLS':
            value = data.value * 1000;
            break;
        default:
            value = data.value;
    }

    return {
        type: data.name,
        value: Math.round(value),
        userAgent: window.navigator.userAgent,
        ...getMainMetaData({
            timestamp: (new Date()).getTime(),
        }),
        ...trackingPages(),
    };
};
