
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'filter-stars',
        props: {
            stars: {
                required: true,
                type: Number,
            },
        },
    });
