import { useState, useMutations } from 'vuex-composition-helpers';
import { computed, unref } from 'vue';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import OperatorFilterModel, {
    NAMESPACE,
    IFilterOperatorState,
    IFilterOperatorMutations,
} from '../model/OperatorFilterModel';

export default function () {
    registerModule.register(getStore(), OperatorFilterModel, NAMESPACE);
    const {
        operatorsFilter,
    } = useState<IFilterOperatorState>(NAMESPACE, ['operatorsFilter']);
    const {
        setOperatorFilter,
    } = useMutations<IFilterOperatorMutations>(NAMESPACE, ['setOperatorFilter']);

    const takenOperators = computed({
        get: () => unref(operatorsFilter),
        set: (value: IFilterOperatorState['operatorsFilter']) => {
            setOperatorFilter(value);
        },
    });

    return {
        setOperatorFilter,
        takenOperators,
    };
}