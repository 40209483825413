/* eslint-disable */
import regionHasOfficesService from '@/js/common/Domain/Service/regionHasOffices';
import gaAsync from '@/js/common/Domain/Service/gaAsync';

class GeneralLeadLoader {
	constructor() {
		this.initEvents();

		this.genLeadData = null;
	}

	setModel(model) {
		this.genLeadData = model;
	}

	initEvents() {
		regionHasOfficesService((hasOffices) => {
			let $openGeneralLeadForm = $('.openGeneralLeadForm');
			let $generalLeadBlock = $('.generalLeadBlock');
			let self = this;

			if (hasOffices) {
				$openGeneralLeadForm.show().on('click', async function() {
					gaAsync(function () {
						ga('send', 'event', 'button', 'OSTAVIT_ZAYAVKU', document.location.pathname, {'nonInteraction': 1})
					});

					let {default: generalLead} = await import(/* webpackChunkName: "general-lead" */ './general-lead');

					if(self.genLeadData) {
						generalLead.setModel(self.genLeadData);
						self.setModel = generalLead.setModel;
					}

					generalLead.open();
					return false;
				});
			} else {
				$openGeneralLeadForm.hide();
				$generalLeadBlock.hide();
				$('.openFranchisingPage').show();
			}
		});
	}
}

let generalLeadLoader = new GeneralLeadLoader();

export default generalLeadLoader;

