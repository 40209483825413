/* eslint-disable */
import HotelEntity from '../Entity/Hotel';
import HotelFactory from '../Factory/Hotel';
import EntityManager from '../EntityManager';
import TourCriteriaObjectValue from '../ObjectValue/TourCriteria';
import AjaxRequestManager from '../AjaxRequestManager';
import hotelToNewFormatAdapter from './service/hotelToNewFormatAdapter';

const HotelsRepository = Backbone.Collection.extend({
    model: HotelEntity,
    addEntry: function (hotels) {
        let hotelsModelsArray;
        let entityManager = new EntityManager();

        console.time('renderHotels');
        hotelsModelsArray = entityManager.add({
            factory: HotelFactory,
            repository: repository
        }, hotels);

        this.add(hotelsModelsArray);
        console.timeEnd('renderHotels');
    },
    getParamsForHotelRequest: function (tourCriteria, term, pageType) {
        let params;

        if (tourCriteria instanceof TourCriteriaObjectValue) {
            params = {
                resort: tourCriteria.get('resorts') ? tourCriteria.get('resorts').pluck('id') : [],
                hotels: tourCriteria.get('hotels') ? tourCriteria.get('hotels').pluck('id') : [],
                term: term
            }
            const countries = tourCriteria.get('countries')
            if (countries?.length === 1) {
                params.country = countries.at(0).get('id')
            } else if (countries?.length > 1) {
                params.countries = countries.map(country => country.get('id'))
            }
        } else {
            params = {
                resort: tourCriteria.resorts ? tourCriteria.resorts : [],
                hotels: tourCriteria.hotels ? tourCriteria.hotels : [],
                term: term
            }
            const { countries } = tourCriteria
            if (countries?.length === 1) {
                params.country = countries[0]
            } else if (countries?.length > 1) {
                params.countries = countries
            }
        }
        if (pageType === 'ski') {
            let resorts;
            if (tourCriteria instanceof TourCriteriaObjectValue && tourCriteria.get('countries') && tourCriteria.get('countries').length) {
                if (tourCriteria.get('resorts') && tourCriteria.get('resorts').length) {
                    resorts = tourCriteria.get('resorts').pluck('id');
                } else {
                    resorts = tourCriteria.get('countries').at(0).get('resorts').pluck('id');
                }
                params.resort = resorts;
            }
        }

        return params;
    },
    getHotels: function (data) {
        const options = this.getOptions(data);
        const callback = data.callback;
        const ajaxRequestManager = new AjaxRequestManager();
        const self = this;
        return ajaxRequestManager.getData(options, {
            success: function (hotels) {
                const filterHotels = self.getCountryInHotels(hotels);
                const hotelsCollection = self.getHotelsBuild(filterHotels, data);
                callback && callback.success ? callback.success(hotelsCollection) : '';
            },
            error: function () {
                callback && callback.error ? callback.error() : '';
            }
        });
    },
    getCountryInHotels: function(hotels) {
        return hotels.map((hotel) => {
            const isCountryId = hotel.countryId;
            const country = hotel.country;
            if(isCountryId && !country) {
                return {
                    ...hotel,
                    country: hotel.countryId,
                }
            }
            return hotel;
        })
    },
    getHotelsBuild: function(hotels, data) {
        let hotelsCollection = new HotelsRepository();
        let term = data?.term ? data.term.toLowerCase() : '';

        hotels = _.map(hotels, (hotel) => hotelToNewFormatAdapter(hotel));

        hotels = _.sortBy(hotels, function (hotel) {
            return hotel.name.toLowerCase().indexOf(term) !== 0;
        });

        hotelsCollection.addEntry(hotels);
        return hotelsCollection;
    },
    getOptions: function(data) {
        let params;
        let tourCriteria = data.tourCriteria;
        let term = data.term ? data.term : null;
        let options;
        let pageType =  data.pageType

        params = this.getParamsForHotelRequest(tourCriteria, term, pageType);
        options = {
            url: window.appConfig.gatewayUrl && window.appConfig.getHotels
                ? `${window.appConfig.gatewayUrl}${window.appConfig.getHotels}`
                : '',
            retriesAvailable: true,
            data: params
        };
        return options;
    },
    getHotelsNew: function (data) {
        return new Promise((res, rej) => {
            const options = this.getOptions(data);
            const ajaxRequestManager = new AjaxRequestManager();
            return ajaxRequestManager.getData(options, {
                success: (hotels) => {
                    res(this.getCountryInHotels(hotels));
                },
                error: () => {
                    rej([]);
                }
            });
        })
    }
});

let repository = new HotelsRepository();
HotelsRepository.repository = repository;

export default HotelsRepository;