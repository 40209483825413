import { BaseState } from '@ve/services/getStore/types.d';
import getStore from '@ve/services/getStore/getStore';
import { Store } from 'vuex';
import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';

export default (model: Backbone.Model): Store<BaseState> => getStore({
    name: 'previousFilterStore',
    backboneMap: [
        {
            fields: [{
                numberOfTourProducts: {
                    field: 'syncTourProducts',
                    action: (_model: never, syncTourProducts: any) => syncTourProducts?.length,
                } as any,
            }],
            model,
        },
    ],
    storeOptions: {
        state: {
            previousStateFiltersSerp: null,
        },
        mutations: {
            setDefaultPreviousStore(state): void {
                state.previousStateFiltersSerp = null;
            },
            setSyncProducts(state, products: number): void {
                state.numberOfTourProducts = products;
            },
            setFilterPreviousStateFilters(state, previousStateFiltersSerp: IMapForFiltersSerpState): void {
                state.previousStateFiltersSerp = previousStateFiltersSerp;
            },
        },
    },
});