import { IRawHotel } from '../types/IRawHotel';

export interface IHotelsRepositoryState {
    hotels: IRawHotel[];
}

export interface IHotelRepositoryMutations {
    setHotels(state: IHotelsRepositoryState, hotels: IRawHotel[]): void;
    resetHotels(state: IHotelsRepositoryState): void;
}

const HotelsRepository = {
    namespaced: true,
    state: {
        hotels: [],
    },
    mutations: {
        setHotels(state: IHotelsRepositoryState, updatedHotels: IRawHotel[] | undefined) {
            const hotelsHash = state.hotels.reduce((acc, hotel) => {
                acc[hotel.id] = true;
                return acc;
            }, {} as Record<number, boolean>);
            const newHotels = updatedHotels?.filter((hotel) => !hotelsHash[hotel.id]) || [];
            if (newHotels.length) {
                state.hotels = [...state.hotels, ...newHotels];
            }
        },
        resetHotels(state: IHotelsRepositoryState) {
            state.hotels = [];
        },
    },
};

export const NAMESPACE = 'hotelsRepositoryName';

export default HotelsRepository;
