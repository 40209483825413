import _ from 'underscore';
import { TrackJS } from 'trackjs';

const imageProxy = (image: string, params?: {
    width: number;
    height: number;
}): string => {
    const cloudHost = FRONTEND_IMAGES_HOST;

    const cloudResolutions = [
        [60, 45],
        [92, 69],
        [100, 60],
        [104, 57],
        [160, 120],
        [220, 165],
        [320, 240],
        [640, 480],
        [940, 705],
        [1920, 1080],
    ];

    if (!image) {
        TrackJS.track('No image provided!');
        return '';
    }
    let cloudSize;

    if (params && params.width && params.height) {
        cloudSize = _.find(cloudResolutions, (cloudResolution) => params.width <= cloudResolution[0] && params.height <= cloudResolution[1]);
    }

    if (cloudSize) {
        return image.replace(new RegExp('^((http|https):)?\\/\\/.*?\\/(.*)$'), `//${cloudHost}/thumbs/${cloudSize[0]}x${cloudSize[1]}/$3`);
    }
    return image.replace(new RegExp('^((http|https):)?\\/\\/.*?\\/(.*)$'), `//${cloudHost}/$3`);
};

export default imageProxy;