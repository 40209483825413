import getStore from '@ve/services/getStore/getStore';
import registerModule from '@ve/services/registerModule';
import { Store } from 'vuex';
import { BaseState } from '@ve/router/types';
import searchPageHotelCardStore, { NAMESPACE as SEARCH_PAGE_HOTEL_CARD_STORE } from '../store/SearchPageHotelCardStore';

export default class {
    intervalIdForUpdatingMinutesAfterSearchStarted!: ReturnType<typeof setInterval>;

    constructor() {
        const store = getStore();
        this.initSearchTimerStore(store);
    }

    private initSearchTimerStore(store: Store<BaseState>): void {
        registerModule.register(
            store,
            searchPageHotelCardStore,
            SEARCH_PAGE_HOTEL_CARD_STORE,
        );
    }

    public startSearchTimer(): void {
        clearInterval(this.intervalIdForUpdatingMinutesAfterSearchStarted);
        const store = getStore();
        store.commit(`${SEARCH_PAGE_HOTEL_CARD_STORE}/setDateAfterSearchStarted`, new Date());
        this.updateTimer(store);
    }

    private updateTimer(store: Store<BaseState>) {
        this.intervalIdForUpdatingMinutesAfterSearchStarted = setInterval(() => {
            store.dispatch(`${SEARCH_PAGE_HOTEL_CARD_STORE}/setMinutesAfterSearchStarted`, new Date());
        }, 10000);
    }
}