import './search-form-autocomplete.css';

import DestinationView from '@/js/desktop/View/destination/Destination';
import DepartureCityView from '@/js/desktop/View/DepartureCity';
import CheckInDateRangeView from '@/js/desktop/View/CheckInDateRange';
import NightRangeView from '@/js/desktop/View/NightRange';
import TouristGroupDesktop
    from '@/js/desktop/components/searchFormAutocomplete/searchForm/touristGroup/TouristGroupDesktop';
import HotelView from '@/js/desktop/View/Hotel';
import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';

import SearchFromAutocompleteModel from './searchFromAutocompleteModel';

export default class SearchFormAutocompleteView extends Backbone.View {
    initDestination() {
        this.model.get('destination').set('view', new this.DestinationViewConstructor({
            countriesData: this.model.get('countries'),
            resortsData: this.model.get('resorts'),
            hotelsData: this.model.get('hotels'),
            departureCity: this.model.get('departureCity'),
            nightRange: this.model.get('nightRange'),
            checkInDateRange: this.model.get('checkInDateRange'),
            el: '#mainSearchForm .destination',
            parentModel: this.model,
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));
    }

    isValid() {
        return !this.model.get('destination').get('view') || this.model.get('destination').get('view').isValid();
    }

    initialize(options = {}) {
        this.model = SearchFromAutocompleteModel();
        this.dataLayerAnalytics = new DataLayerAnalytics();
        this.DestinationViewConstructor = options && options.DestinationView ? options.DestinationView : DestinationView;

        this.model.get('departureCity').set('view', new DepartureCityView({
            model: this.model.get('departureCity'),
            el: '#mainSearchForm .from_city',
            openCallback: () => {
                this.dataLayerAnalytics.generateEvent('changeCity', '');
            },
        }));

        this.initDestination(options);

        this.model.get('checkInDateRange').set('view', new CheckInDateRangeView({
            model: this.model.get('checkInDateRange'),
            departureCityData: this.model.get('departureCity'),
            el: '#mainSearchForm .tour-duration',
            nightRangeEl: '#mainSearchForm .nights',
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));

        this.model.get('nightRange').set('view', new NightRangeView({
            model: this.model.get('nightRange'),
            departureCityData: this.model.get('departureCity'),
            checkInDateRange: this.model.get('checkInDateRange'),
            el: '#mainSearchForm .formDropdown.nights',
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));

        this.model.get('touristGroup').set('view', new TouristGroupDesktop({
            model: this.model.get('touristGroup'),
            el: '#mainSearchForm .touristGroup',
            dataLayerAnalytics: this.dataLayerAnalytics,
        }));

        this.model.get('hotels').set('view', new HotelView({
            model: this.model.get('hotels'),
            searchModel: this.model,
            pageType: options.pageType,
        }));
    }
}