export default (params) => {
    const tourCriteria = {};
    const element = params.operators;
    if (element) {
        tourCriteria.operators = element.split('.');
    } else {
        tourCriteria.operators = [];
    }

    return tourCriteria;
};