/* eslint-disable */
import './lottery-banner-serp.css';
import lotteryTemplate from './template.html';
import checkLotteryService from "@/js/common/Domain/Service/checkLottery";
import ecommerceService from "@/js/common/Domain/Service/ecommerce";
import imageProxy from "@/js/common/Domain/Service/imageProxy";

let LotteryBannerView = Backbone.Epoxy.View.extend({
	initEvents:function() {
		var self = this;
		setTimeout(function() {
			self.$el.on('click', '.lottery-banner__link', $.proxy(self.lotteryBannerClick,self));
		},0);
	},
	lotteryBannerClick:function() {
		checkLotteryService(function(lotteryData) {
			ecommerceService.lotteryClick({
				lotteryId: lotteryData.id,
				promotionType: 'lottery__banner'
			});
		});
	},
	checkConditions: function() {
		var self = this;
		checkLotteryService(function (lotteryData) {
			if (lotteryData) {
				self.render(lotteryData);
			}
		});
	},
	render:function(lotteryData) {
		lotteryData.bannerUrlProxied = imageProxy(lotteryData.bannerUrl.replace('http://', '//'),{width:550, height:340});
		this.$el.append(lotteryTemplate(lotteryData));

		ecommerceService.lotteryImpressions({
			lotteryId:lotteryData.id,
			promotionType:'lottery__banner'
		});
	},
	initialize:function() {
		this.checkConditions();
		this.initEvents();
	}
});

export default LotteryBannerView;