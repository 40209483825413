import 'jquery.cookie';
import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';

const customerEmail = $.cookie('customerEmail');

const AccountModel = Backbone.Epoxy.Model.extend({
    defaults: {
        profileRequestInProgress: false,
        authorized: false,
        customerEmail: customerEmail || '',
        hash: null,
        name: false,
        profileDataReceived: 0,
        customerUuid: null,
    },
    computeds: {
        email: {
            deps: ['customerEmail'],
            get(value) {
                return value;
            },
            set(value) {
                $.cookie('customerEmail', value, { path: '/', expires: 999, domain: wildcardDomain() });
                return {
                    customerEmail: value,
                };
            },
        },
    },
});

const accoutModel = new AccountModel();

export default accoutModel;
