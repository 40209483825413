import setAdditionalMetaOptions from '@/js/common/Service/setAdditionalMetaOptions';
import getABTestValue from '@/js/common/Domain/Service/getABTestValue';

import requestService from './request';

export default (tourCriteriaForTourRequest, callback) => requestService({
    url: `${window.appConfig.apiGatewayUrl}/frontend/tours`,
    data: {
        limit: 10000,
        ...tourCriteriaForTourRequest,
        ...setAdditionalMetaOptions(),
        sections: ['hotels', 'countries', 'firstPaymentDefinitions', 'operators', 'sortRate', 'hotelPhotos'],
        abTests: getABTestValue(),
    },
}, callback);
