import { ICountry } from '../types/ICountry';

export interface ICountriesRepositoryState {
    countries: ICountry[];
}

export interface ICountriesRepositoryMutations {
    setCountries(state: ICountriesRepositoryState, countries: ICountry[]): void;
    resetCountries(state: ICountriesRepositoryState): void;
}

const CountriesRepository = {
    namespaced: true,
    state: {
        countries: [],
    },
    mutations: {
        setCountries(state: ICountriesRepositoryState, updatedCountries: ICountry[] | undefined) {
            const countriesHash = state.countries.reduce((acc, country) => {
                acc[country.id] = true;
                return acc;
            }, {} as Record<number, boolean>);
            const newCountries = updatedCountries?.filter((country) => !countriesHash[country.id]) || [];
            if (newCountries.length) {
                state.countries = [...state.countries, ...newCountries];
            }
        },
        resetCountries(state: ICountriesRepositoryState): void {
            state.countries = [];
        },
    },
};

export const NAMESPACE = 'countriesRepositoryName';

export default CountriesRepository;
