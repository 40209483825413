import { useState, useMutations } from 'vuex-composition-helpers';
import getStore from '@fr/vue/services/getStore/getStore';
import registerModule from '@fr/vue/services/registerModule';
import CountriesRepository, {
    NAMESPACE,
    ICountriesRepositoryMutations,
    ICountriesRepositoryState,
} from '../model/CountriesRepository';

export default function () {
    registerModule.register(getStore(), CountriesRepository, NAMESPACE);
    const { countries } = useState<ICountriesRepositoryState>(NAMESPACE, ['countries']);
    const { setCountries, resetCountries } = useMutations<ICountriesRepositoryMutations>(NAMESPACE, ['setCountries', 'resetCountries']);

    return {
        countries,
        resetCountries,
        setCountries,
    };
}