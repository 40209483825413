/* eslint-disable */
import 'jquery.cookie';

import ecommerceService from '@/js/common/Domain/Service/ecommerce';
import checkLotteryService from '@/js/common/Domain/Service/checkLottery';
import imageProxy from '@/js/common/Domain/Service/imageProxy';
import wildcardDomain from '@/js/common/Domain/Service/wildcardDomain';
import { propsDecorator } from '@/js/common/Domain/Service/mixins/decorators';

import template from './template.html';
import lotteryImg from './lotteryImg.html';

import './lotteryDg.scss';

@propsDecorator({
    template,
})
export default class LotteryDgView extends Backbone.Epoxy.View {
	setCookie() {
		$.cookie(this.cookieName, 1, {
			path: '/',
			expires: 999,
			domain: wildcardDomain(),
		});
	}

	lotteryPopupClick(lotteryData) {
		ecommerceService.lotteryClick({
			lotteryId: lotteryData.id,
			promotionType: 'lottery__popup',
		});
	}

	dialogOpen() {
		checkLotteryService(lotteryData => {
			if (!lotteryData) return;

			ecommerceService.lotteryImpressions({
				lotteryId: lotteryData.id,
				promotionType: 'lottery__popup',
			});

			this.$dialog.correctPopupPosition().dialog({
				width: 718,
				height: 480,
				autoOpen: true,
				resizable: false,
				dialogClass: 'lotteryDg',
				modal: true,
			}).on('click', '.popupClose', () => this.closeDialog());

			this.$dialog.on('click', '.lotteryDg__footer-link', () => {
				this.lotteryPopupClick(lotteryData);
				this.closeDialog();
			});

			$('body').on('click', '.ui-widget-overlay', e => {
				const { target } = e;
				if ($(target).is('.ui-widget-overlay')) this.closeDialog();
			});
		});
	}

	closeDialog() {
		this.$dialog.dialog('close');
		$(window).trigger('lotteryPopupClosed');
		this.setCookie();
	}

	appendBanner(lotteryData) {
		this.$dialog = $(this.template(lotteryData));
		$('body').append(this.$dialog);
	}

	imagesPreload() {
		// images preload hack
		$('body').append(lotteryImg());
	}

	initialize(options) {
		this.imagesPreload();
		this.lotteryCallback = options.lotteryNotActiveCallback;
		checkLotteryService(lotteryData => {
			if (!lotteryData) {
				if (this.lotteryCallback) this.lotteryCallback();
				return;
			}
			const __lotteryData = lotteryData;
			const { id, bannerUrl } = __lotteryData;
			this.cookieName = `lottery_${id}_closed`;
			__lotteryData.image = imageProxy(bannerUrl.replace('http://', '//'), {
				width: 700,
				height: 480,
			});
			this.appendBanner(__lotteryData);

			if (!$.cookie(this.cookieName)) {
				this.dialogOpen()
			} else if (this.lotteryCallback) {
				this.lotteryCallback();
			}
		});
	}
}