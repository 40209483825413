/* eslint-disable */
import moment from "moment";
import 'moment/locale/ru';

import wwContent from './wwContent.html';
import wwlabel from './wwLabel.html';
import wwResorts from './wwResorts.html';

var weatherIcons = ['icon-i16_sun', 'icon-i16_halfsun', 'icon-i16_rain', 'icon-i16_snow']; //not used icon: icon-i16_clouds

var ForecastItemModel = Backbone.Epoxy.Model.extend({
	defaults: {
		type: null,
		date: null,
		weatherType: null,
		airTemperature: null,
		waterTemperature: null
	},
	computeds: {
		icon: {
			deps: ['weatherType'],
			get: function (weatherType) {
				return weatherIcons[weatherType];
			}
		},
		label: {
			deps: ['type', 'date'],
			get: function (type, date) {
				if (type == 'daily') {
					return moment(date).format('ddd');
				} else {
					return moment(date).format('MMMM');
				}
			}
		},
		currentDate: {
			deps: ['type', 'date'],
			get: function (type, date) {
				if (type == 'daily') {
					return false;
				} else {
					return moment().format('MM') == moment(date).format('MM');
				}
			}
		},
		shortDate: {
			deps: ['date'],
			get: function (date) {
				return moment(date).format('DD.MM');
			}
		}
	}
});

var ForecastItemView = Backbone.Epoxy.View.extend({
	viewModel: null,
	bindings: {
		':el': 'toggle:isSelected'
	},
	computeds: {
		isSelected: {
			deps: ['selectedResort', 'resort'],
			get: function (selectedResort, resort) {
				return selectedResort && resort.id == selectedResort;
			}
		}
	},
	render: function () {
		this.$el.html(this.template(this.model.toJSON({computed: true})));
	},
	initialize: function (options) {
		this.viewModel = options.collectionView.viewModel;
		this.template = options.collectionView.itemTemplate;
		this.render();
	}
});

var ForecastItemCollection = Backbone.Collection.extend({
	model: ForecastItemModel
});

var ResortModel = Backbone.Epoxy.Model.extend({
	defaults: {
		id: null,
		name: null,
		collection: null
	}
});

var ResortView = Backbone.Epoxy.View.extend({
	className: 'WWresorts_col',
	bindings: {
		':el': "classes:{active:isSelected}"
	},
	computeds: {
		isSelected: {
			deps: ['selectedResort', 'id'],
			get: function (selectedResort, id) {
				return selectedResort && id == selectedResort;
			}
		}
	},
	events: {
		'click': 'selectResort'
	},
	selectResort: function () {
		var self = this;
		this.viewModel.set('selectedResort', this.model.get('id'));
	},
	render: function () {
		this.$el.html(this.template(this.model.toJSON()));
	},
	initialize: function (options) {
		this.viewModel = options.collectionView.viewModel;
		this.index = options.collectionView.bindingSources.resortCollection.indexOf(this.model);
		this.template = options.collectionView.resortTemplate;
		this.render();
	}
});

var ResortCollection = Backbone.Collection.extend({
	model: ResortModel
});

var WidgetViewModel = Backbone.Epoxy.Model.extend({
	defaults: {
		//resortClickedOnce: false,
		panelOpened: false,
		selectedResort: null,
		resortsCount: null
	}
});
var WidgetDataModel = Backbone.Epoxy.Model.extend({
	defaults: {
		dateFrom: null,
		dateTo: null,
		countryId: null,
		countryName: null,
		resortId: null,
		resortsCount: null,
		resortName: null,
		forecastType: null,
		topForecast: null
	},
	computeds: {
		icon: {
			deps: ['topForecast'],
			get: function (topForecast) {
				return topForecast ? weatherIcons[topForecast.get('weatherType')] : null;
			}
		},
		forecastName: {
			deps: ['countryName', 'resortName', 'resortsCount', 'forecastType'],
			get: function (countryName, resortName, resortsCount) {
				var label = 'Погода - ';
				if (resortName) {
					return label += (resortsCount > 1) ? countryName : resortName;
				} else if (countryName) {
					return label += countryName;
				} else {
					return 'Погода';
				}
			}
		},
		dropdownLabel: {
			deps: ['forecastType', 'dateFrom', 'dateTo'],
			get: function (forecastType, dateFrom, dateTo) {
				if (forecastType == 'daily') {
					//return 'Прогноз погоды на ' + moment(dateFrom).format('DD.MM') + '—' + moment(dateTo).format('DD.MM');
					return 'Прогноз погоды на 2 недели';
				} else {
					return 'Среднемесячный прогноз погоды';
				}
			}
		}
	}
});

let WeatherWidgetView = Backbone.Epoxy.View.extend({
	model: null,
	viewModel: null,
	resortView: ResortView,
	forecastItemView: ForecastItemView,
	events: {
		'click .weatherWidget_panel, .weatherWidget_drop .WWlabel, .weatherWidget_arr': 'openPanel'
	},
	bindingSources: {
		resortCollection: new ResortCollection(),
		forecastItemCollection: new ForecastItemCollection()
	},
	bindingHandlers: {
		slide: {
			set: function ($element, value) {
				if (value) {
					$element.slideDown();
				} else {
					$element.slideUp();
				}
			}
		}
	},
	bindings: {
		':el': "classes:{open:panelOpened}, slide:selectedResort",
		'.forecastName': "text:forecastName",
		'.WWresorts': "collection:$resortCollection,itemView:'resortView',toggle:showResorts",
		'.WWcontent': "collection:$forecastItemCollection,itemView:'forecastItemView'",
		'.weatherWidget_drop .WWlabel': 'text:dropdownLabel'
	},
	computeds: {
		showResorts: {
			deps: ['resortsCount'],
			get: function (resortsCount) {
				return resortsCount && resortsCount > 1;
			}
		}
	},
	openPanel: function () {
		var state = !this.viewModel.get('panelOpened');
		this.viewModel.set('panelOpened', state);
		if (state) {
			this.closePanelProxy = $.proxy(this.closePanel, this);
			$('body').off('click', this.closePanelProxy).on('click', this.closePanelProxy);
		}
	},
	closePanel: function (e) {
		if (e && !$(e.target).parents('.weatherWidget').length) {
			this.viewModel.set('panelOpened', false);
			$('body').off('click', this.closePanelProxy);
		} else if (typeof e == 'undefined') {
			this.viewModel.set('panelOpened', false);
		}
	},
	updateData: function (tourCriteria) {
		if (tourCriteria) {
			var self = this,
				country = tourCriteria.get('countries'),
				countryId = (country && country.at(0) && country.at(0).get('id')) ? country.at(0).get('id') : null,
				countryName = (country && country.at(0) && country.at(0).get('name')) ? country.at(0).get('name') : null,
				resorts = tourCriteria.get('resorts') ? tourCriteria.get('resorts') : null,
				resortId = (resorts && resorts.at(0) && resorts.at(0).get('id')) ? resorts.at(0).get('id') : null,
				resortName = (resorts && resorts.at(0) && resorts.at(0).get('name')) ? resorts.at(0).get('name') : null,
				dateRange = tourCriteria.get('checkInDateRange'),
				dateRangeFrom = (dateRange && dateRange.get('from')) ? dateRange.get('from') : null,
				dateRangeTo = (dateRange && dateRange.get('to')) ? dateRange.get('to') : null,
				nightRange = tourCriteria.get('nightRange'),
				forecastDays = (nightRange && nightRange.get('to')) ? nightRange.get('to') : 7;

			if ((resortId || countryId) && dateRangeFrom) {

				var dateRangeTo = moment(dateRangeTo).add(forecastDays, 'days'),
					dateRangeFrom = moment(dateRangeFrom),
					dateFrom = dateRangeFrom.format('YYYY-MM-DD'),
					dateTo = dateRangeTo.format('YYYY-MM-DD'),
					path, params, resortsIds = [];

				if (resortId) {
					resortsIds = _.pluck(resorts.models, 'id').splice(0, 3);
					path = '/forecasts/resorts';
					params = {
						'dateRange[from]': dateFrom,
						'dateRange[to]': dateTo,
						'id': resortsIds
					}
				} else if (countryId) {
					path = '/forecasts/countries/' + countryId;
					params = {
						'dateRange[from]': dateFrom,
						'dateRange[to]': dateTo
					}
				}

				$.ajax({
					url: path,
					data: params
				})
					.done(function (response) {
						if (response && response.success && response.data) {
							if (response.data.length) {
								self.updateCollection(response.data);
							} else {
								console.log('warning:', 'empty data');
							}
						} else {
							if (response && response.message) {
								console.log('error:', response.message);
							} else {
								console.log('error:', 'unknown error');
							}
						}
					});
				self.model.set('countryId', countryId);
				self.model.set('countryName', countryName);
				self.model.set('resortId', resortId);
				self.model.set('resortsCount', resortsIds.length);
				self.model.set('resortName', resortName);
				self.model.set('dateFrom', dateRangeFrom);
			}
		} else {
			this.updateCollection();
		}
	},
	updateCollection: function (data) {
		if (data) {
			var forecastsData = data,
				resortsData = _.pluck(
					_.uniq(
						data,
						false,
						function (item) {
							return item.resort.id
						}
					),
					'resort'
				);

			let forecasts = this.bindingSources.forecastItemCollection,
				resorts = this.bindingSources.resortCollection;

			forecasts.reset();
			resorts.reset();
			forecasts.reset(forecastsData);
			resorts.reset(resortsData);

			this.viewModel.set('selectedResort', resorts.at(0).get('id'));
			this.viewModel.set('resortsCount', resorts.length);
			this.model.set('forecastType', forecasts.at(0).get('type'));

			var topForecast,
				maxDate = moment(this.model.get('dateFrom'));

			if (this.model.get('forecastType') === 'daily') {
				topForecast = forecasts.min(function (model) {
					return model.get('weatherType');
				});
			} else {
				topForecast = forecasts.find(function (model) {
					var currentMonth = moment().format('MM'),
						forecastMonth = moment(model.get('date')).format('MM');
					if (currentMonth == forecastMonth) {
						return model;
					}
				});
			}

			forecasts.each(function (model) {
				var date = model.get('date');
				if (maxDate.isBefore(date, 'day')) {
					maxDate = moment(date);
				}
			});

			this.model.set('topForecast', topForecast);
			this.model.set('dateTo', maxDate.format('YYYY-MM-DD'));
			this.render();
		} else {
			let forecasts = this.bindingSources.forecastItemCollection,
				resorts = this.bindingSources.resortCollection;
			forecasts.reset();
			resorts.reset();
			this.viewModel.set('selectedResort', null);
			this.render();
		}
	},
	render: function () {
		$('.weatherWidget_panel', this.$el).html(this.template(this.model.toJSON({computed: true})));
	},
	initialize: function () {
		this.resortTemplate = wwResorts;
		this.itemTemplate = wwContent;
		this.template = wwlabel;
		$('.WWresorts', this.$el).empty();
		$('.WWcontent', this.$el).empty();
		$('.weatherWidget_panel', this.$el).empty();
		this.viewModel = new WidgetViewModel();
		this.model = new WidgetDataModel();
	}
});

export default WeatherWidgetView;