
    import {
        defineComponent, PropType, SetupContext,
    } from 'vue';
    import CheckBoxInput from '@ve/components/inputs/CheckBoxInput.vue';
    import RadioInput from '@ve/components/inputs/RadioInput.vue';
    import FilterListItem from '@ve/components/searchFilters/filterList/desktop/FilterListItem.vue';
    import TextCommon from '@ve/components/inputs/TextCommon.vue';
    import FilterItemPosition from '@ve/components/filters/common/components/filterItemPosition/FilterItemPosition.vue';
    import HintTooltip from '@ve/components/hintTooltip/desktop/HintTooltip.vue';
    import FilterAttributeMethods from '@ve/components/filters/common/filterMethods/filterAttributeMethodsAbstract/FilterAttributeMethodsAbstract';
    import { IFilterAttribute } from '@ve/components/filters/common/types';
    import useFilterListRender from './common/useFilterListRender';

    export default defineComponent({
        name: 'FilterListRender',
        model: {
            prop: 'selected',
            event: 'change',
        },
        components: {
            FilterListItem,
            FilterItemPosition,
            TextCommon,
            HintTooltip,
            RadioInput,
            CheckBoxInput,
        },
        props: {
            filterAttributeMethods: {
                type: Object as PropType<FilterAttributeMethods<IFilterAttribute, any>>,
                required: true,
            },
            firstCheckboxName: {
                type: String,
                default: '',
            },
            enableOnlyCheckBox: {
                type: Boolean,
                default: true,
            },
            selected: {
                type: [Array, Number] as PropType<number[] | number>,
                default: 0,
            },
            filteredEntities: {
                type: Array as PropType<any[] | any[][]>,
                required: true,
            },
            allEntities: {
                type: Array as PropType<any[] | any[][]>,
                required: true,
            },
            visibleCounter: {
                type: Boolean,
                default: true,
            },
            visibleIcon: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, { emit }: SetupContext) {
            return {
                ...useFilterListRender(props, emit),
            };
        },
    });
