/* eslint-disable */
import requestService from "@/js/common/Domain/Service/request";

var regionHasOffices = null,
	ajaxInProgress = false;

let regionHasOfficesService = function(callback) {
	var ajaxOptions,
		$document;

	if(_.isNull(regionHasOffices)) {
		$document = $(document);

		$document.one('regionHasOfficesComplete', function () {
			callback(regionHasOffices);
		});

		if(!ajaxInProgress) {
			ajaxOptions = {
				url: '/regions/isRegionWithOffices',
				dataType: "json",
				type: "GET"
			};

			requestService(ajaxOptions, {
				success: function (response) {
					if (response.success === true && response.result === true) {
						regionHasOffices = true;
					} else {
						regionHasOffices = false;
					}
					$document.trigger('regionHasOfficesComplete');
				},
				error: function () {
					regionHasOffices = false;
					$document.trigger('regionHasOfficesComplete');
				}
			});
			ajaxInProgress = true;
		}
	} else {
		callback(regionHasOffices);
	}
};

export default regionHasOfficesService;