import TooltipPopover from '@/js/common/components/tooltip/Tooltip';
import './htmlTooltip.scss';

export default class TooltipPopoverHtml extends Backbone.Epoxy.View {
    initialize(options) {
        const $tooltipContent = $('.tooltipContent', this.$el);
        const html = $tooltipContent.html();
        const model = new Backbone.Model({
            popoverHtml: html,
        });
        $tooltipContent.remove();

        this.tooltip = new TooltipPopover({
            model,
            position: options.position,
            htmlField: 'popoverHtml',
            $activateElement: this.$el,
        });
    }
}