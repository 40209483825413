
    import _ from 'underscore';
    import {
        defineComponent, PropType, computed, toRefs, unref,
    } from 'vue';
    import { useState, useActions } from 'vuex-composition-helpers';
    import FiltersListSection from '@ve/components/searchFilters/filterList/desktop/FiltersListSection.vue';
    import ToggleListContent from '@ve/components/filters/common/components/toggleListContent/ToggleListContent.vue';
    import { IMapForFiltersSerpActions, IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import FilterListRender from '@ve/components/filters/common/components/FilterListRender/FilterListRender.vue';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import useFilterAttributesAnalytics from '@ve/components/filters/common/use/useFilterAttributesAnalytics';
    import FilterPostWrapper from '@ve/components/searchFilters/filterList/desktop/FilterPostWrapper.vue';
    import facilitiesFilterAttributeMethods from '../common/FacilitiesFilterAttributeMethods';

    export default defineComponent({
        components: {
            FiltersListSection,
            ToggleListContent,
            FilterListRender,
            FilterPostWrapper,
        },
        setup(props, { emit }) {
            const {
                takenFacilities, filteredHotels, syncHotels, isPopularityFilterActive,
            } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['takenFacilities', 'filteredHotels', 'syncHotels', 'isPopularityFilterActive']);
            const { action_takenFacilities: setFilterFacilities } = useActions<IMapForFiltersSerpActions>(nameFilterSerpStore, ['action_takenFacilities']);

            const selectedFacilities = computed({
                get: () => unref(takenFacilities),
                set: (values) => {
                    setFilterFacilities(values);
                },
            });
            const isSomeHotelHasFacility = computed(() => facilitiesFilterAttributeMethods.getIsOneHotelHasProperty(unref(syncHotels)));

            return {
                selectedFacilities,
                isPopularityFilterActive,
                filteredHotels,
                isSomeHotelHasFacility,
                syncHotels,
                facilitiesFilterAttributeMethods,
                ...useFilterAttributesAnalytics(facilitiesFilterAttributeMethods),
            };
        },
    });
