import { getCurrentInstance } from 'vue';

interface IUseVueProxy {
    context: Vue | undefined
    $root: Vue | undefined
}

export default (): IUseVueProxy => {
    const context = getCurrentInstance()?.proxy;

    return {
        context,
        $root: context?.$root,
    };
};