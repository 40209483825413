
    import { defineComponent, ref } from 'vue';

    export default defineComponent({
        name: 'FilterListItem',
        setup() {
            const isHover = ref(false);
            return {
                isHover,
            };
        },
    });
