
    import {
        defineComponent,
    } from 'vue';
    import useFilterSelected from '@ve/components/filters/common/use/useFilterSelected';
    import CheckBoxInput from '@ve/components/inputs/CheckBoxInput.vue';
    import HintTooltip from '@ve/components/hintTooltip/desktop/HintTooltip.vue';
    import TextCommon from '@ve/components/inputs/TextCommon.vue';

    export default defineComponent({
        name: 'warranted-filter',
        components: {
            CheckBoxInput,
            HintTooltip,
            TextCommon,
        },
        props: {
            selected: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, ctx) {
            return {
                ...useFilterSelected(props, ctx),
            };
        },
    });
