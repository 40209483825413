/* eslint-disable */
import CountriesRepository from "../Repository/Countries";
import ResortsRepository from "../Repository/Resorts";
import HotelsRepository from "../Repository/Hotels";
import CheckInDateRangeObjectValue from "../ObjectValue/CheckInDateRange";
import NightRangeObjectValue from "../ObjectValue/NightRange";
import TouristGroupObjectValue from "../ObjectValue/TouristGroup";
import HotelCategoriesRepository from "../Repository/HotelCategories";
import MealsRepository from "../Repository/Meals";
import DepartureCityEntity from "../Entity/DepartureCity";

let differencesTourCriteria = function (options) {
	var result = false,
		searchData = options.oldSearchCriteria,
		currentTourCriteria = options.newSearchCriteria,
		differenceList = options.differenceList || ["departureCity", "countries", "hotels", "resorts", "checkInDateRange", "nightRange", "touristGroup"],
		i, j,
		searchDataModel,
		currentTourCriteriaModel;
	for (i in differenceList) {
		searchDataModel = searchData.get(differenceList[i]);
		currentTourCriteriaModel = currentTourCriteria.get(differenceList[i]);

		if (!_.isNull(searchDataModel)) {
			if (searchDataModel instanceof CountriesRepository ||
				searchDataModel instanceof ResortsRepository ||
				searchDataModel instanceof HotelsRepository ||
				searchDataModel instanceof MealsRepository ||
				searchDataModel instanceof HotelCategoriesRepository) {
				if (currentTourCriteriaModel && searchDataModel.length == currentTourCriteriaModel.length) {
					for (j = 0; j < searchDataModel.length; j++) {
						if (currentTourCriteriaModel.at(j).get("id") != searchDataModel.at(j).get("id")) {
							result = true;
							break;
						}
					}
					if (result) break;
				} else {
					result = true;
					break;
				}
			}

			if (searchDataModel instanceof DepartureCityEntity) {
				result = currentTourCriteriaModel && currentTourCriteriaModel.get('id') !== searchDataModel.get('id');

				if (result) break;
			}

			if (searchDataModel instanceof CheckInDateRangeObjectValue) {
				result = currentTourCriteriaModel &&
					(currentTourCriteriaModel.get('from').getTime() != searchDataModel.get('from').getTime() ||
						currentTourCriteriaModel.get('to').getTime() != searchDataModel.get('to').getTime());

				if (result) break;
			}

			if (searchDataModel instanceof NightRangeObjectValue) {
				result = currentTourCriteriaModel &&
					(currentTourCriteriaModel.get('from') != searchDataModel.get('from') ||
						currentTourCriteriaModel.get('to') != searchDataModel.get('to'));

				if (result) break;
			}
			if (searchDataModel instanceof TouristGroupObjectValue) {
				result = currentTourCriteriaModel &&
					(currentTourCriteriaModel.get('adults') != searchDataModel.get('adults') ||
						currentTourCriteriaModel.get('kids') != searchDataModel.get('kids') ||
						currentTourCriteriaModel.get('infants') != searchDataModel.get('infants'));

				if (result) break;
			}
		}
	}
	return result;
};
export default differencesTourCriteria