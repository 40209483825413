
    import {
        defineComponent, PropType,
    } from 'vue';

    export default defineComponent({
        name: 'TextCommon',
        props: {
            disable: {
                type: Boolean,
                default: false,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
            typeText: {
                type: String as PropType<'text' | 'value'>,
                default: 'text',
            },
            size: {
                type: String as PropType<'medium' | 'small'>,
                default: 'small',
            },
        },
    });
