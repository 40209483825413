import type { DepartureCity } from '@ve/types';
import { NAMESPACE as MAP_FOR_SEARCH_DATA } from '@ve/pages/serp/store/SearchDataStore';

import { IAlternativeSearchStore } from '@ve/components/alternativeDeparture/common/store/types/AlternativeSearchStore';

export const NAMESPACE = 'mapForAlternativeSearch';

export default {
    namespaced: true,
    state: () => ({
        directoryDepartureCities: [], //справочник всех departureCity
        idDefaultDepartureCity: 2, // id Москвы, дефолтный id с которого начинается каждый новый альтернативный поиск
        idCurrentDepartureCity: 2,
        emptyDepartureCities: [],
    }),
    mutations: {
        setDefaultState(state) {
            state.emptyDepartureCities = [];
            state.idCurrentDepartureCity = state.idDefaultDepartureCity;
        },
        setDepartureCities(state, directoryDepartureCities: DepartureCity[]) {
            state.directoryDepartureCities = directoryDepartureCities;
        },
        setDepartureCity(state, departureCityId: number) {
            state.idCurrentDepartureCity = departureCityId;
        },
        addEmptyCities(state, departureCity: number | null) {
            if (departureCity) {
                state.emptyDepartureCities.push(departureCity);
            }
        },
    },
    actions: {
        clearAlternativeSearchData({ commit, state, rootState }) {
            const { searchDepartureCity } = rootState[MAP_FOR_SEARCH_DATA];

            commit('setDepartureCity', state.idDefaultDepartureCity);
            commit('addEmptyCities', searchDepartureCity.id);
        },
        noToursFoundForCurrentDeparture({ commit, state }) {
            commit('addEmptyCities', state.idCurrentDepartureCity);
            commit('setDepartureCity', state.idDefaultDepartureCity);
        },
    },
    getters: {
        prevDepartureCityIsDifferentFromCurrentDepartureCity(state, getters, rootState) {
            const prevDepartureCity = rootState[MAP_FOR_SEARCH_DATA].searchDepartureCity;
            return state.idCurrentDepartureCity !== prevDepartureCity.id;
        },
        getAvailableCities(state) {
            const availableCitiesId = [2, 29, 64, 25, 53, 1]; // Москва - Казань - Самара - Екатеринбург - Новосибирск - Санкт-Петербург
            const { directoryDepartureCities } = state;
            return directoryDepartureCities.filter(
                (city: DepartureCity) => availableCitiesId.includes(city.id),
            );
        },
        getAvailableCityWithoutCurrentCity(state, getters) {
            const availableCities = getters.getAvailableCities;
            return availableCities?.filter((city: DepartureCity) => state.idCurrentDepartureCity !== city.id);
        },
        getAvailableCitiesWithoutEmptyDepartures(state, getters) {
            const { emptyDepartureCities } = state;
            const { getAvailableCityWithoutCurrentCity } = getters;

            return getAvailableCityWithoutCurrentCity?.filter(
                (city: DepartureCity) => !emptyDepartureCities.includes(city.id),
            );
        },
        getNameCurrentDepartureCity(state, getters) {
            const { getAvailableCities } = getters;
            const currentDepartureCity = getAvailableCities?.find((deparutreCity: DepartureCity) => deparutreCity.id === state.idCurrentDepartureCity);
            return currentDepartureCity?.name;
        },
    },
} as IAlternativeSearchStore;