import { ITourProduct } from '../types/ITourProduct';
import { IBackboneTourProduct } from '../types/IBackboneTourProduct';

export const processAdaptedTourProduct = (backboneTourProduct: IBackboneTourProduct): ITourProduct | null => {
    const tourProduct = backboneTourProduct.get('tour');
    if (!tourProduct) return null;
    return {
        id: backboneTourProduct.get('id'),
        price: tourProduct.get('price'),
        oilTax: tourProduct.get('oilTax'),
        operator: tourProduct.get('operator'),
        priceWithOilTax: tourProduct.get('priceWithOilTax'),
        hotelAvailable: tourProduct.get('hotelAvailable'),
        flightType: tourProduct.get('flightType'),
        medicalInsurance: tourProduct.get('medicalInsurance'),
        transferType: tourProduct.get('transferType'),
        firstPaymentDefinitions: tourProduct.get('firstPaymentDefinitions'),
        availableTransfers: [],
        country: null,
        room: null,
        hotel: null,
    };
};

export default function processAdaptedTourProducts(backboneTourProducts: IBackboneTourProduct[] | null | undefined): ITourProduct[] {
    if (!backboneTourProducts) return [];
    const adaptedTourProducts = backboneTourProducts.map((backboneTourProduct) => processAdaptedTourProduct(backboneTourProduct));
    return adaptedTourProducts.filter((tourProduct) => !!tourProduct) as ITourProduct[];
}