/* eslint-disable */
export default function getQtyParams(foundTours) {
    const toursWithoutOil = [];
    const differentOperatorsQty = {};
    const differentRoomsQty = {};
    const differentMealsQty = {};
    const operators = {};

    if (foundTours.models && foundTours.models.length) {
        foundTours.models.forEach((model) => {
            const product = model.get('product');
            const operatorId = product.get('operator').id;

            operators[operatorId] = ++operators[operatorId] || 1;

            differentOperatorsQty[operatorId] = true;
            differentMealsQty[product.get('meal').get('id')] = true;
            differentRoomsQty[product.get('room').name] = true;
            if (product.get('oilTaxIsAbsent') === true) {
                toursWithoutOil.push(model.get('id'));
            }
        });
    }
    return {
        toursWithoutOil: toursWithoutOil.length,
        differentOperatorsQty: _.keys(differentOperatorsQty).length,
        differentMealsQty: _.keys(differentMealsQty).length,
        differentRoomsQty: _.keys(differentRoomsQty).length,
        operators: JSON.stringify(operators),
    };
};