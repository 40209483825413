/* eslint-disable */
import 'jquery.cookie';

import { operators } from '@fr/constants';

import checkTourCashbackAvailable from '@/js/common/Domain/Service/checkTourCashbackAvailable';
import checkProductCashbackAvailable from '@/js/common/Domain/Service/checkProductCashbackAvailable';
import userInterestsService from '../Service/userInterests';

const sortRateIncRate = parseInt($.cookie('sortRateIncRate') || 1, 10);
const userInterestsIncRate = parseInt($.cookie('userInterestsIncRate') || 0, 10);

const HotelEntity = Backbone.Model.extend({
    defaults: {
        id: null,
        name: null,
        category: null,
        isPopular: false,
        highlyRated: false,
        resort: null,
        cover: null,
        photos: [],
        imagesHtml: null,
        rating: null,
        ratingDetails: null,
        reviewsQty: null,
        toursQuantity: null,
        url: null,
        tourProducts: null,
        hotelOffers: null,
        meta: null,
        map: null,
        ordersCount: null,
        /* was computeds */
        cityTours: false,
        wellness: false,
        withKids: false,
        airConditioner: false,
        wifi: false,
        adultsOnly: false,
        forCouples: false,
        nearTheLift: false,
        warranted: false,
        nearTheCenter: false,
        withAnimals: false,
        spaAndHealth: false,
        mountains: false,
        eco: false,
        pool: false, //
        lonelyMan: false,
        cashback: false,
        numOfTourists: false,
        nearTheBeach: false,
        beachLine: false,
        beachOwner: false,
        excursions: false,
        sandBeach: false,
        pebbleBeach: false,
        sortRate: null,
        sandPebbleBeach: false,
        userInterestGroups: [],
        isExclusive: false,
        oilTaxIsAbsent: null,
    },
    getCover(callback) {
        const image = this.get('cover');
        const self = this;
        const hotelId = this.get('id');
        let xhr;

        if (image === null) {
            xhr = $.ajax({
                url: `/hotel/photos?id=${hotelId}`,
            }).done((response) => {
                const { result } = response;
                if (response.success == true) {
                    self.set('cover', result.cover);
                    self.set('photos', result.photos);
                    callback ? callback(self) : null;
                }
            });

            this.set('cover', xhr);
        } else if (typeof image.readyState === 'undefined') {
            callback ? callback(self) : null;
            return image;
        }

        return null;
    },
    initWarrantedOption() {
        const tourProducts = this.get('tourProducts');
        const warranted = tourProducts.find((tourProduct) => tourProduct.get('tour').get('hotelAvailable') === 1);
        this.set('warranted', !!warranted);
    },
    initIsExclusive() {
        const tourProducts = this.get('tourProducts');
        const isExclusive = tourProducts.at(0).get('tour').get('operator')?.id === operators.MY_AGENT;
        this.set('isExclusive', !!isExclusive);
    },
    initHotelOptions() {
        const tourProducts = this.get('tourProducts');
        const tour = tourProducts.at(0).get('tour');
        const priceWithOilTax = tour.get('priceWithOilTax');
        const oilTaxIsAbsent = tour.get('oilTaxIsAbsent');
        const userInterestsGroups = userInterestsService.getInterestsGroups();
        const interestsIntersection = _.intersection(this.get('userInterestGroups'), userInterestsGroups);
        const userInterestsData = userInterestsService.getInterestsData();
        let userInterestsRate = 0;
        const hasTourCashbackAvailable = checkTourCashbackAvailable(tourProducts);
        const cashback = !!(window?.appConfig?.cashbackAvailable && hasTourCashbackAvailable);

        const serverSortRate = tour.get('sortRate') || 0;
        _.each(interestsIntersection, (interest) => {
            userInterestsRate += userInterestsData[interest].weight;
        });
        userInterestsRate += 1;

        const resultSortRate = userInterestsRate ? (sortRateIncRate * serverSortRate + userInterestsRate * userInterestsIncRate) : '';

        this.set('debugInfo', `Server sortRate:${serverSortRate.toFixed(4)}${userInterestsRate ? (` InterestGroupsIntersection:${interestsIntersection.join(', ')} ResultGroupsIntersectionRate:${userInterestsRate.toFixed(4)}`) : ''} ResultSortrate:${resultSortRate ? resultSortRate.toFixed(8) : ''}`);

        this.set('minPrice', priceWithOilTax);
        this.set('sortRate', resultSortRate);
        this.set('cashback', cashback);
        this.set('priceWithOilTax', priceWithOilTax);
        this.set('oilTaxIsAbsent', oilTaxIsAbsent);
        this.initWarrantedOption();
        this.initIsExclusive();
    },
    initHotelOffersOptions() {
        const hotelOffers = this.get('hotelOffers');
        let minPrice = hotelOffers.at(0).get('price');

        const hasHotelOfferCashbackAvailable = checkProductCashbackAvailable(hotelOffers);
        const cashback = !!(window?.appConfig?.cashbackAvailable && hasHotelOfferCashbackAvailable);

        minPrice = minPrice || hotelOffers.at(0).get('price');

        const sortRate = hotelOffers.max((hotelOffer) => hotelOffer.get('sortRate')).get('sortRate');

        this.set('minPrice', minPrice);
        this.set('priceWithOilTax', minPrice);
        this.set('sortRate', sortRate);
        this.set('cashback', cashback);
        this.initWarrantedOptionHotelOffers();
    },
    initRailwayToursOptions() {
        const railwayTours = this.get('railwayTours');
        let minPrice = railwayTours.at(0).get('price');

        const hasHotelOfferCashbackAvailable = checkProductCashbackAvailable(railwayTours);
        const cashback = !!(window?.appConfig?.cashbackAvailable && hasHotelOfferCashbackAvailable);

        minPrice = minPrice || railwayTours.at(0).get('price');

        const sortRate = railwayTours.max((hotelOffer) => hotelOffer.get('sortRate')).get('sortRate');

        this.set('minPrice', minPrice);
        this.set('priceWithOilTax', minPrice);
        this.set('sortRate', sortRate);
        this.set('cashback', cashback);
    },
    initWarrantedOptionHotelOffers() {
        const hotelOffers = this.get('hotelOffers');
        const warranted = hotelOffers.find((hotelOffer) => hotelOffer.get('hotelAvailable') === 1);
        this.set('warranted', !!warranted);
    },
});
export default HotelEntity;
