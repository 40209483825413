/* eslint-disable */
import AgeModel from "@/js/common/Domain/Entity/AgeModel";
import {propsDecorator} from "@/js/common/Domain/Service/mixins/decorators";
import AgesCollection
	from "./AgesCollection";
import AGES_REPOSITORY from "./agesRepository";

@propsDecorator({
	defaults:{
		collection: new AgesCollection(AGES_REPOSITORY)
	}
})

export default class ChildesAge extends Backbone.Epoxy.Model{
	initialize(){
		this.set({model: new AgeModel()})
	}
}