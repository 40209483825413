import { SerpHotelAdapted } from '@fr/vue/components/searchFilters/store/types';
import { DESKTOP_SEARCH_PAGE_BANNER_STEP } from '@/js/common/Constants/bannerSteps';
import DataLayerAnalytics from '@/js/common/Domain/Service/DataLayerAnalytics';

export default function (nameLayer: string, checked: boolean, filteredHotels: SerpHotelAdapted[]) {
    const dataLayerAnalytics = new DataLayerAnalytics({
        step: 'searchPage',
        maxStep: 1,
        bannersStep: DESKTOP_SEARCH_PAGE_BANNER_STEP,
    });
    DataLayerAnalytics.toggleFilter(checked, nameLayer);
    dataLayerAnalytics.filterCountEvent(filteredHotels.length);
    if (filteredHotels.length) {
        dataLayerAnalytics.generateTop20Event(filteredHotels);
    }
}