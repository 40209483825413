
    import {
        defineComponent, ref, unref, watch, computed,
    } from 'vue';
    import { useState, useMutations } from 'vuex-composition-helpers';
    import { IMapForFiltersSerpState } from '@ve/components/searchFilters/store/types';
    import CheckBoxInputList from '@ve/components/inputs/CheckBoxInputList.vue';
    import ButtonCommon from '@ve/components/button/desktop/Button.vue';
    import { nameFilterSerpStore } from '@ve/components/searchFilters/store/getFiltersSerpStore';
    import syncApplyingItems from './services/previousFilterApplyingItems';
    import useFilterApplyingMethods from './use/useFilterApplyingMethods';
    import useAdditionalApplyingItems from './use/useAdditionalApplyingItems';
    import getApplyingAttributesCount from './services/getApplyingAttributesCount';
    import useFilterApplyingTourOperator from './use/useFilterApplyingTourOperator';
    import {
        IFilterPreviousState, IFilterApplyingItem, IFilterPreviousMutations, IReturnAdditionalPreviousFilter,
    } from './types';

    export default defineComponent({
        components: {
            CheckBoxInputList,
            ButtonCommon,
        },
        setup() {
            const { previousStateFiltersSerp, numberOfTourProducts } = useState<IFilterPreviousState>('previousFilterStore', ['previousStateFiltersSerp', 'numberOfTourProducts']);
            const { setDefaultPreviousStore } = useMutations<IFilterPreviousMutations>('previousFilterStore', ['setDefaultPreviousStore']);
            const { syncHotels } = useState<IMapForFiltersSerpState>(nameFilterSerpStore, ['syncHotels']);
            const { applyingHandler } = useFilterApplyingMethods();
            const { applyingAdditionalFilters, additionalPreviousFilterItems } = useAdditionalApplyingItems();
            const { tourOperatorApplyingFilterList } = useFilterApplyingTourOperator();

            const idsDeletedApplyingFilterByUser = ref<number[]>([]);
            const idsTakenApplyingFilter = ref<number[]>([]);
            const unAvailableAttributesIds = ref<number[]>([]);

            const addDeleteIdsApplyingItems = ({ value, checked }: { value: number, checked: boolean }) => {
                const isUserDeleteAttribute = !checked;
                if (isUserDeleteAttribute) {
                    unref(idsDeletedApplyingFilterByUser).push(value);
                    return;
                }
                idsDeletedApplyingFilterByUser.value = unref(idsDeletedApplyingFilterByUser).filter((id) => id !== value);
            };

            const getTextAttributePrevious = (applyingItem: IFilterApplyingItem): string => {
                const filterName = applyingItem.filterAttributeMethod.filterName ? `${applyingItem.filterAttributeMethod.filterName}: ` : '';
                if (Array.isArray(applyingItem.valueState)) {
                    return `${filterName}${applyingItem.filterAttributeMethod.getTextAttributesByIds(applyingItem.valueState)}`;
                }
                return `${filterName}${applyingItem.filterAttributeMethod.getTextAttributesByIds([applyingItem.valueState])}`;
            };
            const filtersApplyingAttributes = computed(() => getApplyingAttributesCount(unref(previousStateFiltersSerp), syncApplyingItems, unref(syncHotels)));
            const allAvailableFiltersApplyingAttributes = computed(() => [
                ...unref(filtersApplyingAttributes).availableFiltersAttributes,
                ...unref(tourOperatorApplyingFilterList).availableFiltersAttributes,
            ]);

            const allUnAvailableFiltersApplyingAttributes = computed(() => [
                ...unref(filtersApplyingAttributes).unAvailableFiltersAttributes,
                ...unref(tourOperatorApplyingFilterList).unAvailableFiltersAttributes,
            ]);

            const isVisiblePreviousFilter = computed(() => {
                const isSomeFilterAttributesIsExists = unref(allUnAvailableFiltersApplyingAttributes).length
                    || unref(allAvailableFiltersApplyingAttributes).length
                    || unref(additionalPreviousFilterItems).availableFilters.length
                    || unref(additionalPreviousFilterItems).unAvailableFilters.length;
                return unref(numberOfTourProducts) && isSomeFilterAttributesIsExists;
            });

            watch([
                allAvailableFiltersApplyingAttributes,
                allUnAvailableFiltersApplyingAttributes,
                additionalPreviousFilterItems,
            ], (value) => {
                const [
                    availableFiltersApplyingAttributes,
                    unnAvailableFiltersApplyingAttributes,
                    additionalFilterItems,
                ] = value as [IFilterApplyingItem[], IFilterApplyingItem[], IReturnAdditionalPreviousFilter];
                idsTakenApplyingFilter.value = [...availableFiltersApplyingAttributes, ...additionalFilterItems.availableFilters].reduce((acc, applyingItem) => {
                    const isUserDeleteAvailableFilterAttribute = unref(idsDeletedApplyingFilterByUser).indexOf(applyingItem.id) !== -1;
                    if (isUserDeleteAvailableFilterAttribute) {
                        return acc;
                    }
                    acc.push(applyingItem.id);
                    return acc;
                }, [] as number[]);
                unAvailableAttributesIds.value = [...unnAvailableFiltersApplyingAttributes, ...additionalFilterItems.unAvailableFilters].map((applyingItem) => applyingItem.id);
            });

            const isVisibleButtons = computed(() => unref(additionalPreviousFilterItems).availableFilters.length || unref(allAvailableFiltersApplyingAttributes).length);

            watch(previousStateFiltersSerp, (value) => {
                if (!value) {
                    idsDeletedApplyingFilterByUser.value = [];
                }
            });

            return {
                allUnAvailableFiltersApplyingAttributes,
                allAvailableFiltersApplyingAttributes,
                isVisiblePreviousFilter,
                filtersApplyingAttributes,
                getTextAttributePrevious,
                idsTakenApplyingFilter,
                unAvailableAttributesIds,
                setDefaultPreviousStore,
                applyingHandler,
                applyingAdditionalFilters,
                addDeleteIdsApplyingItems,
                additionalPreviousFilterItems,
                idsDeletedApplyingFilterByUser,
                isVisibleButtons,
            };
        },
    });
