/* eslint-disable @typescript-eslint/ban-ts-comment */
import Backbone from 'backbone';
import moment from 'moment';
import _ from 'underscore';
// @ts-ignore-start
import WATER_ACTIVITIES_REPOSITORY
    from '@/js/common/components/filterCriteria/waterActivities/WaterActivitiesRepository';
// @ts-ignore-end
import OperatorsRepository from '@/js/common/Domain/Repository/Operators';
import { childServiceFilterMethods, restTypeFilterMethods, beachTypeFilterMethods } from '@features/hotel/filterHotels';
import { filterAttributeHotelMethodsByAll }
    from '@fr/vue/components/filters/common/filterMethods/filterAttributeMethodsHotelBoolean/FilterAttributeHotelMethodsByAll';
import { type BackboneFiltersCriteriaModel }
    from '@fr/vue/types/backboneModels/BackboneFiltersCriteriaModel';
import { type IHotelOfferTariff } from '@ve/components/hotel/hotelOffersTariffsPopup/common/types';
import facilitiesFilterAttributeMethods from '@ve/pages/serp/components/filters/facilitiesFilter/common/FacilitiesFilterAttributeMethods';
import filterAttributeMethodsWaterActivities
    from '@ve/pages/serp/components/filters/filterWaterActivities/common/FilterMethodsWaterActivities';
import { type TouristGroup } from '@ve/types';
import { type BackboneSearchFormModel } from '@ve/types/backboneModels/BackboneSearchFormModel';
import {
    type IfieldsForSerpCommmon,
    type IFieldsForSerpClick,
    type IFiledsForSerp,
    type IFiledsForHotelPage,
    type IFieldsForTourPage,
    type IFieldsForCheckoutRedirect,
    type IFieldsForCheckoutRedirectHotellOffer,
} from './MindBoxTypes';

type TSerpCategory = 'serp_main' | 'serp_hotel' | 'serp_hot' | 'serp_railway';

const FILTER_AMENITIES_MAP = {
    lonelyMan: 'Принимает одиноких мужчин',
    nearTheCenter: 'Близко к центру',
    nearTheBeach: 'Близко к пляжу',
    withKids: 'Отдых с детьми',
    forCouples: 'Идеально для пар',
    airConditioner: 'Кондиционер',
    wifi: 'Интернет',
    wellness: 'Оздоровительные туры',
    pool: 'Бассейн',
    withAnimals: 'Отдых с животными',
    spaAndHealth: 'Спа',
    mountains: 'В горах',
    eco: 'Эко отель',
};

const FILTER_CHILD_SERVICE_MAP = {
    children_animators: 'Детская анимация',
    children_seaEnter: 'Удобный вход в море',
    children_childmenu: 'Детское меню',
    children_childBed: 'Детские кроватки',
    children_nanny: 'Услуги няни (платно)',
    children_clubs: 'Детские мини-клубы',
    children_pool: 'Детский бассейн',
    children_playroom: 'Игровая комната',
};

const FILTER_RATING_MAP = {
    best: '4.5',
    better: '4.0',
    good: '3.5',
};

const FILTER_BEACHTYPE_MAP = {
    sandBeach: 'Песчаный',
    pebbleBeach: 'Галечный',
};

const FILTER_BEACHLINE_MAP = {
    firstLine: 1,
    secondLine: 2,
    thirdLine: 3,
};

export default class MindBoxDataHydrator {
    private getPostFilters(filterCriteria: BackboneFiltersCriteriaModel, mapper: Record<string, string | number>): Array<number | string> {
        const adaptedFilterCriteria: Record<string, string | number> = {
            ...filterCriteria.attributes,
            ...filterAttributeHotelMethodsByAll.getMergedAttributes(filterCriteria?.get('takenHotelAttributes')),
            ...facilitiesFilterAttributeMethods.getMergedAttributes(filterCriteria?.get('takenFacilities')),
            ...childServiceFilterMethods.getMergedAttributesIdsByKeys(filterCriteria?.get('childServiceFilterIds')),
            ...restTypeFilterMethods.getMergedAttributes([filterCriteria.get('restType')]),
        };

        const keys = Object.keys(mapper);
        const rawFilters = _.pick(adaptedFilterCriteria, keys);
        const filters = _
            .pairs(rawFilters)
            .filter(([_filterName, value]) => value)
            .map(([filterName]) => mapper[filterName]);

        if (filters.length === 0 && adaptedFilterCriteria.typeFilterRating) {
            return [mapper[adaptedFilterCriteria.typeFilterRating]];
        }

        return filters;
    }

    /**
     * на всех серпах кроме hotel serp в фильтрах по категории - 1/2 звезды обьеденены и в моделе хранится это как 2
     * нужна проверка для верного трека
     */
    private getHotelStars(tourCriteria: BackboneSearchFormModel, serpCategory: TSerpCategory): Array<number | string> {
        const hotelCategories = tourCriteria.get('hotelCategories')?.get('model') ?? tourCriteria.get('hotelCategories');
        const mappedStars = hotelCategories.map((categoryModel) => {
            const stars = categoryModel.get('stars');
            const isAlternativeTypeHotelWithoutStars = categoryModel.get('name')?.length > 2;

            return isAlternativeTypeHotelWithoutStars ? categoryModel.get('name') : stars;
        });
        const shouldAddOneStar = serpCategory !== 'serp_hotel' && !!~_.indexOf(mappedStars, 2) && !~_.indexOf(mappedStars, 1);

        if (shouldAddOneStar) {
            mappedStars.push(1);
        }

        return mappedStars;
    }

    private getHotelBeachLine(filterCriteria: Backbone.Model): number[] {
        if (filterCriteria.get('typeLines')) {
            return filterCriteria.get('typeLines');
        }

        const rawBeachLines = {
            firstLine: filterCriteria.get('firstLine'),
            secondLine: filterCriteria.get('secondLine'),
            thirdLine: filterCriteria.get('thirdLine'),
        };

        return _.pairs(rawBeachLines)
            .filter(([_filterName, value]) => value)
            .map(([filterName]) => FILTER_BEACHLINE_MAP[filterName]);
    }

    private getHotelMeals(tourCriteria: BackboneSearchFormModel): string[] {
        const meals = tourCriteria.get('meals')?.get('model') ?? tourCriteria.get('meals');

        return meals.map((meal) => meal.get('name'));
    }

    private getOperatorsList(filterCriteria: Backbone.Model): string[] | null {
        if (!filterCriteria.get('operators')?.length) {
            return null;
        }

        const operators: number[] = filterCriteria.get('operators').models
            ? filterCriteria.get('operators').models
            : filterCriteria.get('operators');

        if (operators.length === 0) {
            return null;
        }

        return operators
            .filter((activeOperatorId) => (
                OperatorsRepository.repository.some((operator: Backbone.Model) => operator.get('id') === activeOperatorId)
            ))
            .map((activeOperatorId) => (
                OperatorsRepository.repository.find((operator: Backbone.Model) => operator.get('id') === activeOperatorId)
            )
            .get('name'));
    }

    private getChildren(ages: string[]): Record<string, number> {
        return ages.reduce((acc, rawAge, i) => {
            const age = +rawAge <= 1 ? 0.5 : +rawAge;
            acc[`childrenage${i + 1}`] = age;
            return acc;
        }, {} as Record<string, number>);
    }

    private getTouristGroupList(tourCriteria: Backbone.Model | Record<string, string | number>): Record<string, number> {
        const touristGroup: TouristGroup = tourCriteria instanceof Backbone.Model
            ? tourCriteria?.get('touristGroup')
            : tourCriteria?.touristGroup;

        let adults;
        let kids;
        let infants;
        let ages;

        if (touristGroup instanceof Backbone.Model) {
            adults = touristGroup.get('adults') ?? touristGroup.get('model')?.get('adults');
            kids = touristGroup.get('kids') ?? touristGroup.get('model')?.get('kids');
            infants = touristGroup.get('infants') ?? touristGroup.get('model')?.get('infants');
            ages = touristGroup.get('ages') ?? touristGroup.get('model')?.get('ages');
        } else {
            adults = touristGroup.adults;
            kids = touristGroup.kids;
            infants = touristGroup.infants;
            ages = touristGroup.ages || touristGroup.kidsAges;
        }

        const countInfantsInAgesArray = ages.reduce((count: number, age: number | string): number => {
            if (+age <= 1) {
                count++;
            }
            return count;
        }, 0);

        const isInfantsAgesExistInAgesArray = countInfantsInAgesArray === infants;

        if (isInfantsAgesExistInAgesArray) {
            return {
                adults,
                children: kids + infants,
                ...this.getChildren(ages),
            };
        }

        for (let i = 0; i < infants; i++) {
            ages.push(0);
        }

        return {
            adults,
            children: kids + infants,
            ...this.getChildren(ages),
        };
    }

    private getDatesList(tourCriteria: Backbone.Model | Record<string, string | number>): Record<string, string | number> {
        let checkInDateRange;
        let nightRange;

        if (tourCriteria instanceof Backbone.Model) {
            checkInDateRange = tourCriteria?.get('checkInDateRange');
            nightRange = tourCriteria?.get('nightRange');
        } else {
            checkInDateRange = tourCriteria?.checkInDateRange;
            nightRange = tourCriteria?.nightRange;
        }

        if (typeof (checkInDateRange) === 'string') {
            return {
                dateFrom: checkInDateRange,
            };
        }

        const nightsfrom = nightRange
            ? nightRange.get('from') || nightRange.get('model').get('from')
            : 0;

        const nightsto = nightRange
            ? nightRange.get('to') || nightRange.get('model').get('to')
            : 0;

        const dateFrom = checkInDateRange
            ? moment(checkInDateRange.get('from') ?? checkInDateRange?.get('model')?.get('from')).format('YYYY-MM-DD')
            : 0;

        const dateTo = checkInDateRange
            ? moment(checkInDateRange.get('to') ?? checkInDateRange?.get('model')?.get('to')).format('YYYY-MM-DD')
            : 0;

        return {
            dateFrom,
            dateTo,
            nightsfrom,
            nightsto,
        };
    }

    private getWaterActivitiesFilterList(filterCriteria: BackboneFiltersCriteriaModel): string[] {
        return filterCriteria.get('takenWaterActivities')?.reduce((acc, idWaterActivity) => {
            const waterActivityAttribute = filterAttributeMethodsWaterActivities.getAttributeById(idWaterActivity);
            if (waterActivityAttribute) {
                acc.push(waterActivityAttribute.name);
            }
            return acc;
        }, [] as string[]);
    }

    private removeEmptyFields<T>(params: T): T {
        return _.pick(params, (value) => {
            if (_.isArray(value) && (value.length === 0 || _.isNull(value[0]) || _.isUndefined(value[0]))) {
                return false;
            }

            return !_.isNull(value) && !_.isUndefined(value);
        }) as T;
    }

    private getBeachType(filterCriteria: Backbone.Model): string | null {
        if (filterCriteria.get('takenBeachType')) {
            const beachType = beachTypeFilterMethods
                .getKeysAttributeByIds(filterCriteria.get('takenBeachType'))[0] as keyof typeof FILTER_BEACHTYPE_MAP;

            return beachType && beachType in FILTER_BEACHTYPE_MAP
                ? FILTER_BEACHTYPE_MAP[beachType]
                : null;
        }

        const bearchType = filterCriteria.get('beachType') as unknown as keyof typeof FILTER_BEACHTYPE_MAP;

        return bearchType
            ? FILTER_BEACHTYPE_MAP[bearchType]
            : null;
    }

    private getBeachOwner(filterCriteria: Backbone.Model): number {
        if (filterCriteria.get('takenHotelAttributes')) {
            return filterAttributeHotelMethodsByAll.getKeysAttributeByIds(filterCriteria.get('takenHotelAttributes')).includes('beachOwner') ? 1 : 0;
        }
        return filterCriteria.get('beachOwner') ? 1 : 0;
    }

    private prepareIdentities(tourPageModel?: Backbone.Model): Record<string, string> {
        if (!tourPageModel) {
            return {};
        }

        const identities: string[] = tourPageModel.get('identities') || tourPageModel.get('tourProductIdentities');

        return identities
            .slice(0, 9)
            .reduce((acc, identity, index) => ({ ...acc, [`Touridentity${index + 1}`]: identity }), {});
    }

    /**
     * filterbudgetfrom, filterbudgetto тут стоит костыль, из за неверной работы фильтры по бюджету мобвеб
     */
    private fieldsForSerpCommmon(serpCategory: TSerpCategory, filterCriteria: Backbone.Model): IfieldsForSerpCommmon {
        const [_empty, serptype] = serpCategory.split('_');
        const [filterhotelrating] = this.getPostFilters(filterCriteria, FILTER_RATING_MAP);
        const filterbudgetfrom = +filterCriteria.get('minPrice') === 100
            ? null
            : filterCriteria.get('minPrice');
        const filterbudgetto = +filterCriteria.get('maxPrice') === 5000000
            ? null
            : filterCriteria.get('maxPrice');

        return {
            serptype,
            filterbeachtype: this.getBeachType(filterCriteria),
            filteramenities: this.getPostFilters(filterCriteria, FILTER_AMENITIES_MAP),
            filterbudgetfrom,
            filterbudgetto,
            filterclosetoski: filterCriteria.get('nearTheLift') ? 1 : 0,
            filterexclusive: filterCriteria.get('isExclusive') ? 1 : 0,
            filterhotelrating,
            filterlinebeach: this.getHotelBeachLine(filterCriteria),
            filterownedbeach: this.getBeachOwner(filterCriteria),
            filterwateractivities: this.getWaterActivitiesFilterList(filterCriteria),
            filterchildservice: this.getPostFilters(filterCriteria, FILTER_CHILD_SERVICE_MAP),
        };
    }

    public fieldsForSerpClick(
        serpCategory: TSerpCategory,
        { searhPageModel, collectionViewModel }: { searhPageModel: Backbone.Model, collectionViewModel: Backbone.Collection },
    ): IFieldsForSerpClick {
        const product = searhPageModel.get('tourProducts')?.at(0)
            || searhPageModel.get('hotelOffers')?.at(0)
            || searhPageModel.get('railwayTours')?.at(0);

        const tourCriteria = product.get('tourCriteria');
        const filterCriteria = collectionViewModel.get('filterCriteria');
        const searchData = collectionViewModel.get('searchData');

        const countriesModels: Backbone.Collection = searchData?.get('countries')?.models;
        const resortsModels: Backbone.Collection = searchData?.get('resorts')?.models;
        const dates = this.getDatesList(tourCriteria);
        const touristGroup = this.getTouristGroupList(tourCriteria);
        const filterhotelstars = this.getHotelStars(tourCriteria, serpCategory);

        const [_empty, serptype] = serpCategory.split('_');

        const customFields: IFieldsForSerpClick = {
            ...touristGroup,
            ...dates,
            ...this.fieldsForSerpCommmon(serpCategory, filterCriteria),
            cityFrom: serptype === 'hotel' ? null : tourCriteria.get('departureCity')?.get('name'),
            countryId: countriesModels.map((countryModel) => countryModel.get('id')),
            countryName: countriesModels.map((countryModel) => countryModel.get('name')),
            resortId: resortsModels?.map((resortModel) => resortModel.get('id')),
            resortName: resortsModels?.map((resortModel) => resortModel.get('name')),
            filterhighconfirmation: tourCriteria.get('warranted') ? 1 : 0,
            filterhotelstars,
            filtermeal: this.getHotelMeals(tourCriteria),
            filtertouroperators: this.getOperatorsList(filterCriteria),
        };

        return this.removeEmptyFields<typeof customFields>(customFields);
    }

    public fieldsForSerp(serpCategory: TSerpCategory, searhPageModel: BackboneSearchFormModel): IFiledsForSerp {
        const dates = this.getDatesList(searhPageModel);
        const touristGroup = this.getTouristGroupList(searhPageModel);
        const countriesModels = searhPageModel?.get('countries').get('model');
        const resortsModels = searhPageModel?.get('resorts').get('model');
        const filterhotelstars = this.getHotelStars(searhPageModel, serpCategory);

        const [_empty, serptype] = serpCategory.split('_');

        const customFields: IFiledsForSerp = {
            ...dates,
            ...touristGroup,
            serptype,
            cityFrom: serptype === 'hotel' ? null : searhPageModel?.get('departureCity')?.get('model')?.get('name'),
            countryId: countriesModels.map((countryModel) => countryModel.get('id')),
            countryName: countriesModels.map((countryModel) => countryModel.get('name')),
            resortId: resortsModels?.map((resortModel) => resortModel.get('id')),
            resortName: resortsModels?.map((resortModel) => resortModel.get('name')),
            filterhighconfirmation: searhPageModel.get('warranted')?.get('model') ? 1 : 0,
            filterhotelstars,
            filtermeal: this.getHotelMeals(searhPageModel),
        };

        return this.removeEmptyFields(customFields);
    }

    public fieldsForHotelPage(tourCriteria: Backbone.Model, filterCriteria: Backbone.Model): IFiledsForHotelPage {
        const touristGroup = this.getTouristGroupList(tourCriteria);
        const dates = this.getDatesList(tourCriteria);
        const filtermeal = this.getHotelMeals(tourCriteria);
        const countryModel = tourCriteria.get('countries')?.at(0);
        const resortModel = tourCriteria.get('resorts')?.at(0);
        const cityFrom = filterCriteria?.get('departureCity')?.get('model')?.get('name')
            ?? tourCriteria.get('departureCity')?.get('name');

        const customFields: IFiledsForHotelPage = {
            ...touristGroup,
            ...dates,
            countryName: [countryModel?.get('name')],
            resortName: [resortModel?.get('name')],
            cityFrom,
            filtermeal,
            filterhighconfirmation: tourCriteria.get('warranted') ? 1 : 0,
            filtertouroperators: this.getOperatorsList(filterCriteria),
            filterexclusive: tourCriteria.get('isExclusive') ? 1 : 0,
        };

        return this.removeEmptyFields(customFields);
    }

    public fieldsForTourPage(tourProduct: Backbone.Model, tourPageModel?: Backbone.Model): IFieldsForTourPage {
        const isTourProduct = tourProduct.get('type') === 'TourProduct';

        const tour = isTourProduct
            ? tourProduct.get('tour')
            : tourProduct.get('railwayTour');

        const touristGroup = this.getTouristGroupList(tour);
        const dates = this.getDatesList(tour);
        const identities = isTourProduct
            ? this.prepareIdentities(tourPageModel)
            : {};

        const commonFields = {
            ...touristGroup,
            ...identities,
            productType: isTourProduct ? 'tourproduct' : 'railway',
        };

        if (tour instanceof Backbone.Model) {
            // eslint-disable-next-line no-extra-boolean-cast
            const hotelStars = tour.get('hotel').hotelCategoryName?.length < 2
                ? tour.get('hotel').hotelCategory
                : tour.get('hotel').hotelCategoryName;

            const customFields: IFieldsForTourPage = {
                ...commonFields,
                cityFrom: tour.get('departureCity')?.get('name'),
                meal: tour.get('meal')?.get('name'),
                countryId: [tour.get('country')?.get('id')],
                countryName: [tour.get('country')?.get('name')],
                resortId: [tour.get('resort').id],
                resortName: [tour.get('resort').name],
                hotelId: tour.get('hotel').id,
                hotelName: tour.get('hotel').name,
                hotelRating: tour.get('hotel').rating?.toFixed(1),
                hotelStars,
                nightsintour: tour.get('nights'),
                bookingPrice: tourProduct?.get('fullPrice') || tourProduct.get('price'),
                startdatetour: dates.dateFrom,
            };

            return this.removeEmptyFields(customFields);
        }

        // eslint-disable-next-line no-extra-boolean-cast
        const hotelStars = tour.hotelCategory?.name < 2
            ? tour.hotel.hotelCategory
            : tour.hotelCategory?.name;

        const customFields: IFieldsForTourPage = {
            ...commonFields,
            cityFrom: tour.departureCity.name,
            meal: tour.meal.name,
            countryId: [tour.country.id],
            countryName: [tour.country.name],
            resortId: [tour.resort.id],
            resortName: [tour.resort.name],
            hotelId: tour.hotel.id,
            hotelName: tour.hotel.name,
            hotelRating: tour.hotel.rating?.toFixed(1),
            hotelStars,
            nightsintour: tour.nights.hotel,
            bookingPrice: tourProduct?.get('fullPrice') || tourProduct.get('price'),
            startdatetour: dates.dateFrom || tour.checkInDate,
        };

        return this.removeEmptyFields(customFields);
    }

    public fieldsForCheckoutRedirect(tourPageModel: any, quoteUuid: string, priceDetailsViewModel: Backbone.Model): IFieldsForCheckoutRedirect {
        const selectedRouteByPk = tourPageModel.get('selectedRouteByPk');
        const selectedTourProduct = tourPageModel.get('selectedTourProduct');
        const isTourProduct = selectedTourProduct.get('type') === 'TourProduct';

        const tour = isTourProduct
            ? selectedTourProduct.get('tour')
            : selectedTourProduct.get('railwayTour');
        const Touridentity = isTourProduct
            ? selectedTourProduct.get('id')
            : null;
        const quoteid = isTourProduct
            ? quoteUuid
            : null;

        const touristGroup = this.getTouristGroupList(tour);
        const dates = this.getDatesList(tour);
        const bookingPrice = priceDetailsViewModel?.get('fullPrice')
            || selectedRouteByPk?.get('fullPrice')
            || selectedRouteByPk?.get('price');

        const commonFields = {
            quoteid,
            Touridentity,
            bookingPrice,
            productType: isTourProduct ? 'tourproduct' : 'railway',
            ...touristGroup,
        };

        if (tour instanceof Backbone.Model) {
            // eslint-disable-next-line no-extra-boolean-cast
            const hotelStars = tour.get('hotel').hotelCategoryName?.length < 2
                ? tour.get('hotel').hotelCategory
                : tour.get('hotel').hotelCategoryName;

            const customFields = {
                ...commonFields,
                cityFrom: tour.get('departureCity')?.get('name'),
                meal: tour.get('meal')?.get('name'),
                countryId: [tour.get('country')?.get('id')],
                countryName: [tour.get('country')?.get('name')],
                resortId: [tour.get('resort').id],
                resortName: [tour.get('resort').name],
                hotelId: tour.get('hotel').id,
                hotelName: tour.get('hotel').name,
                hotelRating: tour.get('hotel').rating?.toFixed(1),
                hotelStars,
                nightsintour: tour.get('nights'),
                startdatetour: dates.dateFrom,
                touroperatorName: tour.get('operator')?.name,
                transferAmount: priceDetailsViewModel?.get('transferUpsellPriceAmount') || selectedTourProduct.get('transferPriceAmount') || null,
            };

            return this.removeEmptyFields(customFields);
        }

        // eslint-disable-next-line no-extra-boolean-cast
        const hotelStars = tour.hotelCategory?.name < 2
            ? tour.hotel.hotelCategory
            : tour.hotelCategory?.name;

        const customFields = {
            ...commonFields,
            cityFrom: tour.departureCity.name,
            meal: tour.meal.name,
            countryId: [tour.country.id],
            countryName: [tour.country.name],
            resortId: [tour.resort.id],
            resortName: [tour.resort.name],
            hotelId: tour.hotel.id,
            hotelName: tour.hotel.name,
            hotelRating: tour.hotel.rating?.toFixed(1),
            hotelStars,
            nightsintour: tour.nights.hotel,
            startdatetour: dates.dateFrom || tour.checkInDate,
            touroperatorName: tour.operator?.name,
            transferAmount: tourPageModel.transferPriceAmount || null,
        };

        return this.removeEmptyFields(customFields);
    }

    public fieldsForCheckoutRedirectHotellOffer(hotelPageModel: Backbone.Model, tariff: IHotelOfferTariff): IFieldsForCheckoutRedirectHotellOffer {
        const tourCriteria = hotelPageModel.get('searchData');
        const touristGroup = this.getTouristGroupList(tourCriteria);
        const countryModel = tourCriteria.get('countries')?.at(0);
        const resortModel = tourCriteria.get('resorts')?.at(0);
        const hotelModel = tourCriteria.get('hotels')?.at(0);
        const syncTourProducts = hotelPageModel.get('syncTourProducts');
        const product = syncTourProducts.find((_hotelOffer: any) => _hotelOffer.get('id') === tariff.tourId);
        const hotelOffer = product.get('hotelOffer');
        const hotelStars = hotelModel.get('category').get('name');

        const customFields = {
            meal: hotelOffer.get('meal')?.get('name'),
            countryName: [countryModel?.get('name')],
            countryId: [countryModel?.get('id')],
            resortId: [resortModel?.get('id')],
            resortName: [resortModel?.get('name')],
            hotelId: hotelModel.get('id'),
            hotelName: hotelModel.get('name'),
            hotelRating: hotelModel.get('rating').toFixed(1),
            hotelStars,
            nightsintour: hotelOffer.get('nights'),
            productType: 'hoteloffer',
            bookingPrice: hotelOffer.get('price'),
            startdatetour: hotelOffer.get('checkInDate'),
            touroperatorName: hotelOffer.get('operator')?.name,
            ...touristGroup,
        };

        return this.removeEmptyFields(customFields);
    }

    public getProductPrice(product: Backbone.Model): null | number {
        return product
            ? product.get('priceWithOilTax') ?? product.get('price')
            : null;
    }
}