module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="closed-country-banner js-closed-country-banner-wrap">\n    <div class="closed-country-banner__icon"></div>\n\n    <div class="text-block">\n        <p class="text-block__header">\n            '+
((__t=( text[type].label ))==null?'':__t)+
'\n        </p>\n\n        <p class="text-block__title">\n            '+
((__t=( text[type].main ))==null?'':__t)+
'\n            <b class="js-country-closed-name"></b>\n        </p>\n\n        <p class="text-block__link-text">\n            Подробнее об открытых странах можно узнать\n            <a class="text-block__link_alt"\n                href="https://intercom.help/travelata-ac8575273330/ru/articles/4890004-%D0%BA%D1%83%D0%B4%D0%B0-%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE-%D0%BF%D0%BE%D0%B5%D1%85%D0%B0%D1%82%D1%8C"\n                target="_blank"\n                rel="external"\n            >\n                в нашей статье\n            </a>\n        </p>\n\n        ';
 if(type === 'hotel') { 
__p+='\n            <p class="text-block__extra">\n                <span>Лучшие направления</span>\n                <a class="text-block__link_alt"\n                    href="/russia"\n                    target="_blank"\n                >\n                    Россия\n                </a>\n                <a class="text-block__link_alt"\n                    href="/turkey"\n                    target="_blank"\n                >\n                    Турция\n                </a>\n                <a class="text-block__link_alt"\n                    href="/uae"\n                    target="_blank"\n                >\n                    ОАЭ\n                </a>\n            </p>\n        ';
 } 
__p+='\n    </div>\n</div>\n';
}
return __p;
};
