module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

	var moment = require("moment"),
		tour = tourProducts.at(0).get('tour'),
		touristGroup = tour.get('touristGroup'),
		discount = tour.get('discount');

	require('moment/locale/ru');

__p+='\r\n<div class="serpHotelCard__container" data-sortrate="'+
((__t=( sortRate ))==null?'':__t)+
'">\r\n    ';
 if(isExclusive) { 
__p+='\r\n        <div class="js-serpHotelCard__isExclusive"></div>\r\n    ';
 } 
__p+='\r\n	<div class="serpHotelCard__carousel">\r\n		<div class="serpHotelCard__top-block">\r\n			<div>\r\n				';
 if(discount && discount > 0.1) { 
__p+='\r\n				<div class="serpHotelCard__discount">\r\n					Скидка - '+
((__t=( Math.round(discount*100) ))==null?'':__t)+
'%\r\n				</div>\r\n				';
 } 
__p+='\r\n				';
 if (tour.get('lessPlaces')) { 
__p+='\r\n				<div class="serpHotelCard__tip serpHotelCard__tip__less-places">Мало мест</div>\r\n				';
 } 
__p+='\r\n			</div>\r\n		</div>\r\n		<div class="serpHotelCard__carousel-container js-images-carousel">\r\n			<div class="newLoader"></div>\r\n			<ul class="list imagesHtml">'+
((__t=(imagesHtml?imagesHtml:''))==null?'':__t)+
'</ul>\r\n			<span class="js-images-carousel-control-prev serpHotelCard__carousel-prev"><i class="icon-i16_arrow_left_thin"></i></span>\r\n			<span class="js-images-carousel-control-next serpHotelCard__carousel-next"><i class="icon-i16_arrow_right_thin"></i></span>\r\n		</div>\r\n		';

		var distance,
			distanceAirport,
			title;

		if(distances.beach > 0) {
			if(distances.beach >= 1000) {
				distance = parseFloat((distances.beach/1000).toFixed(1)) + ' км';
			} else {
				distance = distances.beach + ' м';
			}
			title = 'до пляжа';

		} else if(distances.lift > 0) {
			if(distances.lift >= 1000) {
				distance = parseFloat((distances.lift/1000).toFixed(1)) + ' км';
			} else {
				distance = distances.lift + ' м';
			}
			title = 'до подъемника';
		}
		if(distances.airport > 0) {
			distanceAirport = distances.airport + ' км';
		}


		if(distance || distanceAirport){ 
__p+='\r\n			<div class="serpHotelCard__distances">\r\n			';
 if(distanceAirport){ 
__p+='\r\n				<div class="serpHotelCard__distance">\r\n					<span>'+
((__t=(distanceAirport))==null?'':__t)+
'</span> до аэропорта\r\n				</div>\r\n			';
 } if(distance){ 
__p+='\r\n				<div class="serpHotelCard__distance">\r\n					<span>'+
((__t=(distance))==null?'':__t)+
'</span>'+
((__t=(title))==null?'':__t)+
'\r\n				</div>\r\n			';
 } 
__p+='\r\n			</div>\r\n		';
 }
__p+='\r\n	</div>\r\n	<div class="serpHotelCard__content">\r\n		<div class="serpHotelCard__left">\r\n			<div class="serpHotelCard__tips-wrap">\r\n			';
 if(rating > 0 || reviewsQty > 0 || tour.get('lessPlaces') || warranted){
__p+='\r\n				';
 if(rating > 0 || reviewsQty > 0 ){
__p+='\r\n				<div class="serpHotelCard__tips">\r\n					';
 if(rating > 0) {
						var ratingColorNumber = 0;
						rating = +rating.toFixed(1);
						if(rating >= 4.5){
							ratingColorNumber = 5;
						}else if(rating >= 4){
							ratingColorNumber = 4;
						}else if(rating >= 3.5){
							ratingColorNumber = 3;
						}
__p+='\r\n					<div class="serpHotelCard__tip serpHotelCard__rating ratingBgColor'+
((__t=(ratingColorNumber))==null?'':__t)+
'">\r\n						'+
((__t=( rating ))==null?'':__t)+
'\r\n					</div>\r\n					';
} if(reviewsQty > 0) { 
__p+='\r\n					<div class="serpHotelCard__tip serpHotelCard__review reviewBdColor'+
((__t=(ratingColorNumber))==null?'':__t)+
'">\r\n						<a href="#" class="goToHotel hotel-reviews">'+
((__t=(reviewsQty ))==null?'':__t)+
' отзыв'+
((__t=( reviewsQty.wordEnding(";а;ов") ))==null?'':__t)+
'</a>\r\n					</div>\r\n					';
 } 
__p+='\r\n				</div>\r\n				';
 } 
__p+='\r\n				';
 if (tour.get('lessPlaces')) { 
__p+='\r\n					<div class="serpHotelCard__tip serpHotelCard__tip__less-places">Мало мест</div>\r\n				';
 } 
__p+='\r\n				';
 if(warranted) { 
__p+='\r\n					<i class="icon-i16_check2 warranted-icon serpHotelCard__warranted js-warranted-icon"><span class="tooltipContent">Высокая вероятность подтверждения бронирования отеля</span></i>\r\n				';
 } 
__p+='\r\n			';
 } 
__p+='\r\n			<div class="js-confirmation-icon"></div>\r\n			</div>\r\n			<div class="serpHotelCard__info">\r\n                <div class="serpHotelCard__wrapper">\r\n                    <div class="serpHotelCard__stars">\r\n                        ';

                        var stars = category.get('stars');
                        var categoryName = category.get('name');
                        if(stars > 0) { for(var i=0;i< stars;i++) { 
__p+='\r\n                        <i class="icon icon-i16_star"></i>\r\n                        ';

                        }
                        } else if(categoryName){
__p+='\r\n                        <span>'+
((__t=( categoryName ))==null?'':__t)+
'</span>\r\n                        ';
 }
__p+='\r\n                    </div>\r\n                    <span class="serpHotelCard__renovation-date">'+
((__t=( dateOfLastHotelChange ))==null?'':__t)+
'</span>\r\n                </div>\r\n                <a href="#" class="serpHotelCard__title goToHotel">'+
((__t=( name ))==null?'':__t)+
'<span class="serpHotelCard__title-blur"></span></a>\r\n				<a href="#" class="serpHotelCard__resort goToHotel distance">\r\n					<i class="icon-i16_location2"></i>\r\n					'+
((__t=( resort instanceof Backbone.Model ? (resort.get('name') + ', ' + resort.get('country').get('name')) : (resort.name ? resort.name : '') ))==null?'':__t)+
'\r\n				</a>\r\n			</div>\r\n			<div class="serpHotelCard__attributes">\r\n				';

				var placesLeft = 4,beachIcon;
				if(beachLine) {
				placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon-i16_water"></i> '+
((__t=( beachLine ))==null?'':__t)+
'-я линия\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (sandBeach) {
					beachIcon = {
						icon: 'sand',
						title: 'песок'
					};
				} else if (sandPebbleBeach) {
					beachIcon = {
						icon: 'sand_pebble',
						title: 'песок, галька'
					};
				} else if (pebbleBeach) {
					beachIcon = {
						icon: 'pebble',
						title: 'галька'
					};
				} 
__p+='\r\n				';
 if(beachIcon) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon-i16_beach_'+
((__t=( beachIcon.icon ))==null?'':__t)+
'"></i> <span>'+
((__t=( beachIcon.title ))==null?'':__t)+
'</span>\r\n						';
 if (beachDescription) { 
__p+='\r\n						<div class="tooltip">\r\n							<div class="serpHotelCard__tooltip-icon">?</div>\r\n							<div class="tooltip__container">'+
((__t=( beachDescription ))==null?'':__t)+
'</div>\r\n						</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (airConditioner) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon-i16_air-conditioning"></i> <span>кондиционер</span>\r\n						';
 if (conditionerDescription) { 
__p+='\r\n						<div class="tooltip">\r\n							<div class="serpHotelCard__tooltip-icon">?</div>\r\n							<div class="tooltip__container">'+
((__t=( conditionerDescription ))==null?'':__t)+
'</div>\r\n						</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (wifi) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon-i16_wifi"></i> <span>wi-fi</span>\r\n						';
 if (wifiDescription) { 
__p+='\r\n						<div class="tooltip">\r\n							<div class="serpHotelCard__tooltip-icon">?</div>\r\n							<div class="tooltip__container">'+
((__t=( wifiDescription ))==null?'':__t)+
'</div>\r\n						</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (nearTheBeach && placesLeft > 0) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_water"></i><span>Близко к пляжу</span>\r\n						';
 if (nearTheBeachDescription) { 
__p+='\r\n						<div class="tooltip">\r\n							<div class="serpHotelCard__tooltip-icon">?</div>\r\n							<div class="tooltip__container">'+
((__t=( nearTheBeachDescription ))==null?'':__t)+
'</div>\r\n						</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (nearTheLift && placesLeft > 0) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_lift"></i><span>Близко к подъемнику</span>\r\n						';
 if (nearTheLiftDescription) { 
__p+='\r\n							<div class="tooltip">\r\n								<div class="serpHotelCard__tooltip-icon">?</div>\r\n								<div class="tooltip__container">'+
((__t=( nearTheLiftDescription ))==null?'':__t)+
'</div>\r\n							</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (nearTheCenter && placesLeft > 0) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_city"></i><span>Близко к центру</span>\r\n						';
 if (nearTheCenterDescription) { 
__p+='\r\n							<div class="tooltip">\r\n								<div class="serpHotelCard__tooltip-icon">?</div>\r\n								<div class="tooltip__container">'+
((__t=( nearTheCenterDescription ))==null?'':__t)+
'</div>\r\n							</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (withKids && placesLeft > 0) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_children"></i><span>Отдых с детьми</span>\r\n						';
 if (withKidsDescription) { 
__p+='\r\n							<div class="tooltip">\r\n								<div class="serpHotelCard__tooltip-icon">?</div>\r\n								<div class="tooltip__container">'+
((__t=( withKidsDescription ))==null?'':__t)+
'</div>\r\n							</div>\r\n						';
 } 
__p+='\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (forCouples && placesLeft > 0) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_hotel_pair"></i><span>Идеально для пар</span>\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (adultsOnly && placesLeft > 0) {
					placesLeft--;
				
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_hotel_16plus"></i><span>Только для взрослых</span>\r\n					</div>\r\n				';
 } 
__p+='\r\n				';
 if (wellness && placesLeft > 0) {
__p+='\r\n					<div class="serpHotelCard__attribute">\r\n						<i class="icon icon-i16_beauty"></i><span>Оздоровительные туры</span>\r\n					</div>\r\n				';
 } 
__p+='\r\n			</div>\r\n		</div>\r\n        <a href="" class="serpHotelCard__right-part-wrapper" style="cursor: default">\r\n            <div class="serpHotelCard__right-part">\r\n                <div class="serpHotelCard__haunter addToFavorites">\r\n                    <i class="icon-i16_heart-3"></i> Добавить в Турхантер\r\n                </div>\r\n                <div class="serpHotelCard__ordersCount-wrapper">\r\n                    ';
 if(ordersCount > 0) { 
__p+='\r\n                    <div class="serpHotelCard__ordersCount">\r\n                        Забронировано '+
((__t=(ordersCount ))==null?'':__t)+
' раз'+
((__t=(ordersCount.wordEnding(';а;') ))==null?'':__t)+
' <br> за месяц\r\n                    </div>\r\n                    ';
 } 
__p+='\r\n                </div>\r\n                <div class="serpHotelCard__right-part-block">\r\n                    <div class="js-right-block"></div>\r\n                </div>\r\n            </div>\r\n        </a>\r\n	</div>\r\n\r\n    <div class="cashbackPartialPayment__banner"></div>\r\n	<div class="partialPayment__banner"></div>\r\n	<div class="freeCancellation__banner"></div>\r\n</div>\r\n';
}
return __p;
};
