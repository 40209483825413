/* eslint-disable */
import errorHandlerService from "@/js/common/Domain/Service/errorHandler";
import accountModel from '@/js/desktop/components/account/accountModel'
import tourHunterTourCriteriaHydrator from "@/js/common/Domain/Hydrator/tourHunterTourCriteria";
import tourCriteriaTourhunterHydrator from "@/js/common/Domain/Hydrator/tourCriteriaTourHunter";

var activeSubscribesAjax,
	activeSubscribes = new Backbone.Collection();

let tourHunterService = {
	getActiveSubscribes: function (callback) {
		var getActiveSubscribesStatus = function () {
				var options = {
					url: window.appConfig.accountUrl + '/api/tourHunter/getActive',
					xhrFields: {withCredentials: true}
				};

				activeSubscribesAjax = $.ajax(options);
			},
			accountIsAuthorized = accountModel.get('authorized'),
			email = accountModel.get('email');

		callback = callback ? callback : function () {
		};

		if (accountIsAuthorized && (!activeSubscribesAjax || activeSubscribesAjax.readyState != 4)) {
			if (!activeSubscribesAjax) {
				getActiveSubscribesStatus();

				$.when(activeSubscribesAjax).then(function (response) {
					if (response.success && response.data) {
						var activeSubscribesData;
						activeSubscribesData = _.map(response.data, function (subscribe) {
							return {
								uuid: subscribe.uuid,
								email: email,
								tourCriteria: tourHunterTourCriteriaHydrator(subscribe.tourCriteria)
							}
						});
						activeSubscribes.reset(activeSubscribesData);
					}
				});
			}

			$.when(activeSubscribesAjax).then(function (response) {
				callback(activeSubscribes);
			}, function () {
				callback(activeSubscribes);
			});
		} else {
			callback(activeSubscribes);
		}

		return activeSubscribes;
	},
	clearActiveAjax: function () {
		activeSubscribesAjax = null;
	},
	unsubscribeByTourCriteria: function (options) {
		var matchedSubscribes = this.matchedSubscribes(options.tourCriteria),
			self = this,
			unsubscribeAjaxes;

		unsubscribeAjaxes = _.map(matchedSubscribes, function (matchedSubscribe) {
			return self.unsubscribe({
				subscribeData: matchedSubscribe
			})
		});

		$.when.apply($, unsubscribeAjaxes).then(function () {
			activeSubscribes.remove(matchedSubscribes);
			options.success();
		});
	},
	unsubscribe: function (options) {
		var subscribeData = options.subscribeData;

		return $.ajax({
			url: window.appConfig.accountUrl + '/api/tourHunter/remove',
			data: {
				uuid: subscribeData.get('uuid'),
				email: subscribeData.get('email')
			},
			dataType: "json",
			type: "POST",
			xhrFields: {withCredentials: true}

		});
	},
	addTourCriteriaToSubscribes: function (tourCriteriaTH) {
		activeSubscribes.add(tourCriteriaTH);
	},
	subscribe: function (options) {
		var self = this,
			tourCriteriaTourhunter = tourCriteriaTourhunterHydrator(options.data.tourCriteria);

		$.ajax({
			url: window.appConfig.accountUrl + '/api/tourHunter/createTourCriteria',
			data: {
				email: options.data.email,
				tourCriteria: tourCriteriaTourhunter,
				lastMinimalPrice: options.data.minPrice
			},
			type: "POST",
			xhrFields: {withCredentials: true}
		})
			.done(function (response) {
				if (response && response.success) {
					options.success ? options.success(response) : '';
					self.addTourCriteriaToSubscribes({
						uuid: response.data.uuid,
						email: options.data.email,
						tourCriteria: tourCriteriaTourhunter
					});
				} else {
					options.error ? options.error(response) : '';
					console.log(response);
					errorHandlerService('Tourhunter subscribe failed');
				}
			})
			.fail(function () {
				options.error ? options.error() : '';
			});
	},
	matchedSubscribes: function (tourCriteria) {
		var tourCriteriaTourhunter = tourCriteriaTourhunterHydrator(tourCriteria),
			subscribedItems = [];

		if (activeSubscribes && activeSubscribes.length) {
			subscribedItems = activeSubscribes.filter(function (subscribeItem) {
				return _.isEqual(tourCriteriaTourhunter, subscribeItem.get('tourCriteria'));
			});
		}

		return subscribedItems;
	},
	criteriaIsSubscribed: function (tourCriteria) {
		var result = false,
			tourCriteriaTourhunter = tourCriteriaTourhunterHydrator(tourCriteria),
			subscribeItem;

		if (activeSubscribes && activeSubscribes.length) {
			for (var i = 0; i < activeSubscribes.length; i++) {
				subscribeItem = activeSubscribes.at(i);
				result = _.isEqual(tourCriteriaTourhunter, subscribeItem.get('tourCriteria'));
				if (result) break;
			}
		}

		return result;
	},
	initAccountAuth: function () {
		var self = this;
		accountModel.on('change:authorized', function (model, authorized) {
			if (authorized) {
				self.getActiveSubscribes();
			} else {
				self.clearActiveAjax();
			}
		});
	},
	init: function () {
		this.initAccountAuth();
	}
};

$(function () {
	tourHunterService.init();
});

export default tourHunterService;
