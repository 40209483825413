import OperatorEntity from '../Entity/Operator';

const OperatorsRepository = Backbone.Collection.extend({
    model: OperatorEntity,
    setOperators(operators) {
        this.reset(operators);
    },
});

OperatorsRepository.repository = new OperatorsRepository();

export default OperatorsRepository;
